import { sendSystemChatMessage } from '../../';
import { MANAGEMENT_EVENTS_TIP } from '../../actions';

export default ({ name = '', amount = 0 }) => (dispatch) => {
  const values = { user: name, amount };
  dispatch(
    sendSystemChatMessage({ id: 'systemMessage.tip', values, css: 'moneybag' })
  );
  dispatch({
    type: MANAGEMENT_EVENTS_TIP,
    payload: {
      amount,
    },
  });
};
