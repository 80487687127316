import './Cam.scss';

import classNames from 'classnames';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import Webcam from 'react-webcam';

import closeIcon from '../../assets/img/svg/close.svg';
import uploadIcon from '../../assets/img/svg/upload.svg';
import FieldErrorWrapper from '../../components/Forms/FieldErrorWrapper/FieldErrorWrapper';
import { uuid } from '../../utils';
import Button from '../Button/Button';
import StandaloneErrorWrapper from '../Forms/FieldErrorWrapper/StandaloneErrorWrapper/StandaloneErrorWrapper';
import FieldWithErrors from '../Forms/FieldWithErrors/FieldWithErrors';
import InputWithLabel from '../Forms/InputWithLabel/InputWithLabel';
import Select from '../Forms/Select/Select';
import {
  dataURLtoFile,
  getDeviceOptions,
  validateFilenameScheme,
} from './Cam.functions';

function Cam({
  title,
  minScreenshotWidth,
  minScreenshotHeight,
  filename,
  selectedCam,
  selectWebcam,
  cropping = false,
  uploadPath,
  uploadFormData = new FormData(),
  outsideFormik,
  openOverlay = () => {},
  closeOverlay = () => {},
  uploadFile = () => {},
  indicator = '',
  camstamp = '',
  singleFile = false,
  setSourceOnly,
}) {
  const webcamRef = useRef();
  const intl = useIntl();

  const [deviceId, setDeviceId] = useState(selectedCam.value);
  const [imgSrc, setImgSrc] = useState(null);
  const [deviceOptions, setDeviceOptions] = useState([]);
  const [error, setError] = useState(false);
  const [userMediaError, setUserMediaError] = useState(null);
  const [filenameError, setFilenameError] = useState(null);
  const [isSnapshot, setIsSnapshot] = useState(false);

  const [file, setFile] = useState({
    name: filename,
    path: filename,
    type: 'image/jpeg',
  });

  const sendUploadImageRequest = useCallback(
    function (completedCrop, file, rotate) {
      const data = new FormData();
      const name = singleFile ? 'file' : 'files[]';
      data.append(name, file, file.name);

      for (let pair of uploadFormData.entries()) {
        data.append(pair[0], pair[1]);
      }

      if (completedCrop) {
        data.append('crop[0][x]', completedCrop.x);
        data.append('crop[0][y]', completedCrop.y);
        data.append('crop[0][width]', completedCrop.width);
        data.append('crop[0][height]', completedCrop.height);
      }

      if (rotate) {
        data.append('rotate[]', rotate);
      }

      uploadFile(uploadPath, data, indicator);
      closeOverlay(camstamp);
    },
    [
      uploadFormData,
      uploadFile,
      uploadPath,
      indicator,
      closeOverlay,
      camstamp,
      singleFile,
    ]
  );
  const capture = useCallback(() => {
    const videoConstraints = {
      deviceId,
    };

    const imageSrc = webcamRef.current.getScreenshot(videoConstraints);

    if (!imageSrc) {
      return setError(true);
    }

    setImgSrc(imageSrc);

    setFile({ ...file, size: imageSrc.length, lastModified: Date.now() });

    setIsSnapshot(true);
  }, [webcamRef, setImgSrc, deviceId, setFile, file, setError, setIsSnapshot]);

  const revertSnapshot = useCallback(() => {
    setImgSrc(null);
    setIsSnapshot(false);
  }, [setImgSrc, setIsSnapshot]);

  const handleDevices = useCallback(
    (mediaDevices) => {
      const deviceOptions = getDeviceOptions(mediaDevices);

      setDeviceOptions(deviceOptions);

      let localDeviceId = deviceOptions[0].value;

      if (
        deviceOptions[0].value !== selectedCam.value &&
        deviceOptions.find((o) => o.value === selectedCam.value)
      ) {
        localDeviceId = selectedCam.value;
      }
      setDeviceId(localDeviceId);
    },
    [setDeviceOptions, setDeviceId, selectedCam]
  );

  const handleDeviceError = useCallback(() => setError(true), []);

  const onChange = useCallback(
    (event) => {
      userMediaError && setUserMediaError(null);

      setDeviceId(event.target.value);

      const selectedWebCam = deviceOptions.find(
        (option) => option.value === event.target.value
      );

      selectWebcam(selectedWebCam);
    },
    [
      setDeviceId,
      deviceOptions,
      selectWebcam,
      userMediaError,
      setUserMediaError,
    ]
  );

  const onUserMediaError = useCallback(
    (e) => {
      setUserMediaError(e);
    },
    [setUserMediaError]
  );

  useEffect(() => {
    // call getusermedia first, so we get permissions
    // else enumerateDevices does not return labels
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(() => navigator.mediaDevices.enumerateDevices().then(handleDevices))
      .catch(handleDeviceError);
  }, [handleDevices, handleDeviceError]);

  const onFilenameChange = useCallback(
    (e) => {
      const newFilename = e.target.value;
      try {
        validateFilenameScheme.validateSync({ filename: newFilename });
        setFile({ ...file, name: newFilename, path: newFilename });
        setFilenameError(null);
      } catch (error) {
        setFilenameError(intl.formatMessage({ id: error.message }));
      }
    },
    [file, intl]
  );

  const openPictureCropper = () => {
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'PictureCropper',
      props: {
        imageSource: imgSrc,
        remoteStamp: camstamp,
        cropperstamp: stamp,
        uploadFormData,
        uploadPath,
        indicator,
        file,
        title,
      },
    });
  };

  const controlItemsClass = classNames('control-items', {
    documents: outsideFormik,
    'profile-image': !outsideFormik,
  });

  return (
    <>
      <div className="overlay-wrapper camshot">
        <div className="overlay-header">
          {title && <h1 className="headline">{title}</h1>}
        </div>
        <div className="overlay-content">
          {!error && (
            <Formik>
              <FieldWithErrors
                name="cams"
                as={Select}
                id="cams"
                disabled={isSnapshot}
                options={deviceOptions}
                onChange={onChange}
                value={selectedCam.value}
                intlTranslate={false}
                label="streamingOptions.selectCam"
              />
            </Formik>
          )}
          {error || userMediaError ? (
            <div className="error-wrapper">
              <div className="error-title">
                {intl.formatMessage({ id: 'WEBCAM_ERROR_TITLE' })}
              </div>
              <hr />
              <div className="error-content">
                {intl.formatMessage({ id: 'WEBCAM_ERROR_TEXT' })}
              </div>
            </div>
          ) : imgSrc ? (
            <>
              <div className="webcam-image-wrapper">
                <img src={imgSrc} alt="camera" />
              </div>
              <div className={controlItemsClass}>
                {!cropping && (
                  <>
                    {outsideFormik ? (
                      <StandaloneErrorWrapper
                        name="filename"
                        error={filenameError ? filenameError : null}
                        noGrid={true}
                      >
                        <InputWithLabel
                          className="filename"
                          value={file.name}
                          name="filename"
                          onChange={onFilenameChange}
                          // onBlur={onFilenameChange}
                          label="FILENAME"
                        />
                      </StandaloneErrorWrapper>
                    ) : (
                      <FieldErrorWrapper
                        name="filename"
                        error={filenameError ? filenameError : null}
                        noGrid={true}
                      >
                        <input
                          type="text"
                          className="filename"
                          value={file.name}
                          name="filename"
                          onChange={onFilenameChange}
                          onBlur={onFilenameChange}
                        />
                      </FieldErrorWrapper>
                    )}
                  </>
                )}
                <div className="webcam-buttons">
                  <Button
                    type="button"
                    variant="dark"
                    onClick={revertSnapshot}
                    label="BUTTON_REVERT"
                  />
                  {cropping ? (
                    <Button
                      type="button"
                      variant="primary"
                      onClick={openPictureCropper}
                      label="BUTTON_CROP_IMAGE"
                    />
                  ) : outsideFormik ? (
                    <Button
                      icon={uploadIcon}
                      label={
                        typeof setSourceOnly === 'function'
                          ? 'BUTTON_UPLOAD_TAKE_IMAGE'
                          : 'BUTTON_UPLOAD_IMAGE'
                      }
                      onClick={() => {
                        // If the Cam component is outside a Formik form, an image is added to the list of files
                        // before being uploaded. A standalone error wrapper is needed as well, since the default one
                        // works only if it is being used for a Formik field.
                        if (typeof setSourceOnly === 'function') {
                          setSourceOnly(imgSrc, file.name);
                          return closeOverlay(camstamp);
                        }
                        const innerfile = dataURLtoFile(imgSrc, file.name);
                        setFile(innerfile);
                        sendUploadImageRequest(null, innerfile);
                      }}
                      className="upload-btn"
                    />
                  ) : (
                    <Button
                      icon={uploadIcon}
                      label="BUTTON_UPLOAD_IMAGE"
                      onClick={() => {
                        const innerfile = dataURLtoFile(imgSrc, file.name);
                        setFile(innerfile);
                        sendUploadImageRequest(null, innerfile);
                      }}
                      className="upload-btn"
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="webcam-image-wrapper">
                <Webcam
                  minScreenshotWidth={minScreenshotWidth}
                  minScreenshotHeight={minScreenshotHeight}
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{ deviceId }}
                  onUserMediaError={onUserMediaError}
                />
              </div>
              <div className="control-items snapshot">
                <Button
                  type="button"
                  variant="primary"
                  onClick={capture}
                  label="SNAPSHOT"
                />
              </div>
            </>
          )}
        </div>

        <Button
          type="button"
          classNamesOnly="close-btn"
          icon={closeIcon}
          onClick={() => closeOverlay(camstamp)}
          intlTranslate={false}
        />
      </div>
      <div className="background" />
    </>
  );
}

export default Cam;
