import { connect } from 'react-redux';

import { toggleUsergameView } from '../../../actions';
import { gameUtils, user as userUtils } from '../../../utils';
import UserGame from './UserGame';

const mapStateToProps = (state) => {
  const user = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);

  if (!user || userUtils.isAdmin(user)) {
    return {
      noUser: true,
    };
  }

  if (
    !user?.partner &&
    userUtils.isDialogUser(user.type) &&
    !!userUtils.hasPartner(user)
  ) {
    user.partner = userUtils.getPartner(user);
  }

  const game = gameUtils.getGame(state, user.partner);

  if (!gameUtils.hasSession(game)) {
    return {
      noUser: true,
    };
  }

  return {
    gameStatus: game?.session?.status,
    id: game.id,
    usergame: state.view.usergame,
    enableResizable: state.view.isMediumLarge || state.view.isMedium,
    componentUrl:
      process.env.REACT_APP_GAME_URL + game.game.webComponentLoaderPath,
    lang: state.intl.locale,
  };
};

const mapDispatchToProps = {
  toggleUsergameView,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGame);
