import {
  GAMES_INVITATIONS_ADD,
  GAME_INVITATION,
  GAME_INVITATION_STATUS,
  GAME_SESSION_STATUS,
} from '../actions/actions';

const initialState = {
  games: [],
};

const getId = (el) => el.partner;

function gamesReducer(state = initialState, action) {
  switch (action.type) {
    case GAMES_INVITATIONS_ADD: {
      return {
        ...state,
        games: action.payload,
      };
    }
    case GAME_INVITATION: {
      if (!action?.payload?.fromId) {
        return state;
      }
      const invitation = action.payload;
      const id = getId(invitation);
      const games = state.games.filter((el) => el.partner !== id);
      games.push(invitation);
      return {
        ...state,
        games,
      };
    }
    case GAME_INVITATION_STATUS: {
      if (!action?.payload?.fromId || action?.payload?.status === 'accepted') {
        // When the invitation is accepted, it is not sure, that it has a session, so this will be
        // updated with the session update call
        return state;
      }
      const invitation = action.payload;
      const id = getId(invitation);
      const games = state.games.filter((el) => el.partner !== id);
      games.push(invitation);
      return {
        ...state,
        games,
      };
    }
    case GAME_SESSION_STATUS: {
      if (!action?.payload?.fromId) {
        return state;
      }
      const invitation = action?.payload;
      const id = getId(invitation);
      const games = state.games.filter((el) => el.partner !== id);
      games.push(invitation);
      return {
        ...state,
        games,
      };
    }
    default:
      return state;
  }
}

export default gamesReducer;
