import { KPI_UPDATE } from '../actions/actions';

const initialState = {
  loading: true,
  data: {},
};

function kpiReducer(state = initialState, action) {
  switch (action.type) {
    case KPI_UPDATE:
      if (action.error) {
        return { ...state, loading: false };
      }
      return { loading: false, data: action.payload };

    default:
      return state;
  }
}

export default kpiReducer;
