import dayjs from 'dayjs';

const monthNames = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export function getYearOptions() {
  let returnArr = [];

  const minDate = 1920;
  const maxDate = dayjs().year() - 18;

  for (let i = maxDate; i > minDate; i--) {
    returnArr.push({
      label: i,
      value: i,
    });
  }

  return returnArr;
}

export function getMonthOptions(intl) {
  let returnArr = [];

  for (let i = 0; i <= 11; i++) {
    returnArr.push({
      label: intl.formatMessage({ id: monthNames[i] }),
      value: i,
    });
  }

  return returnArr;
}

export function getDayOptions(year, month) {
  let returnArr = [];

  const daysInMonth = dayjs(`${year}-${Number(month) + 1}-01`).daysInMonth();

  for (let i = 1; i <= daysInMonth; i++) {
    returnArr.push({
      label: i,
      value: i,
    });
  }

  return returnArr;
}

export function onDateChange(e, values, setFieldValue, setFieldTouched) {
  const year =
    e.target.name === 'birthdayYear' ? e.target.value : values.birthdayYear;

  const month =
    e.target.name === 'birthdayMonth'
      ? Number(e.target.value) + 1
      : Number(values.birthdayMonth) + 1;

  const day =
    e.target.name === 'birthdayDay' ? e.target.value : values.birthdayDay;

  const date = dayjs.utc(`${year}-${month}-${day}`);

  setFieldValue('birthdayYear', date.year(), false);
  setFieldValue('birthdayMonth', date.month(), false);
  setFieldValue('birthdayDay', date.date(), false);

  setFieldValue('birthday', date.toString());
  setFieldTouched('birthday', true);
}
