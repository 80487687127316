import { TYPE_ADMIN } from '../../../constants/user';
import { MANAGEMENT_STREAM_STARTED } from '../../actions';

export default (management) => (userInfo) => {
  return (dispatch) => {
    console.log(`Send stream started with ${JSON.stringify(userInfo)}`);

    management.emit(
      'streamStarted',
      Object.assign({}, userInfo, {
        type: userInfo.type === TYPE_ADMIN ? 'admin' : userInfo.type,
      })
    );

    dispatch({
      type: MANAGEMENT_STREAM_STARTED,
      payload: userInfo,
    });
  };
};
