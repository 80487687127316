import React from 'react';
import { injectIntl } from 'react-intl';

import closeIcon from '../../../assets/img/svg/close.svg';
import Button from '../../Button/Button';

class WarningAlert extends React.Component {
  render() {
    const {
      onClose,
      hideClose = false,
      message,
      type,
      level,
      stamp,
      loading,
      intl,
      scope = 'livecam',
    } = this.props;

    const hasHeadline = message.headline || message.headlineId;
    const cssClasses = [
      'alert',
      `alert-${type.toLowerCase()}`,
      `alert-${level.toLowerCase()}`,
      `alert-${scope}`,
    ];
    if (hasHeadline) {
      cssClasses.push('has-headline');
    }
    if (loading) {
      cssClasses.push('loading');
    }
    return (
      <div className={cssClasses.join(' ')}>
        {hasHeadline ? (
          <h3>
            {message.translate && message.headlineId
              ? intl.formatMessage(
                  { id: message.headlineId },
                  message.headlineValues
                )
              : message.headline}
          </h3>
        ) : (
          ''
        )}
        <p>
          {message.translate
            ? intl.formatMessage({ id: message.id }, message.values)
            : message.message}{' '}
        </p>
        {!hideClose && (
          <Button
            type="button"
            classNamesOnly="close-btn"
            icon={closeIcon}
            onClick={() => onClose(stamp)}
            intlTranslate={false}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(WarningAlert);

