export default {
  WIKI_LOVENSE_INSTALLATION_HEADLINE: `Einrichtung des Lovense Toy`,
  WIKI_LOVENSE_INSTALLATION_SETTINGS: `Bevor Du mit der Einrichtung beginnst, stelle sicher, dass Du in den {link} unter Features die Verwendung von Lovense Toys erlaubst.`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_HEADLINE: `Konfiguration mit dem Smartphone <span class='textcolor'>(Unsere Empfehlung)</span>`,
  WIKI_LOVENSE_INSTALLATION_SUBTEXT: `Wir zeigen Dir hier Schritt für Schritt, wie Du Dein Toy mit Deinem Account in LiveCreator verknüpfen kannst.`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_SUBTEXT: `Installiere die Lovense Connect App aus dem App Store oder von Google Play auf Deinem Smartphone`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_HEADLINE: `Stelle eine Verbindung Deines Lovense Toy mit der App über Bluetooth her`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT: `
  <ol>
    <li>Öffne die Lovense Connect App</li>
    <li>Schalte Dein Toy an</li>
    <li>Toy hinzufügen, indem Du auf "Add Toy" klickst</li>
    <li>Sobald Dein Toy erkannt wird, wähle es aus und beende den Dialog mit "Done"</li>
  </ol>`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_1: `Nun ist Dein Toy mit der App verbunden.`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_2: `
  <span class="bold">Wichtig:</span> Die Lovense Connect App muss offen und Dein Toy angeschaltet sein, um es in der Cam nutzen zu können`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_HEADLINE_2: `Dein Lovense Toy mit Deinem Account in LiveCreator verknüpfen`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_3: `
  <ol>
    <li>Durch klicken des "weiter" Buttons in LiveCreator wird ein QR-Code generiert</li>
    <li>Scanne den QR Code mit der Lovense Connect App auf Deinem Handy</li>
  </ol>`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_4: `Nun siehst Du in der Lovense Connect App unter Accounts Deinen aktiven LiveCreator Account. Ab jetzt kannst Du das Toy bei uns in der Cam nutzen.`,

  WIKI_LOVENSE_INSTALLATION_DESKTOP_HEADLINE: `Konfiguration mit der Desktop-App und dem Lovense USB Bluetooth Adapter`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_ADAPTER: `(Der Adapter ist separat bei Lovense erhältlich)`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_SUBTEXT: `Installiere die <a href='https://de.lovense.com/sextoys/download' target='_blank'>Lovense Remote App</a> auf Deinem Computer.`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_HEADLINE_1: `Stelle eine Verbindung Deines Lovense Toy mit der App über Bluetooth her`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT: `
  <ol>
    <li>Verbinde den USB-Adapter mit Deinem Rechner</li>
    <li>Öffne die Lovense Remote App</li>
    <li>Schalte Dein Toy an</li>
    <li>Toy hinzufügen, indem Du auf "Add Toy" klickst</li>
    <li>Sobald Dein Toy erkannt wird, wähle es aus und beende den Dialog mit "Done"</li>
  </ol>`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_1: `Nun ist Dein Toy mit der Desktop-App verbunden.`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_2: `<span class="bold">Wichtig:</span> Die Lovense Remote App muss gestartet und Dein Toy angeschaltet sein, um es in der Cam nutzen zu können`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_HEADLINE_2: `Dein Lovense Toy mit Deinem Account in LiveCreator verknüpfen`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_3: `
  <ol>
    <li>Durch klicken des "weiter" Buttons in LiveCreator wird ein Zahlen-Code generiert</li>
    <li>Aktiviere in der Lovense Remote App unter "External Control" den Eintrag "Allow Control"</li>
    <li>Klicke auf „Settings“ und trage dort den angezeigten Zahlen-Code ein</li>
  </ol>`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_4: `Ab jetzt kannst Du das Toy bei uns in der Cam nutzen.`,
};
