import { useIntl } from 'react-intl';

import { SPINNER_DEFAULT_DELAY } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Spinner from '../../../Spinner/Spinner';

function UserProfileItem({
  label,
  value,
  prefix = '',
  postfix = '',
  display = false,
  translate = true,
  wrap = false,
  showZero = false,
  largeLoaded = false,
  showSpinner = false,
}) {
  const isSmallMediumScreen = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1399px)'
  );
  const intl = useIntl();
  const displayItem = display || value;
  const item =
    value || (showZero && value === 0)
      ? `${prefix}${value}${postfix}`
      : intl.formatMessage({ id: 'not.specified' });
  return displayItem ? (
    wrap && isSmallMediumScreen ? (
      <>
        <tr className="no-border">
          <td className="bold">{intl.formatMessage({ id: label })}</td>
        </tr>
        <tr>
          <td>
            {!largeLoaded ? (
              showSpinner ? (
                <Spinner
                  size={20}
                  thickness={2}
                  margin="0px 8px"
                  delay={SPINNER_DEFAULT_DELAY}
                />
              ) : null
            ) : translate && value ? (
              intl.formatMessage({ id: item })
            ) : (
              item
            )}
          </td>
        </tr>
      </>
    ) : (
      <tr>
        <td className="bold">{intl.formatMessage({ id: label })}</td>
        <td className="align-right">
          {!largeLoaded ? (
            showSpinner ? (
              <div className="spinner-wrapper">
                <Spinner size={20} thickness={2} margin="0px 8px" />
              </div>
            ) : null
          ) : translate && value ? (
            intl.formatMessage({ id: item })
          ) : (
            item
          )}
        </td>
      </tr>
    )
  ) : null;
}

export default UserProfileItem;
