import useAxios from 'axios-hooks';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { spinnerRequest } from '../../../../../../shared/spinnerRequest';
import Button from '../../../../../Button/Button';

function GallerySelectItem({ onSelect, gallery, spinner, replaceGallery }) {
  const intl = useIntl();
  const [, addFmotdCall] = useAxios(
    { url: `/fmd/${gallery?.id}`, method: 'PUT' },
    { manual: true }
  );
  const [, putFmotdCall] = useAxios(
    { url: `/fmd/${replaceGallery?.id}/update/${gallery?.id}`, method: 'PUT' },
    { manual: true }
  );
  const addFmotdClicked = useCallback(async () => {
    if (!gallery?.id) return;
    try {
      if (replaceGallery?.id) {
        await spinnerRequest({ request: putFmotdCall, spinner });
      } else {
        await spinnerRequest({ request: addFmotdCall, spinner });
      }
      onSelect();
    } catch (err) {
      console.log(err);
    }
  }, [
    gallery?.id,
    addFmotdCall,
    onSelect,
    spinner,
    putFmotdCall,
    replaceGallery?.id,
  ]);
  return (
    <div className="gallery-select-item">
      <img
        src={`${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${gallery?.thumbnail}`}
        alt=""
        className="preview-image"
      />
      <div className="content">
        <span className="title">{gallery?.title?.content}</span>
        {gallery?.thumbnail_exist ? (
          <Button label="FMOTD_APPLY" onClick={() => addFmotdClicked()} />
        ) : (
          <span className="preview-missing">
            {intl.formatMessage(
              {
                id: 'FMOTD_NO_THUMBNAIL',
              },
              {
                link: (
                  <Link
                    to={`/my-content/${
                      gallery.type === 'movies' ? 'videos' : 'photos'
                    }/edit-gallery?type=${gallery.type}&id=${
                      gallery.id
                    }&target=settings`}
                  >
                    {intl.formatMessage({
                      id: 'FMOTD_NO_THUMBNAIL_UPLOAD',
                    })}
                  </Link>
                ),
              }
            )}
          </span>
        )}
      </div>
    </div>
  );
}

export default GallerySelectItem;
