import { MESSAGE_TYPE_SENDER } from '../../../constants';
import { MESSAGE_SENT } from '../../actions';
import { typingSender } from '../..';
export default (data) => (dispatch, getState) => {
  const {
    user: {
      account: { nickname: from },
    },
    dialog: { dialogs },
    camUser: { camUsers },
    chat: { selectedUser },
  } = getState();
  const selectedLivecamUser = camUsers.find((u) => u.userId === selectedUser);
  const user = dialogs.find((d) => d.partner === data.recipient);

  if (!user) {
    return; // just drop it
  }

  function checkLoadingState() {
    const {
      chat: { dialogmessages },
    } = getState();
    const lastSenderMessage = dialogmessages
      .filter((message) => message.type === MESSAGE_TYPE_SENDER)
      .pop();

    if (!Number.isInteger(lastSenderMessage?.id)) {
      return;
    } else {
      dispatch({
        type: MESSAGE_SENT,
        payload,
        meta: {
          selectedLivecamUser: !!selectedLivecamUser,
          selectedUser,
          user,
        },
      });
      if (selectedLivecamUser) {
        dispatch(typingSender(selectedLivecamUser.userId, ''));
      }
    }
  }

  const payload = {
    ...data,
    from,
    to: user?.profile?.info?.nickname,
    type: MESSAGE_TYPE_SENDER,
    userId: data.recipient,
    message: data.body,
    userColor: user.color,
  };

  delete payload.body;

  if (payload.payload?.type !== 'payttachment') {
    delete payload.payload;
  }

  checkLoadingState();
};
