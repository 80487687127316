export default {
  SETTINGS_HEADLINE: `Einstellungen`,
  SETTINGS_INTRO: `In diesem Bereich nimmst Du alle {link} zu Deinem Account, der Livecam und Preisen vor.`,
  SETTINGS_INTRO_LINK: `Einstellungen`,
  SETTINGS_CONTENT_STEP_1: `Optionen`,
  SETTINGS_CONTENT_STEP_1_CONTENT: `Wähle aus welche Features Du nutzen möchtest. Du kannst diese jederzeit anpassen. Eine Erklärung zu den einzelnen Features findest Du in der Infobox.`,
  SETTINGS_CONTENT_STEP_1_1_CONTENT: `Auch Deine {link} stellst Du hier ein und Du kannst entscheiden, welche E-Mail Benachrichtigungen Du von uns erhalten möchtest.`,
  SETTINGS_CONTENT_STEP_1_LINK: `Creator Tags`,
  SETTINGS_CONTENT_STEP_2: `Livecam`,
  SETTINGS_CONTENT_STEP_2_CONTENT: `In den Stream Einstellungen wählst Du Deine Webcam und wenn Du möchtest auch Dein Mikrofon aus. Die Software überprüft die Hard- und Softwarekonfiguration sowie die Bandbreite Deines Internetanschlusses und ermittelt hieraus den bestmöglichen Übertragungswert. Wie Du erfolgreich mit Deiner Webcam starten kannst, zeigen wir Dir {link}.`,
  SETTINGS_CONTENT_STEP_2_LINK: `hier im Wiki`,
  SETTINGS_CONTENT_STEP_3: `Lovense Toy`,
  SETTINGS_CONTENT_STEP_3_CONTENT: `Um ein Lovense Toy in der Livecam anbieten zu können, musst Du zuerst unter Optionen (1) die Nutzung des Features zulassen. Danach führt Dich der Verbindungsassistent durch die nächsten Schritte. {link} zur Konfiguration findest Du im Wiki.`,
  SETTINGS_CONTENT_STEP_3_LINK: `Ausführliche Informationen`,
  SETTINGS_CONTENT_STEP_4: `Preise`,
  SETTINGS_CONTENT_STEP_4_CONTENT: `Lege den Preis pro Minute in der Livecam fest und wie viel eine Nachricht Deine Kunden kosten soll.`,
};
