import notesSVG from '../../../../../../assets/img/svg/notes.svg';
import Button from '../../../../../Button/Button';

function Usernote({ usernote, toggleUsernoteView }) {
  return (
    <div className="control-item usernote-icon">
      <Button
        icon={notesSVG}
        svgClassname={usernote ? 'active' : ''}
        onClick={toggleUsernoteView}
        disabled={false}
        intlTranslate={false}
        variant="icon-only"
      />
    </div>
  );
}

export default Usernote;
