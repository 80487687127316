import './Motto.scss';

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import closeIcon from '../../../assets/img/svg/close.svg';
import { MOTTO_MAX_LENGTH, MOTTO_MAX_TIME } from '../../../constants';
import { lStorage } from '../../../services/storage';
import Button from '../../Button/Button';
import Input from '../../Forms/Input/Input';

function Motto({ closeOverlay, setMotto, cancelMotto, startStream, stamp }) {
  const intl = useIntl();

  let storedMottoObject = {};
  try {
    storedMottoObject = JSON.parse(lStorage.getItem('motto')) || {};
  } catch (error) {
    storedMottoObject = {};
  }

  let storedMotto = '';

  if (
    storedMottoObject.time + MOTTO_MAX_TIME >= Date.now() &&
    storedMottoObject.text !== ''
  ) {
    storedMotto = storedMottoObject.text;
  }

  const [remaining, setRemaining] = useState(MOTTO_MAX_LENGTH);
  const formik = useFormik({
    initialValues: {
      motto: storedMotto,
    },
    onSubmit: (values) => {
      const value = values.motto;
      closeOverlay(stamp);

      if (value !== '') {
        setMotto(value);
        return setTimeout(() => startStream(), 100);
      }

      setMotto('');
      startStream();
    },
  });

  useEffect(() => {
    if (formik.values.motto.length === 0) return;
    setRemaining(MOTTO_MAX_LENGTH - formik.values.motto.length);

    if (formik.values.motto.length > MOTTO_MAX_LENGTH) {
      formik.values.motto = formik.values.motto.substring(0, MOTTO_MAX_LENGTH);
      setRemaining(0);
    }
  }, [formik.values, formik.values.motto]);

  return (
    <>
      <div className="overlay-wrapper small-overlay livecam-motto">
        <div className="overlay-header">
          <h1 className="headline">
            {intl.formatMessage({ id: 'mottoHeadline' })}
          </h1>
        </div>
        <div className="overlay-content">
          <form onSubmit={formik.handleSubmit} autoComplete="on">
            <div className="form-group">
              <label htmlFor="motto">
                {intl.formatMessage({ id: 'mottoLabel' })}
              </label>
              <Input
                placeholder={intl.formatMessage({ id: 'mottoPlaceholder' })}
                name="motto"
                onChange={formik.handleChange}
                value={formik.values.motto}
                autoComplete="on"
              />

              <span className="textarea-length-indicator">
                {intl.formatMessage({ id: 'REMAINING_CHARS' })}: {remaining}
              </span>
            </div>

            <div className="buttons">
              <Button
                type="submit"
                variant="default"
                label="streamingButton.start"
                className={'btn-streaming'}
              />
            </div>
          </form>
        </div>
        <Button
          type="button"
          classNamesOnly="close-btn"
          icon={closeIcon}
          onClick={() => {
            cancelMotto();
            closeOverlay(stamp);
          }}
          intlTranslate={false}
        />
      </div>
      <div className="background" />
    </>
  );
}

export default Motto;
