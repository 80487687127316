import './Attachments.scss';

import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { SUPPORTED_ATTACHMENT_TYPES } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { uuid } from '../../../../utils';
import { getUpload } from '../../../../utils/uploadUtils';
import StandaloneErrorWrapper from '../../../Forms/FieldErrorWrapper/StandaloneErrorWrapper/StandaloneErrorWrapper';
import Scrollbar from '../../../Scrollbar';
import Section from '../../../Section/Section';
import AttachmentsTable from '../../../Tables/AttachmentsTable';
import Uploader from '../../../Uploader';

const translatedErrors = [
  'attachments_reached_limit',
  'attachments_file_is_missing',
  'unable_to_save',
];
function Attachments({
  isMobile,
  openOverlay,
  attachmentsCount,
  uploads = [],
}) {
  const maxAttachments = 25;
  const intl = useIntl();
  const [documentsError, setDocumentsError] = useState(null);
  const indicator = 'attachments';
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const { uploadIndicator, uploadError } = getUpload(uploads, indicator);

  useEffect(() => {
    if (!uploadError) {
      setDocumentsError(null);
    }
    if (indicator === uploadIndicator && uploadError) {
      let error = intl.formatMessage({ id: 'ERROR_UPLOAD_DOCUMENT_2' });
      if (Array.isArray(uploadError?.response?.data?.errors)) {
        error = intl.formatMessage({ id: 'ERROR_UPLOAD_DOCUMENT_1' });
        const firstError = uploadError.response.data.errors.pop();
        if (translatedErrors.includes(firstError)) {
          error = intl.formatMessage({ id: firstError });
        }
      }
      setDocumentsError(error);
    }
  }, [indicator, uploadError, uploadIndicator, intl]);

  const openCam = () => {
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Cam',
      props: {
        indicator,
        camstamp: stamp,
        uploadPath: '/user/attachment',
        title: intl.formatMessage({ id: 'TAKE_SNAPSHOT' }),
        minScreenshotWidth: 1600,
        filename: `snapshot${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
        // Button inside the Cam is now the same as in the Onboarding, but the outsideFormik prop is still
        // needed since the regular one contains a wrapper connected to formik
        outsideFormik: true,
        singleFile: true,
      },
    });
  };

  const attachmentsWrapper = classNames('attachments-wrapper', {
    'is-mobile': isMobile,
  });

  return (
    <div className="page-wrapper">
      <Section
        title={intl.formatMessage({
          id: 'MONEYMAKERS_HEADLINE',
        })}
        className="component-section attachments-container"
      >
        <Scrollbar visible={!isLargeScreen}>
          <div className={attachmentsWrapper}>
            <Scrollbar visible={isLargeScreen}>
              <div className="info-wrapper">
                <h1 className="headline">
                  {intl.formatMessage({ id: 'ATTACHMENT_UPLOAD' })}
                </h1>
                <p>{intl.formatMessage({ id: 'ATTACHMENT_TEXT_1' })}</p>
                <p>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: intl
                        .formatMessage({ id: 'ATTACHMENT_AVAILABLE_SLOTS' })
                        .replace('{number}', maxAttachments - attachmentsCount),
                    }}
                  />
                </p>
                <div className="uploader-wrapper">
                  <StandaloneErrorWrapper
                    name="documents"
                    error={
                      typeof documentsError === 'string'
                        ? documentsError.match(/.*File type must be.*/g)
                          ? intl.formatMessage(
                              { id: 'ERROR_UPLOAD_FILETYPE' },
                              { typlist: '.jpg, .jpeg, .jpe, .png, .mp4' }
                            )
                          : documentsError
                        : null
                    }
                    noGrid={true}
                  >
                    {attachmentsCount < maxAttachments && (
                      <Uploader
                        uploadPath="/user/attachment"
                        indicator={indicator}
                        label="ATTACHMENT_UPLOADER_LABEL"
                        acceptedFileTypes={SUPPORTED_ATTACHMENT_TYPES}
                        openCam={openCam}
                        isMobile={isMobile}
                        handleError={setDocumentsError}
                        className="documents"
                        dropzoneProps={{ multiple: false }}
                        singleFile={true}
                      />
                    )}
                  </StandaloneErrorWrapper>
                </div>
              </div>
            </Scrollbar>

            <Scrollbar visible={isLargeScreen} hasBoxShadow={true}>
              <div className="data-wrapper">
                <div className="table-box">
                  <h2 className="headline">
                    {intl.formatMessage(
                      { id: 'AVAILABLE_FILES' },
                      { number: attachmentsCount }
                    )}
                  </h2>
                  <div className="files-table">
                    <AttachmentsTable />
                  </div>
                </div>
              </div>
            </Scrollbar>
          </div>
        </Scrollbar>
      </Section>
    </div>
  );
}

export default Attachments;

