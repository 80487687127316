import { user as userUtils } from '../../../utils';
import { DIALOG_USER_OFFLINE } from '../../actions';

export default (payload) => (dispatch, getState) => {
  const {
    dialog: { dialogs },
  } = getState();
  const allDialogs = [...dialogs];
  if (!userUtils.get(allDialogs, payload.user)) {
    return;
  }
  dispatch({ type: DIALOG_USER_OFFLINE, payload });
};
