import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import toggleIcon from '../../../assets/img/svg/arrow-down.svg';

function MessengerHeaderItem({
  title = '',
  additionalNumber,
  chevronButton,
  open = true,
  onClick = () => {},
}) {
  return (
    <div className="submenu-header-item" onClick={onClick}>
      <div className="title">
        <ReactSVG
          src={toggleIcon}
          className={open ? 'open' : 'closed'}
          wrapper="span"
        />
        <FormattedMessage id={title} />
        {additionalNumber && (
          <span className="additional-number">{additionalNumber}</span>
        )}
      </div>
      {chevronButton}
    </div>
  );
}

export default React.memo(MessengerHeaderItem);
