import { management } from '../../actions';
import { roomType as roomTypeUtils, user as userUtils } from '../../utils';
import { SENDER_TYPING } from '../actions';

export default (userId, text) => (dispatch, getState) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    sender: { roomTypes },
  } = getState();
  if (roomTypeUtils.isEvent(roomTypes)) {
    return dispatch({
      type: SENDER_TYPING,
      payload: {
        event: true,
        text,
      },
    });
  }
  const user = userUtils.get([...camUsers, ...dialogs], userId);
  if (!user) {
    return;
  }
  const hasText = text.length > 0;
  if (userUtils.isVisibleLivecamUser(user)) {
    dispatch(management.typingMessage(user, hasText));
  }
  dispatch({
    type: SENDER_TYPING,
    payload: {
      event: false,
      userId,
      text,
    },
  });
};
