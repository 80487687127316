import './ProgressItem.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import NumberItem from '../NumberItem';
import DashedProgressWrapper from './DashedProgressWrapper/DashedProgressWrapper';
import FilledProgressWrapper from './FilledProgressWrapper/FilledProgressWrapper';

function ProgressItem({
  data = '',
  percent,
  value,
  location,
  type,
  leftText,
  rightText,
}) {
  const progressItemDiv = classNames({
    'table-progress-item-div': location !== 'dashboard',
    'dashboard-progress-item-div': location === 'dashboard',
  });

  const progressWrapperDiv = classNames('progress-wrapper-div');

  const rightTextDiv = classNames('right-text-div', {
    'green-text bold': type === 'dashed',
  });
  return (
    <div className={progressItemDiv}>
      {location === 'dashboard' && (
        <div className="left-text-div">{leftText}</div>
      )}
      {type === 'dashed' ? (
        <DashedProgressWrapper
          className={progressWrapperDiv}
          percent={percent}
        />
      ) : (
        <FilledProgressWrapper
          className={progressWrapperDiv}
          percent={percent}
        />
      )}
      {location !== 'dashboard' ? (
        <>
          <div className="percent-value-div">
            <NumberItem number={percent} /> %
          </div>
          <div className="data-value-div">
            <FormattedMessage
              id="PROGRESS_ITEM_LEFT"
              values={{ data, value }}
            />
          </div>
        </>
      ) : (
        <div className={rightTextDiv}>{rightText}</div>
      )}
    </div>
  );
}

export default ProgressItem;

