import { connect } from 'react-redux';

import UserAdvertising from './UserAdvertising';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  advId: state.user?.account?.advId,
  nickname: state.user?.account?.nickname,
});

export default connect(mapStateToProps)(UserAdvertising);
