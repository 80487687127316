import * as webrtcAdapter from '../../../node_modules/webrtc-adapter/out/adapter'; //eslint-disable-line no-unused-vars
import { stream as streamActions } from '../../actions';
import { STREAMING_SERVER_TYPE } from '../../constants';
import { CHANGE_BANDWIDTH_ERROR } from '../../errors';
import { stream } from '../../utils';
import { management } from '../';
import { enhanceSDP } from './sdpParser';
import StatsContainer from './StatsContainer';
import VideoStatsContainer from './VideoStatsContainer';

const peerConnectionConfig = { iceServers: [] };
const statsInterval = 1000;

let browser;

let enhanceData = {
  videoBitrate: 300,
  videoFrameRate: 10,
  audioBitrate: 48,
};
let streamInfo = {
  applicationName: '',
  streamName: '',
  sessionId: '',
};

let startDelay = 0;
let peerConnection = null;
let localStream = null;
let wsConnection = null;
let sdpUrl = '';
let dispatch = null;
let statsTimer = null;

let statsRunning = false;
let videoStats = new VideoStatsContainer();
let audioStats = new StatsContainer();

let reconnectCounter = 0;
//@todo move this to database
const reconnectTimeout = 2000;
const reconnectTries = 10;
let track = null;

const stats = () => {
  if (peerConnection && track) {
    peerConnection
      .getStats(track)
      .then((results) => {
        if (!statsRunning) {
          console.log('Stop stats');
          return clearTimeout(statsTimer);
        }
        let audioFound = false;
        let videoFound = false;
        results.forEach((res) => {
          if (res.type === 'outbound-rtp') {
            if (res.mediaType === 'video') {
              videoFound = true;
              videoStats.add(res);
            } else if (res.mediaType === 'audio') {
              audioFound = true;
              audioStats.add(res);
            }
          }
        });
        if (audioFound || videoFound) {
          dispatch(
            streamActions.events.stats({
              bytesSent: videoFound
                ? videoStats.lastBytesSent
                : 0 + audioFound
                ? audioStats.lastBytesSent
                : 0,
              bandwidth: videoFound
                ? videoStats.bandwidth
                : 0 + audioFound
                ? audioStats.bandwidth
                : 0,
              fps: videoFound ? videoStats.fps : 0,
            })
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
const changeBandwidth = (bitrate) => {
  if (peerConnection) {
    if (browser.paramterEncodingAllowed) {
      return peerConnection.getSenders().forEach((sender) => {
        const parameters = sender.getParameters();
        if (!parameters.encodings) {
          parameters.encodings = [{}];
        }
        if (!bitrate) {
          delete parameters.encodings[0].maxBitrate;
        } else {
          parameters.encodings[0].maxBitrate = bitrate * 1000;
        }
        sender
          .setParameters(parameters)
          .then(() => {
            dispatch(streamActions.events.bandwidth(bitrate));
          })
          .catch((e) => {
            management.log.splunk.error('ChangeBandwidth1: ' + e.message);
            dispatch(
              streamActions.events.error(new Error(CHANGE_BANDWIDTH_ERROR))
            );
          });
      });
    }
    peerConnection
      .createOffer()
      .then((offer) => peerConnection.setLocalDescription(offer))
      .then(() => {
        const desc = {
          type: peerConnection.remoteDescription.type,
          sdp: enhanceSDP(
            peerConnection.remoteDescription.sdp,
            { ...enhanceData, videoBitrate: bitrate },
            browser
          ),
        };
        console.log(
          'Applying bandwidth restriction to setRemoteDescription:\n' + desc.sdp
        );
        return peerConnection.setRemoteDescription(desc);
      })
      .then(() => {
        dispatch(streamActions.events.bandwidth(bitrate));
      })
      .catch((e) => {
        management.log.splunk.error('ChangeBandwidth2: ' + e.message);
        dispatch(streamActions.events.error(new Error(CHANGE_BANDWIDTH_ERROR)));
      });
  }
};
const getMsgFromEvent = (evt) => {
  let msgJSON = {};
  try {
    msgJSON = JSON.parse(evt.data);
  } catch (e) {
    console.log(`failed parsing event data onmessage ${e}`);
    msgJSON = {};
  }
  return msgJSON;
};

const reset = () => {
  statsRunning = false;
  clearTimeout(statsTimer);
  videoStats.reset();
  audioStats.reset();
};

const defaultErrorHandler = (error) => {
  console.log(`defaultErrorHandler: ${error.name}`);
  management.log.splunk.error(
    `defaultErrorHandler: ${error.name}, ${error.message}`
  );
  reset();
  dispatch(streamActions.events.error(error));
};

const closeSocket = () => {
  if (!wsConnection) {
    return;
  }
  wsConnection.onopen = null;
  wsConnection.onmessage = null;
  wsConnection.onclose = null;
  wsConnection.onerror = null;
  wsConnection.close();
  wsConnection = null;
};
const resetPeerconnection = () => {
  if (!peerConnection) {
    return;
  }
  peerConnection.oniceconnectionstatechange = null;
  peerConnection.onicecandidate = null;
  const senders = peerConnection.getSenders();
  if (senders && senders.length > 0) {
    peerConnection.removeTrack(senders.shift());
  }
  // close peerConnection, Firefox does close it
  // be itself after a few seconds, but Chrome keeps it open
  peerConnection.close();
  peerConnection = null;
};
const reconnect = (stream) => {
  console.log('WEBRTC reconnect');
  reconnectCounter++;
  dispatch(streamActions.reconnect());
  resetPeerconnection();
  setTimeout(() => {
    if (!management || !management.connected()) {
      return stop();
    }
    publish(stream);
  }, reconnectTimeout);
};

const startStreamHandling = () => {
  peerConnection
    .getStats(track)
    .then((results) => {
      let initialStats = new VideoStatsContainer();
      results.forEach((res) => {
        if (res.type === 'outbound-rtp') {
          if (res.mediaType === 'video') {
            initialStats.add(res);
          }
        }
      });
      reconnectCounter = 0;
      dispatch(
        streamActions.events.publish(
          enhanceData.videoBitrate,
          initialStats.lastBytesSent
        )
      );
      reset();
      statsRunning = true;
      statsTimer = setInterval(stats, statsInterval);
    })
    .catch((error) => {
      console.log(error);
    });
};

const onIceConnectionStateChange = (event) => {
  console.log('iceconnection');
  if (event && event.currentTarget && event.currentTarget.iceConnectionState) {
    console.log(
      `iceconnection changed ${event.currentTarget.iceConnectionState}`
    );
    switch (event.currentTarget.iceConnectionState) {
      case 'closed':
        reset();
        dispatch(streamActions.events.stop());
        break;
      case 'failed':
        reset();
        console.log('iceconnectionstatus: failed Connection');
        if (reconnectCounter < reconnectTries && localStream) {
          console.log(`iceconnection reconnect`);
          management.log.splunk.info(
            `iceconnectionstatus: failed Connection: reconnect try ${reconnectCounter}`
          );
          return reconnect(localStream);
        }
        reconnectCounter = 0;
        management.log.splunk.warn('iceconnectionstatus: failed Connection');
        dispatch(streamActions.events.error(new Error('failed Connection')));
        break;
      case 'disconnected':
        dispatch(streamActions.events.disconnected());
        reset();
        console.log('iceconnectionstatus: lost Connection');
        if (reconnectCounter < reconnectTries && localStream) {
          console.log(`iceconnection reconnect`);
          management.log.splunk.info(
            `iceconnectionstatus: lostConnection: reconnect try ${reconnectCounter}`
          );
          return reconnect(localStream);
        }
        reconnectCounter = 0;
        management.log.splunk.warn('iceconnectionstatus: lostConnection');
        dispatch(streamActions.events.error(new Error('lost Connection')));
        break;
      case 'connected':
        if (reconnectCounter > 0) {
          console.log(`iceconnection:reconnected counter: ${reconnectCounter}`);
          management.log.splunk.info(
            `iceconnection:reconnected counter: ${reconnectCounter}`
          );
        }
        console.log('iceconnection:connected');
        //fix for transcoding is starting...
        if (!startDelay) {
          startStreamHandling();
        } else {
          console.log(`Delaying start stream ${startDelay}`);
          setTimeout(() => {
            if (peerConnection) {
              startStreamHandling();
            }
          }, startDelay);
        }
        break;
      default:
    }
  }
};
const webSocketOnOpen = () => {
  console.log('wsConnection.onopen');

  peerConnection = new RTCPeerConnection(peerConnectionConfig);
  peerConnection.onicecandidate = (event) => {
    if (event.candidate !== null) {
      console.log(
        `gotIceCandidate: ${JSON.stringify({ ice: event.candidate })}`
      );
    }
  };
  peerConnection.oniceconnectionstatechange = onIceConnectionStateChange;
  const localTracks = localStream.getTracks();
  for (let localTrack in localTracks) {
    if (localTracks[localTrack].kind === 'video') {
      track = localTracks[localTrack];
    }
    peerConnection.addTrack(localTracks[localTrack], localStream);
  }

  peerConnection
    .createOffer()
    .then(gotDescription)
    .catch((error) => {
      console.log(`errorHandlerCreateOffer:  ${error.message}`);
      management.log.splunk.warn(`errorHandlerCreateOffer:  ${error.message}`);
      reset();
      dispatch(streamActions.events.error(error));
    });
};

const gotDescription = (description) => {
  const enhancedSdp = enhanceSDP(description.sdp, enhanceData, browser);
  if (!enhancedSdp) {
    return defaultErrorHandler(new Error('H264 not supported'));
  }
  if (!peerConnection) {
    return;
  }
  description.sdp = enhancedSdp;

  console.log('gotDescription: ' + JSON.stringify({ sdp: description }));

  peerConnection
    .setLocalDescription(description)
    .then(() => {
      const msg = {
        direction: 'direction',
        command: 'sendOffer',
        streamInfo: streamInfo,
        sdp: description,
        userData: {},
      };
      wsConnection.send(JSON.stringify(msg));
    })
    .catch(defaultErrorHandler);
};

const webSocketOnMessage = (evt) => {
  console.log('wsConnection.onmessage: \n' + evt.data);
  const msgJSON = getMsgFromEvent(evt);

  if (!peerConnection) {
    return;
  }
  if (Number(msgJSON.status) !== 200) {
    console.log(`Status not 200: ${msgJSON.status}`);
    reset();
    closeSocket();
    if (
      msgJSON.status === 503 &&
      reconnectCounter < reconnectTries &&
      localStream
    ) {
      management.log.splunk.info(`Status not 200: ${msgJSON.status}`);
      return reconnect(localStream);
    }
    management.log.splunk.error(`Status not 200: ${msgJSON.status}`);
    return dispatch(
      streamActions.events.error(new Error(`Status not 200: ${msgJSON.status}`))
    );
  }

  streamInfo.sessionId =
    (msgJSON.streamInfo && msgJSON.streamInfo.sessionId) || '';

  const sdpData = msgJSON.sdp;
  if (sdpData !== undefined) {
    console.log(`sdp: ${sdpData}`);

    peerConnection
      .setRemoteDescription(new RTCSessionDescription(sdpData))
      .catch(defaultErrorHandler);
  }

  const iceCandidates = msgJSON.iceCandidates;
  if (iceCandidates !== undefined) {
    iceCandidates.forEach((iceCandidate) => {
      console.log(`iceCandidates: ${iceCandidate}`);
      peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidate));
    });
  } else {
    management.log.splunk.error('no ice candidates');
    defaultErrorHandler(new Error('no ice candidates'));
  }

  closeSocket();
};

const stop = () => {
  console.log('stop called');
  reset();
  resetPeerconnection();
  closeSocket();
  dispatch && dispatch(streamActions.events.stop());
  console.log('publisher stopped');
};
const test = stream.canH264WebRTC;

const init = (options, dispatchParam) => {
  if (typeof options !== 'object') {
    return;
  }
  browser = options.browser;
  const id = (options.simpleObject && options.simpleObject.key) || '';
  Object.assign(streamInfo, {
    applicationName: options.application || '',
    streamName:
      (options.publishName &&
        `${options.publishName}?type=${STREAMING_SERVER_TYPE}&id=${id}`) ||
      '',
  });
  Object.assign(enhanceData, options.enhanceData);
  sdpUrl = options.sdpUrl || '';
  startDelay = options.startDelay || 0;
  dispatch = dispatchParam;
};

const publish = (stream) => {
  console.log(`Publish stream ${stream}`);
  if (!management.connected()) {
    stop();
    return dispatch(
      streamActions.events.error(new Error('management:notConnected'))
    );
  }
  wsConnection = new WebSocket(sdpUrl);
  wsConnection.binaryType = 'arraybuffer';
  localStream = stream;
  wsConnection.onopen = webSocketOnOpen;
  wsConnection.onmessage = webSocketOnMessage;
  wsConnection.onclose = () => {
    console.log('wsConnection.onclose');
  };
  wsConnection.onerror = (evt) => {
    console.log(`wsConnection.onerror: ${JSON.stringify(evt)}`);
    reset();
    if (reconnectCounter < reconnectTries && localStream) {
      console.log('try to reconnect after connection error' + reconnectCounter);
      management.log.splunk.info(
        `wsConnection.onerror:reconnect counter: ${reconnectCounter}`
      );
      return reconnect(localStream);
    }
    management.log.splunk.warn(`wsConnection.onerror: ${JSON.stringify(evt)}`);
    reconnectCounter = 0;
    dispatch(streamActions.events.error(new Error('wsConnection:error')));
  };
};

const changeResolution = async (resolution) => {
  if (!track) {
    return Promise.reject(new Error('no track'));
  }
  const constraints = track.getConstraints();
  const [width, height] = resolution.split('x');
  constraints.width.exact = width;
  constraints.height.exact = height;

  return track.applyConstraints(constraints);
};

const reconnectWrapper = () => {
  if (localStream) {
    reconnect(localStream);
  }
};

export default {
  init,
  test,
  publish,
  stop,
  changeBandwidth,
  changeResolution,
  reconnect: reconnectWrapper,
};
