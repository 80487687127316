import classNames from 'classnames';
import { useEffect, useRef } from 'react';

import rotateIcon from '../../../../assets/img/svg/rotate-right.svg';
import zoomInIcon from '../../../../assets/img/svg/zoom.svg';
import zoomOutIcon from '../../../../assets/img/svg/zoomout.svg';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Button from '../../../Button/Button';

function Cam2Cam({
  running,
  rotate = 0,
  onRotateClick = () => {},
  onZoomClick = () => {},
  isMobile,
  isEvent = false,
  stream,
  zoom,
}) {
  const video = useRef();
  const rotateClass = classNames({
    [`rotate-${rotate}`]: !isMobile,
    large: zoom,
    small: !zoom,
  });

  const wrapperClasses = classNames('cam2cam-video-wrapper', {
    large: zoom,
  });
  const isLarge = useMediaQuery('(min-width: 1600px)');

  useEffect(() => {
    console.log('cam2cam2 did update');
    console.log({ cam2cam2stream: stream });
    if (video?.current && stream) {
      video.current.srcObject = stream;
    }
  }, [video, stream]);

  return running && !isEvent ? (
    <span className={wrapperClasses}>
      <video
        onClick={onRotateClick}
        className={rotateClass}
        autoPlay
        ref={video}
      />
      {!isMobile && (
        <>
          <Button
            variant="icon-only"
            className="btn-rotate"
            onClick={onRotateClick}
            intlTranslate={false}
            icon={rotateIcon}
          />
          {isLarge && (
            <Button
              variant="icon-only"
              className="btn-zoom"
              onClick={onZoomClick}
              intlTranslate={false}
              icon={zoom ? zoomOutIcon : zoomInIcon}
            />
          )}
        </>
      )}
    </span>
  ) : null;
}

export default Cam2Cam;
