import { updateCam, updateMicrophone } from '../../actions';

export default (err, deviceInfos) => {
  return (dispatch) => {
    if (!err) {
      dispatch(
        updateCam(
          null,
          deviceInfos.filter((device) => device.kind === 'videoinput')
        )
      );
      dispatch(
        updateMicrophone(
          null,
          deviceInfos.filter((device) => device.kind === 'audioinput')
        )
      );
    } else {
      dispatch(updateCam(err));
      dispatch(updateMicrophone(err));
    }
  };
};
