import './SubMenu.scss';

import classNames from 'classnames';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import lovenseSVG from '../../../../assets/img/lovense/lush.svg';
import camSVG from '../../../../assets/img/svg/cam.svg';
import errorSVG from '../../../../assets/img/svg/error.svg';
import exclusiveSVG from '../../../../assets/img/svg/exclusive.svg';
import helpSVG from '../../../../assets/img/svg/question-mark.svg';
import voyeurSVG from '../../../../assets/img/svg/voyeur.svg';
import {
  STREAMING_RECONNECTING,
  STREAMING_STARTED,
} from '../../../../constants';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { lStorage } from '../../../../services/storage';
import Button from '../../../Button/Button';
import StreamingButton from '../../../Livecam/StreamingButtonBar/StreamingButton';
import MessengerHeaderItem from '../../../Menu/MessengerHeaderItem/MessengerHeaderItem';
import MessengerItem from '../../../Menu/MessengerItem/MessengerItem';
import SettingsButton from '../../../SettingsButton';
import TooltipIcon from '../../../TooltipIcon/TooltipIcon';
import { activitiesFilter } from '../Livecam.functions';
import ToycontrolMenu from '../ToycontrolMenu';
import {
  gameFilter,
  messageFilters as messageFiltersWithoutGame,
} from './SubMenu.functions';

function SubMenu({
  exclusive,
  voyeur,
  normal,
  exclusiveEnabled,
  voyeurEnabled,
  step,
  isReconnecting,
  silentReconnect,
  askExclusiveRoomtypeAlert,
  askExclusiveRoomtypeConfirmed,
  changeRoomTypes,
  dialog,
  camUserFilter,
  activityCount,
  onMenuToggle,
  messagesFilter,
  isMobile,
  camInitialized,
  filter,
  toycontrolActive,
  toys,
  toycontrolMenuOpen,
  toggleToycontrolMenu,
  toycontrolEnabled,
  activeToy,
  chevronButton,
  isLivecam,
  isIcebreakerAllowed,
  isIcebreakerEnabled,
  isGameBlocked,
  showCookieHint,
  showLivecamErrorHint,
}) {
  const messageFilters = !isGameBlocked
    ? [...messageFiltersWithoutGame, ...gameFilter]
    : messageFiltersWithoutGame;

  const toycontrolMenuRef = useRef();

  const [messengerOpen, setMessengerOpen] = useState(
    lStorage.getItem('messengerOpen') === 'false' ? false : true
  );
  const [activitiesOpen, setActivitiesOpen] = useState(
    lStorage.getItem('activitiesOpen') === 'false' ? false : true
  );
  const [featuresOpen, setFeaturesOpen] = useState(
    lStorage.getItem('featuresOpen') === 'false' ? false : true
  );

  const intl = useIntl();
  const history = useHistory();
  const isStreaming =
    step === STREAMING_STARTED ||
    step === STREAMING_RECONNECTING ||
    isReconnecting ||
    silentReconnect;

  const classesCam = classNames('cam-icon', 'icon-cam', {
    active: normal,
  });

  const classesVoyeur = classNames('cam-icon', 'icon-voyeur', {
    active: voyeur,
  });

  const classesExclusive = classNames('cam-icon', 'icon-exclusive', {
    active: exclusive,
  });
  useEffect(() => {
    if (askExclusiveRoomtypeAlert !== '' && askExclusiveRoomtypeConfirmed) {
      changeRoomTypes(!exclusive);
    }
  }, [
    askExclusiveRoomtypeAlert,
    askExclusiveRoomtypeConfirmed,
    exclusive,
    changeRoomTypes,
  ]);
  const tooltipNormalShow = intl.formatMessage({ id: 'tooltip.normalShow' });
  const tooltipVoyeurShow = intl.formatMessage({ id: 'tooltip.voyeurShow' });
  const tooltipExclusiveShow = intl.formatMessage({
    id: 'tooltip.exclusiveShow',
  });

  const setMessagesFilter = (filter) => {
    messagesFilter(filter);
  };

  const activitiesFilterList = activitiesFilter.map((item) => {
    if (item.type === 'separator') {
      return <hr key={item.id} />;
    }
    return (
      <MessengerItem
        key={`messages-filter-${item.id}`}
        item={item}
        onClick={() => setMessagesFilter(item.id)}
        isActive={filter === item.id}
        additionalContent={item.filter(camUserFilter)}
        onMenuToggle={onMenuToggle}
        isMobile={isMobile}
      />
    );
  });
  const messageFiltersList = messageFilters.map((item) => {
    if (item.type === 'separator') {
      return <hr key={item.id} />;
    }
    return (
      <MessengerItem
        key={`messages-filter-${item.id}`}
        item={item}
        onClick={() => setMessagesFilter(item.id)}
        isActive={filter === item.id}
        additionalContent={item.filter(dialog)}
        onMenuToggle={onMenuToggle}
        isMobile={isMobile}
      />
    );
  });

  const toyKeys = Object.keys(toys);
  const toysArray = toyKeys.map((key) => toys[key]);
  const hasActiveToy = toysArray.find((toy) => parseInt(toy?.status, 10) === 1);
  const classesToy = classNames('icon-toycontrol', {
    active: hasActiveToy && !!activeToy,
  });

  useOutsideClick(toycontrolMenuRef, () => {
    if (toycontrolMenuOpen) {
      toggleToycontrolMenu();
    }
  });

  const clickToycontrol = hasActiveToy
    ? toggleToycontrolMenu
    : (e) => {
        e.preventDefault();
        e.stopPropagation();
      };

  const toycontrolButtonClasses = classNames('item', 'item-toycontrol', {
    active: hasActiveToy,
  });

  return (
    <>
      <div className="submenu-toggle messenger">
        <MessengerHeaderItem
          title="MENU_HEADER_MESSENGER"
          open={messengerOpen}
          onClick={() => {
            const newState = !messengerOpen;
            lStorage.setItem('messengerOpen', newState);
            setMessengerOpen(newState);
          }}
          chevronButton={chevronButton}
        />
        {messengerOpen && messageFiltersList}
        <hr />
      </div>

      <div className="submenu-toggle activities">
        <MessengerHeaderItem
          title="MENU_HEADER_ACTIVITIES"
          open={activitiesOpen}
          onClick={() => {
            const newState = !activitiesOpen;
            lStorage.setItem('activitiesOpen', newState);
            setActivitiesOpen(newState);
          }}
          additionalNumber={activityCount}
        />
        {activitiesOpen && activitiesFilterList}
        <hr />
      </div>

      {(isLivecam || isIcebreakerAllowed) && (
        <div className="submenu-toggle features">
          {' '}
          <MessengerHeaderItem
            title="MENU_HEADER_FEATURES"
            open={featuresOpen}
            onClick={() => {
              const newState = !featuresOpen;
              lStorage.setItem('featuresOpen', newState);
              setFeaturesOpen(newState);
            }}
          />
          {featuresOpen && isIcebreakerAllowed && (
            <div className="item item-icebreaker">
              <span className="menu-name">Icebreaker</span>
              <span className="additional-content">
                {intl.formatMessage({
                  id: isIcebreakerEnabled
                    ? 'ICEBREAKER_ACTIVE'
                    : 'ICEBREAKER_INACTIVE',
                })}
              </span>
            </div>
          )}
          {featuresOpen && isLivecam && (
            <>
              {camInitialized &&
                toycontrolActive &&
                toycontrolEnabled &&
                isStreaming && (
                  <>
                    <div
                      className={toycontrolButtonClasses}
                      onClick={clickToycontrol}
                    >
                      {intl.formatMessage({ id: 'title.toycontrol' })}
                      <div className="icon-wrapper">
                        <ReactSVG
                          src={lovenseSVG}
                          className={classesToy}
                          wrapper={'span'}
                          disabled={toysArray.length < 2}
                        />
                      </div>
                    </div>
                    {toycontrolMenuOpen && toysArray.length > 0 && (
                      <div
                        ref={toycontrolMenuRef}
                        className="toggle-menu toycontrol-menu"
                      >
                        <ToycontrolMenu close={toggleToycontrolMenu} />
                      </div>
                    )}
                  </>
                )}
              {isLivecam && (
                <div className="item item-livecam">
                  {intl.formatMessage({ id: 'title.livecam' })}
                  {!isStreaming && (
                    <div className="cam-icons">
                      <span className="icon-wrapper">
                        <SettingsButton
                          onClick={() =>
                            history.push('/my-account/settings/livecam')
                          }
                        />
                      </span>
                      <span className="icon-wrapper">
                        <Button
                          intlTranslate={false}
                          icon={helpSVG}
                          variant="icon-only"
                          className="help-btn"
                          onClick={() =>
                            history.push('/wiki/general#livecamFaq')
                          }
                        />
                      </span>

                      {showCookieHint && (
                        <div className="icon-wrapper tooltip-red">
                          <TooltipIcon
                            tooltip={intl.formatMessage({
                              id: 'login.cookie3rdHint',
                            })}
                            icon={errorSVG}
                            position="right"
                            btnClassName="error-btn"
                          />
                        </div>
                      )}
                      {!showCookieHint && showLivecamErrorHint && (
                        <div className="icon-wrapper tooltip-red">
                          <TooltipIcon
                            tooltip={intl.formatMessage({
                              id: 'login.livecamErrorHint',
                            })}
                            icon={errorSVG}
                            position="right"
                            btnClassName="error-btn"
                          />
                        </div>
                      )}
                    </div>
                  )}

                  {isStreaming && (
                    <div className="cam-icons">
                      <div className="icon-wrapper" title={tooltipNormalShow}>
                        <ReactSVG
                          src={camSVG}
                          className={classesCam}
                          wrapper={'span'}
                        />
                      </div>
                      {voyeurEnabled && (
                        <div className="icon-wrapper" title={tooltipVoyeurShow}>
                          <ReactSVG
                            src={voyeurSVG}
                            className={classesVoyeur}
                            wrapper={'span'}
                          />
                        </div>
                      )}
                      {exclusiveEnabled && (
                        <div
                          className="icon-wrapper"
                          title={tooltipExclusiveShow}
                        >
                          <ReactSVG
                            src={exclusiveSVG}
                            className={classesExclusive}
                            wrapper={'span'}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              {camInitialized && <StreamingButton />}
            </>
          )}
          <hr />
        </div>
      )}
    </>
  );
}

export default SubMenu;
