import { useIntl } from 'react-intl';

import UserProfileInfoItem from './UserProfileInfoItem';

function UserProfileInfo({
  nickname,
  largeLoaded,
  age,
  gender,
  country,
  zip_code,
  languages,
  is_support,
}) {
  const intl = useIntl();
  if (!nickname) {
    return null;
  }

  const countryEntry = `${zip_code ? zip_code : ''}${
    zip_code && country && country !== '-' ? ' | ' : ''
  }${
    country && country !== '-'
      ? intl.formatMessage({ id: country.toUpperCase() })
      : ''
  }`;
  return (
    <div className="area user-info">
      {is_support ? (
        <div className="bold">
          {intl.formatMessage({ id: 'user.isSupport' })}
        </div>
      ) : (
        <table className="noHover">
          <tbody>
            <UserProfileInfoItem
              label="user.age"
              value={age}
              postfix={` ${intl.formatMessage({ id: 'user.age.years' })}`}
              translate={false}
              largeLoaded={largeLoaded}
              showSpinner={true}
            />
            <UserProfileInfoItem
              label="user.gender"
              value={gender}
              largeLoaded={largeLoaded}
            />
            <UserProfileInfoItem
              label="user.country"
              value={countryEntry}
              translate={false}
              largeLoaded={largeLoaded}
            />
            <UserProfileInfoItem
              label="user.language"
              value={
                languages?.length && intl.formatMessage({ id: languages[0] })
              }
              translate={false}
              largeLoaded={largeLoaded}
            />
          </tbody>
        </table>
      )}
    </div>
  );
}

export default UserProfileInfo;
