import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  userAddAuthToken as addAuthToken,
  auth,
  cookie,
  userLogout as logout,
} from '../../../../actions';
import Form from './Form';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  cookies: state.browser.cookiesAllowed,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addAuthToken,
      cookie,
      logout,
    },
    dispatch
  ),
  startAuth: (interval) => dispatch(auth.start(interval)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);

