import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import CamStopDE from '../../../../../assets/img/wiki/wiki_messenger_features_cam_de.png';
import CamStopEN from '../../../../../assets/img/wiki/wiki_messenger_features_cam_en.png';
import CamStartDE from '../../../../../assets/img/wiki/wiki_messenger_features_camOff_de.png';
import CamStartEN from '../../../../../assets/img/wiki/wiki_messenger_features_camOff_en.png';
import NoCamDE from '../../../../../assets/img/wiki/wiki_messenger_features_noCam_de.png';
import NoCamEN from '../../../../../assets/img/wiki/wiki_messenger_features_noCam_en.png';
import ToyDE from '../../../../../assets/img/wiki/wiki_messenger_features_toy_de.png';
import ToyEN from '../../../../../assets/img/wiki/wiki_messenger_features_toy_en.png';
import BackButton from '../../../../BackButton';

function MultiMessenger({
  unconfirmed,
  nickname,
  locale,
  onBackButtonClicked,
}) {
  const intl = useIntl();
  const account = nickname
    ? nickname
    : intl.formatMessage({ id: 'LINK_TEXT_ACCOUNT' });

  return (
    <div className="wiki-content multi-messenger-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="MULTI_MESSENGER_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="MULTI_MESSENGER_INTRO" />
      </p>
      <p className="bold">
        <FormattedMessage id="MULTI_MESSENGER_INTRO_1">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="MULTI_MESSENGER_INTRO_2" />
      </p>
      <p>
        <FormattedMessage id="MULTI_MESSENGER_INTRO_3">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <h2 className="headline">
        <FormattedMessage id="MULTI_MESSENGER_HEADLINE_1" />
      </h2>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_1_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_1_1_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_2_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_2_1_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_3_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_3_1_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_4" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_4_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">5</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_5" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_5_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_5_1_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">6</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_6" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_6_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">7</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_7" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="MULTI_MESSENGER_FILTER_STEP_7_CONTENT"
              values={{
                link: (
                  <Link to="/wiki/moneymakers#fourScore">
                    <FormattedMessage id="MULTI_MESSENGER_FILTER_STEP_7_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>

      <h2 className="headline">
        <FormattedMessage id="MULTI_MESSENGER_HEADLINE_2" />
      </h2>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_1_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_1_1_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_2_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_3_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_4" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_4_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">5</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_5" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_ACT_STEP_5_CONTENT" />
          </p>
        </div>
      </div>

      <h3 className="headline">
        <FormattedMessage id="MULTI_MESSENGER_HEADLINE_3" />
      </h3>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FEAT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FEAT_STEP_1_CONTENT" />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img
                className="img-responsive"
                src={NoCamEN}
                alt="Features no cam"
              />
            ) : (
              <img
                className="img-responsive"
                src={NoCamDE}
                alt="Features ohne Cam"
              />
            )}
          </div>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FEAT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="MULTI_MESSENGER_FEAT_STEP_2_CONTENT"
              values={{
                link: (
                  <Link to="/wiki/general#lovenseInstallation">
                    <FormattedMessage id="MULTI_MESSENGER_FEAT_STEP_2_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img className="img-responsive" src={ToyEN} alt="Features toy" />
            ) : (
              <img className="img-responsive" src={ToyDE} alt="Features Toy" />
            )}
          </div>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="MULTI_MESSENGER_FEAT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="MULTI_MESSENGER_FEAT_STEP_3_CONTENT"
              values={{
                link: unconfirmed ? (
                  <FormattedMessage
                    id="LINK_TEXT_CAM_SETTINGS"
                    values={{ account }}
                  />
                ) : (
                  <Link to="/my-account/settings/livecam">
                    <FormattedMessage
                      id="LINK_TEXT_CAM_SETTINGS"
                      values={{ account }}
                    />
                  </Link>
                ),
              }}
            />
          </p>
          <div className="image-wrapper two-images">
            <div>
              {locale === 'en' ? (
                <img
                  className="img-responsive"
                  src={CamStartEN}
                  alt="Features cam start"
                />
              ) : (
                <img
                  className="img-responsive"
                  src={CamStartDE}
                  alt="Features Cam starten"
                />
              )}
            </div>
            <div>
              {locale === 'en' ? (
                <img
                  className="img-responsive"
                  src={CamStopEN}
                  alt="Features cam stop"
                />
              ) : (
                <img
                  className="img-responsive"
                  src={CamStopDE}
                  alt="Features Cam stoppen"
                />
              )}
            </div>
          </div>
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FEAT_STEP_3_1_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage id="MULTI_MESSENGER_FEAT_STEP_3_2_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage
              id="MULTI_MESSENGER_FEAT_STEP_3_3_CONTENT"
              values={{
                link: (
                  <Link to="/wiki/general#livecamFaq">
                    <FormattedMessage id="MULTI_MESSENGER_FEAT_STEP_3_3_CONTENT_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
export default MultiMessenger;
