export default {
  ROUTE_FMOTD_HEADLINE:
    'Take the opportunity to draw the attention of 100,000 users to you',
  FMOTD_INFO_HEADLINE_1: 'Your hotspot for 100,000+ users every day',
  FMOTD_INFO_TEXT_1:
    'Secure one of the most important advertising spaces in our community now! <span class="italic">Free Movie of the Day</span> enables you to reach more than 10,000 new customers and well over 100,000 daily active existing customers. Absolutely prominent and exclusive!',
  FMOTD_INFO_TEXT_2:
    'Apply with a Premium Movie* and secure the coveted advertising hotspot in the form of an XXL banner directly on the homepage and for another 14 days in the <span class="italic">Free Movie of the Day</span> area.',
  FMOTD_INFO_TEXT_3:
    '<span class="bold">The best thing:</span> you can apply as often as you like. The best content wins the contract!',
  FMOTD_INFO_TEXT_4:
    'Don\'t waste time or money! Apply now for <span class="italic">Free Movie of the Day</span>!',
  FMOTD_INFO_TEXT_5:
    '* The application must contain a paid, high-resolution premium movie with a minimum length of 5 minutes. If your application is successful, this movie will be made available free of charge for a full 14 days in the <span class="italic">Free Movie of the Day</span> area.',
  FMOTD_INFO_TEXT_6:
    ' Whether and at what point in time the video is accepted for the  <span class="italic">Free Movie of the Day</span> area is entirely up to the portal operator to decide. We reserve the right to reject applications without comment. If you have any questions, our Support will be happy to help.',
  FMOTD_NO_GALLERY_SET: `No gallery set`,
  FMOTD_APPLY_NOW: `Apply now`,
  FMOTD_TABLE_HEADLINE: `Apply for Free Movie of the Day`,
  FMOTD_TABLE_HEADLINE_SUB:
    '<span class="bold">Note:</span> You can only choose galleries that are published and that are not offered free.',
  FMOTD_BADGE_ARCHIVE: 'Free Movie of the Day',
  FMOTD_BADGE_CURRENT: 'Current Free Movie of the Day',
  FMOTD_BADGE_SCHEDULE: 'Schedule',
  FMOTD_BADGE_APPLIED: 'In Review',
  FMOTD_VIEWS: 'views',
  FMOTD_USERS: 'users',
  FMOTD_MENU_DELETE: 'Delete application',
  FMOTD_MENU_EDIT: 'Edit application',
  FMOTD_AVAILABLE_GALLERIES: 'Available galleries',
  FMOTD_NO_GALLERIES: 'No galleries available! Please check your {link}.',
  FMOTD_GALLERIES: 'galleries',
  FMOTD_APPLY: 'Apply',
  FMOTD_REPLACE: 'Replace:',
  FMOTD_DECLINED: 'Your application has been declined',
  FMOTD_NO_THUMBNAIL: 'Please {link} a new gallery preview image!',
  FMOTD_NO_THUMBNAIL_UPLOAD: 'upload',
};
