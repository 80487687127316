import './MyPersonalDetails.scss';

import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import Scrollbar from '../../Scrollbar';

function MyPersonalDetails({ initialValues = {}, items }) {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const routes = items.map((route, i) => {
    return (
      <Route
        key={`/my-account/my-personal-details${route.path}`}
        path={`/my-account/my-personal-details${route.path}`}
        component={route.component}
      />
    );
  });
  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-account-wrapper my-personal-details-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <Formik initialValues={initialValues}>
              <Switch>
                {routes}
                <Redirect to="/" />
              </Switch>
            </Formik>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            <h1 className="headline">
              <FormattedMessage id="PERSONAL_DATA_INFO_HEADLINE" />
            </h1>
            <p>
              <FormattedMessage id="PERSONAL_DATA_INFO_CONTENT_1" />
            </p>
            <p>
              <FormattedMessage id="PERSONAL_DATA_INFO_CONTENT_2" />
            </p>
            <p>
              <FormattedMessage id="PERSONAL_DATA_INFO_CONTENT_3" />
            </p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default MyPersonalDetails;

