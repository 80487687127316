export default {
  LEGAL_TERMS: 'Terms of Service',
  LEGAL_TERMS_HEADLINE: 'General Terms and Conditions for Performers',
  LEGAL_TERMS_INTRO: `The following Terms and Conditions govern the contractual relationship between the IP Broadcasting B.V., Lottumseweg 43, 5971 BV Grubbenvorst, Netherlands (hereinafter: IPB) and the customers of its tele-media services (hereinafter: performer). They define the conditions under which the services of the IPB are used and serve the security of communication of performers and users of the services of IPB.`,

  LEGAL_TERMS_SUBJECT: 'Subject of the contract; scope of services',
  LEGAL_TERMS_SUBJECT_1: `IPB offers the performers the ability to create a profile on a central database system operated by IPB. The created profile enables the performers to upload data material, such as, but not limited to, photographs, cinematographic works and texts, on the central database system operated by IPB. Third parties who have registered as users on various portals operated by IPB (hereinafter: users) can then access this data material. Access to data can be subject to a user fee. In this case, the performer is entitled to a commission. Users can continue to contact the performers via the Web page internal message system (Messenger), as well as via webcam and partly by telephone or SMS; these services can also be subject to a charge for the user and justify a claim to a commission for the performer.`,
  LEGAL_TERMS_SUBJECT_2: `In addition to this portal, IPB operates portals under other names and other domains. To be able to provide the performer with the maximum number of users, IPB allows the users of the respective portals to access the profiles of performers across the service. For this reason, IPB also advertises the profiles of the performers on partner sites of other providers.`,
  LEGAL_TERMS_SUBJECT_3: `Temporary errors or interruptions of the services provided by IPB may occur due to force majeure or to technical changes (maintenance, interruptions for offline backups, software updates). IPB will make all reasonable efforts to ensure the uninterrupted call-up of services. The availability of the services is limited to accessibility with minor impairments.`,
  LEGAL_TERMS_SUBJECT_3_1: `Impairment of the availability of the services is deemed minor if the disturbance or interruption does not exceed 24 hours in a month.`,
  LEGAL_TERMS_SUBJECT_4: `IPB reserves the right to amend the performance and service description if the amendment is due to legal or regulatory requirements or if an adaptation to the technical state of the art is necessary, insofar as the change does not vary significantly from the original performance or service description and the performer is not disadvantaged by this.`,
  LEGAL_TERMS_SUBJECT_5: `IPB may use externally mandated service providers to offer its tele-media services. This includes in particular payment providers, content providers, senders of newsletters and debt collection agencies. Externally mandated service providers that perform services on behalf of IPB are not considered third parties within the meaning of these Terms and Conditions. IPB assumes no responsibility for the accuracy and reliability of information, opinions or other communications from such providers.`,

  LEGAL_TERMS_CONCLUSION: 'Conclusion of the contract and access',
  LEGAL_TERMS_CONCLUSION_1: `Access to the content area of IPB requires registration with a personal password chosen by the performer and the user name chosen by the performer. With the log-on, the performer goes live and can make use of the services of IPB.`,
  LEGAL_TERMS_CONCLUSION_2: `After the initial upload of his or her data material, the performer receives notification by email that his or her data has been input. The contract is concluded with the confirmation of this email by the performer.`,
  LEGAL_TERMS_CONCLUSION_3: `Persons who are not of legal age are excluded from using the services of IPB. Proof of the age of majority and identity must be submitted in writing (two-sided copy of identity card, passport or driving licence). For data material that includes more persons than solely the performer, the proof of age must be provided in the aforementioned form for all participating persons. Misrepresentation in the proof of age shall lead in all cases to the immediate blocking of access and retention of all revenues generated for the performer.`,
  LEGAL_TERMS_CONCLUSION_4: `Persons acting on behalf of a third party are excluded from using the services of IPB.`,
  LEGAL_TERMS_CONCLUSION_5: `By registering, the performer confirms the inclusion of these General Terms and Conditions. This creates a contractual relationship between IPB and the performer.`,
  LEGAL_TERMS_CONCLUSION_6: `There is no legal entitlement to registration as a performer. IPB is entitled to exclude performers from participating or to reject them at any time without stating reasons. IPB has the right at any time to block the access of a performer if the IPB system is being used illegally by the performer, and in particular – but not exclusively – for breach of the obligations listed in section VI.`,

  LEGAL_TERMS_IPB_SERVICES: 'Services provided by IPB',
  LEGAL_TERMS_IPB_SERVICES_1:
    'With access to the database, IPB makes available an IT service and is not responsible for any success.',

  LEGAL_TERMS_PERFORMER_SERVICES: 'Services of the performers',
  LEGAL_TERMS_PERFORMER_SERVICES_1: `The performer shall be available to act as a content provider for IPB.`,
  LEGAL_TERMS_PERFORMER_SERVICES_2: `The performer can prepare data material on his or her profile for access by third parties. Data material are in particular
  <ul>
    <li>Photographs and photographic works,</li>
    <li>Cinematographic works,</li>
    <li>Editorial texts on the profile of the performer (hereinafter: blog) and</li>
    <li>Cam-shows</li>
  </ul>`,
  LEGAL_TERMS_PERFORMER_SERVICES_3: `The performer can also contact a user through the Messenger, a webcam, and partly by phone or by SMS.`,

  LEGAL_TERMS_RENUMERATION: 'Remuneration of the performers',
  LEGAL_TERMS_RENUMERATION_1: `If a user accesses the data material provided by the performers, he must pay the price determined by the performer. From the proceeds so obtained the actor/ performer receives a commission of up to 32% of the net revenue.`,
  LEGAL_TERMS_RENUMERATION_1_1: `Commission claims arise as follows:
  <ol>
    <li>If the gross revenue of an actor/ performer exceeds Euro 10.000 per accounting period (14 days), the actor receives 32% of the revenue generated.</li>
    <li>If the gross revenue of an actor/ performer exceeds Euro 5.000 per accounting period (14 days), the actor receives 31% of the revenue generated.</li>
    <li>If the gross revenue of an actor/ performer exceeds Euro 2.500 per accounting period (14 days), the actor receives 30% of the revenue generated.</li>
    <li>If the gross revenue of an actor/ performer exceeds Euro 999,90 per accounting period (14 days), the actor receives 27,5% of the revenue generated.</li>
    <li>If the gross revenue of an actor/ performer exceeds Euro 200 per accounting period (14 days), the actor receives 25% of the revenue generated.</li>
  </ol>`,
  LEGAL_TERMS_RENUMERATION_1_2: `As assessment basis for the commission claims the respective net proceeds are used, which were obtained by contents of the actor per accounting period (14 days). In the field of value-added telephone services and/or premium SMS, the basis of assessment is the net distribution of the network operators to IPB. With other forms of evaluation, the basis of assessment of the commission is always the net sum, which is paid by users or partners (third programs, payment providers, etc.) to IPB.`,
  LEGAL_TERMS_RENUMERATION_2: `The actor/ performer can achieve a further additional commission claim of 25% of the net revenues of a user. The requirement for commission is justified by the fact that the user is recruited by the actor over one of IPB generated and made available Affiliate-Hyperlink.`,
  LEGAL_TERMS_RENUMERATION_3: `An example for a calculation:<br />
  If Euro 1210 Euro gross revenues have been achieved in an accounting period, Euro 1000 net revenues remain. Therefore, the actor/ performer gets a commission claim of Euro 275 after the accounting period. If the net total revenue of the actor/ performer exceeds Euro 20.000 (gross revenue of Euro 24200) in an accounting period, the actor/ performer receives a commission claim of Euro 6.400 from this revenue.`,
  LEGAL_TERMS_RENUMERATION_3_1: `If the performer solicited the user through an affiliate link, this turnover gives rise to a further claim for commission of Euro 250.`,
  LEGAL_TERMS_RENUMERATION_4: `The commissions are calculated respectively on the 1st and 16th of the month for the previous accounting period. The accounting period for the settlement on the 1st of the month is the period from the 16th to the last day of the previous month. The accounting period for the settlement on the 16th of the month is the period from the 1st to the 15th of the previous month. IPB shall create a written commission settlement for the performer and send it by post or provide it for download in the performer area.`,
  LEGAL_TERMS_RENUMERATION_5: `IPB only pays commissions when a minimum settlement amount of 50 Euro is reached. For this purpose an account within the Euro Payments Area (SEPA) is required. If the commission amount of 50 Euro is not reached within one billing period, the payment will be transferred to the next billing period.`,
  LEGAL_TERMS_RENUMERATION_5_1: `Payments to accounts outside the aforementioned SEPA area are only made for settlement amounts of 250 Euro and above. If the commission amount of 250 Euro is not reached within one billing period, the payment will be transferred to the next billing period.`,
  LEGAL_TERMS_RENUMERATION_6: `The commission is disbursed net, without VAT. For Dutch performers, VAT is paid out exclusively to those who can furnish corresponding proof of sales tax liability.`,
  LEGAL_TERMS_RENUMERATION_7: `The payout of the commission is made to performers who are registered through an Studio account via the operator of the Studio account. In this case, IPB directly pays the total commission amount to the operator of the Studio account. Performers do not have a payout claim against IPB in this scenario.`,
  LEGAL_TERMS_RENUMERATION_8: `IPB reserves the right to pay out so-called "freecoins" to users through advertising campaigns. There are no revenues achieved by freecoins. If the content provided by the performers as well as cam shows are paid with "freecoins", the actors are not entitled to a commission.`,
  LEGAL_TERMS_RENUMERATION_9: `Performers can generate additional revenues by advertising new performers using their individual advertising ID. The commission share of the respective sales of the recruited performers is up to 12% according to the following table:`,
  LEGAL_TERMS_RENUMERATION_9_1: `
    <table class="has-border noHover table400">
       <tr>
          <td>5.0 % commission</td>
          <td class="align-right">sales up to 2 499.99 EUR</td>
       </tr>
       <tr>
          <td>7.5 % commission</td>
          <td class="align-right">sales from 2 500.00 EUR</td>
       </tr>
       <tr>
          <td>10.0 % commission</td>
          <td class="align-right">sales from 7 000.00 EUR</td>
       </tr>
       <tr>
          <td>12.0 % commission</td>
          <td class="align-right">sales from 10 000.00 EUR</td>
       </tr>
     </table>`,
  LEGAL_TERMS_RENUMERATION_9_2: `In case of several recruited performers, the commission share is determined by the sales of the respective performer and not by the total sales of all recruited performers.`,
  LEGAL_TERMS_RENUMERATION_9_3: `The payment of the commission to the recruiting Performer shall be made by IPB at the end of each 14-day settlement period (see also V. No. 1). The payment of the commission to the recruited performers is based on V. No. 1, the recruited performer is therefore remunerated regularly and has no losses due to the commission distribution to the recruiting performer.`,

  LEGAL_TERMS_OBLIGATIONS: 'Obligations and responsibilities of the performers',
  LEGAL_TERMS_OBLIGATIONS_1: `The performer is solely responsible for the content of his or her data material and for the other information that he provides about himself or herself. He or she assures that the data are truthful. The performer furthermore assures that he or she owns all necessary rights to use the data material uploaded, in particular to have obtained the permissions of the rights holders and, if necessary, of further persons appearing in the data material.`,
  LEGAL_TERMS_OBLIGATIONS_2: `The performer furthermore shall not abuse or breach the legal provisions concerning the services of IPB, in particular, shall not
  <ol>
    <li>disseminate any defamatory, offensive, or otherwise illegal material or information of such kind about third parties;</li>
    <li>make available or disseminate illegal pornography, in particular child pornography;</li>
    <li>use these services to threaten or harass other persons or violate the legal rights (including personal rights) of third parties;</li>
    <li>introduce into the system or the database any data that contains a virus or contains software or other material that is protected by copyright or by other proprietary rights, unless the user is the owner of the respective rights or has the necessary consent to use them;</li>
    <li>use these services in a way that adversely affects the availability of services for other performers and users;</li>
    <li>send any messages that serve a commercial purpose, and</li>
    <li>mention in the personal description any names, addresses, telephone or fax numbers, Messenger addresses, emails or URLs;</li>
    <li>collect, store, or process any personal data about other users or performers.</li>
  </ol>`,
  LEGAL_TERMS_OBLIGATIONS_3: `The Performer is also prohibited from causing damage to IPB through unfair competitive practices. In this case, the attempt is sufficient; proof of damage by IPB is not required in either case. The performer does not have to suffer a direct pecuniary advantage as a result of the anti-competitive conduct; acting for and in favor of third parties is also expressly included.`,
  LEGAL_TERMS_OBLIGATIONS_3_1: `Unfair competitive practices include, but are not limited to:
  <ol>
    <li>Soliciting performers or users for other platforms, agencies or business models not operated by IPB (e.g. by contacting them via e-mail, chats or telephone or advertising by means of banners, search engine keywords, etc.).</li>
    <li>Promoting other platforms, agencies or business models on livecreator.com and/or through communication channels provided by IPB and/or contacting Performers, Users or third parties facilitated by IPB.</li>
    <li>Bidding on relevant search terms/keywords of IPB (e.g. "livecreator") on search engines (e.g. Google, Bing, Yandex) with the aim of redirecting traffic to another website ("brand bidding")</li>
    <li>By imitating the visual design (UX & UI) and/or using similar or identical domains to deceive other performers or users (via the operator of the website) and to induce them to register on another platform or to register on Livecreator.com using an affiliate link in order to receive a commission on the sales of these performers in the context of an affiliate program ("Brand Counterfeiting") in violation of the rules.</li>
  </ol>`,
  LEGAL_TERMS_OBLIGATIONS_3_2: `In the aforementioned cases, IPB shall be entitled to terminate the contract with the Performer immediately and without notice to the Performer. Claims of the Performer against IPB shall be frozen and already paid revenues may be reclaimed by IPB.`,
  LEGAL_TERMS_OBLIGATIONS_3_3: `In addition, the Performer undertakes to pay a contractual penalty, the amount of which is left to the discretion of the competent court, but at least EUR 5,000.00 per competition violation.`,
  LEGAL_TERMS_OBLIGATIONS_3_4: `In simple cases of minor extent, in which a negligent violation can be assumed after consultation with the Performer, a warning shall be issued instead of the termination of the contract and the withholding of commissions. Whether a simple case is given, is solely at the discretion of IPB and is not contestable.`,
  LEGAL_TERMS_OBLIGATIONS_4: `The performer is not permitted to link users of IPB to other Internet pages or to draw their attention to them or to other systems such as telephone premium services or to advertise such systems in his or her uploaded data material.`,
  LEGAL_TERMS_OBLIGATIONS_5: `The active promotion of competitors’ pages entitles IPB to the immediate exclusion of the performer and retaining all revenues generated by the performer.`,
  LEGAL_TERMS_OBLIGATIONS_6: `The performer is expressly prohibited from fraudulently employing persons, equipment, agreements or other aids. IPB may make decisions at its own discretion on how to deal with fraudulent activities. If fraudulent activities are suspected, IPB is entitled to freeze commissions until a review is carried out and, if the suspicion is confirmed, to refuse payment. The performer can submit in writing a reasoned objection against such a decision within 10 days.`,
  LEGAL_TERMS_OBLIGATIONS_7: `The performer is further not permitted to refer already registered users to his affiliate link in accordance with V point 2 and to urge users to make another registration to obtain the commission in accordance with V point 2.`,
  LEGAL_TERMS_OBLIGATIONS_8: `The performer is prohibited from registering as a user.`,
  LEGAL_TERMS_OBLIGATIONS_9: `The performer is responsible for the content of the blog posts on his or her profile page. The performer can be held liable for untruthful assertions by third parties that damage the business interests of IPB. If IPB must be held liable for the consequences of the allegations made in the blog, the performer shall indemnify IPB against all costs and compensate IPB for any damage sustained by IPB. The performer assures that the contents of his blog do not infringe any intellectual property rights or other rights of third parties.`,
  LEGAL_TERMS_OBLIGATIONS_10: `Non-compliance with one of the obligations of conduct referred to in this section may lead to immediate termination without notice by IPB of the contractual relationship and may also entail civil and criminal consequences. IPB reserves the right in this context to exclude the performer from its services.`,
  LEGAL_TERMS_OBLIGATIONS_11: `If the performer links business interests contrary to the provisions of these General Terms and Conditions with the conclusion of the contract, in particular uses third-party data made available to him by IPB for commercial purposes, he or she shall pay IPB a contractual penalty of Euro 5,001.00 for every proven violation. Any further claims for damages remain hereby unaffected.`,
  LEGAL_TERMS_OBLIGATIONS_12: `You agree not to use the service for any illegal purpose, or for the transmission or posting of any digital information including, but not limited to, text, images, graphics, and audio/video/audio-video recordings (the Content) that is unlawful, harassing, libelous, abusive, threatening or obscene or that that constitutes an infringement/invasion of the rights of privacy or publicity of any individual or entity.`,
  LEGAL_TERMS_OBLIGATIONS_12_1: `YOU ARE STRICTLY PROHIBITED FROM USING THE SERVICE TO OFFER, ADVERTISE, OR PROMOTE PROSTITUTION, SEX TRAFFICKING, OR UNLAWFUL SEX ACTS BY FORCE, FRAUD OR COERCION.`,

  LEGAL_TERMS_LIABILITY: 'Liability',
  LEGAL_TERMS_LIABILITY_1: `Since IPB has no influence on the transport of data over the Internet outside of its own IT infrastructure, as well as on account of the idiosyncrasies and uncertainties of the Internet, IPB can assume no liability for such external data loss and/or errors that may occur during data transmission. IPB is not responsible for any failures of the offer, the causes of which lie outside the sphere of influence of IPB (e.g. due to force majeure or technical disruptions of the Internet).`,
  LEGAL_TERMS_LIABILITY_2: `IPB assumes no liability for the misuse of information that the performer has made available to users or to other third parties. IPB is similarly not responsible for the unauthorised acquiring of knowledge by third parties of performers’ personal data (for example, through unauthorised access to the central database), unless IPB has allowed third parties to acquire that unauthorised knowledge intentionally or through gross negligence.`,
  LEGAL_TERMS_LIABILITY_3: `IPB is liable – excluding violation of essential contractual obligations and for death, injury or impaired health or claims under the Product Liability Act – only for intent and gross negligence. Essential contractual obligations are those the fulfilment of which is necessary to achieve the objective of the contract.`,
  LEGAL_TERMS_LIABILITY_4: `In the event of a minor negligent breach of a duty that is essential to the purpose of the contract (cardinal obligation), the liability of IPB is limited to an amount that is foreseeable and typical of the nature of the transaction in question.`,
  LEGAL_TERMS_LIABILITY_5: `The foregoing limitation of liability also applies to the personal liability of employees, representatives and bodies of IPB.`,
  LEGAL_TERMS_LIABILITY_6: `IPB has no further liability.`,

  LEGAL_TERMS_INDEMNIFICATION: 'Indemnification',
  LEGAL_TERMS_INDEMNIFICATION_1: `The performer indemnifies IPB from any and all liabilities and from all further costs, obligations, claims, demands and expenses, from any actions and damages due to insult, defamation, infringement of intellectual property, personal or other rights of third parties and due to loss of services for other performers or users for which the performer is responsible.`,
  LEGAL_TERMS_OPERATION: 'Operation and rights',
  LEGAL_TERMS_OPERATION_1: `The performer grants to IPB a simple, temporally, spatially and textually unrestricted right (§ 79 UrhG, German Copyright Act) to take comprehensive advantage of and exploit the work of the performer in the IPB database, also with the aim of commercial marketing. The granting of rights expressly covers all known and unknown forms of offer opportunities in the Internet, in particular the possibility to integrate within paid online services and websites, as well as within the freely accessible Internet. The transfer of rights encompasses in particular all prerogatives and claims arising from the protection of related rights, in particular from Sections 73 et seq., 77 to 79 Copyright Act and from section 94 Copyright Act, the rights of the database creator under § 87 b Copyright Act, and all other rights and interest in the contractual recordings granted under copyright law and other laws (in particular the compensation claims for permission-free but paid uses by third parties, sections 46, 47, 52, 54, 76, 77, 86, 94 paragraph 4 Copyright Act), including all intellectual property rights and other prerogatives that are required to carry out the acts of exploitation specified in the contract.`,
  LEGAL_TERMS_OPERATION_2: `The performer transfers to IPB in particular the following rights:
  <ol>
    <li>The right to exploit the works of the performer on one or several online portals:
    <ol>
      <li>through images transmitted on the portal(s) (thumbnails, pictures as part of so-called picture galleries, images) via the upload function of the portal.</li>
      <li>through videos transmitted on the portal(s) (screen recording) including the corresponding soundtrack (preview videos, sales videos, etc.).</li>
      <li>in file form (via e-mail, Skype, and other digital transmission options), also for other marketing purposes of the company outside online portals.</li>
  </ol>
    <li>The right to exploit the works of the performer non-physically (“non-physical exploitation"), in particular to use them in databases, in data networks (e.g. the Internet), in other storage media of volatile or non-volatile type, telephone or telecommunication services (using all systems, in particular all mobile networks and mobile systems, such as WAP and UMTS) or similar technologies in each and any selection and arrangement, for all uses possible in the context of such technologies, in particular to feed in, to archive, store and/or to keep the selected works of the actor ready for call-up, and to transmit the same, for a fee or free of charge, wired or wirelessly on demand, for the purpose of audio and/or visual perception, reproduction and/or non-physical dissemination, in particular in all audio-file configurations such as Liquid Audio, MP3, SDMI standards, Real Audio etc., and – as far as technically required – to convert the same for that purpose. Transferred with this is the right to offer and make available the uploaded works of the performer to the public non-physically and to provide them to individual customers (e.g. private consumers, traders, channels, etc.) in particular for the purpose of so-called real-time play, real-time streaming, and download for temporary or long-term storage on storage media of the customer, and deferred streaming (e.g. in the framework of so-called on-demand services, which are used on so-called stand-alone units). IPB shall decide in this respect on the link with other contractually and/or non-contractually specified recordings. IPB shall decide separately for each contractually specified single recording regarding system quality, storage permission for end customers, and duration of storage.</li>
    <li>The right to use the selected works of the performer for marketing and promotional purposes outside the portals of the IPB.</li>
  </ol>`,
  LEGAL_TERMS_OPERATION_3: `IPB is entitled, but not obligated, to check the contents of the uploaded data material for compatibility with the general laws or these General Terms and Conditions and – if necessary – not to publish the respective contents. IPB and its users have also particularly high standards of quality, safety and level of services provided by and through IPB. In this context IPB expressly reserves the right to exclude the publishing of data material that does not meet the high standards of IPB users.`,
  LEGAL_TERMS_OPERATION_4: `IPB assumes no editorial control over the content of the performer posted on his or her blog.`,

  LEGAL_TERMS_CANCELLATION: 'Cancellation and annulment of the contract',
  LEGAL_TERMS_CANCELLATION_1: `The performer may at any time request IPB to discontinue the availability of the data material provided on the IPB database and to shut down the profile of the performer. Should the performer issue this request with regard to premium material, IPB may demand that any such premium already paid be returned to IPB or offset against otherwise earned commissions, at the discretion of IPB.`,
  LEGAL_TERMS_CANCELLATION_2: `After termination of the contract all personal data of the participant will be deleted by IPB on request, insofar as this is permitted by law. Excluded from this is content to which the performer has granted IPB a temporally unlimited right of use.`,
  LEGAL_TERMS_CANCELLATION_3: `IPB is entitled at any time to discontinue its service for individual performers in whole or in part without stating reasons.`,

  LEGAL_TERMS_PRIVACY: 'Data privacy',
  LEGAL_TERMS_PRIVACY_1: `IPB collects, processes and uses personal data of the user as described in these Terms and Conditions and in the {link}. The processing and retention of personal data is guided by the statutory requirements.`,
  LEGAL_TERMS_PRIVACY_LINK: 'privacy policy',

  LEGAL_TERMS_MODIFICATION: 'Modification of the General Terms and Conditions',
  LEGAL_TERMS_MODIFICATION_1: `IPB reserves the right to change these terms and conditions at any time, provided that the core provisions of the contract remain unaffected hereby and that this change is necessary to adapt to developments that could not be foreseen by IPB upon conclusion of the contract and that if not taken into account would significantly disrupt the balance of the contract. Core provisions are in particular the nature and scope of the agreed services, as well as the duration, including the provisions for cancellation. IPB is entitled to make changes to close loopholes in the contract that occur in particular through regulatory changes and changes in the case law.`,
  LEGAL_TERMS_MODIFICATION_2: `IPB will notify the user of the changes by sending an email that includes the General Terms and Conditions that are to be amended. In place of enclosing the full text, a reference to the address on the Internet where the new version is available is deemed sufficient. The user may object to the amendments within two weeks. If the user does not object to the changes within this period, the amended General Terms and Conditions shall apply from the date the period expires.`,

  LEGAL_TERMS_FINAL: 'Final Provisions',
  LEGAL_TERMS_FINAL_1: `The legal relationships existing between IPB and the performer shall be governed by the law of the Federal Republic of Germany subject to compulsory regulation.`,
  LEGAL_TERMS_FINAL_2: `The court of jurisdiction for all claims arising from this contract is Berlin.`,
  LEGAL_TERMS_FINAL_3: `Should individual provisions of these general terms and conditions be or become ineffective, the validity of the remaining provisions of this agreement shall remain hereby unaffected. The Contracting Parties shall replace the invalid provision with a provision that comes as close as possible to the economic purpose of the original provision. A similar procedure is to be followed to close any loopholes that may be revealed.`,
  LEGAL_TERMS_FINAL_4: `Modifications and/or supplements to this agreement must be in writing to be valid, unless otherwise agreed in these Terms and Conditions. This also applies to any waiver of the written-form requirement.`,
  LEGAL_TERMS_FINAL_5: `Deviating terms and conditions of the performer/user are not accepted.`,
};
