import './SpinnerItem.scss';

import React from 'react';
import { useIntl } from 'react-intl';

function SpinnerItem({
  conversionDetails,
  uploadPercent,
  conversionError,
  component,
}) {
  const intl = useIntl();
  let remainingTime, lessThanAMinute;

  if (conversionDetails && conversionDetails?.remainingTime) {
    lessThanAMinute = conversionDetails?.remainingTime < 60;
    remainingTime = lessThanAMinute
      ? conversionDetails.remainingTime
      : parseInt(conversionDetails.remainingTime / 60);
  }

  const percent = uploadPercent
    ? uploadPercent
    : conversionDetails
    ? conversionDetails.percent
    : null;

  return (
    <div className="thumbnail-item">
      <div className="thumbnail-wrapper">
        <div className="thumbnail has-spinner">
          <div className="spinner-wrapper">
            <div className="spinner" />
            <div className="conversion-text">
              {conversionError ? (
                <>{intl.formatMessage({ id: 'ERROR_FETCHING_CONVERSION' })}</>
              ) : (
                <>
                  {!component
                    ? intl.formatMessage({
                        id: uploadPercent ? 'IS_UPLOADING' : 'IS_CONVERTING',
                      })
                    : intl.formatMessage({
                        id: 'PHOTO_IS_CONVERTING',
                      })}
                  {!!percent && `: ${percent}%`}
                  {!!conversionDetails?.remainingTime && (
                    <div className="conversion-countdown">
                      {intl.formatMessage(
                        {
                          id: lessThanAMinute
                            ? 'REMAINING_TIME_SECONDS'
                            : 'REMAINING_TIME_MINUTES',
                        },
                        { time: remainingTime }
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SpinnerItem;

