import './VideoWithOverlay.scss';

import { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import linkSVG from '../../assets/img/svg/open-in-new.svg';
import { uuid } from '../../utils';
import Button from '../Button/Button';
import StyledLink from '../StyledLink/StyledLink';
import VideoPlayerItem from '../VideoPlayerItem/VideoPlayerItem';

function VideoWithOverlay({
  openOverlay,
  video,
  poster,
  showOverlay = true,
  wikiLink,
}) {
  const videoRef = useRef(null);
  const aspectRatio = 'vjs-16-9';
  const fillOption = 'vjs-fill';
  const handlePause = () => {
    videoRef.current.pause();
  };
  const openVideo = async () => {
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'VideoOverlay',
      props: {
        openDocumentStamp: stamp,
        fileSource: video,
        opacity: '0.60',
      },
    });
    handlePause();
  };
  return (
    <div className="video-with-overlay-wrapper">
      <VideoPlayerItem
        data={video}
        videoOverlayRef={videoRef}
        poster={poster}
        aspectRatio={aspectRatio}
        fillOption={fillOption}
      />
      {showOverlay && (
        <div className="video-footer">
          <div className="internal-link" onClick={openVideo}>
            <Button icon={linkSVG} intlTranslate={false} variant="icon-only" />
            <span className="link">
              <FormattedMessage id="OPEN_VIDEO_OVERLAY" />
            </span>
          </div>
          {wikiLink ? (
            <StyledLink url={wikiLink} text={'OPEN_WIKI_LINK'} />
          ) : null}
        </div>
      )}
    </div>
  );
}

export default VideoWithOverlay;

