import { lStorage, sStorage } from '../../services/storage';
import { USER_REFRESH_AUTH_TOKEN } from '../actions';

export default function (payload) {
  const storage = !!lStorage.getItem('user') ? lStorage : sStorage;

  storage.setItem('user', JSON.stringify(payload));

  return { type: USER_REFRESH_AUTH_TOKEN, payload };
}

