import './Line.scss';

import classNames from 'classnames';
import React from 'react';

function Line({ color = 'gray' }) {
  const className = classNames('step-line', {
    [color]: color,
  });

  return <div className={className} />;
}

export default Line;

