import { user as userUtils } from '../../utils';
import api from '../../utils/api';
import { addOnlineStatus } from '.';

export default () => async (dispatch, getState) => {
  try {
    if (!userUtils.userLoggedInAndConfirmed(getState())) return;
    const response = await api.get('/online');

    if (!userUtils.userLoggedInAndConfirmed(getState())) return;
    dispatch(
      addOnlineStatus(response?.data?.map((el) => el.replace('-', '@')))
    );
  } catch (err) {
    console.log({ onlineStatuError: err });
    console.log('could not load online status');
  }
};

