import './PreviewContest.scss';

import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

function PreviewContest({ locale, contest }) {
  const intl = useIntl();
  if (!contest) {
    return null;
  }

  const state = contest?.state;
  const livecams = contest?.show?.livecams;
  const messages = contest?.show?.messages;
  const content = contest?.show?.content;

  if (!livecams && !messages && !content) return null;

  const imageClasses = classNames('contest-image', {
    [`locale-${locale}`]: !!locale,
    [`contest-${state}`]: !!state,
  });

  return (
    <div className="preview-contest-wrapper">
      <div className={imageClasses}>
        {' '}
        <p className="contest-info">
          <Link to="/wiki/contest">
            {intl.formatMessage({ id: 'CONTEST_INFO_LINK_TEXT' })}
          </Link>
        </p>
      </div>
    </div>
  );
}

export default PreviewContest;

