import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { audio, sound } from '../../../actions';
import SoundSelect from './SoundSelect';

const mapStateToProps = (state) => ({
  title: 'audio.connectionLost',
  sounds: state.sounds.available,
  selected: state.preferences.sounds.lostConnection.type,
  active: state.preferences.sounds.lostConnection.active,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (e) => {
    dispatch(sound.update('lostConnection', e.target.value));
  },
  onActivate: (value) => {
    dispatch(sound.activate('lostConnection', value));
  },
  onPlay: (sound) => {
    dispatch(audio.play(sound));
  },
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SoundSelect)
);
