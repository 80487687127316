export default {
  XMAS_WIKI_HEADLINE: 'Be our Xmas Girl',
  XMAS_WIKI_INTRO_1:
    'Apply now and secure yourself one of the high converting calendar doors!',
  XMAS_WIKI_INTRO_2: 'Ready, steady, go!',

  XMAS_WIKI_INFO_TEXT_1: `We will offer our advent Calendar in this year too. Therefore we are looking for Creators which are willing to provide content for the calendar windows. The user will be able to open a calendar window each day and will find at least some bonus coins or VIP-Points.`,
  XMAS_WIKI_INFO_TEXT_1_1: `We would like to offer them a little extra present from one Creator per day. For instance a picture or movie. These presents will be without charge but of course you are increasing your chance for additional profile visitors and thus more revenue. Because we will <span class="bold">certainly show an explicit link to your profile with the present.</span>. 😊`,
  XMAS_WIKI_INFO_HEADLINE_1: `As a present we will accept:`,
  XMAS_WIKI_INFO_TEXT_2: `
    <ul class='checklist'>
      <li><span class='bold'>Pictures</span> with Xmas theme in HD resolution and best quality</li>
      <li><span class='bold'>Movies</span> with Xmas theme in high resolution and best quality</li>
    </ul>`,
  XMAS_WIKI_INFO_HEADLINE_2: `IMPORTANT:`,
  XMAS_WIKI_INFO_TEXT_3: `
    <ul class='checklist'>
      <li>The content has to be non-nude or softcore!</li>
      <li>We reserve the right to choose the - in our opinion - best content from all transmittals.</li>
    </ul>`,
  XMAS_WIKI_INFO_TEXT_4: `If you like to take part of this campaign please contact  Kathrin: <a class="blue" href="mailto:kathrin@livecreator.com">kathrin@livecreator.com</a>`,
  XMAS_WIKI_INFO_TEXT_5: `
    <ul class='checklist'>
      <li>Send your content directly via email</li>
      <li>Create a gallery with your content (do NOT publish it!!) and send the gallery name via email</li>
      <li>You can upload the desired media to a cloud (e.g. Dropbox) and send the link via email</li>
    </ul>`,
  XMAS_WIKI_INFO_TEXT_6: `We are looking forward to your contribution!`,
};
