export default {
  ROUTE_COMMISSION_HEADLINE: `Get an overview of your current commission rate and all settlements made here`,
  COMMISSION_HEADLINE_1: `The LiveCreator commission model`,
  COMMISSION_HEADLINE_2: `When are credits paid out?`,
  COMMISSION_TEXT_1: `For each billing period you can receive up to 32% commission with LiveCreator.`,
  COMMISSION_TEXT_2: `All commissions are free of cancellation!`,
  COMMISSION_TEXT_3: `Your current commission rate depends on the number of coins earned in our communities.`,
  COMMISSION_TEXT_4: `<span class="bold">The coin-based relay in detail:</span><br />
  <table class="noBorder noHover">
   <tr>
      <td>up to</td>
      <td class="align-right">9.999 Coins</td>
      <td>25%</td>
   </tr>
   <tr>
      <td>from</td>
      <td class="align-right">10.000 Coins</td>
      <td>27.5%</td>
   </tr>
   <tr>
      <td>from</td>
      <td class="align-right">25.000 Coins</td>
      <td>30%</td>
   </tr>
   <tr>
      <td>from</td>
      <td class="align-right">50.000 Coins</td>
      <td>31%</td>
   </tr>
   <tr>
      <td>from</td>
      <td class="align-right">100.000 Coins</td>
      <td>32%</td>
   </tr>
  </table>`,
  COMMISSION_TEXT_PAY_1: `There are two billing periods per month, one from the 1st to the 15th of the month, which is drawn on the 16th of a month and one from the 16th to the end of the month, which is drawn on the 1st of the following month`,
  COMMISSION_TEXT_PAY_2: `All items sold by you are billed in these statements, with some being coin-based (such as messages, livecam, movies and pictures, sexicons, virtual gifts), others being static (such as the commission from affiliate users and Creators).`,
  COMMISSION_TEXT_PAY_3: `Billing is usually carried out immediately in the next 2-3 working days after the end of a billing period.`,
  COMMISSION_TEXT_PAY_4: `The prerequisite for this is that the minimum payment amount of EUR 100 or a higher amount is exceeded, which you can change `,
  COMMISSION_TEXT_PAY_5: `here`,
  COMMISSION_TEXT_PAY_6: `. In addition, your stored bank details must be verified and activated!`,

  PAYOUTS_TABLE_BILL: 'bills',
  BILL_ARTICLES_ARTICLE: `Article`,
  BILL_ARTICLES_TIME: `Time`,
  BILL_ARTICLES_CUSTOMER: `Customer`,
  BILL_ARTICLES_PORTAL: `Portal`,
  BILL_ARTICLES_GALLERY: `Gallery`,
  BILL_ARTICLES_COIN_PURCHASES: `Coin Purchases`,
  BILL_ARTICLES_COMMISSION: `Commission`,
  BILL_ARTICLES_DURATION: `Duration`,
  BILL_ARTICLES_TURNOVER: `Turnover`,
  BILL_ARTICLES_HIDDEN_USERS: `Hidden user`,
  BILL_ARTICLES_CREATOR: `Creator`,
  BILL_ARTICLES_NUMBER: `Number`,
  BILL_ARTICLES_SHARE: `Share`,
  BILL_ARTICLES_SALES: `Sales`,
  BILL_ARTICLES_TOTAL_TURNOVER: `Total turnover`,
  BILL_ARTICLES_SHOW_ALL: `Show all`,
  BILL_ARTICLES_SHOW_LESS: `Show less`,

  ROUTE_BILLING_PERIOD_TITLE: `Current billing period`,
  ROUTE_PARKING_CREDITS_TITLE: `Parking credits`,
  ROUTE_PAYOUT_TITLE: `Payout`,
  ROUTE_LIVECAM_OVERVIEW_TITLE: `Livecam overview`,
  ARTICLE_COINS_TABLE_ARTICLE: `Article`,
  ARTICLE_COINS_TABLE_NUMBER: `Number`,
  ARTICLE_COINS_TABLE_FIRST_ON: `First on`,
  ARTICLE_COINS_TABLE_LAST_ON: `Last on`,
  ARTICLE_COINS_TABLE_TURNOVER: `Turnover`,
  ARTICLE_COINS_TABLE_SHARE: `Share`,
  ARTICLE_COINS_TABLE_PREMIUM: `Premium`,
  MODELS_TABLE_MODEL: `Model`,
  MODELS_TABLE_COMMISSION_RATE: `Commission rate`,
  MODELS_TABLE_PROGRESS: `Progress`,
  MODELS_TABLE_YOUR_COMMISSION: `Your commission`,
  MODELS_TABLE_MINIMUM_TURNOVER: `Minimum turnover`,
  MODELS_TABLE_YOUR: `This is your`,
  MODELS_TABLE_CURRENT_LEVEL: `current level`,
  MODELS_TABLE_FROM_COINS: `(from {coins} Coins)`,
  MODELS_TABLE_YOU_REACHED_COMMISSION_RATE: `You reached a commission rate of {rate}`,

  PAYOUT_PAYOUT: `Payout`,
  PAYOUT_FROM: `from`,
  PAYOUT_AMOUNT: `Amount`,
  PARKING_CREDITS_BILL: `Bill`,
  PARKING_CREDITS_FROM: `from`,
  PARKING_CREDITS_BILLINGPERIOD: `Billing period`,
  PARKING_CREDITS_MODEL: `Model`,
  PARKING_CREDITS_PREMIUM: `Premium`,
  LIVECAM_OVERVIEW_NUMBER: `No`,
  LIVECAM_OVERVIEW_TIME: `Time`,
  LIVECAM_OVERVIEW_CUSTOMER: `Customer`,
  LIVECAM_OVERVIEW_PORTAL: `Portal`,
  LIVECAM_OVERVIEW_DURATION: `Duration`,
  UNTIL: `until`,

  TOTAL_TURNOVER: `Total turnover`,
  BILLING_PERIOD_CURRENT_CREDITS: `Current credits`,
  PARKING_CREDITS_REVENUE: `Parking revenue`,
  PARKING_TOTAL_REVENUE: 'Total',
  PAYOUTS_PAYOUTS: `Payouts`,
  LIVECAM_OVERVIEW_HEADLINE: `Livecam overview`,
  BILLING_PERIOD_PAYOUT_INFO: `Your credits will be payed out on {end}, if the total sum is greater than the minimum payout of `,
  BILLING_PERIOD_PAYOUT_INFO_2: '.',
  CREDITS: `Credits`,
  article_message: `Private message`,
  article_livecams: `Livecam`,
  article_movies: `Video`,
  article_pictures: `Picture`,
  article_sexicon: `Sexicon`,
  article_regard: `Virtual gift`,
  article_exclusivecams: `Exclusive show`,
  article_voyeurcams: `Voyeur show`,
  article_livecamtip: `Livecam Tip`,
  article_payttachment: `Premium Attachment`,
  article_advrev: `Revenue from turnover referred users`,
  article_advppl: `Commission user signup (PPL)`,
  article_supersenderbonus: `Revenue from turnover referred Creators`,
  article_bonus: `Bonus`,
  article_adjustment: `Adjustment`,
  article_ext_livecams: `External Livecam`,
  article_toycontrol: `Toycontrol`,
  article_ghostmessage: `Private message`,
  article_ghostregard: `Virtual gift`,
  article_ghostsexicon: `Sexicons`,
  article_voicemessage: `Voice Message`,
  article_game: `Community Game`,
  ARTICLES_OTHER: `Commissions from actual turnovers`,
  ARTICLES_SEE_ABOVE: `(see above)`,
  PROGRESS_ITEM_LEFT: `{data} {value} left`,

  SALES_TODAY: `Sales today`,
  SALES_TODAY_SHORT: `Today`,
};

