import { connect } from 'react-redux';

import {
  spinner,
  toggleVeriffBar,
  toggleVeriffFrame,
  veriffError,
} from '../../actions';
import Veriff from './Veriff';

const mapStateToProps = (state) => ({
  ...state.veriff,
  locale: state.intl.locale,
});

const mapDispatchToProps = {
  toggleVeriffFrame,
  toggleVeriffBar,
  veriffError,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Veriff);
