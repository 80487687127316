import { connect } from 'react-redux';

import Xmas from './Xmas';

const mapStateToProps = (state) => ({
  isMobile: state.view.isMobile,
  locale: state.intl.locale,
});

export default connect(mapStateToProps)(Xmas);

