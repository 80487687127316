export default {
  ROUTE_DASHBOARD_HEADLINE: `Key facts at a glance`,
  DASHBOARD_BANNER_HEADLINE: `Welcome`,
  DASHBOARD_BANNER_TEXT: `Click here for the Multi Messenger. Keep track and manage all your chats, customers and cam shows.`,
  DASHBOARD_LINK_TITLE_1: `Set your prices`,
  DASHBOARD_LINK_CONTENT_1: `Set the price per minute in the livecam and how much a message should cost your customers.`,
  DASHBOARD_LINK_TITLE_2: `Edit your public profile`,
  DASHBOARD_LINK_CONTENT_2: `Upload new profile pictures, change your description text or add information`,
  DASHBOARD_LINK_TITLE_3: `Upload file attachments`,
  DASHBOARD_LINK_CONTENT_3: `You can send file attachments directly in the Messenger and add them to your Icebreaker.`,
  DASHBOARD_LINK_TITLE_4: `Create galleries with your media`,
  DASHBOARD_LINK_CONTENT_4: `Manage your photo and video galleries and read received comments`,
  DASHBOARD_LINK_TITLE_5: `Edit your Livecam settings`,
  DASHBOARD_LINK_CONTENT_5: `Adapt notification tones, short texts and an optimal video resolution to your needs.`,
  DASHBOARD_AFFILIATE_TITLE_1: `Advertising link for creators`,
  DASHBOARD_AFFILIATE_CONTENT_1: `Invite a friend as a creator with <span class="bold">your personal link</span>`,
  DASHBOARD_AFFILIATE_TITLE_2: `Advertising links for users`,
  DASHBOARD_AFFILIATE_CONTENT_2: `Acquire users and receive an additional 25% commission with <span class="bold">your personal links</span>`,

  DASHBOARD_CURRENT_SALES: `Current sales`,
  DASHBOARD_CURRENT_CREDITS: `Current credits`,
  DASHBOARD_TOTAL_TURNOVER: `Total turnover`,
  DASHBOARD_CREDITS: `Credits`,
  DASHBOARD_MY_PERFORMANCE: `My performance`,
  DASHBOARD_IMPORTANT_LINKS: `Important links`,
  DASHBOARD_AFFILIATE_LINKS: `Affiliate program`,
  DASHBOARD_OPEN_MESSENGER: `Open the Multi Messenger here`,
  DASHBOARD_LIVECAM_NOT_ENABLED_TIP_TITLE: `Your Livecam is not enabled`,
  DASHBOARD_LIVECAM_NOT_ENABLED_TIP_CONTENT: `Please contact the <a href="/support">support</a>`,
  DASHBOARD_BROWSER_NOT_ALLOWED_TIP_TITLE: 'Browser not allowed for Livecam',
  DASHBOARD_BROWSER_NOT_ALLOWED_TIP_CONTENT: `Your browser is not supported for using our livecam software, please use one of the following:
   {browsers}`,
  DASHBOARD_PROGRESS: `Progress`,
  DASHBOARD_WEBINAR_JOIN_NOW: `Join Now`,
  DASHBOARD_WEBINAR_BEGINNERS: `For beginners and advanced`,
  DASHBOARD_WEBINAR_ONLINE: `ONLINE WEBINAR`,
  DASHBOARD_BILLING_SEE_DETAILS: 'see details',
};

