export default {
  XMAS_WIKI_HEADLINE: 'Werde ein Xmas Girl',
  XMAS_WIKI_INTRO_1:
    'Bewirb Dich jetzt und sichere Dir eines der begehrten und umsatzstarken Türchen!',
  XMAS_WIKI_INTRO_2: '24 Plätze - Fertig - Los!',

  XMAS_WIKI_INFO_TEXT_1: `Auch in diesem Jahr werden wir den Usern einen Adventskalender anbieten. Dafür suchen wir Creator, die den Inhalt für ein Türchen zur Verfügung stellen. Die User werden jeden Tag ein Türchen öffnen können, hinter dem sich in jedem Fall entweder Bonus-Coins oder VIP-Punkte verbergen.`,
  XMAS_WIKI_INFO_TEXT_1_1: `Zusätzlich möchten wir ein kleines Präsent von jeweils einem Creator anbieten. Z.B. ein Bild oder ein Video. Diese Präsente werden gratis sein, allerdings erhöhst Du damit die Chance auf zusätzliche Profilbesucher und somit auf mehr Umsatz, denn <span class="bold">natürlich werden wir das Geschenk deutlich mit Deinem Profil verlinken</span>. 😊`,
  XMAS_WIKI_INFO_HEADLINE_1: `Als Präsent kannst Du einreichen:`,
  XMAS_WIKI_INFO_TEXT_2: `
    <ul class='checklist'>
      <li><span class='bold'>Fotos</span> mit weihnachtlichem Motiv in HD-Auflösung und bester Qualität</li>
      <li><span class='bold'>Videos</span> mit weihnachtlichem Motiv in hoher Auflösung und bester Qualität</li>
    </ul>`,
  XMAS_WIKI_INFO_HEADLINE_2: `WICHTIG:`,
  XMAS_WIKI_INFO_TEXT_3: `
    <ul class='checklist'>
      <li>Die Medien dürfen keinen Hardcore Content zeigen, also sollten FSK12 oder FSK16 entsprechen!</li>
      <li>Wir behalten uns vor, aus den Einsendungen die aus unserer Sicht passendsten auszuwählen.</li>
    </ul>`,
  XMAS_WIKI_INFO_TEXT_4: `Solltest Du an der Aktion teilnehmen wollen, dann kontaktiere bitte Kathrin: <a class="blue" href="mailto:kathrin@livecreator.com">kathrin@livecreator.com</a>`,
  XMAS_WIKI_INFO_TEXT_5: `
    <ul class='checklist'>
      <li>Du kannst Deinen Beitrag direkt per E-Mail senden</li>
      <li>Du kannst eine Galerie mit den Medien erstellen (NICHT veröffentlichen!!) und den Galerienamen mailen</li>
      <li>Du kannst die Medien in eine Cloud (z.B. Dropbox) laden und die Verlinkung zu dem Ordner mailen</li>
    </ul>`,
  XMAS_WIKI_INFO_TEXT_6: `Wir freuen uns auf Deinen Beitrag!`,
};
