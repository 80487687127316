import { SHOW_TYPE_VOYEUR } from '../../../constants';
import {
  getUnixTimestamp,
  messageUtils,
  user as userUtils,
} from '../../../utils';
import api from '../../../utils/api';
import { createSystemDialogMessage } from '../../../utils/chat';
import { FETCH_ALL_MESSAGES } from '../../actions';
import { dialogRead, errorLog } from '../..';

export default (partner, intialCall = false) =>
  async (dispatch, getState) => {
    if (!userUtils.userLoggedInAndConfirmed(getState())) return;
    const {
      user: {
        account: { messagingId, nickname: sendername },
      },
      chat: { selectedUser, page },
      dialog: { dialogs },
      camUser: { camUsers },
      user: {
        account: { scopes },
      },
    } = getState();
    const payload = [];
    const allDialogs = [...dialogs];
    try {
      const response = await api.get(`/user/dialog/${partner}?p=${page}`);
      if (!userUtils.userLoggedInAndConfirmed(getState())) return;

      const hasMessageScope = scopes.includes('send_message');
      const isCamMessengerRoute =
        window?.location?.pathname === '/multi-messenger';

      const activeCamUser =
        camUsers.find(
          (u) =>
            userUtils.getPartner(u) === partner &&
            u.showType !== SHOW_TYPE_VOYEUR
        ) || {};
      if (activeCamUser?.type) {
        activeCamUser.partner = userUtils.hasPartner(activeCamUser)
          ? userUtils.getPartner(activeCamUser)
          : null;
      }
      if (userUtils.isExclusiveShow(activeCamUser) && intialCall) {
        payload.push(
          createSystemDialogMessage({
            id: 'systemMessage.startExclusive',
            userId: activeCamUser.userId,
            time: getUnixTimestamp(activeCamUser?.showStartTime),
          })
        );
      }
      const attachmentUser = userUtils.get(
        [...camUsers, ...allDialogs],
        partner
      );
      for (let i = 0; i < response.data.length; i++) {
        const msg = messageUtils.create(
          response.data[i],
          partner,
          messagingId,
          activeCamUser,
          attachmentUser,
          sendername
        );
        msg.recipient = msg.sender === messagingId ? selectedUser : messagingId;
        payload.push(msg);
      }
      const isSelected =
        partner === selectedUser || partner === activeCamUser.partner;

      if (isSelected && hasMessageScope && isCamMessengerRoute && page === 0) {
        // confirm dialog read, this happens only once per dialog,
        // but it also happens if dialog is already read
        dispatch(dialogRead(partner));
      }
      dispatch({
        type: FETCH_ALL_MESSAGES,
        payload,
      });
      return payload;
    } catch (error) {
      const errorObject = {
        location: 'fetchAllMessages',
        error,
      };
      dispatch(errorLog(errorObject));
      console.log({ fetchAllMessages: error });
      dispatch({
        type: FETCH_ALL_MESSAGES,
        error: true,
        payload: error,
      });
    }
  };

