import { FAVORITE_IDS_ADD } from '../actions/actions';

const initialState = {
  ids: [],
};

function favoritesReducer(state = initialState, action) {
  switch (action.type) {
    case FAVORITE_IDS_ADD:
      return { ...state, ids: action.payload };

    default:
      return state;
  }
}

export default favoritesReducer;
