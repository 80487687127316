import './FileItem.scss';

import classNames from 'classnames';
import React from 'react';

import closeIcon from '../../../assets/img/svg/close.svg';
import Button from '../../Button/Button';
import LinkItem from '../../Tables/TableItems/LinkItem/LinkItem';

function FileItem({
  name,
  href,
  disabled,
  isDeleteBtnShown = true,
  onDeleteBtnClick = () => {},
  error,
  status,
}) {
  const className = classNames('uploader-file-item', {
    'has-error': error,
  });
  return (
    <div className={className} {...(error ? { title: error } : {})}>
      {href ? (
        <a href={href} target="_blank" rel="noreferrer">
          {name}
        </a>
      ) : (
        <LinkItem data={name} status={status} />
      )}

      {isDeleteBtnShown && (
        <Button
          className="close-btn"
          type="button"
          disabled={disabled}
          onClick={onDeleteBtnClick}
          icon={closeIcon}
          intlTranslate={false}
          variant="icon-only"
        />
      )}
    </div>
  );
}

export default FileItem;

