import {
  management as managementActions,
  typingSender,
} from '../../../../actions';
import {
  getSenderNameForUser,
  livecamMessagePlaceholder,
} from '../../../../utils/chat';
import userUtils from '../../../../utils/user';
import { MANAGEMENT_MESSAGE_SENDER_SEND } from '../../../actions';

const createSuccess = (message, user, senderName) => ({
  type: MANAGEMENT_MESSAGE_SENDER_SEND,
  payload: {
    message,
    user,
    senderName,
  },
});

const chatMessageCallback = (user, dispatch, management) => (data) => {
  if (data && data.errorStatus === 42) {
    management.log.debug(`Userlist: UserID: ${user.userId}`);
    return dispatch(managementActions.events.removeUser(user));
  }
};

export default (management) => (message, userId) => {
  return (dispatch, getState) => {
    const {
      sender,
      camUser: { camUsers },
      dialog: { dialogs },
    } = getState();

    const userList = [...camUsers, ...dialogs];
    const user = userId
      ? userUtils.get(userList, userId)
      : userUtils.activeUser(userList);

    if (!user) {
      return;
    }

    const clearedMessage = livecamMessagePlaceholder(message, user);

    if (clearedMessage === '') {
      return;
    }

    const senderName = getSenderNameForUser(user, sender.logins);

    const params = {
      userId: user.userId,
      isAdmin: userUtils.isAdmin(user),
      message: clearedMessage,
    };

    management.emit(
      'livecamChatMessage',
      params,
      chatMessageCallback(user, dispatch, management)
    );
    dispatch(createSuccess(clearedMessage, user, senderName));
    dispatch(typingSender(user.userId, ''));
  };
};
