import { audio } from '../../../../../actions';
import { user as userUtils } from '../../../../../utils';
import { createIncomingMessagePayload } from '../../../../../utils/chat';
import { MANAGEMENT_EVENTS_MESSAGE_USER_ADD } from '../../../../actions';

export default (data) => {
  return (dispatch, getState) => {
    const payload = createIncomingMessagePayload(data, getState());
    if (!payload) {
      return;
    }

    if (userUtils.isDialogUser(payload.userType)) {
      return;
    }

    dispatch({
      type: MANAGEMENT_EVENTS_MESSAGE_USER_ADD,
      payload,
    });
    dispatch(audio.newMessage());
  };
};
