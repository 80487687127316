import './ToycontrolMenu.scss';

import classNames from 'classnames';

import noToy from '../../../../assets/img/svg/cancel.svg';
import { LOVENSE_TOYS } from '../../../../constants/lovense';
import Button from '../../../Button/Button';

const sortAlphabetically = (a, b) => {
  if (a.label.toLowerCase() > b.label.toLowerCase()) return 1;
  if (a.label.toLowerCase() < b.label.toLowerCase()) return -1;
  return 0;
};

function ToycontrolMenu({ connected, close, activeToy, setActiveToy }) {
  if (!connected || !connected?.toys) {
    return null;
  }

  const toyOptions = Object.keys(connected.toys)
    .map((key) => {
      const toy = connected.toys[key];
      return {
        label: toy.name,
        value: toy.id,
        icon: LOVENSE_TOYS[toy.name.toLowerCase()],
        disabled: parseInt(toy.status, 10) === 0,
        active: toy.id === activeToy,
      };
    })
    .sort(sortAlphabetically)
    .reduce((arr, el) => {
      if (el.active) {
        return [el, ...arr];
      }
      return [...arr, el];
    }, []);

  toyOptions.unshift({
    label: 'TOYCONTROL_NO_TOY',
    value: '',
    active: activeToy === '',
  });

  return (
    <>
      {toyOptions.map((toy, index) => {
        const classes = classNames('no-shadow', {
          disabled: toy.disabled,
          active: toy.active,
        });

        const onClick =
          !toy.disabled &&
          !toy.active &&
          !(toy.value === '' && activeToy === '')
            ? () => {
                setActiveToy(toy.value);
                close();
              }
            : () => {};

        return toy.value !== '' ? (
          <Button
            variant="default"
            className={classes}
            key={index}
            icon={toy.icon}
            label={toy.label}
            onClick={onClick}
            intlTranslate={false}
          />
        ) : (
          <Button
            variant="default"
            className={classes}
            key={index}
            icon={noToy}
            label={toy.label}
            onClick={onClick}
          />
        );
      })}
    </>
  );
}

export default ToycontrolMenu;
