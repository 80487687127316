import { connect } from 'react-redux';

import { spinner, userUpdate as update } from '../../../../actions';
import BillingPeriod from './BillingPeriod';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    minimum_payout: state.user.private?.minimum_payout,
    bank_location: state.user.private?.bank_location,
    language: state.user.account?.language,
  };
};
const mapDispatchToProps = { spinner, update };

export default connect(mapStateToProps, mapDispatchToProps)(BillingPeriod);
