import { MANAGEMENT_SENDER_TYPING } from '../../../actions';

export default (management) => (user, status) => (dispatch) => {
  const userId = user.userId;
  if (user.notifiedTyping !== status) {
    management.emit('isTyping', { userId, status });
    dispatch({
      type: MANAGEMENT_SENDER_TYPING,
      payload: {
        userId,
        status,
      },
    });
  }
};
