import './AnswerComments.scss';

import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import axiosInstance from '../../../../../utils/api';
import Button from '../../../../Button/Button';
import { getValidationScheme } from '../Comments.functions';

function AnswerComments({ id, onCommentAnswer, onCancel }) {
  const intl = useIntl();

  return (
    <div className="answer">
      <h2 className="headline">
        {intl.formatMessage({ id: 'COMMENTS_ANSWER_HEADLINE' })}
      </h2>
      <p>{intl.formatMessage({ id: 'COMMENTS_ANSWER_DESCRIPTION' })}</p>
      <Formik
        enableReinitialize={true}
        initialValues={{ comment: '' }}
        validationSchema={getValidationScheme(intl)}
        onSubmit={async (values, { setSubmitting, setFieldError }) => {
          try {
            const response = await axiosInstance({
              url: `/comments/${id}`,
              method: 'POST',
              data: { comment: values.comment },
            });
            if (response.status === 204) {
              onCommentAnswer();
            }
          } catch (error) {
            setFieldError(
              'comment',
              intl.formatMessage({ id: 'ERROR_DURING_ACTION' })
            );
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, values, errors, handleSubmit }) => (
          <Form className="add-gallery-comment" onSubmit={handleSubmit}>
            <div className="form-group">
              <label>
                <FormattedMessage id="COMMENTS_FORM_LABEL" />
              </label>
              <div
                className={classNames('textarea-wrapper', {
                  'has-error': errors.comment !== undefined,
                })}
              >
                <Field
                  as="textarea"
                  id="comment"
                  name="comment"
                  maxLength="255"
                  minLength="16"
                  disabled={isSubmitting}
                />
                {errors.comment !== undefined && (
                  <div className="error-message">{errors.comment}</div>
                )}
              </div>
            </div>
            <div className="form-group noMargin">
              <span className="column" />
              <p className="textarea-length-indicator">
                <FormattedMessage id="REMAINING_CHARS" />:{' '}
                {255 - values.comment.length}
              </p>
            </div>
            <div className="btn-comments-wrapper">
              <Button
                type="button"
                variant="dark"
                onClick={onCancel}
                label={'CANCEL'}
                disabled={isSubmitting}
              />
              <Button
                type="submit"
                variant="primary"
                label={'SEND'}
                disabled={isSubmitting || errors.comment?.length > 0}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AnswerComments;

