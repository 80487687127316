import './PrivacyPolicy.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import PageSection from '../../../PageSection/PageSection';
import privacyPolicy from './PrivacyPolicy.text';

function PrivacyPolicy({ isMobile }) {
  const isContentSectionDisplayed = useMediaQuery('(min-width: 1600px)');

  const className = classNames('privacy-policy-container', {
    mobile: isMobile,
  });

  return (
    <PageSection
      title={<FormattedMessage id="LEGAL_PRIVACY" />}
      className={className}
      rightColumn={privacyPolicy}
      rightHeader={true}
    >
      <h1 className="headline">
        <FormattedMessage id="LEGAL_PRIVACY" />
      </h1>

      <div className="privacy-policy-container">
        <p>
          <FormattedMessage id="LEGAL_PRIVACY_INTRO" />
        </p>
        <h2 className="headline">
          <FormattedMessage id="LEGAL_PRIVACY_RESPONSIBLE_HEADER" />
        </h2>
        <p>
          <FormattedMessage id="LEGAL_PRIVACY_RESPONSIBLE_DATA">
            {(message) => (
              <span dangerouslySetInnerHTML={{ __html: message[0] }} />
            )}
          </FormattedMessage>
        </p>
        {!isContentSectionDisplayed && privacyPolicy}
      </div>
    </PageSection>
  );
}

export default PrivacyPolicy;
