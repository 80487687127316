import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactSVG } from 'react-svg';

import menuIcon from '../../../../../assets/img/svg/menu-dots.svg';
import noPictureIcon from '../../../../../assets/img/svg/no-picture.svg';
import useOutsideClick from '../../../../../hooks/useOutsideClick';
import axiosInstance from '../../../../../utils/api';
import Button from '../../../../Button/Button';
import SpinnerComponent from '../../../../Spinner/SpinnerComponent';
import DateItem from '../../../../Tables/TableItems/DateItem';
import AnswerComments from '../AnswerComments';

export default function CommentsItem({
  isMobile,
  comment,
  date,
  gallery,
  id,
  user,

  openDialog,

  isOwnComment,

  answering,
  onCommentAnswer,
  onCommentDelete,
}) {
  const intl = useIntl();
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef();

  const [{ loadingGalleryData }, getGalleryData] = useAxios(
    `/galleries/${gallery.id}`,
    { manual: true }
  );
  const [galleryData, setGalleryData] = useState({});

  const [isAnswering, setIsAnswering] = useState(false);

  const onCommentsAnswerCancel = () => {
    setIsAnswering(false);
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await getGalleryData();
        setGalleryData(res.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [gallery, getGalleryData]);

  useOutsideClick(menuRef, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  const menuClassNames = classNames('btn-comments', {
    open: menuVisible,
    answering,
  });

  return (
    <>
      {!isMobile && (
        <tr>
          <td className="date">
            <DateItem data={date} />
          </td>
          <td className="user">
            {isOwnComment ? (
              <span>{user.nickname}</span>
            ) : (
              <Link
                onClick={() => openDialog(user.id, user.nickname)}
                to="/multi-messenger"
              >
                {user.nickname}
              </Link>
            )}
          </td>
          <td className="preview">
            <div className="comments-thumbnail-wrapper">
              <Link
                to={`/my-content/${
                  galleryData.media === 'movies' ? 'videos' : 'photos'
                }/edit-gallery?type=${galleryData.media}&id=${
                  gallery.id
                }&target=settings`}
              >
                {loadingGalleryData ? (
                  <SpinnerComponent className="spinner" />
                ) : galleryData.thumbnail?.thumbnail ? (
                  <div
                    className="comments-thumbnail"
                    style={{
                      backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${galleryData.thumbnail?.thumbnail}')`,
                    }}
                  />
                ) : (
                  <div className="comments-thumbnail no-image">
                    <ReactSVG
                      src={noPictureIcon}
                      wrapper="span"
                      className="icon"
                    />
                  </div>
                )}
              </Link>
            </div>
          </td>
          <td className="comment">
            <p className="title">
              <Link
                to={`/my-content/${
                  galleryData.media === 'movies' ? 'videos' : 'photos'
                }/edit-gallery?type=${galleryData.media}&id=${
                  gallery.id
                }&target=settings`}
              >
                {gallery.title?.content ??
                  intl.formatMessage({
                    id: 'COMMENTS_GALLERY_TITLE_NOT_AVAILABLE',
                  })}
              </Link>
            </p>
            <p>{comment}</p>
          </td>
          <td className="options">
            <Button
              intlTranslate={false}
              icon={menuIcon}
              variant="icon-only"
              className={menuClassNames}
              onClick={() => setMenuVisible(!menuVisible)}
            />
            {menuVisible && (
              <div
                className="toggle-menu comments-menu"
                id={`comments-menu${id}`}
                ref={menuRef}
              >
                <div>
                  {!isOwnComment && (
                    <div
                      className="menu-item"
                      onClick={() => {
                        setIsAnswering(true);
                        setMenuVisible(false);
                      }}
                    >
                      {intl.formatMessage({ id: 'COMMENTS_MENU_ANSWER' })}
                    </div>
                  )}

                  <div
                    className="menu-item"
                    onClick={() => {
                      axiosInstance({
                        url: `/galleries/${gallery.id}/comment/${id}`,
                        method: 'DELETE',
                      }).finally(() => {
                        setMenuVisible(false);
                        onCommentDelete(id);
                      });
                    }}
                  >
                    {intl.formatMessage({ id: 'COMMENTS_MENU_DELETE' })}
                  </div>
                </div>
              </div>
            )}
          </td>
        </tr>
      )}

      {isMobile && (
        <div className="comment-wrapper">
          <div className="comment-title-wrapper">
            <div className="comment-title">
              <span className="date">
                <DateItem data={date} />
              </span>
              {intl.formatMessage({ id: 'COMMENTS_FROM' })}
              {isOwnComment ? (
                <span>{user.nickname}</span>
              ) : (
                <span className="user">
                  <Link
                    onClick={() => openDialog(user.id, user.nickname)}
                    to="/multi-messenger"
                  >
                    <span>{user.nickname}</span>
                  </Link>
                </span>
              )}
            </div>
            <span className="options">
              <Button
                intlTranslate={false}
                icon={menuIcon}
                variant="icon-only"
                className={menuClassNames}
                onClick={() => setMenuVisible(!menuVisible)}
              />
              {menuVisible && (
                <div
                  className="toggle-menu comments-menu"
                  id={`comments-menu${id}`}
                  ref={menuRef}
                >
                  <div>
                    {!isOwnComment && (
                      <div
                        className="menu-item"
                        onClick={() => {
                          setIsAnswering(true);
                          setMenuVisible(false);
                        }}
                      >
                        {intl.formatMessage({ id: 'COMMENTS_MENU_ANSWER' })}
                      </div>
                    )}

                    <div
                      className="menu-item"
                      onClick={() => {
                        axiosInstance({
                          url: `/galleries/${gallery.id}/comment/${id}`,
                          method: 'DELETE',
                        }).finally(() => {
                          setMenuVisible(false);
                          onCommentDelete(id);
                        });
                      }}
                    >
                      {intl.formatMessage({ id: 'COMMENTS_MENU_DELETE' })}
                    </div>
                  </div>
                </div>
              )}
            </span>
          </div>
          <div className="comment">
            <p className="title">
              <Link
                to={`/my-content/${
                  galleryData.media === 'movies' ? 'videos' : 'photos'
                }/edit-gallery?type=${galleryData.media}&id=${
                  gallery.id
                }&target=settings`}
              >
                {gallery.title?.content ??
                  intl.formatMessage({
                    id: 'COMMENTS_GALLERY_TITLE_NOT_AVAILABLE',
                  })}
              </Link>
            </p>
            <p>{comment}</p>
          </div>
          {isAnswering && (
            <AnswerComments
              id={id}
              onCommentAnswer={onCommentAnswer}
              onCancel={onCommentsAnswerCancel}
            />
          )}
        </div>
      )}

      {isAnswering && !isMobile && (
        <tr className="answer-open">
          <td colSpan="5">
            <AnswerComments
              id={id}
              onCommentAnswer={onCommentAnswer}
              onCancel={onCommentsAnswerCancel}
            />
          </td>
        </tr>
      )}
    </>
  );
}

