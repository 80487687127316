import { connect } from 'react-redux';

import { userTokenLogin } from '../../../actions';
import TokenLogin from './TokenLogin';

const mapStateToProps = () => ({
  grantType: 'management',
});

const mapDispatchToProps = {
  userTokenLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenLogin);

