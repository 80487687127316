import commission from './commission';
import contest from './contest';
import dashboard from './dashboard';
import documents from './documents';
import kpi from './kpi';
import languages from './languages';
import onboarding from './onboarding';
import privateData from './privateData';
import profile from './profile';
import settings from './settings';
import virtualGifts from './virtualGifts';

export default {
  ...onboarding,
  ...dashboard,
  ...profile,
  ...settings,
  ...privateData,
  ...commission,
  ...documents,
  ...virtualGifts,
  ...kpi,
  ...contest,
  ...languages,

  FIELD_NICKNAME: `Nickname:`,
  FIELD_EMAIL: `Email:`,
  FIELD_FIRST_NAME: `First name:`,
  FIELD_LAST_NAME: `Last name:`,
  FIELD_BIRTHDAY: `Date of birth:`,
  FIELD_ZIP: `Zip`,
  FIELD_CITY: `City`,
  FIELD_COUNTRY: `Country:`,
  DO_NOT_SHOW_IN_FILTER: `Do not show my profile here`,
  FIELD_NATIVE_LANGUAGE: `Native language:`,
  FIELDS_LANGUAGES: `I also speak:`,
  MORE_LANGUAGES_DESCRIPTION: `Please only enter languages that you speak fluently in addition to your native language.`,
  FIELD_DESCRIPTION: `Description`,
  FIELD_ICEBREAKER_FSK:
    'We initiate the first contact with a large number of customers for you. How should we approach these men for you?',
  FIELD_ICEBREAKER_FSK16: `I'm more interested in flirting, I like it soft.`,
  FIELD_ICEBREAKER_FSK18: `I'm open to everything and like to talk about sexual topics right from the start. <span class="italic">Has a positive effect on your sales!</span>`,
  FIELD_ICEBREAKER_SAVING_ERROR: 'Error while saving',
  FIELD_PASSWORD: `Password:`,
  FIELD_LANGUAGE: `Language:`,
  FIELD_GENDER: `Gender:`,
  SELECT_GENDER_MALE: `Male`,
  SELECT_GENDER_FEMALE: `Female`,
  SELECT_GENDER_TRANSGENDER: `Transgender`,
  REMAINING_CHARS: `Remaining characters`,

  JANUARY: `January`,
  FEBRUARY: `February`,
  MARCH: `March`,
  APRIL: `April`,
  MAY: `May`,
  JUNE: `June`,
  JULY: `July`,
  AUGUST: `August`,
  SEPTEMBER: `September`,
  OCTOBER: `October`,
  NOVEMBER: `November`,
  DECEMBER: `December`,

  milf: `MILF`,
  lesbians: `Lesbians`,
  grannies: `Grannies`,
  asian: `Asian`,
  bisexuals: `Bisexuals`,
  blondes: `Blondes`,
  blackhaired: `Black-Haired`,
  dominatrixes: `Dominatrixes`,
  slaves: `Slaves`,
  european: `European`,
  exhibitionists: `Exhibitionists`,
  fetish: `Fetish`,
  gays: `Gays`,
  longhair: `Long Hair`,
  shorthair: `Short Hair`,
  smalltits: `Small Tits`,
  hangingtits: `Hanging Tits`,
  bigcocks: `Big Cocks`,
  bigtits: `Big Tits`,
  blacks: `Blacks`,
  bodybuilders: `Bodybuilders`,
  voyeurs: `Voyeurs`,
  transsexuals: `Transsexuals`,
  pregnantwomen: `Pregnant Women`,
  teens: `Teens`,
  roleplayers: `Role Players`,
  schoolgirls: `Schoolgirls`,
  redheads: `Redheads`,
  chubby: `Chubby`,
  latinas: `Latinas`,
  boys: `Boys`,
  slim: `Slim`,
  fatties: `Fatties`,
  transvestites: `Transvestites`,
  tattoospiercings: `Tattoos / Piercings`,
  brunettes: `Brunettes`,
  gangbang: `Gangbang`,
  swinger: `Swinger`,
  students: `Students`,
  bdsm: `BDSM`,
  middleeastern: `Arabic`,
  dating: `Dating`,
  northeuropean: 'Northeuropean',
  southeuropean: 'Southeuropean',
  arabic: 'Arabic',
  indian: 'Indian',
  african: 'Black / African American',
  latino: 'Latino / Hispanic',
  lightblue: 'light blue',
  blue: 'blue',
  brown: 'brown',
  green: 'green',
  bluegray: 'blue-gray',
  bluegreen: 'blue-green',
  greengray: 'green-gray',
  gray: 'gray',
  grazy: 'crazy color',
  lightblond: 'light blonde',
  blond: 'blonde',
  darkblond: 'dark blonde',
  darkbrown: 'dark brown',
  red: 'red',
  black: 'black',
  white: 'white',
  other: 'other color ',
  bald: 'bald',
  stubbles: 'stubbles',
  short: 'short',
  longer: 'longer',
  shoulder: 'shoulder',
  long: 'long',
  verylong: 'very long',
  figure_sportive: 'sportive',
  figure_normal: 'normal',
  figure_slim: 'slim',
  figure_thickish: 'thickish',
  figure_thick: 'thick',
  figure_fat: 'fat',
  figure_brawny: 'brawny',
  figure_athletic: 'athletic',
  none: 'none',
  A: 'A',
  AA: 'AA',
  B: 'B',
  C: 'C',
  D: 'D',
  DD: 'DD',
  F: 'F',
  G: 'G',
  normal: 'normal',
  little: 'little',
  fewmore: 'few more',
  strong: 'strong',
  shaved: 'shaved',
  pubic_hair_none: 'none',
  pubic_hair_little: 'little',
  pubic_hair_normal: 'natural',
  pubic_hair_strong: 'strong',
  pubic_hair_shaved: 'shaved',
  pubic_hair_partshaved: 'partly shaven',
  true: 'yes',
  false: 'no',
  read: 'reading glasses',
  tattoo_and_piercings_none: 'none',
  tattoo_and_piercings_one: 'one',
  tattoo_and_piercings_several: 'several',
  tattoo_and_piercings_many: 'many',
  single: 'single',
  married: 'married',
  divorced: 'divorced',
  separated: 'separated',
  notfixed: 'nothing fixed',
  partner: 'Partner',
  singleparent: 'Single parent',
  sometimes: 'sometimes',
  woman: 'a woman',
  man: 'a man',
  couple: 'a couple',
  transvestite: 'a transvestite',
  transexual: 'a transexual',
  bisexual: 'a bisexual',
  escapade: 'an escapade',
  relationship: 'a relationship',
  flirt: 'a flirt',
  emailsex: 'Email sex',
  affair: 'an affair',
  ons: 'ONS',
  frivolous_friendship: 'an exciting friendship',
  fetish_erotic: 'Fetish erotic',
  groupsex: 'Group sex',
  flowerpower: 'Vanilla sex',
  analsex: 'Anal sex',
  oralsex: 'Oral sex',
  submissive: 'being submissive',
  dominant: 'being dominant',
  toys: 'Sex toys',
  swinging: 'Swinger',
  outdoorsex: 'Outdoor sex',
  publicsex: 'Public sex',
  dessous: 'Lingerie',
  rpg: 'RPG',
  voyeurism: 'Voyeurism',
  not_available: 'not specified',
};
