import './Commission.scss';

import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { NOTIFICATION_WARNING } from '../../../constants';
import useGetIncompleteBankDetails from '../../../hooks/useGetIncompleteBankDetails';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Notification from '../../Notification/Notification';
import Scrollbar from '../../Scrollbar';

function Commission({ user, items }) {
  const history = useHistory();
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const incompleteBankDetails = useGetIncompleteBankDetails(user);
  const isBillingPeriodPage =
    history.location.pathname === '/my-account/commission/billing-period';

  const routes = items.map((route, i) => {
    return (
      <Route
        key={`/my-account/commission${route.path}`}
        path={`/my-account/commission${route.path}`}
        component={route.component}
      />
    );
  });

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-account-wrapper commission-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <Switch>
              {routes}
              <Redirect to="/" />
            </Switch>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            {!user?.account?.slave &&
              !(isBillingPeriodPage && isMobileScreen) && (
                <>
                  <h2 className="headline">
                    {isBillingPeriodPage
                      ? intl.formatMessage({ id: 'COMMISSION_HEADLINE_1' })
                      : intl.formatMessage({ id: 'COMMISSION_HEADLINE_2' })}
                  </h2>
                  {isBillingPeriodPage ? (
                    <>
                      <p>{intl.formatMessage({ id: 'COMMISSION_TEXT_1' })}</p>
                      <p className="bold blue">
                        {intl.formatMessage({ id: 'COMMISSION_TEXT_2' })}
                      </p>
                      <p>{intl.formatMessage({ id: 'COMMISSION_TEXT_3' })}</p>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'COMMISSION_TEXT_4',
                          }),
                        }}
                      ></p>
                      <p>
                        {intl.formatMessage({ id: 'COMMISSION_TEXT_PAY_1' })}
                      </p>
                      <p>
                        {intl.formatMessage({ id: 'COMMISSION_TEXT_PAY_2' })}
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        {intl.formatMessage({ id: 'COMMISSION_TEXT_PAY_1' })}
                      </p>
                      <p>
                        {intl.formatMessage({ id: 'COMMISSION_TEXT_PAY_2' })}
                      </p>
                      <p>
                        {intl.formatMessage({ id: 'COMMISSION_TEXT_PAY_3' })}
                      </p>
                      <p>
                        <span>
                          {intl.formatMessage({
                            id: 'COMMISSION_TEXT_PAY_4',
                          })}
                        </span>
                        <Link to={'/my-account/commission/billing-period'}>
                          {intl.formatMessage({
                            id: 'COMMISSION_TEXT_PAY_5',
                          })}
                        </Link>
                        <span>
                          {intl.formatMessage({
                            id: 'COMMISSION_TEXT_PAY_6',
                          })}
                        </span>
                      </p>
                      {incompleteBankDetails && (
                        <Notification
                          title={'BANK_ACCOUNT_MISSING_TITLE'}
                          id={'static_incomplete_bank_details'}
                          content={'BANK_ACCOUNT_MISSING_CONTENT'}
                          subcontent={'BANK_ACCOUNT_MISSING_SUBCONTENT'}
                          link={'/my-account/my-personal-details/bank-details'}
                          type={NOTIFICATION_WARNING}
                        />
                      )}
                    </>
                  )}
                </>
              )}
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Commission;

