import { connect } from 'react-redux';

import { changeFontsize } from '../../../actions';
import FontSizeSelect from './FontSizeSelect';

const mapStateToProps = (state) => ({
  ...state.settings,
  fontsize: state.preferences.fontsize,
});

const mapDispatchToProps = (dispatch) => ({
  onChange: (size) => dispatch(changeFontsize(size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FontSizeSelect);
