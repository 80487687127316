import { connect } from 'react-redux';

import { gameUtils, user as userUtils } from '../../../../utils';
import Overlay from './Overlay';

const mapStateToProps = (state) => {
  const user = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);

  if (!user || userUtils.isAdmin(user)) {
    return {};
  }

  if (
    !user?.partner &&
    userUtils.isDialogUser(user.type) &&
    !!userUtils.hasPartner(user)
  ) {
    user.partner = userUtils.getPartner(user);
  }

  const game = gameUtils.getGame(state, user.partner);

  if (!game || !gameUtils.isDecidedGame(game)) {
    return {};
  }
  let gameState = 'draw';
  if (game?.session?.decision === 'winner') {
    if (game?.session?.winnerId.replace('-', '@') === user.partner) {
      gameState = 'lost';
    } else {
      gameState = 'won';
    }
  }
  return {
    gameState,
  };
};

export default connect(mapStateToProps)(Overlay);
