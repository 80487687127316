import { user as userUtils } from '../../utils';
import select from './select';

export default () => (dispatch, getState) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    camUserFilter: { filter, messagesFilter, activities },
  } = getState();

  const dialogUserFiltered = userUtils.filter(
    filter,
    messagesFilter,
    [...userUtils.visibleUser(camUsers), ...userUtils.visibleUser(dialogs)],
    activities
  );

  // as livecamusers will never be at the end, we do not need to merge the livecam ist into this
  const endUser = dialogUserFiltered.pop();

  if (endUser) {
    dispatch(select(endUser.id));
  }
};
