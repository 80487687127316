import {
  CAM2CAM_EVENT_START,
  CAM2CAM_EVENT_STOP,
  MANAGEMENT_CAM_LOGOUT,
  MANAGEMENT_EVENTS_CAM2CAM_DISABLE,
} from '../actions/actions';

const initialState = {};

const cam2cam = (state = initialState, action) => {
  switch (action.type) {
    case CAM2CAM_EVENT_START:
      return { ...state, [action.payload.userId]: action.payload.stream };
    case CAM2CAM_EVENT_STOP: {
      const copyState = { ...state };
      delete copyState[action.payload.userId];
      return copyState;
    }
    case MANAGEMENT_CAM_LOGOUT:
      return initialState;
    case MANAGEMENT_EVENTS_CAM2CAM_DISABLE: {
      const copyState = { ...state };
      delete copyState[action.payload.userId];
      return copyState;
    }
    default:
      return state;
  }
};

export default cam2cam;
