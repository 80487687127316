import './FormErrorMessage.scss';

import React from 'react';

function FormErrorMessage({ isShown, title, message }) {
  return isShown ? (
    <div className="form-error-message">
      {title && <span className="title">{title}</span>}
      <span className="message">{message}</span>
    </div>
  ) : null;
}

export default FormErrorMessage;

