import { useIntl } from 'react-intl';

function UserProfileText({ label, text, display = false, language }) {
  const intl = useIntl();
  const displayItem = display || (text && text[language]);
  return displayItem ? (
    <>
      <tr className="no-border">
        <td className="bold">{intl.formatMessage({ id: label })}</td>
      </tr>
      <tr>
        <td>
          {!text || !text[language]
            ? intl.formatMessage({ id: 'not.specified' })
            : text[language]}
        </td>
      </tr>
    </>
  ) : null;
}

export default UserProfileText;
