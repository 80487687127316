import { WEBCAM_SELECT } from '../actions/actions';

const initialState = {
  selectedCam: {
    label: '',
    value: ''
  }
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case WEBCAM_SELECT: {
      const selectedCam = action.payload || state.selectedCam;
      return {...state, selectedCam}
    }
    default:
      return state;
  }
};

export default reduce;
