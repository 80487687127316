import * as Yup from 'yup';

export function getValidationScheme(intl) {
  return Yup.object().shape({
    comment: Yup.string()
      .trim()
      .min(16, intl.formatMessage({ id: 'VALIDATION_COMMENTS_TOO_SHORT' }, { n: 16 }))
      .max(255, intl.formatMessage({ id: 'VALIDATION_COMMENTS_TOO_LONG' }, { n: 255 }))
      .required(intl.formatMessage({ id: 'VALIDATION_REQUIRED_FIELD' }))
      .test({
        test: function (value) {
          console.log('Validation value:', value);
          const useme = String(value).trim();
          if (
            typeof useme === 'string' &&
            useme.match(/^.*(https?:\/\/|www\..+)+.*$/gim)
          ) {
            return this.createError({
              message: intl.formatMessage({ id: 'VALIDATION_NO_URLS' }),
            });
          }
          if (typeof useme === 'string' && useme.match(/<[/?a-zA-Z]+[/?0-9]*>/g)) {
            return this.createError({
              message: intl.formatMessage({ id: 'VALIDATION_NO_TAGS' }),
            });
          }

          return true;
        },
      }),
  });
}
