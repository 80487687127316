import { CHECK_STREAM_TIMEOUT } from '../../../constants';
import {
  LOG_HIGH_BANDWIDTH,
  MANAGEMENT_LOGGED_IN,
  MAX_ZEROBYTES_RECONNECT_TRIES,
  STREAMING_STARTED,
} from '../../../constants';
import managementService from '../../../services/management';
import { incrementZerobytesReconnect, management } from '../../';
let timer = null;

const start = () => (dispatch, getState) => {
  console.log('timerCheckStream start');

  if (timer !== null) {
    return;
  }
  let bytesSentCheck = 0;
  let highBandwidthLogged = false;
  timer = setInterval(() => {
    const {
      streaming: { managementStep, permanentStream, step, zeroBytesReconnects },
      streamingstats: { bytesSent, bandwidth },
    } = getState();

    if (!permanentStream) {
      stop();
      return;
    }

    if (managementStep !== MANAGEMENT_LOGGED_IN) {
      return;
    }

    if (step !== STREAMING_STARTED) {
      return;
    }

    console.log('timerCheckstream run');

    if (!highBandwidthLogged && bandwidth > LOG_HIGH_BANDWIDTH) {
      highBandwidthLogged = true;
      managementService.log.splunk.info(`High Bandwidth: ${bandwidth}`);
    }

    // @see LCJS-5406
    // const randomTrue = Math.floor(Math.random() * Math.floor(10)) % 2 === 0;
    // console.log({randomTrue});
    // add randomTrue to the next if
    // if (bytesSent > bytesSentCheck && !randomTrue) {
    // to test incrementZerobytesReconnect

    if (bytesSent > bytesSentCheck) {
      bytesSentCheck = bytesSent;
      return;
    }

    if (zeroBytesReconnects < MAX_ZEROBYTES_RECONNECT_TRIES) {
      dispatch(incrementZerobytesReconnect(zeroBytesReconnects));
      managementService.log.splunk.info(
        `reconnect_zero_bytes:${zeroBytesReconnects}`
      );
      return dispatch(management.events.streamReconnect());
    }

    dispatch(
      management.logoutCam({ kickMessage: 'alert.lowFps', translate: true }, 18)
    );
  }, CHECK_STREAM_TIMEOUT);
};

const stop = () => () => {
  if (timer) {
    console.log('timerCheckStream stop');
    clearTimeout(timer);
    timer = null;
  }
};

export default {
  start,
  stop,
};
