import { connect } from 'react-redux';

import { userUpdate as update } from '../../../actions';
import Doi from './Doi';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  update,
};

export default connect(mapStateToProps, mapDispatchToProps)(Doi);
