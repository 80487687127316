const extensionRegex = new RegExp('[^.]+$');
const supportedImageTypes = ['jpg', 'jpeg', 'gif', 'png'];

const getFileType = (file) => file?.match(extensionRegex)[0];

const isImage = (file) => supportedImageTypes.includes(getFileType(file));

export default {
  getFileType,
  isImage,
};
