import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import errorIcon from '../../../assets/img/svg/error.svg';

function PhotosInfo() {
  return (
    <>
      <h2 className="headline">
        <FormattedMessage id="WHAT_OPTIONS_DO_I_HAVE" />
      </h2>
      <p>
        <FormattedMessage id="PHOTOS_INFO_1">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="PHOTOS_INFO_1_1" />
      </p>
      <p>
        <FormattedMessage id="GALLERY_INFO_1">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="PHOTOS_INFO_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="PHOTOS_INFO_3">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="GALLERY_INFO_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="GALLERY_INFO_3">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="GALLERY_INFO_4">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="GALLERY_INFO_5">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="GALLERY_INFO_6">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <div className="badge-description">
        <span className="badge blue">
          <FormattedMessage id="unpublished" />
        </span>
        <FormattedMessage id="GALLERY_INFO_6_1" />
      </div>
      <div className="badge-description">
        <span className="badge orange">
          <FormattedMessage id="pending" />
        </span>
        <FormattedMessage id="GALLERY_INFO_6_2" />
      </div>
      <div className="badge-description">
        <span className="badge green">
          <FormattedMessage id="scheduled" />
        </span>
        <FormattedMessage id="GALLERY_INFO_6_3" />
      </div>
      <div className="badge-description">
        <span className="badge green">
          <FormattedMessage id="published" />
        </span>
        <FormattedMessage id="GALLERY_INFO_6_4" />
      </div>
      <div className="badge-description">
        <span className="badge red">
          <FormattedMessage id="archived" />
        </span>
        <FormattedMessage id="GALLERY_INFO_6_5" />
      </div>
      <div className="badge-description">
        <span className="badge red">
          <FormattedMessage id="blocked" />
        </span>
        <FormattedMessage id="GALLERY_INFO_6_6" />
      </div>
      <div className="badge-description">
        <span className="badge red rejection-badge">
          <ReactSVG src={errorIcon} className="icon" wrapper="span" />
        </span>
        <FormattedMessage id="GALLERY_INFO_6_7" />
      </div>
    </>
  );
}

export default PhotosInfo;
