export const getImageSource = (file, intl) => {
  return new Promise((resolve, reject) => {
    if (!file)
      reject(new Error(intl.formatMessage({ id: 'UPLOADER_NO_FILE' })));
    const reader = new FileReader();

    function onLoad() {
      reader.removeEventListener('load', onLoad);
      resolve(reader.result);
    }

    reader.addEventListener('load', onLoad, false);
    reader.readAsDataURL(file);
    setTimeout(() => {
      reader.removeEventListener('load', onLoad);
      reject(new Error(intl.formatMessage({ id: 'UPLOADER_FILE_TIMEOUT' })));
    }, 120000);
  });
};

export const checkImage = (imageSource, intl, minDimensions) => {
  return new Promise((resolve, reject) => {
    if (!imageSource)
      reject(new Error(intl.formatMessage({ id: 'UPLOADER_NO_SOURCE' })));
    const img = new Image();
    img.src = imageSource;
    img.onload = function () {
      if (!img.naturalWidth || !img.naturalHeight) {
        return reject(
          new Error(intl.formatMessage({ id: 'UPLOADER_BROKEN_IMAGE' }))
        );
      }

      if (
        img.naturalWidth < minDimensions.width ||
        img.naturalHeight < minDimensions.height
      ) {
        return reject(
          new Error(
            intl.formatMessage(
              {
                id: 'UPLOADER_INVALID_DIMENSIONS',
              },
              {
                minWidth: minDimensions.width,
                minHeight: minDimensions.height,
              }
            )
          )
        );
      }
      resolve(imageSource);
    };
    setTimeout(() => {
      img.onload = function () {};
      reject(new Error(intl.formatMessage({ id: 'UPLOADER_TIMEOUT' })));
    }, 120000);
  });
};
