import { NO_ARRAY } from '../../errors';
import { UPDATE_MICROPHONE } from '../actions';

export default (err, microphones) => {
  if (err) {
    return {
      type: UPDATE_MICROPHONE,
      payload: err,
      error: true,
    };
  }
  if (!Array.isArray(microphones)) {
    return {
      type: UPDATE_MICROPHONE,
      payload: new Error(NO_ARRAY),
      error: true,
    };
  }
  return {
    type: UPDATE_MICROPHONE,
    payload: { microphones },
  };
};
