import { management } from '../../services';
import { default as loginCam } from './cam/login';
import { default as logoutCam } from './cam/logout';
import { default as startCam2Cam } from './cam2cam/start';
import { default as stopCam2Cam } from './cam2cam/stop';
import { default as checkuserTimer } from './checkuser/timer';
import { default as connect } from './connect';
import { default as events } from './events';
import { default as confirmExclusive } from './exclusive/confirm';
import { default as declineExclusive } from './exclusive/decline';
import { default as logfpsTimer } from './logfps/timer';
import { default as sendMessage } from './message/sender/send';
import { default as sendMessage2All } from './message/sender/send2All';
import { default as typingMessage } from './message/sender/typing';
import { default as disableMicrophone } from './microphone/disable';
import { default as enableMicrophone } from './microphone/enable';
import { default as switchOrigins } from './origins/switch';
import { default as requestPrivateAudioSession } from './privateAudioSession/request';
import { default as startSenderAudio } from './senderAudio/start';
import { default as stopSenderAudio } from './senderAudio/stop';
import { default as startedSenderStream } from './stream/sender/started';
import { default as startedStream } from './stream/started';
import { default as streaminfoTimer } from './streaminfo/timer';
import { default as declineUpgrade } from './upgrade/decline';
import { default as askUpgradeSender } from './upgrade/sender/ask';
import { default as startUpgrade } from './upgrade/start';
import { default as kickUser } from './user/kick';

export default {
  events: events(management),
  startedSenderStream: startedSenderStream(management),
  streaminfoTimer: streaminfoTimer(management),
  logfpsTimer: logfpsTimer(management),
  connect: connect(management),
  logoutCam: logoutCam(management),
  loginCam: loginCam(management),
  startedStream: startedStream(management),
  sendMessage: sendMessage(management),
  sendMessage2All: sendMessage2All(management),
  typingMessage: typingMessage(management),
  switchOrigins: switchOrigins(management),
  kickUser: kickUser(management),
  checkuserTimer: checkuserTimer(management),
  confirmExclusive,
  declineExclusive,
  declineUpgrade: declineUpgrade(management),
  startUpgrade: startUpgrade(management),
  askUpgradeSender: askUpgradeSender(management),
  enableMicrophone: enableMicrophone(management),
  disableMicrophone: disableMicrophone(management),
  requestPrivateAudioSession: requestPrivateAudioSession(management),
  startSenderAudio: startSenderAudio(management),
  stopSenderAudio: stopSenderAudio(management),
  startCam2Cam: startCam2Cam(management),
  stopCam2Cam: stopCam2Cam(management),
};
