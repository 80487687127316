import { user as userUtils } from '../../../utils';
import { DIALOG_USER_ONLINE } from '../../actions';
import { closeOverlay } from '../../overlay';

export default (payload) => (dispatch, getState) => {
  dispatch(closeOverlay({ overlayType: 'IdleDialog' }));
  const {
    dialog: { dialogs },
  } = getState();
  const allDialogs = [...dialogs];
  if (!userUtils.get(allDialogs, payload.user)) {
    return;
  }
  dispatch({ type: DIALOG_USER_ONLINE, payload });
};
