import { user as userUtils } from '../../../utils';
import { management } from '../../';
import { USERAUDIO_EVENT_STOP } from '../../actions';

export default (userId) => {
  return (dispatch, getState) => {
    const userList = getState().camUser.camUsers;
    userList.forEach((user) => {
      if (user.userId !== userId && !userUtils.isAdmin(user)) {
        console.log(`Sending start sender audio to ${user.userId}`);
        dispatch(management.startSenderAudio(user.userId));
      }
    });
    dispatch({
      type: USERAUDIO_EVENT_STOP,
      payload: {
        userId,
      },
    });
  };
};
