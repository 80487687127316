import { user as userUtils } from '../../../utils';
import api from '../../../utils/api';
import { USER_CAPACITY_GET } from '../../actions';

export default () => async (dispatch, getState) => {
  try {
    if (!userUtils.userLoggedInAndConfirmed(getState())) return;
    const response = await api.get(`/user/account`);

    if (
      !userUtils.userLoggedInAndConfirmed(getState()) ||
      !response?.data?.capacity
    ) {
      return;
    }
    dispatch({
      type: USER_CAPACITY_GET,
      payload: response.data.capacity,
    });
  } catch (err) {
    console.log({ getCapacityError: err });
    console.log('could not get capacity');
  }
};
