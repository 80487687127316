import { MESSAGE_CHAT_REMOVE } from '../../actions';

export default (id, userId) => {
  return {
    type: MESSAGE_CHAT_REMOVE,
    payload: {
      id,
      userId,
    },
  };
};
