import { uuid } from '../../utils';
import { FRIEND_DELETE } from '../actions';

export default (userId, requestSent, name) => (dispatch) => {
  if (requestSent) {
    return dispatch({
      type: FRIEND_DELETE,
      payload: { id: userId },
    });
  }

  return dispatch({
    type: FRIEND_DELETE,
    payload: { id: userId, name },
    meta: {
      start: true,
      stamp: uuid(),
    },
  });
};
