import { sender, uuid } from '../../../utils';
import { MANAGEMENT_ORIGINS_SWITCH } from '../../actions';
import { default as managementActions } from '../';

export default (management) => (logins, roomTypes) => {
  return (dispatch, getState) => {
    management.emit(
      'switchOrigins',
      sender.getLoginData(logins, roomTypes),
      (err) => {
        if (err) {
          dispatch(managementActions.logoutCam({}, 7));
          return dispatch({
            type: MANAGEMENT_ORIGINS_SWITCH,
            payload: err,
            error: true,
            meta: {
              stamp: uuid(),
            },
          });
        }
        dispatch({
          type: MANAGEMENT_ORIGINS_SWITCH,
          payload: {
            logins,
            roomTypes,
          },
          meta: { start: true },
        });
        setTimeout(() => {
          const {
            sender: { switchoriginbuttonlock },
          } = getState();
          if (!switchoriginbuttonlock) {
            return;
          }
          dispatch({
            type: MANAGEMENT_ORIGINS_SWITCH,
            meta: { end: true },
          });
        }, 1000);
      }
    );
  };
};
