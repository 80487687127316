export default {
  MULTI_MESSENGER_HEADLINE: `The main menu of the Multi Messenger`,
  MULTI_MESSENGER_INTRO: `With LiveCreator's Multi Messenger, you always have a full overview and manage all your chats, customers and cam shows easily and simultaneously.`,
  MULTI_MESSENGER_INTRO_1: `Don't miss any message, no new or regular customers!<br />
  Always keep an eye on everything – anytime, anywhere – even on your smartphone.<br />
  Work through your chats in no time - whether you're doing a cam show or just chatting!`,
  MULTI_MESSENGER_INTRO_2: `The structure of the Multi Messenger menu is logical and intuitive:`,
  MULTI_MESSENGER_INTRO_3: `In your messenger, your active chats can be seen under “All”. We have divided them into the filters “Unread”, “Unanswered” and “New Users” so that you can quickly see which chats have not been edited.<br /> Your favorites and friends will also appear in the corresponding filters.
The filters apply to the currently loaded chats. Newly arriving chats are automatically categorized depending on which filter applies.  You can find older contacts in the “Relationships” menu item, in the corresponding Friends or Favorites category.`,

  MULTI_MESSENGER_HEADLINE_1: `The message filters`,

  MULTI_MESSENGER_FILTER_STEP_1: `All`,
  MULTI_MESSENGER_FILTER_STEP_1_CONTENT: `Here you can see your previous conversations. With this filter you always have an overview of all your chats and can switch between them without detours.`,
  MULTI_MESSENGER_FILTER_STEP_1_1_CONTENT: `<span class="bold">Tip:</span> Use the arrow keys on your keyboard to navigate even faster! This saves you valuable time and allows you to concentrate fully on your conversations.`,

  MULTI_MESSENGER_FILTER_STEP_2: `Unread`,
  MULTI_MESSENGER_FILTER_STEP_2_CONTENT: `Unread messages are those that you have received but have not yet read. It's important to keep an eye on your unread messages to make sure you're always up to date and don't keep your users waiting. By responding quickly, you'll show them that you take their conversations seriously and give them full attention.`,
  MULTI_MESSENGER_FILTER_STEP_2_1_CONTENT: `Keep in mind that a chat is only successful if both sides actively participate in it. If a message goes unread for too long, it disrupts the flow of the conversation and can cause your users to turn away from you. So don't hesitate to open and reply to your unread messages.`,

  MULTI_MESSENGER_FILTER_STEP_3: `Unanswered`,
  MULTI_MESSENGER_FILTER_STEP_3_CONTENT: `Unanswered messages are those that you have already read but not yet answered. It's important that you don't just receive messages, but also respond to them. This is the only way to keep the chat going and maintain good communication. The users who have written to you are waiting for you to reply to them and are eager to see your reaction. By replying to them, you show them that you've read their messages and that you care about them.`,
  MULTI_MESSENGER_FILTER_STEP_3_1_CONTENT: `There are good reasons why messages sometimes go unanswered, maybe you just didn't have time to reply, or you forgot the message. But it's important that you take time to respond to the messages, as this is one way to maintain a connection.`,

  MULTI_MESSENGER_FILTER_STEP_4: `New user`,
  MULTI_MESSENGER_FILTER_STEP_4_CONTENT: `When you're online and get messages from users, you'll make new contacts and attract potential customers. That's why in this menu item we show you the chats in which users write to you for the first time after an icebreaker has been sent. It is important to act quickly so that the user is not disappointed. By responding to him immediately, you can retain him and win a new regular customer.`,

  MULTI_MESSENGER_FILTER_STEP_5: `Favorites`,
  MULTI_MESSENGER_FILTER_STEP_5_CONTENT: `This menu item is a great way to organize your chats and tag your regular customers. You have full control over who you mark as a favorite. If you feel like you've had a great chat with a user and they might be a potential repeat customer, mark them as favorites by clicking on the star at the top of your chat. This way, you can quickly and easily access his messages and revive or resume the chat. But be careful not to include every user in this list. Otherwise it will quickly become confusing! Use this filter to organize your good customers in an easily accessible way for you.`,
  MULTI_MESSENGER_FILTER_STEP_5_1_CONTENT: `<span class="bold">Tip:</span> Customers won't see when you mark them as "favorites".`,

  MULTI_MESSENGER_FILTER_STEP_6: `Friends`,
  MULTI_MESSENGER_FILTER_STEP_6_CONTENT: `This filter may seem empty at first, but that's about to change. Once you're online a few times, you'll notice that you're getting friend requests from a lot of users. But before you simply accept them, you should take the time to write to the respective user and establish active communication. After all, what's the point of having a bunch of friends you never talk to? You can find your friend requests in your activities in Multi Messenger or under the blue heart in your main menu.`,

  MULTI_MESSENGER_FILTER_STEP_7: `Games`,
  MULTI_MESSENGER_FILTER_STEP_7_CONTENT: `We'll show you invitations to a {link} match and current games here.`,
  MULTI_MESSENGER_FILTER_STEP_7_LINK: `4Score`,

  MULTI_MESSENGER_HEADLINE_2: `Activities`,
  MULTI_MESSENGER_ACT_STEP_1: `Profile visitors`,
  MULTI_MESSENGER_ACT_STEP_1_CONTENT: `Here you will see new customers who are currently visiting your profile. If you click on a customer's name, you may also be able to see the first icebreaker in the chat.`,
  MULTI_MESSENGER_ACT_STEP_1_1_CONTENT: `Now it gets particularly exciting, because the user is currently looking at your profile. If he likes what he reads and your picture appeals to him, there's a good chance he'll write to you and get in touch with you. However, you will not be able to contact this customer until they have responded and written to you. Don't worry if the user doesn't respond directly. We'll send him more icebreakers on your behalf to get him interested in you.`,

  MULTI_MESSENGER_ACT_STEP_2: `Customer logins`,
  MULTI_MESSENGER_ACT_STEP_2_CONTENT: `Customers are users who have already spent money with you. This can be done through chat, webcam, or purchasing one of your galleries. You have convinced the customer of yourself, be it with your pictures or your personality. Since he's online right now, take the chance to remind him of you again. Don't write him a ready-made copy-and-paste message, but a personal one with information that you have previously collected about him in your notes. This means that the chances of engaging the customer in an active chat again are high.`,

  MULTI_MESSENGER_ACT_STEP_3: `Friend logins`,
  MULTI_MESSENGER_ACT_STEP_3_CONTENT: `Friends are users whose friend request you have already accepted. If one of your friends is logging in, we'll show them to you immediately below this point. This way, you don't have to constantly search through your friends list to find out which of them is currently online. You'll instantly see who's online, and you can send them a nice message to rekindle or continue the chat.`,

  MULTI_MESSENGER_ACT_STEP_4: `Friend requests`,
  MULTI_MESSENGER_ACT_STEP_4_CONTENT: `Friend requests are users who either already have contact with you or may want to make contact with a friend request. Look at the user's chat and profile to see if you're already in touch. If you haven't written a message with each other yet, this is your chance to bind the user to you and see if your interests match before you accept the friend request. You can write to customers who have sent you a friend request.`,

  MULTI_MESSENGER_ACT_STEP_5: `Content purchases`,
  MULTI_MESSENGER_ACT_STEP_5_CONTENT: `Here we show you every user who has just bought something from you. He's active on your profile, and your content appealed to him. Leave him a thank you note or start the conversation with him. Maybe he'll tell you what he's in the mood for or what he wants to buy from you next.`,

  MULTI_MESSENGER_HEADLINE_3: `Features`,
  MULTI_MESSENGER_FEAT_STEP_1: `Icebreaker`,
  MULTI_MESSENGER_FEAT_STEP_1_CONTENT: `In addition to the display in the main menu, you can also see here whether icebreakers are currently being sent for you.`,

  MULTI_MESSENGER_FEAT_STEP_2: `Lovense Toy`,
  MULTI_MESSENGER_FEAT_STEP_2_CONTENT: `If you have connected a {link} to your LiveCreator account, it is possible to select and start the toy here.`,
  MULTI_MESSENGER_FEAT_STEP_2_LINK: `Lovense Toy`,

  MULTI_MESSENGER_FEAT_STEP_3: `Livecam`,
  MULTI_MESSENGER_FEAT_STEP_3_CONTENT: `If you have selected your webcam under {link}, you can start and end your stream directly in Multi Messenger.`,
  LINK_TEXT_CAM_SETTINGS: `{account} - Settings - Livecam`,
  MULTI_MESSENGER_FEAT_STEP_3_1_CONTENT: `<span class="bold">Start</span><br />After clicking on the green “Start streaming” button, you will be asked to enter a motto for your show. This is not a must, you can also start the stream without a motto.`,
  MULTI_MESSENGER_FEAT_STEP_3_2_CONTENT: `<span class="bold">Stop</span><br />After clicking on the red “Stop streaming” button, your livecam will go offline in LiveCreator and in all of our communities.`,
  MULTI_MESSENGER_FEAT_STEP_3_3_CONTENT: `We answer the most {link} about the livecam in the Wiki.`,
  MULTI_MESSENGER_FEAT_STEP_3_3_CONTENT_LINK: `frequently asked questions`,
};
