import React from 'react';
import { useIntl } from 'react-intl';

import Spinner from './Spinner';

function UploadSpinner({
  active = false,
  indicator = null,
  uploads = [],
  ...rest
}) {
  const intl = useIntl();

  const upload = uploads.find((upload) => upload?.indicator === indicator);
  const percent = upload?.percent;

  return active && percent ? (
    <div className="spinner-modal">
      <Spinner {...rest} />
      <div className="percent-wrapper">
        {intl.formatMessage({ id: 'UPLOADING' }) + ` ${percent}%`}
      </div>
      <div className="background" />
    </div>
  ) : null;
}

export default UploadSpinner;
