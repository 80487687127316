import { user as userUtils } from '../../utils';

export default () => {
  return (dispatch, getState) => {
    console.log('Stop User Audio called');

    const privateAudioSessionUser = userUtils.privateAudioSessionUser(
      getState().camUser.camUsers
    );
    if (!privateAudioSessionUser || !privateAudioSessionUser.webRTCConnection) {
      return;
    }

    privateAudioSessionUser.webRTCConnection.stop();
  };
};
