export const NO_DEVICE = 'no device is given';
export const NO_RESOLUTION = 'no valid resolution given';
export const NO_GETUSERMEDIA = 'browser does not support getUserMedia';
export const NO_ARRAY = 'value is no array';
export const STREAMING_NOT_POSSIBLE = 'streaming not possible';
export const NO_SERVER_AVAILABLE = 'no server available';
export const USERNOTE_OPEN_ERROR = 'get usernote not possible';
export const USERNOTE_CONFIRM_ERROR = 'put usernote not possible';
export const NO_ORIGIN = 'no origin left';
export const NO_ORIGIN_EXCLUSIVE = 'no origin found for exclusive';
export const SERVER_ERROR = 'server error';
export const USER_NOT_FOUND = 'user not found';
export const TIMEOUT = 'timeout';
export const ABORT = 'abort';
export const BANDWIDTH_NOT_COMPLETED = 'bandwidth not completed';
export const NOT_ENOUGH_BANDWIDTH = 'not enough bandwidth';
export const PASSWORD_EMPTY = 'password empty';
export const USERNAME_EMPTY = 'username empty';
export const CHANGE_BANDWIDTH_ERROR = 'change bandwidth error';
