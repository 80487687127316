import log from '../../utils/logApi';

export default (payload) => (dispatch, getState) => {
  if (!getState().user?.isLoggedIn) return;
  const {
    user: {
      account: { id: userId, nickname: sendername, language, state },
    },
  } = getState();
  const { function: location, data } = payload;
  const logObject = {
    location,
    message: 'function',
    userId,
    sendername,
    language,
    state,
    level: 'info',
    time: new Date(),
    data,
  };
  log(logObject);
};
