import { connect } from 'react-redux';

import {
  userRefreshAuthToken as refreshAuthToken,
  spinner,
} from '../../../actions';
import Signup from './Signup';

const mapDispatchToProps = {
  refreshAuthToken,
  spinner,
};

export default connect(null, mapDispatchToProps)(Signup);
