import './PerformanceIndicator.scss';

import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import questionMarkIcon from '../../../assets/img/svg/question-mark.svg';
import notAvailableIcon from '../../../assets/img/svg/question-mark.svg';
import TooltipIcon from '../../TooltipIcon/TooltipIcon';

function PerformanceIndicator({ label, value, tooltip, position }) {
  const isNA = typeof value !== 'number';
  const indicatorClass = classNames('performance-indicator', {
    green: value > 0,
    red: value < 0,
    yellow: value === 0,
    na: isNA,
  });
  const needleClass = classNames('needle', isNA ? 'na' : `pos${value}`);
  return (
    <div className={indicatorClass}>
      <div className="performance-title">
        <div className="wrapper">
          <span className="performance-label">{label}</span>
          {tooltip && (
            <TooltipIcon
              position={position}
              tooltip={tooltip}
              icon={notAvailableIcon}
            />
          )}
        </div>
        <span className="indicator">
          {value > 0 ? '+' : ''}
          {isNA ? 'n/a' : value}
        </span>
      </div>
      <div className="circle-container">
        <div className="color-circle">
          <div className="white-circle">
            <div className="empty-circle" />
          </div>
        </div>
        <div className="inner-circle" />
        {!isNA && <div className="needle-dot" />}
        {!isNA && <div className={needleClass} />}
        {isNA && (
          <ReactSVG
            src={questionMarkIcon}
            className="not-available"
            wrapper="div"
            renumerateIRIElements={false}
          />
        )}
      </div>
    </div>
  );
}

export default PerformanceIndicator;
