import { ORIGIN_TOGGLE, ROOM_TYPES_CHANGE } from '../../actions/actions';
import {
  ALERT_LEVEL_ERROR,
  ALERT_LEVEL_INFO,
  ALERT_TYPE_CLOSE,
} from '../../constants';
import { NO_ORIGIN } from '../../errors';
import { alert as alertUtils } from '../../utils';

const initialState = [];

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case ORIGIN_TOGGLE: {
      if (!action.error) {
        return state;
      }

      if (
        action.payload.message === NO_ORIGIN &&
        alertUtils.hasMessage(state, 'alert.oneOrigin')
      ) {
        return state;
      }

      let message = action.error;
      if (action.payload.message === NO_ORIGIN) {
        message = {
          translate: true,
          id: 'alert.oneOrigin',
        };
      }

      return [
        ...state,
        {
          stamp: action.meta.stamp,
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_INFO,
          scope: 'livecam',
          message,
        },
      ];
    }
    case ROOM_TYPES_CHANGE:
      if (action.error) {
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_ERROR,
            scope: 'livecam',
            message: {
              translate: true,
              id: 'alert.exclusiveChangeNotPossible',
            },
          },
        ];
      }
      return state;
    default:
      return state;
  }
};

export default reduce;
