import './StreamingInfo.scss';

import React from 'react';
import { injectIntl } from 'react-intl';

const StreamingInfo = ({
  selectedCam,
  selectedResolution,
  bandwidth,
  fps,
  camInitialized,
  intl,
}) => {
  const fpsHint = intl.formatMessage({ id: 'streamingInfo.fpsHint' });
  const fpsNumber = fps || 0;
  const fpsClass =
    fpsNumber < 10 && fpsNumber > 5
      ? 'fps-medium'
      : fpsNumber <= 5 && fpsNumber > 0
      ? 'fps-low'
      : 'fps-normal';
  const fpsClasses = ['data', 'fps-info', fpsClass];
  const fpsTitle =
    fpsClass === 'fps-low' || fpsClass === 'fps-medium' ? fpsHint : '';
  return camInitialized ? (
    <div className="stream-info">
      <div className="data">
        <span>{intl.formatMessage({ id: 'streamingInfo.resolution' })}</span>
        {selectedResolution}
      </div>
      <div className="data">
        <span>{intl.formatMessage({ id: 'streamingInfo.upload' })}</span>
        {intl.formatMessage(
          { id: 'streamingInfo.upload.value' },
          {
            bandwidth: bandwidth
              ? intl.formatNumber(bandwidth, { maximumFractionDigits: 0 })
              : 0,
          }
        )}
      </div>
      <div className={fpsClasses.join(' ')} title={fpsTitle}>
        <span>{intl.formatMessage({ id: 'streamingInfo.fps' })}</span>
        {fps > 0 ? intl.formatNumber(fps, { maximumFractionDigits: 2 }) : 0}
      </div>
    </div>
  ) : null;
};

export default injectIntl(StreamingInfo);
