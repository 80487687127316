export default {
  VERIFF_VERIFY_BUTTON: 'Start verification',
  VERIFF_BAR_MANDATORY_TEXT:
    'Your account needs to be verified. Please start the process here.',
  VERIFF_BAR_PENDING_TEXT:
    'Your account is currently being reviewed by support and will be ready again in a few minutes. We ask for your patience. Thank you.',
  VERIFF_OVERLAY_HEADLINE: 'Your account needs to be verified',
  VERIFF_OVERLAY_TEXT_1:
    "To continue using your Creator Account, we need to verify your personal information and ID documents. This is required by our Terms of Service and legal regulations (Anti-Money Laundering Act). Until the verification is complete, you won't be able to use the Multi Messenger, and your payouts will be on hold.",
  VERIFF_OVERLAY_TEXT_2:
    'We work with the certified and independent service provider Veriff.com for this process.',
  VERIFF_OVERLAY_TEXT_3: `Here's how it works:<br />
    <ul>
    <li>You need a smartphone with a camera or a webcam on your computer and your ID documents.</li>
    <li>Veriff will guide you step by step through the verification process.</li>
    <li>Once the verification is finished, your account will be fully restored within a few minutes.</li>
    </ul>
    `,
  'alert.veriffError':
    'Connection error. Please try again in a few minutes by reloading the page. If it does nto work even after about half an hour, contect the support.',
};
