import { camLogoutTimer, toyTimer, wakeLock } from '../../../actions';
import { uuid } from '../../../utils';
import { MANAGEMENT_CAM_LOGOUT } from '../../actions';

export default (management) =>
  (payload, logoutReason = 0) =>
  (dispatch, getState) => {
    if (getState().sender?.runToy?.countDown > 0) {
      dispatch(toyTimer.stop());
    }

    dispatch({
      type: MANAGEMENT_CAM_LOGOUT,
      payload,
      meta: {
        start: true,
        stamp: uuid(),
      },
    });
    dispatch(wakeLock.release());
    setTimeout(() => {
      const { streaming } = getState();
      if (!streaming || !streaming.streambuttonlock) {
        return;
      }
      dispatch({
        type: MANAGEMENT_CAM_LOGOUT,
        payload,
        meta: {
          stop: true,
        },
      });
    }, 3000);

    dispatch(camLogoutTimer.start());

    management.emit('camLogout', {
      logoutParams: {},
      logoutReason,
    });
  };
