import { connect } from 'react-redux';

import {
  answerFriendRequest,
  friendsError,
  spinner,
  updateListFriendRequest,
} from '../../../../../actions';
import FriendRequests from './FriendRequests';

const mapStateToProps = (state) => ({
  dialog: state.dialog.dialogs,
  askAnswerFriendRequestConfirmed:
    state.friends.askAnswerFriendRequestConfirmed,
  askAnswerFriendRequestConfirmedAlert:
    state.friends.askAnswerFriendRequestConfirmedAlert,
  askAnswerFriendRequestId: state.friends.askAnswerFriendRequestId,
  askAnswerFriendRequestType: state.friends.askAnswerFriendRequestType,
  askAnswerFriendRequestNickname: state.friends.askAnswerFriendRequestNickname,
});

const mapDispatchToProps = {
  spinner,
  answerFriendRequest,
  friendsError,
  updateListFriendRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(FriendRequests);

