import { connect } from 'react-redux';

import TermsAndConditions from './TermsAndConditions';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  isMobile: state.browser.isMobile,
});

export default connect(mapStateToProps)(TermsAndConditions);
