import api from '../../../utils/api';
import userUtils from '../../../utils/user';
import { errorLog } from '../../';
import { USER_PROFILE_GET } from '../../actions';

export default function (user) {
  return async (dispatch, getState) => {
    if (!getState().user?.isLoggedIn) {
      return;
    }
    let userId = user.userId;
    let partner;
    if (user?.partner) {
      partner = user?.partner;
    } else if (userUtils.hasPartner(user)) {
      partner = userUtils.getPartner(user);
    } else {
      return;
    }
    try {
      const turnoverResponse = await api.get(`/profile/${partner}/turnover`);
      const turnover = turnoverResponse.data.turnover;

      dispatch({
        type: USER_PROFILE_GET,
        payload: { userId, turnover },
      });
    } catch (error) {
      const errorObject = {
        location: 'getUserProfileTurnover',
        error,
      };
      dispatch(errorLog(errorObject));

      console.error(error);
    }
  };
}
