import { connect } from 'react-redux';

import { spinner } from '../../actions';
import ThumbnailItem from './ThumbnailItem';

const mapStateToProps = (state) => ({
  activeGallery: state.galleries.active,
});

const mapDispatchToProps = {
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(ThumbnailItem);
