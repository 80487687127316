import { SOCKET_EMIT_CALLBACK_TIMEOUT } from '../../../constants';
import { timeout } from '../../../utils';
import { getCamData } from '../../../utils/cam';
import { MANAGEMENT_EVENTS_RECONNECT } from '../../actions';
import { management as managementActions, stream } from '../..';

export default (management) => () => (dispatch, getState) => {
  const {
    cam,
    streaming: { isReconnecting },
  } = getState();

  if (isReconnecting) {
    return;
  }
  dispatch({
    type: MANAGEMENT_EVENTS_RECONNECT,
    meta: { start: true },
  });

  const connectionData = {
    publishName: getCamData(cam).publishName,
  };

  if (management.connected()) {
    management.emit(
      'availableMediaServer',
      connectionData,
      timeout(SOCKET_EMIT_CALLBACK_TIMEOUT, (err, data) => {
        if (!management || !management.connected()) {
          return;
        }
        if (err || (data && data.errorStatus !== 12)) {
          dispatch(managementActions.logoutCam({}, 22));
          return (
            management.log &&
            management.log.splunk &&
            management.log.splunk.warn(`try cam reconnect failed`)
          );
        }

        dispatch(managementActions.logfpsTimer.stop());
        dispatch({
          type: MANAGEMENT_EVENTS_RECONNECT,
          payload: {
            availableMediaServer: [...data.availableMediaServer],
          },
        });
        dispatch(stream.stop(false));
      })
    );
  }
};
