export default {
  LEGAL_IMPRINT: 'Imprint',
  LEGAL_IMPRINT_COMPANY: 'IP Broadcasting B.V.',
  LEGAL_IMPRINT_ADDRESS: `Lottumseweg 43 <br />
    5971 BV Grubbenvorst <br />
    The Netherlands`,
  LEGAL_IMPRINT_TAX: 'Tax: NL806101623B01',
  LEGAL_IMPRINT_KVK: `KvK-Number: 12037006
  Kamer von Koophandel Amsterdam`,
  LEGAL_IMPRINT_ODR:
    'The European Commission has a platform for Online Dispute Resolution (ODR). This can be reached via <a href="http://ec.europa.eu/consumers/odr/" target="_blank">http://ec.europa.eu/consumers/odr/</a>',
};
