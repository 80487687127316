import * as Yup from 'yup';

export const getValidationScheme = (intl) => {
  const t = (id) => intl.formatMessage({ id });
  const required = t('VALIDATION_REQUIRED_FIELD');
  const nameRegEx = /^[A-Za-z0-9\s.,'ßüäöÄÜÖ-]+$/u;
  return Yup.object().shape({
    address1: Yup.string()
      .required(required)
      .max(64, t('CHANGE_ADDRESS_STREET_TOO_LONG'))
      .matches(nameRegEx, t('CHANGE_ADDRESS_VALIDATION_ERROR')),
    address2: Yup.string()
      .max(64, t('CHANGE_ADDRESS_STREET2_TOO_LONG'))
      .matches(nameRegEx, t('CHANGE_ADDRESS_VALIDATION_ERROR')),
    country_code: Yup.string()
      .required(required)
      .matches(nameRegEx, t('CHANGE_ADDRESS_VALIDATION_ERROR')),
    zip: Yup.string()
      .when('country_code', {
        is: 'DE',
        then: Yup.string().required(required),
      })
      .max(12, t('CHANGE_ADDRESS_ZIP_TOO_LONG'))
      .matches(nameRegEx, t('CHANGE_ADDRESS_VALIDATION_ERROR')),
    city: Yup.string()
      .required(required)
      .max(64, t('CHANGE_ADDRESS_CITY_TOO_LONG'))
      .matches(nameRegEx, t('CHANGE_ADDRESS_VALIDATION_ERROR')),
  });
};

