import './FilledProgressWrapper.scss';

import classNames from 'classnames';
import React from 'react';

function FilledProgressWrapper({ className = '', percent = 0 }) {
  const progressPercentDiv = classNames('filled-percent-div');
  return (
    <div className={className}>
      <div
        className={progressPercentDiv + ' full-blue-percent-div'}
        style={{ width: `${percent}%` }}
      />
      <div
        className={progressPercentDiv + ' empty-percent-div'}
        style={{ width: `${100 - percent}%` }}
      />
    </div>
  );
}

export default FilledProgressWrapper;

