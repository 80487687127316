import {
  ALERT_CANCEL,
  ALERT_OK,
  IGNORE_USER,
  USER_IGNORED_IDS_ADD,
  USER_UNIGNORE_ID,
} from '../actions/actions';

const initialState = {
  askIgnoreUserConfirmed: undefined,
  askIgnoreUserConfirmedAlert: undefined,
  askIgnoreUserId: undefined,
  askIgnoreUserKick: undefined,
  ignoredIds: [],
};

function ignoredReducer(state = initialState, action) {
  switch (action.type) {
    case IGNORE_USER:
      if (action.meta && action.meta.start) {
        return Object.assign({}, state, {
          askIgnoreUserConfirmedAlert: action.meta.stamp,
          askIgnoreUserKick: action.meta.hasUser,
          askIgnoreUserId: action.payload.clientCustomerId
            ? `${action.payload.clientProductId}-${action.payload.clientCustomerId}`
            : action.payload.partner,
        });
      } else {
        return Object.assign({}, state, {
          askIgnoreUserConfirmedAlert: undefined,
          askIgnoreUserConfirmed: undefined,
          askIgnoreUserKick: undefined,
          askIgnoreUserId: undefined,
        });
      }
    case ALERT_OK: {
      switch (action.payload.stamp) {
        case state.askIgnoreUserConfirmedAlert:
          return Object.assign({}, state, { askIgnoreUserConfirmed: true });
        default:
          return state;
      }
    }
    case ALERT_CANCEL: {
      switch (action.payload.stamp) {
        case state.askIgnoreUserConfirmedAlert:
          return Object.assign({}, state, {
            askIgnoreUserConfirmedAlert: undefined,
            askIgnoreUserConfirmed: undefined,
          });
        default:
          return state;
      }
    }
    case USER_IGNORED_IDS_ADD:
      return { ...state, ignoredIds: [...action.payload, ...state.ignoredIds] };
    case USER_UNIGNORE_ID:
      const id = action.payload;
      const filteredState = state.ignoredIds.filter(
        (dialogId) => dialogId !== id
      );
      return { ...state, ignoredIds: [...filteredState] };
    default:
      return state;
  }
}

export default ignoredReducer;

