import {
  ALERT_CANCEL,
  ALERT_OK,
  COOKIE,
  MANAGEMENT_CAM_LOGIN,
  MANAGEMENT_ORIGINS_SWITCH,
  MANAGEMENT_USER_KICK,
  ORIGIN_TOGGLE,
  REDIRECTED_SETTINGS,
  RESET_CAM,
  ROOM_TYPES_CHANGE,
  ROOM_TYPES_VOYEUR_ADD,
  ROOM_TYPES_VOYEUR_REMOVE,
  RUN_TOY_SUCCESS,
  SENDER_LOGIN,
  SENDER_LOGOUT,
  TOKEN_LOGIN_SENDER,
  TOYCONTROLDATA_CHANGED,
  TOYCONTROL_CALL,
  TOYCONTROL_TOGGLE,
  TOY_COUNTDOWN,
  TOY_SET_ACTIVE,
  TOY_SET_INTENSITY,
  UPGRADE_INIT,
  UPGRADE_SAVE,
} from '../actions/actions';
import { lStorage } from '../services/storage';
import { roomType } from '../utils';

const initialState = {
  loginStarted: false,
  askExclusiveRoomtypeAlert: null,
  askExclusiveRoomtypeConfirmed: null,
  askKickUserConfirmed: undefined,
  askKickUserConfirmedAlert: undefined,
  askInitCamAlert: null,
  askInitCamAlertConfirmed: null,
  isAuthenticated: false,
  authInterval: -1,
  sessionId: '',
  isTokenLogin: false,
  server: {
    serviceURL: process.env.REACT_APP_LIVECAM_SERVICE_URL,
  },
  software: {
    os: {
      isWindows: /win/gi.test(navigator.platform),
    },
    origin: process.env.REACT_APP_CAM_SOFTWARE_ORIGIN,
    lostPasswordLink: '',
    version: process.env.REACT_APP_CAM_VERSION,
    portalName: '',
    portalShortName: '',
    name: '',
    username: '',
    password: '',
    gone: false,
  },
  feature: {
    powersender: false,
  },
  logins: [],
  roomTypes: [],
  upgradeStarted: false,
  saved: {
    logins: [],
    roomTypes: [],
  },
  switchoriginbuttonlock: false,
  tokenLoginError: false,
  toycontrol: {},
  runToy: {},
};

const sender = (state = initialState, action) => {
  switch (action.type) {
    case MANAGEMENT_CAM_LOGIN:
      if (
        action.error &&
        action.meta &&
        action.meta.errorStatus &&
        action.meta.errorStatus === 6
      ) {
        return { ...state, software: { ...state.software, gone: true } };
      }
      return state;
    case TOKEN_LOGIN_SENDER:
      if (action.error && action?.payload?.error === 'Gone') {
        return {
          ...state,
          loginStarted: false,
          software: { ...state.software, gone: true },
        };
      }
      if (action.meta && action.meta.start) {
        return { ...state, isTokenLogin: true };
      }
      return {
        ...state,
        isTokenLogin: false,
        tokenLoginError: !!action?.error,
      };
    case COOKIE:
      if (!action.payload.thirdPartyCookiesAllowed) {
        return { ...state, loginStarted: false };
      }
      return state;
    case SENDER_LOGIN: {
      if (action.error) {
        const loginStarted = false;
        if (typeof action.payload === 'string' && action.payload === 'Gone') {
          return {
            ...state,
            loginStarted,
            software: { ...state.software, gone: true },
          };
        }
        return { ...state, loginStarted };
      }
      if (action.meta && action.meta.start) {
        return { ...state, loginStarted: true };
      }
      const software = Object.assign({}, state.software, {
        username: action.payload.username,
        password: action.payload.password,
      });

      const roomTypes = roomType.init(action.payload.roomTypes);
      return Object.assign({}, state, {
        isAuthenticated: true,
        authInterval: action.payload.authInterval,
        sessionId: action.payload.sessionId,
        software,
        feature: roomType.isEvent(roomTypes)
          ? { powersender: true }
          : action.payload.feature || {},
        logins:
          action.payload.ids.map((id) =>
            Object.assign({}, id, { activated: true })
          ) || [],
        origin: action.payload.origin,
        roomTypes,
        loginStarted: false,
        isTokenLogin: false,
        toycontrol: action.payload.toycontrol,
      });
    }
    case MANAGEMENT_USER_KICK:
      if (action.meta && action.meta.start) {
        return Object.assign({}, state, {
          askKickUserConfirmedAlert: action.meta.stamp,
        });
      } else {
        return Object.assign({}, state, {
          askKickUserConfirmedAlert: undefined,
          askKickUserConfirmed: undefined,
        });
      }
    case ALERT_OK: {
      switch (action.payload.stamp) {
        case state.askKickUserConfirmedAlert:
          return Object.assign({}, state, { askKickUserConfirmed: true });
        case state.askInitCamAlert:
          return Object.assign({}, state, { askInitCamAlertConfirmed: true });
        case state.askExclusiveRoomtypeAlert:
          return { ...state, askExclusiveRoomtypeConfirmed: true };
        default:
          return state;
      }
    }
    case ALERT_CANCEL: {
      switch (action.payload.stamp) {
        case state.askKickUserConfirmedAlert:
          return Object.assign({}, state, {
            askKickUserConfirmedAlert: undefined,
            askKickUserConfirmed: undefined,
          });
        case state.askInitCamAlert:
          return Object.assign({}, state, {
            askInitCamAlert: null,
            askInitCamAlertConfirmed: null,
          });
        case state.askExclusiveRoomtypeAlert:
          return {
            ...state,
            askExclusiveRoomtypeAlert: null,
            askExclusiveRoomtypeConfirmed: null,
          };
        default:
          return state;
      }
    }
    case ROOM_TYPES_CHANGE:
      if (action.error) {
        return {
          ...state,
          askExclusiveRoomtypeAlert: null,
          askExclusiveRoomtypeConfirmed: null,
        };
      }
      return {
        ...state,
        roomTypes: action.payload.roomTypes,
        logins: action.payload.logins,
        askExclusiveRoomtypeAlert: null,
        askExclusiveRoomtypeConfirmed: null,
      };
    case ROOM_TYPES_VOYEUR_ADD:
      return {
        ...state,
        roomTypes: roomType.addVoyeur(state.roomTypes),
      };
    case ROOM_TYPES_VOYEUR_REMOVE:
      return {
        ...state,
        roomTypes: roomType.removeVoyeur(state.roomTypes),
      };
    case REDIRECTED_SETTINGS:
      return {
        ...state,
        askInitCamAlert: null,
        askInitCamAlertConfirmed: null,
        askExclusiveRoomtypeConfirmed: null,
      };
    case RESET_CAM:
      return { ...state, askInitCamAlert: action.meta.stamp };
    case MANAGEMENT_ORIGINS_SWITCH:
      if (action.error) {
        return state;
      }
      if (action.meta && action.meta.start) {
        let { askExclusiveRoomtypeAlert, askExclusiveRoomtypeConfirmed } =
          state;
        if (
          askExclusiveRoomtypeConfirmed &&
          askExclusiveRoomtypeAlert &&
          askExclusiveRoomtypeAlert !== ''
        ) {
          askExclusiveRoomtypeAlert = null;
          askExclusiveRoomtypeConfirmed = null;
        }
        return {
          ...state,
          logins: action.payload.logins,
          roomTypes: action.payload.roomTypes,
          switchoriginbuttonlock: true,
          askExclusiveRoomtypeAlert,
          askExclusiveRoomtypeConfirmed,
        };
      }
      if (action.meta && action.meta.end) {
        return { ...state, switchoriginbuttonlock: false };
      }
      return state;
    case ORIGIN_TOGGLE: {
      if (action.error) {
        return state;
      }
      return Object.assign({}, state, { logins: action.payload });
    }
    case UPGRADE_INIT:
      if (action.meta && action.meta.start) {
        return Object.assign({}, state, { upgradeStarted: true });
      }
      return Object.assign({}, state, { upgradeStarted: false });
    case UPGRADE_SAVE:
      if (action.meta.add) {
        return Object.assign({}, state, { saved: action.payload });
      }
      return Object.assign({}, state, { saved: initialState.saved });
    case SENDER_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        switchoriginbuttonlock: false,
      };
    case TOYCONTROL_TOGGLE: {
      if (action?.error) {
        return state;
      }

      const payload = { ...action.payload };

      if (!payload?.toggleDone) {
        return state;
      }

      delete action.payload.toggleDone;

      if (action.payload.active) {
        return {
          ...state,
          toycontrol: {
            ...state.toycontrol,
            ...action.payload,
          },
        };
      }

      return {
        ...state,
        toycontrol: null,
      };
    }
    case TOYCONTROL_CALL: {
      if (action?.error) {
        return state;
      }
      if (!action.payload.qr || !action.payload.code) {
        return state;
      }

      return {
        ...state,
        toycontrol: {
          ...state.toycontrol,
          ...action.payload,
        },
      };
    }
    case TOYCONTROLDATA_CHANGED: {
      return {
        ...state,
        toycontrol: {
          ...state?.toycontrol,
          ...action.payload?.payload,
        },
      };
    }
    case TOY_SET_ACTIVE: {
      if (
        action.payload === '' ||
        !state?.toycontrol?.connected?.toys ||
        !state?.toycontrol?.connected?.toys[action.payload]
      ) {
        if (action?.meta?.save) {
          lStorage.setItem('toyId', 'none');
        }
        return {
          ...state,
          toycontrol: {
            ...state.toycontrol,
            activeToy: '',
          },
        };
      }
      if (action?.meta?.save) {
        lStorage.setItem('toyId', action.payload);
      }
      return {
        ...state,
        toycontrol: {
          ...state.toycontrol,
          activeToy: action.payload,
        },
      };
    }
    case RUN_TOY_SUCCESS:
      return {
        ...state,
        runToy: {
          ...action.payload,
          countDown: Math.ceil(parseInt(action.payload.timeoutMS, 10) / 1000),
          start: true,
        },
      };
    case TOY_COUNTDOWN:
      return {
        ...state,
        runToy: {
          ...state.runToy,
          countDown: action.payload - 1,
          start: false,
        },
      };
    case TOY_SET_INTENSITY: {
      return {
        ...state,
        toycontrol: {
          ...state.toycontrol,
          intensity: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default sender;
