import commission from './commission';
import contest from './contest';
import dashboard from './dashboard';
import documents from './documents';
import kpi from './kpi';
import languages from './languages';
import onboarding from './onboarding';
import privateData from './privateData';
import profile from './profile';
import settings from './settings';
import virtualGifts from './virtualGifts';

export default {
  ...onboarding,
  ...dashboard,
  ...profile,
  ...settings,
  ...privateData,
  ...commission,
  ...documents,
  ...virtualGifts,
  ...kpi,
  ...contest,
  ...languages,

  FIELD_NICKNAME: `Nickname:`,
  FIELD_EMAIL: `E-Mail:`,
  FIELD_FIRST_NAME: `Vorname:`,
  FIELD_LAST_NAME: `Nachname:`,
  FIELD_BIRTHDAY: `Geburtsdatum:`,
  FIELD_ZIP: `PLZ`,
  FIELD_CITY: `Ort`,
  FIELD_COUNTRY: `Land:`,
  DO_NOT_SHOW_IN_FILTER: `Mein Profil hier nicht anzeigen`,
  FIELD_NATIVE_LANGUAGE: `Muttersprache`,
  FIELDS_LANGUAGES: `Ich spreche außerdem:`,
  MORE_LANGUAGES_DESCRIPTION: `Bitte wähle hier nur Sprachen aus, die Du neben Deiner Muttersprache fließend sprichst.`,
  FIELD_DESCRIPTION: `Beschreibung`,
  FIELD_ICEBREAKER_FSK:
    'Wir initiieren den Erstkontakt zu einer großen Gruppe von Usern. Wie sollen wir diese Männer für Dich ansprechen?',
  FIELD_ICEBREAKER_FSK16:
    'Ich interessiere mich hauptsächlich für Flirten. Ich mag es sanft.',
  FIELD_ICEBREAKER_FSK18: `Ich bin offen für alles und mag es auch über sexuelle Themen zu sprechen. <span class="italic">Wirkt sich positiv auf Deine Umsätze aus!</span>`,
  FIELD_ICEBREAKER_SAVING_ERROR: 'Fehler beim Speichern',
  FIELD_PASSWORD: `Passwort`,
  FIELD_LANGUAGE: `Sprache`,
  FIELD_GENDER: `Geschlecht:`,
  SELECT_GENDER_MALE: `Mann`,
  SELECT_GENDER_FEMALE: `Frau`,
  SELECT_GENDER_TRANSGENDER: `Transgender`,
  REMAINING_CHARS: `Verbleibende Zeichen`,

  JANUARY: `Januar`,
  FEBRUARY: `Februar`,
  MARCH: `März`,
  APRIL: `April`,
  MAY: `Mai`,
  JUNE: `Juni`,
  JULY: `Juli`,
  AUGUST: `August`,
  SEPTEMBER: `September`,
  OCTOBER: `Oktober`,
  NOVEMBER: `November`,
  DECEMBER: `Dezember`,

  milf: `MILF`,
  lesbians: `Lesben`,
  grannies: `Omas`,
  asian: `Asiatisch`,
  bisexuals: `Bisexuelle`,
  blondes: `Blonde`,
  blackhaired: `Schwarzhaarige`,
  dominatrixes: `Dominas`,
  slaves: `Sklaven`,
  european: `Europäisch`,
  exhibitionists: `Exhibitionisten`,
  fetish: `Fetisch`,
  gays: `Gays`,
  longhair: `Langhaarige`,
  shorthair: `Kurzhaarige`,
  smalltits: `Kleine Brüste`,
  hangingtits: `Hängetitten`,
  bigcocks: `Große Schwänze`,
  bigtits: `Große Brüste`,
  blacks: `Schwarze`,
  bodybuilders: `Bodybuilder`,
  voyeurs: `Voyeure`,
  transsexuals: `Transsexuelle`,
  pregnantwomen: `Schwangere`,
  teens: `Teens`,
  roleplayers: `Rollenspieler`,
  schoolgirls: `Schulmädchen`,
  redheads: `Rothaarige`,
  chubby: `Mollige`,
  latinas: `Latinas`,
  boys: `Jungs`,
  slim: `Schlanke`,
  fatties: `Fette`,
  transvestites: `Transvestiten`,
  tattoospiercings: `Tattoos / Piercings`,
  brunettes: `Braunhaarige`,
  gangbang: `Gangbang`,
  swinger: `Swinger`,
  students: `Studenten`,
  bdsm: `BDSM`,
  middleeastern: `Arabisch`,
  dating: `Dating`,
  northeuropean: `Nordeuropäer/in`,
  southeuropean: `Südeuropäer/in`,
  arabic: `Araber/in`,
  indian: `Inder/in`,
  african: `Afrikaner/in`,
  latino: `Lateinamerikaner/in`,
  lightblue: `hellblau`,
  blue: `blau`,
  brown: `braun`,
  green: `grün`,
  bluegray: `blau-grau`,
  bluegreen: `blau-grün`,
  greengray: `grün-grau`,
  gray: `grau`,
  grazy: `verrückte Farbe`,
  lightblond: `hellblond`,
  blond: `blond`,
  darkblond: `dunkelblond`,
  darkbrown: `dunkelbraun`,
  red: `rot`,
  black: `schwarz`,
  white: `weiß`,
  other: `andere Farbe`,
  bald: `Glatze`,
  stubbles: `raspelkurz`,
  short: `kurz`,
  longer: `länger`,
  shoulder: `schulterlang`,
  long: `lang`,
  verylong: `sehr lang`,
  figure_sportive: `sportlich`,
  figure_normal: `normal`,
  figure_slim: `schlank`,
  figure_thickish: `etwas zu dick`,
  figure_thick: `dick`,
  figure_fat: `sehr dick`,
  figure_brawny: `muskulös`,
  figure_athletic: `athletisch`,
  none: `so gut wie nicht vorhanden`,
  A: 'A',
  AA: 'AA',
  B: 'B',
  C: 'C',
  D: 'D',
  DD: 'DD',
  F: 'F',
  G: 'G',
  normal: `normal`,
  little: `wenig`,
  fewmore: `etwas mehr`,
  strong: `viel`,
  shaved: `rasiert`,
  pubic_hair_none: `nicht vorhanden`,
  pubic_hair_little: `wenig`,
  pubic_hair_normal: `normal`,
  pubic_hair_strong: `stark`,
  pubic_hair_shaved: `stets rasiert`,
  pubic_hair_partshaved: `teilrasiert`,
  true: `ja`,
  false: `nein`,
  read: `nur zum Lesen`,
  tattoo_and_piercings_none: `keins`,
  tattoo_and_piercings_one: `nur eins`,
  tattoo_and_piercings_several: `mehrere`,
  tattoo_and_piercings_many: `viele`,
  single: `ledig`,
  married: `verheiratet`,
  divorced: `geschieden`,
  separated: `getrennt lebend`,
  notfixed: `offene Beziehung`,
  partner: `mit Partner`,
  singleparent: `Alleinerziehend`,
  sometimes: `gelegentlich`,
  woman: `eine Frau`,
  man: `einen Mann`,
  couple: `ein Pärchen`,
  transvestite: `eine/n Transvestiten`,
  transexual: `eine/n Transsexuellen`,
  bisexual: `eine/n Bisexuellen`,
  escapade: `einen Seitensprung`,
  relationship: `eine Beziehung`,
  flirt: `einen Flirt`,
  emailsex: `Email Sex`,
  affair: `eine Affäre`,
  ons: `einen ONS`,
  frivolous_friendship: `eine frivole Freundschaft`,
  fetish_erotic: `Fetish-Erotik`,
  groupsex: `Gruppensex`,
  flowerpower: `Blümchen-Sex`,
  analsex: `Analsex`,
  oralsex: `Oralsex`,
  submissive: `devot sein`,
  dominant: `dominant sein`,
  toys: `Sexspielzeug`,
  swinging: `swingen`,
  outdoorsex: `Outdoorsex`,
  publicsex: `Sex in der Öffentlichkeit`,
  dessous: `Dessous`,
  rpg: `Rollenspiele`,
  voyeurism: `Voyeurismus`,
  not_available: `keine Angabe`,
};
