import attachments from './attachments';
import creatorReferral from './creatorReferral';
import creatorTags from './creatorTags';
import diary from './diary';
import firstSteps from './firstSteps';
import fourScore from './fourScore';
import galleries from './galleries';
import icebreaker from './icebreaker';
import livecamFaq from './livecamFaq';
import livecamSetting from './livecamSetting';
import lovenseInstallation from './lovenseInstallation';
import multiMessenger from './multiMessenger';
import profile from './profile';
import profileImage from './profileImage';
import rankings from './rankings';
import relationships from './relationships';
import settings from './settings';
import timeManagement from './timeManagement';
import userAdvertising from './userAdvertising';
import userInfocard from './userInfocard';
import visibility from './visibility';
import voiceMessage from './voiceMessage';
import xmasCalendar from './xmasCalendar';

export default {
  ...creatorReferral,
  ...userAdvertising,
  ...profileImage,
  ...livecamSetting,
  ...livecamFaq,
  ...fourScore,
  ...xmasCalendar,
  ...lovenseInstallation,
  ...firstSteps,
  ...multiMessenger,
  ...attachments,
  ...creatorTags,
  ...rankings,
  ...profile,
  ...settings,
  ...icebreaker,
  ...diary,
  ...galleries,
  ...voiceMessage,
  ...visibility,
  ...relationships,
  ...timeManagement,
  ...userInfocard,

  WIKI_HOW_TO_HEADER: `Wähle ein Thema aus`,
  WIKI_HOW_TO_MENU_CREATOR_REFERRAL: `Creator werben`,
  WIKI_HOW_TO_MENU_USER_ADVERTISING: `User werben`,
  WIKI_HOW_TO_MENU_PROFILE_IMAGE: `Dein perfektes Profilbild`,
  WIKI_HOW_TO_MENU_LIVECAM_SETTING: `Einstellungen zur Livecam`,
  WIKI_HOW_TO_MENU_LIVECAM_FAQ: `FAQ zur Livecam`,
  WIKI_HOW_TO_MENU_4SCORE: `Community Game 4Score`,
  WIKI_HOW_TO_MENU_LOVENSE_INSTALLATION: `Einrichtung des Lovense Toy`,
  WIKI_HOW_TO_MENU_FIRST_STEPS: `Der perfekte Start in LiveCreator`,
  WIKI_HOW_TO_MENU_MULTI_MESSENGER: `Multi Messenger`,
  WIKI_HOW_TO_MENU_ATTACHMENTS: `Dateianhänge`,
  WIKI_HOW_TO_MENU_CREATOR_TAGS: `Creator Tags`,
  WIKI_HOW_TO_MENU_RANKINGS: `Rankings / Meine Leistung`,
  WIKI_HOW_TO_MENU_PROFILE: `Dein Profil`,
  WIKI_HOW_TO_MENU_SETTINGS: `Einstellungen`,
  WIKI_HOW_TO_MENU_ICEBREAKER: `Icebreaker`,
  WIKI_HOW_TO_MENU_DIARY: `Tagebuch`,
  WIKI_HOW_TO_MENU_GALLERIES: `Foto und Video Galerien`,
  WIKI_HOW_TO_MENU_VOICE_MESSAGE: `Sprachnachrichten`,
  WIKI_HOW_TO_MENU_VISIBILITY: `Sichtbarkeit und Präsenz`,
  WIKI_HOW_TO_MENU_RELATIONSHIPS: `Beziehungen - Freunde und Favoriten`,
  WIKI_HOW_TO_MENU_TIME_MANAGEMENT: `Zeitmanagement`,
  WIKI_HOW_TO_MENU_INFOCARD: `User Infocard`,

  WIKI_HEADLINE_VIDEO: `Video Tutorial`,
};
