import { SOCKET_SERVICE_STEP_NOT_CONNECTED } from '../../../constants';
import { CONNECT_SOCKET_SERVICE } from '../../actions';

export default (error) => (dispatch) => {
  dispatch({
    type: CONNECT_SOCKET_SERVICE,
    payload: {
      step: SOCKET_SERVICE_STEP_NOT_CONNECTED,
    },
  });
  console.debug({ socketServiceConnectError: error });
};
