import { SENDER_LOGIN, SENDER_LOGOUT } from '../actions/actions';
import { CHECKUSER_INTERVAL, CHECKUSER_INTERVAL_EVENT } from '../constants';
import { roomType } from '../utils';

const initialState = {
  interval: CHECKUSER_INTERVAL,
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case SENDER_LOGIN:
      if (
        action.payload.roomTypes &&
        roomType.isEvent(action.payload.roomTypes)
      ) {
        return { ...state, interval: CHECKUSER_INTERVAL_EVENT };
      }
      return state;
    case SENDER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reduce;
