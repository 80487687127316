import React from 'react';

import NumberSelect from '../../Forms/NumberSelect/NumberSelect';

const FontSizeSelect = ({ onChange, fontsize }) => (
  <NumberSelect
    options={fontsize?.possible?.map((size) => {
      return { label: size + 'px', value: size };
    })}
    label="fontSize.title"
    value={fontsize?.selected}
    small={true}
    onChange={(event) => onChange(event.target.value)}
  />
);

export default FontSizeSelect;
