import { STREAMING_SERVER_TYPE_CAM2CAM } from '../../../../constants';
import { cam2cam } from '../../../';

export default (data) => (dispatch) => {
  const connectionData = {
    sdpUrl: data.sdpUrl,
    applicationName: data.application,
    streamName: `${data.publishName}?type=${STREAMING_SERVER_TYPE_CAM2CAM}&id=${data.key}`,
  };
  dispatch(cam2cam.start(data.socketId, connectionData));
};
