const update = (oldObject, newValues) =>
  Object.assign({}, oldObject, newValues);

const clone = (obj) => JSON.parse(JSON.stringify(obj));
const equal = (object1, object2) => {
  if (object1 === null || object2 === null) {
    return object1 === null && object2 === null;
  }
  return JSON.stringify(object1) === JSON.stringify(object2);
};

const filter = (obj, predicate) => {
  let result = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key) && !predicate(obj[key])) {
      result[key] = obj[key];
    }
  }

  return result;
};
export default {
  clone,
  update,
  equal,
  filter,
};
