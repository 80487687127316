export default {
  LINK_TEXT_ACCOUNT: `Mein Account`,
  LIVECAM_SETTING_HEADLINE: `Schritt für Schritt zur ersten Livecam Show`,
  LIVECAM_SETTING_CONTENT_1: `Wie Du erfolgreich mit Deiner Webcam starten kannst möchten wir Dir hier mit einigen Tipps zeigen.`,

  LIVECAM_SETTING_HEADLINE_2: `Planung und Vorbereitung`,
  LIVECAM_SETTING_CONTENT_STEP_1: `Internetverbindung`,
  LIVECAM_SETTING_CONTENT_STEP_1_CONTENT: `Führe als ersten Schritt einen <a href='https://www.speedmeter.de/' target='_blank'>"Speedtest"</a> durch, um Deine Upload- und Download-Rate zu messen. Der minimale Upload sollte 5 MBit/s betragen, optimalerweise 10 MBit/s. Kontaktiere Deinen Internetanbieter, um Deine Geschwindigkeit anzupassen oder die beste Verbindung für Dich auszuwählen. Eine stabile Internetverbindung ist entscheidend für eine unterbrechungsfreie Übertragung. Am stabilsten ist Deine Verbindung, wenn Du per LAN-Kabel online gehst.`,
  LIVECAM_SETTING_CONTENT_STEP_2: `Webcam`,
  LIVECAM_SETTING_CONTENT_STEP_2_CONTENT: `Für einen PC benötigst Du eine Webcam mit einer Auflösung von 640 x 480 Pixel oder mehr. Eine einfache Einsteigerkamera mit einem 1080p-HD-Breitbildsensor ist empfehlenswert, um die Schärfe und Bildqualität zu optimieren. Kameras der Marken Logitech oder Microsoft sind sehr gut und erschwinglich. In den meisten Laptops sind inzwischen gute Webcams integriert.
Platziere Deine Webcam so, dass Du Dich leicht umpositionieren kannst und achte darauf, dass sie auf einer festen Unterlage steht, damit Dein Bild nicht wackelt. Nutze eine kabellose Maus und Tastatur für mehr Flexibilität und Bewegungsfreiheit.`,
  LIVECAM_SETTING_CONTENT_STEP_3: `Arbeitsplatz`,
  LIVECAM_SETTING_CONTENT_STEP_3_CONTENT: `Wähle einen Ort, an dem Du Dich wohl fühlst und in Ruhe arbeiten kannst. Achte darauf, dass Deine Kunden im Hintergrund keine Gegenstände erkennen können, die Du nicht mit Absicht dort platziert hast. Utensilien wie Toys oder Kleidung solltest Du in Deiner Nähe haben. Sorge für ausreichende Beleuchtung, vermeide jedoch grelles oder flackerndes Licht und Schattenwurf. Kleine farbige Lampen können Dein Bild und Deinen Hintergrund aufwerten und interessanter gestalten, sofern sie nicht blinken oder schnell die Farben wechseln.`,

  LIVECAM_SETTING_HEADLINE_3: `Einstellungen in LiveCreator`,
  LIVECAM_SETTING_CONTENT_STEP_4: `Webcam`,
  LIVECAM_SETTING_CONTENT_STEP_4_CONTENT: `Unter dem Punkt {link} wählst Du Deine Webcam aus. Wähle hier auch die Videoauflösung, mit der das Webcam-Bild übertragen werden soll. Die Software überprüft Deine Hard- und Softwarekonfiguration sowie die Bandbreite Deines Internetanschlusses und ermittelt hieraus den bestmöglichen Übertragungswert.`,
  LINK_TEXT_CAM_SETTINGS: `{account} - Einstellungen - Livecam`,
  LIVECAM_SETTING_CONTENT_STEP_4_1_CONTENT: `Die Auflösung wird als Breite x Höhe in Pixel angegeben, in Klammern siehst Du das Seitenverhältnis der Auflösung (4:3 oder 16:9).`,
  LIVECAM_SETTING_CONTENT_STEP_4_2_CONTENT: `Wenn im Vorschaubild links/rechts oder oben/unten schwarze Balken dargestellt werden, musst Du die Videoauflösung anpassen, denn die Balken reduzieren die maximal mögliche Videodarstellung für den User.`,
  LIVECAM_SETTING_CONTENT_STEP_4_3_CONTENT: `Jetzt wählst Du noch Dein Mikrofon aus und hast damit die ersten Schritte schon geschafft.`,
  LIVECAM_SETTING_CONTENT_STEP_5: `Preise`,
  LIVECAM_SETTING_CONTENT_STEP_5_CONTENT: `Unter dem Punkt {link} stellst Du Deine Preise ein. Pro Minute wird dem User Dein gewünschter Preis berechnet. Setze Deine Preise sorgfältig und ändere sie nicht zu oft, denn das könnte Deine Kunden unter Umständen verärgern.`,
  LINK_TEXT_PRICE: `{account} - Einstellungen - Preise`,
  LIVECAM_SETTING_HEADLINE_4: `Die Show kann beginnen`,
  LIVECAM_SETTING_CONTENT_4: `Ob es ein erotischer Chat oder doch eine Dildo-Show wird, das bestimmst Du ganz allein. Sei jedoch offen für die Vorschläge und Wünsche Deiner Kunden und überlege, wie Du diese in Deine Show einbeziehen kannst.`,
  LIVECAM_SETTING_CONTENT_4_1: `Nimm Dir Zeit und bereite Dich auf Deine Show in Ruhe vor. Nur so kannst Du Dein Bestes geben. Investiere Zeit in Dein Styling und Aussehen, als würdest Du zu einem ersten Date gehen. Eine positive Einstellung und ein Lächeln tragen definitiv zu einer erfolgreichen Livecam Show bei.`,
  LIVECAM_SETTING_CONTENT_STEP_6: `Garderobe`,
  LIVECAM_SETTING_CONTENT_STEP_6_CONTENT: `Neben Dessous kannst Du auch andere Kleidungsstücke in Betracht ziehen. Du kannst zum Beispiel Kleider, Röcke, Hosen oder Oberteile tragen und diese je nach Stimmung und Thema Deiner Show wechseln. Die Kombination aus verschiedenen Kleidungsstücken kann auch dazu beitragen, dass der Kunde länger in Deiner Livecam Show bleibt. Denke daran, dass Accessoires und Schuhe eine wichtige Rolle bei Deinem Outfit spielen. Sie verleihen Deinem Look das gewisse Extra.`,
  LIVECAM_SETTING_CONTENT_STEP_6_1_CONTENT: `Es ist wichtig, dass Du bei der Auswahl Deiner Outfits sorgfältig vorgehst und etwas zusammenstellst, das Deinen Stil und Deine Persönlichkeit widerspiegelt. Wenn Du Dich in Deinem Outfit wohl fühlst, kannst Du selbstbewusst und entspannt in Deiner Livecam Show auftreten.`,
  LIVECAM_SETTING_CONTENT_STEP_7: `Toys`,
  LIVECAM_SETTING_CONTENT_STEP_7_CONTENT: `Wenn Du Toys in Deiner Livecam Show verwenden möchtest, solltest Du diese griffbereit haben, um Unterbrechungen zu vermeiden, die der Stimmung schaden. Du kannst auch verschiedene Toys verwenden und Deinen Kunden die Möglichkeit geben, zu wählen, welches sie gerne sehen möchten.`,
  LIVECAM_SETTING_CONTENT_STEP_7_1_CONTENT: `<span class="bold">Extra Tipp:</span> Verwende Toys der Firma Lovense und biete Deinen Kunden ein besonderes interaktives Erlebnis! Ermögliche Deinen Kunden damit direkt mit Dir zu interagieren, indem Sie Dein Toy für Coins steuern. Das sorgt für noch mehr Spannung und bringt Dir sofort Zusatzverdienst in Deiner Livecam Show.`,
  LIVECAM_SETTING_CONTENT_STEP_8: `Start und Kommunikation`,
  LIVECAM_SETTING_CONTENT_STEP_8_CONTENT: `Sobald Du bereit bist, starte Deine Livecam Show. Gehe dafür zu Deinem {link} {icon}. Im Menü unter dem Punkt "Features" findest Du den Button um Deinen Stream zu starten.`,
  LIVECAM_SETTING_CONTENT_STEP_8_1_CONTENT: `Betritt ein Kunde Deine Livecam, ertönt eine Ansage und im Messenger wird am Namen des Users "Neu" angezeigt. Begrüße jeden Kunden entweder mit einem netten Lächeln und Winken in die Kamera oder einem vorgefertigten Text (F-Tasten), um den Kunden das Gefühl zu geben, willkommen und geschätzt zu sein.`,
  LIVECAM_SETTING_CONTENT_STEP_8_2_CONTENT: `<span class="bold">Tipp:</span> Dein Mikrofon kannst Du über Deinem Cam-Bild an- und ausschalten. Ist der Ton angeschaltet, hören Dich alle Kunden, die sich gerade in Deiner Show befinden. Die User sehen sich jedoch nicht gegenseitig.`,
};

