import { useEffect, useRef } from 'react';

let xDown = null;
let yDown = null;
let timer = null;

function ChatControlSwipe({ navRight, navLeft, children, isMobile }) {
  const swipeHandlerRef = useRef();

  function getTouches(evt) {
    return (
      evt.touches || // browser API
      evt.originalEvent.touches
    ); // jQuery
  }

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    function debounce(func) {
      clearTimeout(timer);
      setTimeout(() => {
        func();
      }, 300);
    }

    function handleTouchStart(evt) {
      const firstTouch = getTouches(evt)[0];
      xDown = firstTouch.clientX;
      yDown = firstTouch.clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      let xUp = evt.touches[0].clientX;
      let yUp = evt.touches[0].clientY;

      let xDiff = xDown - xUp;
      let yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          debounce(navLeft);
        } else {
          debounce(navRight);
        }
      } else {
        if (yDiff > 0) {
          /* down swipe */
        } else {
          /* up swipe */
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }
    const handler = swipeHandlerRef.current;
    handler.addEventListener('touchstart', handleTouchStart, { passive: true });
    handler.addEventListener('touchmove', handleTouchMove, { passive: true });
    return () => {
      handler.removeEventListener('touchstart', handleTouchStart);
      handler.removeEventListener('touchmove', handleTouchMove);
    };
  }, [swipeHandlerRef, navLeft, navRight, isMobile]);

  return isMobile ? (
    <div className="swipe-handler" ref={swipeHandlerRef}>
      {children}
    </div>
  ) : (
    children
  );
}

export default ChatControlSwipe;
