import api from '../../utils/api';
import { errorLog } from '../';
import { USER_NOTES_UPDATE } from '../actions';
export default function ({ message }) {
  return async (dispatch, getState) => {
    const {
      usernote: { userId },
    } = getState();

    try {
      if (!userId) {
        return;
      }
      dispatch({
        type: USER_NOTES_UPDATE,
        meta: { start: true },
      });
      let response;
      if (message === '') {
        response = await api.delete('/user/notes/' + userId);
      } else {
        response = await api.put('/user/notes/' + userId, {
          message,
        });
      }
      dispatch({
        type: USER_NOTES_UPDATE,
        payload: { userId, message: response?.data?.message },
      });
    } catch (error) {
      const errorObject = {
        location: 'updateUserNote',
        error,
      };
      dispatch(errorLog(errorObject));
      dispatch({
        type: USER_NOTES_UPDATE,
        meta: { error: true },
        payload: { error, userId },
      });
      console.error(error);
    }
  };
}
