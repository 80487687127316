import './ConfirmEmail.scss';

import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import PageSection from '../../PageSection/PageSection';

function ConfirmEmail({
  user = {},
  spinner = () => {},
  userTokenLogin = () => {},
}) {
  const search = useLocation().search;
  const history = useHistory();
  const email = new URLSearchParams(search).get('email');
  const token = new URLSearchParams(search).get('token');
  const intl = useIntl();
  const [error, setError] = useState(false);

  const [, verifyEmailRequest] = useAxios(
    {
      url: '/user/email/verify',
      method: 'POST',
      data: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        token: token,
        email: email,
      },
    },
    {
      manual: true,
    }
  );
  useEffect(() => {
    spinner(true);
    verifyEmailRequest()
      .then((response) => {
        setError(false);
        if (response?.data) {
          const data = response.data;
          return userTokenLogin(
            data?.user_id,
            data.token,
            'verified_user',
            history
          );
        }
        spinner(false);
      })
      .catch(() => {
        spinner(false);
        setError(true);
      });
  }, [verifyEmailRequest, spinner, userTokenLogin, history]);

  return (
    <PageSection
      title={
        error
          ? intl.formatMessage({ id: 'CONFIRMED_EMAIL_ERROR' })
          : intl.formatMessage({ id: 'SUCCESS_TITLE' })
      }
      className="confirm-email-container"
    >
      {!error ? (
        <div className="success-wrapper">
          <div className="success-title">
            {' ' + intl.formatMessage({ id: 'CONFIRMED_EMAIL_SUCCESS_TITLE' })}
          </div>
          <hr />
          <div className="success-content">
            {intl.formatMessage(
              { id: 'CONFIRMED_EMAIL_SUCCESS_CONTENT' },
              {
                link: (
                  <Link
                    to={user?.isLoggedIn ? '#' : '/login'}
                    onClick={() => user?.isLoggedIn && window.location.reload()}
                  >
                    {intl.formatMessage({ id: 'CLICK_HERE' })}
                  </Link>
                ),
              }
            )}
          </div>
        </div>
      ) : (
        <div className="error-wrapper">
          <div className="error-title">
            {' ' + intl.formatMessage({ id: 'CONFIRMED_EMAIL_ERROR_TITLE' })}
          </div>
          <hr />
          <div className="error-content">
            {intl.formatMessage({ id: 'CONFIRMED_EMAIL_ERROR_CONTENT' })}
          </div>
        </div>
      )}
    </PageSection>
  );
}

export default ConfirmEmail;

