import './NumberItem.scss';

import React from 'react';
import { useIntl } from 'react-intl';

function NumberItem({ user = {}, number, currency, precision, ...props }) {
  const intl = useIntl();
  function generateNumberFormat() {
    if (number === 0 && !currency) {
      return number;
    }

    const options = {};
    if (typeof precision === 'number') {
      options.maximumFractionDigits = precision;
    }
    if (currency) {
      options.style = 'currency';
      options.currency = currency;
      if (number === 0) {
        options.maximumFractionDigits = 0;
      }
    }

    return intl.formatNumber(number, options);
  }

  return <>{generateNumberFormat()}</>;
}

export default NumberItem;

