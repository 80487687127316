import './MultipleFields.scss';

import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import checkCircleIcon from '../../../assets/img/svg/check-circle-outline.svg';
import checkedIcon from '../../../assets/img/svg/check.svg';
import closeIcon from '../../../assets/img/svg/close.svg';
import Input from '../Input/Input';

function MultipleFields({
  children,
  disabled,
  fields,
  commonLabel,
  commonId,
  additionalLabel,
  requestUrl,
  updateField,
  update,
  updateOptions = {},
  invalidDataLabel,
  ...otherProps
}) {
  const { values, setFieldValue } = useFormikContext();
  const [initialValue, setInitialValue] = useState(
    new Array(fields.length).fill('')
  );
  const [buttonsVisible, setButtonsVisibility] = useState(
    new Array(fields.length).fill(false)
  );
  const [keepButtonsVisible, setKeepButtonsVisible] = useState(false);
  const { change, isDataLoading, dataRequestError } = updateOptions;
  const [isSavedArray, setIsSavedArray] = useState(
    new Array(fields.length).fill(false)
  );

  const intl = useIntl();
  const translatedLabel = commonLabel
    ? intl.formatMessage({ id: commonLabel })
    : commonLabel;
  // An array of items is expected as props to the component, hence a hook to bind to the fields and get the errors can't be used
  // Either a new component should be used to errors should be fetched without a hook if there is a need for it

  const className = classNames('multiple-fields-message-wrapper', {
    [`${commonId}-wrapper`]: commonId,
    disabled: disabled,
  });

  const buttonsClassName = classNames('multiple-fields-buttons-wrapper', {
    'has-error':
      dataRequestError?.response?.data?.errors &&
      buttonsVisible.every((value) => value === false),
  });

  function handleBlur(name, index) {
    if (!keepButtonsVisible) {
      setButtonsVisibility(new Array(fields.length).fill(false));
      if (!isDataLoading && values[name] !== initialValue[index]) {
        change({ [name]: values[name] });

        setIsSavedArray((prevArray) => {
          const newArray = [...prevArray];
          newArray[index] = true;
          return newArray;
        });
      }
    }
  }

  function handleButtonClick(type, name, index) {
    if (type === 'reject') {
      setFieldValue(name, initialValue[index]);
    } else if (!isDataLoading && values[name] !== initialValue[index]) {
      change({ [name]: values[name] });

      setIsSavedArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = true;
        return newArray;
      });
    }
    setButtonsVisibility(new Array(fields.length).fill(false));
  }

  function handleKeyDown(event, name, index) {
    if (event.key === 'Enter') {
      let data = {
        [name]: values[name],
      };
      change(data);
      setIsSavedArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = true;
        return newArray;
      });
      event.target.blur();
    }

    if (event.key === 'Escape') {
      setFieldValue(name, initialValue[index]);
      setIsSavedArray((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = false;
        return newArray;
      });
    }
  }

  function handleFocus(event, index) {
    setInitialValue((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = event.target.value;
      return newValues;
    });
  }

  function handleAutocomplete(event) {
    if (event.target.hasAttribute('autocompleted')) {
      let data = {};
      fields.forEach((field) => {
        const name = document.getElementById(field.id).name;
        const value = document.getElementById(field.id).value;
        data[name] = value;
      });
      change(data);
      setIsSavedArray((prevArray) => {
        return new Array(fields.length).fill(true);
      });
    }
  }

  const showErrorAllowed = !(
    buttonsVisible.find((bv) => bv === true) && keepButtonsVisible
  );

  useEffect(() => {
    if (isSavedArray) {
      const timer = setTimeout(() => {
        setIsSavedArray((prevArray) => {
          return new Array(fields.length).fill(false);
        });
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [fields.length, isSavedArray]);

  return (
    <>
      <div className="form-group">
        {translatedLabel && <label htmlFor={commonId}>{translatedLabel}</label>}
        <div className={className}>
          {fields.map((field, index) => {
            const fieldClassName = classNames('multiple-field-item');
            return (
              <div
                className={classNames(buttonsClassName, {
                  'has-success':
                    isSavedArray[index] &&
                    !dataRequestError?.response?.data?.errors,
                })}
                key={`${field.name}.${index}`}
              >
                <Field
                  key={`${field.id}.${index}`}
                  as={Input}
                  id={field.id}
                  name={field.name}
                  className={fieldClassName}
                  disabled={disabled}
                  onBlur={() => handleBlur(field.name, index)}
                  onFocus={(event) => handleFocus(event, index)}
                  onChange={(event) => {
                    setFieldValue(field.name, event.target.value);
                    handleAutocomplete(event);
                    setButtonsVisibility((prevButtons) => {
                      const newButtons = [...prevButtons];
                      newButtons[index] = true;
                      return newButtons;
                    });
                  }}
                  onKeyDown={(event) => handleKeyDown(event, field.name, index)}
                  {...otherProps}
                />

                {isSavedArray[index] && (
                  <div
                    className={classNames('icon-wrapper', {
                      'has-success': !dataRequestError?.response?.data?.errors,
                    })}
                  >
                    <ReactSVG src={checkCircleIcon} />
                  </div>
                )}
                {buttonsVisible[index] && (
                  <div className="input-action-buttons">
                    <div className="action-buttons-wrapper">
                      <div
                        className="action-button confirm-button"
                        onMouseDown={() => {
                          setKeepButtonsVisible(true);
                        }}
                        onMouseUp={() => {
                          setKeepButtonsVisible(false);
                        }}
                        onClick={() => {
                          handleButtonClick('confirm', field.name, index);
                        }}
                      >
                        <ReactSVG
                          src={checkedIcon}
                          wrapper="span"
                          className="confirm-icon"
                        />
                      </div>
                      <div
                        className="action-button reject-button"
                        onMouseDown={() => {
                          setKeepButtonsVisible(true);
                        }}
                        onMouseUp={() => {
                          setKeepButtonsVisible(false);
                        }}
                        onClick={() => {
                          handleButtonClick('reject', field.name, index);
                        }}
                      >
                        <ReactSVG
                          src={closeIcon}
                          wrapper="span"
                          className="reject-icon"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          {dataRequestError?.response?.data?.errors &&
            invalidDataLabel &&
            showErrorAllowed && (
              <div className="error-message">
                <FormattedMessage id={invalidDataLabel} />
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default MultipleFields;
