import api from '../../services/sender';
import { TOYCONTROL_TOGGLE } from '../actions';

export default function () {
  return async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }

    try {
      const active = !getState().sender?.toycontrol?.active;
      const payload = await api.toycontrol({ toggle: true, active });

      if (!getState().user.isLoggedIn) {
        return;
      }

      return dispatch({ type: TOYCONTROL_TOGGLE, payload });
    } catch (error) {
      dispatch({ type: TOYCONTROL_TOGGLE, payload: error, error: true });
    }
  };
}
