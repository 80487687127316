import { MESSAGE_TYPE_SENDER, MESSAGE_TYPE_USER } from '../constants';
const create = (
  payload,
  partner,
  messagingId,
  activeCamUser,
  attachmentUser,
  sendername
) => {
  const msg = { ...payload };
  msg.type =
    msg.sender === messagingId ? MESSAGE_TYPE_SENDER : MESSAGE_TYPE_USER;
  msg.userId = partner;
  msg.message = msg.body;
  msg.color = msg.userColor = activeCamUser.color || '';
  msg.payload = msg.payload || {};

  if (activeCamUser?.type) {
    msg.userId = activeCamUser.userId;
    msg.from =
      activeCamUser.partner === msg.sender ? activeCamUser.name : sendername;

    msg.to = msg.from === activeCamUser.name ? sendername : activeCamUser.name;

    if (!msg?.payload?.lc_id) {
      msg.payload.lc_id = -1;
    }
  }

  if (msg?.attachment && msg.type === MESSAGE_TYPE_USER) {
    if (
      attachmentUser?.portals?.allowAttachments === true &&
      attachmentUser?.portals?.cdnPath !== ''
    ) {
      msg.attachment.cdnPath = attachmentUser.portals.cdnPath;
    }
  }

  return msg;
};

export default { create };

