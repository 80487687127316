export default (socketService) => () => (dispatch, getState) => {
  const {
    user: { access_token: token },
  } = getState();

  const evtid = 'user.keep-idle-' + Date.now();
  const type = 'user.keep-idle';
  const options = {
    type,
    token,
    evtid,
  };

  socketService.emit(type, options);
};
