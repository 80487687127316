import { closeAlert } from '../../../../actions';
import { upgradetimer as upgradetimerUtils } from '../../../../utils';
import { EXCLUSIVE_ASK_SENDER_ABORT } from '../../../actions';

export default (payload) => (dispatch, getState) => {
  const { upgradetimer } = getState();
  const timer = upgradetimerUtils.findExclusiveTimer(payload, upgradetimer);
  if (timer && timer.senderRequestStamp) {
    dispatch(closeAlert(timer.senderRequestStamp));
  }
  dispatch({
    type: EXCLUSIVE_ASK_SENDER_ABORT,
    payload,
  });
};
