import './BackButton.scss';

import React from 'react';
import { ReactSVG } from 'react-svg';

import backButton from '../../assets/img/svg/chevron-single-left.svg';

function BackButton({ ...attributes }) {
  return (
    <ReactSVG
      src={backButton}
      wrapper="span"
      className="back-button"
      {...attributes}
    />
  );
}

export default BackButton;
