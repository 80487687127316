import userUtils from '../../../../utils/user';
import { stream, camUser as userActions } from '../../../';
import { MANAGEMENT_EVENTS_STREAM_STOP } from '../../../actions';

export default (userInfo) => {
  return (dispatch, getState) => {
    if (userInfo.type === 'client') {
      return;
    }
    const {
      camUser: { camUsers },
      dialog: { dialogs },
      camUserFilter,
      sender: {
        feature: { powersender },
      },
    } = getState();

    const { action } = userUtils.createStopEventAction(
      camUsers,
      dialogs,
      MANAGEMENT_EVENTS_STREAM_STOP,
      userInfo,
      powersender,
      camUserFilter
    );

    dispatch(action);
    if (
      action?.meta?.nextActiveUser?.userId &&
      action.meta.nextActiveUser.userId !== 'system'
    ) {
      userActions.select(action?.meta?.nextActiveUser?.userId);
    }
    console.log(`Check Stop Stream`);
    dispatch(stream.checkStop());
  };
};
