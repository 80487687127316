import {management} from '../actions';
import {
  LOGMESSAGE_LEVEL_DEBUG,
  LOGMESSAGE_LEVEL_INFO,
  LOGMESSAGE_LEVEL_WARN,
  LOGMESSAGE_LEVEL_ERROR
} from '../constants';

import io from 'socket.io-client';
// @todo check if we realy need this dictionary to remove listeners

const listener = {};
let socket = null;
let _lastSocketId;

const init = (url, options, dispatch) => {
  socket = io(url, options);
  const events = management.events;
  for (let action in events) {
    listener[action] = (data) => dispatch(events[action](data));
    console.log(`set socket action ${action}`);
    socket.on(action, listener[action]);
  }
};

const emit = (msg, data, cb) => {
  if (socket !== null) {
    socket.emit(msg, data, cb);
  }
};

const disconnect = () => {
  if (socket !== null) {
    socket.disconnect();
  }
};
const connected = () => {
  if (socket !== null) {
    _lastSocketId = socketId();
    return socket.connected;
  }
  return false;
};

const logMessage = (logLevel, message, type = '') => {
  if (socket !== null) {
    emit('logMessage', {logLevel, message, type});
  }
};
const log = {
  splunk: {
    info: (message) => logMessage(LOGMESSAGE_LEVEL_INFO, message, 'splunk'),
    warn: (message) => logMessage(LOGMESSAGE_LEVEL_WARN, message, 'splunk'),
    error: (message) => logMessage(LOGMESSAGE_LEVEL_ERROR, message, 'splunk')
  },
  debug: (message) => {
    logMessage(LOGMESSAGE_LEVEL_DEBUG, message);
  },
  info: (message) => {
    logMessage(LOGMESSAGE_LEVEL_INFO, message);
  },
  warn: (message) => {
    logMessage(LOGMESSAGE_LEVEL_WARN, message);
  },
  error: (message) => {
    logMessage(LOGMESSAGE_LEVEL_ERROR, message);
  }
};
const socketId = () => {
  if (socket) {
    return socket.io.engine.id;
  }
  return null;
};
const lastSocketId = () => _lastSocketId;

export default {
  emit,
  init,
  disconnect,
  connected,
  log,
  socketId,
  lastSocketId
};
