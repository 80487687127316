import dayjs from 'dayjs';
import { connect } from 'react-redux';

import Onboarding from './Onboarding';

const defaultsObj = {
  nickname: '',

  gender: '',
  country_code: 'DE',
  native_language: 'de',
  additional_languages: [],
  zip: '',

  fsk12Images: [],
  fsk16Images: [],
  images: [],
  profile_pictures: {},

  forename: '',
  surname: '',
  birthday: '',

  birthdayDay: '',
  birthdayMonth: '',
  birthdayYear: '',

  documents: [],
};

function getInitialValues(reduxObj) {
  let returnObj = {};
  let reduxValues = { ...reduxObj };

  for (const k in defaultsObj) {
    returnObj[k] = reduxValues[k] || defaultsObj[k];
  }

  // Nickname
  if (
    typeof returnObj.nickname === 'string' &&
    returnObj.nickname.match(/^(?:VX|(?:user|sender)-)/i)
  ) {
    returnObj.nickname = '';
  }

  // Birthday
  const birthday = dayjs.utc(returnObj.birthday);
  if (birthday.isValid()) {
    returnObj.birthdayDay = birthday.date();
    returnObj.birthdayMonth = birthday.month();
    returnObj.birthdayYear = birthday.year();
    returnObj.birthday = birthday.format('YYYY-MM-DD');
  } else {
    returnObj.birthdayDay = 1;
    returnObj.birthdayMonth = 0;
    returnObj.birthdayYear = 1980;
    returnObj.birthday = '1980-01-01';
  }

  return returnObj;
}

const mapStateToProps = (state) => ({
  initialValues: getInitialValues({
    ...state.user.private,
    ...state.user.public,
    ...state.user.account,
  }),
  user: state.user,
  isMobile: state.view.isMobile,
  isMedium: state.view.isMedium,
  isMediumLarge: state.view.isMediumLarge,
});

export default connect(mapStateToProps)(Onboarding);

