import { MANAGEMENT_LOGGED_IN } from '../../constants';
import { muteMicrophone } from '../../utils/cam';
import { management } from '../';
import { MICROPHONE_MUTE } from '../actions';

export default (mute) => {
  return (dispatch, getState) => {
    const stream = getState().streaming.stream;

    muteMicrophone(stream, mute);
    if (getState().streaming.managementStep === MANAGEMENT_LOGGED_IN) {
      if (mute) {
        dispatch(management.disableMicrophone());
      } else {
        dispatch(management.enableMicrophone());
      }
    }
    return dispatch({
      type: MICROPHONE_MUTE,
      payload: {
        mute,
      },
    });
  };
};
