import { connect } from 'react-redux';

import { openDialog } from '../../../../actions/dialog';
import Game4Score from './Game4Score';

const mapStateToProps = (state) => ({
  user: state.user,
  lang: state.intl.locale,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (id, name) => dispatch(openDialog(id, name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Game4Score);
