import { connect } from 'react-redux';

import { user as userUtils } from '../../../utils';
import Icebreaker from './Icebreaker';

const mapStateToProps = (state) => ({
  isIcebreakerEnabled: userUtils.isIcebreakerEnabled(state.user),
  isIcebreakerAllowed: userUtils.isIcebreakerAllowed(state.user),
});

export default connect(mapStateToProps)(Icebreaker);
