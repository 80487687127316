import React from 'react';
class Useraudio extends React.Component {
  render() {
    const { running, stream } = this.props;
    return !running ? null : (
      <audio
        autoPlay
        ref={(audio) => {
          this.audio = audio;
          if (stream && this.audio) {
            this.audio.srcObject = stream;
          }
        }}
      />
    );
  }
}

export default Useraudio;
