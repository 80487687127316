import attachments from './attachments';
import icebreaker from './icebreaker';

export default {
  ...attachments,
  ...icebreaker,

  MONEYMAKERS_HEADLINE:
    'Verpasse keine Chance einen zahlenden Kunden zu generieren',
};
