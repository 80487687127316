import {
  CONTEST_ACTIVE,
  CONTEST_FINISHED,
  CONTEST_PREVIEW,
  CONTEST_TEMPLATE,
} from '../constants';

const hasContest = (state) =>
  Object.values(state.contests).some((el) => el.template === CONTEST_TEMPLATE);
const hasActiveOrFinishedContest = (state) =>
  hasContest(state) &&
  [CONTEST_FINISHED, CONTEST_ACTIVE].includes(getContest(state).state);

const hasPreviewContest = (state) =>
  hasContest(state) && [CONTEST_PREVIEW].includes(getContest(state).state);

const getContest = (state) =>
  Object.values(state.contests).find((el) => el.template === CONTEST_TEMPLATE);

export default {
  hasContest,
  getContest,
  hasActiveOrFinishedContest,
  hasPreviewContest,
};
