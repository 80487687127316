import dayjs from 'dayjs';
import { connect } from 'react-redux';

import MyPersonalDetails from './MyPersonalDetails';

const defaultsObj = {
  nickname: '',
  email: '',

  forename: '',
  surname: '',
  birthday: '',

  address1: '',
  address2: '',
  zip: '',
  city: '',
  country_code: '',

  phone: '',
  skype: '',

  company: '',
  vat: '',

  minimum_payout: '',

  bank_forename: '',
  bank_surname: '',

  bank_address1: '',
  bank_address2: '',
  bank_zip: '',
  bank_city: '',
  bank_country: '',

  bank_location: '',

  bank_holder: '',
  bank_sepa_iban: '',
  bank_sepa_swift: '',
  bank_nonsepa_iban: '',
  bank_nonsepa_swift: '',
  bank_nonsepa_name: '',
  bank_nonsepa_address: '',
  bank_nonsepa_city: '',
  bank_nonsepa_country: '',
};

function getInitialValues(reduxObj) {
  let returnObj = {};
  let reduxValues = { ...reduxObj };

  for (const k in defaultsObj) {
    returnObj[k] = reduxValues[k] || defaultsObj[k];
  }

  // Nickname
  if (
    typeof returnObj.nickname === 'string' &&
    returnObj.nickname.match(/^(?:VX|(?:user|sender)-)/i)
  ) {
    returnObj.nickname = '';
  }

  // Birthday
  const birthday = dayjs.utc(returnObj.birthday);
  if (birthday.isValid()) {
    returnObj.birthdayDay = birthday.date();
    returnObj.birthdayMonth = birthday.month();
    returnObj.birthdayYear = birthday.year();
    returnObj.birthday = birthday.format(
      reduxObj.language === 'de' ? 'DD.MM.YYYY' : 'MM/DD/YYYY'
    );
  } else {
    returnObj.birthdayDay = 1;
    returnObj.birthdayMonth = 1;
    returnObj.birthdayYear = 1980;
    returnObj.birthday =
      reduxObj.language === 'de' ? '01.01.1980' : '01/01/1980';
  }

  return returnObj;
}

const mapStateToProps = (state) => ({
  initialValues: getInitialValues({
    ...state.user.public,
    ...state.user.private,
    ...state.user.account,
  }),
  user: state.user,
});

export default connect(mapStateToProps)(MyPersonalDetails);
