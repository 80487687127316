import { VIEW_DIMENSIONS_LARGE_SCREEN_STREAMING_WINDOW_UPDATE } from '../actions/actions';

const initialState = {
  largeScreenStreaming: { width: 0, height: 0 },
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_DIMENSIONS_LARGE_SCREEN_STREAMING_WINDOW_UPDATE:
      return { ...state, largeScreenStreaming: action.payload };
    default:
      return state;
  }
};

export default reduce;
