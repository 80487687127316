import { roomType as roomTypeUtils, uuid } from '../../utils';
import { NOTIFICATION_SEND } from '../actions';
export default ({ title, body, icon }) => (dispatch, getState) => {
  const {
    sender: { roomTypes },
  } = getState();

  if (roomTypeUtils.isEvent(roomTypes)) {
    return;
  }

  dispatch({
    type: NOTIFICATION_SEND,
    payload: { title, body, icon },
    meta: { stamp: uuid() },
  });
};
