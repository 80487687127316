import {
  EXCLUSIVE_ASK,
  EXCLUSIVE_ASK_SENDER,
  EXCLUSIVE_ASK_SENDER_ABORT,
  SENDER_ASK_UPGRADE_DENIED,
  SHOW_TYPE_EXPIRED,
  UPGRADETIMER_START,
  UPGRADETIMER_STOP,
  UPGRADE_START,
} from '../actions/actions';
import { SHOW_TYPE_EXCLUSIVE } from '../constants';

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPGRADETIMER_START:
      return [...state, action.payload];
    case EXCLUSIVE_ASK_SENDER:
      return state.map((timer) => {
        if (
          action.payload &&
          timer.userId === action.payload.userId &&
          timer.showType === SHOW_TYPE_EXCLUSIVE
        ) {
          timer.senderRequest = true;
        }
        return timer;
      });
    case EXCLUSIVE_ASK_SENDER_ABORT:
      return state.map((timer) => {
        if (action.payload && timer.userId === action.payload.userId) {
          timer.senderRequest = false;
        }
        return timer;
      });
    case UPGRADETIMER_STOP:
      return state.filter((timer) => timer.userId !== action.payload.userId);
    case EXCLUSIVE_ASK: // if a user asks for an exclusive show, exclusive button gets deaktivated
      if (!action.meta || !action.meta.start) {
        return state.map((timer) => {
          if (
            timer.userId === action.payload.userId &&
            timer.showType === SHOW_TYPE_EXCLUSIVE
          ) {
            timer.requestActive = false;
          }
          return timer;
        });
      }
      return state.map((timer) => {
        if (
          timer.userId === action.payload.userId &&
          timer.showType === SHOW_TYPE_EXCLUSIVE
        ) {
          timer.requestActive = true;
        }
        return timer;
      });
    case UPGRADE_START:
    case SENDER_ASK_UPGRADE_DENIED: // if the asked the user but user denied, sender is not able in this sessin to ask again
    case SHOW_TYPE_EXPIRED:
      return state.filter(
        (timer) =>
          timer.userId !== action.payload.userId ||
          (timer.showType !== action.payload.showType &&
            timer.userId === action.payload.userId)
      );
    default:
      return state;
  }
};

export default reducer;
