import React from 'react';
import { useIntl } from 'react-intl';

import useTranslateTableTitles from '../../../../../hooks/useTranslateTableTitles';
import BackButton from '../../../../BackButton';
import Button from '../../../../Button/Button';
import SpinnerComponent from '../../../../Spinner/SpinnerComponent';
import { fullVirtualGiftsTableTitles } from './AllVirtualGiftsList.functions';

function VirtualGiftsList({ items, setFilteredGifts, svg, category, loading }) {
  const intl = useIntl();
  const translatedTitles = useTranslateTableTitles(fullVirtualGiftsTableTitles);
  return (
    <>
      <div className="table-box">
        {loading ? (
          <SpinnerComponent />
        ) : (
          <>
            <h2 className="headline">
              {svg ? (
                <BackButton onClick={() => setFilteredGifts(null)} />
              ) : null}
              <span>
                {intl.formatMessage({ id: 'VIRTUAL_GIFTS_CATEGORY' })}
              </span>
              <span>{category}</span>
            </h2>
            <table className="virtual-gifts noHover">
              <thead>
                <tr>
                  {translatedTitles.map((title, index) => {
                    return (
                      <th
                        style={{
                          width: title.width,
                          textAlign: title.position,
                        }}
                        key={`${title}.${index}`}
                      >
                        {title.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {items?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <img
                          src={
                            item.img_medium &&
                            `${process.env.REACT_APP_IMAGE_URL_1}${item.img_medium}`
                          }
                          alt=""
                        />
                      </td>
                      <>
                        <td>{item.name}</td>
                        <td className="text-right">
                          {item.price} {intl.formatMessage({ id: 'COINS' })}
                        </td>
                      </>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {svg ? (
              <Button
                type="button"
                className="primary"
                intlTranslate={false}
                onClick={() => setFilteredGifts(null)}
                label={'Back'}
              />
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

export default VirtualGiftsList;

