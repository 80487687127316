export default {
  LANDING_PAGE_HEADLINE_1: `Use Social Media to Make Money!`,
  LANDING_PAGE_HEADLINE_2_1: `Make money online`,
  LANDING_PAGE_HEADLINE_2_2: `Whenever and wherever you like`,
  LANDING_PAGE_HEADLINE_2_3: `Start Working as a Creator Today`,
  LANDING_PAGE_HEADLINE_2_4: `We bring followers and fans directly to you`,
  LANDING_PAGE_HEADLINE_2_5: `Over 4.5 million Followers`,
  LANDING_PAGE_HEADLINE_2_6: `850,000 new followers per month`,
  LANDING_PAGE_HEADLINE_3: `FAQ / <span class="bold">Frequently Asked Questions</span`,

  CAROUSEL_ITEM_1: `
    <div class="item-headline">
      Get started with our 
      <br />
      <span class="bold">Video-Tour!</span>
    </div>
    <div clasS="item-content">
    Take one minute and learn everything you need to know to kickstart your career as Creator with us. Register now and benefit from our personal support and various free coachings
    </div>`,

  CAROUSEL_ITEM_2: `
    <div class="item-headline">
      World Class
      <br />
      <span class="bold">Content Marketing</span>
    </div>
    <div clasS="item-content">
      Earn money from home or remotely when it suits you. Be your own boss and earn more than 60 EUR per hour with your own content!
    </div>`,
  CAROUSEL_ITEM_3: `
    <div class="item-headline">
      <span class="bold">Top Earning</span>
      <br />
      Potential
    </div>
    <div class="item-content">
      Get up to 32% commission as a Creator. Bring your followers to LiveCreator and secure up to 57% commission!
    </div>`,
  CAROUSEL_ITEM_4: `
    <div class="item-headline">
      Manage Your Own <br />
      <span class="bold">Work-Life Balance</span>
    </div>
    <div class="item-content">
      Decide what you want to do in front of the webcam and what is taboo! Anonymous, friendly chats with or without webcam. Maybe even something sexier? It's up to you!
    </div>`,
  CAROUSEL_ITEM_5: `
    <div class="item-headline">
      No Experience <br />
      <span class="bold">Necessary</span>
    </div>
    <div class="item-content">
      Benefit from our free webinars and training sessions across all levels and topics! Support and coaching available for newbies and experienced Creators.
    </div>`,

  PANEL_TITLE_1: `What Advantages does LiveCreator offer?`,
  PANEL_SUBTITLE_1: `Start your career as a Creator! LiveCreator introduces you to millions of fans and followers! No experience? No problem! We'll show you what's important in free webinars and training sessions. All you need is a smartphone with a camera.`,
  PANEL_CONTENT_1: `
    {image}
    <p>With around 850,000 new members register with us every month there’s no need to worry that fans won’t be able to find you and become followers.
      Spend your time chatting with or without webcam, in private chats or talking directly through your cam stream. You get paid for every chat message and every minute you stream for your fans / followers! Whenever it suits you, from home or on the go - you decide!
    </p>
    <p>
      Registering as a Creator is free of charge! There are no fees or costs, just try it out with no obligations! You will see how much fun it is - and how easy it is to make a lot of money.
    </p>
    <ul>
      <li>Organize your time yourself (Full-time, Part-time, A few hours per week...)</li>
      <li>Manage your work-life balance yourself</li>
      <li>Be your own boss</li>
      <li>You decide how much money you want to earn.</li>
    </ul>`,

  PANEL_TITLE_2: `What Income Opportunities does LiveCreator offer you?`,
  PANEL_SUBTITLE_2: `The average hourly rate of EUR 31 can be achieved without any problems, however if you put in the time and effort up to 60 EUR per hour is possible! You decide when and how much you want to work. Be your own boss!`,
  PANEL_CONTENT_2: `
    <p>
      Set your own working hours with absolute flexibility. Full-time or part-time, a few hours a week, only on weekends or evenings after your day job. It is up to you how much money you want to make. The more often you are active on the platform, the more fans / followers you will make and the more sales your profile will generate. And you earn up to 32% commission!
    </p>
    {image}
    <p>Achieve the financial independence you've always strived for. Vacations, nice clothes, designer shoes or even a new car. Commissions are automatically paid out twice per month.</p>
    Find out how to earn even more commission {link}`,

  PANEL_TITLE_3: `How does LiveCreator work?`,
  PANEL_SUBTITLE_3: `LiveCreator offers you exclusive access to social media webcam platforms with several million active members and around 850,000 new users per month.<br />
This way LiveCreator leads new fans / followers to your profile each day.`,
  PANEL_CONTENT_3: `
    <p>
      Work with  computer or laptop or with your smartphone. Stream, chat or upload video and / or picture content to keep your followers entertained and make money. It doesn't matter whether you work from home or if you are on the go. You can always make money. And with our easy to use system you don't need to have any special technical skills. Just sit back and enjoy a stress-free work environment.
    </p>
      {image}
    <p>
       The platform is designed so that Creators (Content Creators, webcam models, influencers) interact live with fans / followers in the chat with or without webcam. This can be private conversations, small talk or erotic shows. If you don't want to show your face and prefer to remain anonymous, that's possible too. You decide what you want to share with your followers / fans.
    </p>
    <p>
       No matter what you choose, there are enough fans / followers for every target group who are more than happy to pay for every message they send or every minute they spend with you, and you can also set your own prices.
    </p>`,

  PANEL_TITLE_4: `How can you make even more money with LiveCreator?`,
  PANEL_SUBTITLE_4: `We already have millions of members on our platforms and will make sure that they will get to know your profile. But your followers from other platforms are also very welcome on LiveCreator and will bring you additional commissions.`,
  PANEL_CONTENT_4: `
    <p>
      Because if you already have your own social media profile and bring your followers to LiveCreator, we will pay you an additional 25% commission for them! You will get this 25% commission on all purchases from followers you brought along - even on their purchases from other creators!
    </p>
    {image}
    <p>
      But even without “bringing” followers, you can become a successful creator with our support. Especially if you have little to no experience so far, you will benefit from our free webinars (training and further education). On request, you will also receive accompanying, individual coaching. If you still don't know what to do, our support team looks forward to answering your questions. Just contact us and we will support you in the best possible way!
    </p>`,

  PANEL_TITLE_5: `Why are so many Content Creators and Influencers successful?`,
  PANEL_SUBTITLE_5: `A main factor for this success is understanding you are working solely for yourself. How much money you earn per month is in your own hands and there is no boss taking a slice from you. That is very motivating and encouraging.`,
  PANEL_CONTENT_5: `
    {image}
    <p>
     There is no need to justify yourself to an employer or do something that you don't enjoy. Work for yourself and manage your time the way you want. And with many members on LiveCreator at any time of the day you are not tied down to certain hours.
    </p>
    <p>
      A good work-life balance is becoming more and more important these days. It increases your satisfaction, inner peace and well-being. And that in turn lays a great foundation for you to be successful.
    </p>
    <p>
      Social media and webcam platforms are a crisis-proof industry of the future. This gives you the security of being able to work successfully long term while earning a lot of money.
    </p>`,

  PANEL_TITLE_6: `I have never worked in front of a webcam, what requirements do I have to meet?`,
  PANEL_SUBTITLE_6: `The most important requirement is that you are communicative and like to talk to people. You don't need any special experience or previous knowledge. Our support team will help you with the first steps.`,
  PANEL_CONTENT_6: `
    <p>
      You will see, that you will quickly learn how to behave in front of the webcam. You will have the greatest success if you simply present yourself as you really are. Don't worry if you're still nervous and insecure at first. This is completely normal and will pass quickly.
    </p>
    {image}
    <p>
      We also offer free webinars (special training and further education). There, we will explain the basic success factors, e.g. the settings of your webcam, the right illumination, what should and should not be visible in the background, how to best present yourself in front of the webcam, how to start a conversation and also how you convert your followers to regular customers.
    </p>
    <p>
      In addition, our support team will be happy to answer all your questions and provide useful tips. Feel free to contact us, we look forward to hear from you!
    </p>`,

  PANEL_TITLE_7: `Why should I become a Creator?`,
  PANEL_SUBTITLE_7: `You can start right now without any prior knowledge or experience and earn money immediately. Anyone who is at least 18 years old can register and earn money on LiveCreator.`,
  PANEL_CONTENT_7: `
    <p>
      Registration is free and you can quickly create a great profile. Interested members can follow and can contact you via chat or your webcam and you get paid for every message in the chat and every minute a fan / follower spends with you in the webcam session.
    </p>
    <p>
      Additionally, it's a lot of fun to chat and be in front of the webcam! You come into contact with a wide variety of people, have a great time and get paid for it. You will receive a lot of compliments and attention. And best of all, you decide when and where you work. You are your own boss and earn an above-average income.
    </p>`,

  PANEL_TITLE_8: `What experience do I need?`,
  PANEL_SUBTITLE_8: `You don't need any previous experience to become a Creator. Are you communicative? A people person? That is the best requirement for success.`,
  PANEL_CONTENT_8: `
    <p>
      LiveCreator offers free seminars and training sessions for both beginners and experienced Creators. You can also speak to a support manager at any time if you have any questions. Our team looks forward to hearing your questions or suggestions.
    </p>
    <p>
      In the chat and webcam sessions you only do and show what you feel comfortable with. Some Creators don't show their faces, choosing to remain completely anonymous. Others only show their faces and nothing else and some prefer to put on a sexy show in front of the webcam. You decide what you want to do. On LiveCreator there are plenty of fans and something for everyone, especially if you want to make money.
    </p>`,

  PANEL_TITLE_9: `Do I need special technical equipment?`,
  PANEL_SUBTITLE_9: `A smartphone with an internet connection is enough to get started. You can work more efficiently and earn money more effectively with a laptop or desktop PC. If you also want to show yourself via cam stream and videos, at least one integrated camera is required.`,
  PANEL_CONTENT_9: `
    <p>
      Successful creators, who have been around for a longer time, sometimes buy a better cam and special lighting for the live stream or for content shoots. However, this is not necessary – especially in the beginning.
    </p>
    <p>
      Do you have a smartphone or a computer? Then you can start right now and start earning money!
    </p>`,

  PANEL_TITLE_10: `Do I have to look like a model to make money?`,
  PANEL_SUBTITLE_10: `Absolutely not! Appearance plays no role whatsoever. Body shape and looks are irrelevant. What matters is your personality and how you treat your fans / followers.`,
  PANEL_CONTENT_10: `
    <p>
      Attention, kindness and interest are much more important to most members than looks.
    </p>
    <p>
      There are so many followers on LiveCreator, with such different tastes, you can guarantee there will be enough fans looking for just your type. Most users find their next-door neighbor more approachable and interesting than any super-model. Be who you are, and you will reap the rewards!
    </p>`,

  PANEL_TITLE_11: `How much money can I earn per week?`,
  PANEL_SUBTITLE_11: `Average earnings are around EUR 31 an hour but it varies from Creator to Creator. The more active you are and the more fans / followers you generate the more you will earn.`,
  PANEL_CONTENT_11: `
    <p>
      Our top Creators earn more than 60 EUR per hour. Often these are the Creators who are very active and have a lot of fans / followers. Experience shows that it takes a few weeks before you start earning that much, but it can be quickly achieved with friendliness, a pleasant voice and tone along with a bit of hard work.
    </p>`,

  PANEL_TITLE_12: `Do I have to show my face or can I be anonymous?`,
  PANEL_SUBTITLE_12: `You decide what and how much you want to show. Most Creators show their faces, but there are also successful Creators who remain anonymous.`,
  PANEL_CONTENT_12: `
    <p>
      It is entirely up to you whether you want to show your face on the webcam or not. Just do what you feel comfortable with.
    </p>`,

  PANEL_TITLE_13: `Do I have to undress in front of the webcam?`,
  PANEL_SUBTITLE_13: `Of course not! You only do and show whatever you feel comfortable with. LiveCreator is primarily about interacting with your fans / followers. Nobody has to undress to take part.`,
  PANEL_CONTENT_13: `
    <p>
      While there are Creators who like to show erotic content and put on sexy shows, and who are also very successful, it's up to you what you want to do in front of the webcam. Most fans just want to have a chat. And for that they pay per minute!
    </p>`,

  PANEL_TITLE_14: `Am I tied to  a minimum or fixed working hours?`,
  PANEL_SUBTITLE_14: `No. You only work when you want to. There is also no requirement to work a specific number of hours per day or per week. It's entirely up to you!`,
  PANEL_CONTENT_14: `
    <p>
      Of course, when you work more you make more money. We recommend that you create a work time schedule that will make it easier for you and easier for your fans / followers to know when you are online.
    </p>
    <p>
      Our support team is happy to advise you if you have any questions about how to best organize yourself in order to be more successful.
    </p>`,

  PANEL_TITLE_15: `Do I have to bring my own fans and followers?`,
  PANEL_SUBTITLE_15: `No. The social media webcam pages in the LiveCreator network have millions of members and we make sure that they can find your profile. All completely free of charge for you.`,
  PANEL_CONTENT_15: `
    <p>
      But if you already have many followers on social media you can send these followers to your profile on LiveCreator. You will also receive an additional commission from us when they spend money. Even if these users spend money with other Creators, you will still receive money from us. So, you get to earn twice! You will receive a commission of 25% on all sales from followers that you send to LiveCreator.
    </p>`,

  PANEL_TITLE_16: `Do I have to pay tax on my income?`,
  PANEL_SUBTITLE_16: `Yes of course. As with any income, you must declare and pay taxes to your responsible tax office on all LiveCreator commissions.`,
  PANEL_CONTENT_16: `
    <p>
      If you do not yet have a trade license, please contact your trade office and tax office. They will forward you all the required documents and inform you on the next steps. Signing up is a quick and easy one-time process.
    </p>
    <p>
      We cannot offer tax advice, but if you have questions, we recommend you contact your trade and tax office, or talk to a tax advisor.
    </p>`,

  PANEL_TITLE_17: `Will a boss try to tell me how to work?`,
  PANEL_SUBTITLE_17: `No! You are your own boss and only you determine when, where and how you work. It is very liberating to be your own boss and work for yourself, especially when it comes to the financial aspect.`,
  PANEL_CONTENT_17: `
    <p>
      At LiveCreator you don't make your employer rich, you make yourself rich! If you are disciplined and can work hard you will earn more money on LiveCreator than at any other job, while being your own boss!
    </p>`,

  PANEL_TITLE_18: `Are there any costs for me?`,
  PANEL_SUBTITLE_18: `No. Everything you do on LiveCreator is free of charge. We never charge fees or add hidden costs. Registering with LiveCreator is completely risk-free.`,
  PANEL_CONTENT_18: `
    <p>
      With LiveCreator you earn a commission for every sale a user makes with you - without incurring any costs, so it is absolutely worth your while!
    </p>`,

  PANEL_TOGGLE_MORE: `more`,
  PANEL_TOGGLE_LESS: `less`,
  CAROUSEL_BUTTON_LABEL: `Learn more`,
  LANDING_REGISTRATION_BUTTON: `Sign up now for free`,
};
