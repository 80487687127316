import countdownAction from './countdown';

let timer;

const start = () => (dispatch, getState) => {
  console.log('toy countdown timer start');
  timer = setTimeout(() => {
    const countDown = getState().sender?.runToy?.countDown;

    dispatch(countdownAction(countDown));
    if (countDown - 1 > 0) {
      dispatch(start());
    }
  }, 1000);
};

const stop = () => (dispatch) => {
  if (timer) {
    console.log('toy countdown timer stop');
    dispatch(countdownAction(1));
    clearTimeout(timer);
    timer = null;
  }
};

export const toyTimer = { start, stop };
