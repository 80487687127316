import { MANAGEMENT_LOGGED_OUT } from '../../constants';
import { NO_ORIGIN_EXCLUSIVE } from '../../errors';
import { roomType, sender, uuid } from '../../utils';
import { ROOM_TYPES_CHANGE } from '../actions';
import { management } from '..';

export default (activateExclusive, save = true) => {
  return (dispatch, getState) => {
    const senderState = JSON.parse(JSON.stringify(getState().sender));
    const oldRoomTypes = senderState.roomTypes;
    let roomTypes, logins;
    if (activateExclusive) {
      logins = sender.activateOriginsForExclusive(senderState);
      if (!logins) {
        return dispatch({
          type: ROOM_TYPES_CHANGE,
          error: true,
          meta: { stamp: uuid() },
          payload: new Error(NO_ORIGIN_EXCLUSIVE),
        });
      }
      roomTypes = roomType.activateExclusive(oldRoomTypes);
    } else {
      roomTypes = roomType.activateNormal(oldRoomTypes);
      logins = senderState.logins;
    }
    if (
      getState().streaming.managementStep === MANAGEMENT_LOGGED_OUT ||
      !save
    ) {
      dispatch({
        type: ROOM_TYPES_CHANGE,
        payload: {
          roomTypes,
          logins,
        },
      });
    }
    if (save) {
      dispatch(management.switchOrigins(logins, roomTypes));
    }
  };
};
