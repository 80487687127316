import { COOKIE } from '../actions';
export default function (thirdPartyCookiesAllowed, cookieHintData) {
  return {
    type: COOKIE,
    payload: {
      thirdPartyCookiesAllowed,
    },
    meta: cookieHintData,
  };
}
