import { connect } from 'react-redux';

import Kpi from './Kpi';

const mapStateToProps = (state) => ({
  loading: state.kpi.loading,
  performanceData: state.kpi.data,
});

export default connect(mapStateToProps)(Kpi);
