import { connect } from 'react-redux';

import { errorLog, userLogout } from '../../../../actions';
import Page404 from './Page404';

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = {
  userLogout,
  errorLog,
};

export default connect(mapStateToProps, mapDispatchToProps)(Page404);
