import React from 'react';
import { injectIntl } from 'react-intl';

import Button from '../../Button/Button';

class ConfirmAlert extends React.Component {
  render() {
    const { onConfirm, onAbort, message, type, level, stamp, intl } =
      this.props;
    const hasHeadline =
      (message.headline && message.headline !== '') ||
      (message.headlineId && message.headlineId !== '');
    const cssClasses = [
      'alert',
      `alert-${type.toLowerCase()}`,
      `alert-${level.toLowerCase()}`,
    ];
    if (hasHeadline) {
      cssClasses.push('has-headline');
    }
    return (
      <div className={cssClasses.join(' ')}>
        {hasHeadline ? (
          <h3>
            {message.translate && message.headlineId
              ? intl.formatMessage(
                  { id: message.headlineId },
                  message.headlineValues
                )
              : message.headline}
          </h3>
        ) : (
          ''
        )}
        <p>
          {message.translate
            ? intl.formatMessage({ id: message.id }, message.values)
            : message.message}
        </p>
        <div className="alert-buttons">
          <Button
            onClick={() => onConfirm(stamp)}
            variant="primary"
            label="alert.yes"
          />
          <Button
            onClick={() => onAbort(stamp)}
            variant="primary"
            label="alert.no"
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(ConfirmAlert);
