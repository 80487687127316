import './FourScore.scss';

import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import gamesIcon from '../../../../../assets/img/svg/games.svg';
import Banner4ScoreDE from '../../../../../assets/img/wiki/wiki_4score_banner_de.jpg';
import Banner4ScoreEN from '../../../../../assets/img/wiki/wiki_4score_banner_en.jpg';
import WikiView from '../../../../../assets/img/wiki/wiki_view_lcr.png';
import BackButton from '../../../../BackButton';

function FourScore({ locale, gamesBlocked, unconfirmed, onBackButtonClicked }) {
  return (
    <div className="wiki-content four-score-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="HEADLINE_4SCORE" />
      </h1>
      <p>
        <FormattedMessage id="CONTENT_1_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      {locale === 'en' ? (
        <img
          className="img-responsive"
          src={Banner4ScoreEN}
          alt="Community Game"
        />
      ) : (
        <img
          className="img-responsive"
          src={Banner4ScoreDE}
          alt="Community Game"
        />
      )}
      <p>
        <FormattedMessage id="CONTENT_2_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTENT_3_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTENT_4_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTENT_5_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTENT_6_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTENT_7_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTENT_8_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTENT_8_1_4SCORE" />
      </p>

      <img className="img-responsive" src={WikiView} alt="LiveCreator" />

      <h3 className="headline">
        <FormattedMessage id="HEADLINE_DETAIL_4SCORE" />
      </h3>

      <p>
        <FormattedMessage id="CONTENT_1_DETAIL_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CONTENT_2_DETAIL_4SCORE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p className="has-margin-bottom">
        <FormattedMessage id="CONTENT_3_DETAIL_4SCORE" />
      </p>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="LIST_HEADLINE_1_DETAIL_4SCORE" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="LIST_CONTENT_1_DETAIL_4SCORE" />
          </p>
          <p>
            <FormattedMessage id="LIST_CONTENT_1_1_DETAIL_4SCORE">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage id="LIST_CONTENT_1_2_DETAIL_4SCORE">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="LIST_HEADLINE_2_DETAIL_4SCORE" />
          </span>
        </div>
        <div className="wiki-list-content">
          <ul>
            <li>
              <FormattedMessage id="LIST_CONTENT_2_DETAIL_4SCORE" />
            </li>
            <li>
              <FormattedMessage
                id="LIST_CONTENT_2_1_DETAIL_4SCORE"
                values={{
                  link: unconfirmed ? (
                    <FormattedMessage id="MENU_ITEM_CAM_MESSENGER" />
                  ) : (
                    <Link to="/multi-messenger">
                      <FormattedMessage id="MENU_ITEM_CAM_MESSENGER" />
                    </Link>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage id="LIST_CONTENT_2_2_DETAIL_4SCORE" />
            </li>
            <li>
              <FormattedMessage id="LIST_CONTENT_2_3_DETAIL_4SCORE" />
            </li>
          </ul>
          <div className="icon-box">
            <div className="icon-description">
              <ReactSVG
                src={gamesIcon}
                wrapper="span"
                className="icon invitation"
              />
              <FormattedMessage id="LIST_ICON_INVITATION_4SCORE" />
            </div>
            <div className="icon-description">
              <ReactSVG src={gamesIcon} wrapper="span" className="icon" />
              <FormattedMessage id="LIST_ICON_RUNNING_4SCORE" />
            </div>
            <div className="icon-description">
              <ReactSVG src={gamesIcon} wrapper="span" className="icon turn" />
              <FormattedMessage id="LIST_ICON_TURN_4SCORE" />
            </div>
          </div>
          <ul>
            <li>
              <FormattedMessage
                id="LIST_CONTENT_2_4_DETAIL_4SCORE"
                values={{
                  link: unconfirmed ? (
                    <FormattedMessage id="LINK_TEXT_STATS_4SCORE" />
                  ) : (
                    <Link to="/statistics/game4score">
                      <FormattedMessage id="LINK_TEXT_STATS_4SCORE" />
                    </Link>
                  ),
                }}
              />
            </li>
            <li>
              <FormattedMessage id="LIST_CONTENT_2_5_DETAIL_4SCORE" />
            </li>
            {!gamesBlocked ? (
              <li>
                <FormattedMessage
                  id="LIST_CONTENT_2_6_DETAIL_4SCORE"
                  values={{
                    link: unconfirmed ? (
                      <FormattedMessage id="MENU_ITEM_SETTINGS" />
                    ) : (
                      <Link to="/my-account/settings/options">
                        <FormattedMessage id="MENU_ITEM_SETTINGS" />
                      </Link>
                    ),
                  }}
                />
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default FourScore;
