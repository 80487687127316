export const fullLivecamOverviewTableTitles = [
  { title: 'LIVECAM_OVERVIEW_NUMBER', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_TIME', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_CUSTOMER', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_PORTAL', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_DURATION', width: '20%', position: 'right' },
];

export const mediumLivecamOverviewTableTitles = [
  { title: 'LIVECAM_OVERVIEW_NUMBER', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_TIME', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_CUSTOMER', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_PORTAL', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_DURATION', width: '20%', position: 'right' },
];

export const mobileLivecamOverviewTableTitles = [
  { title: 'LIVECAM_OVERVIEW_NUMBER', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_TIME', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_CUSTOMER', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_PORTAL', width: '20%', position: 'left' },
  { title: 'LIVECAM_OVERVIEW_DURATION', width: '20%', position: 'right' },
];
