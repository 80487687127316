import { CHANGE_RESOLUTION_CAM } from '../../actions';
import { chooseCam } from '../';

export default (value, label) => (dispatch) => {
  dispatch({
    type: CHANGE_RESOLUTION_CAM,
    payload: {
      value,
      label,
    },
  });
  dispatch(chooseCam());
};
