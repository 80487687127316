export function generatePricesList(min, max, step) {
  let pricesList = [];

  for (let i = min; i <= max; i += step) {
    pricesList.push({ value: i, label: i });
  }

  return pricesList;
}

export function setNewPrices(prices) {
  let newPrices = {
    normal: 0,
    exclusive: 0,
    voyeur: 0,
    dating: 0,
  };

  newPrices.normal =
    prices.normal_show_price || prices.normal_show_default_price;
  newPrices.dating = newPrices.normal < 25 ? newPrices.normal : 25;
  newPrices.voyeur = Math.ceil(newPrices.normal * 0.75);
  newPrices.exclusive =
    prices.exclusive_show_price || prices.exclusive_show_default_price;

  return newPrices;
}
