import React from 'react';

function Input({ id, name, type = 'text', innerRef, ...otherProps }) {
  return (
    <input
      ref={innerRef}
      id={id || name}
      name={name}
      type={type}
      {...otherProps}
    />
  );
}

export default Input;

