export const fullDocumentsTableTitles = [
  { title: 'DOCUMENTS_NAME', width: '45%', position: 'left' },
  { title: 'DOCUMENTS_STATUS', width: '20%', position: 'left' },
  {
    title: 'DOCUMENTS_TYPE',
    width: '15%',
    position: 'left',
    showTableHeader: true,
  },
  { title: 'DOCUMENTS_UPLOAD_TIME', width: '20%', position: 'right' },
];

export const mediumDocumentsTableTitles = [
  { title: 'DOCUMENTS_NAME', width: '50%', position: 'left' },
  { title: 'DOCUMENTS_STATUS', width: '20%', position: 'left' },
  {
    title: 'DOCUMENTS_TYPE',
    width: '15%',
    position: 'left',
    showTableHeader: true,
  },
  { title: 'DOCUMENTS_UPLOAD_TIME', width: '30%', position: 'right' },
];

export const mobileDocumentsTableTitles = [
  { title: 'DOCUMENTS_NAME', width: '70%', position: 'left' },
  { title: 'DOCUMENTS_STATUS', width: '30%', position: 'left' },
];
