import { CAM_LOGOUT_TIMER_TIMEOUT } from '../../../constants';
import management from '../../../services/management';

let timer;

const start = () => () => {
  console.log('camLogoutTimer start');
  timer = setTimeout(management.disconnect, CAM_LOGOUT_TIMER_TIMEOUT);
};

const stop = () => () => {
  if (timer) {
    console.log('camLogoutTimer stop');
    clearTimeout(timer);
    timer = null;
  }
};

export const camLogoutTimer = {
  start,
  stop,
};
