const check = ({text, url, sessionId, origin}) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
      'X-Sessionid': sessionId,
      'X-Origin': origin
    },
    body: text
  };
  return fetch(url + '/check', requestOptions);
};
const save = ({bandwidth, url, sessionId, origin}) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Sessionid': sessionId,
      'X-Origin': origin
    },
    body: JSON.stringify({bandwidth: bandwidth.toString()})
  };
  return fetch(url + '/save', requestOptions);  
};
export default {
  check,
  save
};
