import './PageSection.scss';

import React from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';
import Scrollbar from '../Scrollbar/Scrollbar';

function PageSection({
  title,
  rightTitle,
  rightColumnTitle,
  rightColumn,
  rightHeader,
  className,
  children,
  containsHeader = false,
  scrollToTop = false,
  ...other
}) {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  return (
    <div className={`page-content-section ${className || ''}`} {...other}>
      <div className="column left-column">
        {title && (
          <div className="header">
            <span>{title}</span>
            {rightTitle && <span className="title-right">{rightTitle}</span>}
          </div>
        )}
        <Scrollbar
          visible={true}
          containsHeader={containsHeader}
          isLargeScreen={isLargeScreen}
          scrollToTop={scrollToTop}
        >
          <div className="column-content">{children}</div>
        </Scrollbar>
      </div>
      <div className="column right-column">
        {(rightHeader || rightColumnTitle) && (
          <div className="header">
            <span className="title-right">{rightColumnTitle}</span>
          </div>
        )}
        <Scrollbar
          visible={isLargeScreen}
          containsHeader={containsHeader}
          isLargeScreen={isLargeScreen}
          scrollToTop={scrollToTop}
        >
          {rightColumn && <div className="column-content">{rightColumn}</div>}
        </Scrollbar>
      </div>
    </div>
  );
}

export default PageSection;

