import './PersonalDetails.scss';

import { useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useButtonChange from '../../../../hooks/useButtonChange';
import { uuid } from '../../../../utils';
import Button from '../../../Button/Button';
import ButtonFieldWithErrors from '../../../Forms/ButtonFieldWithErrors';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import Input from '../../../Forms/Input/Input';
import MultipleFields from '../../../Forms/MultipleFields';

function PersonalDetails({
  update = () => {},
  user,
  spinner = () => {},
  countries = {},
  openOverlay = () => {},
}) {
  const intl = useIntl();
  const { values } = useFormikContext();
  const addressStatus = user.private.new_address?.status;
  const baseChangeOpts = {
    updateField: 'private',
    requestUrl: '/user/private',
    update,
    spinner,
  };
  const updateOptions = {
    phone: useButtonChange({
      ...baseChangeOpts,
      name: 'phone',
    }),
    skype: useButtonChange({
      ...baseChangeOpts,
      name: 'skype',
    }),
    company: useButtonChange({
      ...baseChangeOpts,
      name: 'company',
    }),
    vat: useButtonChange({
      ...baseChangeOpts,
      name: 'vat',
    }),
  };
  const country = countries.find(
    (c) => c.value === values?.country_code
  )?.label;

  const openAddressChanger = () => {
    const stamp = uuid();
    openOverlay({
      Component: 'AddressChanger',
      stamp,
      props: {
        intl,
        stamp,
      },
    });
  };

  return (
    <div className="personal-details-wrapper">
      <h1 className="headline">
        <FormattedMessage id="PERSONAL_DATA" />
      </h1>
      <div className="data-columns">
        <div className="forms-left">
          <FieldWithErrors
            as={Input}
            name="forename"
            label="FIELD_FIRST_NAME"
            disabled={true}
          />
          <FieldWithErrors
            as={Input}
            name="surname"
            label="FIELD_LAST_NAME"
            disabled={true}
          />
          <FieldWithErrors
            as={Input}
            name="birthday"
            label="FIELD_BIRTHDAY"
            disabled={true}
          />
          <FieldWithErrors
            as={Input}
            name="address1"
            label="FIELD_STREET_ADDRESS"
            disabled={true}
          />
          <FieldWithErrors
            as={Input}
            name="address2"
            label="FIELD_STREET_ADDRESS2"
            disabled={true}
          />
          <MultipleFields
            fields={[
              { name: 'zip', id: 'zip', label: 'FIELD_ZIP' },
              { name: 'city', id: 'city', label: 'FIELD_CITY' },
            ]}
            commonLabel="FIELD_ZIP_AND_CITY"
            commonId={'zip_city'}
            disabled={true}
          />
          <FieldWithErrors
            as={Input}
            name="country_cFode"
            disabled={true}
            label="FIELD_LIVINGCOUNTRY"
            value={country}
          />
          <div className="address-changer">
            {addressStatus === 'declined' && (
              <div className="error message-address">
                <div className={user.private?.new_address?.comment && 'title'}>
                  <FormattedMessage id="ADDRESS_DECLINED" />
                </div>
                {user.private?.new_address?.comment && (
                  <p>{user.private?.new_address?.comment}</p>
                )}
              </div>
            )}
            {addressStatus === 'pending' ? (
              <p>
                <FormattedMessage id="ADDRESS_PENDING_1" />
                <a
                  className="blue"
                  onClick={() => {
                    openAddressChanger();
                  }}
                >
                  {intl.formatMessage({ id: 'ADDRESS_PENDING_2' })}
                </a>
              </p>
            ) : (
              <Button
                className={'open-address-changer'}
                type="button"
                label={'CHANGE_ADDRESS'}
                onClick={() => {
                  openAddressChanger();
                }}
              />
            )}
          </div>
        </div>

        <div className="data-right">
          <div className="form-contact">
            <div className="contact-label-wrapper">
              <FormattedMessage id="FIELD_CONTACT" />
            </div>
            <ButtonFieldWithErrors
              as={Input}
              name="phone"
              label="FIELD_PHONE"
              updateOptions={updateOptions['phone']}
              invalidDataLabel="INVALID_PHONE_NUMBER"
            />
            <ButtonFieldWithErrors
              as={Input}
              name="skype"
              label="FIELD_SKYPE"
              updateOptions={updateOptions['skype']}
              invalidDataLabel="INVALID_SKYPE"
            />
          </div>
          {!user.account.slave && (
            <>
              <div className="form-business">
                <div className="business-label-wrapper">
                  <FormattedMessage id="PERSONAL_DETAILS_FOR_BUSINESS">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </div>
                <ButtonFieldWithErrors
                  as={Input}
                  name="company"
                  label="FIELD_COMPANY"
                  updateOptions={updateOptions['company']}
                  invalidDataLabel="INVALID_COMPANY_NAME"
                />
                <ButtonFieldWithErrors
                  as={Input}
                  name="vat"
                  label="FIELD_VAT"
                  updateOptions={updateOptions['vat']}
                  invalidDataLabel="INVALID_VAT"
                />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;

