import { connect } from 'react-redux';

import MyContent from './MyContent';

const mapStateToProps = (state) => ({
  user: state.user,
  isMobile: state.view.isMobile,
});

export default connect(mapStateToProps)(MyContent);

