export const fullPayoutsTableTitles = [
  { title: 'PAYOUT_PAYOUT', width: '15%', position: 'left' },
  { title: 'PAYOUT_FROM', width: '15%', position: 'left' },
  { title: '', width: '60%', position: 'left' },
  { title: 'PAYOUT_AMOUNT', width: '10%', position: 'right' },
];

export const mediumPayoutsTableTitles = [
  { title: 'PAYOUT_PAYOUT', width: '20%', position: 'left' },
  { title: 'PAYOUT_FROM', width: '15%', position: 'left' },
  { title: '', width: '50%', position: 'left' },
  { title: 'PAYOUT_AMOUNT', width: '15%', position: 'right' },
];

export const mobilePayoutsTableTitles = [
  { title: 'PAYOUT_PAYOUT', width: '30%', position: 'left' },
  { title: 'PAYOUT_FROM', width: '15%', position: 'left' },
  { title: '', width: '40%', position: 'left' },
  { title: 'PAYOUT_AMOUNT', width: '15%', position: 'right' },
];
