export default {
  ICEBREAKER_HEADLINE: 'Meine Icebreaker bearbeiten',
  ICEBREAKER_SETTINGS: 'Icebreaker Einstellungen',
  ICEBREAKER_ADD_ATTACHMENT: 'hinzufügen',
  ICEBREAKER_SCROLL: 'Lese hier wie das Icebreaker System funktioniert',
  ICEBREAKER_PARAGRAPH_CALLTOACTION:
    'Füge diesem Icebreaker ein ansprechendes Bild oder Video an und erhöhe damit die Chance auf eine Antwort.',
  ICEBREAKER_HEADLINE_PICTURE: 'Bild Icebreaker',
  ICEBREAKER_DUPLICATE_EXPLANATION:
    'Ein Bild oder Video kann nur angehängt werden, wenn es noch keinem Icebreaker zugewiesen ist.',
  ICEBREAKER_PARAGRAPH_EXPLANATION_PICTURE:
    'Ein Bild spricht mehr als tausend Worte. Starte und versüße einen Icebreaker Dialog mit einem privatem Bild von Dir. Ermögliche dem Icebreaker System mit diesem Bild verschiedene andere Icebreaker zu einem noch interessanteren Einstiegsdialog zu verknüpfen. Damit gestaltest Du Deinen Erstkontakt zu Usern noch reizvoller! Icebreaker, welche Bilder enthalten und durch diese verknüpft werden, weisen die höchste Antwortrate auf.',
  ICEBREAKER_HEADLINE_FIRST_LOGIN: 'I. Erster Login eines Users',
  ICEBREAKER_PARAGRAPH_EXPLANATION_FIRST_LOGIN:
    'Wird als Begrüßung an User versandt, die sich nach ihrer Registrierung zum ersten Mal in das Portal einloggen.',
  ICEBREAKER_HEADLINE_RECURRENT_LOGIN: 'II. Weiterer Login eines Users',
  ICEBREAKER_PARAGRAPH_EXPLANATION_RECURRENT_LOGIN:
    'Eine Begrüßung, die an User versandt wird, welche zwar schon häufiger auf dem Portal eingeloggt waren, bisher aber noch keinen Kontakt mit Dir hatten.',
  ICEBREAKER_HEADLINE_KISS_SENT: 'III. Kuss gesendet',
  ICEBREAKER_PARAGRAPH_EXPLANATION_KISS_SENT:
    'Wenn Du es einmal nicht schaffst, zeitnah auf einen erhaltenen Kuss zu reagieren, wird dieser Icebreaker für Dich versandt. Der Text bezieht sich also inhaltlich darauf, dass Du einen Kuss erhalten hast.',
  ICEBREAKER_HEADLINE_PROFILE_VISIT: 'IV. Profil besucht',
  ICEBREAKER_PARAGRAPH_PROFILE_VISIT:
    'Wenn ein User Dein Profil besucht hat besteht die Chance, dass dieser Icebreaker versandt wird und den User auf den Profilbesuch anspricht.',
  ICEBREAKER_HEADLINE_FOLLOWUP: 'V. Unbeantwortete Icebreaker nachfassen',
  ICEBREAKER_PARAGRAPH_EXPLANATION_FOLLOWUP:
    'Wenn der User auf Deinen “Erster Login” oder “Profil Besuch” Icebreaker nicht antwortet, besteht eine Chance, dass dieser Icebreaker hier einige Minuten nach Deiner ersten Nachricht versandt wird.',
  ICEBREAKER_HEADLINE_USER_PICTURE_WANTED: 'VI. User Profilbild anfragen',
  ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PICTURE_WANTED:
    'Dieser Icebreaker spricht den User darauf an, dass er noch kein Profilbild hochgeladen hat. Sollte der User hierauf nicht antworten, besteht die Chance, dass ein dazugehöriger Folgetext versandt wird, der an die erste Nachricht anknüpft.',
  ICEBREAKER_HEADLINE_NEW_PROFILEIMAGE: 'VII. User Profilbild Upload',
  ICEBREAKER_PARAGRAPH_EXPLANATION_NEW_PROFILEIMAGE:
    'Dieser Icebreaker gibt dem User ein positives Feedback, wenn dieser ein Profilbild hochgeladen hat.',
  ICEBREAKER_HEADLINE_USER_PREFERENCES_WANTED: 'VIII. User Vorlieben anfragen',
  ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PREFERENCES_WANTED:
    'Der User hat noch keine Vorlieben in seinem Profil ausgewählt und dies wird als Aufhänger genutzt. Sollte der User hierauf nicht antworten, besteht auch hier die Chance, dass ein Folgetext versandt wird, der an die erste Nachricht anknüpft.',
  ICEBREAKER_HEADLINE_USER_PREFERENCES_MATCH: 'IX. User Vorlieben Match',
  ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PREFERENCES_MATCH:
    'Dieser Icebreaker wird versandt, sobald einzelne Vorlieben übereinstimmen. Du weißt vorher also nicht, bei welchen Vorlieben ihr die Überschneidungen habt und Du solltest im Profil des Users schnell nachschauen, bevor Du antwortest!',
  ICEBREAKER_HEADLINE_HON_MATCH: 'X. Matchmaker Treffer',
  ICEBREAKER_PARAGRAPH_EXPLANATION_HON_MATCH:
    'Der User hat Dein Profilbild positiv bewertet und Du scheinst ihm zu gefallen. Der Icebreaker geht davon aus, dass auch Du den User positiv bewertet hast und verwendet einen Treffer beim Matchmaking als Aufhänger.',
  ICEBREAKER_HEADLINE_HON_PICTURE_WANTED: 'XI. Matchmaker Voting',
  ICEBREAKER_PARAGRAPH_EXPLANATION_HON_PICTURE_WANTED:
    'Ähnlich wie beim Icebreaker X, hat der User Dein Bild positiv bewertet. In diesem Fall hat der User aber kein Profilbild. Da es seltsam wäre, wenn ohne Profilbild ein Matchmaking Treffer zustande kommt, bedankt sich dieser Icebreaker für das Voting und sendet anschließend einen Icebreaker Typ VI (User Profilbild anfragen) hinterher.',
  ICEBREAKER_HEADLINE_PROFILE_VISIT_LIVECAM: 'XII. Livecam Einladung',
  ICEBREAKER_PARAGRAPH_EXPLANATION_PROFILE_VISIT_LIVECAM:
    'Dieser Icebreaker lädt den User in Deine Camshow ein. Er wird versandt, wenn sich ein User Dein Profil anschaut, während Du eine Livecamshow laufen hast, es aber wieder verlässt, ohne Deine Camshow zu besuchen oder Dich anzuschreiben.',
  ICEBREAKER_HEADLINE_MEDIA_PURCHASE: 'XIII. Reaktion auf Contentkauf',
  ICEBREAKER_PARAGRAPH_EXPLANATION_MEDIA_PURCHASE:
    'Sollte ein User Content von Dir kaufen, bisher aber noch keinen Kontakt zu Dir gehabt haben, wird dieser Icebreaker versandt, um das offensichtliche Interesse des Users zu nutzen und einen Dialog zu beginnen.',
  ATTACHMENT_LABEL_NO_FILES: 'Kein Dateieanhang hochgeladen',
  ICEBREAKER_LOADING_ERROR:
    'Icebreaker konnten nicht geladen werden, bitte lade die Seite neu.',
  ICEBREAKER_INFO_HEADLINE: `Das Community Icebreakersystem <br /><span>Geschaffen um Dir dabei zu helfen, das Eis zum User zu brechen!</span>`,
  ICEBREAKER_INFO_TEXT_1: `<span class="bold">Funktionsweise des Icebreaker Systems</span><br />
    Unser Icebreaker System übernimmt für Dich automatisch mit Hilfe von intuitiv gesteuerten Nachrichten die Initiative und verhilft Dir sehr schnell zu zahlreichen neuen Kontakten und Dialogen mit Usern. Die verschiedenen Icebreaker Typen basieren auf einer smarten und lernenden Logik, welche aufeinander und auf die Verhaltensmuster der User reagieren und damit die Dialogbereitschaft der User, also Deine Verdienstmöglichkeiten, um ein Vielfaches erhöhen!`,
  ICEBREAKER_INFO_TEXT_2: `<span class="bold">Besonderheit der Performancetexte</span><br />
    Die intelligente Steuerung des Icebreakersystem garantiert die optimale und größte Bandbreite an möglichen Neukontakten. Die bestmögliche Konvertierung, sprich Antwortrate, garantieren unsere sogenannten Performance Icebreakertexte!`,
  ICEBREAKER_INFO_TEXT_3: `
    Jeder Icebreakertyp verfügt über einen eigenen Textpool, welcher hunderte Textvarianten beinhaltet. Alle Texte im Icebreakersystem sind einzigartig und wiederholen sich nicht! Die Besonderheit: Alle Texte werden täglich im Rahmen tausender Dialoge und Erstkontakte analysiert und dahingehend ausgewertet, welche von ihnen die höchsten Antwortraten erzielen. Diese bieten dann stets die höchsten Chancen, User zu einem Dialog zu animieren und damit die bestmögliche Performance für den jeweiligen Icebreakertypen.`,
  ICEBREAKER_INFO_TEXT_4: `
    Das intelligente Icebreakersystem und die Performance Icebreakertexte sichern Dir das Maximum an neuen Usern! 100% automatisch und absolut kostenfrei!`,
  ICEBREAKER_INFO_TEXT_5: `<span class="bold">Bilder und Videos hinzufügen – Chancen erhöhen</span><br />
    Um die bestmögliche Performance zu erzielen, empfehlen wir Dir, zu jedem Icebreakertypen ein individuelles Bild oder ein kurzes Video hinzuzufügen. Damit steigerst Du die hohe Antwortrate der Performance Icebreaktertexte noch einmal deutlich.`,
  ICEBREAKER_INACTIVE: 'inaktiv',
  ICEBREAKER_ACTIVE: 'aktiv',
};

