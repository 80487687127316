import './Notification.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { ReactSVG } from 'react-svg';

import deleteIcon from '../../assets/img/svg/close.svg';
import pinIcon from '../../assets/img/svg/pin.svg';
import unpinIcon from '../../assets/img/svg/unpin.svg';
import {
  NOTIFICATION_DEACTIVATION,
  NOTIFICATION_MAINTENANCE,
  NOTIFICATION_NOTE,
  NOTIFICATION_TIP,
  NOTIFICATION_WARNING,
} from '../../constants';

function Notification({
  title = '',
  content = '',
  type = '',
  id,
  subcontent,
  link,
  updateTips,
  updateDeletedTip,
  pinned,
  values = {},
  pinnable = true,
  closable = true,
}) {
  const history = useHistory();
  const intl = useIntl();

  const [, deleteTipRequest] = useAxios(
    {
      url: `/user/tips/${id}`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const [, pinTipRequest] = useAxios(
    {
      url: '/user/tips',
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  function deleteTip() {
    deleteTipRequest().then(() => {
      updateDeletedTip(id);
    });
  }

  function pinTip() {
    const payload = {
      tip: parseInt(id),
      pinned: !pinned,
    };
    pinTipRequest({ data: payload }).then((response) => {
      updateTips(response.data);
    });
  }

  const className = classNames('notification-item', {
    deactivation: type === NOTIFICATION_DEACTIVATION,
    maintenance: type === NOTIFICATION_MAINTENANCE,
    warning: type === NOTIFICATION_WARNING,
    note: type === NOTIFICATION_NOTE,
    tip: type === NOTIFICATION_TIP,
    browser: id === 'static_browser_not_allowed',
  });

  function redirectToLink() {
    history.push(link);
  }

  return (
    <div className={className}>
      <div className="title">
        <span
          dangerouslySetInnerHTML={{
            __html: id.includes('static')
              ? intl.formatMessage({ id: title })
              : title,
          }}
        />
      </div>
      <div className="content">
        {/* If there is a value then we need to split the formatMessage so we don't get an error */}
        {id.includes('static') ? (
          <span
            dangerouslySetInnerHTML={{
              __html: Object.keys(values).length
                ? intl.formatMessage({ id: content }, values)
                : intl.formatMessage({ id: content }),
            }}
          />
        ) : (
          <span dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </div>
      {subcontent && link && (
        <div className="subcontent" onClick={() => redirectToLink()}>
          {id.includes('static') ? (
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: subcontent }),
              }}
            />
          ) : (
            subcontent
          )}
        </div>
      )}
      <div className="badge">
        {<FormattedMessage id={type.toUpperCase()} />}
      </div>
      {!id.includes('static') && type !== NOTIFICATION_MAINTENANCE && (
        <>
          {pinnable && (
            <div className="pin" onClick={() => pinTip()}>
              <ReactSVG src={pinned ? unpinIcon : pinIcon} wrapper="span" />
            </div>
          )}
          {closable && (
            <div className="delete" onClick={() => deleteTip()}>
              <ReactSVG src={deleteIcon} wrapper="span" />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Notification;

