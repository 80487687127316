import { chooseCam } from '../';
import { CHANGE_MICROPHONE } from '../actions';

export default (value, label, choose = true) => {
  return (dispatch, getState) => {
    const {
      cam: {
        selectedCam: { value: camValue },
      },
    } = getState();

    dispatch({
      type: CHANGE_MICROPHONE,
      payload: {
        value: value === 'false' ? false : value,
        label,
      },
    });
    if (camValue !== '0' && choose) {
      return dispatch(chooseCam());
    }
  };
};
