import './NicknameChanger.scss';

import useAxios from 'axios-hooks';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '../../Button/Button';
import NicknameInputField from '../../Nickname/NicknameInputField';

function NicknameChanger() {
  const intl = useIntl();
  const [nicknameChanged, setNicknameChanged] = useState(false);
  const { values, errors, setFieldValue, setFieldTouched } = useFormikContext();

  const [
    { error: nicknameError, loading: isNicknameDataLoading },
    nicknameRequest,
  ] = useAxios(
    {
      url: '/user/account',
      method: 'PUT',
      data: {
        nickname: values.nickname,
        isSender: true,
      },
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (values.nickname !== '') {
      setNicknameChanged(true);
      setFieldTouched('nickname', true);
    }
  }, [values, setFieldTouched]);

  function setNicknameAlternative(item) {
    setNicknameChanged(true);
    setFieldTouched('nickname', true);
    setFieldValue('nickname', item);
  }

  async function changeNickname(item) {
    try {
      setNicknameChanged(false);
      await nicknameRequest();
      window.location.reload();
    } catch (error) {
      if (!error?.response?.data?.alternatives) {
        console.log(error);
      }
    }
  }

  return (
    <>
      <div className="overlay-wrapper small-overlay nickname-change">
        <div className="overlay-header">
          <h1 className="headline">
            {intl.formatMessage({ id: 'CHANGE_NICKNAME_TITLE' })}
          </h1>
        </div>
        <div className="overlay-content">
          <p className="text">
            {intl.formatMessage({ id: 'CHANGE_NICKNAME_TEXT' })}
          </p>
          <NicknameInputField
            label={'CHANGE_NICKNAME_NEW'}
            isNicknameDataLoading={isNicknameDataLoading}
            errors={errors.nickname}
            nicknameError={!nicknameChanged && nicknameError}
            changeNickname={setNicknameAlternative}
            onChange={(e) => {
              setFieldValue('nickname', e.target.value);
              setFieldTouched('nickname', true);
            }}
          />
          <div className="buttons">
            <Button
              type="button"
              disabled={
                (nicknameError?.response?.data['alternatives'] &&
                  !nicknameChanged) ||
                errors.nickname ||
                values.nickname === ''
              }
              label="CHANGE_NICKNAME_CHANGE"
              className={'btn-change'}
              onClick={() => {
                changeNickname();
              }}
            />
          </div>
        </div>
      </div>
      <div className="background" />
    </>
  );
}

export default NicknameChanger;

