export function spinnerRequest({ request, spinner, payload, timeout = 2000 }) {
  const timer = setTimeout(() => {
    spinner(true);
  }, timeout);
  return new Promise((resolve, reject) => {
    request(payload ? payload : {})
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        clearTimeout(timer);
        spinner(false);
      });
  });
}
