import './StatusItem.scss';

import classNames from 'classnames';
import React from 'react';
import { ReactSVG } from 'react-svg';

import rejectedIcon from './../../../../assets/img/svg/cancel.svg';
import confirmedIcon from './../../../../assets/img/svg/check-circle-outline.svg';
import pendingIcon from './../../../../assets/img/svg/check-circle-outline.svg';

export const statusInfoMap = new Map([
  ['rejected', { icon: rejectedIcon }],
  ['pending', { icon: pendingIcon }],
  ['confirmed', { icon: confirmedIcon }],
  ['unconfirmed', { icon: pendingIcon }],
]);

function StatusItem({ data, status = '', style }) {
  const tableItem = classNames('status-item item-content', {
    'rejected-item': status === 'rejected',
    'pending-item': status === 'pending' || status === 'unconfirmed',
    'confirmed-item': status === 'confirmed',
  });

  const iconItem = classNames('icon-item');

  return status ? (
    <div className={tableItem} style={style}>
      <ReactSVG
        src={statusInfoMap.get(status).icon}
        className={iconItem}
        wrapper="span"
      />
      {data}
    </div>
  ) : null;
}

export default StatusItem;

