import './ProfileImageAndCam.scss';

import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import statusIcon from '../../../../assets/img/svg/on-offline.svg';
import Cam2Cam from '../../Cam/Cam2Cam';
import UserProfileImage from '../UserProfileImage';

function ProfileImageAndCam({
  running,
  nickname,
  whitelabelLong,
  isMobile,
  is_support,
  status,
  zoom,
}) {
  const statusClasses = classNames('user-status', status);
  const wrapperClasses = classNames('profile-image-and-cam2cam-wrapper', {
    large: zoom,
    small: !zoom,
  });
  return (
    <div className={wrapperClasses}>
      {status && (
        <div className={statusClasses}>
          {!is_support ? (
            <>
              <ReactSVG src={statusIcon} wrapper="span" />
              <span className="text">{status}</span>
            </>
          ) : null}
        </div>
      )}
      {running && !isMobile ? (
        <>
          {
            <div className="cam2cam">
              <Cam2Cam />
            </div>
          }
          <div className="user-nickname">
            {nickname}
            {whitelabelLong && (
              <span className="user-whitelabel">{whitelabelLong}</span>
            )}
          </div>
        </>
      ) : (
        <UserProfileImage />
      )}
    </div>
  );
}

export default ProfileImageAndCam;

