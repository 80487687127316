import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import SpinnerPage from '../../Spinner/SpinnerPage';

function TokenLogin({ userTokenLogin, grantType }) {
  const location = useLocation();
  const history = useHistory();
  const pathname = location.pathname;

  useEffect(() => {
    const pathItems = pathname.split('/');
    if (pathItems[2]?.length && pathItems[3]?.length) {
      userTokenLogin(pathItems[2], pathItems[3], grantType, history);
    } else {
      history.push('/');
    }
  }, [userTokenLogin, pathname, grantType, history]);

  return <SpinnerPage />;
}

export default TokenLogin;

