export default {
  RANKINGS_HEADLINE: `Rankings`,
  RANKINGS_INTRO: `rankings give you an insight into your performance on our site. This way you can quickly and easily find out how well your profile picture or your behavior in the messenger is received by customers. This allows you to work specifically on the areas in which you want to make improvements`,
  RANKINGS_INTRO_1: `The rankings are displayed in the range from -5 to +5. If you get an "n/a" displayed in one of the areas, we currently have too little information on the display of a value to be able to make a well-founded statement here.`,

  RANKINGS_HEADLINE_1: `The areas in detail:`,
  RANKINGS_CONTENT_STEP_1: `Profile image`,
  RANKINGS_CONTENT_STEP_1_CONTENT: `This value shows how well your chosen profile picture is received by the customer. You can choose in your profile which of your pictures should be shown on your profile and thus on the customers by default. The higher the value, the more appealing your customers will find your image. If you haven't uploaded a new profile picture for a long time, you can upload a new one immediately. Your customers will certainly be happy about a little variety, but be careful: too frequent changes can also have a negative effect.`,
  RANKINGS_CONTENT_STEP_2: `Icebreaker`,
  RANKINGS_CONTENT_STEP_2_CONTENT: `This value does not show you how many icebreakers are sent for you, but how many customers react to the icebreaker and reply to you. Here it is important to know that your profile picture and any file attachments play a major role in the answer and thus in the evaluation. The more customers appeal to your profile and your pictures, the higher the probability that they will write you on the Icebreaker.`,
  RANKINGS_CONTENT_STEP_3: `Online time`,
  RANKINGS_CONTENT_STEP_3_CONTENT: `This value is always determined from an average of the last few days. This means that as soon as you are online regularly and longer, your value will increase. If you are not online for several days in a row, this value will go down. If you now think that you will simply be present for an extremely long time the next day and the value will improve immediately as a result, we have to disappoint you. We want you to have a certain regularity in your online time, because this is the only way you can retain your customers in the long term. Important to know: Icebreakers are only sent while you are online.`,
  RANKINGS_CONTENT_STEP_4: `Chat Quality`,
  RANKINGS_CONTENT_STEP_4_CONTENT: `This value shows how well you can bind the customers - who get in contact with you - to you in a longer chat. A low rate is usually due to poor writing style, unfriendliness, empty promises regarding meetings or "rip-off attempts".`,
  RANKINGS_CONTENT_STEP_5: `Response rate`,
  RANKINGS_CONTENT_STEP_5_CONTENT: `This value shows how well you respond to your customers. It is always important to keep an eye on the "unread" and "unanswered" menu items. You should answer these messages quickly, after all, a customer wants to write to you and pays for it.`,
  RANKINGS_CONTENT_STEP_6: `Response time`,
  RANKINGS_CONTENT_STEP_6_CONTENT: `This value shows how well and quickly you respond to messages from your customers. Only if you remain in active communication, you can bind the customer to you and make him your regular customer.`,
  RANKINGS_CONTENT_STEP_7: `Premium attachments`,
  RANKINGS_CONTENT_STEP_7_CONTENT: `This value (quota) is shown to you as soon as you send paid attachments to customers. The value shows you as a percentage how good the sales rate of your premium file attachments is. Keep in mind that this feature isn't designed to send unsolicited pictures to your customers in hopes of increasing earnings. Use this function to sell your customer a suitable picture in a conversation. If your quota falls below 50% at the end of the month, this function will be automatically deactivated for you at the beginning of the new month. To reactivate them, you should go to one of the coaching sessions or to the open help room, to learn something about how sending premium file attachments will work better in the future.`,

  RANKINGS_OUTRO: `If you don't see the "My Performance" section on your dashboard yet, it's because the function is activated automatically as soon as we see a good chat ranking in your profile. As soon as the function has been activated, you will see a yellow note on the right-hand side of your dashboard.`,
};
