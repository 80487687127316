import './Payouts.scss';

import useAxios from 'axios-hooks';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PAGINATION_PAGE_SIZE } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useTranslateTableTitles from '../../../../hooks/useTranslateTableTitles';
import Pagination from '../../../Pagination/Pagination';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import PayoutsTable from '../../../Tables/PayoutsTable';
import {
  fullPayoutsTableTitles,
  mediumPayoutsTableTitles,
  mobilePayoutsTableTitles,
} from './Payouts.functions';

function Payouts({ isSubmenuShown }) {
  const intl = useIntl();
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const isMediumScreen =
    useMediaQuery('(min-width: 1024px) and (max-width: 1599px)') &&
    isSubmenuShown;
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const titles = isMobileScreen
    ? mobilePayoutsTableTitles
    : isMediumScreen
    ? mediumPayoutsTableTitles
    : fullPayoutsTableTitles;

  const translatedTitles = useTranslateTableTitles(titles);

  const [{ data: payoutsData, loading: payoutsDataLoading }] = useAxios({
    url: `/user/bills/payouts?l=${PAGINATION_PAGE_SIZE}&o=${offset}`,
  });

  return (
    <div className="payouts-wrapper">
      <div className="table-box">
        {payoutsDataLoading ? (
          <SpinnerComponent />
        ) : (
          <>
            <h1 className="headline">
              <FormattedMessage id="PAYOUTS_PAYOUTS" />
            </h1>
            {payoutsData ? (
              <div className="payouts-table">
                <PayoutsTable
                  items={payoutsData.payouts}
                  precision={2}
                  translatedTitles={translatedTitles}
                  isMobileScreen={isMobileScreen}
                />
              </div>
            ) : (
              <>{intl.formatMessage({ id: 'NO_DATA' })}</>
            )}
          </>
        )}
      </div>
      {!payoutsDataLoading && payoutsData && (
        <Pagination
          offset={offset}
          totalItems={payoutsData?.number}
          pageNumber={pageNumber}
          setOffset={setOffset}
          setPageNumber={setPageNumber}
        />
      )}
    </div>
  );
}

export default Payouts;
