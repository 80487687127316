import { connect } from 'react-redux';

import { userUpdateDocuments as updateDocuments } from '../../../../../../actions';
import FileApi from './FileApi';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateDocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(FileApi);
