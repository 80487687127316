export default {
  ROUTE_DASHBOARD_HEADLINE: `Die wichtigsten Daten auf einen Blick`,
  DASHBOARD_BANNER_HEADLINE: `Willkommen`,
  DASHBOARD_BANNER_TEXT: `Hier geht’s zum Multi Messenger. Behalte die Übersicht und manage alle Deine Chats, Kunden und Cam-Shows.`,
  DASHBOARD_LINK_TITLE_1: `Stelle Deine Preise ein`,
  DASHBOARD_LINK_CONTENT_1: `Lege den Preis pro Minute in der Livecam fest und wie viel eine Nachricht Deine Kunden kosten soll.`,
  DASHBOARD_LINK_TITLE_2: `Passe Dein öffentliches Profil an`,
  DASHBOARD_LINK_CONTENT_2: `Lade neue Bilder hoch, erstelle Beschreibungen über Dich und füge mehr Informationen hinzu.`,
  DASHBOARD_LINK_TITLE_3: `Lade Dateianhänge hoch`,
  DASHBOARD_LINK_CONTENT_3: `Dateianhänge kannst Du direkt im Messenger verschicken und zu Deinen Icebreaker hinzufügen.`,
  DASHBOARD_LINK_TITLE_4: `Erstelle Galerien mit Deinen Medien`,
  DASHBOARD_LINK_CONTENT_4: `Verwalte Deine Foto- und Video-Galerien und lese erhaltene Kommentare`,
  DASHBOARD_LINK_TITLE_5: `Bearbeite Deine Livecam Einstellungen`,
  DASHBOARD_LINK_CONTENT_5: `Passe Benachrichtigungstöne, Kurztexte und eine optimale Videoauflösung an Deine Bedürfnisse an.`,
  DASHBOARD_AFFILIATE_TITLE_1: `Werbelink für Creator`,
  DASHBOARD_AFFILIATE_CONTENT_1: `Lade eine Freundin zu LiveCreator ein mit <span class="bold">Deinem persönlichen Link</span>`,
  DASHBOARD_AFFILIATE_TITLE_2: `Werbelink für User`,
  DASHBOARD_AFFILIATE_CONTENT_2: `Werbe User und erhalte eine zusätzliche Provision von 25% mit <span class="bold">einem Deiner persönlichen Links</span>`,

  DASHBOARD_CURRENT_SALES: `Aktuelle Verkäufe`,
  DASHBOARD_CURRENT_CREDITS: `Aktuelles Guthaben`,
  DASHBOARD_TOTAL_TURNOVER: `Gesamtumsatz`,
  DASHBOARD_CREDITS: `Guthaben`,
  DASHBOARD_MY_PERFORMANCE: `Meine Leistung`,
  DASHBOARD_IMPORTANT_LINKS: `Wichtige Links`,
  DASHBOARD_AFFILIATE_LINKS: `Affiliate Programm`,
  DASHBOARD_OPEN_MESSENGER: `Öffne hier den Multi Messenger`,
  DASHBOARD_LIVECAM_NOT_ENABLED_TIP_TITLE: `Die Livecam ist für Dich nicht aktiviert`,
  DASHBOARD_LIVECAM_NOT_ENABLED_TIP_CONTENT: `Bitte kontaktiere den <a href="/support">Support</a>`,
  DASHBOARD_BROWSER_NOT_ALLOWED_TIP_TITLE:
    'Browser nicht erlaubt für die Livecam',
  DASHBOARD_BROWSER_NOT_ALLOWED_TIP_CONTENT: `Dein Browser wird für die Verwendung unserer Livecam-Software nicht unterstützt, bitte verwende einen der folgenden:
     {browsers}`,
  DASHBOARD_PROGRESS: `Fortschritt`,
  DASHBOARD_WEBINAR_JOIN_NOW: `Jetzt beitreten`,
  DASHBOARD_WEBINAR_BEGINNERS: `Für Anfänger und Fortgeschrittene`,
  DASHBOARD_WEBINAR_ONLINE: `ONLINE WEBINAR`,
  DASHBOARD_BILLING_SEE_DETAILS: 'mehr anzeigen',
};

