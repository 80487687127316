import './Section.scss';

import React from 'react';

function Section({ title, rightTitle, className, children, ...other }) {
  return (
    <div className={`content-section ${className || ''}`} {...other}>
      {(title || rightTitle) && (
        <div className="header">
          {title}
          {rightTitle && <span className="title-right">{rightTitle}</span>}
        </div>
      )}

      <div className="content">{children}</div>
    </div>
  );
}

export default Section;

