import './DateItem.scss';

import classNames from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

function DateItem({ data = '', user, dateFormat, ...props }) {
  const tableItem = classNames('date-item item-content');

  function generateDateFormat() {
    if (dateFormat) {
      return dayjs(data).format(dateFormat);
    }

    if (user.account.language === 'de') {
      return dayjs(data).format('DD.MM.YYYY');
    }

    return dayjs(data).format('MM/DD/YYYY');
  }

  return (
    <div className={tableItem} style={props.style}>
      {generateDateFormat()}
    </div>
  );
}

export default DateItem;

