import './../FieldErrorWrapper.scss';
import './StandaloneErrorWrapper.scss';

import classNames from 'classnames';
import React from 'react';

function StandaloneErrorWrapper({
  name,
  label,
  error,
  markAsRequired,
  showEmptySpan = true,
  noGrid,
  className,
  children,
  small,
  ...otherProps
}) {
  const containerClassName = classNames('field-error-wrapper', {
    [`${name}-wrapper`]: name,
    'has-error': error,
    'no-grid': noGrid,
    small,
    [className]: className,
  });

  const labelClassName = classNames({
    'field-label-required-mark-container': markAsRequired,
  });

  const errorClassName = classNames('error-text');

  return (
    <div className={containerClassName} {...otherProps}>
      {label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
          {markAsRequired && <sup>*</sup>}
        </label>
      )}

      {children}

      {/* Empty grid item */}
      {showEmptySpan && <span />}

      {error && <div className={errorClassName}>{error ? error : null}</div>}
    </div>
  );
}

export default StandaloneErrorWrapper;

