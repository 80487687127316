export default {
  CREATOR_REFERRAL_TITLE: `Werbe Creator und kassiere bis zu 12% Provision`,
  CREATOR_REFERRAL_CONTENT_1: `LiveCreator.com geht in die nächste Phase. Wir möchten uns bei Dir ganz herzlich bedanken, dass Du Teil unserer Community bist und Dir jetzt die Möglichkeit bieten, Deine Freundinnen, Bekannten oder andere Mädels für unser Portal zu begeistern und an ihren Umsätzen mitzuverdienen. Je erfolgreicher der von Dir geworbene Creator ist, desto mehr kannst Du verdienen, ganz ohne dafür selbst etwas zu tun.`,
  CREATOR_REFERRAL_CONTENT_2: `Ab dem 01. Juli 2023 kannst Du, abhängig vom Umsatz Deiner geworbenen Creator, <span class="bold">bis zu 12%</span> Werbe-Provision zusätzlich verdienen und das ganze <span class="bold">absolut stornofrei</span>!`,
  CREATOR_REFERRAL_CONTENT_3: `Wenn Du eine Freundin als Creator einladen möchtest, sende ihr den folgenden Link per E-Mail oder Messenger. Bitte achte darauf, dass der Link vollständig ist.`,
  CREATOR_REFERRAL_LINK: `Dein persönlicher Link`,
  CREATOR_REFERRAL_TITLE_1: `Das neue Modell für geworbene Creator im Überblick`,
  CREATOR_REFERRAL_CONTENT_4: `Der coinbasierte Provisionssatz steigt mit der Anzahl der erwirtschafteten Coins eines geworbenen Creators im entsprechenden Abrechnungszeitraum:`,
  CREATOR_REFERRAL_CONTENT_TABLE: `
    <table class="has-border noHover table300">
       <tr>
          <td>bis 24.999 Coins</td>
          <td class="align-right">5,0 %</td>
       </tr>
       <tr>
          <td>ab 25.000 Coins</td>
          <td class="align-right">7,5 %</td>
       </tr>
       <tr>
          <td>ab 70.000 Coins</td>
          <td class="align-right">10,0 %</td>
       </tr>
       <tr>
          <td>ab 100.000 Coins</td>
          <td class="align-right">12,0 %</td>
       </tr>
     </table>`,
  CREATOR_REFERRAL_CONTENT_5: `Es gibt im Monat zwei Abrechnungszeiträume, jeweils einen<br />
    <ul>
      <li>vom 1. bis zum 15. des Monats, dessen Abrechnung am 16. eines Monats erfolgt</li>
      <li>vom 16. bis zum Ultimo, dessen Abrechnung am 1. des Folgemonats erfolgt</li>
    </ul>`,
  CREATOR_REFERRAL_CONTENT_6: `In diesen wird neben Deiner eigenen Provision auch jene aus den Umsätzen Deiner geworbenen Creator abgerechnet und ausgezahlt.`,
  CREATOR_REFERRAL_CONTENT_7: `Wenn das kein Grund ist, jetzt noch aktiver Creator für LiveCreator.com zu werben?!`,
  CREATOR_REFERRAL_TITLE_2: `Hier noch unsere Top Tipps, um Creator zu werben`,
  CREATOR_REFERRAL_CONTENT_8: `
    <ul class='checklist'>
      <li>teile Deinen Link auf Deinen sozialen Medienprofilen wie Facebook, Twitter, Instagram, Pinterest oder TikTok. Du kannst den Link in Deiner Bio platzieren oder direkt in einem Beitrag posten</li>
      <li>platziere einen Textlink oder ein Banner auf Deiner eigenen Homepage und nutze den Link als Ziel</li>
      <li>oder erstelle eine eigene Website mit einem Homepage-Baukasten, auf der Du regelmäßig Beiträge veröffentlichen und Deinen Link bewerben kannst</li>
      <li>schalte Anzeigen auf Portalen wie Markt.de, Quoka oder in Online Kleinanzeigen, um eine größere Reichweite für Deinen Werbelink zu erzielen</li>
    </ul>`,
  CREATOR_REFERRAL_CONTENT_9: `Du siehst, es ist nicht viel Aufwand, diese Tipps umzusetzen – aber es bringt wirklich sehr, sehr viel.`,
  CREATOR_REFERRAL_CONTENT_10: `Gerne kannst Du persönlich mit unserem Support Kontakt aufnehmen, um weitere Möglichkeiten zur Creator-Werbung und Umsatzsteigerung zu besprechen. Beachte auch unsere Online-Webinare, die regelmäßig stattfinden.`,
};
