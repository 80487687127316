import { user as userUtils } from '../../../../utils';
import { management } from '../../../';
import { MANAGEMENT_EVENTS_CAM2CAM_DISABLE } from '../../../actions';

export default (data) => (dispatch, getState) => {
  dispatch({
    type: MANAGEMENT_EVENTS_CAM2CAM_DISABLE,
    payload: data,
  });
  const userId = data.userId;
  const user = userUtils.get(getState().camUser.camUsers, userId);
  if (user && user.cam2camActive) {
    dispatch(management.stopCam2Cam(userId));
  }
};
