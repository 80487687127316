import { connect } from 'react-redux';

import { deleteFriend, friendsError, spinner } from '../../../../../actions';
import ActiveFriends from './ActiveFriends';

const mapStateToProps = (state) => ({
  dialog: state.dialog.dialogs,
  askDeleteFriendConfirmed: state.friends.askDeleteFriendConfirmed,
  askDeleteFriendConfirmedAlert: state.friends.askDeleteFriendConfirmedAlert,
  askDeleteFriendId: state.friends.askDeleteFriendId,
});

const mapDispatchToProps = {
  spinner,
  deleteFriend,
  friendsError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveFriends);
