import { MANAGEMENT_LOGGED_OUT } from '../../constants';
import { NO_ORIGIN } from '../../errors';
import {
  objectUtils as _,
  roomType,
  sender as senderUtils,
  uuid,
} from '../../utils';
import { ORIGIN_TOGGLE } from '../actions';
import { management } from '..';

export default (toggleLogin, switchOrigins = true) => (dispatch, getState) => {
  const {
    sender: originalSenderState,
    streaming: { managementStep },
  } = getState();
  const sender = _.clone(originalSenderState);
  let newLogins;

  if (
    senderUtils.activatedOriginsCount(sender) === 1 &&
    toggleLogin.activated
  ) {
    return dispatch({
      type: ORIGIN_TOGGLE,
      error: true,
      meta: {
        stamp: uuid(),
      },
      payload: new Error(NO_ORIGIN),
    });
  }
  if (roomType.isExclusive(sender.roomTypes)) {
    newLogins = senderUtils.onlyActivateOrigin(
      sender.logins,
      toggleLogin.origin
    );
  } else {
    newLogins = senderUtils.toggleLogin(sender, toggleLogin);
  }
  if (managementStep === MANAGEMENT_LOGGED_OUT || !switchOrigins) {
    dispatch({
      type: ORIGIN_TOGGLE,
      payload: newLogins,
    });
  }
  if (switchOrigins) {
    dispatch(management.switchOrigins(newLogins, sender.roomTypes));
  }
};
