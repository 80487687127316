import { connect } from 'react-redux';

import { toggleUsernoteView } from '../../../../../../actions';
import Usernote from './Usernote';

const mapStateToProps = (state) => ({
  ...state.view,
});

const mapDispatchToProps = {
  toggleUsernoteView,
};

export default connect(mapStateToProps, mapDispatchToProps)(Usernote);
