import { connect } from 'react-redux';

import { user as userUtils } from '../../../../utils';
import ProfileImageAndCam from './ProfileImageAndCam';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    cam2cam,
    view: { isMobile },
  } = state;
  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
  const streams = cam2cam;
  const stream =
    activeUser?.userId && streams[activeUser?.userId]
      ? streams[activeUser?.userId]
      : null;
  const running = !!stream;
  const zoom = activeUser?.cam2camZoom;
  return {
    ...activeUser?.profile?.info,
    running,
    isMobile,
    status: activeUser?.flags?.includes('online') ? 'online' : 'offline',
    zoom,
    is_support:
      activeUser?.flags?.includes('is_support') ||
      userUtils.isAdmin(activeUser),
  };
};

export default connect(mapStateToProps)(ProfileImageAndCam);

