import './Onboarding.scss';

import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import Section from '../../Section/Section';
import { getValidationScheme } from './Onboarding.functions';
import OnboardingStepper from './OnboardingStepper';
import ReviewHeadline from './ReviewHeadline';

function Onboarding({
  initialValues = {},
  user = {},
  isMobile,
  isMedium,
  isMediumLarge,
}) {
  const history = useHistory();
  const intl = useIntl();

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationScheme(intl)}
      >
        <Section
          id="title-section"
          className="onboarding"
          title={
            isMedium || isMediumLarge
              ? intl.formatMessage({ id: 'ONBOARDING_TITLE' })
              : intl.formatMessage({ id: 'FIRST_STEPS_TITLE' })
          }
        >
          {(history.location.pathname === '/onboarding/profile-details' ||
            user.account.state === 'pending' ||
            user.account.state === 'review_needed') &&
            !isMobile && (
              <div className="intro-text">
                {!isMedium && !isMediumLarge && (
                  <h1 className="headline">
                    {intl.formatMessage({ id: 'ONBOARDING_TITLE' })}
                  </h1>
                )}

                {user.account.state === 'pending' ? (
                  <>
                    <p>
                      {intl.formatMessage({
                        id: 'ONBOARDING_HEADLINE_PENDING_1',
                      })}
                    </p>
                    <p className="no-margin">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'ONBOARDING_HEADLINE_PENDING_2',
                          }),
                        }}
                      />
                    </p>
                  </>
                ) : user.account.state === 'review_needed' ? (
                  <ReviewHeadline user={user} intl={intl} />
                ) : (
                  <>
                    <p>
                      {intl.formatMessage({
                        id: 'ONBOARDING_HEADLINE_1',
                      })}
                    </p>
                    <p className="no-margin">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: intl.formatMessage({
                            id: 'ONBOARDING_HEADLINE_2',
                          }),
                        }}
                      />
                    </p>
                  </>
                )}
              </div>
            )}
          <OnboardingStepper />
        </Section>
      </Formik>
    </>
  );
}

export default Onboarding;
