import './LivecamSettings.scss';

import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import cancelIcon from './../../../../assets/img/svg/close.svg';
import { CHECKING } from '../../../../constants/bandwidth';
import BandwidthCheck from '../../../Livecam/BandwidthCheck';
import Cam from '../../../Livecam/Cam';
import FunctionKeys from '../../../Livecam/FunctionKeys';
import ShowSendernameCheckbox from '../../../Livecam/ShowSendernameCheckBox';
import SoundSelect from '../../../Livecam/SoundSelect';
import StreamingOptions from '../../../Livecam/StreamingOptions';
import UserColorCheckbox from '../../../Livecam/UserColorCheckbox';

function LivecamSettings({
  powersender,
  bandwidth,
  cam,
  abortCheckBandwidth,
  abortLoadingCam,
  chooseCam,
  isMobile,
  isMobileBrowser,
}) {
  const intl = useIntl();
  return (
    <div className="livecam-settings-wrapper">
      <div className="data-full">
        <div className="cam-preview group-container">
          {(bandwidth.check === CHECKING ||
            cam.checkingCam === CHECKING ||
            bandwidth.error) && (
            <div
              className="close-btn"
              onClick={() => {
                abortCheckBandwidth();
                abortLoadingCam();
                if (cam.selectedCam.value !== '0') {
                  chooseCam();
                }
              }}
            >
              <ReactSVG src={cancelIcon} wrapper="span" />
            </div>
          )}
          {cam.error.id ? (
            <div className="checking">
              {intl.formatMessage(
                {
                  id: cam.error.id,
                },
                { error: cam.error.message ? cam.error.message : '' }
              )}
            </div>
          ) : bandwidth.error || cam.bandwidthError ? (
            <div className="checking">
              {intl.formatMessage({ id: 'alert.bandwidthCheckError' })}
            </div>
          ) : bandwidth.check === CHECKING ? (
            <div className="checking">
              {intl.formatMessage(
                {
                  id: 'bandwidthCheck.check',
                },
                { suffix: `(${bandwidth.step} / ${bandwidth.length})` }
              )}
            </div>
          ) : cam.checkingCam === CHECKING ? (
            <div className="checking">
              {intl.formatMessage(
                {
                  id: 'alert.testCam',
                },
                {
                  suffix: `${cam.resolution} (${cam.step} / ${cam.length})`,
                }
              )}
            </div>
          ) : (
            <Cam showMotto={false} />
          )}
        </div>
      </div>
      <div className="data-columns">
        <div className="data-left">
          <StreamingOptions />
        </div>
        <div className="data-right">
          <BandwidthCheck />
        </div>
      </div>
      <div className="data-columns">
        <div className="data-left sound">
          <SoundSelect />
        </div>
        {!isMobile && powersender && (
          <div className="data-right settings">
            <div className="group-container">
              <h2 className="headline">
                <FormattedMessage id="MENU_ITEM_LIVECAM_OPTIONS" />
              </h2>
              <UserColorCheckbox />
              <ShowSendernameCheckbox />
            </div>
          </div>
        )}
      </div>
      {!isMobileBrowser && (
        <div className="data-full">
          <Formik
            initialValues={{
              f1: '',
              f2: '',
              f3: '',
              f4: '',
              f5: '',
              f6: '',
              f7: '',
              f8: '',
            }}
          >
            <FunctionKeys />
          </Formik>
        </div>
      )}
    </div>
  );
}

export default LivecamSettings;
