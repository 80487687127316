import { connect } from 'react-redux';

import {
  abortCheckBandwidth,
  abortLoadingCam,
  chooseCam,
} from '../../../../actions';
import LivecamSettings from './LivecamSettings';

const mapStateToProps = (state) => ({
  powersender: state.sender.feature.powersender,
  bandwidth: state.bandwidth,
  cam: state.cam,
  isMobile: state.view.isMobile,
  isMobileBrowser: state.browser.isMobile,
});

const mapDispatchToProps = (dispatch) => ({
  abortCheckBandwidth: () => dispatch(abortCheckBandwidth()),
  abortLoadingCam: () => dispatch(abortLoadingCam()),
  chooseCam: () => dispatch(chooseCam()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LivecamSettings);
