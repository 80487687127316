import { MESSAGE_TYPE_SYSTEM, MESSAGE_TYPE_USER } from '../constants';
import gameUtils from './gameUtils';
import { systemMessageId } from '.';

const livecamMessagePlaceholders = [
  {
    regex: /%u%/g,
    replaceFunction: (user) => user.name,
  },
  {
    regex: /%t%/g,
    replaceFunction: () => {
      const d = new Date();
      return d.getHours() + ':' + d.getMinutes();
    },
  },
];

const chatMessagePlaceholders = [
  {
    regex: /@amateurName/g,
    replaceFunction: (payload) => payload.nickname,
  },
  {
    regex: /@nickname/g,
    replaceFunction: (payload) => payload.user.name,
  },
];

export const livecamMessagePlaceholder = (message, user) => {
  let result = message.trim();
  livecamMessagePlaceholders.forEach((item) => {
    result = result.replace(item.regex, item.replaceFunction(user));
  });
  return result;
};

export const chatMessagePlaceholder = (message, user, nickname) => {
  let result = message.trim();
  chatMessagePlaceholders.forEach((item) => {
    result = result.replace(
      item.regex,
      item.replaceFunction({ user, nickname })
    );
  });
  return result;
};

export const createChatMessage = (data) =>
  Object.assign({}, data, {
    time: Date.now(),
  });

export const getSenderNameForUser = (user, logins) => {
  if (user.senderName) {
    return user.senderName;
  }

  let senderName = '';
  logins.forEach((login) => {
    if (login.origin === user.origin) {
      senderName = login.sendername;
    }
  });

  return senderName;
};

export const createIncomingMessagePayload = (message, state) => {
  const user = state.camUser.camUsers.find((singleUser) => {
    return singleUser.userId === message.userId;
  });
  if (!user || !user.visible) {
    return;
  }
  if (!user.active) {
    user.newMessage = true;
  }
  message.userColor = user.color;

  const senderName = getSenderNameForUser(user, state.sender.logins);
  message.type = MESSAGE_TYPE_USER;
  message.from = user.name;
  message.to = senderName;
  message.userType = user.type;
  return message;
};

export const addMessage = (state, message) => {
  let messages;
  let newState = { ...state };

  const messageIndex = newState.messages.findIndex(
    (msg) =>
      msg?.id && message?.payload?.tempId && msg.id === message.payload.tempId
  );

  if (messageIndex !== -1) {
    messages = [
      ...newState.messages.slice(0, messageIndex),
      message,
      ...newState.messages.slice(messageIndex + 1),
    ];
  } else {
    messages = [...newState.messages, message];
  }

  if (messages.length > state.maxMessages) {
    messages.shift();
  }
  return Object.assign({}, state, { messages });
};

const sortByTime = (a, b) => {
  if (a.time < b.time) return -1;
  if (a.time > b.time) return 1;
  return 0;
};
export const addDialogMessages = (state, messages) => {
  const filteredMessages = messages.filter(
    (m) => !state.dialogmessages.includes(m.id)
  );

  const dialogmessages = [...state.dialogmessages, ...filteredMessages].sort(
    sortByTime
  );
  const containsNonIcebreakerMessages =
    state.containsNonIcebreakerMessages ||
    messages.includes(
      (message) => message?.payload?.type !== 'match' && !message.payload?.ibid
    );

  return {
    ...state,
    containsNonIcebreakerMessages,
    dialogmessages,
  };
};

export const removeDialogMessage = (state, id) => {
  if (id && !state.dialogmessages.some((el) => el.id === id)) {
    return state;
  }

  const updatedMessages = state.dialogmessages.filter(
    (message) => message.id !== id
  );
  const dialogmessages = updatedMessages.sort(sortByTime);
  return {
    ...state,
    dialogmessages,
  };
};

export const failedDialogMessage = (state, id, payload) => {
  const messageIndex = state.dialogmessages.findIndex((el) => el.id === id);

  if (messageIndex === -1) {
    return state;
  }

  const updatedMessages = state.dialogmessages.map((message, index) => {
    if (index === messageIndex) {
      return {
        ...message,
        payload: {
          ...message.payload,
          resendPayload: payload,
          loading: false,
        },
      };
    }
    return message;
  });

  const sortedMessages = updatedMessages.sort(sortByTime);

  return {
    ...state,
    dialogmessages: sortedMessages,
  };
};

export const addDialogMessage = (state, message) => {
  if (
    (message.message === '' &&
      !message.payload &&
      message?.type === 'MESSAGE_TYPE_SENDER') ||
    (message.id && state.dialogmessages.find((el) => el.id === message.id))
  ) {
    return state;
  }
  let newState = { ...state };
  if (message?.payload?.resendId) {
    newState = removeDialogMessage(newState, message.payload.resendId);
  }

  let updatedMessages;

  const messageIndex = newState.dialogmessages.findIndex(
    (msg) => msg.id === message?.payload?.tempId
  );

  if (messageIndex !== -1) {
    updatedMessages = [
      ...newState.dialogmessages.slice(0, messageIndex),
      message,
      ...newState.dialogmessages.slice(messageIndex + 1),
    ];
  } else {
    updatedMessages = [...newState.dialogmessages, message];
  }

  const dialogmessages = updatedMessages.sort(sortByTime);
  const containsNonIcebreakerMessages =
    newState.containsNonIcebreakerMessages ||
    (message?.payload?.type !== 'match' && !message.payload?.ibid);

  return {
    ...newState,
    containsNonIcebreakerMessages,
    dialogmessages,
  };
};
export const createSystemDialogMessage = (message) => ({
  id: message.id,
  type: MESSAGE_TYPE_SYSTEM,
  values: message.values,
  time: message.time,
  from: '',
  to: '',
  translate: true,
  userId: message.userId,
});
export const createSystemMessage = (chat, message) => ({
  id: message.id,
  type: MESSAGE_TYPE_SYSTEM,
  values: message.values,
  time: Date.now(),
  from: 'System',
  to: '',
  translate: true,
  highlight: message.highlight || undefined,
  userId: message.userId || 'system',
  cssclass: message.css || '',
});

export const createGameMessage = (game, meta) => {
  if (!game || gameUtils.isActive(game) || !meta?.userId) return;
  const { from, to, userId } = meta;
  let type;
  if (!gameUtils.hasSession(game)) {
    if (game.status === 'timedOut') {
      type = 'invitationTimeout';
    } else if (game.status === 'declined') {
      type = 'invitationDeclined';
    }
  } else {
    const { decision, decisionReason, winnerId } = game.session;
    const isWinner = winnerId?.replace('-', '@') !== game.partner;
    if (decision === 'draw') {
      type = 'draw';
    } else if (decision === 'winner') {
      if (decisionReason === 'timeout') {
        type = isWinner ? 'winByTimeout' : 'lostByTimeout';
      } else if (decisionReason === 'turn') {
        type = isWinner ? 'winByTurn' : 'lostByTurn';
      }
    }
  }
  if (!type) return;
  return {
    id: systemMessageId(),
    type: 'MESSAGE_TYPE_SENDER',
    recipient: game.partner,
    time: gameUtils.lastUpdatedTimestamp(game),
    body: '',
    from,
    to,
    attachment: null,
    payload: { type: `game.${type}` },
    sender: game.toId.replace('-', '@'),
    userId,
  };
};

export const getLastMessage = (messages) =>
  messages.length > 0 ? messages[messages.length - 1] : {};
export const getFirstMessage = (messages) =>
  messages.length > 0 ? messages[0] : {};
