import { connect } from 'react-redux';

import Photos from './Photos';

const defaultsObj = {
  media: '',

  title: '',
  description: '',
  categories: '',

  created: '',
  published: false,
  price: '',
  thumbnail: '',
  status: '',
  pictures: [],
};

function getInitialValues(reduxObj) {
  let returnObj = {};
  let reduxValues = { ...reduxObj };

  for (const k in defaultsObj) {
    returnObj[k] = reduxValues[k] || defaultsObj[k];
  }
  returnObj.description = '';
  if (reduxObj?.description?.content !== undefined) {
    returnObj.description = reduxObj.description.content;
  }

  returnObj.title = '';
  if (reduxObj?.title?.content !== undefined) {
    returnObj.title = reduxObj.title.content;
  }

  return returnObj;
}

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state.galleries.active),
});

export default connect(mapStateToProps)(Photos);

