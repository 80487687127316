import './PayoutsTable.scss';

import classNames from 'classnames';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import arrowDownIcon from '../../../assets/img/svg/arrow-down.svg';
import pdfIcon from '../../../assets/img/svg/pdf.svg';
import DateItem from '../TableItems/DateItem';
import LinkItem from '../TableItems/LinkItem/LinkItem';
import NumberItem from '../TableItems/NumberItem';
import InlinePayoutsTable from './InlinePayoutsTable/InlinePayoutsTable';
import PdfApi from './PdfApi/PdfApi';

function PayoutsTable({
  items = [],
  precision = 0,
  language = 'en',
  translatedTitles,
  isMobileScreen,
}) {
  const intl = useIntl();
  const [showBillsTable, setShowBillsTable] = useState(new Array(items.length));

  function toggleBillsTables(index) {
    const newState = new Array(items.length).fill(false);
    const visibility = showBillsTable[index];
    newState[index] = !visibility;
    setShowBillsTable(newState);
  }

  return (
    <>
      <table>
        <thead>
          <tr>
            {translatedTitles.map((title, index) => {
              return (
                <th
                  style={{ width: title.width, textAlign: title.position }}
                  key={`${title}.${index}`}
                >
                  {title.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            const arrowClass = classNames('arrow-wrapper', {
              'arrow-rotated': showBillsTable[index],
            });
            return (
              <tr key={`${item.key}.${index}`} className="payouts-table-row">
                <td className="text-left">
                  <div className="pdf-id-div">
                    {item.payoutId}
                    <PdfApi pdfUrl={item.pdf}>
                      <div className="pdf-icon-div">
                        <ReactSVG src={pdfIcon} wrapper="span" />
                      </div>
                    </PdfApi>
                  </div>
                </td>
                <td className="text-left">
                  <span className="payouts-date-item-span">
                    <DateItem data={item.date} />
                  </span>
                </td>
                <td>
                  {!isMobileScreen && (
                    <span
                      onClick={() => toggleBillsTables(index)}
                      className="payouts-table-toggle-item"
                    >
                      <span className={arrowClass}>
                        <ReactSVG wrapper="span" src={arrowDownIcon} />
                      </span>
                      <LinkItem
                        data={`${intl.formatNumber(item.bill_number)} 
                      ${intl.formatMessage({
                        id: 'PAYOUTS_TABLE_BILL',
                      })}`}
                      />
                    </span>
                  )}
                  {showBillsTable[index] && (
                    <div className="child-table-div">
                      <InlinePayoutsTable
                        items={item.bills}
                        language={language}
                        precision={precision}
                        currency={item.currency}
                      />
                    </div>
                  )}
                </td>
                <td className="text-right">
                  <NumberItem
                    number={item.amount}
                    precision={precision}
                    currency={item.currency}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default PayoutsTable;

