import {
  EXCLUSIVE_ASK,
  EXCLUSIVE_ASK_SENDER,
  EXCLUSIVE_ASK_TICK,
  SHOW_TYPE_EXPIRED,
  UPGRADE_START,
} from '../../actions/actions';
import {
  ALERT_LEVEL_MARK,
  ALERT_LEVEL_NONE,
  ALERT_TYPE_EXCLUSIVE,
  ALERT_TYPE_EXCLUSIVE_SENDER,
  EXCLUSIVE_MAX_TICK,
  SHOW_TYPE_EXCLUSIVE,
} from '../../constants';
import { alert as alertUtils, user as userUtils } from '../../utils';

const initialState = [];

const reduce = (state = initialState, action) => {
  switch (action.type) {
    // room/showtype actions
    case EXCLUSIVE_ASK:
      if (action.meta.start) {
        let upgrade = userUtils.findExclusiveUpgrade(action.payload);
        const pos = alertUtils.createExclusivePosition(state);
        return [
          ...state,
          {
            tick: EXCLUSIVE_MAX_TICK,
            stamp: action.meta.stamp,
            type: ALERT_TYPE_EXCLUSIVE,
            level: ALERT_LEVEL_NONE,
            username: action.payload.name,
            userId: action.payload.userId,
            price: upgrade.price,
            expired: false,
            pos,
            scope: 'livecam',
          },
        ];
      }
      return state.filter((alert) => alert.stamp !== action.meta.stamp);
    case EXCLUSIVE_ASK_TICK:
      return state.map((alert) => {
        if (alert.stamp === action.meta.stamp) {
          alert.tick = action.payload;
        }
        return alert;
      });
    case SHOW_TYPE_EXPIRED:
      return state.map((alert) => {
        if (
          alert.userId === action.payload.userId &&
          action.payload.showType === SHOW_TYPE_EXCLUSIVE
        ) {
          alert.expired = true;
        }
        return alert;
      });
    case UPGRADE_START:
      if (action.payload.showType === SHOW_TYPE_EXCLUSIVE) {
        return state.filter(
          (alert) =>
            [ALERT_TYPE_EXCLUSIVE, ALERT_TYPE_EXCLUSIVE_SENDER].indexOf(
              alert.type
            ) < 0
        );
      }
      return state;
    case EXCLUSIVE_ASK_SENDER: {
      let upgrade = userUtils.findExclusiveUpgrade(action.payload);
      const pos = alertUtils.createExclusivePosition(state);
      return [
        ...state,
        {
          stamp: action.meta.stamp,
          type: ALERT_TYPE_EXCLUSIVE_SENDER,
          level: ALERT_LEVEL_MARK,
          username: action.payload.name,
          userId: action.payload.userId,
          price: upgrade.price,
          expired: false,
          pos,
          scope: 'livecam',
        },
      ];
    }
    default:
      return state;
  }
};

export default reduce;
