import { EXCLUSIVE_ASK } from '../actions';

export default (user, stamp, start) => ({
  type: EXCLUSIVE_ASK,
  payload: user,
  meta: {
    stamp,
    start,
  },
});
