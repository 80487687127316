import change from './change';
import fetchData from './fetchData';
import filter from './filter';
import closeAllMenues from './menues/closeAll';
import messagesFilter from './messagesFilter';
import select from './select';
import selectend from './selectend';
import selectfirst from './selectfirst';
import selectlast from './selectlast';
import selectnext from './selectnext';
export default {
  select,
  selectnext,
  selectlast,
  filter,
  selectfirst,
  selectend,
  messagesFilter,
  fetchData,
  change,
  closeAllMenues,
};
