import {
  ACTIVITY_TYPE_CONTENT_PURCHASE,
  ACTIVITY_TYPE_CUSTOMER_LOGIN,
  ACTIVITY_TYPE_FRIEND_LOGIN,
  ACTIVITY_TYPE_FRIEND_REQUEST,
  ACTIVITY_TYPE_PROFILE_VISIT,
} from '../../../constants';

export default {
  [ACTIVITY_TYPE_PROFILE_VISIT]: `Unser Icebreakersystem wird versuchen, den User auf Dich aufmerksam zu machen.
    Sobald der User Interesse zeigt und Dir eine Nachricht schreibt, übernimmst Du den weiteren Kontakt.`,
  ACTIVITY_TYPE_PROFILE_VISIT_NON_ICEBREAKER: `Sobald der User Interesse zeigt und Dir eine Nachricht schreibt, übernimmst Du den weiteren Kontakt.`,
  [ACTIVITY_TYPE_CUSTOMER_LOGIN]: `Der User hat schon Content von Dir gekauft oder war in Deiner Webcam.
    Schreibe ihn direkt an und nutze die Chance ihn erneut von Dir zu begeistern.`,
  [ACTIVITY_TYPE_FRIEND_LOGIN]: `Du bist mit dem User befreundet. Schreibe ihn direkt an und nutze die Chance ihn kennen zu lernen.`,
  [ACTIVITY_TYPE_FRIEND_REQUEST]: `Der User hat Dir eine Freundschaftsanfrage gesendet. Eine Freundschaft entsteht aus einem aktiven Kontakt miteinander.
    Schreibe ihn direkt an und nutze die Chance ihn kennen zu lernen.`,
  [ACTIVITY_TYPE_CONTENT_PURCHASE]: `Der User hat Content von Dir gekauft. Schreibe ihn direkt an und nutze die Chance ihn kennen zu lernen.`,
};
