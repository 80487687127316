import { connect } from 'react-redux';

import { openOverlay, spinner } from '../../../../actions';
import { user as userUtils } from '../../../../utils';
import UserProfileImage from './UserProfileImage';

const mapStateToProps = (state) => {
  const activeUser = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);
  if (activeUser?.profile?.info) {
    return {
      ...activeUser?.profile?.info,
      safeMode: state.preferences?.safeMode,
      profilePictureFsk: activeUser?.profile?.info?.profile_image_fsk,
      is_support: activeUser?.flags?.includes('is_support'),
    };
  }
  if (activeUser?.name && activeUser?.portalName) {
    return {
      nickname: activeUser.name,
      whitelabelLong: activeUser.portalName,
      is_support: false,
      profilePictureFsk: 'fsk12',
      safeMode: state.preferences?.safeMode,
    };
  }
  if (userUtils.isAdmin(activeUser)) {
    return {
      nickname: activeUser.name,
      is_support: true,
    };
  }
  return {};
};

export default connect(mapStateToProps, { openOverlay, spinner })(
  UserProfileImage
);

