import { connect } from 'react-redux';

import { isLivecamCreator } from '../../../../../utils';
import LivecamFaq from './LivecamFaq';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  isLivecamCreator: isLivecamCreator(state.user),
  nickname: state.user?.account?.nickname,
});

export default connect(mapStateToProps)(LivecamFaq);

