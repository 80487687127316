import { STREAMING_SERVER_TYPE } from '../../../../constants';
import { user as userUtils } from '../../../../utils';
import { useraudio } from '../../..';

export default (data) => {
  return (dispatch, getState) => {
    const {
      camUser: { camUsers },
    } = getState();
    const userId = data.userId;
    const user = userUtils.get(camUsers, userId);
    if (!user || !user.visible) {
      return;
    }
    const {
      sdpUrl,
      application: applicationName,
      simpleObject,
    } = getState().streaming.camLoginData;
    dispatch(
      useraudio.start(userId, {
        sdpUrl,
        applicationName,
        streamName: `${userId}_audio?type=${STREAMING_SERVER_TYPE}&id=${simpleObject.key}`,
      })
    );
  };
};
