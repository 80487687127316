import md5 from 'crypto-js/md5';

export { default as stream } from './stream';
export { default as user } from './user';
export { default as sender } from './sender';
export { default as arrayUtils } from './arrayUtils';
export { default as objectUtils } from './objectUtils';
export { default as defaultIntl } from './locale';
export { default as roomType } from './roomType';
export { default as alert } from './alert';
export { default as upgradetimer } from './upgradetimer';
export { default as storage } from './storage';
export { default as gameUtils } from './gameUtils';
export { default as messageUtils } from './messageUtils';
export { default as contestUtils } from './contestUtils';

let number = 0;
let uuids = [];
let systemMessageNumber = 0;

export const emptyFunction = () => {};

export const uuid = () => {
  let uuid = '_' + ++number;
  uuids.push(uuid);
  return uuid;
};
export const lastUuid = () => {
  return uuids.length > 0 ? uuids.slice(-1).pop() : undefined;
};

export const clearSystemMessageId = () => {
  systemMessageNumber = 0;
};

export const systemMessageId = () => {
  let systemMessageId = (systemMessageNumber -= 0.0001);
  return systemMessageId;
};

export const hashAlert = (alert) => {
  const message =
    typeof alert.message === 'string' ? alert.message : alert.message?.id;
  return '' + md5(alert.type + alert.level + JSON.stringify(message));
};

export const timeout = (time, callback) => {
  let called = false;

  let interval = setTimeout(function () {
    if (called) {
      return;
    }
    called = true;
    callback(new Error('timeout'));
  }, time);

  return (data) => {
    if (called) {
      return;
    }
    called = true;
    clearTimeout(interval);
    callback(null, data);
  };
};

export const randomString = (length) => {
  let result = '';
  const low = 65,
    high = 90;
  for (let i = 0; i < length; i++) {
    const charCode = Math.floor(Math.random() * (1 + high - low)) + low;
    result += String.fromCharCode(charCode);
  }
  return result;
};
export const getTimestamp = () => new Date().getTime();
export const getUnixTimestamp = (date) =>
  Math.floor(new Date(date).getTime() / 1000);

export const getUrlParams = () => {
  const params = {};
  const search = window.location.search;
  if (search.length <= 1) {
    return params;
  }
  search
    .substr(1)
    .split('&')
    .forEach((keyvaluepair) => {
      const pair = keyvaluepair.split('=');
      params[pair.shift()] = pair.pop();
    });
  return params;
};

export const removeUrlParam = (name) => {
  try {
    const url = new URL(window.location);
    url.searchParams.delete(name);
    window.history.replaceState(null, document.title, url);
  } catch (err) {
    console.log(err);
  }
};
export const removeUrlParams = () => {
  try {
    const href = window.location.href;
    const url = href.split('?')[0];
    window.history.replaceState(null, document.title, url);
  } catch (err) {
    console.log(err);
  }
};

export const ucfirst = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const getCookieHintData = (locale, third) => {
  const link_chrome = 'https://support.google.com/chrome/answer/95647';
  const link_firefox =
    locale === 'de'
      ? 'https://support.mozilla.org/de/kb/cookies-informationen-websites-auf-ihrem-computer'
      : 'https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer';
  const link_edge =
    locale === 'de'
      ? 'https://support.microsoft.com/de-de/windows/l%C3%B6schen-und-verwalten-von-cookies-168dab11-0753-043d-7c16-ede5947fc64d'
      : 'https://support.microsoft.com/en/help/17442/windows-internet-explorer-delete-manage-cookies';
  const firefox_android =
    locale === 'de'
      ? 'https://www.whatismybrowser.com/de/guides/how-to-enable-cookies/firefox-android'
      : 'https://www.whatismybrowser.com/guides/how-to-clear-cookies-browsing-history-and-cache/firefox-android';
  const firefox_iphone =
    locale === 'de'
      ? 'https://www.whatismybrowser.com/de/guides/how-to-enable-cookies/firefox-iphone'
      : 'https://www.whatismybrowser.com/guides/how-to-enable-cookies/firefox-iphone';
  const safari_mac =
    locale === 'de'
      ? 'https://support.apple.com/de-de/guide/safari/sfri11471/mac'
      : 'https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac';

  let links = {
    link_chrome,
    link_firefox,
    link_edge,
    firefox_android,
  };

  if (!third) {
    links = { ...links, firefox_iphone, safari_mac };
  }

  const cookieHintData = {
    headlineId: 'ERROR',
    id: third ? 'login.cookie3rdHint' : 'login.cookieHint',
    links,
    scope: 'general',
  };

  return cookieHintData;
};

export const isLivecamCreator = (user) =>
  user?.isLoggedIn &&
  user?.account?.state === 'confirmed' &&
  user?.account?.livecam === 'true';

export const createNotesServiceUrl = (user, sender) => {
  // at the moment we cannot change api urls to https on every environment
  // as the air sendersoftware goes nuts on staging
  // so we keep them on http in the database and rewrite it here
  // once the issu is solved remove this replace
  const baseUrl = sender.settings.serviceUrl.replace('http:', 'https:');

  return baseUrl + '/messenger_notes/session/' + user.sessionId;
};

export const shortenCount = (count, maxCount) =>
  count <= maxCount ? count : `${maxCount}+`;
