const stream = document.createElement('video');

const types = {
  h264: [
    'video/mp4; codecs="avc1.58A01E, mp4a.40.2"',
    'video/mp4; codecs="avc1.42c01f, mp4a.40.5"',
    'video/mp4; codecs="avc1.42c01e, mp4a.40.5"',
  ],
};
const test = (type) =>
  stream &&
  stream.canPlayType &&
  types[type] &&
  types[type].length &&
  types[type].some((elem) => stream.canPlayType(elem) !== '');

const canPlayH264 = () => test('h264');

const canH264WebRTC = () =>
  !(
    navigator.userAgent &&
    (/SamsungBrowser\/4/i.test(navigator.userAgent) ||
      /Edge/i.test(navigator.userAgent))
  ) &&
  canPlayH264() &&
  window.RTCPeerConnection &&
  window.WebSocket &&
  window.RTCSessionDescription &&
  window.RTCIceCandidate;

export default {
  canH264WebRTC,
};
