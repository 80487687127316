export function generatePriceList(min, max, step) {
  let pricesList = [];

  for (let i = min; i <= max; i += step) {
    pricesList.push({ value: i, label: i });
  }

  return pricesList;
}

