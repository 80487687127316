import './FieldErrorWrapper.scss';

import classNames from 'classnames';
import { useField } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function FieldErrorWrapper({
  name,
  label,
  error,
  markAsRequired,
  showEmptySpan = true,
  noGrid,
  className,
  children,
  reviewError,
  ...otherProps
}) {
  const [, meta] = useField(name);

  const hasError = meta.error && meta.touched;

  const containerClassName = classNames('field-error-wrapper', {
    [`${name}-wrapper`]: name,
    'has-error': hasError || error || reviewError,
    'no-grid': noGrid,
    [className]: className,
  });

  const labelClassName = classNames({
    'field-label-required-mark-container': markAsRequired,
  });

  const errorClassName = classNames('error-text');

  return (
    <div className={containerClassName} {...otherProps}>
      {label && (
        <label htmlFor={name} className={labelClassName}>
          <FormattedMessage id={label} />
          {markAsRequired && <sup>*</sup>}
        </label>
      )}

      {children}

      {/* Empty grid item */}
      {showEmptySpan && <span />}

      {(error || hasError || reviewError) && (
        <div className={errorClassName}>
          {reviewError
            ? reviewError
            : error
            ? error
            : hasError
            ? meta.error
            : null}
        </div>
      )}
    </div>
  );
}

export default FieldErrorWrapper;

