import StatsContainer from './StatsContainer';

class VideoStatsContainer extends StatsContainer {
  calculateFps ({framesEncoded, timestamp}) {
    if (this.lastFramesEncoded === -1 || this.lastTimestamp === -1) {
      this.lastFramesEncoded = framesEncoded;
      return 0;
    }

    this.fps = (framesEncoded - this.lastFramesEncoded) * 1000 / (timestamp - this.lastTimestamp);

    this.lastFramesEncoded = framesEncoded;
  }

  add(stats) {
    this.calculateFps(stats);
    super.add(stats);
  }
  reset() {
    super.reset();
    this.lastFramesEncoded = -1;
  }
}

export default VideoStatsContainer;
