import api from '../../utils/api';
import { KPI_UPDATE } from '../actions';
export default () => async (dispatch) => {
  try {
    const response = await api.get('/user/kpis');
    dispatch({
      type: KPI_UPDATE,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: KPI_UPDATE,
      error,
    });
  }
};
