import { WebRTCPublisher } from '../../services';
import { default as changeBandwidth } from './bandwidth/change';
import timerCheck from './check/timer';
import { default as events } from './events';
import { default as initReconnect } from './init/reconnect';
import { default as lowFps } from './lowFps';
import { default as publish } from './publish';
import { default as reconnect } from './reconnect';
import { default as stop } from './stop';
import { default as checkStop } from './stop/check';
import { default as timerStop } from './stop/timer';

export default {
  events,
  publish: publish(WebRTCPublisher),
  stop: stop(WebRTCPublisher),
  timerStop,
  checkStop,
  changeBandwidth: changeBandwidth(WebRTCPublisher),
  timerCheck,
  reconnect,
  initReconnect: initReconnect(WebRTCPublisher),
  lowFps,
};
