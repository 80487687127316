import { lStorage } from '../../services/storage';
import { functionLog } from '../';
import { DIALOGS_SORT } from '../actions';
export default function (payload) {
  return (dispatch) => {
    lStorage.setItem('sorting', payload);
    dispatch(functionLog({ function: 'sorting', data: payload }));
    dispatch({ type: DIALOGS_SORT, payload });
  };
}

