import {
  ADD_ONLINE_STATUS,
  START_TIMER_ONLINE_STATUS,
  STOP_TIMER_ONLINE_STATUS,
} from '../actions/actions';

const initialState = {
  online: [],
  timerRunning: false,
};

const onlineStatus = (state = initialState, action) => {
  switch (action.type) {
    case START_TIMER_ONLINE_STATUS:
      return { ...state, timerRunning: true };
    case STOP_TIMER_ONLINE_STATUS:
      return { ...state, timerRunning: false };

    case ADD_ONLINE_STATUS:
      return { ...state, online: action.payload };
    default:
      return state;
  }
};

export default onlineStatus;

