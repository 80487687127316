import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  cookie,
  errorLogin,
  keydownWindows,
  userLogout as logout,
  openOverlay,
  resize,
  toggleSidebar as toggle,
  toggleUserinfoView,
  toggleUserlistView,
  updateIntl,
  userLogin,
  userShowLogoutAlert,
  versionTimer,
  visibilityChange,
} from '../../actions';
import { user as userUtils } from '../../utils';
import App from './App';

const mapStateToProps = (state, ownProps) => ({
  ...state.veriff,
  isSubmenuShown: state.view.sidebar,
  user: state.user,
  sender: state.sender,
  store: ownProps.store,
  browser: state.browser,
  alerts: state.alert,
  notifications: state.notifications,
  locale: state.intl.locale,
  cookies: state.browser.cookiesAllowed,
  thirdPartyCookiesTested: state.browser.thirdPartyCookiesTested,
  thirdPartyCookiesAllowed: state.browser.thirdPartyCookiesAllowed,
  userinfo: state.view.userinfo,
  usernote: state.view.usernote,
  userlist: state.view.userlist,
  isMobile: state.view.isMobile,
  activeSpinner: state.spinner.active,
  friendRequests: state.friends.total,
  newMessages:
    state.dialog.dialogs.filter((d) => !d.read && d.visible).length +
    state.camUser.camUsers.filter((u) => u.newMessage && u.visible).length,
  gameTurn:
    !userUtils.gamesBlocked(state) &&
    state.dialog.dialogs.some((d) => d.flags.includes('gameTurn')),
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        logout,
        toggle,
        keydownWindows,
        errorLogin,
        cookie,
        toggleUserinfoView,
        resize,
        toggleUserlistView,
        userShowLogoutAlert,
        userLogin,
        updateIntl,
      },
      dispatch
    ),
    openOverlay: (e) => dispatch(openOverlay(e)),
    keyDown: (e) => dispatch(keydownWindows(e)),
    visibilityChange: () => dispatch(visibilityChange()),
    versionTimerStart: () => dispatch(versionTimer.start()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

