import { user as userUtils } from '../../../utils';
import { management } from '../../';
import { USERAUDIO_EVENT_START } from '../../actions';

export default (userId, stream) => {
  return (dispatch, getState) => {
    const userList = getState().camUser.camUsers;
    userList.forEach((user) => {
      if (user.userId !== userId && !userUtils.isAdmin(user)) {
        console.log(`Sending stop sender audio to ${user.userId}`);
        dispatch(management.stopSenderAudio(user.userId));
      }
    });
    dispatch({
      type: USERAUDIO_EVENT_START,
      payload: {
        userId,
        stream,
      },
    });
  };
};
