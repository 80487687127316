import './Notifications.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  NOTIFICATION_ALL,
  NOTIFICATION_MAINTENANCE,
  NOTIFICATION_TIP,
  NOTIFICATION_WARNING,
} from '../../constants';
import useGetIncompleteBankDetails from '../../hooks/useGetIncompleteBankDetails';
import Notification from '../Notification/Notification';
import SpinnerPage from '../Spinner/SpinnerPage';
import {
  browserNotAllowedTip,
  hasMaxLevel,
  hasMinLevel,
  incompleteBankDetailsTip,
  livecamNotAllowedTip,
} from './Notifications.functions';

const Notifications = ({
  user,
  browsers,
  browserAllowed,
  spinner,
  type = NOTIFICATION_ALL,
  maxLevel = NOTIFICATION_MAINTENANCE,
  minLevel = NOTIFICATION_TIP,
}) => {
  const [tips, setTips] = useState([]);
  const [tipsLoaded, setTipsLoaded] = useState(null);
  const showLivecamTip = user?.account?.showLivecamTip;
  const incompleteBankDetails = useGetIncompleteBankDetails(user);

  const history = useHistory();
  const [{ loading: tipsLoading }, tipsRequest] = useAxios(
    {
      url: '/user/tips',
    },
    { manual: true }
  );

  const isWarning = minLevel === NOTIFICATION_WARNING;

  const notificationsClassNames = classNames({
    'dashboard-col-wrapper': isWarning,
    'notifications-warnings-wrapper': isWarning,
  });

  useEffect(() => {
    const internalLinks = document.getElementsByClassName('internalLink');
    if (internalLinks?.length > 0) {
      const internalLinksArray = [...internalLinks];
      internalLinksArray?.forEach((link) => {
        const internalLinkHref = link?.getAttribute('href');
        //if tips are loaded multiple times on this page,
        //please make sure to add event listener only once
        link?.addEventListener(
          'click',
          (e) => {
            e.preventDefault();
            history.push(internalLinkHref);
          },
          false
        );
      });
    }
  }, [tipsLoaded, history]);

  const processTips = useCallback(
    (tips) => {
      const hasMaxLevelFunc = hasMaxLevel(maxLevel);
      const hasMinLevelFunc = hasMinLevel(minLevel);
      const showTip = (tip) =>
        tip.headline !== null &&
        tip.headline !== 'missing.headline' &&
        hasMinLevelFunc(tip.type) &&
        hasMaxLevelFunc(tip.type) &&
        (type === NOTIFICATION_ALL || type === tip.type);
      let priortyTips = [];
      let allTips = [];
      if (tips?.length > 0) {
        priortyTips = tips.filter(
          (tip) => tip.type === NOTIFICATION_MAINTENANCE && showTip(tip)
        );
        allTips = tips.filter(
          (tip) => tip.type !== NOTIFICATION_MAINTENANCE && showTip(tip)
        );
      }
      if (!browserAllowed) {
        const browserNotAllowedTipObj = browserNotAllowedTip(browsers);
        if (showTip(browserNotAllowedTipObj)) {
          allTips.push(browserNotAllowedTipObj);
        }
      } else if (showLivecamTip && showTip(livecamNotAllowedTip)) {
        allTips.push(livecamNotAllowedTip);
      }
      if (
        incompleteBankDetails &&
        !user?.account?.slave &&
        showTip(incompleteBankDetailsTip)
      ) {
        allTips.unshift(incompleteBankDetailsTip);
      }
      setTips([...priortyTips, ...allTips]);
    },
    [
      browserAllowed,
      browsers,
      incompleteBankDetails,
      user?.account?.slave,
      minLevel,
      maxLevel,
      type,
      showLivecamTip,
    ]
  );

  useEffect(() => {
    let isMounted = true;
    tipsRequest()
      .then((response) => {
        processTips(response?.data);
        isMounted && setTipsLoaded(true);
      })
      .catch(() => {
        isMounted && setTipsLoaded(true);
      });
    return () => (isMounted = false);
  }, [showLivecamTip, spinner, tipsRequest, processTips]);

  function updateTips(tips) {
    processTips(tips);
  }

  function deleteTip(id) {
    if (tips?.length) {
      const newTips = tips.filter((tip) => tip.id !== parseInt(id));
      processTips(newTips);
    }
  }

  /* Since there will always both a headline and a text for a tip, check only for one of them */
  return tipsLoading ? (
    <SpinnerPage />
  ) : (
    tips?.length > 0 && (
      <div className={notificationsClassNames}>
        <div className="padding-wrapper">
          <div className="notification-items-wrapper">
            {tips?.map((tip) => {
              return (
                <Notification
                  key={tip.id}
                  id={tip.id.toString()}
                  title={tip.headline}
                  content={tip.text}
                  type={tip.type}
                  updateTips={updateTips}
                  updateDeletedTip={deleteTip}
                  pinned={tip.pinned}
                  values={tip.values}
                />
              );
            })}
          </div>
        </div>
      </div>
    )
  );
};

export default Notifications;
