export default {
  INFOCARD_HEADLINE: `User Infocard`,
  INFOCARD_INTRO: `In the {link} you will find all information about the selected user in the right-hand work area. This information can be helpful for asking the user initial questions or addressing certain characteristics. This is how the user information area is structured:`,
  INFOCARD_INTRO_LINK: `Multi Messenger`,
  INFOCARD_USERINFO: `User Info`,
  INFOCARD_USERINFO_TEXT: `There is a lot of information about the user here.`,
  INFOCARD_USERINFO_TEXT_1: `You can see the following information:`,
  INFOCARD_NOTES: `Notes`,
  INFOCARD_NOTES_TEXT: `As soon as you are in contact with the user and they reveal something about themselves that is not in the profile information, you can make notes under this tab. This way you have everything important to hand for later messages.`,
  INFOCARD_GAMES: `4Score`,
  INFOCARD_GAMES_TEXT: `If you have already played 4Score with the user, you can find current games under this tab.`,

  INFOCARD_USERINFO_PICTURE: `Profile picture`,
  INFOCARD_USERINFO_PICTURE_TEXT: `You can enlarge the profile picture by clicking on the magnifying glass next to the picture. Are the pictures of your customers too explicit for you? In the {link} you can activate the safe mode and then you will no longer see any suggestive pictures.`,
  INFOCARD_USERINFO_PICTURE_TEXT_1: `In this area you will also find the online status, the user's nickname (like you, he has a pseudonym as a name) and which platform the customer comes from.`,
  INFOCARD_USERINFO_PICTURE_LINK: `options`,

  INFOCARD_USERINFO_FEATURES: `Features`,
  INFOCARD_USERINFO_FEATURES_TEXT: `Look at which paid features the customer can use.`,

  INFOCARD_USERINFO_DATA: `Important information`,
  INFOCARD_USERINFO_DATA_TEXT: `You can see age, gender, origin and language at first glance. For personal information about the customer, click on the "More" button.`,
  INFOCARD_USERINFO_PERSONAL: `Personal Info`,

  INFOCARD_USERINFO_REVENUE: `Turnover`,
  INFOCARD_USERINFO_REVENUE_TEXT: `Here you will find an overview of sales broken down by different categories. This gives you an overview of how the revenue was made up with the customer. This way, you can easily see if the customer is mainly coming to your live cam, sending you messages, buying premium file attachments, or viewing your photo and video galleries.`,
};
