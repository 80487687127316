import { uuid } from '../../../utils';
import { FRIEND_FRIEND_REQUEST_ALREADY_SENT } from '../../actions';

export default function (payload) {
  return {
    type: FRIEND_FRIEND_REQUEST_ALREADY_SENT,
    meta: {
      stamp: uuid(),
    },
  };
}
