import './Smiley.scss';

import classNames from 'classnames';
import React from 'react';

const Smiley = ({
  position,
  name,
  style,
  code,
  onClick = () => {},
  cssClass,
  single = false,
}) => {
  const classes = ['smiley'];

  classes.push(name.replace(/ /g, '-'));
  if (cssClass) {
    classes.push(cssClass);
  }
  const wrapperClasses = classNames('smiley-wrapper', {
    'smiley-single': single,
    sprite: position === 'bottom',
  });
  return (
    <span className={wrapperClasses}>
      <span
        onClick={() => onClick(code)}
        style={style}
        className={classes.join(' ')}
      />
    </span>
  );
};

export default Smiley;
