import { ALERT_OK } from '../actions';

export default (stamp) => {
  return {
    type: ALERT_OK,
    payload: {
      stamp,
    },
  };
};
