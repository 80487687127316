import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import camMessengerIcon from '../../../assets/img/svg/camMessenger.svg';

function DefaultBannerContent() {
  return (
    <>
      <h1 className="headline">
        <FormattedMessage id="DASHBOARD_BANNER_HEADLINE" />
      </h1>
      <p className="banner-text">
        <FormattedMessage id="DASHBOARD_BANNER_TEXT" />
      </p>
      <span className="icon-wrapper">
        <ReactSVG
          src={camMessengerIcon}
          wrapper="span"
          className="messenger-icon"
        />
      </span>
    </>
  );
}

export default DefaultBannerContent;

