import './PictureCropper.scss';

import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCrop from 'react-image-crop';
import { FormattedMessage } from 'react-intl';

import closeIcon from '../../assets/img/svg/close.svg';
import { dataURLtoFile } from '../../components/Cam/Cam.functions';
import useMediaQuery from '../../hooks/useMediaQuery';
import useWindowResize from '../../hooks/useWindowResize';
import Button from '../Button/Button';
import Scrollbar from '../Scrollbar/Scrollbar';
import ActionButtons from './ActionButtons/ActionButtons';
import CropPreview from './CropPreview/CropPreview';

function PictureCropper({
  title,
  file,
  imageSource,
  indicator,
  uploadFormData = new FormData(),
  uploadPath,
  uploadFile = () => {},
  remoteStamp,
  cropperstamp = '',
  closeOverlay = () => {},
  minDimensions,
  widescreen = false,
}) {
  const staticMinWidth = minDimensions?.width ? minDimensions.width : 1080;
  const staticMinHeight = minDimensions?.height ? minDimensions.height : 1080;

  const [crop, setCrop] = useState({});
  const [completedCrop, setCompletedCrop] = useState(null);
  const [minWidth, setMinWidth] = useState(100);
  const [aspectRatioSwitch, setAspectRatioSwitch] = useState(true);
  // A rotate amount in degrees; Even though the canvas rotate function takes in radians, the degrees are easier to keep track
  // Also, degrees are needed for the API call
  const [rotate, setRotate] = useState(0);
  // The rotated image is actually every image except the original one, if the rotate button is clicked once, rotatedImage replaces the original
  const [rotatedImage, setRotatedImage] = useState(null);
  // The original dimensions of the image that is first uploaded, needed for calculating the correct ratios
  const [startingDimensions, setStartingDimensions] = useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0,
  });
  const imageRef = useRef();
  const tempCanvasRef = useRef(null);
  const [hasRotated, setHasRotated] = useState(false);
  const windowResize = useWindowResize();
  const isMobileScreen = useMediaQuery(
    '(max-width: 649px) and (orientation: portrait)'
  );

  const onLoad = (i) => {
    const img = i.target;
    imageRef.current = img;
    if (startingDimensions.width === 0 || !hasRotated) {
      setStartingDimensions({
        width: img.width,
        height: img.height,
        naturalWidth: img.naturalWidth,
        naturalHeight: img.naturalHeight,
      });
    }
    let minWidth;
    let minHeight;
    let y;
    let x;
    const ratio = img.width / img.naturalWidth;
    if (startingDimensions.naturalWidth) {
      minWidth = widescreen
        ? staticMinWidth * (img.width / startingDimensions.naturalWidth)
        : staticMinHeight * (img.height / startingDimensions.naturalHeight);
      minHeight =
        staticMinHeight * (img.height / startingDimensions.naturalHeight);
      if (rotate === 90 || rotate === 270) {
        minWidth = widescreen
          ? staticMinWidth * (img.width / startingDimensions.naturalHeight)
          : staticMinHeight * (img.height / startingDimensions.naturalWidth);
        minHeight =
          staticMinHeight * (img.height / startingDimensions.naturalWidth);
      }
      y = (ratio * img.naturalHeight - minHeight) / 2;
    } else {
      if (img.naturalWidth >= img.naturalHeight) {
        minWidth = widescreen
          ? staticMinWidth * (img.width / img.naturalWidth)
          : staticMinHeight * ratio;
        minHeight = staticMinHeight * ratio;
        y = (ratio * img.naturalHeight - minHeight) / 2;
      } else {
        minWidth = widescreen
          ? staticMinWidth * (img.width / img.naturalWidth)
          : staticMinHeight * (img.height / img.naturalHeight);
        minHeight = staticMinHeight * (img.height / img.naturalHeight);
        y = (img.height - minHeight) / 2;
      }
    }
    // Crop can always start at (0,0) or it can be centered
    // Center varies depending on image size and orientation

    x = (img.width - minWidth) / 2;
    setCrop({
      unit: 'px',
      aspect: widescreen ? 16 / 9 : 1 / 1,
      width: widescreen ? minWidth : minHeight,
      height: minHeight,
      x: x,
      y: y,
    });
    setCompletedCrop({
      unit: 'px',
      aspect: widescreen ? 16 / 9 : 1 / 1,
      width: widescreen ? minWidth : minHeight,
      height: minHeight,
      x: x,
      y: y,
    });
    setMinWidth(minWidth);
    return false;
  };

  // Use effect for adjusting the cropper and not letting it get out of bounds
  useEffect(() => {
    if (!imageRef.current) {
      return;
    }
    let minWidth;
    // minWidth will almost always be calculated based on the starting dimensions of the image,
    // except if it is the first useEffect after the image is uplaoded
    if (startingDimensions.width) {
      minWidth = widescreen
        ? staticMinWidth *
          (imageRef.current.width / startingDimensions.naturalWidth)
        : startingDimensions.naturalWidth >= startingDimensions.naturalHeight
        ? staticMinHeight *
          (imageRef.current.height / startingDimensions.naturalHeight)
        : staticMinWidth *
          (imageRef.current.width / startingDimensions.naturalWidth);
      if (rotate === 90 || rotate === 270) {
        minWidth = widescreen
          ? staticMinWidth *
            (imageRef.current.width / startingDimensions.naturalHeight)
          : startingDimensions.naturalWidth >= startingDimensions.naturalHeight
          ? staticMinWidth *
            (imageRef.current.width / startingDimensions.naturalWidth)
          : staticMinHeight *
            (imageRef.current.height / startingDimensions.naturalHeight);
      }
    } else {
      minWidth = widescreen
        ? staticMinWidth *
          (imageRef.current.width / imageRef.current.naturalWidth)
        : startingDimensions.naturalWidth >= startingDimensions.naturalHeight
        ? staticMinHeight *
          (imageRef.current.height / startingDimensions.naturalHeight)
        : staticMinWidth *
          (imageRef.current.width / startingDimensions.naturalWidth);
    }
    // Cropper width can't be smaller than the minimum dimensions
    if (
      widescreen
        ? crop.width < minWidth
        : crop.width < minWidth || crop.height < minWidth
    ) {
      setCrop((prevCrop) => {
        return {
          ...prevCrop,
          width: minWidth,
          height: widescreen ? (minWidth / 16) * 9 : minWidth,
        };
      });
      setCompletedCrop((prevCrop) => {
        return {
          ...prevCrop,
          width: minWidth,
          height: widescreen ? (minWidth / 16) * 9 : minWidth,
        };
      });
    }
    // if the screen size changes, preview picture to be updated every time.
    if (imageRef.current.width) {
      setCompletedCrop((prevCrop) => {
        return { ...prevCrop };
      });
    }
    // Cropper width should always be smaller than the image
    if (parseInt(crop.width) > parseInt(imageRef.current.width)) {
      const newWidth =
        imageRef.current.width - 5 > minWidth
          ? imageRef.current.width - 5
          : minWidth;
      setCrop((prevCrop) => {
        return {
          ...prevCrop,
          width: newWidth,
          height: widescreen ? prevCrop.height : newWidth,
        };
      });
      setCompletedCrop((prevCrop) => {
        return {
          ...prevCrop,
          width: newWidth,
          height: widescreen ? prevCrop.height : newWidth,
        };
      });
    }
    // Cropper height should always be smaller than the image
    if (
      widescreen
        ? crop.height > imageRef.current.height ||
          crop.height > (crop.width / 16) * 9
        : parseInt(crop.height) > parseInt(imageRef.current.height)
    ) {
      const newHeight =
        imageRef.current.height - 5 > minWidth
          ? imageRef.current.height - 5
          : minWidth;
      setCrop((prevCrop) => {
        const width = prevCrop ? prevCrop.width : crop.width;
        return {
          ...prevCrop,
          width: widescreen ? prevCrop.width : newHeight,
          height: widescreen ? (width / 16) * 9 : newHeight,
        };
      });
      setCompletedCrop((prevCrop) => {
        const width = prevCrop ? prevCrop.width : crop.width;
        return {
          ...prevCrop,
          width: widescreen ? prevCrop.width : newHeight,
          height: widescreen ? (width / 16) * 9 : newHeight,
        };
      });
    }

    // Cropper should not go out of bounds from the right
    if (parseInt(crop.x + crop.width) > parseInt(imageRef.current.width)) {
      setCrop((prevCrop) => {
        return { ...prevCrop, x: imageRef.current.width - crop.width };
      });
      setCompletedCrop((prevCrop) => {
        return { ...prevCrop, x: imageRef.current.width - crop.width };
      });
    }
    // Cropper should not go out of bounds from bottom left and right
    if (parseInt(crop.y + crop.height) > parseInt(imageRef.current.height)) {
      setCrop((prevCrop) => {
        return { ...prevCrop, y: imageRef.current.height - crop.height };
      });
      setCompletedCrop((prevCrop) => {
        return { ...prevCrop, y: imageRef.current.height - crop.height };
      });
    }
    // Cropper should not go out of bounds from the left
    if (crop.x < 0) {
      setCrop((prevCrop) => {
        return {
          ...prevCrop,
          x: widescreen ? 0 : (imageRef.current.width - crop.width) / 2,
        };
      });
      setCompletedCrop((prevCrop) => {
        return {
          ...prevCrop,
          x: widescreen ? 0 : (imageRef.current.width - crop.width) / 2,
        };
      });
    }
    // Cropper should not go out of bounds from the top
    if (crop.y < 0) {
      setCrop((prevCrop) => {
        return { ...prevCrop, y: 0 };
      });
      setCompletedCrop((prevCrop) => {
        return { ...prevCrop, y: 0 };
      });
    }
    setMinWidth(minWidth);
  }, [
    startingDimensions.naturalWidth,
    startingDimensions.width,
    windowResize,
    rotate,
    startingDimensions.naturalHeight,
    crop,
    staticMinHeight,
    staticMinWidth,
    widescreen,
  ]);

  function handleCropChange(newCrop) {
    // Only resize the cropper if the width is correct
    if (newCrop.width >= minWidth) {
      setCrop(newCrop);
    }
  }

  function rotateImage(radians) {
    const image = imageRef.current;
    const tempCanvas = tempCanvasRef.current;
    const tempCtx = tempCanvas.getContext('2d');
    let widthHeightDifference;
    if (startingDimensions.height < startingDimensions.width) {
      widthHeightDifference = image.width - image.height;
      tempCanvas.width = image.height * 2;
      tempCanvas.height = (image.height + widthHeightDifference) * 2;
    } else if (startingDimensions.height >= startingDimensions.width) {
      widthHeightDifference = image.height - image.width;
      tempCanvas.width = image.height * 2;
      tempCanvas.height = (image.height - widthHeightDifference) * 2;
    }
    if (radians === Math.PI) {
      tempCanvas.width = image.width * 2;
      tempCanvas.height = image.height * 2;
    }
    tempCtx.imageSmoothingQuality = 'high';
    tempCtx.translate(tempCanvas.width / 2, tempCanvas.height / 2);
    tempCtx.rotate(radians);
    tempCtx.drawImage(
      image,
      -image.width,
      -image.height,
      image.width * 2,
      image.height * 2
    );
    tempCtx.rotate(-radians);
    tempCtx.translate(-tempCanvas.width / 2, -tempCanvas.height / 2);
    // Image type can be added as a parameter in toDataURL()
    const finalImage = tempCanvas.toDataURL();
    setRotatedImage(finalImage);
  }

  // await Image.load(src) from image-js can be used to generate a new image

  function rotateClockwise() {
    setHasRotated(true);
    if (startingDimensions.naturalHeight < staticMinWidth) {
      rotate === 0 ? setRotate(180) : setRotate(0);
      rotateImage(Math.PI);
    } else {
      rotateImage(Math.PI / 2);
      if (rotate === 270) {
        setRotate(0);
      } else {
        setRotate((prevRotate) => {
          return prevRotate + 90;
        });
      }
    }
  }

  function rotateCounterClockwise() {
    setHasRotated(true);
    if (startingDimensions.naturalHeight < staticMinWidth) {
      rotate === 0 ? setRotate(180) : setRotate(0);
      rotateImage(Math.PI);
    } else {
      rotateImage(-Math.PI / 2);
      if (rotate === 0) {
        setRotate(270);
      } else {
        setRotate((prevRotate) => {
          return prevRotate - 90;
        });
      }
    }
  }

  function uploadCroppedImage() {
    let scale;
    let heightCompareValue;
    let widthCompareValue;
    if (rotate === 0 || rotate === 180) {
      scale = startingDimensions.naturalHeight / imageRef.current.height;
      heightCompareValue = startingDimensions.naturalHeight;
      widthCompareValue = startingDimensions.naturalWidth;
    } else {
      scale = startingDimensions.naturalHeight / imageRef.current.width;
      heightCompareValue = startingDimensions.naturalWidth;
      widthCompareValue = startingDimensions.naturalHeight;
    }
    const crop = {
      ...completedCrop,
      x: Math.round(scale * completedCrop.x),
      y: Math.round(scale * completedCrop.y),
      width: Math.round(scale * completedCrop.width),
      height: Math.round(scale * completedCrop.height),
    };

    if (crop.height === undefined || crop.height > heightCompareValue) {
      crop.height = heightCompareValue;
    }

    if (crop.width === undefined || crop.width > widthCompareValue) {
      crop.width = widthCompareValue;
    }

    if (crop.y === undefined || crop.y + crop.height > heightCompareValue) {
      const difference = crop.y + crop.height - heightCompareValue;
      crop.y = crop.y - difference;
    }

    if (crop.x === undefined || crop.x + crop.width > widthCompareValue) {
      const difference = crop.x + crop.width - widthCompareValue;
      crop.x = crop.x - difference;
    }

    const updatedRotate = rotate < 0 ? 360 + rotate : rotate;
    const innerFile = dataURLtoFile(imageSource, file.name);
    sendUploadImageRequest(crop, innerFile, updatedRotate);
    setHasRotated(false);
  }

  const sendUploadImageRequest = useCallback(
    function (completedCrop, file, rotate) {
      const data = new FormData();

      data.append(
        uploadPath.includes('thumbnail') || uploadPath.includes('diary')
          ? 'file'
          : 'files[]',
        file,
        file.name
      );

      for (let pair of uploadFormData.entries()) {
        data.append(pair[0], pair[1]);
      }
      if (completedCrop) {
        if (uploadPath.includes('thumbnail')) {
          data.append('crop[x]', completedCrop.x);
          data.append('crop[y]', completedCrop.y);
          data.append('crop[width]', completedCrop.width);
          data.append('crop[height]', completedCrop.height);
        } else {
          data.append('crop[0][x]', completedCrop.x);
          data.append('crop[0][y]', completedCrop.y);
          data.append('crop[0][width]', completedCrop.width);
          data.append('crop[0][height]', completedCrop.height);
        }
      }

      if (rotate) {
        data.append(
          uploadPath.includes('thumbnail') ? 'rotate' : 'rotate[]',
          rotate
        );
      }

      uploadFile(uploadPath, data, indicator);
      console.log({ cropperstamp, remoteStamp });
      closeOverlay(cropperstamp);
      console.log({ cropperstamp, remoteStamp });
      if (remoteStamp) {
        console.log({ cropperstamp, remoteStamp });
        closeOverlay(remoteStamp);
        console.log({ cropperstamp, remoteStamp });
      }
    },
    [
      uploadFormData,
      uploadFile,
      uploadPath,
      indicator,
      cropperstamp,
      remoteStamp,
      closeOverlay,
    ]
  );

  function closeModal() {
    setStartingDimensions({
      width: 0,
      height: 0,
      naturalWidth: 0,
      naturalHeight: 0,
    });
    imageRef.current = null;
    setHasRotated(false);
    closeOverlay(cropperstamp);
    if (remoteStamp) {
      closeOverlay(remoteStamp);
    }
  }

  // Calculations for portrait mode are necessary, for landscape can maybe be deleted
  const cropWrapperClass = classNames(
    'react-crop-wrapper',
    widescreen ? ' rectangle' : ' square',
    {
      // Equal dimenstions (width === height) take the same parameters as portrait mode dimensions
      portrait:
        startingDimensions.naturalHeight === startingDimensions.naturalWidth ||
        (startingDimensions.naturalWidth < startingDimensions.naturalHeight &&
          rotate % 180 === 0) ||
        (startingDimensions.naturalWidth > startingDimensions.naturalHeight &&
          (rotate === 90 || rotate === 270)),
      landscape:
        (startingDimensions.naturalWidth > startingDimensions.naturalHeight &&
          rotate % 180 === 0) ||
        (startingDimensions.naturalWidth < startingDimensions.naturalHeight &&
          (rotate === 90 || rotate === 270)),
    }
  );

  useEffect(() => {
    // change the height of the react-crop-wrapper here
    const leftCol = document.getElementById('left-col');
    if (!leftCol) return;
    if (isMobileScreen) return;

    const leftColRatio = leftCol.offsetWidth / leftCol.offsetHeight;
    const imageRatio =
      rotate % 180 === 0
        ? startingDimensions.naturalWidth / startingDimensions.naturalHeight
        : startingDimensions.naturalHeight / startingDimensions.naturalWidth;
    const element = document.getElementsByClassName('react-crop-wrapper')[0];
    if (imageRatio > leftColRatio) {
      element.style.height = 'initial';
      element.style.width = '100%';
    } else {
      element.style.height = '100%';
      element.style.width = 'initial';
    }
  }, [
    isMobileScreen,
    rotate,
    startingDimensions.naturalHeight,
    startingDimensions.naturalWidth,
    windowResize,
  ]);

  const isLowHeight = window.innerHeight < 768;

  const cropperPreview11 = (
    <CropPreview
      imgRef={imageRef}
      completedCrop={completedCrop}
      text="Preview 1:1"
    />
  );
  const cropperPreview43 = (
    <>
      <CropPreview
        imgRef={imageRef}
        completedCrop={completedCrop}
        is43={true}
        wideScreen={widescreen}
        text="Preview 4:3"
      />
    </>
  );
  const cropperPreview169 = (
    <CropPreview
      imgRef={imageRef}
      completedCrop={completedCrop}
      is169={true}
      wideScreen={widescreen}
      text="Preview 16:9"
    />
  );

  return (
    <>
      <div className="overlay-wrapper">
        <div className="overlay-header">
          {title && <h1 className="headline">{title}</h1>}
        </div>
        <div className="overlay-content">
          <div className="crop-grid" id="crop-grid">
            <div className="left-col" name="imageField" id="left-col">
              {imageSource && (
                <ReactCrop
                  className={cropWrapperClass}
                  crop={crop}
                  minWidth={minWidth}
                  keepSelection={true}
                  ruleOfThirds={true}
                  onComplete={(c) => {
                    setCompletedCrop(c);
                  }}
                  onChange={handleCropChange}
                  aspect={widescreen ? 16 / 9 : 1 / 1}
                >
                  <img
                    src={rotatedImage || imageSource}
                    alt="Crop"
                    onLoad={(img) => setTimeout(() => onLoad(img), 200)}
                  />
                </ReactCrop>
              )}
            </div>

            <canvas
              // A canvas element needs to be present for the canvas rotation of the image to work
              ref={tempCanvasRef}
              className="display-none"
            />

            <div className="right-col" id="right-col">
              <Scrollbar visible={true}>
                <div className="content">
                  {isLowHeight && (
                    <div className="crop-previews">
                      {aspectRatioSwitch
                        ? widescreen
                          ? cropperPreview169
                          : cropperPreview11
                        : widescreen
                        ? cropperPreview43
                        : cropperPreview169}
                      <a
                        href="#"
                        onClick={() => setAspectRatioSwitch(!aspectRatioSwitch)}
                      >
                        {`Show ${
                          aspectRatioSwitch
                            ? widescreen
                              ? '4:3'
                              : '16:9'
                            : widescreen
                            ? '16:9'
                            : '1:1'
                        } aspect ratio`}
                      </a>
                    </div>
                  )}
                  {!isLowHeight && (
                    <div className="crop-previews">
                      {widescreen ? (
                        <>
                          {cropperPreview169}
                          {cropperPreview43}
                        </>
                      ) : (
                        <>
                          {cropperPreview11}
                          {cropperPreview169}
                        </>
                      )}
                    </div>
                  )}
                  <div className="previews-text" id="previews-text">
                    <p>
                      <FormattedMessage id="UPLOADER_PICTURE_CROPPER_TEXT_1" />
                    </p>
                    <p>
                      <FormattedMessage id="UPLOADER_PICTURE_CROPPER_TEXT_2" />
                    </p>
                    <p>
                      <FormattedMessage id="UPLOADER_PICTURE_CROPPER_TEXT_3" />
                    </p>
                    <p>
                      <FormattedMessage id="UPLOADER_PICTURE_CROPPER_TEXT_4" />
                    </p>
                  </div>
                </div>
              </Scrollbar>
              <ActionButtons
                className="action-buttons"
                name="buttonsField"
                file={file}
                onUploadClick={uploadCroppedImage}
                onClockwiseRotateBtnClick={rotateClockwise}
                onCounterClockwiseRotateBtnClick={rotateCounterClockwise}
              />
            </div>
          </div>
        </div>
        <Button
          type="button"
          onClick={closeModal}
          classNamesOnly="close-btn"
          icon={closeIcon}
          intlTranslate={false}
        />
      </div>
      <div className="background" />
    </>
  );
}

export default PictureCropper;

