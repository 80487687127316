import './GalleryComment.scss';

import useAxios from 'axios-hooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import deleteIcon from '../../assets/img/svg/delete.svg';
import { spinnerRequest } from '../../shared/spinnerRequest';
import DateItem from '../Tables/TableItems/DateItem';

function GalleryComment({
  comment,
  language,
  galleryId,
  spinner,
  activeGallery,
  updateActiveGallery,
}) {
  const [{ loading: isCommentDeleting }, deleteCommentRequest] = useAxios(
    {
      url: `/galleries/${galleryId}/comment/${comment.id}`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const deleteComment = () => {
    if (isCommentDeleting) {
      return;
    }
    spinnerRequest({
      request: deleteCommentRequest,
      spinner,
    })
      .then(() => {
        const updatedComments = activeGallery?.comments?.filter(
          (c) => c.id !== comment.id
        );
        const updatedGallery = { ...activeGallery, comments: updatedComments };
        updateActiveGallery(updatedGallery);
      })
      .catch(() => {});
  };

  return (
    <div className="gallery-comment">
      <div className="comment-content">
        <div>
          {comment.user} <FormattedMessage id="COMMENT_WRITES_ON" />{' '}
          {
            <DateItem
              data={comment.created}
              dateFormat={
                language === 'de' ? 'D. MMM YYYY HH:mm' : 'MMM D, YYYY h:mm a'
              }
            />
          }
        </div>
        <div>{comment.comment}</div>
      </div>
      <div className="delete-comment" onClick={() => deleteComment()}>
        <ReactSVG src={deleteIcon} wrapper="span" />
      </div>
    </div>
  );
}

export default GalleryComment;

