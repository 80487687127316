import { connect } from 'react-redux';

import { spinner, updateActiveGallery } from '../../../actions';
import GalleryInfo from './GalleryInfo';

const mapStateToProps = (state) => ({
  activeGallery: state.galleries.active,
  language: state.user.account.language,
});

const mapDispatchToProps = {
  updateActiveGallery,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(GalleryInfo);
