import { connect } from 'react-redux';

import { spinner } from '../../../../../actions';
import MyRequests from './MyRequests';

const mapStateToProps = (state) => ({
  dialog: state.dialog.dialogs,
});

const mapDispatchToProps = {
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyRequests);
