import { connect } from 'react-redux';

import {
  answerFriendRequest,
  favoriteUpdateFromList,
  openDialog,
  unignoreIdUser,
} from '../../../actions';
import {
  deleteFriend,
  friendsError,
  removeDeniedFriendRequest,
  updateListOwnRequests,
} from '../../../actions/friend';
import RelationshipItem from './RelationshipItem';

const mapStateToProps = (state) => ({
  ownRequests: state.friends.ownRequests,
  hasFriendsScope: state.user.account?.scopes?.includes('friends'),
  safeMode: state.preferences.safeMode,
});

const mapDispatchToProps = (dispatch) => ({
  openDialog: (id, name) => dispatch(openDialog(id, name)),
  answerFriendRequest: (requestSent, userId, nickname) =>
    dispatch(answerFriendRequest(requestSent, userId, nickname)),
  removeFavoriteUser: (id) => dispatch(favoriteUpdateFromList(id)),
  removeFriend: (id, name) => dispatch(deleteFriend(id, false, name)),
  updateListOwnRequests: (list) => dispatch(updateListOwnRequests(list)),
  friendsError: () => dispatch(friendsError()),
  removeDeniedFriendRequest: (id, name) =>
    dispatch(removeDeniedFriendRequest(id, name, false)),
  unignoreIdUser: (id) => dispatch(unignoreIdUser(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RelationshipItem);

