import { RATATOSKR_TIME_FRAME } from '../../../constants';
import { OPEN_EVENT_RATATOSKR } from '../../actions';
export default (ratatoskrService) => () => (dispatch, getState) => {
  const userId = getState().user?.account?.messagingId;
  const lastAction = Date.now() - RATATOSKR_TIME_FRAME;
  ratatoskrService.send({ user: { userId }, channels: [], lastAction });
  dispatch({
    type: OPEN_EVENT_RATATOSKR,
  });
};
