import './DocumentsTable.scss';

import useAxios from 'axios-hooks';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';

import useMediaQuery from '../../../hooks/useMediaQuery';
import useTranslateTableTitles from '../../../hooks/useTranslateTableTitles';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import {
  fullDocumentsTableTitles,
  mediumDocumentsTableTitles,
  mobileDocumentsTableTitles,
} from '../../Content/Documents/Documents.functions';
import MyAccountContext from '../../Content/MyAccount/MyAccountContext';
import StepperContext from '../../Stepper/StepperContext';
import DateItem from '../TableItems/DateItem';
import StatusItem from '../TableItems/StatusItem/StatusItem';

function DocumentsTable({
  openDocument,
  items = [],
  isSubmenuShown,
  user,
  spinner,
  errorDocument,
}) {
  const MyAccount = useContext(MyAccountContext);
  const Stepper = useContext(StepperContext);
  const setIsLoading =
    MyAccount?.setIsLoading || Stepper?.setIsLoading || function () {};
  const intl = useIntl();
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const isMediumScreen =
    useMediaQuery('(min-width: 1024px) and (max-width: 1599px)') &&
    isSubmenuShown;
  const titles = isMobileScreen
    ? mobileDocumentsTableTitles
    : isMediumScreen
    ? mediumDocumentsTableTitles
    : fullDocumentsTableTitles;

  const translatedTitles = useTranslateTableTitles(titles);
  const [, tokenRequest] = useAxios(
    {
      method: 'POST',
    },
    { manual: true }
  );
  const itemTypes = items.map((item) => item.type);
  const showColumn = itemTypes.every((element) => element === itemTypes[0]);
  async function findDocument(payload) {
    const response = await tokenRequest({
      url: `/user/documents/${payload.id}/token`,
    });
    if (response?.data?.token) {
      await fetch(
        `${process.env.REACT_APP_API_URL}/user/documents/${payload.id}/file?token=${response?.data?.token}`,
        {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        }
      ).then(async (res) => {
        if (!res.ok) {
          errorDocument();
          return;
        }
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        openDocument(payload.title, imageObjectURL);
      });
    }
  }
  async function previewImage(state, id, title) {
    if (state === 'confirmed') {
      try {
        await spinnerRequest({
          request: findDocument,
          payload: { id, title },
          timeout: 100,
          setLoading: setIsLoading,
          spinner: spinner,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }
  const statusTranslations = {
    rejected: intl.formatMessage({ id: 'LABEL_REJECTED' }),
    pending: intl.formatMessage({ id: 'LABEL_PENDING' }),
    confirmed: intl.formatMessage({ id: 'LABEL_CONFIRMED' }),
  };
  return (
    <>
      <table>
        <thead>
          <tr>
            {translatedTitles.map((title, index) => {
              return showColumn && title.showTableHeader ? null : (
                <th
                  style={{ width: title.width, textAlign: title.position }}
                  key={`${title}.${index}`}
                >
                  {title.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr key={`${item.id}.${index}`}>
                <td
                  className={'text-left description-td ' + item.state}
                  onClick={() =>
                    previewImage(item.state, item.id, item.description)
                  }
                >
                  {item.description}
                </td>
                <td className={'text-left status-td ' + item.state}>
                  <StatusItem
                    data={statusTranslations[item.state]}
                    status={item.state}
                  />
                  {item.comment ? (
                    <div
                      className={
                        item.state === 'confirmed'
                          ? 'comment-div action-needed'
                          : 'comment-div'
                      }
                    >
                      {item.comment}
                    </div>
                  ) : null}
                </td>
                {!isMobileScreen && !showColumn && (
                  <td>
                    {intl.formatMessage({
                      id: `DOCUMENTS_${item.type.toUpperCase()}`,
                    })}
                  </td>
                )}
                {!isMobileScreen && (
                  <td className="align-right">
                    {' '}
                    <DateItem
                      data={item.created}
                      dateFormat={
                        user.account?.language === 'de'
                          ? 'DD.MM.YYYY - HH:mm'
                          : 'MM/DD/YYYY - h:mm a'
                      }
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default DocumentsTable;

