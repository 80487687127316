export const getIcebreakers = () => [
  {
    headline: 'ICEBREAKER_HEADLINE_PICTURE',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_PICTURE',
    text3: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'picture',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_FIRST_LOGIN',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_FIRST_LOGIN',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'first.login',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_RECURRENT_LOGIN',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_RECURRENT_LOGIN',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'recurrent.login',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_KISS_SENT',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_KISS_SENT',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'kiss.sent',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_PROFILE_VISIT',
    text1: 'ICEBREAKER_PARAGRAPH_PROFILE_VISIT',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'profile.visit',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_FOLLOWUP',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_FOLLOWUP',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'followUp',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_USER_PICTURE_WANTED',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PICTURE_WANTED',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'user.picture.wanted',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_NEW_PROFILEIMAGE',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_NEW_PROFILEIMAGE',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'new.profileImage',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_USER_PREFERENCES_WANTED',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PREFERENCES_WANTED',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'user.preferences.wanted',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_USER_PREFERENCES_MATCH',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PREFERENCES_MATCH',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'user.preferences.match',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_HON_MATCH',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_HON_MATCH',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'hon.match',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_HON_PICTURE_WANTED',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_HON_PICTURE_WANTED',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'hon.picture.wanted',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_PROFILE_VISIT_LIVECAM',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_PROFILE_VISIT_LIVECAM',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'profile.visit.livecam',
    menu: false,
  },
  {
    headline: 'ICEBREAKER_HEADLINE_MEDIA_PURCHASE',
    text1: 'ICEBREAKER_PARAGRAPH_EXPLANATION_MEDIA_PURCHASE',
    text2: 'ICEBREAKER_PARAGRAPH_CALLTOACTION',
    label: 'ATTACHMENT_LABEL',
    type: 'media.purchase',
    menu: false,
  },
];

export const initialValues = {
  picture: '',
  'first.login': '',
  'recurrent.login': '',
  'kiss.sent': '',
  'profile.visit': '',
  followUp: '',
  'user.picture.wanted': '',
  'new.profileImage': '',
  'user.preferences.wanted': '',
  'user.preferences.match': '',
  'hon.match': '',
  'hon.picture.wanted': '',
  'profile.visit.livecam': '',
  'media.purchase': '',
};
