import { useRef } from 'react';

import {
  CHAT_LAYOUT_MULTI,
  CHAT_LAYOUT_SINGLE,
  SORT_BY_TIME_ASC,
  SORT_BY_TIME_DESC,
} from '../../../../constants';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import Button from '../../../Button/Button';

function UserListMenu({
  open,
  toggleUserlistMenu,
  layout,
  changeLayoutChat,
  enableChatLayout,
  isMobile,
  sortDialogs,
  activeSort,
}) {
  const menuRef = useRef();

  const switchId =
    layout === CHAT_LAYOUT_MULTI
      ? 'btn.title.chatMulti'
      : 'btn.title.chatSingle';

  const switchTo =
    layout === CHAT_LAYOUT_MULTI ? CHAT_LAYOUT_SINGLE : CHAT_LAYOUT_MULTI;

  useOutsideClick(menuRef, () => {
    if (open) {
      toggleUserlistMenu();
    }
  });

  return open ? (
    <div className="toggle-menu settings" ref={menuRef}>
      {!isMobile && enableChatLayout && (
        <Button
          variant="default"
          className="menu-item border-bottom"
          label={switchId}
          onClick={() => {
            changeLayoutChat(switchTo);
            toggleUserlistMenu();
          }}
        />
      )}
      {activeSort === SORT_BY_TIME_ASC ? (
        <Button
          variant="default"
          className="menu-item"
          label="btn.title.sortDesc"
          onClick={() => {
            sortDialogs(SORT_BY_TIME_DESC);
            toggleUserlistMenu();
          }}
          active={activeSort === SORT_BY_TIME_DESC}
        />
      ) : (
        <Button
          variant="default"
          className="menu-item"
          label="btn.title.sortAsc"
          onClick={() => {
            sortDialogs(SORT_BY_TIME_ASC);
            toggleUserlistMenu();
          }}
          active={activeSort === SORT_BY_TIME_ASC}
        />
      )}
    </div>
  ) : null;
}

export default UserListMenu;
