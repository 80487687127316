import { useState } from 'react';
import { ReactSVG } from 'react-svg';

import userHiddenIcon from '../../../../assets/img/svg/user-hidden.svg';
import userIcon from '../../../../assets/img/svg/user.svg';
import zoomIcon from '../../../../assets/img/svg/zoom.svg';
import { uuid } from '../../../../utils';
import Button from '../../../Button/Button';

function UserProfileImage({
  profile_image,
  nickname,
  whitelabelLong,
  safeMode,
  is_support,
  profilePictureFsk,
  openOverlay,
  spinner,
}) {
  const [toggleProfileImage, setToggleProfileImage] = useState(safeMode);
  const profileImage = profile_image?.includes('https://')
    ? profile_image
    : process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + profile_image;

  const safeModeProfilePicture = profilePictureFsk === 'fsk12';
  const openPicture = () => {
    spinner(true);
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Document',
      props: {
        openDocumentStamp: stamp,
        title: nickname,
        fileSource: profileImage,
      },
    });
    spinner(false);
  };

  return (
    <div className="user-profile-image-wrapper">
      <div className="user-profile-image-inner">
        {profile_image && safeModeProfilePicture ? (
          <>
            <div
              className={`user-profile-image`}
              style={{ backgroundImage: `url(${profileImage})` }}
            />
            <div className="user-profile-image-zoom">
              <Button
                type="button"
                variant="icon-only"
                icon={zoomIcon}
                onClick={openPicture}
                intlTranslate={false}
              />
            </div>
          </>
        ) : profile_image && !toggleProfileImage ? (
          <>
            <div
              onClick={() =>
                safeMode && setToggleProfileImage(!toggleProfileImage)
              }
              className={`user-profile-image ${
                safeMode && 'safe-mode-clickable'
              }`}
              style={{ backgroundImage: `url(${profileImage})` }}
            />
            <div className="user-profile-image-zoom">
              <Button
                type="button"
                variant="icon-only"
                icon={zoomIcon}
                onClick={openPicture}
                intlTranslate={false}
              />
            </div>
          </>
        ) : profile_image && toggleProfileImage ? (
          <div
            className={`user-profile-image empty ${
              safeMode && 'safe-mode-clickable'
            }`}
            onClick={() =>
              safeMode && setToggleProfileImage(!toggleProfileImage)
            }
          >
            <ReactSVG src={userHiddenIcon} wrapper="span" />
          </div>
        ) : (
          <div className={`user-profile-image empty`}>
            <ReactSVG src={userIcon} wrapper="span" />
          </div>
        )}
      </div>
      <div className="user-nickname">
        <span>{nickname}</span>
        {whitelabelLong && !is_support && (
          <span className="user-whitelabel">{whitelabelLong}</span>
        )}
      </div>
    </div>
  );
}

export default UserProfileImage;

