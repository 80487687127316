import { UPDATE_CONTESTS } from '../actions/actions';

const initialState = {};

const contests = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CONTESTS:
      return { ...action.payload };
    default:
      return state;
  }
};

export default contests;
