export default {
  LIVECAM_SETTING_HEADLINE: `Step by Step to your first livecam show`,
  LINK_TEXT_ACCOUNT: `My account`,
  LIVECAM_SETTING_CONTENT_1: `We would like to show you a few tips how to get started successfully with your webcam.`,

  LIVECAM_SETTING_HEADLINE_2: `Planning and preparation`,
  LIVECAM_SETTING_CONTENT_STEP_1: `Internet connection`,
  LIVECAM_SETTING_CONTENT_STEP_1_CONTENT: `As a first step, run a <a href='https://www.speedmeter.de/' target='_blank'>"speed test"</a> to measure your upload and download rates. The minimum upload should be 5 MBit/s, optimally 10 MBit/s. Contact your ISP to adjust your speed or choose the best connection for you. A stable internet connection is crucial for uninterrupted transmission. Your connection is most stable if you go online via a LAN cable.`,
  LIVECAM_SETTING_CONTENT_STEP_2: `Webcam`,
  LIVECAM_SETTING_CONTENT_STEP_2_CONTENT: `For a PC you need a webcam with a resolution of 640 x 480 pixels or more. A basic entry-level camera with a 1080p HD widescreen sensor is recommended to optimize sharpness and image quality. Cameras from Logitech or Microsoft are very good and affordable. Good webcams are also integrated into most laptops nowadays.
Place your webcam so that you can easily reposition yourself and make sure that it is on a firm surface so that your image does not wobble. Use a wireless mouse and keyboard for more flexibility and freedom of movement.`,
  LIVECAM_SETTING_CONTENT_STEP_3: `Workspace`,
  LIVECAM_SETTING_CONTENT_STEP_3_CONTENT: `Choose a place where you feel comfortable and can work in peace. Make sure that your customers can't see any objects in the background that you didn't put there on purpose. You should have utensils such as toys or clothing close by. Make sure there is sufficient lighting, but avoid bright or flickering light and shadows. Small colored lights can add interest to your image and background as long as they don't blink or change colors quickly.`,

  LIVECAM_SETTING_HEADLINE_3: `Settings in LiveCreator`,
  LIVECAM_SETTING_CONTENT_STEP_4: `Webcam`,
  LIVECAM_SETTING_CONTENT_STEP_4_CONTENT: `Select your webcam under {link}. Select the video resolution that should be used to transmit your image. The software checks your hardware and software configuration as well as the bandwidth of your Internet connection and uses this to determine the best possible transmission value.`,
  LINK_TEXT_CAM_SETTINGS: `{account} - Settings - Livecam`,
  LIVECAM_SETTING_CONTENT_STEP_4_1_CONTENT: `The resolution is specified as width x height in pixels, you can see the aspect ratio of the resolution (4:3 or 16:9) in brackets.`,
  LIVECAM_SETTING_CONTENT_STEP_4_2_CONTENT: `If black bars are displayed left/right or top/bottom in the preview image, you must adjust the video resolution, because the bars reduce the maximum possible video display for the user.`,
  LIVECAM_SETTING_CONTENT_STEP_4_3_CONTENT: `Now you select your microphone and have already taken the first steps.`,
  LIVECAM_SETTING_CONTENT_STEP_5: `Prices`,
  LIVECAM_SETTING_CONTENT_STEP_5_CONTENT: `Under {link} you set your prices. The user will be charged your desired price per minute. Set your prices carefully and don't change them too often, as this could annoy your customers.`,
  LINK_TEXT_PRICE: `{account} - Settings - Prices`,

  LIVECAM_SETTING_HEADLINE_4: `The show can begin`,
  LIVECAM_SETTING_CONTENT_4: `You alone decide whether it will be an erotic chat or a dildo show. However, be open to the suggestions and wishes of your customers and consider how you can include them in your show.`,
  LIVECAM_SETTING_CONTENT_4_1: `Take your time and prepare for your show in peace. That's the only way you can be your best. Invest time in styling yourself and looking like you're going on a first date. A positive attitude and a smile definitely contribute to a successful livecam show.`,
  LIVECAM_SETTING_CONTENT_STEP_6: `Wardrobe`,
  LIVECAM_SETTING_CONTENT_STEP_6_CONTENT: `Besides lingerie, you can also consider other clothing items. For example, you can wear dresses, skirts, pants, or tops and switch them up depending on the mood and theme of your show. The combination of different clothes can also help the customer stay longer in your livecam show. Remember that accessories and shoes play an important role in your outfit. They give your look that certain something extra.`,
  LIVECAM_SETTING_CONTENT_STEP_6_1_CONTENT: `It's important that you take care when choosing your outfits and put together something that reflects your style and personality. If you feel comfortable in your outfit, you can appear confident and relaxed in your livecam show.`,
  LIVECAM_SETTING_CONTENT_STEP_7: `Toys`,
  LIVECAM_SETTING_CONTENT_STEP_7_CONTENT: `If you want to use toys in your livecam show, keep them close by to avoid interruptions that hurt the mood. You can also use different toys and give your customers the opportunity to choose which one they would like to see.`,
  LIVECAM_SETTING_CONTENT_STEP_7_1_CONTENT: `<span class="bold">Extra tip:</span> use toys from Lovense and offer your customers a special interactive experience! Allow your customers to interact with you directly by controlling your toy for coins. This creates even more excitement and immediately brings you additional income in your livecam show.`,
  LIVECAM_SETTING_CONTENT_STEP_8: `Launch and Communication`,
  LIVECAM_SETTING_CONTENT_STEP_8_CONTENT: `As soon as you are ready, start your livecam show. To do this, go to your {link} {icon}. In the menu under the item "Features" you will find the button to start your stream.`,
  LIVECAM_SETTING_CONTENT_STEP_8_1_CONTENT: `If a customer enters your livecam, an announcement will be heard and "New" will be displayed next to the user's name in Messenger. Greet each customer with either a nice smile and wave at the camera or a canned text (F-keys) to make customers feel welcome and valued.`,
  LIVECAM_SETTING_CONTENT_STEP_8_2_CONTENT: `<span class="bold">Tip:</span> You can switch your microphone on and off above your cam image. If the sound is switched on, all customers who are currently in your show will hear you. However, the users do not see each other.`,
};

