import { uuid } from '../../utils';
import api from '../../utils/api';
import { PREFERENCES_ADD } from '../actions';

export default (e) => async (dispatch, getState) => {
  const keys = Object.keys(e);
  if (keys?.length !== 1) return;
  const value = e[keys[0]];
  const key = parseInt(keys[0].substring(1)) - 1;
  const payload = {
    value,
    key,
  };

  const { preferences } = getState();

  const functionkeys = preferences.functionkeys.map((key, index) =>
    index === payload.key ? payload.value : key
  );

  const data = {
    ...preferences,
    functionkeys,
  };

  try {
    await api.put('/user/preferences', { data });

    dispatch({
      type: PREFERENCES_ADD,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PREFERENCES_ADD,
      payload: getState().preferences,
      error: true,
      meta: { stamp: uuid() },
    });

    console.log({ functionKeyChangeError: error });
  }
};
