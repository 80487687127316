import { user as userUtils } from '../../utils';
import {
  START_TIMER_ONLINE_STATUS,
  STOP_TIMER_ONLINE_STATUS,
} from '../actions';
import { getOnlineStatus } from '.';

let timer;
const ONLINE_STATUS_INTERVAL = 120000;
const start = () => (dispatch, getState) => {
  if (!getState().onlineStatus.timerRunning) {
    console.log('online status timer start');
    dispatch({
      type: START_TIMER_ONLINE_STATUS,
    });
  }
  timer = setTimeout(() => {
    if (!userUtils.userLoggedInAndConfirmed(getState())) {
      return dispatch(stop());
    }
    if (
      window?.location?.pathname.includes('/multi-messenger') ||
      window?.location?.pathname.includes('/statistics/top-customers')
    ) {
      dispatch(getOnlineStatus());
    }
    dispatch(start());
  }, ONLINE_STATUS_INTERVAL);
};

const stop = () => (dispatch, getState) => {
  if (timer) {
    if (getState().onlineStatus.timerRunning) {
      console.log('online status timer stop');
      dispatch({
        type: STOP_TIMER_ONLINE_STATUS,
      });
    }
    clearTimeout(timer);
    timer = null;
  }
};

export default { start, stop };
