import { connect } from 'react-redux';

import { user as userUtils } from '../../../../utils';
import UserProfileTurnover from './UserProfileTurnover';

const mapStateToProps = (state) => {
  const activeUser = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);
  const isSupport =
    userUtils.isAdmin(activeUser) || activeUser?.flags?.includes('is_support');
  if (activeUser?.profile?.turnover && !isSupport) {
    return {
      ...activeUser?.profile?.turnover,
      isSupport,
      largeLoaded: activeUser?.profile?.info?.largeLoaded,
    };
  }
  return {
    isSupport,
  };
};

export default connect(mapStateToProps)(UserProfileTurnover);
