import io from 'socket.io-client';

import { socketService } from '../actions';

let listener = {};
let socket = null;

const init = (url, options, dispatch) => {
  if (socket) {
    socket.removeAllListeners();
    listener = {};
  }
  socket = io(url, options);
  const events = socketService.events;
  for (let action in events) {
    listener[action] = (data) => dispatch(events[action](data));
    console.log(`set socketService action ${action}`);
    socket.on(action, listener[action]);
  }
};

const emit = (msg, data, cb) => {
  if (socket !== null) {
    socket.emit(msg, data, cb);
  }
};

const disconnect = () => {
  if (socket !== null) {
    socket.disconnect();
  }
};

const connected = () => {
  if (socket !== null) {
    return socket.connected;
  }
  return false;
};

export default {
  emit,
  init,
  disconnect,
  connected,
};
