import { connect } from 'react-redux';

import {
  camResizing,
  toggleCamView,
  updateLargeScreenStreamingWindow,
} from '../../../../actions';
import { user as userUtils } from '../../../../utils';
import Streaming from './Streaming';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    cam2cam,
    view,
    cam: {
      selectedResolution: { value: resolutionValue },
    },
    streaming: { camInitialized },
  } = state;
  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
  const streams = cam2cam;
  const stream =
    activeUser?.userId && streams[activeUser?.userId]
      ? streams[activeUser?.userId]
      : null;
  const running = !!stream;
  const sideBar = state.view.sidebar;
  return {
    ...view,
    running,
    camInitialized,
    resolutionValue,
    sideBar,
  };
};

const mapDispatchToProps = {
  toggleCamView,
  updateLargeScreenStreamingWindow,
  camResizing,
};

export default connect(mapStateToProps, mapDispatchToProps)(Streaming);
