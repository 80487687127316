import { user as userUtils } from '../../../utils';
import { stream } from '../..';

let timer;

const start = (management) => () => (dispatch, getState) => {
  const {
    checkuser: { interval },
  } = getState();

  if (!timer) {
    timer = setInterval(() => {
      const {
        camUser: { camUsers },
        sender: { upgradeStarted },
      } = getState();

      if (!management.connected()) {
        return dispatch(stop());
      }

      const getId = (item) => item.id;

      const user = camUsers
        .filter((user) => !userUtils.isAdmin(user))
        .map(getId);
      const admin = camUsers
        .filter((user) => userUtils.isAdmin(user))
        .map(getId);

      if (user.length > 0) {
        if (!upgradeStarted) {
          management.emit('checkUser', { user });
        }
      }
      if (admin.length > 0) {
        management.emit('checkAdmin', { admin });
      }
      dispatch(stream.checkStop());
    }, interval);
  }
};

const stop = () => () => {
  clearInterval(timer);
  timer = null;
};

export default (management) => ({
  start: start(management),
  stop,
});
