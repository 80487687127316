import { connect } from 'react-redux';

import MultiMessenger from './MultiMessenger';

const mapStateToProps = (state) => ({
  unconfirmed: state.user?.account?.state === 'unconfirmed',
  nickname: state.user?.account?.nickname,
  locale: state.intl.locale,
});
export default connect(mapStateToProps)(MultiMessenger);
