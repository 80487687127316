export const fullVirtualGiftsTableTitles = [
  { title: '', width: '100px', position: 'left' },
  { title: 'TABLE_TITLE_NAME', width: 'calc(50% - 100px', position: 'left' },
  { title: 'TABLE_TITLE_LAST_FROM', width: '20%', position: 'left' },
  { title: 'TABLE_TITLE_DATE', width: '10%', position: 'left' },
  { title: 'TABLE_TITLE_PRICE', width: '10%', position: 'right' },
  { title: 'TABLE_TITLE_NUMBER', width: '10%', position: 'right' },
];

export const mobileVirtualGiftsTableTitles = [
  { title: '', width: '100px', position: 'left' },
  {
    title: 'TABLE_TITLE_LAST_FROM',
    width: 'calc(100% - 240px)',
    position: 'left',
  },
  { title: 'TABLE_TITLE_PRICE', width: '140px', position: 'right' },
];
