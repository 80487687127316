import { sound } from '../../actions';
import { AUDIO_FILES } from '../../constants/sound';
import { roomType as roomTypeUtils } from '../../utils';
import { AUDIO_PLAY } from '../actions';
// one global instance for audioContext, max instances are 6
let audioContext = null;
// for Safari and Browser that doesnt have WebAPI support
const AudioContext = window.AudioContext || window.webkitAudioContext || false;

const play = (audio, volume, dispatch, type) => {
  if (AudioContext) {
    const gainNode = audioContext.createGain();
    const source = audioContext.createMediaElementSource(audio);

    if (!!gainNode?.gain) {
      gainNode.gain.value = volume;
    }

    source.connect(gainNode);
    gainNode.connect(audioContext.destination);
  }
  audio
    .play()
    .then(() => {})
    .catch((e) => {
      if (e && e.name === 'NotAllowedError') {
        dispatch(sound.confirmPlay(type));
      } else {
        console.log(e);
      }
    });
};

export default (type) => (dispatch, getState) => {
  const {
    preferences: { volume },
    sender: { roomTypes },
  } = getState();

  if (roomTypeUtils.isEvent(roomTypes)) {
    return;
  }

  const file = AUDIO_FILES[type];
  if (file) {
    const audio = new Audio(file);
    audio.crossOrigin = 'anonymous';
    // @see https://stackoverflow.com/questions/43794356/html5-volume-increase-past-100
    if (audioContext === null && AudioContext) {
      audioContext = new AudioContext();
    }

    if (audioContext.state === 'suspended') {
      audioContext.resume().then(() => {
        console.log('AudioContext was suspended');
        play(audio, volume, dispatch, type);
      });
    } else {
      console.log('AudioContext was not suspended');
      play(audio, volume, dispatch, type);
    }

    dispatch({
      type: AUDIO_PLAY,
      payload: { type },
    });
  }
};
