import { default as startEvent } from './event/start';
import { default as stopEvent } from './event/stop';
import { default as start } from './start';
import { default as stop } from './stop';

export default {
  startEvent,
  stopEvent,
  start,
  stop,
};
