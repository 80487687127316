import { connect } from 'react-redux';

import {
  deleteDiaryEntry,
  spinner,
  updateActiveGallery,
} from '../../../../actions';
import MyDiary from './MyDiary';

const mapStateToProps = (state) => ({
  activeGalleryId: state.galleries.active?.id,
  language: state.user.account?.language,
  askDeleteDiaryEntryConfirmed: state.galleries.askDeleteDiaryEntryConfirmed,
  askDeleteDiaryEntryConfirmedAlert:
    state.galleries.askDeleteDiaryEntryConfirmedAlert,
  askDeleteDiaryEntryId: state.galleries.askDeleteDiaryEntryId,
});

const mapDispatchToProps = {
  deleteDiaryEntry,
  spinner,
  updateActiveGallery,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyDiary);

