export function generateCategoriesOptions(intl, categories, existingCats) {
  let options = categories
    .filter((category) => !existingCats.includes(category))
    .map((category) => {
      // - is not allowed in the translation files so _ must be used, only for display purposes
      const modifiedCategory = category.replace('-', '_');
      return {
        value: category,
        label: intl.formatMessage({ id: modifiedCategory }),
      };
    });
  options = options.sort((a, b) => a.label.localeCompare(b.label));
  options.unshift({
    label: intl.formatMessage({ id: 'PLEASE_SELECT' }),
    value: '',
  });
  return options;
}

export function generateDocumentsOptions(intl, data, selectedDocuments) {
  const options = data
    .filter(
      (document) =>
        !selectedDocuments.find((d) => d.id === document.id) &&
        document.mode !== 'main'
    )
    .map((document) => {
      return { label: document.description, value: document.id };
    });

  options.unshift({
    label: intl.formatMessage({ id: 'PLEASE_SELECT' }),
    value: '',
  });
  return options;
}
