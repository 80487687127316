import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';

import closeIcon from '../../../assets/img/svg/close.svg';
import Button from '../../Button/Button';

function FadingAlert({
  onClose,
  hideClose = false,
  message,
  type,
  level,
  stamp,
  loading,
  intl,
  scope = 'livecam',
  expireTime,
  fadingAlertType,
}) {
  const hasHeadline = message.headline || message.headlineId;
  const cssClasses = [
    'alert',
    `alert-${type.toLowerCase()}`,
    `alert-${level.toLowerCase()}`,
    `alert-${scope}`,
    `alert-${fadingAlertType}`,
  ];
  if (hasHeadline) {
    cssClasses.push('has-headline');
  }
  if (loading) {
    cssClasses.push('loading');
  }

  const [alertVisible, setAlertVisible] = useState(true);

  useEffect(() => {
    let timer;
    if (expireTime) {
      timer = setTimeout(() => {
        setAlertVisible(false);
        onClose(stamp);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [expireTime, onClose, stamp]);

  return (
    alertVisible && (
      <div className={cssClasses.join(' ')}>
        {hasHeadline ? (
          <h3>
            {message.translate && message.headlineId
              ? intl.formatMessage(
                  { id: message.headlineId },
                  message.headlineValues
                )
              : message.headline}
          </h3>
        ) : (
          ''
        )}
        <p>
          {message.translate
            ? intl.formatMessage({ id: message.id }, message.values)
            : message.message}{' '}
        </p>
        {!hideClose && !expireTime && (
          <Button
            type="button"
            classNamesOnly="close-btn"
            icon={closeIcon}
            onClick={() => onClose(stamp)}
            intlTranslate={false}
          />
        )}
      </div>
    )
  );
}

export default injectIntl(FadingAlert);

