import { sendSystemChatMessage } from '../../../../actions';
import { user as userUtils } from '../../../../utils';
import { SENDER_ASK_UPGRADE_DENIED } from '../../../actions';

export default (payload) => (dispatch, getState) => {
  const user = userUtils.get(getState().camUser.camUsers, payload.userId);
  if (!user || !user.visible || !user.name) {
    return;
  }
  dispatch(
    sendSystemChatMessage({
      id: 'systemMessage.exclusiveDenied',
      highlight: true,
      userId: payload.userId,
      values: { user: user.name },
    })
  );
  dispatch({
    type: SENDER_ASK_UPGRADE_DENIED,
    payload,
  });
};
