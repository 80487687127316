import React from 'react';
import { FormattedMessage } from 'react-intl';

import DiaryDE from '../../../../../assets/img/wiki/wiki_diary_de.jpg';
import DiaryEN from '../../../../../assets/img/wiki/wiki_diary_en.jpg';
import BackButton from '../../../../BackButton';

function Diary({ locale, onBackButtonClicked }) {
  return (
    <div className="wiki-content wiki-diary-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="DIARY_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="DIARY_INTRO" />
      </p>
      <p>
        <FormattedMessage id="DIARY_INTRO_1" />
      </p>

      <h2 className="headline">
        <FormattedMessage id="DIARY_CONTENT" />
      </h2>
      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive border-light-gray"
            src={DiaryEN}
            alt="Diary"
          />
        ) : (
          <img
            className="img-responsive border-light-gray"
            src={DiaryDE}
            alt="Tagebuch"
          />
        )}
      </div>
      <p>
        <FormattedMessage id="DIARY_CONTENT_1" />
      </p>
      <p>
        <FormattedMessage id="DIARY_CONTENT_2" />
      </p>
      <h2 className="headline">
        <FormattedMessage id="DIARY_CONTENT_3" />
      </h2>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="DIARY_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="DIARY_CONTENT_STEP_1_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="DIARY_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p className="has-margin-bottom">
            <FormattedMessage id="DIARY_CONTENT_STEP_2_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="DIARY_CONTENT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="DIARY_CONTENT_STEP_3_CONTENT" />
          </p>
        </div>
      </div>

      <p className="list-headline">
        <FormattedMessage id="DIARY_CONTENT_4" />
      </p>
      <p>
        <FormattedMessage id="DIARY_CONTENT_5">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p className="list-headline">
        <FormattedMessage id="DIARY_CONTENT_6" />
      </p>
      <p>
        <FormattedMessage id="DIARY_CONTENT_7">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
    </div>
  );
}
export default Diary;
