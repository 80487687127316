import './Item.scss';

import classNames from 'classnames';
import React from 'react';
import { ReactSVG } from 'react-svg';

import checkIcon from '../../../../assets/img/svg/check.svg';
import errorIcon from '../../../../assets/img/svg/error.svg';

function Item({
  title,
  icon,
  color = 'gray',
  isCurrentItem,
  isError,
  disabled,
  isSuccess,
  onClick,
  pendingState,
}) {
  const itemColor =
    pendingState || (disabled && isSuccess)
      ? 'green'
      : disabled
      ? 'gray'
      : isError
      ? 'red'
      : isCurrentItem
      ? 'blue'
      : isSuccess
      ? 'green'
      : color;

  const iconClassNames = classNames('icon', {
    [itemColor]: itemColor,
  });
  const clickable = typeof onClick === 'function';
  const className = classNames('step-item', {
    clickable,
  });
  return (
    <div className={className} {...(clickable ? { onClick } : {})}>
      <div className={iconClassNames}>
        <ReactSVG
          src={
            isCurrentItem
              ? icon
              : isSuccess
              ? checkIcon
              : isError
              ? errorIcon
              : icon
          }
        />
      </div>

      <div className="title">{title}</div>
    </div>
  );
}

export default Item;

