import './RequestSelect.scss';

import classNames from 'classnames';
import { useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import arrowDownIcon from '../../../assets/img/svg/arrow-down.svg';
import checkCircleIcon from '../../../assets/img/svg/check-circle-outline.svg';
import trashCan from '../../../assets/img/svg/delete.svg';
import Button from '../../Button/Button';
import {useSelector} from "react-redux";

function RequestSelect({
  id,
  name,
  options,
  defaultValue = '',
  defaultValueOption,
  intlTranslate,
  updateOptions,
  existingData,
  index,
  outsideChangeHandler,
  disabled,
  trimData,
  noIsSaved,
  initIsSaved = false,
  galleryInfo = false,
  deleteButton,
  ...otherProps
}) {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext();
  const { change, isDataLoading } = updateOptions;
  const [isSaved, setIsSaved] = useState(false);
  const timeoutRef = useRef(null);
  const lastCategories = useSelector(
      (state) => state.galleries?.lastActive?.categories
  );

  const selectOptions = options.map((o, i) => {
    const translatedLabel =
      intlTranslate === false ? o.label : intl.formatMessage({ id: o.label });
    return o.label === 'break_line_element' ? (
      <option disabled key="separator-line">
        ─────────────────────
      </option>
    ) : (
      <option value={o.value} key={`${o.value}.${i}`}>
        {translatedLabel}
      </option>
    );
  });

  const selectClassName = classNames('select-container', {
    disabled,
  });

  function handleSelect(event) {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      setIsSaved(false);
    }
    if (outsideChangeHandler) {
      outsideChangeHandler(event.target.value)
        .then(() => {
          if (!noIsSaved) {
            setIsSaved(true);
            timeoutRef.current = setTimeout(() => {
              setIsSaved(false);
            }, 1500);
          }
        })
        .catch((e) => {
          setIsSaved(false);
        });
    } else {
      if (!isDataLoading) {
        let newData = event.target.value;
        if (existingData) {
          if (index >= existingData.length) {
            newData = existingData.concat([event.target.value]);
          } else {
            newData = [...existingData];
            newData[index] = event.target.value;
          }
        }
        if (trimData) {
          newData = newData.filter((element) => element);

          if (newData.length === 0) {
            newData = [options[1].value];
          }
        }
        change({ [name]: newData });
        setFieldValue(name, newData);

        // De-Morgan
        if (!noIsSaved && !galleryInfo) {
          setIsSaved(true);
          timeoutRef.current = setTimeout(() => {
            setIsSaved(false);
          }, 1500);
        }
      }
    }
  }
  function handleDelete() {
    let toDelete = otherProps.value;
    let newData = existingData.filter((element) => element !== toDelete);
    if (newData.length === 0) {
      newData = [options[1].value];
    }
    change({ [name]: newData });
  }

  useEffect(() => {
    if (initIsSaved) {
      setIsSaved(true);
      timeoutRef.current = setTimeout(() => {
        setIsSaved(false);
      }, 1500);
    }
  }, [initIsSaved, lastCategories]);

  // Reset isSaved after 1.5 seconds
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      className={classNames(selectClassName, {
        'has-success': isSaved,
      })}
    >
      <select
        id={id || name}
        name={id || name}
        {...otherProps}
        className={selectClassName}
        onChange={(event) => handleSelect(event)}
        disabled={disabled}
      >
        {options.length > 0 ? (
          selectOptions
        ) : (
          <option value="" disabled>
            {defaultValueOption}
          </option>
        )}
      </select>
      {isSaved && (
        <div
          className={classNames('icon-wrapper', {
            'has-success': isSaved,
            deleteField: deleteButton,
          })}
        >
          <ReactSVG src={checkCircleIcon} />
        </div>
      )}
      <div
        className={classNames('arrow-down-icon', {
          'has-success': isSaved,
          deleteField: deleteButton,
        })}
      >
        <ReactSVG src={arrowDownIcon} />
      </div>
      {deleteButton && existingData.length > 1 && (
        <Button
          variant="icon-only"
          className="btn-delete"
          onClick={handleDelete}
          intlTranslate={false}
          icon={trashCan}
        />
      )}
    </div>
  );
}

export default RequestSelect;
