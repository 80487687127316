import React from 'react';

import ToggleButton from '../../Forms/ToggleButton/ToggleButton';

const ShowSendernameCheckbox = ({ enabled, onChange }) => (
  <ToggleButton
    data={enabled}
    click={() => onChange(!enabled)}
    label="showSendername.title"
    name="cc"
  />
);

export default ShowSendernameCheckbox;
