import React from 'react';
import { injectIntl } from 'react-intl';

import closeIcon from '../../../assets/img/svg/close.svg';
import Button from '../../Button/Button';

class CloseAlert extends React.Component {
  render() {
    const {
      onClose,
      hideClose = false,
      message,
      type,
      level,
      stamp,
      loading,
      intl,
      scope = 'livecam',
    } = this.props;

    const hasHeadline = message.headline || message.headlineId;
    const cssClasses = [
      'alert',
      `alert-${type.toLowerCase()}`,
      `alert-${level.toLowerCase()}`,
      `alert-${scope}`,
    ];
    if (hasHeadline) {
      cssClasses.push('has-headline');
    }
    if (loading) {
      cssClasses.push('loading');
    }
    return (
      <div className={cssClasses.join(' ')}>
        {hasHeadline ? (
          <h3>
            {message.translate && message.headlineId
              ? intl.formatMessage(
                  { id: message.headlineId },
                  message.headlineValues
                )
              : message.headline}
          </h3>
        ) : (
          ''
        )}
        <p>
          {message.translate
            ? intl.formatMessage({ id: message.id }, message.values)
            : message.message}{' '}
          {message.translate && message.links ? (
            <span className="cookielinks">
              {message.links.link_support && (
                <a
                  target="_blank"
                  href={message.links.link_support}
                  rel="noreferrer"
                >
                  {intl.formatMessage({ id: 'SUPPORT_LINK_TEXT' })}.
                </a>
              )}
              {message.links.link_chrome && (
                <a
                  target="_blank"
                  href={message.links.link_chrome}
                  rel="noreferrer"
                >
                  Chrome
                </a>
              )}
              {message.links.link_firefox && (
                <a
                  target="_blank"
                  href={message.links.link_firefox}
                  rel="noreferrer"
                >
                  Firefox
                </a>
              )}
              {message.links.firefox_android && (
                <a
                  target="_blank"
                  href={message.links.firefox_android}
                  rel="noreferrer"
                >
                  Firefox Android
                </a>
              )}
              {message.links.firefox_iphone && (
                <a
                  target="_blank"
                  href={message.links.firefox_iphone}
                  rel="noreferrer"
                >
                  Firefox IPhone
                </a>
              )}
              {message.links.link_edge && (
                <a
                  target="_blank"
                  href={message.links.link_edge}
                  rel="noreferrer"
                >
                  Microsoft Edge
                </a>
              )}
              {message.links.safari_mac && (
                <a
                  target="_blank"
                  href={message.links.safari_mac}
                  rel="noreferrer"
                >
                  Safari Mac
                </a>
              )}
            </span>
          ) : null}
        </p>
        {!hideClose && (
          <Button
            type="button"
            classNamesOnly="close-btn"
            icon={closeIcon}
            onClick={() => onClose(stamp)}
            intlTranslate={false}
          />
        )}
      </div>
    );
  }
}

export default injectIntl(CloseAlert);
