import { connect } from 'react-redux';

import { spinner } from '../../../../actions';
import LivecamOverview from './LivecamOverview';

const mapStateToProps = (state) => ({
  user: state.user,
  isSubmenuShown: state.view.sidebar,
});
const mapDispatchToProps = { spinner };
export default connect(mapStateToProps, mapDispatchToProps)(LivecamOverview);
