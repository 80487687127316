import { connect } from 'react-redux';

import { spinner } from '../../../../actions';
import Ignored from './Ignored';

const mapDispatchToProps = {
  spinner,
};

export default connect(null, mapDispatchToProps)(Ignored);
