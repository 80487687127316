import './AccountDetails.scss';

import useAxios from 'axios-hooks';
import { useFormikContext } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import saveIcon from '../../../../assets/img/svg/save.svg';
import Button from '../../../Button/Button';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import Input from '../../../Forms/Input/Input';
import Password from '../../../Forms/Password/Password';
import StandaloneFieldWithErrors from '../../../Forms/StandaloneFieldWithErrors/StandaloneFieldWithErrors';

function AccountDetails({ spinner = () => {} }) {
  const intl = useIntl();
  const { values } = useFormikContext();

  const [passwords, setPasswords] = useState({
    password: '',
    repeatPassword: '',
    currentPassword: '',
  });
  const noPasswordError = {
    password: null,
    repeatPassword: null,
    currentPassword: null,
  };
  const [passwordErrors, setPasswordErrors] = useState(noPasswordError);
  const hasPasswordError = () => {
    return (
      passwordErrors.password ||
      passwordErrors.repeatPassword ||
      passwordErrors.currentPassword
    );
  };
  const [passwordNotification, setPasswordNotification] = useState(null);

  const [{ loading: isAccountDetailsLoading }, accountDetailsRequest] =
    useAxios(
      {
        url: '/user/account',
        method: 'PUT',
        data: {
          nickname: values.nickname,
          password: passwords.password,
          password_confirmation: passwords.repeatPassword,
          current_password: passwords.currentPassword,
        },
      },
      {
        manual: true,
      }
    );

  function sendAccountDetailsRequest() {
    let hasError = false;
    if (!passwords.password.length || passwords.password.length < 6) {
      hasError = true;
      setPasswordErrors((prevState) => ({
        ...prevState,
        password: intl.formatMessage({
          id: 'PASSWORD_CHANGE_VALIDATION_MESSAGE_LENGTH',
        }),
      }));
    }
    if (
      !passwords.repeatPassword.length ||
      passwords.password !== passwords.repeatPassword
    ) {
      hasError = true;
      setPasswordErrors((prevState) => ({
        ...prevState,
        repeatPassword: intl.formatMessage({
          id: 'PASSWORD_CHANGE_VALIDATION_MESSAGE_REPEAT',
        }),
      }));
    }
    if (hasError) {
      return;
    }
    if (!isAccountDetailsLoading) {
      spinner(true);
      accountDetailsRequest()
        .then((response) => {
          spinner(false);
          setPasswords({
            password: '',
            repeatPassword: '',
            currentPassword: '',
          });
          setPasswordErrors(noPasswordError);
          setPasswordNotification(
            intl.formatMessage({ id: 'PASSWORD_CHANGE_SUCCESS_LABEL' })
          );
        })
        .catch((error) => {
          if (error.response.data.errors.current_password) {
            setPasswordErrors((prevState) => ({
              ...prevState,
              currentPassword: intl.formatMessage({
                id: 'PASSWORD_CHANGE_ERROR_CURRENT_PASSWORD',
              }),
            }));
          } else if (error.response.status === 500) {
            let errorMessage = intl.formatMessage({
              id: 'PASSWORD_CHANGE_ERROR_LABEL',
            });
            if (error.response.data?.translatedError) {
              errorMessage = intl.formatMessage({
                id: error.response.data?.translatedError,
              });
            }
            setPasswordErrors((prevState) => ({
              ...prevState,
              password: errorMessage,
            }));
          } else {
            setPasswordErrors((prevState) => ({
              ...prevState,
              password: intl.formatMessage({
                id: 'PASSWORD_CHANGE_ERROR_LABEL',
              }),
            }));
          }
          spinner(false);
        });
    }
  }

  const handlePasswordInputChange = (event) => {
    setPasswords((prevState) => {
      const newState = {
        ...prevState,
        [event.target.name]: event.target.value,
      };
      setPasswordErrors(noPasswordError);
      setPasswordNotification(null);
      return newState;
    });
  };

  return (
    <div className="account-details-wrapper">
      <h1 className="headline">
        <FormattedMessage id="ACCOUNT_DETAILS_HEADLINE" />
      </h1>

      <div className="data-columns">
        <form className="account-details">
          <FieldWithErrors
            as={Input}
            name={'nickname'}
            label="FIELD_NICKNAME"
            disabled={true}
          />
          <FieldWithErrors
            as={Input}
            name={'email'}
            label="FIELD_EMAIL"
            disabled={true}
          />
        </form>
        {!passwordNotification ? (
          <>
            <form className="reset-password">
              <StandaloneFieldWithErrors
                as={Password}
                label="FIELD_CURRENT_PASSWORD"
                name={'currentPassword'}
                value={passwords.currentPassword}
                onChange={(event) => handlePasswordInputChange(event)}
                error={passwordErrors.currentPassword}
              />
              <StandaloneFieldWithErrors
                as={Password}
                label="FIELD_NEW_PASSWORD"
                name={'password'}
                value={passwords.password}
                onChange={(event) => handlePasswordInputChange(event)}
                error={passwordErrors.password}
              />
              <StandaloneFieldWithErrors
                as={Password}
                label="FIELD_REPEAT_PASSWORD"
                name={'repeatPassword'}
                value={passwords.repeatPassword}
                onChange={(event) => handlePasswordInputChange(event)}
                error={passwordErrors.repeatPassword}
              />
              <div className="form-group">
                <span className="column" />
                <Button
                  label="SAVE"
                  className="btn-form"
                  icon={saveIcon}
                  addEmptySpan={true}
                  disabled={hasPasswordError()}
                  onClick={() => sendAccountDetailsRequest()}
                />
              </div>
            </form>
          </>
        ) : (
          <div className="password-message password-success">
            {passwordNotification}
          </div>
        )}
      </div>
    </div>
  );
}

export default AccountDetails;

