import {
  CHAT_HEIGHT_CHANGE,
  CHAT_PAGE_INCREMENT,
  FETCH_ALL_MESSAGES,
  GAME_INVITATION_STATUS,
  GAME_SESSION_STATUS,
  IGNORE_USER,
  MANAGEMENT_EVENTS_ADMIN_REMOVE,
  MANAGEMENT_EVENTS_MESSAGE_ADMIN_ADD,
  MANAGEMENT_EVENTS_MESSAGE_USER_ADD,
  MANAGEMENT_EVENTS_USER_REMOVE,
  MANAGEMENT_MESSAGE_SENDER_SEND,
  MANAGEMENT_MESSAGE_SENDER_SEND2ALL,
  MANAGEMENT_USER_KICK,
  MENU_CHAT_TOGGLE,
  MENU_PAYTTACHMENTS_TOGGLE,
  MESSAGE_CHAT_REMOVE,
  MESSAGE_CHAT_SEND,
  MESSAGE_CHAT_SYSTEM_SEND,
  MESSAGE_RECEIVED,
  MESSAGE_SENT,
  REGARDS_ADD,
  RESET_NEW_MESSAGES,
  SAVE_FETCH_MESSAGES_EVENT,
  SELECT_USER,
  SENDER_LOGOUT,
  SENDER_TYPING,
  SEX_ICONS_ADD,
  STREAM_STOP,
  USER_LOGOUT,
} from '../actions/actions';
import { MESSAGE_TYPE_SENDER, MESSAGE_TYPE_SENDER_2ALL } from '../constants';
import { TYPE_DATING } from '../constants/user';
import { objectUtils, user as userUtils } from '../utils';
import {
  addDialogMessage,
  addDialogMessages,
  addMessage,
  createChatMessage,
  createGameMessage,
  createSystemDialogMessage,
  createSystemMessage,
  failedDialogMessage,
  removeDialogMessage,
} from '../utils/chat';

const initialState = {
  typingDelay: 300,
  maxMessages: 500,
  height: 300,
  messages: [],
  selectedUser: 'system',
  kickUser: {},
  userKickable: false,
  isAdmin: false,
  startTime: 0,
  text: '',
  chatMenuOpen: false,
  dialogmessages: [],
  isMessageUser: false,
  initialized: false,
  messageDoneEvtId: null,
  payttachmentsMenuOpen: false,
  containsNonIcebreakerMessages: false,
  fskLevels: { sexIcons: [], regards: [] },
  newMessage: 0,
  page: 0,
};

const chat = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGOUT:
    case SENDER_LOGOUT:
      return {
        ...state,
        chatMenuOpen: initialState.chatMenuOpen,
      };
    case STREAM_STOP:
      return { ...state, chatMenuOpen: initialState.chatMenuOpen };
    case MENU_CHAT_TOGGLE:
      return { ...state, chatMenuOpen: !state.chatMenuOpen };
    case MENU_PAYTTACHMENTS_TOGGLE:
      return { ...state, payttachmentsMenuOpen: action.payload };
    case MANAGEMENT_USER_KICK: {
      if (action.meta && action.meta.start) {
        return {
          ...state,
          kickUser: action.payload,
        };
      }
      return state;
    }
    case MANAGEMENT_EVENTS_ADMIN_REMOVE:
    case MANAGEMENT_EVENTS_USER_REMOVE: {
      if (action.meta?.nextActiveUser?.userId === 'system') {
        return { ...state, selectedUser: 'system' };
      }
      return state;
    }
    case MANAGEMENT_EVENTS_MESSAGE_ADMIN_ADD:
    case MANAGEMENT_EVENTS_MESSAGE_USER_ADD:
      return addMessage(state, createChatMessage(action.payload));
    case MESSAGE_CHAT_SEND:
      if (
        action.error ||
        (state.dialogmessages.find((m) => m.id === action.payload.id) &&
          action.payload?.payload?.tempId) ||
        (action?.meta?.selectedCamUserId !== action?.meta?.user?.userId &&
          state.messages.find((m) => m.id === action.payload.id))
      ) {
        const newState = { ...state };

        if (
          action.error &&
          action?.payload !== 'messages_limit_reach' &&
          state.dialogmessages.find((m) => m.id === action.id)
        ) {
          return {
            ...failedDialogMessage(newState, action.id, action.message),
          };
        } else {
          return {
            ...removeDialogMessage(newState, action.id),
          };
        }
      }

      const {
        payload: {
          time,
          id,
          body,
          recipients,
          sender,
          payload,
          payttachment_id,
        },
        meta: { user, selectedCamUserId, senderName, loading },
      } = action;

      const message = {
        type: MESSAGE_TYPE_SENDER,
        time,
        id,
        payload: { tempId: undefined, resendId: undefined },
        message: body,
        recipient: recipients[0],
        sender,
        from: senderName,
        to: user.name,
        userColor: user.color,
        userId:
          user.userId !== selectedCamUserId ? selectedCamUserId : user.userId,
      };

      if (payload?.type === 'payttachment') {
        message.payload = {
          type: 'payttachment',
          payttachment: { id: payttachment_id, ...payload.payttachment },
          loading: loading,
        };
      }
      if (payload?.type === 'voice') {
        message.payload = {
          type: 'voice',
          voiceData: payload?.voiceData,
          id: payload?.voiceData?.id,
          loading: loading,
        };
      }
      let newState = { ...state };

      if (user.partner !== selectedCamUserId) {
        message.payload.tempId = payload?.tempId ?? null;
        newState = addMessage(state, message);
      }

      if (
        action.payload.attachment ||
        (action.payload.attachment_id && loading)
      ) {
        message.attachment = action.payload.attachment;
        message.payload.loading = loading;
        message.attachment_id = loading && action.payload?.attachment_id;
      }

      message.payload.tempId = payload?.tempId ?? null;
      message.payload.resendId = payload?.resendId ?? null;

      return {
        ...addDialogMessage(newState, message),
        initialized: true,
      };
    case CHAT_HEIGHT_CHANGE:
      return {
        ...state,
        height: action.payload,
      };
    case FETCH_ALL_MESSAGES:
      if (action.error) {
        return {
          ...state,
          initialized: true,
        };
      }
      return { ...addDialogMessages(state, action.payload), initialized: true };
    case CHAT_PAGE_INCREMENT: {
      return {
        ...state,
        page: state.page + 1,
      };
    }
    case GAME_INVITATION_STATUS: {
      if (!action?.meta?.isSelected || action?.payload?.status === 'accepted') {
        // When the invitation is accepted, it is not sure, that it has a session, so this will be
        // updated with the session update call
        return state;
      }
      const gameMessage = createGameMessage(action?.payload, action?.meta);
      if (!gameMessage) {
        return state;
      }
      let newState = { ...state };
      if (userUtils.isLivecamUserId(action?.meta?.userId)) {
        newState = addMessage(state, gameMessage);
      }
      return {
        ...addDialogMessage(newState, gameMessage),
        state,
      };
    }
    case GAME_SESSION_STATUS: {
      if (!action?.meta?.isSelected) {
        return state;
      }
      const gameMessage = createGameMessage(action?.payload, action?.meta);
      if (!gameMessage) {
        return state;
      }
      let newState = { ...state };
      if (userUtils.isLivecamUserId(action?.meta?.userId)) {
        newState = addMessage(state, gameMessage);
      }
      return {
        ...addDialogMessage(newState, gameMessage),
        state,
      };
    }
    case SELECT_USER: {
      // selectedUser is needed only for display,
      // in the user list the user has the active flag
      // with this flag the messages get rerendered with correct state
      const nextActiveUser = action.payload;
      const isSameUser = action.meta?.isSameUser;
      const game = action.meta?.game;
      let gameMessage;
      if (game) {
        gameMessage = createGameMessage(game, {
          userId: nextActiveUser.userId,
          from: action.meta.from,
          to: action.meta.to,
        });
      }
      return {
        ...state,
        selectedUser: nextActiveUser.userId,
        isMessageUser:
          !!nextActiveUser?.partner &&
          nextActiveUser?.type === 'dialog.fetched',
        startTime: nextActiveUser.streamingStartTime
          ? Date.parse(nextActiveUser.streamingStartTime)
          : 0,
        isAdmin: userUtils.isAdmin(nextActiveUser),
        userKickable: userUtils.isKickable(nextActiveUser),
        containsNonIcebreakerMessages: isSameUser
          ? state.containsNonIcebreakerMessages
          : false,
        page: 0,
        dialogmessages: isSameUser
          ? [
              ...state.dialogmessages.map((dialogmessage) => {
                return { ...dialogmessage, userId: nextActiveUser.userId };
              }),
            ]
          : gameMessage
          ? [gameMessage]
          : [],
        initialized:
          nextActiveUser?.type === TYPE_DATING || isSameUser || false,
      };
    }
    case MANAGEMENT_MESSAGE_SENDER_SEND: {
      if (action.payload && action.payload.message) {
        const message = {
          message: action.payload.message,
          type: MESSAGE_TYPE_SENDER,
          from: action.payload.senderName,
          to: action.payload.user.name,
          userColor: action.payload.user.color,
          userId: action.payload.user.userId,
        };
        return addMessage(state, createChatMessage(message));
      }
      return state;
    }
    case MANAGEMENT_MESSAGE_SENDER_SEND2ALL: {
      if (action.payload && action.payload.message) {
        const message = {
          message: action.payload.message,
          type: MESSAGE_TYPE_SENDER_2ALL,
          from: action.payload.senderName,
          to: '2all',
        };
        return addMessage(state, createChatMessage(message));
      }
      return state;
    }
    case SENDER_TYPING:
      if (action.payload && action.payload.event) {
        return { ...state, text: action.payload.text };
      }
      return state;
    case MESSAGE_CHAT_REMOVE: {
      if (action?.payload?.userId === state.selectedUser) {
        return {
          ...state,
          dialogmessages: state.dialogmessages.filter(
            (m) => m.id !== action?.payload?.id
          ),
        };
      }
      return state;
    }
    case MESSAGE_CHAT_SYSTEM_SEND: {
      let newState = state;
      if (
        action?.meta?.showInDialog &&
        action?.payload?.userId === state.selectedUser
      ) {
        newState = addDialogMessage(
          newState,
          createSystemDialogMessage(action.payload)
        );
      }
      return addMessage(
        newState,
        createSystemMessage(state.messages, action.payload)
      );
    }
    case MESSAGE_RECEIVED: {
      const {
        payload,
        meta: { selectedUser },
      } = action;

      if (
        payload.done &&
        state.messageDoneEvtId &&
        payload.evtid === state.messageDoneEvtId &&
        !payload.type
      ) {
        return {
          ...state,
          initialized: true,
          messageDoneEvtId: null,
        };
      }
      let newState = { ...state };

      if (action.payload?.payload?.lc_id && !action.payload.evtid.match(/@/)) {
        newState = {
          ...newState,
          ...addMessage(newState, action.payload),
        };
      }
      if (selectedUser === payload.userId) {
        let numberOfNewMessages = (state.newMessage || 0) + 1;
        newState = {
          ...newState,
          ...addDialogMessage(newState, payload),
          initialized: state.initialized || payload.done,
          newMessage: numberOfNewMessages,
        };
      }

      if (objectUtils.equal(newState, state) && payload.done) {
        newState = { ...newState, initialized: true };
      }

      return newState;
    }
    case MESSAGE_SENT: {
      if (action?.meta?.selectedLivecamUser && action?.meta?.selectedUser) {
        action.payload.userId = action.meta.selectedUser;
      }

      if (
        state.dialogmessages.find((m) => m.id === action.payload.id) ||
        (action?.meta?.selectedUser !== action?.meta?.user?.userId &&
          state.messages.find((m) => m.id === action.payload.id))
      ) {
        return state;
      }

      const { payload } = action;

      const message = {
        type: MESSAGE_TYPE_SENDER,
        ...payload,
      };

      let newState = addMessage(state, message);

      if (action.payload.attachment) {
        message.attachment = action.payload.attachment;
      }

      return {
        ...addDialogMessage(newState, message),
        initialized: true,
      };
    }
    case RESET_NEW_MESSAGES: {
      return {
        ...state,
        newMessage: 0,
      };
    }
    case IGNORE_USER: {
      if (action.meta.lastUser) {
        return {
          ...state,
          dialogmessages: [],
          isMessageUser: false,
          selectedUser: 'system',
        };
      }
      return state;
    }
    case SAVE_FETCH_MESSAGES_EVENT: {
      return {
        ...state,
        messageDoneEvtId: action.payload,
      };
    }
    case SEX_ICONS_ADD:
      return {
        ...state,
        fskLevels: { ...state.fskLevels, sexIcons: [...action.payload] },
      };
    case REGARDS_ADD:
      return {
        ...state,
        fskLevels: { ...state.fskLevels, regards: [...action.payload] },
      };
    default:
      return state;
  }
};

export default chat;
