import { connect } from 'react-redux';

import CreatorReferral from './CreatorReferral';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  advId: state.user?.account?.advId,
});

export default connect(mapStateToProps)(CreatorReferral);
