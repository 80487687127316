import { STREAMING_STARTED, STREAMING_STOPPED } from '../../../../constants';
import { management, stream } from '../../../';
import { MANAGEMENT_EVENTS_STREAM_START } from '../../../actions';

export default (userInfo) => {
  return (dispatch, getState) => {
    const {
      streaming: {
        step,
        permanentStream,
        camLoginData: {
          sdpOpts: { videoBitrate },
        },
      },
    } = getState();
    console.log(`streamStart: ${step}`);
    if (step === STREAMING_STOPPED) {
      dispatch(stream.publish(getState().streaming.camLoginData));
    } else if (step === STREAMING_STARTED) {
      dispatch(management.startedStream(userInfo));
      if (permanentStream) {
        dispatch(stream.changeBandwidth(videoBitrate));
      }
    }
    dispatch({
      type: MANAGEMENT_EVENTS_STREAM_START,
      payload: userInfo,
    });
  };
};
