import { testCam } from '../actions';
import { ABORT, NO_GETUSERMEDIA } from '../errors';
import AbortError from '../errors/abort';
import { uuid } from './';

export const getCam = (constraints) => {
  return new Promise((resolve, reject) => {
    if (navigator.mediaDevices.getUserMedia) {
      // this should work with webRTC adapter
      return navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          resolve(stream);
        })
        .catch((err) => {
          reject(err);
        });
    }
    reject(new Error(NO_GETUSERMEDIA));
  });
};

export const getConstraint = (camState, deviceInfos) => {
  const { selectedCam, selectedMicrophone } = camState;
  const { camWidth, camHeight, aspectRatio, camFps } = getCamData(camState);
  const foundCam = deviceInfos.find(
    (d) => d.deviceId === selectedCam.value || d.label === selectedCam.label
  );
  const cam = foundCam && (foundCam.deviceId || selectedCam.label);
  const constraint = {
    video: {
      deviceId: { exact: cam },
      facingMode: 'user',
      frameRate: camFps,
      width: { exact: camWidth },
      height: { exact: camHeight },
      ratioLabel: aspectRatio,
    },
    audio: selectedMicrophone.value && { deviceId: selectedMicrophone.value },
  };
  console.log(constraint);
  return constraint;
};

export const testResolutions = (
  dispatch,
  getState,
  stamp,
  resolutions = [],
  fps,
  cam,
  microphone = false
) => {
  const fpsResolutions = resolutions.find((res) => res.fps === fps);
  if (
    !fpsResolutions ||
    !fpsResolutions.resolution ||
    !fpsResolutions.resolution.length
  ) {
    return Promise.reject(new AbortError(ABORT, 4, uuid()));
  }
  // we need to wrap this call as we do not want to abort on a fail
  const check = (resolutions, camDeviceId) => {
    const length = resolutions.length;
    let i = 1;
    return resolutions.reduce(
      (p, resolution) =>
        p
          .then(
            (result) =>
              new Promise((resolve, reject) => {
                if (getState().cam.abortSelectCam) {
                  return reject(new Error(ABORT));
                }
                const constraint = {
                  video: {
                    deviceId: { exact: camDeviceId },
                    facingMode: 'user',
                    frameRate: parseInt(fps),
                    width: { exact: resolution.width },
                    height: { exact: resolution.height },
                    ratioLabel: resolution.aspectRatio,
                  },
                  audio: microphone && { deviceId: microphone },
                };
                dispatch(
                  testCam(
                    {
                      step: i++,
                      length,
                      resolution: resolution.width + 'x' + resolution.height,
                    },
                    stamp
                  )
                );
                return getCam(constraint)
                  .then((stream) => {
                    console.log('got stream');
                    for (let track of stream.getTracks()) {
                      track.stop();
                    }
                    return resolve([...result, { ...resolution, fps }]);
                  })
                  .catch((err) => {
                    console.log(err);
                    return resolve(result);
                  });
              })
          )
          .catch((err) => Promise.reject(err)),
      Promise.resolve([])
    );
  };
  const { name: browser } = getState().browser;
  // SAFARI ONLY
  if (browser.toLowerCase() === 'safari') {
    const foundCam = getState().cam.cams.find((c) => c.value === cam);
    if (!foundCam) {
      return Promise.resolve([]);
    }
    return navigator.mediaDevices
      .enumerateDevices()
      .then((deviceInfos) => {
        const foundCamByLabel = deviceInfos.find(
          (info) => info.kind === 'videoinput' && info.label === foundCam.label
        );
        if (!foundCamByLabel) {
          return check(fpsResolutions.resolution, foundCam.value);
        }
        return check(fpsResolutions.resolution, foundCamByLabel.deviceId);
      })
      .catch(() => Promise.resolve([]));
  }
  // ALL BROWSER
  return check(fpsResolutions.resolution, cam);
};

export const createResolution = ({ width, height, aspectRatio }) => {
  return {
    value: width + 'x' + height,
    label: width + ' x ' + height + ' (' + aspectRatio + ')',
  };
};

export const createPublishOptions = (data, state) => {
  const {
    streaming: {
      camLoginData: { codecs },
    },
    cam,
    browser,
  } = state;
  const {
    sdpOpts: { videoBitrate, audioBitrate, fps: videoFrameRate },
  } = data;
  let maxVideoBitrate = videoBitrate;
  const serverRes = cam.serverResolutions.find((r) => r.fps === videoFrameRate);
  if (serverRes) {
    // take max resolution first (sorted by database input)
    maxVideoBitrate = serverRes.resolution[0].bandwidth;
  }
  return Object.assign({}, data, {
    browser,
    publishName: getPublishName(cam),
    enhanceData: {
      maxVideoBitrate,
      videoBitrate,
      videoFrameRate,
      audioBitrate,
      codecs,
    },
  });
};
export const getRatio = (cam) => {
  const aspectRatio = cam.selectedResolution.label.match(/\((\d+:?\d?)\)/)[1];
  return aspectRatio === '4:3' ? 4 / 3 : 16 / 9;
};

export const getPublishName = (cam) => {
  // there where several fps before we set fps hard to 30 @see LCJS-4778
  // @todo remove this as we are sure we don't need it anymore
  const fpsResolutions = cam.serverResolutions.find(
    (res) => res.fps === parseInt(cam.selectedFps.value)
  );
  const width = parseInt(cam.selectedResolution.value.split('x').shift());
  const height = parseInt(cam.selectedResolution.value.split('x').pop());
  const resolution = fpsResolutions.resolution.find(
    (res) => res.width === width && res.height === height
  );
  if (!resolution) {
    return '';
  }
  return resolution.publishName;
};

export const getCamData = (cam) => {
  const {
    selectedResolution,
    selectedCam,
    selectedFps,
    selectedMicrophone,
    resolutions,
  } = cam;

  return {
    camWidth: parseInt(selectedResolution.value.split('x').shift()),
    camHeight: parseInt(selectedResolution.value.split('x').pop()),
    aspectRatio: selectedResolution.label.match(/\((\d+:?\d?)\)/)[1],
    webcam: selectedCam.label,
    microphone: selectedMicrophone.value ? selectedMicrophone.label : '',
    publishName: getPublishName(cam),
    camFps: parseInt(selectedFps.value),
    resolutions,
  };
};
const sortResolutions = (a, b) => {
  if (a.width !== b.width) {
    return b.width - a.width;
  }
  if (a.height !== b.height) {
    return b.height - a.height;
  }
  return 0;
};
const sortFps = (a, b) => b - a;

export const getChangeCamData = (possibleResolutions, serverResolutions) => {
  const objChangeCam = {};
  if (possibleResolutions.length > 0) {
    const fps = serverResolutions
      .map((obj) => obj.fps)
      .sort(sortFps)
      .map((mappingFps) =>
        Object.assign({
          value: mappingFps,
        })
      );

    // take max fps, sortFps will always sort higest at first place
    objChangeCam.selectedFps = fps[0];
    objChangeCam.resolutions = possibleResolutions
      .sort(sortResolutions)
      .map((r) => createResolution(r));

    // take max resolution first (sorted by database input)
    objChangeCam.selectedResolution = objChangeCam.resolutions[0];
  }
  return objChangeCam;
};

export const reset = (state, initialState) =>
  Object.assign({}, state, {
    selectedCam: initialState.selectedCam,
    selectedFps: initialState.selectedFps,
    selectedResolution: initialState.selectedResolution,
    selectedMicrophone: initialState.selectedMicrophone,
    resolutions: initialState.resolutions,
  });

export const muteMicrophone = (stream, mute) => {
  if (
    stream &&
    typeof stream.getAudioTracks === 'function' &&
    stream.getAudioTracks().length
  ) {
    console.log(`Mute ${mute} stream ${stream.id}`);
    stream.getAudioTracks()[0].enabled = !mute;
  }
};

export const hasResolution = (selectedResolution, possibleResolutions) => {
  const width = parseInt(selectedResolution.value.split('x').shift());
  const height = parseInt(selectedResolution.value.split('x').pop());
  const resolution = possibleResolutions.find(
    (r) => r.width === width && r.height === height
  );
  return !!resolution;
};

export const requestCamPermissions = async (
  testResolutions,
  checkForPermission,
  dispatch,
  testCam,
  stamp,
  logger
) => {
  console.log(
    `requestCamPermissions with resolutions: ${JSON.stringify(testResolutions)}`
  );

  const length = testResolutions.length;
  const errorArray = [];
  let constraint = {};
  for (let i in testResolutions) {
    const r = testResolutions[i];
    dispatch(
      testCam(
        {
          step: parseInt(i) + 1,
          length,
          resolution: r.width + 'x' + r.height,
        },
        stamp
      )
    );

    try {
      console.log(
        `requestCamPermissions testing with audio ${JSON.stringify(r)}`
      );
      constraint = {
        video: {
          width: r.width,
          height: r.height,
        },
        audio: true,
      };
      await checkForPermission(constraint);
      console.log(`requestCamPermissions testing success`);
      return true;
    } catch (error) {
      errorArray.push({ error, constraint: { ...constraint } });
      try {
        console.log(
          `requestCamPermissions testing without audio ${JSON.stringify(r)}`
        );
        constraint = {
          video: {
            width: r.width,
            height: r.height,
          },
        };
        await checkForPermission(constraint);
        console.log(`requestCamPermissions testing success`);
        return true;
      } catch (error) {
        errorArray.push({ error, constraint: { ...constraint } });
        console.log(
          `requestCamPermissions testing error ${error.name} / ${error.message}`
        );
      }
    }
  }
  logger.error(
    `error requestCamPermissions`,
    errorArray.map(
      (o) =>
        `${o.error.name} / ${o.error.message} / constraint: video ${
          o.constraint.video.width
        }x${o.constraint.video.height}, audio: ${!!o.constraint.audio}`
    )
  );
  return false;
};
