import { user as userUtils } from '../../../utils';
import { cam2cam } from '../../';
import { CAM2CAM_EVENT_START } from '../../actions';

export default (userId, stream) => {
  return (dispatch, getState) => {
    const {
      camUser: { camUsers },
      dialog: { dialogs },
      streaming: { permanentCam2CamStream },
    } = getState();
    const combinedUserList = [...camUsers, ...dialogs];
    const userList = userUtils.cam2camUser(combinedUserList);
    userList.forEach((cam2camUser) => {
      if (cam2camUser.userId !== userId && !permanentCam2CamStream) {
        console.log(`Stop cam2cam for user  ${cam2camUser.userId}`);
        dispatch(cam2cam.stop(cam2camUser.userId));
      }
    });
    // check if the activeUser is still the user this cam2cam was started for
    const activeUser = userUtils.activeUser(combinedUserList);
    if (
      !activeUser ||
      (activeUser.userId !== userId && !permanentCam2CamStream)
    ) {
      return;
    }
    dispatch({
      type: CAM2CAM_EVENT_START,
      payload: {
        userId,
        stream,
      },
    });
  };
};
