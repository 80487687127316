import {
  abortAskExclusiveSender,
  askExclusive,
  management,
  tickAskExclusive,
} from '../../../../../actions';
import { EXCLUSIVE_MAX_TICK, SHOW_TYPE_NORMAL } from '../../../../../constants';
import { alert, user as userUtils, uuid } from '../../../../../utils';

export default ({ showType, userId }) => (dispatch, getState) => {
  const user = userUtils.get(getState().camUser.camUsers, userId);

  if (!user || !user.visible) {
    return;
  }
  if (showType === SHOW_TYPE_NORMAL) {
    return dispatch(management.startUpgrade(userId, showType));
  }

  const stamp = uuid();
  let maxTick = EXCLUSIVE_MAX_TICK;

  /*
   * timer for 30 seconds for ExclusiveAlert
   */
  let timer = setInterval(() => {
    if (!alert.has(getState().alert, stamp)) {
      return clearInterval(timer);
    }

    let reason = 'timeout';

    if (alert.find(getState().alert, stamp).expired) {
      reason = 'notEnoughCoins';
    }

    maxTick--;

    dispatch(tickAskExclusive(maxTick, stamp));

    if (maxTick === 0) {
      dispatch(askExclusive(user, stamp, false));

      dispatch(management.declineExclusive(userId, reason));

      clearInterval(timer);
    }
  }, 1000);

  dispatch(abortAskExclusiveSender({ userId }));
  dispatch(askExclusive(user, stamp, true));
};
