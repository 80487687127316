import { connect } from 'react-redux';

import { resetCam, stream, toggleCamView } from '../../../../../../actions';
import { MANAGEMENT_LOGGED_IN } from '../../../../../../constants';
import { user as userUtils } from '../../../../../../utils';
import Cams from './Cams';

const mapStateToProps = (state) => ({
  ...state.view,
  cookiesAllowed:
    state.browser.cookiesAllowed &&
    state.browser.thirdPartyCookiesAllowed &&
    state.browser.thirdPartyCookiesTested,
  fps:
    userUtils.hasVisibleUserOrVoyeur(state.camUser.camUsers) &&
    state.streamingstats.fps,
  hasCam: state.cam.selectedCam.value !== '0',
  streaming: state.streaming.managementStep === MANAGEMENT_LOGGED_IN,
  camInitialized: state.streaming.camInitialized,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  toggleCamView: () => dispatch(toggleCamView()),
  lowFps: (type) => dispatch(stream.lowFps(type)),
  noWebcamFound: () => dispatch(resetCam({ showAlert: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Cams);
