import { sender as senderService } from '../../../../../services';
import { management } from '../../../../';
export default (reason) => {
  return (dispatch, getState) => {
    const {
      streaming: { transportCloseReconnectDelay },
      sender: {
        software: { origin: softwareOrigin },
        server: { serviceURL },
      },
    } = getState();
    const logger = senderService.logger({ serviceURL, softwareOrigin });
    logger.error(`io connect error: ${reason}`);
    dispatch(
      management.connect.start(transportCloseReconnectDelay, new Error(reason))
    );
  };
};
