export function roundToHour(date) {
  let p = 60 * 60 * 1000; // milliseconds in an hour
  return new Date(Math.ceil(date / p) * p).getTime(); // Always get the next hour
}

export function isToday(date) {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}
