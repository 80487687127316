import {
  ALERT_CANCEL,
  ALERT_VERSION_UPDATE_OPTIONAL,
  USER_LOGOUT,
  USER_LOGOUT_FORCED,
} from '../actions/actions';

const initialState = {
  optionalstamp: null,
  cancel: false,
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case ALERT_VERSION_UPDATE_OPTIONAL:
      return { ...state, optionalstamp: action.meta.stamp };
    case ALERT_CANCEL: {
      if (action.payload?.stamp === state.optionalstamp) {
        return { ...state, cancel: true };
      }
      return state;
    }
    case USER_LOGOUT:
    case USER_LOGOUT_FORCED:
      return initialState;
    default:
      return state;
  }
};

export default reduce;
