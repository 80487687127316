import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { errorLog } from '../../actions';
import ErrorComponent from './Error';

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        errorLog,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorComponent);

