import './MobileOverlay.scss';

import { useHistory } from 'react-router-dom';

import messengerIcon from '../../../../assets/img/svg/camMessenger.svg';
import diaryIcon from '../../../../assets/img/svg/diary.svg';
import Button from '../../../Button/Button';

function DashboardMobileOverlay({ stamp, closeOverlay }) {
  const history = useHistory();
  return (
    <div className="mobile-overlay" onClick={() => closeOverlay(stamp)}>
      <div className="overlay-button-wrapper">
        <Button
          variant="icon-label"
          icon={diaryIcon}
          label="diary_weblog"
          onClick={(e) => {
            history.push({
              pathname: `/my-content/diary/edit`,
            });
          }}
        />
        <Button
          variant="icon-label"
          icon={messengerIcon}
          label="MENU_ITEM_CAM_MESSENGER"
          onClick={(e) => {
            history.push({
              pathname: `/multi-messenger`,
            });
          }}
        />
      </div>
    </div>
  );
}

export default DashboardMobileOverlay;
