import { connect } from 'react-redux';

import { closeOverlay, errorLog } from '../../../../actions';
import Document from './Document';

const mapStateToProps = (state) => ({
  isMobile: state.browser.isMobile,
});

const mapDispatchToProps = { closeOverlay, errorLog };

export default connect(mapStateToProps, mapDispatchToProps)(Document);

