import './Turn.scss';

import { FormattedMessage } from 'react-intl';

function Turn({ noUser, turn }) {
  return !noUser ? (
    <h2 className="headline game-turn">
      <FormattedMessage id={turn} />
    </h2>
  ) : null;
}

export default Turn;
