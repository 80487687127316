import './PasswordReset.scss';

import useAxios from 'axios-hooks';
import { Formik, Form as FormikForm } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

import loginIcon from '../../../assets/img/svg/login.svg';
import Button from '../../Button/Button';
import FieldWithErrors from '../../Forms/FieldWithErrors/FieldWithErrors';
import FormErrorMessage from '../../Forms/FormErrorMessage/FormErrorMessage';
import Password from '../../Forms/Password/Password';
import PageSection from '../../PageSection/PageSection';

function PasswordReset() {
  const intl = useIntl();

  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const token = new URLSearchParams(search).get('token');

  const initialValues = {
    new_password: '',
    repeat_password: '',
  };

  const resetPasswordSchema = Yup.object().shape({
    new_password: Yup.string().min(
      6,
      intl.formatMessage({ id: 'PASSWORD_CHANGE_VALIDATION_MESSAGE_LENGTH' })
    ),
    repeat_password: Yup.string()
      .min(
        6,
        intl.formatMessage({ id: 'PASSWORD_CHANGE_VALIDATION_MESSAGE_LENGTH' })
      )
      .test(
        'passwords-match',
        intl.formatMessage({ id: 'PASSWORD_CHANGE_VALIDATION_MESSAGE_REPEAT' }),
        function (value) {
          return this.parent.new_password === value;
        }
      ),
  });

  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const [{ error: resetPasswordError }, resetPasswordRequest] = useAxios(
    {
      url: '/reset-password',
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  function sendResetPasswordRequest(values, actions) {
    const payload = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      token: token,
      email: email,
      password: values.new_password,
      password_confirmation: values.repeat_password,
    };
    resetPasswordRequest({ data: payload })
      .then((response) => {
        setShowSuccess(true);
      })
      .catch((error) => {
        setShowError(true);
      });
  }

  return (
    <PageSection
      title={<FormattedMessage id="ACCOUNT_LOGIN" />}
      className="reset-password-container"
    >
      <h1 className="headline">
        <FormattedMessage id="ACCOUNT_FORGOT_PASSWORD" />
      </h1>

      {!showSuccess && !showError && (
        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordSchema}
          onSubmit={sendResetPasswordRequest}
        >
          {({ isSubmitting }) => {
            return (
              <FormikForm className="reset-password-form">
                <FormErrorMessage
                  isShown={resetPasswordError}
                  title={intl.formatMessage({ id: 'ERROR' })}
                  message={intl.formatMessage(
                    { id: 'ERROR_RESET_PASSWORD' },
                    {
                      link: (
                        <Link to={'/forgot-password'}>
                          {intl.formatMessage({ id: 'TRY_AGAIN' })}
                        </Link>
                      ),
                    }
                  )}
                />

                <FieldWithErrors
                  as={Password}
                  name={'new_password'}
                  label="FIELD_NEW_PASSWORD"
                />

                <FieldWithErrors
                  as={Password}
                  name={'repeat_password'}
                  label="FIELD_REPEAT_PASSWORD"
                />

                <div className="form-group">
                  <span className="column" />
                  <Button
                    type="submit"
                    className="btn-form"
                    icon={loginIcon}
                    label={'RESET_PASSWORD_LABEL'}
                    id="submit-btn"
                  />
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      )}

      {showSuccess && (
        <div className="success-wrapper">
          <div className="success-title">
            {' '}
            <FormattedMessage id="SUCCESS_TITLE" />
          </div>
          <hr />
          <div className="success-content">
            <FormattedMessage id="RESET_PASSWORD_SUCCESS_MESSAGE" />
          </div>
        </div>
      )}
      {showError && (
        <FormErrorMessage
          isShown={showError}
          title={intl.formatMessage({ id: 'ERROR' })}
          message={intl.formatMessage(
            { id: 'ERROR_RESET_PASSWORD' },
            {
              link: (
                <Link to={'/forgot-password'}>
                  {intl.formatMessage({ id: 'TRY_AGAIN' })}
                </Link>
              ),
            }
          )}
        />
      )}
    </PageSection>
  );
}

export default PasswordReset;

