import './Tag.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function Tag({
  status,
  click = () => {},
  name,
  disabled,
  extraClassName,
  intlTranslate = true,
}) {
  const tagClass = classNames(
    'tag-button',
    {
      'tag-on': status,
      disabled,
    },
    extraClassName
  );

  return (
    <div className={tagClass} onClick={click}>
      {intlTranslate ? <FormattedMessage id={name} /> : name}
    </div>
  );
}

export default Tag;

