import './Login.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import PageSection from '../../PageSection/PageSection';
import Form from './Form';

const forgotPasswordText = (
  <span>
    <FormattedMessage id="ACCOUNT_FORGOT_PASSWORD" />
    &nbsp;
    <Link to="/forgot-password">
      <FormattedMessage id="CLICK_HERE" />
    </Link>
  </span>
);

const rightColumnTitle = (
  <>
    <span className="title-right">
      <FormattedMessage id="QUESTION_ACCOUNT" />
    </span>
    &nbsp;
    <Link to="/signup">
      <FormattedMessage id="ACCOUNT_SIGN_UP" />
    </Link>
  </>
);

function Login() {
  const isContentSectionDisplayed = useMediaQuery('(min-width: 1600px)');

  return (
    <PageSection
      title={<FormattedMessage id="ACCOUNT_LOGIN" />}
      rightTitle={!isContentSectionDisplayed && forgotPasswordText}
      rightColumnTitle={isContentSectionDisplayed && rightColumnTitle}
      className="login-container"
    >
      <h1 className="headline">
        <FormattedMessage id="LOGIN_HEADLINE" />
      </h1>
      <Form />
      <div className="forgot-password-text-container">
        <hr />
        {forgotPasswordText}
      </div>
    </PageSection>
  );
}

export default Login;
