import './VeriffBar.scss';

import { useIntl } from 'react-intl';

import Button from '../Button/Button';

function VeriffBar({ toggleVeriffFrame, veriffstatus }) {
  const intl = useIntl();
  return veriffstatus === 'mandatory' ? (
    <div className="veriff-bar">
      <span className="veriff-bar-text">
        {intl.formatMessage({ id: 'VERIFF_BAR_MANDATORY_TEXT' })}
      </span>
      <Button
        className={'btn-veriff-bar'}
        label="VERIFF_VERIFY_BUTTON"
        onClick={toggleVeriffFrame}
      />
    </div>
  ) : veriffstatus === 'pending' ? (
    <div className="veriff-bar">
      {intl.formatMessage({ id: 'VERIFF_BAR_PENDING_TEXT' })}
    </div>
  ) : null;
}

export default VeriffBar;
