import React from 'react';
import { FormattedMessage } from 'react-intl';

function StartingPhotosInfo() {
  return (
    <>
      <h2 className="headline">
        <FormattedMessage id="STARTING_GALLERY_INFO_HEADLINE_1" />
      </h2>
      <p>
        <FormattedMessage id="STARTING_GALLERY_INFO_TEXT_1" />
      </p>
      <p>
        <FormattedMessage id="STARTING_GALLERY_INFO_TEXT_2" />
      </p>
      <p>
        <FormattedMessage id="STARTING_GALLERY_INFO_TEXT_3" />
      </p>
      <p>
        <FormattedMessage id="STARTING_GALLERY_INFO_TEXT_4" />
      </p>
      {/*<h3 className="headline">*/}
      {/*  <FormattedMessage id="HOW_TO_VIDEO" />*/}
      {/*</h3>*/}
      {/* Add the video here */}
    </>
  );
}

export default StartingPhotosInfo;
