export default {
  RELATIONSHIPS_HEADLINE: `Relationships – Friends and Favorites`,
  RELATIONSHIPS_CONTENT: `Under the {link} item in the main menu, you can view and edit your saved contacts, accept or reject friend requests, and contact "friends" and "favorites" directly. Strategic use of these two categories is crucial for stress-free interaction. By organizing and managing your friends and favorites, you ensure that you don't miss a message. This way, you can always stay in touch with your most important customers and actively write to them without having to search for them.`,
  RELATIONSHIPS_CONTENT_LINK: `Relationships`,
  RELATIONSHIPS_CONTENT_MESSENGER_LINK: `Multi Messenger`,
  RELATIONSHIPS_CONTENT_1: `Friends`,
  RELATIONSHIPS_CONTENT_1_1: `A friend in your account is a customer with whom you communicate regularly. This friendship can be based on the fact that you share similar interests or that you have more in-depth discussions about your activities.`,
  RELATIONSHIPS_CONTENT_1_2: `Once you've been online a few times, you'll notice that you're receiving friend requests from many users. But before you simply accept them, you should take the time to write to the respective user and build up active communication and friendship. There's no point in having friends you never talk to.`,
  RELATIONSHIPS_CONTENT_1_3: `<span class="bold">Notice:</span> Every user can see your friends on your profile, so always be careful when accepting new friendships without prior contact.`,

  RELATIONSHIPS_CONTENT_2: `How can I send a friend request myself?`,
  RELATIONSHIPS_CONTENT_2_1: `Click on the heart at the top of the customer's chat window and you will send him a friend request.`,
  RELATIONSHIPS_CONTENT_2_2: `Where can I find new friend requests while I'm online?`,
  RELATIONSHIPS_CONTENT_2_3: `When you are online, new friend requests will be displayed directly in your {link} under Activities. There you can respond quickly and write to the customer.`,
  RELATIONSHIPS_CONTENT_2_4: `Where can I find new friend requests if I've been offline?`,
  RELATIONSHIPS_CONTENT_2_5: `You can find your open friend requests from users under the menu item {link}.`,
  RELATIONSHIPS_CONTENT_2_LINK: `Relationships – Friends – Requests`,
  RELATIONSHIPS_CONTENT_2_NO_FRIEND: `The customer is not a friend yet. Click on the heart to send him a request.`,
  RELATIONSHIPS_CONTENT_2_IS_FRIEND: `The customer is a confirmed friend. By clicking on the filled heart you remove the customer as a friend.`,

  RELATIONSHIPS_CONTENT_3: `Where can I find my friends?`,
  RELATIONSHIPS_CONTENT_3_1: `Active chats with your friends can be found in {link} under the “Friends” filter.`,
  RELATIONSHIPS_CONTENT_3_2: `You can find friends who are currently online in the {link} under Activities – Friend logins.`,
  RELATIONSHIPS_CONTENT_3_3: `You can find an overview of all your friends under the menu item {link}.`,
  RELATIONSHIPS_CONTENT_3_LINK: `Relationships – Friends – Active friends`,

  RELATIONSHIPS_CONTENT_4: `Favorites`,
  RELATIONSHIPS_CONTENT_4_1: `Make note of users who send you a lot of attention or spend a lot of coins with you. This way, you always have an eye on your top customers and don't forget to contact them regularly.`,
  RELATIONSHIPS_CONTENT_4_2: `A favorite can also be a customer with whom you have a good conversation and with whom you have the feeling that you will continue to communicate for a long time in the future. This can be a decisive criterion for them to become your favorite and thus your regular customer.`,
  RELATIONSHIPS_CONTENT_4_3: `<span class="bold">Tip:</span> Customers won’t see if you mark them as a favorite.`,
  RELATIONSHIPS_CONTENT_4_4: `How do I make a customer my favorite?`,
  RELATIONSHIPS_CONTENT_4_5: `Click on the star at the top of the customer's chat window to mark them as a favorite.`,
  RELATIONSHIPS_CONTENT_4_6: `Where can I find my favorites?`,
  RELATIONSHIPS_CONTENT_4_7: `Active chats with your favorites can be found in {link} under the “Favorites” filter.`,
  RELATIONSHIPS_CONTENT_4_8: `You can find an overview of all your favorites under the menu item {link}.`,
  RELATIONSHIPS_CONTENT_4_LINK: `Relationships – Favorites`,
  RELATIONSHIPS_CONTENT_4_NO_FAVORITE: `The customer is not a favorite yet. Click on the star to mark him as a favorite.`,
  RELATIONSHIPS_CONTENT_4_IS_FAVORITE: `The customer is marked as a favorite. By clicking on the filled star you remove the customer as a favorite.`,

  RELATIONSHIPS_CONTENT_5: `Attention`,
  RELATIONSHIPS_CONTENT_5_1: `Older chats are automatically removed from your Multi Messenger so that you only see the current chats with your contacts. If you want to revive an older chat with one of your friends or favorites, you can write to them at any time on the respective overview page by clicking on the profile picture menu (three dots) and thereby bring them back into your Multi Messenger.`,

  RELATIONSHIPS_CONTENT_6: `Conclusion`,
  RELATIONSHIPS_CONTENT_6_1: `Take advantage of the "Favorites" and "Friends" feature to efficiently manage your chats and contacts. This way you always keep track of things and can find and write to your good friends and favorites faster at any time.`,
};
