import './HeaderMenuButton.scss';

import classNames from 'classnames';
import React from 'react';
import { ReactSVG } from 'react-svg';

function HeaderMenuButton({ icon, click = () => {} }) {
  const itemClass = classNames('header-menu-button');
  return (
    <div className={itemClass}>
      <ReactSVG src={icon} wrapper="span" className="header-menu-button-icon" />
    </div>
  );
}

export default HeaderMenuButton;

