import { Formik } from 'formik';
import { connect } from 'react-redux';
import * as Yup from 'yup';

import { getValidationScheme } from '../Onboarding/Onboarding.functions';
import NicknameChanger from './NicknameChanger';

const initialValues = {
  nickname: '',
};

const NicknameChangerForm = ({ intl }) => {
  const validationSchema = getValidationScheme(intl);

  const nicknameValidationSchema = Yup.object().shape({
    nickname: validationSchema.fields.nickname,
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={nicknameValidationSchema}
    >
      <NicknameChanger />
    </Formik>
  );
};

export default connect(null)(NicknameChangerForm);

