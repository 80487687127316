import './ToggleButton.scss';

import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

function ToggleButton({
  data,
  click = () => {},
  label,
  subtext,
  name,
  disabled,
}) {
  const intl = useIntl();
  const toggleButtonClass = classNames(`toggle-button toggle-button-${name}`, {
    'toggle-on': data,
    'toggle-off': !data,
    disabled,
  });

  const toggleLabelClass = classNames('toggle-label', {
    [`toggle-label-${name}`]: name,
  });

  const onClick = disabled
    ? (e) => {
        e.preventDefault();
        e.stopPropagation();
      }
    : click;

  return (
    <div className="form-group toggle-wrapper">
      {label && (
        <label className={toggleLabelClass}>
          {intl.formatMessage({ id: label })}{' '}
          {subtext && (
            <span className="subtext">
              {intl.formatMessage({ id: subtext })}
            </span>
          )}
        </label>
      )}
      <div className={toggleButtonClass} onClick={onClick}>
        {data
          ? intl.formatMessage({ id: 'YES' })
          : intl.formatMessage({ id: 'NO' })}
        <div className="slider" />
      </div>
    </div>
  );
}

export default ToggleButton;

