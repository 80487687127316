import { user as userUtils } from '../../utils';
import camUser from '.';
export default () => (dispatch, getState) => {
  const {
    camUserFilter: { filter, messagesFilter, activities },
    camUser: { camUsers },
    dialog: { dialogs },
  } = getState();

  const filteredList = userUtils.filter(
    filter,
    messagesFilter,
    [...userUtils.visibleUser(camUsers), ...userUtils.visibleUser(dialogs)],
    activities
  );
  const nextActiveUser =
    userUtils.getLastUser(filteredList) || filteredList.pop();

  if (!nextActiveUser) {
    return;
  }

  dispatch(camUser.select(nextActiveUser.userId));
};
