import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

import icebreakerIcon from '../../../assets/img/svg/icebreaker.svg';

function Icebreaker({ isIcebreakerEnabled, isIcebreakerAllowed }) {
  const icebreakerClasses = classNames('icebreaker-icon', {
    active: isIcebreakerEnabled,
  });

  return isIcebreakerAllowed ? (
    <div className={icebreakerClasses}>
      <ReactSVG src={icebreakerIcon} className="icon" wrapper="span" />
    </div>
  ) : null;
}

export default Icebreaker;
