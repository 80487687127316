import {
  STREAM_EVENTS_ERROR,
  STREAM_LOW_FPS,
  STREAM_PUBLISH,
} from '../../actions/actions';
import {
  ALERT_LEVEL_ERROR,
  ALERT_TYPE_CLOSE,
  ALERT_TYPE_WARNING,
  WARNING_FPS,
} from '../../constants';
import { CHANGE_BANDWIDTH_ERROR, NO_SERVER_AVAILABLE } from '../../errors';

const initialState = [];

const reduce = (state = initialState, action) => {
  switch (action.type) {
    // stream actions
    case STREAM_PUBLISH:
      if (action.error) {
        if (action.payload && action.payload.message === NO_SERVER_AVAILABLE) {
          return [
            ...state,
            {
              stamp: action.meta.stamp,
              type: ALERT_TYPE_CLOSE,
              level: ALERT_LEVEL_ERROR,
              scope: 'livecam',
              message: {
                translate: true,
                id: 'alert.camLoginError21',
              },
            },
          ];
        }
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_ERROR,
            scope: 'livecam',
            message: action.payload.message,
          },
        ];
      }
      return state;
    case STREAM_EVENTS_ERROR:
      if (action.payload) {
        if (action.payload.message === CHANGE_BANDWIDTH_ERROR) {
          return [
            ...state,
            {
              stamp: action.meta.stamp,
              type: ALERT_TYPE_CLOSE,
              level: ALERT_LEVEL_ERROR,
              scope: 'livecam',
              message: {
                translate: true,
                id: 'alert.changeBandwidthError',
              },
            },
          ];
        }
        // LCJS-4988
        if (
          action.payload.message ===
          'Changing the number of m-sections is not allowed'
        ) {
          return [
            ...state,
            {
              stamp: action.meta.stamp,
              type: ALERT_TYPE_WARNING,
              level: ALERT_LEVEL_ERROR,
              scope: 'livecam',
              message: {
                translate: true,
                id: 'alert.noh264Error',
              },
            },
          ];
        }
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_ERROR,
            scope: 'livecam',
            message: {
              translate: true,
              id: 'alert.streamingError',
              values: { message: action.payload.message },
            },
          },
        ];
      }
      return state;
    case STREAM_LOW_FPS:
      return [
        ...state,
        {
          stamp: action.meta.stamp,
          type:
            action.meta.type === WARNING_FPS
              ? ALERT_TYPE_WARNING
              : ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          scope: 'livecam',
          message: {
            translate: true,
            id: 'alert.lowFpsStream',
            headlineId: 'alert.lowFpsStreamHeadline',
          },
        },
      ];
    default:
      return state;
  }
};

export default reduce;
