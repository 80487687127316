import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import addIcon from '../../../assets/img/svg/add.svg';
import closeIcon from '../../../assets/img/svg/close.svg';
import noImageIcon from '../../../assets/img/svg/no-picture.svg';
import openCameraIcon from '../../../assets/img/svg/openCamera.svg';
import questionMarkIcon from '../../../assets/img/svg/question-mark.svg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../Button/Button';
import VideoPlayerItem from '../../VideoPlayerItem/VideoPlayerItem';
import ImageItem from './../ImageItem/ImageItem';

function StandardUploader({
  wrapperClassName = '',
  labelClassName = '',
  uploadingNotAvaialable = false,
  markAsRequired = false,
  isMobile = false,
  openCam = () => {},
  hasChildren = false,
  hasChildrenWrapper = true,
  uploaderChildren,
  imageError = '',
  onlineImage,
  helpContent,
  getRootProps = () => {},
  getInputProps = () => {},
  open = () => {},
  poster,
  setFsk18 = () => {},
  translatedLabel = '',
  isHelpContentShown,
  setIsHelpContentShown,
  videoUrl,
  withVideo,
  profileVideosLoading,
  indicator,
}) {
  const intl = useIntl();
  const isSmallMobileScreen = useMediaQuery('(max-width: 499px)');
  return (
    <div {...getRootProps({ className: wrapperClassName })}>
      {uploadingNotAvaialable ? (
        <div className="label hardcore-label" onClick={() => setFsk18()}>
          {isHelpContentShown
            ? intl.formatMessage({ id: 'PROFILE_PICTURES_LABEL_HARDCORE' })
            : intl.formatMessage({
                id: 'PROFILE_PICTURES_HARDCORE_LABEL_INFO',
              })}
        </div>
      ) : (
        <span className={labelClassName}>
          {translatedLabel}
          {markAsRequired && <sup>*</sup>}
        </span>
      )}

      {!uploadingNotAvaialable && (
        <div className="inner-content">
          <input {...getInputProps()} />

          <div className="buttons">
            <Button
              type="button"
              onClick={() => {
                open();
              }}
              icon={addIcon}
              tabIndex="1"
              id="chooser-picture-upload"
              classNamesOnly="chooser-btn"
              svgClassname="svg-wrapper"
              label="BUTTON_CHOOSE_FILE"
              addEmptySpan={true}
            />
            {!isMobile && !withVideo && (
              <Button
                type="button"
                onClick={openCam}
                icon={openCameraIcon}
                id="cam-picture-upload"
                tabIndex="2"
                classNamesOnly="chooser-btn cam"
                svgClassname="svg-wrapper"
                label="BUTTON_OPEN_CAMERA"
                addEmptySpan={true}
              />
            )}
          </div>
          {hasChildrenWrapper && indicator !== 'profilepictures' ? (
            <div className="file-list">{uploaderChildren}</div>
          ) : (
            uploaderChildren
          )}
          {imageError !== '' && indicator !== 'profilepictures' ? (
            <div className="text-error">{imageError}</div>
          ) : null}
          {indicator === 'profilepictures' ? (
            <div>
              <p>
                {intl.formatMessage({
                  id: 'PROFILE_PICTURES_UPLOAD_TEXT_1',
                })}
              </p>
              <p>
                {intl.formatMessage({
                  id: 'PROFILE_PICTURES_UPLOAD_TEXT_2',
                })}
                <span
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({
                      id: 'PROFILE_PICTURES_UPLOAD_TEXT_3',
                    }),
                  }}
                />
              </p>
            </div>
          ) : null}
          {hasChildren && isSmallMobileScreen ? null : (
            <>
              {onlineImage &&
                indicator !== 'profilepictures' &&
                (onlineImage.length ? (
                  <div className="online-image">
                    <ImageItem
                      key={`online-image-item`}
                      src={
                        process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES +
                        onlineImage[0].file
                      }
                      labelTitle={intl.formatMessage({
                        id: 'LABEL_ONLINE',
                      })}
                      status={'confirmed'}
                    />
                  </div>
                ) : null)}
            </>
          )}
          {withVideo && !profileVideosLoading && (
            <>
              {videoUrl ? (
                <div className="online-video">
                  <VideoPlayerItem data={videoUrl} poster={poster} />
                  <span className="badge">
                    <FormattedMessage id="LABEL_ONLINE" />
                  </span>
                </div>
              ) : (
                <div className="empty-online-item-wrapper">
                  <div className="image-wrapper">
                    <div className="img">
                      <ReactSVG src={noImageIcon} wrapper="span" />
                    </div>
                    <span className="badge">
                      {intl.formatMessage({ id: 'LABEL_ONLINE' })}
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}

      {imageError && indicator === 'profilepictures' ? (
        <div className="error-message">{imageError}</div>
      ) : null}
      {helpContent && (
        <>
          <div className="help-content">{helpContent}</div>

          <button
            type="button"
            className="help-btn"
            onClick={() => setIsHelpContentShown((value) => !value)}
          >
            <ReactSVG
              src={isHelpContentShown ? closeIcon : questionMarkIcon}
              wrapper="span"
            />
          </button>
        </>
      )}

      <div className="drop-indicator">
        <FormattedMessage id="UPLOAD_DROP_INDICATOR" />
      </div>
    </div>
  );
}

export default StandardUploader;

