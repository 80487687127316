import { useEffect } from 'react';

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleMouseDown = (event) => {
      if (!ref?.current || !ref.current.contains(event.target)) {
        document.addEventListener('mouseup', handleMouseUp);
      }
    };

    const handleMouseUp = (event) => {
      if (!ref?.current || !ref.current.contains(event.target)) {
        setTimeout(() => callback(), 0);
      }
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [ref, callback]);
};

export default useOutsideClick;

