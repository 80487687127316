import './Spinner.scss';

import React, { useEffect, useState } from 'react';

function Spinner({
  size = 80,
  thickness = 8,
  variant = 'primary',
  margin = '8px',
  delay = 0,
}) {
  const [hidden, setHidden] = useState(delay > 0);

  useEffect(() => {
    let timer;
    if (delay > 0) {
      timer = setTimeout(() => {
        setHidden(false);
      }, delay);
    }
    return () => {
      clearTimeout(timer);
      timer = null;
    };
  });
  return (
    !hidden && (
      <div style={{ width: size, height: size, margin }} className="spinner">
        <div
          style={{ borderWidth: thickness, width: size, height: size }}
          className={variant}
        />
        <div
          style={{ borderWidth: thickness, width: size, height: size }}
          className={variant}
        />
        <div
          style={{ borderWidth: thickness, width: size, height: size }}
          className={variant}
        />
        <div
          style={{ borderWidth: thickness, width: size, height: size }}
          className={variant}
        />
      </div>
    )
  );
}

export default Spinner;

