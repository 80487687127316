import './UnsubscribeNewsletter.scss';

import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import { spinnerRequest } from '../../../shared/spinnerRequest';
import ToggleButton from '../../Forms/ToggleButton/ToggleButton';
import PageSection from '../../PageSection/PageSection';

function UnsubscribeNewsletter({ spinner = () => {}, logout, user }) {
  const search = useLocation().search;
  const email = new URLSearchParams(search).get('email');
  const token = new URLSearchParams(search).get('token');
  const intl = useIntl();
  const [error, setError] = useState(false);
  const [requestCalled, setRequestCalled] = useState(false);
  const [optionsData, setOptionsData] = useState(null);

  const [, getOptionsDataRequest] = useAxios(
    {
      url: '/anonymous/unsubscribe',
      method: 'get',
      params: {
        email: email,
        token: token,
      },
    },
    {
      manual: true,
    }
  );

  const [, unsubscribe] = useAxios(
    {
      url: '/anonymous/unsubscribe',
      method: 'post',
      data: {
        email: email,
        token: token,
        email_notification: optionsData?.email_notifications,
        newsletter: optionsData?.newsletter,
      },
    },
    {
      manual: true,
    }
  );

  function Unsubscribe(option) {
    setOptionsData((prevOptions) => ({
      ...prevOptions,
      [option]: !prevOptions[option],
    }));
  }

  useEffect(() => {
    if (
      (!requestCalled && !user?.isLoggedIn) ||
      (user?.isLoggedIn &&
        user?.account?.email === email &&
        (user?.account?.state === 'onboarding' ||
          user?.account?.state === 'pending' ||
          user?.account?.state === 'review_needed' ||
          user?.account?.state === 'double_optin_needed'))
    ) {
      setRequestCalled(true);
      if (optionsData === null) {
        spinnerRequest({
          request: getOptionsDataRequest,
          spinner,
          timeout: 200,
        })
          .then((response) => {
            setOptionsData(response?.data);
            if (response.data.errors) {
              setError(true);
            }
          })
          .catch((e) => {
            if (true) {
              setError(true);
            }
          });
      }
    }
  }, [
    spinner,
    getOptionsDataRequest,
    user?.account?.state,
    user?.isLoggedIn,
    user?.account?.email,
    optionsData,
    email,
    logout,
    requestCalled,
  ]);

  useEffect(() => {
    if (user?.isLoggedIn && user?.account?.email !== email) {
      logout();
      return;
    }
  });

  useEffect(() => {
    if (optionsData !== null) {
      spinnerRequest({
        request: unsubscribe,
        spinner,
      }).catch(() => {
        setError(true);
      });
    }
  }, [unsubscribe, spinner, optionsData]);

  return (
    <PageSection
      title={intl.formatMessage({ id: 'UNSUBSCRIBE_NEWSLETTER_TITLE' })}
      className="confirm-email-container"
    >
      {user?.isLoggedIn &&
        (user?.account?.state !== 'onboarding' ||
          user?.account?.state !== 'pending' ||
          user?.account?.state !== 'review_needed' ||
          user?.account?.state !== 'double_optin_needed') &&
        user?.account?.email === email && (
          <Redirect to="/my-account/settings/options" />
        )}
      {error === true ? (
        <div className="error-wrapper">
          <div className="error-title">
            {' ' +
              intl.formatMessage({ id: 'UNSUBSCRIBE_NEWSLETTER_ERROR_TITLE' })}
          </div>
          <hr />
          <div className="error-content">
            {intl.formatMessage({ id: 'UNSUBSCRIBE_NEWSLETTER_ERROR_CONTENT' })}
          </div>
        </div>
      ) : (
        <div className="group-container">
          <h2 className="headline">
            <FormattedMessage id="UNSUBSCRIBE_NEWSLETTER_HEADER" />
          </h2>
          <p>
            <FormattedMessage id="UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_TEXT_1" />
          </p>
          <ToggleButton
            data={optionsData?.email_notifications ? true : false}
            click={() => Unsubscribe('email_notifications')}
            label="UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_EMAIL"
            name="email_notification"
          />
          <p>
            <FormattedMessage id="UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_TEXT_2" />
          </p>
          <ToggleButton
            data={optionsData?.newsletter ? true : false}
            click={() => Unsubscribe('newsletter')}
            label="UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_NEWSLETTER"
            subtext="UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_RECOMMENDED"
            name="newsletter"
          />
        </div>
      )}
    </PageSection>
  );
}

export default UnsubscribeNewsletter;

