import { PAGINATION_PAGE_SIZE } from '../../../constants';
import api from '../../../utils/api';
import { updateListFriendRequest } from '../';

const interval = 300000;

let timer;

const isAllowed = (getState) =>
  ['confirmed', 'unconfirmed'].includes(getState().user?.account?.state);

const start = () => async (dispatch, getState) => {
  if (!isAllowed(getState)) return dispatch(stop());
  if (timer) return;

  console.log('Friends timer started');
  const response = await api.get(
    `/user/friends/requested?l=${PAGINATION_PAGE_SIZE}`
  );
  dispatch(updateListFriendRequest(response?.data));
  timer = setInterval(async () => {
    try {
      if (!isAllowed(getState)) return dispatch(stop());
      const response = await api.get(
        `/user/friends/requested?l=${PAGINATION_PAGE_SIZE}`
      );
      dispatch(updateListFriendRequest(response?.data));
    } catch (err) {
      console.log({ getFriendsRequestError: err });
    }
  }, interval);
};

const stop = () => () => {
  console.log('Friends timer stopped');
  clearInterval(timer);
  timer = null;
};

export default {
  start,
  stop,
};
