import './MyPublicProfile.scss';

import React from 'react';
import { useIntl } from 'react-intl';

import openIcon from '../../../../assets/img/svg/open-in-new.svg';
import image1 from '../../../../assets/img/view1.png';
import image2 from '../../../../assets/img/view2.png';
import image3 from '../../../../assets/img/view3.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Button from '../../../Button/Button';

function MyPublicProfile({ nickname }) {
  const intl = useIntl();
  const isSmallScreen = useMediaQuery('(max-width: 1024px)');
  return (
    <div className="public-profile-wrapper">
      <div className="data-columns group-container">
        <div className="data-left">
          <h2 className="headline">
            {intl.formatMessage({ id: 'MY_PUBLIC_DETAILS_HEADLINE_1' })}
          </h2>
          <p>{intl.formatMessage({ id: 'MY_PUBLIC_DETAILS_TEXT_1' })}</p>
          {!isSmallScreen && (
            <Button
              type="button"
              icon={openIcon}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_AMX_URL}/#/sedcard/${nickname}?preview_mode=${process.env.REACT_APP_SED_CARD_PREVIEW_TOKEN}`
                )
              }
              label={'LABEL_MY_PROFILE_IN_THIS_VERSION'}
            />
          )}
        </div>
        <div className="data-right">
          <img src={image1} alt="AmateurX" />
          {isSmallScreen && (
            <Button
              type="button"
              icon={openIcon}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_AMX_URL}/#/sedcard/${nickname}?preview_mode=${process.env.REACT_APP_SED_CARD_PREVIEW_TOKEN}`
                )
              }
              label={'LABEL_MY_PROFILE_IN_THIS_VERSION'}
            />
          )}
        </div>
      </div>

      <div className="data-columns group-container">
        <div className="data-left">
          <h2 className="headline">
            {intl.formatMessage({ id: 'MY_PUBLIC_DETAILS_HEADLINE_2' })}
          </h2>
          <p>{intl.formatMessage({ id: 'MY_PUBLIC_DETAILS_TEXT_2' })}</p>
          {!isSmallScreen && (
            <Button
              type="button"
              icon={openIcon}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_FUN_URL}/#/sedcard/${nickname}?preview_mode=${process.env.REACT_APP_SED_CARD_PREVIEW_TOKEN}`
                )
              }
              label={'LABEL_MY_PROFILE_IN_THIS_VERSION'}
            />
          )}
        </div>
        <div className="data-right">
          <img src={image2} alt="FunCommunity" />
          {isSmallScreen && (
            <Button
              type="button"
              icon={openIcon}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_FUN_URL}/#/sedcard/${nickname}?preview_mode=${process.env.REACT_APP_SED_CARD_PREVIEW_TOKEN}`
                )
              }
              label={'LABEL_MY_PROFILE_IN_THIS_VERSION'}
            />
          )}
        </div>
      </div>

      <div className="data-columns group-container">
        <div className="data-left">
          <h2 className="headline">
            {intl.formatMessage({ id: 'MY_PUBLIC_DETAILS_HEADLINE_3' })}
          </h2>
          <p>{intl.formatMessage({ id: 'MY_PUBLIC_DETAILS_TEXT_3' })}</p>
          {!isSmallScreen && (
            <Button
              type="button"
              icon={openIcon}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_AMF_URL}/#/sedcard/${nickname}?preview_mode=${process.env.REACT_APP_SED_CARD_PREVIEW_TOKEN}`
                )
              }
              label={'LABEL_MY_PROFILE_IN_THIS_VERSION'}
            />
          )}
        </div>
        <div className="data-right">
          <img src={image3} alt="AmateurFans" />
          {isSmallScreen && (
            <Button
              type="button"
              icon={openIcon}
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_AMF_URL}/#/sedcard/${nickname}?preview_mode=${process.env.REACT_APP_SED_CARD_PREVIEW_TOKEN}`
                )
              }
              label={'LABEL_MY_PROFILE_IN_THIS_VERSION'}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MyPublicProfile;
