import account from './account';
import content from './content';
import game from './game';
import home from './home';
import legal from './legal';
import livecam from './livecam';
import menu from './menu';
import moneymakers from './moneymakers';
import relationships from './relationships';
import statistics from './statistics';
import support from './support';
import toycontrol from './toycontrol';
import uploader from './uploader';
import veriff from './veriff';
import wiki from './wiki';

export default {
  ...home,
  ...menu,
  ...legal,
  ...support,
  ...livecam,
  ...account,
  ...uploader,
  ...content,
  ...relationships,
  ...moneymakers,
  ...toycontrol,
  ...wiki,
  ...statistics,
  ...game,
  ...veriff,

  LABEL_ENGLISH: 'English',
  LABEL_DEUTSCH: 'Deutsch',
  LABEL_HERE: `here`,
  LABEL_CHANGE: `change`,
  LABEL_ONLINE: `online`,
  LABEL_REJECTED: `rejected`,
  LABEL_PENDING: `pending`,
  LABEL_CONFIRMED: `confirmed`,
  LABEL_UNCONFIRMED: `deactivated`,
  unpublished: `In progress`,
  pending: `In review`,
  published: `Published`,
  blocked: `Blocked`,
  archived: `Archived`,
  schedule: `Schedule`,
  scheduled: `Scheduled`,

  SAVE: `Save`,
  CANCEL: 'Cancel',
  SECONDS_LABEL: `s`,
  WARNING: `Warning`,
  NOTE: `Note`,
  TIP: `Tip`,
  MAINTENANCE: `Maintenance`,
  YES: `Yes`,
  NO: `No`,
  SEARCH: `Search`,
  CLICK_HERE: `Click here`,
  TRY_AGAIN: `try again`,
  PLEASE_SELECT: `Please select...`,
  LOADING: `Loading...`,

  SUCCESS_TITLE: `It worked`,
  ERROR: 'ERROR',

  LABEL_SHOW: `Show`,

  LINK_SUPPORT: `support.`,
  ERROR_MESSAGE: 'An error occurred. Please reload.',
  NO_DATA: 'No data',

  VALIDATION_EMAIL: `This is not a valid email address`,
  VALIDATION_REQUIRED_FIELD: `This is a required field`,
  VALIDATION_NICKNAME_TO_SHORT: `Nicknames must be at least 5 characters long`,
  VALIDATION_NICKNAME_TO_LONG: `Nicknames must be at most 20 characters long`,
  VALIDATION_PASSWORD_TO_SHORT: `Passwords must be at least 6 characters long`,
  VALIDATION_PROFILE_DESCRIPTION_TO_SHORT: `Profile description has to be at least 32 characters`,
  VALIDATION_PROFILE_DESCRIPTION_TO_LONG: `Profile description has to be 500 characters at most`,
  VALIDATION_PHOTO_DESCRIPTION_TO_SHORT: `Description has to be at least 32 characters`,
  VALIDATION_PHOTO_DESCRIPTION_TO_LONG: `Description has to be 1000 characters at most`,
  VALIDATION_VIDEO_DESCRIPTION_TO_SHORT: `Description has to be at least 32 characters`,
  VALIDATION_VIDEO_DESCRIPTION_TO_LONG: `Description has to be 1000 characters at most`,
  VALIDATION_DIARY_DESCRIPTION_TO_SHORT: `Description has to be at least 32 characters`,
  VALIDATION_DIARY_DESCRIPTION_TO_LONG: `Description has to be 3000 characters at most`,
  VALIDATION_NICKNAME_NOT_VALID_1: `Nicknames can not begin with VX, sender- or user-`,
  VALIDATION_NICKNAME_NOT_VALID_2: `Nicknames must start with a letter and can only contain one - or _ in between`,
  VALIDATION_FIELD_TO_SHORT: `The field has to be at least 32 characters`,
  VALIDATION_FIELD_TOO_SHORT_4: `The field has to be at least 4 characters`,
  VALIDATION_PROFILE_FORENAME_MAX: `The first names may contain a maximum of 64 characters`,
  VALIDATION_PROFILE_SURENAME_MAX: `The last names may contain a maximum of 64 characters`,
  VALIDATION_PROFILE_AGE_18: `You have to be at least 18 years of age in order to be able to use this service`,
  VALIDATION_NAME_NOT_CORRECT: 'The name contains not allowed characters',
  VALIDATION_NO_URLS: 'URLs are not allowed here',
  VALIDATION_NO_TAGS: `HTML tags are not allowed here`,
  VALIDATION_FILENAME_REQUIRED: `Filename can not be empty`,
  VALIDATION_FILENAME_AT_LEAST_5_CHARACTERS: `Filename must be at least 5 characters long`,
  VALIDATION_FILENAME_AT_MOST_50_CHARACTERS: `Filename must be at most 50 characters long`,
  VALIDATION_FILENAME_ENDING: `Filenames have to end with .jpg`,

  NICKNAME_TAKEN: `The nickname is already taken`,
  NICKNAME_ALTERNATIVE: `Our alternatives:`,
  NICKNAME_ERROR: `An error occurred. Please check your internet connection and try again.`,
  NICKNAME_AVAILABLE: `This nickname is available`,

  PASSWORD_CHANGE_VALIDATION_MESSAGE_LENGTH: `Passwords must be at least 6 characters.`,
  PASSWORD_CHANGE_VALIDATION_MESSAGE_REPEAT: `New password and repeat password must match.`,
  PASSWORD_CHANGE_SUCCESS_LABEL: `Password successfully changed`,
  PASSWORD_CHANGE_ERROR_LABEL: `There was an error while changing your password. Please try again.`,
  PASSWORD_CHANGE_ERROR_CURRENT_PASSWORD: `Wrong Password`,
  PASSWORD_CHANGED_LABEL: `Password successfully changed`,
  PASSWORD_ERROR_LABEL: `Wrong password`,

  WEBCAM_ERROR_TITLE: `There is an error accessing your webcam`,
  WEBCAM_ERROR_TEXT: `Please check if it's correctly connected to your device and you gave permissions to access it. You can also upload an existing picture or contact our support.`,

  ERROR_BANK_DETAILS: `Your bank details are not complete!`,
  ERROR_UPLOAD_DOCUMENT_1: `Upload failed due to invalid file format.`,
  attachments_reached_limit: `You have reached the maximum count of attachments.`,
  attachments_file_is_missing: `The uploaded file is not valid.`,
  unable_to_save: `The file could not be saved.`,
  ERROR_UPLOAD_DOCUMENT_2: `File upload failed. Please check your internet connection.`,
  ERROR_INVALID_CREDENTIALS: `Invalid credentials. Please try again.`,
  ERROR_BLOCKED_ACCOUNT: `Account blocked. Please contact our support`,
  DEACTIVATION: `Account blocked`,
  ERROR_MALE_ACCOUNT: `Due to very high registration numbers, male creator onboarding is currently unavailable.`,
  ERROR_REGISTER_GENERAL: `An error occured. Please try again.`,
  ERROR_RESET_PASSWORD: `Your password request has expired or is invalid. Please {link}.`,
  ERROR_NETWORK_ERROR: `Network error. Please try again.`,
  ZIP_FILE_ERROR: `The upload of the zip file did not work correctly, please check the file.`,
  VIDEO_DURATION_60_ERROR: `Video must be minimum 60 seconds long.`,

  FORGOT_PASSWORD_SUCCESS_MESSAGE:
    'Please follow the instructions in the email we sent you.',
  RESET_PASSWORD_SUCCESS_MESSAGE: `Your password has been changed`,

  CONFIRMED_EMAIL_SUCCESS_TITLE: `Your email address is confirmed now`,
  CONFIRMED_EMAIL_SUCCESS_CONTENT: `{link} to proceed`,
  CONFIRMED_EMAIL_ERROR: `That didn't work`,
  CONFIRMED_EMAIL_ERROR_TITLE: `There has been an error confirming the email.`,
  CONFIRMED_EMAIL_ERROR_CONTENT: `Please try again.`,

  UNSUBSCRIBE_NEWSLETTER_ERROR_TITLE: `There has been an error loading the page.`,
  UNSUBSCRIBE_NEWSLETTER_ERROR_CONTENT: `Please try again.`,
  UNSUBSCRIBE_NEWSLETTER_TITLE: `Notifications & Newsletter`,
  UNSUBSCRIBE_NEWSLETTER_HEADER: `Change Notifications & Newsletter`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_TEXT_1: `Would you like to be informed if you receive a message while you are offline?`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_EMAIL: `Email notifications`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_TEXT_2: `We will inform you at regular intervals about news about LiveCreator.com and our communities. We also provide you with useful tips on how you can become even more successful as a Creator.`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_NEWSLETTER: `Subscribe newsletter`,
  UNSUBSCRIBE_NEWSLETTER_NOTIFICATION_RECOMMENDED: `(recommended)`,

  BANK_ACCOUNT_MISSING_TITLE: `Your bank details are not complete!`,
  BANK_ACCOUNT_MISSING_CONTENT: `You haven't filled out your banking information. Therefore we can't pay out the revenues.`,
  BANK_ACCOUNT_MISSING_SUBCONTENT: `Follow me to edit!`,

  EMAIL_UPDATE_ERROR:
    'Failed to update email. The email might already be in use.',
  EMAIL_NOT_CONFIRMED: `Not verified yet`,
  EMAIL_VERIFY_INSTRUCTION: `Please verify your email address to unlock access to LiveCreator.`,
  EMAIL_CONFIRMED: `Your email address is confirmed`,

  NEW_VERSION_HEADLINE: `There is a new version available.`,
  NEW_VERSION_TEXT_1: `Click the button to reload the page and get the new version.`,
  NEW_VERSION_TEXT_2: `If you do not click the button, the page will automatically reload when after the countdown.`,
  NEW_VERSION_RELOAD: `Reload`,
  NEW_VERSION_LATER: `Don't update now`,

  NICKNAME_REVIEW_ERROR: `Nickname is invalid`,
  GENDER_REVIEW_ERROR: `Gender is invalid`,
  COUNTRY_CODE_REVIEW_ERROR: `Country is invalid`,
  ZIP_CODE_INVALID: `Invalid Zip Code`,
  NATIVE_LANGUAGE_REVIEW_ERROR: `Native language is invalid`,
  FORENAME_REVIEW_ERROR: `Forename is invalid`,
  SURNAME_REVIEW_ERROR: `Surname is invalid`,
  BIRTHDAY_REVIEW_ERROR: `Birthday is invalid`,

  verify_email_wait: `Email could not be sent. Please try again in {minutes} minutes`,
  verify_email_error: `Error while sending email. Please try again`,
  verify_email_already_verified: `Email is already verified`,

  UPLOADER_NO_SOURCE: `No image found`,
  UPLOADER_BROKEN_IMAGE: `The image seems to be broken`,
  UPLOADER_INVALID_DIMENSIONS: `The resolution is too low. It has to be at least {minWidth} x {minHeight} pixels.`,
  UPLOADER_TIMEOUT: `The image upload took too long`,
  UPLOADER_NO_FILE: `No file found`,
  UPLOADER_FILE_TIMEOUT: `Loading the file took too long`,

  VALUE_DOES_NOT_MAKE_SENSE: `Sure?`,

  INVALID_IBAN_LABEL: `The IBAN is invalid`,
  INVALID_BIC_SWIFT_LABEL: `The BIC/SWIFT is invalid`,
  INVALID_STREET_ADDRESS: `Invalid street address`,
  INVALID_STREET_ADDRESS_2: `Invalid street continue`,
  INVALID_PHONE_NUMBER: `Invalid phone number`,
  INVALID_SKYPE: `Invalid skype`,
  INVALID_COMPANY_NAME: `Invalid company name`,
  INVALID_VAT: `Invalid VAT`,
  INVALID_ZIP_CITY: `Invalid Zip/City`,
  INVALID_FORENAME: `Invalid forename`,
  INVALID_SURNAME: `Invalid surname`,
  INVALID_BANK_NAME: `Invalid name o/t bank`,
  INVALID_BANK_ADDRESS: `Invalid bank address`,
  INVALID_BANK_CITY: `Invalid city o/t bank`,
  INVALID_DESCRIPTION: `Invalid description`,
  INVALID_ABOUT_MYSELF: `Invalid description about myself`,
  INVALID_ABOUT_ME: `Invalid description about me`,
  INVALID_EROTIC_FANTASY: `Invalid erotic fantasy description`,
  INVALID_ZIP: `Invalid zip`,
  INVALID_HEIGHT: `Invalid height`,
  INVALID_WEIGHT: `Invalid weight`,
  IMAGE_UPLOAD_ERROR: `Error during upload. Please try again.`,
  INVALID: `Invalid`,
  INVALID_DATE_MESSAGE: `The date is not valid`,

  SEND_EMAIL_SUCCESS: `Email successfully sent`,
  CHANGE_EMAIL_SUCCESS: `Email address successfully changed`,
  image_not_found_or_confirmed: `Image has not yet been uploaded or confirmed.`,

  PAGE404_NOT_FOUND: `OOPS! Page not found`,
  PAGE404_NOT_FOUND_TEXT: `we are sorry, but the page you requested does not exist`,
  PAGE404_NOT_FOUND_LINK: `Back to homepage`,

  GALLERY_ERROR_LOADING: `The gallery could not be loaded. Please try again`,
  GALLERY_ERROR_THUMBNAIL: `The thumbnail has been rejected.`,
  GALLERY_ERROR_MISSING: `The following is missing to publish the gallery:`,
  GALLERY_ERROR_TITLE: `Gallery title`,
  GALLERY_ERROR_DESCRIPTION: `a short description`,
  GALLERY_ERROR_PREVIEW: `a preview image`,
  GALLERY_ERROR_CATEGORY: `at least 1 category`,
  GALLERY_ERROR_PHOTOS: `at least 3 photos`,
  GALLERY_ERROR_DOCUMENTS: `Actor document(s)`,
  GALLERY_ERROR_VIDEO: `a video`,
  GALLERY_INVALID_PICTURE_TO_DELETE: `Invalid picture to delete.`,

  GALLERY_PUBLISHED: `The gallery has been published successfully. Once it has been editorially checked, it will go online and be available to your customers.`,
  GALLERY_SCHEDULED: `Your gallery will now be checked by the editors and will be activated at the specified time.`,

  SUPPORT_LINK_TEXT: `support`,
  ALERT_FORCED_LOGOUT: 'Please try again later or contact our',

  NO_SOUND: 'No sound',
  ALERT_DOCUMENT: `The document was not found.`,

  ERROR_UPLOAD_FILETYPE: 'This file type not allowed, only {typlist}',

  BUTTON_TITLE_ASK_ME_AGAIN: `Ask me again later`,
  BUTTON_TITLE_ACTIVATE: `Activate`,
  IDLE_MODAL_TITLE: `Icebreaker inactive!`,
  IDLE_MODAL_TEXT_1: `Icebreaker will no longer be sent for you after 15 minutes of inactivity in the Messenger.`,
  IDLE_MODAL_TEXT_2: `Click the green button to reactivate the Icebreaker and earn more money!`,
  UPLOADING: `Uploading`,

  ERROR_DURING_ACTION: `There has been an error, please try again.`,
  ERROR_TITLE: `An error occurred`,
  ALERT_USER_LOGOUT:
    'Are you sure you want to leave the page? All required data has been provided. In order to finish the registration, please click "finalize".',
  'title.toycontrol': 'Lovense Toy',
  GAME_INVITATION_MESSAGE_HEADLINE: 'Do you want to play a match with me?',
  'gameinvite.button.no': 'No, thanks',
  'gameinvite.button.yes': `Yes, let's play`,
  GAME_LOADING_ERROR: 'The game could not be loaded',
  GAME_ACCEPT_ERROR: 'An error occurred. The game could not be launched.',
  GAME_DECLINE_ERROR: 'An error occured. The game could not be declined.',

  COPIED: 'Copied',

  OPEN_VIDEO_OVERLAY: `Open video in an overlay`,
  OPEN_WIKI_LINK: `More information in our Wiki`,
  VOICE_MESSAGE_NO_LONGER_AVAILABLE: `The voice message is no longer available`,
  SONUS_ERROR: 'Please try again or contact support. {number}',
  MICROPHONE_DISABLED_ERROR:
    'In order to use this feature, please activate your microphone',
  SONUS_TOKEN_ERROR:
    'Temporary unable to send audio messages. Please reload and try again or contact the support. {number}',
  AUDIO_IS_CONVERTING: 'converting',
  AUDIO_BUTTON_DELETE: 'delete',
  AUDIO_BUTTON_STOP: 'stop',
  AUDIO_BUTTON_PLAY: 'play',
  AUDIO_BUTTON_RECORD: 'record',
  PREFERENCES_ADD_ERROR:
    'Your change could not be saved and has been reset. Please try again later.',
};

