import { toggleSmileyOverlay, typingSender } from '../../actions';
import { roomType as roomTypeUtils, user as userUtils } from '../../utils';
export default (code) => (dispatch, getState) => {
  const {
    chat: { selectedUser },
    camUser: { camUsers },
    dialog: { dialogs },
    sender: { roomTypes },
    chat: { text },
  } = getState();
  if (roomTypeUtils.isEvent(roomTypes)) {
    dispatch(typingSender(null, text + code));
    return dispatch(toggleSmileyOverlay());
  }
  const selectedUserObject = userUtils.get(
    [...camUsers, ...dialogs],
    selectedUser
  );

  if (selectedUserObject) {
    dispatch(typingSender(selectedUser, selectedUserObject.text + code));
  }
  dispatch(toggleSmileyOverlay());
};
