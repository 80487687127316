import sha1 from 'crypto-js/sha1';

import packageJson from '../../package.json';

const log = ({
  level,
  error,
  location = '',
  pathname = '',
  sendername = '',
  state = '',
  userId = '',
  language = '',
  count = '',
  data = {},
  message = '',
  userInfo,
}) => {
  if (!process.env.REACT_APP_LOG_API || !process.env.REACT_APP_LOG_API_SECRET) {
    return;
  }
  let stack = '';
  if (typeof error === 'string') {
    message = error;
  } else if (typeof error === 'object') {
    message = error.message;
    stack = error.stack;
  }
  const time = Math.floor(Date.now() / 1000);
  const token =
    '' + sha1(level + time + message + process.env.REACT_APP_LOG_API_SECRET);
  const lcrVersion = packageJson.version;
  let body = {
    level,
    message,
    location,
    sendername,
    state,
    userId,
    language,
    token,
    stack,
    pathname,
    time,
    lcrVersion,
    count,
    data,
  };
  if (userInfo && Object.keys(userInfo) > 2) {
    try {
      const {
        name: username,
        systemId,
        showType,
        clientCustomerId,
        clientProductId,
        sessionId,
        type,
        showStartTime,
      } = userInfo;
      body = {
        ...body,
        data: {
          ...data,
          username,
          systemId,
          showType,
          clientCustomerId,
          clientProductId,
          sessionId,
          type,
          showStartTime,
        },
      };
    } catch (error) {
      body = {
        ...body,
        userInfoError: error?.message,
      };
    }
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };

  return fetch(process.env.REACT_APP_LOG_API, requestOptions)
    .then((response) => {
      console.log({ response });
    })
    .catch((error) => {
      console.error('Error:', error);
    });
};

export default log;

