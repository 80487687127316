import { getTimestamp } from '../../utils';
import log from '../../utils/logApi';
import { LOG_NO_DIALOG } from '../actions';

export default (payload) => (dispatch, getState) => {
  if (!getState().user?.isLoggedIn) return;
  const {
    log: { userLoggedInTime, socketServiceConnectedTime },
    user: {
      account: { id: userId, nickname: sendername, language, state },
    },
  } = getState();
  const {
    dialogCount,
    onlineCount,
    fetchDialogStartTime,
    dialogFetchedTime,
    onlineStateFetchedTime,
    friendFetchedTime,
    friendCount,
    friendAddedTime,
    favoriteFetchedTime,
    favoriteCount,
    favoriteAddedTime,
    flagsAddedTime,
    ignoredCount,
    ignoredAddedTime,
    ignoredFetchedTime,
  } = payload;
  const dialogLoadedTime = getTimestamp();
  const socketServiceConnectedDuration =
    socketServiceConnectedTime - userLoggedInTime;
  const fetchDialogStartDuration =
    fetchDialogStartTime - socketServiceConnectedTime;
  const dialogFetchedDuration = dialogFetchedTime - fetchDialogStartTime;
  const onlineStateFetchedDuration = onlineStateFetchedTime - dialogFetchedTime;

  const friendsFetchedDuration = friendFetchedTime - onlineStateFetchedTime;
  const friendsAddedDuration = friendAddedTime - friendFetchedTime;
  const favoriteFetchedDuration = favoriteFetchedTime - friendAddedTime;
  const favoriteAddedDuration = favoriteAddedTime - favoriteFetchedTime;
  const ignoredFetchedDuration = ignoredFetchedTime - friendAddedTime;
  const ignoredAddedDuration = ignoredAddedTime - ignoredFetchedTime;
  const flagsAddedDuration = flagsAddedTime - ignoredAddedTime;

  const dialogLoadedDuration = dialogLoadedTime - onlineStateFetchedTime;
  const dialogLoadedDurationTotal = dialogLoadedTime - userLoggedInTime;

  const logObject = {
    location: 'noDialogPerformance',
    message: 'performance',
    userId,
    sendername,
    language,
    state,
    level: 'info',
    time: new Date(),
    data: {
      dialogLoadedDuration,
      socketServiceConnectedDuration,
      fetchDialogStartDuration,
      dialogFetchedDuration,
      onlineStateFetchedDuration,
      dialogLoadedDurationTotal,
      friendsFetchedDuration,
      friendsAddedDuration,
      friendCount,
      favoriteFetchedDuration,
      favoriteAddedDuration,
      favoriteCount,
      ignoredFetchedDuration,
      ignoredAddedDuration,
      ignoredCount,
      flagsAddedDuration,
      dialogCount,
      onlineCount,
    },
  };
  log(logObject);
  dispatch({
    type: LOG_NO_DIALOG,
    payload: {
      ...payload,
      dialogLoadedTime,
      dialogLoadedDuration,
      socketServiceConnectedDuration,
      fetchDialogStartDuration,
      dialogFetchedDuration,
      onlineStateFetchedDuration,
      dialogLoadedDurationTotal,
      friendsFetchedDuration,
      friendsAddedDuration,
      favoriteFetchedDuration,
      favoriteAddedDuration,
      ignoredFetchedDuration,
      ignoredAddedDuration,
      flagsAddedDuration,
    },
  });
};
