import './Alert.scss';

import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';

import {
  abortAskExclusiveSender,
  abortLoadingCam,
  cancelAlert,
  closeAlert,
  management,
  okAlert,
  reload,
} from '../../../actions';
import {
  ALERT_TYPE_CAM_LOADING,
  ALERT_TYPE_CLOSE,
  ALERT_TYPE_CONFIRM,
  ALERT_TYPE_DELETE_DENIED_REQUEST,
  ALERT_TYPE_EXCLUSIVE,
  ALERT_TYPE_EXCLUSIVE_SENDER,
  ALERT_TYPE_FADING,
  ALERT_TYPE_FRIEND_REQUEST,
  ALERT_TYPE_INFO,
  ALERT_TYPE_RELOAD,
  ALERT_TYPE_SEND_FRIEND_REQUEST,
  ALERT_TYPE_VERSION_UPDATE,
  ALERT_TYPE_WARNING,
  SHOW_TYPE_EXCLUSIVE,
} from '../../../constants';
import { alert as alertUtils } from '../../../utils';
import CloseAlert from './CloseAlert';
import ConfirmAlert from './ConfirmAlert';
import ExclusiveAlert from './ExclusiveAlert';
import FadingAlert from './FadingAlert';
import FriendRequestAlert from './FriendRequestAlert';
import InfoAlert from './InfoAlert';
import RemoveDeniedRequestAlert from './RemoveDeniedRequestAlert';
import SendFriendRequestAlert from './SendFriendRequestAlert';
import VersionUpdateAlert from './VersionUpdateAlert';
import WarningAlert from './WarningAlert';

class Alert extends React.Component {
  render() {
    const {
      alerts,
      onClose,
      onConfirm,
      onReload,
      onAbort,
      onCamLoadingClose,
      exclusiveConfirm,
      exlusiveDecline,
      exclusiveConfirmSender,
      exclusiveAbortSender,
      hasModal,
      scope,
    } = this.props;

    const hasWarningAlert =
      alerts?.length === 1 && alerts[0].type === ALERT_TYPE_WARNING;
    const cssClasses = classNames('alert-wrapper', {
      'has-warning-alert': hasWarningAlert,
      modal: hasModal,
    });
    const filteredAlerts = alerts.filter((a) => {
      a.scope = a.scope || 'general';
      return a.scope === scope;
    });

    if (filteredAlerts.length === 0) {
      return null;
    }

    return (
      <div className={cssClasses}>
        {filteredAlerts.map((alert) => {
          const isSender = alert.type === ALERT_TYPE_EXCLUSIVE_SENDER;
          const exclusiveConfirmClick = isSender
            ? exclusiveConfirmSender
            : exclusiveConfirm;
          const exclusiveAbortClick = isSender
            ? exclusiveAbortSender
            : exlusiveDecline;

          switch (alert.type) {
            case ALERT_TYPE_CLOSE:
              return (
                <CloseAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  loading={false}
                  onClose={onClose}
                  scope={alert.scope}
                />
              );
            case ALERT_TYPE_CAM_LOADING:
              return (
                <CloseAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  loading={true}
                  onClose={onCamLoadingClose}
                  hideClose={alert.hideClose}
                />
              );
            case ALERT_TYPE_CONFIRM:
              return (
                <ConfirmAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  onConfirm={onConfirm}
                  onAbort={onAbort}
                  noAbort={!!alert.noAbort}
                />
              );
            case ALERT_TYPE_INFO:
              return (
                <InfoAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  onConfirm={onClose}
                />
              );
            case ALERT_TYPE_RELOAD:
              return (
                <InfoAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  onConfirm={onReload}
                />
              );
            case ALERT_TYPE_EXCLUSIVE_SENDER:
            case ALERT_TYPE_EXCLUSIVE:
              return (
                <ExclusiveAlert
                  key={alert.stamp}
                  type={alert.type}
                  level={alert.level}
                  onConfirm={exclusiveConfirmClick(alert)}
                  onAbort={exclusiveAbortClick(alert)}
                  tick={alert.tick}
                  username={alert.username}
                  price={alert.price}
                  expired={alert.expired}
                  isSender={isSender}
                />
              );
            case ALERT_TYPE_VERSION_UPDATE:
              return (
                <VersionUpdateAlert
                  key={alert.stamp}
                  type={alert.type}
                  stamp={alert.stamp}
                  level={alert.level}
                  mandatory={alert.mandatory}
                  onAbort={onAbort}
                />
              );
            case ALERT_TYPE_WARNING:
              return (
                <WarningAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  loading={true}
                  onClose={onCamLoadingClose}
                  hideClose={alert.hideClose}
                />
              );
            case ALERT_TYPE_FRIEND_REQUEST:
              return (
                <FriendRequestAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  onConfirm={onConfirm}
                  onAbort={onAbort}
                  onClose={onClose}
                  noAbort={!!alert.noAbort}
                />
              );
            case ALERT_TYPE_FADING:
              return (
                <FadingAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  loading={false}
                  onClose={onClose}
                  scope={alert.scope}
                  expireTime={true}
                  fadingAlertType={alert.fadingAlertType}
                />
              );
            case ALERT_TYPE_SEND_FRIEND_REQUEST:
              return (
                <SendFriendRequestAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  onConfirm={onConfirm}
                  onAbort={onAbort}
                  noAbort={!!alert.noAbort}
                />
              );
            case ALERT_TYPE_DELETE_DENIED_REQUEST:
              return (
                <RemoveDeniedRequestAlert
                  key={alert.stamp}
                  stamp={alert.stamp}
                  message={alert.message}
                  type={alert.type}
                  level={alert.level}
                  onConfirm={onConfirm}
                  onAbort={onAbort}
                  noAbort={!!alert.noAbort}
                />
              );
            default:
              return <></>;
          }
        })}
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    alerts: state.alert,
    hasModal: alertUtils.hasModal(state.alert),
    scope: ownProps.scope || 'general',
  }),
  (dispatch) => {
    return {
      onConfirm: (stamp) => {
        dispatch(closeAlert(stamp));
        dispatch(okAlert(stamp));
      },
      onReload: () => {
        dispatch(reload());
      },
      onAbort: (stamp) => {
        dispatch(closeAlert(stamp));
        dispatch(cancelAlert(stamp));
      },
      onClose: (stamp) => {
        dispatch(closeAlert(stamp));
      },
      onCamLoadingClose: (stamp) => {
        dispatch(abortLoadingCam());
        dispatch(closeAlert(stamp));
      },
      exclusiveConfirm:
        ({ stamp, userId }) =>
        () => {
          dispatch(closeAlert(stamp));
          dispatch(management.confirmExclusive(userId));
        },
      exlusiveDecline:
        ({ stamp, userId }) =>
        () => {
          dispatch(closeAlert(stamp));
          dispatch(management.declineExclusive(userId, 'denied'));
        },
      exclusiveConfirmSender:
        ({ stamp, userId }) =>
        () => {
          dispatch(closeAlert(stamp));
          dispatch(
            management.askUpgradeSender({
              userId,
              showType: SHOW_TYPE_EXCLUSIVE,
            })
          );
        },
      exclusiveAbortSender:
        ({ userId }) =>
        () => {
          dispatch(abortAskExclusiveSender({ userId }));
        },
    };
  }
)(Alert);
