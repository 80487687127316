export default {
  LEGAL_IMPRINT: `Impressum`,
  LEGAL_IMPRINT_COMPANY: `IP Broadcasting B.V.`,
  LEGAL_IMPRINT_ADDRESS: `Lottumseweg 43 <br />
    5971 BV Grubbenvorst <br />
    The Netherlands`,
  LEGAL_IMPRINT_TAX: `Steuernummer: NL806101623B01`,
  LEGAL_IMPRINT_KVK: `KvK-Nummer: 12037006
  Kamer von Koophandel Amsterdam`,
  LEGAL_IMPRINT_ODR: `Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie unter <a href="http://ec.europa.eu/consumers/odr/" target="_blank">http://ec.europa.eu/consumers/odr/</a>`,
};
