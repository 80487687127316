import React from 'react';
import { useIntl } from 'react-intl';

import cancelIcon from '../../../assets/img/svg/cancel.svg';
import checkCircleIcon from '../../../assets/img/svg/check-circle-outline.svg';
import FieldWithErrors from '../../Forms/FieldWithErrors/FieldWithErrors';
import IndicatorInput from '../../Forms/IndicatorInput/IndicatorInput';
import NicknameAlternatives from '../NicknameAlternatives';

function NicknameInputField({
  label,
  isNicknameDataLoading,
  errors = false,
  touched = false,
  hasReviewError = false,
  nicknameError,
  changeNickname,
  nicknameData,
  ...attributes
}) {
  const intl = useIntl();

  return (
    <FieldWithErrors
      as={IndicatorInput}
      name="nickname"
      label={label}
      tabIndex="1"
      text={
        !errors
          ? isNicknameDataLoading && !errors && !hasReviewError
            ? intl.formatMessage({ id: 'LOADING' })
            : hasReviewError
            ? null
            : nicknameError && !errors
            ? nicknameError?.response?.data?.errors?.nickname
              ? intl.formatMessage({ id: 'NICKNAME_TAKEN' })
              : intl.formatMessage({ id: 'NICKNAME_ERROR' })
            : nicknameData && !errors
            ? intl.formatMessage({ id: 'NICKNAME_AVAILABLE' })
            : null
          : null
      }
      secondText={
        nicknameError?.response?.data?.errors?.nickname && !errors ? (
          <NicknameAlternatives
            nicknameError={nicknameError}
            click={changeNickname}
          />
        ) : null
      }
      icon={
        isNicknameDataLoading && !errors
          ? null
          : errors?.nickname && touched
          ? null
          : hasReviewError
          ? null
          : nicknameError
          ? cancelIcon
          : nicknameData
          ? checkCircleIcon
          : null
      }
      color={
        isNicknameDataLoading && !errors
          ? 'blue'
          : errors || (touched && errors) || hasReviewError || nicknameError
          ? 'red'
          : nicknameData
          ? 'green'
          : null
      }
      {...attributes}
    />
  );
}

export default NicknameInputField;

