import { connect } from 'react-redux';

import StateRoute from './StateRoute';

const mapStateToProps = (state) => ({
  user: state.user,
  browser: state.browser,
});

export default connect(mapStateToProps)(StateRoute);
