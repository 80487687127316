import { connect } from 'react-redux';

import {
  userLogout as logout,
  spinner,
  userUpdate as update,
  userFinaliseBtnShown,
} from '../../../../actions';
import OnboardingNavigator from './OnboardingNavigator';

const mapStateToProps = (state) => ({
  user: state.user,
  locale: state.intl.locale,
});

const mapDispatchToProps = {
  update,
  spinner,
  userFinaliseBtnShown,
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OnboardingNavigator);
