import { connect } from 'react-redux';

import { spinner, userUpdate as update } from '../../../../../actions';
import PersonalData from './PersonalData';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  update,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalData);
