import { FormattedMessage } from 'react-intl';

import videoPosterImgDe from '../../../../../assets/img/videoPoster/profile_image_de.jpg';
import videoPosterImgEn from '../../../../../assets/img/videoPoster/profile_image_en.jpg';
import BackButton from '../../../../BackButton';
import VideoWithOverlay from '../../../../VideoWithOverlay';

function ProfileImage({ locale, onBackButtonClicked }) {
  const video =
    locale === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/profile_image2_en.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/profile_image2_de.mp4`;
  const videoPosterImg = locale === 'en' ? videoPosterImgEn : videoPosterImgDe;

  return (
    <div className="wiki-content profile-image-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="PROFILE_IMAGE_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="PROFILE_IMAGE_CONTENT_1" />
      </p>
      <p>
        <FormattedMessage id="PROFILE_IMAGE_CONTENT_2" />
      </p>

      <h2 className="headline">
        <FormattedMessage id="WIKI_HEADLINE_VIDEO" />
      </h2>
      <VideoWithOverlay
        video={video}
        poster={videoPosterImg}
        showOverlay={false}
      />

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="PROFILE_IMAGE_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="PROFILE_IMAGE_CONTENT_STEP_1_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="PROFILE_IMAGE_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="PROFILE_IMAGE_CONTENT_STEP_2_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="PROFILE_IMAGE_CONTENT_STEP_2_2_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="PROFILE_IMAGE_CONTENT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="PROFILE_IMAGE_CONTENT_STEP_3_CONTENT" />
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="PROFILE_IMAGE_CONTENT_STEP_4" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="PROFILE_IMAGE_CONTENT_STEP_4_CONTENT" />
          </p>
        </div>
      </div>

      <p>
        <FormattedMessage id="PROFILE_IMAGE_CONTENT_TIPP">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
    </div>
  );
}
export default ProfileImage;
