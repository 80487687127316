import './OnboardingDoi.scss';

import useAxios from 'axios-hooks';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import checkCircleIcon from '../../../../../assets/img/svg/check-circle-outline.svg';
import Button from '../../../../Button/Button';
import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import IndicatorInput from '../../../../Forms/IndicatorInput/IndicatorInput';
import Input from '../../../../Forms/Input/Input';
import InputWithLabel from '../../../../Forms/InputWithLabel/InputWithLabel';
import StandaloneFieldWithErrors from '../../../../Forms/StandaloneFieldWithErrors/StandaloneFieldWithErrors';
import StepperContext from '../../../../Stepper/StepperContext';

function OnboardingDoi({ update = () => {}, user = {} }) {
  const {
    currentItemIndex,
    nextItemIndex,
    setCurrentItemIndex,
    markCurrentItemAsValid,
    markCurrentItemAsInvalid,
    isLoading,
    sendingToReview,
    setSendingToReview,
  } = useContext(StepperContext);
  const [email, setEmail] = useState(user.account.email);
  const [emailError, setEmailError] = useState(null);
  const [changingEmail, setChangingEmail] = useState(false);
  const [verificationError, setVerificationError] = useState(null);
  const [verificationSuccess, setVerificationSucess] = useState(null);
  const [emailChangeSuccess, setEmailChangeSuccess] = useState(false);
  const intl = useIntl();
  const [{ loading: isLoadingUpdateEmail }, resendEmailVerification] = useAxios(
    {
      url: '/user/account',
      method: 'PUT',
      data: { email },
    },
    {
      manual: true,
    }
  );
  const [, resendVerifyLink] = useAxios(
    {
      url: '/user/email/verify',
      method: 'PUT',
      data: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        email: email,
      },
    },
    {
      manual: true,
    }
  );
  const [, touchedFieldRequest] = useAxios(
    {
      url: '/touch_field',
      method: 'POST',
      data: {
        field: 'doi',
        touch: true,
      },
    },
    { manual: true }
  );
  const handleEmailVerify = useCallback(() => {
    if (isLoadingUpdateEmail) {
      return;
    }
    resendEmailVerification({ data: { email } })
      .then((result) => {
        if (result?.data?.email === email) {
          console.log('email account update success');
          update({
            account: {
              ...user.account,
              email,
            },
          });
          setEmailChangeSuccess(true);
          setEmailError(null);
        } else {
          setEmailError('EMAIL_UPDATE_ERROR');
        }
      })
      .catch((error) => {
        console.log(
          `email account update error with message ${error?.message}`
        );
        setEmailError('EMAIL_UPDATE_ERROR');
        setEmailChangeSuccess(false);
      });
  }, [email, resendEmailVerification, isLoadingUpdateEmail, update, user]);
  function resendVerification() {
    resendVerifyLink()
      .then(() => {
        setVerificationError(null);
        setVerificationSucess(true);
      })
      .catch((error) => {
        if (error && error.response) {
          const tryAgainLaterError = error.response.data.errors.findIndex(
            (element) => {
              return element.includes('verify_email_wait');
            }
          );
          if (tryAgainLaterError !== -1) {
            setVerificationError(
              error.response.data.errors[tryAgainLaterError].split('|')
            );
          } else if (error.response.data.errors) {
            setVerificationError(error.response.data.errors);
          } else {
            setVerificationError('verify_email_error');
          }
        }
        setVerificationSucess(null);
      });
  }
  const targetRef = useRef();
  useEffect(() => {
    if (targetRef?.current) {
      targetRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'end',
      });
    }
  }, []);

  useEffect(() => {
    if (user?.account?.touched_fields?.length) {
      return;
    }
    touchedFieldRequest()
      .then(() => {
        update({
          account: {
            ...user.account,
            touched_fields: ['doi'],
          },
        });
      })
      .catch(() => {});
  }, [
    touchedFieldRequest,
    update,
    user.account,
    user.account?.touched_fields?.length,
  ]);

  useEffect(() => {
    if (user.account.state !== 'onboarding') {
      return;
    }
    if (user.account.doi && emailError) {
      setEmailError(null);
      markCurrentItemAsValid();
    } else if (!user.account.doi && !emailError) {
      markCurrentItemAsInvalid();
      setEmailError('EMAIL_DOI_ERROR');
    }
  }, [
    emailError,
    markCurrentItemAsInvalid,
    markCurrentItemAsValid,
    user.account.doi,
    user.account.state,
  ]);
  useEffect(() => {
    if (sendingToReview !== 'pending') {
      return;
    }
    setSendingToReview('ready');
  }, [sendingToReview, setSendingToReview]);
  useEffect(() => {
    if (
      nextItemIndex === null ||
      nextItemIndex === currentItemIndex ||
      isLoading
    ) {
      return;
    }
    setCurrentItemIndex(nextItemIndex);
  }, [currentItemIndex, isLoading, nextItemIndex, setCurrentItemIndex]);

  return (
    <div className="content-inner doi-step">
      <span ref={targetRef} />
      <h1 className="step-headline">
        <FormattedMessage id="DOI_TITLE" />
      </h1>
      <div className="content-grid">
        {user.account.state === 'onboarding' && (
          <>
            {user.account.doi && (
              <div className="grid-col">
                <FieldWithErrors
                  as={IndicatorInput}
                  name="email"
                  color="green"
                  value={email}
                  label="EMAIL_VERIFICATION_LABEL"
                  text={intl.formatMessage({ id: 'EMAIL_CONFIRMED' })}
                  icon={checkCircleIcon}
                  disabled={true}
                />
              </div>
            )}
            {!user.account.doi && (
              <>
                {changingEmail ? (
                  <>
                    <div className="grid-col">
                      <StandaloneFieldWithErrors
                        as={Input}
                        type="email"
                        name="email"
                        label="NEW_EMAIL"
                        className="email-change-input"
                        disabled={
                          isLoading || isLoadingUpdateEmail || user.account.doi
                        }
                        maxLength="64"
                        value={email}
                        onChange={(e) => {
                          const newEmail = e.target.value;
                          setEmail(newEmail);
                          if (
                            newEmail !== user.account.email ||
                            !user.account.doi
                          ) {
                            setEmailError(emailError);
                            markCurrentItemAsInvalid();
                          }
                        }}
                        error={
                          emailError &&
                          emailError !== 'EMAIL_DOI_ERROR' &&
                          intl.formatMessage({ id: emailError })
                        }
                      />
                      <div className="form-group">
                        <span className="column" />
                        <Button
                          type="button"
                          variant="primary"
                          onClick={handleEmailVerify}
                          label="BUTTON_CHANGE"
                          className="btn-form"
                        />
                      </div>
                      {emailChangeSuccess && (
                        <div className="form-group">
                          <span className="column" />
                          <div className="column2 success-wrapper">
                            {intl.formatMessage({ id: 'CHANGE_EMAIL_SUCCESS' })}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="grid-col">
                      <div className="email-change">
                        <p>
                          {intl.formatMessage({
                            id: 'EMAIL_CHANGE_NOTIFICATION_1',
                          })}
                        </p>
                        <p>
                          {intl.formatMessage(
                            { id: 'EMAIL_CHANGE_NOTIFICATION_2' },
                            {
                              link: (
                                <Link
                                  to="#"
                                  onClick={() => window.location.reload()}
                                >
                                  {intl.formatMessage({
                                    id: 'EMAIL_RELOAD_HERE',
                                  })}
                                </Link>
                              ),
                            }
                          )}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="grid-col">
                    <InputWithLabel
                      type="email"
                      name="email"
                      label="EMAIL_VERIFICATION_LABEL"
                      className="email-display-input"
                      disabled={true}
                      maxLength="64"
                      value={email}
                    />
                    <div className="form-group">
                      <span className="column" />
                      <div className="error-wrapper">
                        <div className="error-title">
                          {' ' +
                            intl.formatMessage({ id: 'EMAIL_NOT_CONFIRMED' })}
                        </div>
                        <hr />
                        <div className="error-content">
                          {intl.formatMessage({
                            id: 'EMAIL_VERIFY_INSTRUCTION',
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {!changingEmail && !user.account.doi && (
          <div className="grid-col">
            <div className="not-verified">
              <p>
                <FormattedMessage id="EMAIL_RESEND_1" />
              </p>
              <p>
                <FormattedMessage
                  id="EMAIL_RESEND_2"
                  values={{
                    link: (
                      <Link to="#" onClick={() => resendVerification()}>
                        <FormattedMessage id="CLICK_HERE" />
                      </Link>
                    ),
                  }}
                />
              </p>
              {verificationError && (
                <div className="error-wrapper">
                  <FormattedMessage
                    id={verificationError[0]}
                    values={
                      verificationError[1] && {
                        minutes: verificationError[1],
                      }
                    }
                  />
                </div>
              )}
              {verificationSuccess && (
                <div className="success-wrapper">
                  <FormattedMessage id="SEND_EMAIL_SUCCESS" />
                </div>
              )}
              <p>
                <FormattedMessage
                  id="EMAIL_CHANGE_REQUEST"
                  values={{
                    link: (
                      <Link to="#" onClick={() => setChangingEmail(true)}>
                        <FormattedMessage id="EMAIL_CHANGE" />
                      </Link>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OnboardingDoi;
