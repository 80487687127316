import './VirtualGifts.scss';

import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Route, Switch, useHistory } from 'react-router';

function VirtualGifts({ items }) {
  const intl = useIntl();

  const [routes, setRoutes] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname === '/my-account/virtual-gifts') {
      history.push('/my-account/virtual-gifts/my-virtual-gifts');
    }
  }, [history]);

  useEffect(() => {
    const routes = items.map((route, i) => {
      return (
        <Route
          key={`/my-account/virtual-gifts${route.path}`}
          path={`/my-account/virtual-gifts${route.path}`}
          component={route.component}
        />
      );
    });

    setRoutes(routes);
  }, [history.location.pathname, intl, items]);
  return (
    <>
      <Switch>{routes}</Switch>
    </>
  );
}

export default VirtualGifts;
