import { connect } from 'react-redux';

import Livecam from './Livecam';

const mapStateToProps = (state) => ({
  isMobile: state.view.isMobile,
  cam: state.view.cam,
});

export default connect(mapStateToProps)(Livecam);
