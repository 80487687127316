import './GalleryPhotoUploader.scss';

import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import addIcon from '../../../assets/img/svg/add.svg';
import previewImageIcon from '../../../assets/img/svg/preview-image.svg';
import zipIcon from '../../../assets/img/svg/zip-upload.svg';
import useGetPhotoGalleriesDropzone from '../../../hooks/useGetPhotoGalleriesDropzone';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../Button/Button';
import ThumbnailItem from '../../ThumbnailItem/ThumbnailItem';
import VideoPlayerItem from '../../VideoPlayerItem/VideoPlayerItem';
import { checkImage, getImageSource } from './../Uploader.functions';

function GalleryPhotoUploader({
  handleDrop = () => {},
  uploadPath = '',
  uploadZip = '',
  uploadThumbnail = '',
  uploadFormData = {},
  uploadFile = () => {},
  indicator = '',
  onChoose = () => {},
  handleError = () => {},
  dropzoneProps = {},
  minDimensions = {},
  minGalleryDimensions,
  acceptedFileTypes = [],
  additionalAcceptedFileTypes = [],
  wrapperClassName = '',
  title,
  openPictureCropper = () => {},
  thumbnailUrl,
  imageError,
  imageSuccess,
  activeGallery,
  isMobile,
  videoUrl,
  thumbnailComment,
}) {
  const intl = useIntl();
  const uploadingThumbnail = useRef(false);
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');

  const isZipFile = (file) =>
    [
      'application/zip',
      'application/zip-compressed',
      'application/x-zip-compressed',
      'multipart/x-zip',
      'application/octet-stream',
    ].includes(file?.type);

  const doUpload = useCallback(
    async (acceptedFiles) => {
      handleDrop(acceptedFiles);
      const data = new FormData();

      let path = uploadPath;
      if (isZipFile(acceptedFiles[0]) || uploadingThumbnail.current) {
        path = isZipFile(acceptedFiles[0]) ? uploadZip : uploadThumbnail;
        acceptedFiles.forEach((file) => {
          data.append('file', file, file.name);
        });
      } else {
        acceptedFiles.forEach((file, index) => {
          data.append('files[]', file, file.name);
          data.append('assume_landscape[]', 1);
        });
      }

      for (let pair of uploadFormData.entries()) {
        data.append(pair[0], pair[1]);
      }
      uploadFile(path, data, indicator);
      // Setting the focus for the tab index after upload
      document.getElementById('chooser-picture-upload').focus();
    },
    [
      handleDrop,
      uploadPath,
      uploadFile,
      indicator,
      uploadThumbnail,
      uploadZip,
      uploadFormData,
    ]
  );

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      onChoose();
      if (rejectedFiles.length) {
        handleError(`Error: ${rejectedFiles[0].errors[0].message}`);
      }
      if (uploadingThumbnail.current) {
        if (minDimensions?.width && minDimensions?.height) {
          try {
            const iSource = await checkImage(
              await getImageSource(acceptedFiles[0], intl),
              intl,
              minDimensions
            );
            openPictureCropper(
              acceptedFiles,
              iSource,
              uploadingThumbnail.current ? uploadThumbnail : null
            );
            uploadingThumbnail.current = false;
          } catch (error) {
            uploadingThumbnail.current = false;
            return handleError(error.message);
          }
        }
      } else {
        for (let i = 0; i < acceptedFiles.length; i++) {
          if (!isZipFile(acceptedFiles[i])) {
            try {
              await checkImage(
                await getImageSource(acceptedFiles[i], intl),
                intl,
                minGalleryDimensions
              );
            } catch (error) {
              return handleError(error.message);
            }
          }
        }
        return doUpload(acceptedFiles);
      }
    },
    [
      onChoose,
      handleError,
      minDimensions,
      intl,
      openPictureCropper,
      uploadThumbnail,
      minGalleryDimensions,
      doUpload,
    ]
  );

  const onDropThumbnail = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      uploadingThumbnail.current = true;
      onDrop(acceptedFiles, rejectedFiles);
    },
    [onDrop]
  );

  const openThumbnailUploader = () => {
    if (!activeGallery?.active?.thumbnail?.thumbnail) {
      openThumbnail();
    }
  };

  const {
    getRootProps,
    getInputProps,
    getRootPropsImage,
    getInputPropsImage,
    openImage,
    getRootPropsZip,
    getInputPropsZip,
    openZip,
    getRootPropsThumbnail,
    getInputPropsThumbnail,
    openThumbnail,
  } = useGetPhotoGalleriesDropzone({
    acceptedFileTypes,
    additionalAcceptedFileTypes,
    dropzoneProps,
    onDrop,
    onDropThumbnail,
  });

  const labelClassName = classNames('label', {
    'missing-title': !title,
  });

  return (
    <div {...getRootProps({ className: wrapperClassName })}>
      <span className={labelClassName}>
        {title ? title : intl.formatMessage({ id: 'UNNAMED_GALLERY' })}
      </span>
      <div className="gallery-inner-content">
        <input {...getInputProps()} />

        <span className="top-wrapper">
          <div className="buttons">
            <span {...getRootPropsImage()}>
              <input {...getInputPropsImage()} />
              <Button
                type="button"
                onClick={() => {
                  openImage();
                }}
                icon={addIcon}
                tabIndex="1"
                id="chooser-picture-upload"
                classNamesOnly="chooser-btn"
                svgClassname="svg-wrapper"
                label="BUTTON_CHOOSE_FILE"
                addEmptySpan={true}
              />
            </span>
            {!isMobile && (
              <span {...getRootPropsZip()}>
                <input {...getInputPropsZip()} />
                <Button
                  type="button"
                  onClick={() => {
                    openZip();
                  }}
                  icon={zipIcon}
                  tabIndex="1"
                  id="chooser-picture-upload"
                  classNamesOnly="chooser-btn"
                  svgClassname="svg-wrapper"
                  label="BUTTON_ZIP_ARCHIVE"
                  addEmptySpan={true}
                />
              </span>
            )}
          </div>
          <div className="uploader-description">
            {!isMobile && !thumbnailComment && (
              <p className="title">
                <FormattedMessage id="GALLERY_UPLOADER_HEADLINE" />
              </p>
            )}
            {thumbnailComment ? (
              <div className="thumbnail-comment-error">
                <span className="comment-headline">
                  {intl.formatMessage({ id: 'UPLOAD_COMMENT' })}
                </span>
                <div className="text-error">{thumbnailComment}</div>
                <div className="text-error">
                  <span className="underlined" onClick={openThumbnail}>
                    {intl.formatMessage({ id: 'GALLERY_UPLOAD_NEW_PREVIEW' })}
                  </span>
                  <span className="text-error">
                    {intl.formatMessage({ id: 'GALLERY_OR_SELECT_NEW_IMAGE' })}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <p>{intl.formatMessage({ id: 'GALLERY_UPLOADER_TEXT_1' })}</p>
                <p>{intl.formatMessage({ id: 'GALLERY_UPLOADER_TEXT_2' })}</p>
              </>
            )}
            {imageError !== '' && !isMobileScreen && (
              <div className="text-error">{imageError}</div>
            )}
            {imageSuccess !== '' && (
              <div className="text-pending">{imageSuccess}</div>
            )}
          </div>
        </span>
        <span className="bottom-wrapper">
          {imageError !== '' && isMobileScreen && (
            <div className="text-error">{imageError}</div>
          )}
          {videoUrl ? (
            <div className="online-image">
              <VideoPlayerItem data={videoUrl} />
            </div>
          ) : (
            <>
              <div
                className="empty-online-item-wrapper"
                {...getRootPropsThumbnail()}
              >
                <input {...getInputPropsThumbnail()} />
                <div className="image-wrapper">
                  {thumbnailUrl ? (
                    <ThumbnailItem
                      item={{ file: thumbnailUrl }}
                      type="photo"
                      galleryThumbnail={true}
                      showMediumOnPreview={true}
                      openThumbnail={openThumbnail}
                      setImageError={handleError}
                    />
                  ) : (
                    <div
                      className="img"
                      onClick={() => openThumbnailUploader()}
                    >
                      <span />
                      <span className="svg-wrapper">
                        <ReactSVG src={previewImageIcon} wrapper="span" />
                      </span>
                      <span className="preview-image-label">
                        {intl.formatMessage({ id: 'GALLERY_PREVIEW_IMAGE' })}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </span>
      </div>

      <div className="drop-indicator">
        <FormattedMessage id="UPLOAD_DROP_INDICATOR" />
      </div>
    </div>
  );
}

export default GalleryPhotoUploader;

