import { MANAGEMENT_CONNECT_STARTED, SOCKET_OPTIONS } from '../../constants';
import { sender as senderService } from '../../services';
import { uuid } from '../../utils';
import { audio, management as managementActions } from '../';
import {
  MANAGEMENT_CONNECT,
  MANAGEMENT_CONNECT_START,
  MANAGEMENT_CONNECT_STOP,
} from '../actions';

let timer = null;

const start =
  (reconnectDelay = 0, err = null) =>
  (dispatch, getState) => {
    if (timer !== null) {
      return;
    }

    const {
      streaming: { maxConnects, connectCounter, silentReconnect },
    } = getState();

    if (connectCounter >= maxConnects) {
      dispatch(audio.lostConnection());
      return dispatch({
        type: MANAGEMENT_CONNECT,
        payload: err,
        meta: { stamp: uuid(), silentReconnect },
        error: true,
      });
    }
    dispatch({
      type: MANAGEMENT_CONNECT_START,
    });
    if (reconnectDelay === 0) {
      return dispatch(managementActions.connect.connect());
    }
    console.log(`connect timer started: ${reconnectDelay}`);
    timer = setTimeout(
      () => dispatch(managementActions.connect.connect()),
      reconnectDelay
    );
  };
const stop = () => (dispatch) => {
  dispatch({
    type: MANAGEMENT_CONNECT_STOP,
  });
  stopTimer();
};
const stopTimer = () => {
  clearTimeout(timer);
  timer = null;
};
const connect = (management) => () => {
  return async (dispatch, getState) => {
    console.log(`connect started ${getState().streaming.managementStep}`);
    stopTimer();
    try {
      const state = getState();
      const {
        server: { serviceURL },
        software: { origin: softwareOrigin },
        sessionId,
      } = state.sender;
      const senderServerUrls = await senderService.server({
        type: 'sender',
        serviceURL,
        softwareOrigin,
      });
      const { host, port } = senderServerUrls[0];
      const url =
        host +
        ':' +
        port +
        '/sender' +
        '?sessionId=' +
        sessionId +
        '&origin=' +
        softwareOrigin +
        '&web=1';
      if (getState().streaming.managementStep !== MANAGEMENT_CONNECT_STARTED) {
        return;
      }
      dispatch({
        type: MANAGEMENT_CONNECT,
      });
      management.init(url, SOCKET_OPTIONS, dispatch);
    } catch (err) {
      const {
        streaming: { transportCloseReconnectDelay },
      } = getState();
      dispatch(
        managementActions.connect.start(transportCloseReconnectDelay, err)
      );
    }
  };
};
const connectService = (management) => ({
  start,
  connect: connect(management),
  stop,
});

export default connectService;
