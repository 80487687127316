import { management, stream } from '../../../actions';
import {
  MANAGEMENT_LOGGED_IN,
  STREAMING_IS_STARTING,
} from '../../../constants';
import { roomType as roomTypeUtils } from '../../../utils';
import { STREAM_STOP } from '../../actions';
export default () => {
  return (dispatch, getState) => {
    console.log('Stopped Stream event');
    const {
      sender: { roomTypes },
      streaming: { step, camLoginData, isReconnecting, silentReconnect },
    } = getState();
    if (step === STREAMING_IS_STARTING) {
      return dispatch(stream.publish(camLoginData));
    }
    if (getState().streaming.managementStep === MANAGEMENT_LOGGED_IN) {
      dispatch(management.logoutCam({}, 19));
      if (!roomTypeUtils.isEvent(roomTypes)) {
        dispatch(management.logfpsTimer.stop());
      }
      dispatch(management.checkuserTimer.stop());
    }
    if (!roomTypeUtils.isEvent(roomTypes)) {
      dispatch(stream.timerCheck.stop());
    }
    dispatch(stream.timerStop.stop());
    dispatch({
      type: STREAM_STOP,
      meta: {
        isReconnecting,
        silentReconnect,
      },
    });
  };
};
