import React from 'react';

import ToggleButton from '../../Forms/ToggleButton/ToggleButton';

const UserColorCheckbox = ({ enabled, onChange }) => (
  <ToggleButton
    data={enabled}
    click={() => onChange(!enabled)}
    label="userColors.title"
    name="cc"
  />
);

export default UserColorCheckbox;
