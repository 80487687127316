import { Field as FormikField } from 'formik';
import React from 'react';

class Field extends React.Component {
  render() {
    const {
      intl,
      label,
      placeholder,
      intlTranslation = true,
      ...rest
    } = this.props;
    const props = {
      ...rest,
      dispatch: undefined,
    };
    return (
      <FormikField
        {...props}
        label={
          label && intlTranslation ? intl.formatMessage({ id: label }) : label
        }
        placeholder={
          placeholder && intlTranslation
            ? intl.formatMessage({ id: placeholder })
            : placeholder
        }
      />
    );
  }
}

export default Field;
