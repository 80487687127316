import {
  ABORT,
  BANDWIDTH_NOT_COMPLETED,
  NOT_ENOUGH_BANDWIDTH,
} from '../../errors';
import AbortError from '../../errors/abort';
import { uuid } from '../../utils';
import { testResolutions } from '../../utils/cam';
import { CHANGE_CAM } from '../actions';
import { changeCamResolution, changedCam, chooseCam, endLoadingCam } from './';
export default (
  value,
  label,
  stamp,
  resolutionLabel = '',
  resolutionValue = ''
) => {
  return (dispatch, getState) => {
    const {
      cam,
      bandwidth: { percent, averageBandwidth },
    } = getState();
    if (percent !== 100) {
      return dispatch({
        type: CHANGE_CAM,
        meta: { stamp, errorStamp: uuid() },
        error: true,
        payload: new Error(BANDWIDTH_NOT_COMPLETED),
      });
    }
    let highFps = 0;
    const resolutions = cam.serverResolutions.map((fps) => {
      if (highFps <= fps.fps) {
        highFps = fps.fps;
      }
      return Object.assign({}, fps, {
        resolution: fps.resolution.filter(
          (item) => item.bandwidth <= averageBandwidth
        ),
      });
    });
    if (!resolutions.reduce((prev, fps) => prev + fps.resolution.length, 0)) {
      return dispatch({
        type: CHANGE_CAM,
        meta: { stamp, errorStamp: uuid() },
        error: true,
        payload: new Error(NOT_ENOUGH_BANDWIDTH),
      });
    }

    const microphone = cam.selectedMicrophone && cam.selectedMicrophone.value;
    testResolutions(
      dispatch,
      getState,
      stamp,
      resolutions,
      highFps,
      value,
      microphone
    )
      .then((possibleResolutions) => {
        if (!possibleResolutions || !possibleResolutions.length) {
          dispatch(endLoadingCam(stamp));
          return dispatch({
            type: CHANGE_CAM,
            error: true,
            payload: new AbortError(ABORT, 1, uuid()),
          });
        }

        dispatch(changedCam(value, label, possibleResolutions));
        if (
          resolutionValue !== '' &&
          resolutionLabel !== '' &&
          getState().cam.resolutions.some(
            (obj) =>
              resolutionValue === obj.value && resolutionLabel === obj.label
          )
        ) {
          dispatch(changeCamResolution(resolutionValue, resolutionLabel));
        } else {
          dispatch(chooseCam());
        }
        dispatch(endLoadingCam(stamp));
      })
      .catch((error) => {
        console.log({ error });
        dispatch(endLoadingCam(stamp));
        dispatch({
          type: CHANGE_CAM,
          error: true,
          payload: error,
        });
      });
  };
};
