export default {
  ATTACHMENTS_HEADLINE: `Was sind Dateianhänge?`,
  ATTACHMENTS_CONTENT_1: `Dateianhänge können unter {link} (grünes Icon im Hauptmenü) hochgeladen werden. Du hast die Möglichkeit, bis zu 25 Dateien in Deinem Account zu speichern. Bitte beachte, dass der Name der Datei zu jeder Zeit für den Kunden sichtbar ist. Überprüfe Deine Dateianhänge regelmäßig und tausche die Bilder gegebenenfalls aus, damit Deine Kunden immer aktuelle Bilder von Dir erhalten.`,
  ATTACHMENTS_CONTENT_1_LINK: `Umsatzbringer - Dateianhänge`,
  ATTACHMENTS_CONTENT_1_1: `Welche Vorteile bieten Dir die Dateianhänge?`,
  ATTACHMENTS_CONTENT_1_2: `
    <ul class='checklist'>
      <li>Du kannst Dateianhänge nutzen, um die Antwortrate auf Deine Icebreaker zu steigern, indem Du an einen oder mehrere Icebreaker ein Bild oder Video anhängst. Es ist sinnvoll, mindestens eine Datei am „Bild Icebreaker“ zu verwenden, um bei Bedarf ein aktuelles Bild von Dir zu senden.</li>
      <li>Du kannst kostenlose Dateianhänge nutzen, um einem Kunden ein besonderes Gefühl von Wertschätzung zu geben.</li>
    </ul>`,

  ATTACHMENTS_HEADLINE_2: `Kostenpflichtige Dateianhänge`,
  ATTACHMENTS_CONTENT_2: `Sobald Du ein entsprechend hohes Chat-Ranking erreicht hast und es keine Beschwerden von Kunden gibt, werden Dir die Premium-Dateianhänge automatisch freigeschaltet. Diese Funktion eröffnet Dir völlig neue Möglichkeiten.`,
  ATTACHMENTS_CONTENT_2_1: `Welche Vorteile bieten Dir die Premium-Dateianhänge?`,
  ATTACHMENTS_CONTENT_2_2: `
    <ul class='checklist'>
      <li>Mit den Premium-Dateianhängen kannst Du individuell auf die Wünsche und Bedürfnisse Deiner Kunden eingehen.</li>
      <li>Du kannst die Datei direkt im Messenger hochladen, Deinen gewünschten Preis einstellen und sie Deinem Kunden senden.</li>
      <li>Ein Premium-Dateianhang kann ein Bild oder ein Video sein, solange sie die Größe von 100 MB nicht überschreitet.</li>
      <li>Mit den Premium-Dateianhängen erschließt Du neue Verdienstmöglichkeiten. Du kannst Dein Angebot erweitern und zusätzliche Medien wie Bilder, Videos von Dir anbieten, die Du zu einem von Dir festgelegten Preis verkaufen kannst.</li>
    </ul>`,
  ATTACHMENTS_CONTENT_2_3: `Wichtig:`,
  ATTACHMENTS_CONTENT_2_4: `
    <ul>
      <li>Der Premium-Dateianhang ist nicht dazu gedacht, Deine Kunden unaufgefordert mit massenhaft Dateianhängen zu belästigen (Spam) und sie ungefragt zum Kauf aufzufordern. Du kannst ihnen erst Premium-Dateianhänge senden, wenn Du mit ihnen in einer aktiven Korrespondenz bist.</li>
      <li>Verschicke keine Medien, die Du schon in Deinen Galerien anbietest. Der Kunde möchte keine doppelten Bilder oder Videos von Dir kaufen. Das wirkt sich unter Umständen sogar negativ auf Dein Chat-Ranking aus.</li>
      <li>Sobald Du dieses Feature aktiv nutzt, wirst Du in Deinem Dashboard unter dem Punkt "Meine Leistung" Deine Verkaufsquote sehen. Sinkt Deine Verkaufsquote am Ende eines Monats unter 50%, wird das Feature am Monatsanfang automatisch deaktiviert. In diesem Fall kannst Du Dich beim Support melden und gemeinsam besprechen, welche Änderungen erforderlich sind, damit Du diese Funktion wieder erhältst.</li>
    </ul>`,
};
