import api from '../../../utils/api';
import { addIdsFriend } from '..';

export default () => async (dispatch, getState) => {
  try {
    let friendIds = [];
    const friendResponse = await api.get(`/user/friends/ids`);
    if (!getState().user?.account?.id) {
      return;
    }
    if (friendResponse?.data?.length > 0) {
      friendIds = friendResponse.data.map((el) => el.replace('-', '@'));
    }
    dispatch(addIdsFriend(friendIds));
  } catch (err) {
    console.log({ fetchFriendIdsError: err });
  }
};
