import './ModelsTable.scss';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import useTranslateTableTitles from '../../../hooks/useTranslateTableTitles';
import NumberItem from '../TableItems/NumberItem';
import ProgressItem from '../TableItems/ProgressItem/ProgressItem';

function ModelsTable({
  items = [],
  titles = [],
  precision,
  currency,
  mediumScreen,
  mobileScreen,
  billDetailsTable,
  ...otherProps
}) {
  const intl = useIntl();
  const translatedTitles = useTranslateTableTitles(titles);
  return (
    <table>
      <thead>
        <tr>
          {translatedTitles.map((title, index) => {
            return (
              <th
                style={{
                  width: title.width,
                  textAlign: title.position,
                }}
                key={`${title}.${index}`}
              >
                {(!billDetailsTable || !title.hideInBillDetails) && title.title}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {items.map((item, index) => {
          const tableRowClass = classNames({
            'models-table-current-row': item.status === 'current',
          });

          // A class for all the td elememnts
          const tableDataClass = classNames('models-table-td', {
            'models-table-td-current': item.status === 'current',
            'models-table-td-disabled': item.status === 'disabled',
          });

          // A class for all the div elements inside the td elements
          // Divs are used additionally to td elements in order to make the arrow which
          // is supposed to appear on the 'current' row. One of the divs is then transformed into the arrow
          // If this was done with the td elements, then an additional td element would need to be present
          // and would displace the position of the others
          const tableDataDivClass = classNames('models-table-td-div', {
            'models-table-td-div-current': item.status === 'current',
          });

          // A class for the first div of the element where the arrow is supposed to be shown
          const tableDataDivArrowClass1 = classNames('models-table-td-div', {
            'models-table-td-div-arrow-1 text-left': item.status === 'current',
            'text-center': item.status !== 'current',
          });

          // A class for the second div of the element where the arrow is supposed to be shown
          const tableDataDivArrowClass2 = classNames(
            'models-table-td-div-arrow-2'
          );

          // A class for the div of the last td since it differs from the rest
          const tableDataLastDivClass = classNames('models-table-td-div', {
            'models-table-td-div-last': item.status === 'current',
          });

          const dataKeyClass = classNames('item-data-key', {
            'item-data-key-enabled': item.status === 'enabled',
          });

          const currentLevelSpanClass = classNames('current-level-span', {});

          return (
            <tr key={`${item.key}.${index}`} className={tableRowClass}>
              <td className={tableDataClass}>
                <div className={tableDataDivClass}>
                  <span className={dataKeyClass}>{item.key}</span>
                </div>
                {item.status === 'current' ? (
                  <div className="arrowStart" />
                ) : null}
              </td>

              <td className={tableDataClass}>
                <div
                  className={tableDataDivClass + ' text-center font-weight-500'}
                >
                  <span>
                    <NumberItem number={item.rate} /> %
                  </span>
                </div>
              </td>

              {!mediumScreen && !mobileScreen && !billDetailsTable ? (
                <td className={tableDataClass}>
                  <div className={tableDataDivClass + ' text-right'}>
                    {item.status !== 'current' ? (
                      <span>{intl.formatNumber(item.threshold)} Coins</span>
                    ) : (
                      intl.formatMessage({ id: 'MODELS_TABLE_YOUR' })
                    )}
                  </div>
                </td>
              ) : null}

              <td className={tableDataClass}>
                <div className={tableDataDivArrowClass1}>
                  {item.status !== 'current' ? (
                    <span>
                      {item?.next_coins ? (
                        <ProgressItem
                          data={intl.formatNumber(item.next_coins)}
                          value={'Coins'}
                          percent={parseFloat(
                            intl.formatNumber(item.next_percent)
                          )}
                          type={'dashed'}
                        />
                      ) : null}
                    </span>
                  ) : (
                    <span className={currentLevelSpanClass}>
                      {billDetailsTable ? (
                        // If the table is inside the bill details area
                        <span>
                          {intl.formatMessage(
                            {
                              id: 'MODELS_TABLE_YOU_REACHED_COMMISSION_RATE',
                            },
                            {
                              rate: `${intl.formatNumber(item.rate, {
                                minimumFractionDigits: 1,
                              })}%`,
                            }
                          )}
                        </span>
                      ) : (
                        // If the table is inside the billing period
                        <>
                          {' ' +
                            (mediumScreen
                              ? intl.formatMessage({ id: 'MODELS_TABLE_YOUR' })
                              : '') +
                            ' ' +
                            intl.formatMessage({
                              id: 'MODELS_TABLE_CURRENT_LEVEL',
                            }) +
                            ' '}

                          <span className="small">
                            {intl.formatMessage(
                              {
                                id: 'MODELS_TABLE_FROM_COINS',
                              },
                              { coins: intl.formatNumber(item.threshold) }
                            )}
                          </span>
                        </>
                      )}
                    </span>
                  )}
                </div>
                {item.status === 'current' ? (
                  <div className={tableDataDivArrowClass2} />
                ) : null}
              </td>

              <td className={tableDataClass}>
                <div
                  className={
                    tableDataLastDivClass + ' text-right font-weight-500'
                  }
                >
                  <span>
                    <NumberItem
                      number={
                        billDetailsTable
                          ? otherProps.affectedRevenue
                          : item.revenue
                      }
                      precision={precision}
                      currency={currency}
                    />
                  </span>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ModelsTable;

