import { TYPE_ADMIN, TYPE_DATING } from '../../constants/user';
import { roomType as roomTypeUtils, user as userUtils } from '../../utils';
import { management, sendChatMessage } from '../';
export default (key) => (dispatch, getState) => {
  const {
    sender: { roomTypes },
    preferences: { functionkeys },
    camUser: { camUsers },
    dialog: { dialogs },
    chat: { isMessageUser },
  } = getState();

  if (isMessageUser) {
    return;
  }

  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
  const isAdmin = activeUser?.type === TYPE_ADMIN;
  const isDatingUser = activeUser?.type === TYPE_DATING;
  const message = functionkeys[key];

  if (message === '') {
    return;
  }

  if (!isAdmin && !isDatingUser) {
    return dispatch(sendChatMessage(message));
  }

  if (roomTypeUtils.isEvent(roomTypes)) {
    return dispatch(management.sendMessage2All(message));
  }

  dispatch(management.sendMessage(message));
};
