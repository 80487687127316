import {
  ADD_CAM,
  BANDWIDTH_CHECK,
  CAM_LOADING_ABORT,
  CAM_LOADING_END,
  CAM_LOADING_START,
  CAM_OVERLAYHEIGHT_CHANGE,
  CAM_PERMISSION_REQUEST_FAILED,
  CAM_RESIZING,
  CHANGE_CAM,
  CHANGE_LABEL_CAM,
  CHANGE_LABEL_MICROPHONE,
  CHANGE_MICROPHONE,
  CHANGE_RESOLUTION_CAM,
  MANAGEMENT_EVENTS_RESOLUTION_SENDER_CHANGE,
  RESET_CAM,
  RESET_MICROPHONE,
  SENDER_LOGIN,
  SENDER_LOGIN_DATA,
  SERVER_RESOLUTION_SAVE,
  TEST_CAM,
  UPDATE_CAM,
  UPDATE_MICROPHONE,
} from '../actions/actions';
import { ABORT_ERROR_NAME } from '../constants';
import { CHECKING } from '../constants/bandwidth';
import {
  ABORT,
  BANDWIDTH_NOT_COMPLETED,
  NOT_ENOUGH_BANDWIDTH,
} from '../errors';
import { lStorage } from '../services/storage';
import { getChangeCamData, reset } from '../utils/cam';
import crypt from '../utils/crypt';

const chooseCam = {
  label: 'streamingOptions.selectCam',
  value: '0',
  disabled: true,
  translate: true,
};
const chooseMicrophone = {
  label: 'streamingOptions.noMic',
  value: false,
  translate: true,
};

const initialState = {
  serverResolutions: [],
  oldServerResolutions: {},
  cams: [chooseCam],
  resolutions: [],
  microphones: [chooseMicrophone],
  abortSelectCam: false,
  selectCamRunning: false,
  selectedCam: { value: chooseCam.value, label: chooseCam.label },
  selectedFps: { value: 30 },
  selectedResolution: { value: '', label: '' },
  selectedMicrophone: {
    value: chooseMicrophone.value,
    label: chooseMicrophone.label,
  },
  overlayHeight: 300,
  resolution: '',
  step: 0,
  length: 0,
  checkingCam: false,
  error: { id: false, message: false },
  bandwidthError: false,
  resizing: false,
};

const cam = (state = initialState, action) => {
  switch (action.type) {
    case SENDER_LOGIN_DATA: {
      const {
        selectedCam,
        selectedMicrophone,
        selectedResolution,
        resolutions,
        oldServerResolutions,
      } = action.payload?.storedCams || state;

      return {
        ...state,
        selectedCam,
        selectedMicrophone,
        selectedResolution,
        resolutions,
        oldServerResolutions,
      };
    }
    case SENDER_LOGIN: {
      if (action.payload.resolutions) {
        const serverResolutions = action.payload.resolutions.filter(
          (res) => res.fps === 30
        );

        return {
          ...state,
          serverResolutions,
          overlayHeight: initialState.overlayHeight, // always reset this on login so it gets calculated
        };
      }
      return state;
    }
    case CHANGE_CAM: {
      if (action.error) {
        if (action.payload.message === BANDWIDTH_NOT_COMPLETED) {
          return { ...state, bandwidthError: true };
        }
        if (action.payload.message === NOT_ENOUGH_BANDWIDTH) {
          return {
            ...state,
            error: { id: 'alert.notEnoughBandwidth', message: false },
          };
        }
        if (
          action.payload.name === ABORT_ERROR_NAME &&
          action.payload.abortnumber === 1
        ) {
          return {
            ...state,
            error: { id: 'alert.camAbortNoResolutions', message: false },
          };
        } else if (action.payload.name === ABORT_ERROR_NAME) {
          return {
            ...state,
            error: {
              id: 'alert.camAbort',
              message: { number: action.payload.abortnumber },
            },
          };
        }
        if (
          action.payload.message !== ABORT ||
          state.selectedCam.value === '0'
        ) {
          return reset(state, initialState);
        }
      }

      let objChangeCam = {
        selectedCam: action.payload,
      };

      if (action.payload.possibleResolutions) {
        objChangeCam = {
          ...objChangeCam,
          ...getChangeCamData(
            action.payload.possibleResolutions,
            state.serverResolutions
          ),
        };
      }
      return { ...state, ...objChangeCam };
    }
    case CHANGE_RESOLUTION_CAM:
      return { ...state, selectedResolution: action.payload };
    case UPDATE_CAM: {
      if (action.error) {
        // @todo implement
        // action.error called from updateCam action
        return state;
      }

      let cams = action.payload.cams.map((cam, index) => {
        let tmp = {
          value: cam.deviceId,
          groupId: cam.groupId,
          kind: cam.kind,
          label: cam.label === '' ? 'Cam ' + (index + 1) : cam.label,
        };
        return tmp;
      });

      cams.unshift(initialState.cams[0]);
      initialState.cams = cams;
      const camItem = `${crypt.secretHash()}:cams`;
      lStorage.setItem(
        camItem,
        crypt.run(
          {
            selectedCam: state.selectedCam,
            selectedMicrophone: state.selectedMicrophone,
            selectedResolution: state.selectedResolution,
            resolutions: state.resolutions,
            oldServerResolutions: state.oldServerResolutions,
          },
          true
        )
      );
      return { ...state, cams };
    }
    case UPDATE_MICROPHONE: {
      let microphones = action.payload.microphones.map((microphone, index) => {
        let tmp = {
          value: microphone.deviceId,
          groupId: microphone.groupId,
          kind: microphone.kind,
          label:
            microphone.label === ''
              ? 'Microphone ' + (index + 1)
              : microphone.label,
        };
        return tmp;
      });

      microphones.unshift(initialState.microphones[0]);
      initialState.microphones = microphones;
      return { ...state, microphones };
    }
    case CHANGE_MICROPHONE: {
      if (action.error) {
        return reset(state, initialState);
      }
      return {
        ...state,
        selectedMicrophone: action.payload,
      };
    }
    case ADD_CAM: {
      if (action.error) {
        return reset(
          {
            ...state,
            error: { id: 'alert.camAccess', message: action.payload.message },
          },
          initialState
        );
      }
      return state;
    }
    case RESET_CAM:
      lStorage.removeItem(`${crypt.secretHash()}:cams`);

      return reset(state, initialState);
    case SERVER_RESOLUTION_SAVE:
      return { ...state, oldServerResolutions: action.payload };
    case CHANGE_LABEL_CAM:
      return {
        ...state,
        selectedCam: { ...state.selectedCam, label: action.payload },
      };
    case CHANGE_LABEL_MICROPHONE:
      return {
        ...state,
        selectedMicrophone: {
          ...state.selectedselectedMicrophoneCam,
          label: action.payload,
        },
      };
    case CAM_LOADING_ABORT:
      if (!state.selectCamRunning) {
        return state;
      }
      return {
        ...state,
        selectCamRunning: false,
        abortSelectCam: true,
        checkingCam: false,
        error: { id: false, message: false },
        bandwidthError: false,
      };
    case CAM_LOADING_END:
      return {
        ...state,
        selectCamRunning: false,
        abortSelectCam: false,
        checkingCam: false,
        error: { id: false, message: false },
        bandwidthError: false,
      };
    case CAM_LOADING_START:
      return {
        ...state,
        selectCamRunning: true,
        abortSelectCam: false,
        checkingCam: CHECKING,
        error: { id: false, message: false },
        bandwidthError: false,
      };
    case BANDWIDTH_CHECK:
      if (action.error) {
        if (action.error.message !== ABORT) {
          return Object.assign({}, state, {
            selectCamRunning: false,
            abortSelectCam: false,
          });
        }
        return reset(state, initialState);
      }
      return state;
    case TEST_CAM:
      if (action.meta) {
        return {
          ...state,
          resolution: action.payload.resolution,
          step: action.payload.step,
          length: action.payload.length,
        };
      }
      return state;
    case RESET_MICROPHONE:
      return {
        ...state,
        selectedMicrophone: initialState.selectedMicrophone,
        microphones: initialState.microphones,
      };
    case CAM_OVERLAYHEIGHT_CHANGE:
      return {
        ...state,
        overlayHeight: action.payload,
      };
    case MANAGEMENT_EVENTS_RESOLUTION_SENDER_CHANGE: {
      const selectedResolution = state.resolutions.find(
        (r) => r.value === action.payload.resolution
      );
      if (!selectedResolution) {
        return state;
      }
      return { ...state, selectedResolution };
    }
    case CAM_PERMISSION_REQUEST_FAILED:
      return {
        ...state,
        error: { id: 'alert.camAbortNoResolutions', message: false },
      };
    case CAM_RESIZING:
      return {
        ...state,
        resizing: action.payload,
      };
    default:
      return state;
  }
};

export default cam;
