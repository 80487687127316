export default {
  FIRST_STEPS_HEADLINE: `Your start in LiveCreator`,
  FIRST_STEPS_CONTENT_1: `In order to get started successfully on LiveCreator, we will show you here how the interface is structured.`,
  FIRST_STEPS_HEADLINE_2: `LiveCreator is divided into three functional areas:`,
  FIRST_STEPS_CONTENT_STEP_1: `Main menu`,
  FIRST_STEPS_CONTENT_MENU_1: `Here you can change your personal and account details, manage your documents and {link}, view invoices and make all necessary {link1}.`,
  FIRST_STEPS_CONTENT_MENU_1_LINK: `your public profile`,
  FIRST_STEPS_CONTENT_MENU_1_LINK_1: `settings`,
  FIRST_STEPS_CONTENT_MENU_2: `The {link} is your tool to stay in touch with customers, via chat or in the livecam.`,
  FIRST_STEPS_CONTENT_MENU_2_LINK: `Multi Messenger`,
  FIRST_STEPS_CONTENT_MENU_3: `Here you have the opportunity to manage your files, which you can send to customers via message as a {link}. You can also customize your {link1} with file attachments.`,
  FIRST_STEPS_CONTENT_MENU_3_LINK: `file attachment`,
  FIRST_STEPS_CONTENT_MENU_3_LINK_1: `icebreakers`,
  FIRST_STEPS_CONTENT_MENU_4: `Check and change the status of relationships with users.`,
  FIRST_STEPS_CONTENT_MENU_5: `Create and edit your {link} here and read or reply to comments received. You can also manage your {link1} here.`,
  FIRST_STEPS_CONTENT_MENU_5_LINK: `photo and video galleries`,
  FIRST_STEPS_CONTENT_MENU_5_LINK_1: `diary`,
  FIRST_STEPS_CONTENT_MENU_6: `This menu item contains an overview of the top 25 customers who have spent the most coins on you. You can also find your last 30 {link} games.`,
  FIRST_STEPS_CONTENT_MENU_7: `We recommend that you take a look here, especially at the start. You will find many helpful tips and instructions in the wiki and the latest newsletters. If you still have questions, the contact details of your coach Kathrin and the dates for the Zoom help calls that take place several times a week are stored in the help center.`,
  FIRST_STEPS_CONTENT_MENU_8: `In addition to the legal notice and data protection, this mandatory information also includes the possibility of subsequently changing your cookie settings.`,

  FIRST_STEPS_CONTENT_STEP_2: `Work space`,
  FIRST_STEPS_CONTENT_STEP_2_CONTENT: `Depending on which menu item you are on, you can make your settings in this area, create galleries or diary entries and edit your profile.`,
  FIRST_STEPS_CONTENT_STEP_2_1_CONTENT: `The functions of the Multi Messenger are so comprehensive that the work area is divided into three areas:`,
  FIRST_STEPS_CONTENT_STEP_2_1_LIST: `the user list`,
  FIRST_STEPS_CONTENT_STEP_2_2_LIST: `the chat`,
  FIRST_STEPS_CONTENT_STEP_2_3_LIST: `and an {link} from the selected user`,
  FIRST_STEPS_CONTENT_STEP_2_LINK: `infocard`,
  FIRST_STEPS_CONTENT_STEP_3: `Info box with explanations, notes and tips`,
  FIRST_STEPS_CONTENT_STEP_3_CONTENT: `Here you will find important information and tips relevant to your chosen area of work. For example, in the info box we explain to you what options you have when creating/editing galleries under {link} and how it works.`,
  FIRST_STEPS_CONTENT_STEP_3_1_CONTENT: `<span class="bold">Important:</span> If you use a smaller screen resolution, the information will be displayed below the workspace!`,
};
