import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { errorDocument } from '../../../actions';
import DocumentsTable from './DocumentsTable';

const mapStateToProps = (state) => ({
  isSubmenuShown: state.view.sidebar,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        errorDocument,
      },
      dispatch
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable);
