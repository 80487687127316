export default {
  HEADER_TITLE_NEWSLETTER: 'Newsletter Übersicht',
  HEADER_PAGE_NEWSLETTER: `Alle Newsletter zum nochmal Lesen`,
  ERROR_NO_NEWS_LETTER: `Kein Newsletter gefunden`,

  HEADER_TITLE_SUPPORT: `Hilfe & Support`,

  SUPPORT_INFO_HEADLINE: 'Hallo!',
  SUPPORT_INFO_TEXT: `<p>Ich bin Kathrin, Dein Coach auf LiveCreator.<br />
  Für Fragen zur Onboarding-Tour, den wichtigsten Schritten, Einstellungen oder anderen Fragen zu Deinem Profil stehe ich Dir</p>
  <ul class="bold">
    <li>Dienstags von 10.00 - 11.00 Uhr</li>
    <li>Mittwochs von 16.00 - 17.00 Uhr</li>
    <li>Donnerstags von 14.00 - 15.00 Uhr</li>
  </ul>
  live in <span class="bold">Zoom</span> zur Verfügung. Klicke einfach auf <a class="blue" href="https://us02web.zoom.us/j/89900156510?pwd=am11ME5VVnE4S3BlaWlFZHJCYjFJUT09" target="_blank">diesen Link</a>.</p>
  <p>Beachte bitte, dass der Zoom-Link nur in den oben genannten Zeiträumen funktioniert.</p>
  <p class="bold">Viele Grüße und viel Erfolg<br />
  Kathrin</p>`,

  SUPPORT_INTRO: `Womit brauchst Du Hilfe?`,
  SUPPORT_BACK_BUTTON: 'Zurück',

  SUPPORT_CONTACT: `Kontakt`,
  SUPPORT_EMAIL: `E-Mail: <span class="bold"><a href="mailto:support@livecreator.com">support@livecreator.com</a></span>`,
  SUPPORT_COPYRIGHT: `© {year} by IP Broadcasting B.V.`,
  SUPPORT_ALL_RIGHTS_RESERVED: `Alle Rechte vorbehalten.`,
  SUPPORT_NO_REPRODUCTIONS: `Keine unerlaubte Vervielfältigung gestattet`,
  SUPPORT_VERSION: `<span class="bold">Version:</span> {version}`,

  TUTORIAL_SUBJECT_TEXT: 'Hat das Dein Problem gelöst?',
  TUTORIAL_YES_BUTTON: 'Ja, vielen Dank',
  TUTORIAL_NO_BUTTON: 'Nein, ich benötige mehr Hilfe',

  SUPPORT_FORM_HEADLINE: 'Sag uns, wie wir Dir helfen können',
  CONTACT_SUPPORT_CATEGORY: `Kategorie:`,
  CONTACT_SUPPORT_SUBJECT: `Betreff:`,
  CONTACT_SUPPORT_MESSAGE: `Nachricht:`,
  SUPPORT_ERROR_MESSAGE: `Bitte gib eine Nachricht ein`,
  CONTACT_SUPPORT_BUTTON: `Nachricht senden`,

  SUPPORT_SUCCESS: `Deine Anfrage wurde erfolgreich gesendet.`,
  SUPPORT_ERROR: `Es ist ein Fehler beim Versenden aufgetreten. Bitte versuche es noch einmal.`,
};
