import { management } from '../../../';
import { MANAGEMENT_EVENTS_STREAMINFO_START } from '../../../actions';

export default () => (dispatch, getState) => {
  const { statsInterval, streamInfoCounter } = getState().streaming;
  const timerNotRunning = streamInfoCounter === 0;
  dispatch({
    type: MANAGEMENT_EVENTS_STREAMINFO_START,
  });
  if (timerNotRunning) {
    dispatch(management.streaminfoTimer.start(statsInterval));
  }
};
