/* eslint eqeqeq: "off" */
const getCodecId = (sdp, codec) => {
  //only finds first appearance
  const findCodec = new RegExp(`a=rtpmap:(\\d+) ${codec}/(\\d+)`, 'i');
  const foundCodec = sdp.match(findCodec);
  return (foundCodec && foundCodec.length >= 2 && foundCodec[1]) || false;
};
const getProfileLevelId = (sdp, level) => {
  const findCodec = new RegExp(
    `a=fmtp:(\\d+) [\\w-=;]*profile-level-id=${level}`,
    'i'
  );
  const foundCodec = sdp.match(findCodec);
  return (foundCodec && foundCodec.length >= 2 && foundCodec[1]) || false;
};
const getTypeLine = (sdp, type) => {
  const findLine = new RegExp(`m=${type} (\\d+) ([\\w/]+)([ \\d+]+)`, 'i');
  const foundLine = sdp.match(findLine);
  if (!foundLine || foundLine.length < 4) {
    return false;
  }
  return foundLine;
};

const getCodecIds = (line) => line[3].match(/(\d+)/gi);

/**
 *
 * @param {string} sdp the sdp string
 * @param {string} type video or audio
 * @param {string} codec h264, vp8, vp9,... or opus, PCMU,...
 * @param {string} profileId optional, the h264 profile id
 *
 * For example:
 *  codecSelector(sdp, 'video', 'h264', '42e01f') or
 *  codecSelector(sdp, 'audio', 'opus')
 */
const codecSelector = (sdp, type, codec, profileId) => {
  let codecId;
  let returnObj = {
    notSameType: () => false,
    getLine: () => -1,
    empty: false,
  };
  if (codec === '') {
    returnObj.empty = true;
    return returnObj;
  }
  if (profileId && profileId !== '') {
    codecId = getProfileLevelId(sdp, profileId);
  } else {
    codecId = getCodecId(sdp, codec);
  }
  if (!codecId) {
    return returnObj;
  }
  const typeLine = getTypeLine(sdp, type);
  if (!typeLine) {
    return returnObj;
  }
  const codecIds = getCodecIds(typeLine);
  console.log({ codecId, typeLine, codecIds });
  if (codecIds.findIndex((elem) => elem === codecId) < 0) {
    return returnObj;
  }
  return Object.assign(returnObj, {
    notSameType: (id) => id && codecId != id && codecIds.indexOf(id) >= 0,
    getLine: () => `m=${type} ${typeLine[1]} ${typeLine[2]} ${codecId}`,
  });
};

const getID = (line) => {
  const findid = new RegExp('a=([\\w-]+):(\\d+) (.+)');
  const found = line.match(findid);
  return found && found.length >= 3 ? found[2] : 0;
};

const getRtpMapType = (line) => {
  const findid = new RegExp('a=rtpmap:(\\d+) (\\w+)/(\\d+)');
  const found = line.match(findid);
  return found && found.length >= 3 ? found[2].toLowerCase() : null;
};

const enhanceSDP = (sdp, enhanceData, browser) => {
  console.log({ enhanceData, sdp });
  const { video, audio } = enhanceData.codecs;
  const videocodec = codecSelector(
    sdp,
    'video',
    video.name,
    video.profilelevel
  );
  const audiocodec = codecSelector(sdp, 'audio', audio.name);
  if (!videocodec) {
    return false;
  }
  const sdpLines = sdp.split(/\r\n/);
  let sdpSection = 'header';
  let hitMID = false;
  let sdpStrRet = '';
  let bandwidthFactor = 1;
  if (
    browser.name.toLowerCase() === 'firefox' ||
    browser.name.toLowerCase() === 'safari'
  ) {
    bandwidthFactor = 1000;
  }

  sdpLines.forEach((sdpLine) => {
    console.log({ sdpLine });
    const id = getID(sdpLine);
    if (
      sdpLine.length <= 0 ||
      ((sdpLine.startsWith('a=rtpmap') ||
        sdpLine.startsWith('a=rtcp-fb') ||
        sdpLine.startsWith('a=fmtp')) &&
        (videocodec.notSameType(id) || audiocodec.notSameType(id))) ||
      sdpLine.indexOf('b=CT') === 0 ||
      sdpLine.indexOf('b=AS') === 0 ||
      sdpLine.indexOf('b=TIAS') === 0
    ) {
      return;
    }

    if (sdpLine.indexOf('m=audio') === 0) {
      sdpSection = 'audio';
      sdpStrRet += audiocodec.empty ? sdpLine : audiocodec.getLine();
      hitMID = false;
    } else if (sdpLine.indexOf('m=video') === 0) {
      sdpSection = 'video';
      sdpStrRet += videocodec.empty ? sdpLine : videocodec.getLine();
      hitMID = false;
    } else if (sdpLine.indexOf('a=rtpmap') === 0) {
      console.log('detected bandwidth');
      sdpSection = 'bandwidth';
      sdpStrRet += sdpLine;
      hitMID = false;
    } else {
      sdpStrRet += sdpLine;
    }
    if (
      sdpLine.indexOf('a=mid:') === 0 ||
      sdpLine.indexOf('a=rtpmap') === 0 ||
      sdpLine.indexOf('c=IN ') === 0
    ) {
      if (!hitMID) {
        if ('audio'.localeCompare(sdpSection) === 0) {
          if (enhanceData.audioBitrate > 0) {
            if (bandwidthFactor > 1) {
              sdpStrRet +=
                '\r\nb=TIAS:' + enhanceData.audioBitrate * bandwidthFactor;
            }
            sdpStrRet +=
              '\r\nb=AS:' + enhanceData.audioBitrate * bandwidthFactor;
            sdpStrRet +=
              '\r\nb=CT:' + enhanceData.audioBitrate * bandwidthFactor;
          }
          hitMID = true;
        } else if ('video'.localeCompare(sdpSection) === 0) {
          if (enhanceData.maxVideoBitrate > 0) {
            if (bandwidthFactor > 1) {
              sdpStrRet +=
                '\r\nb=TIAS:' + enhanceData.maxVideoBitrate * bandwidthFactor;
            }
            sdpStrRet +=
              '\r\nb=AS:' + enhanceData.maxVideoBitrate * bandwidthFactor;
            sdpStrRet +=
              '\r\nb=CT:' + enhanceData.maxVideoBitrate * bandwidthFactor;
            if (enhanceData.videoFrameRate > 0 && bandwidthFactor === 1) {
              sdpStrRet += '\r\na=framerate:' + enhanceData.videoFrameRate;
            }
          }
          hitMID = true;
        } else if (
          'bandwidth'.localeCompare(sdpSection) === 0 &&
          bandwidthFactor === 1
        ) {
          console.log('bandwidth');
          const rtpmapType = getRtpMapType(sdpLine);
          if (rtpmapType !== null) {
            if (
              ['vp9', 'vp8', 'h264', 'red', 'ulpfec', 'rtx'].indexOf(
                rtpmapType
              ) >= 0 &&
              enhanceData.maxVideoBitrate > 0
            ) {
              sdpStrRet +=
                '\r\na=fmtp:' +
                id +
                ' x-google-min-bitrate=' +
                enhanceData.maxVideoBitrate +
                ';x-google-max-bitrate=' +
                enhanceData.maxVideoBitrate;
            }
            if (
              ['opus', 'isac', 'g722', 'pcmu', 'pcma', 'cn'].indexOf(
                rtpmapType
              ) >= 0 &&
              enhanceData.audioBitrate > 0
            ) {
              sdpStrRet +=
                '\r\na=fmtp:' +
                id +
                ' x-google-min-bitrate=' +
                enhanceData.audioBitrate +
                ';x-google-max-bitrate=' +
                enhanceData.audioBitrate;
            }
          }
        }
      }
    }
    sdpStrRet += '\r\n';
  });

  return sdpStrRet;
};

export { enhanceSDP };
