import { connect } from 'react-redux';

import Settings from './Settings';

const mapStateToProps = (state) => ({
  isMobile: state.browser.isMobile,
  user: state.user,
  toycontrolActive: !!state.sender?.toycontrol?.active,
  language: state.user.account?.language,
  toycontrolEnabled:
    state.user.account.toy_control === 'enabled' &&
    state.user?.account?.livecam === 'true' &&
    state.browser.thirdPartyCookiesTested &&
    state.browser.thirdPartyCookiesAllowed,
});

export default connect(mapStateToProps)(Settings);

