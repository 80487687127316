import api from '../../../utils/api';
import { addIgnoredIdsUser } from '..';

export default () => async (dispatch, getState) => {
  try {
    let ignoredIds = [];
    const ignoredResponse = await api.get(`/user/ignored/ids`);
    if (!getState().user?.account?.id) {
      return;
    }
    if (ignoredResponse?.data?.length > 0) {
      ignoredIds = ignoredResponse?.data?.map((el) => el.replace('-', '@'));
    }
    dispatch(addIgnoredIdsUser(ignoredIds));
  } catch (err) {
    console.log({ fetchIgnoredIdsError: err });
  }
};
