import { uuid } from '../../../utils';
import { DIARY_ENTRY_DELETE } from '../../actions';

export default (id, requestSent) => (dispatch) => {
  if (requestSent) {
    return dispatch({
      type: DIARY_ENTRY_DELETE,
      payload: { id },
    });
  }

  return dispatch({
    type: DIARY_ENTRY_DELETE,
    payload: { id },
    meta: {
      start: true,
      stamp: uuid(),
    },
  });
};
