import { connect } from 'react-redux';

import CreatorTags from './CreatorTags';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  nickname: state.user?.account?.nickname,
  unconfirmed: state.user?.account?.state === 'unconfirmed',
});
export default connect(mapStateToProps)(CreatorTags);
