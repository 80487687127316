import {
  MANAGEMENT_EVENTS_CONNECTION_DISCONNECT,
  STREAM_EVENTS_STATS,
  STREAM_STOP,
} from '../actions/actions';

const initialState = {
  bandwidth: 0,
  bytesSent: 0,
  fps: 0,
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case STREAM_EVENTS_STATS:
      return Object.assign({}, state, action.payload);
    case MANAGEMENT_EVENTS_CONNECTION_DISCONNECT:
      return initialState;
    case STREAM_STOP:
      return initialState;
    default:
      return state;
  }
};

export default reduce;
