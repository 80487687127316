import classNames from 'classnames';

import iconLeft from '../../../../../../assets/img/svg/first.svg';
import iconRigth from '../../../../../../assets/img/svg/last.svg';
import Button from '../../../../../Button/Button';

function NavButton({ dir, selectend, selectfirst }) {
  const icon = dir === 'left' ? iconLeft : iconRigth;
  const onClick = dir === 'left' ? selectfirst : selectend;
  const classes = classNames('btn-nav', {
    dir,
  });

  return (
    <div className="control-item btn-directions">
      <Button
        intlTranslate={false}
        renumerateIRIElements={false}
        icon={icon}
        variant="icon-only"
        onClick={onClick}
        className={classes}
      />
    </div>
  );
}

export default NavButton;
