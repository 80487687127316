import api from '../../services/sender';
import { TOY_SET_INTENSITY } from '../actions';

export default function (payload) {
  return async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }

    await api.toycontrolIntensity(payload);

    if (!getState().user.isLoggedIn) {
      return;
    }

    return dispatch({ type: TOY_SET_INTENSITY, payload });
  };
}
