export default {
  MY_VIRTUAL_GIFTS_INFO_TEXT: `Auf dieser Seite siehst Du eine Übersicht über alle Geschenke, die Du von Deinen Kunden in den letzten 45 Tagen erhalten hast.`,
  MY_VIRTUAL_GIFTS_INFO_TEXT_1: `Geschenke sind virtuelle Aufmerksamkeiten, die Dir Deine Kunden im Chat machen können.
  Sie werden in der Abrechnung separat aufgeführt.`,
  MY_VIRTUAL_GIFTS_INFO_HEADER: `Welche Geschenke sind in der Übersicht enthalten?`,
  ROUTE_VIRTUAL_GIFTS_HEADLINE: `Eine Übersicht Deiner erhaltenen Aufmerksamkeiten und welche Aufmerksamkeiten es gibt`,
  MY_RECEIVED_VIRTUAL_GIFTS: `Meine erhaltenen Aufmerksamkeiten`,
  NO_VIRTUAL_GIFTS: `Du hast in den letzten 45 Tagen kein Geschenk erhalten.`,
  TABLE_TITLE_NAME: `Name`,
  TABLE_TITLE_LAST_FROM: `Von`,
  TABLE_TITLE_DATE: `Datum`,
  TABLE_TITLE_PRICE: `Preis`,
  TABLE_TITLE_NUMBER: `Anzahl`,
  VIRTUAL_GIFTS_CATEGORY: `Kategorie:`,
  VIRTUAL_GIFTS_CATEGORY_LIST: `Folgende Kategorien sind vorhanden`,
};
