import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';

import ThumbnailItem from '../../ThumbnailItem';

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    display: 'inline-block',
    cursor: 'move',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <ThumbnailItem
        key={props.id}
        id={props.id}
        item={props.item}
        tickOrText={props.tickOrText}
        type="photo"
        openPictureCropper={props.openPictureCropper}
        galleryStatus={props.galleryStatus}
        itemDeleted={props.itemDeleted}
        itemMenuVisible={props.itemMenuVisible}
        updateMenuVisibility={props.updateMenuVisibility}
        setImageError={props.setImageError}
        changeItemPosition={props.changeItemPosition}
      />
    </div>
  );
}

export default SortableItem;

