import './Newsletter.scss';

import useAxios from 'axios-hooks';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NEWSLETTER_PAGINATION_SIZE } from '../../../constants';
import useMediaQuery from '../../../hooks/useMediaQuery';
import api from '../../../utils/api';
import Button from '../../Button/Button';
import Pagination from '../../Pagination/Pagination';
import Scrollbar from '../../Scrollbar/Scrollbar';
import Section from '../../Section/Section';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import DateItem from '../../Tables/TableItems/DateItem';
import NewsletterIframe from '../NewsletterIframe/NewsletterIframe';

function Newsletter({ nickname }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const [newsletters, setNewsletters] = useState([]);
  const [data, setData] = useState();
  const [offset, setOffset] = useState(0);
  const [totalItems, setTotalItems] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [initialNewsletterLoading, setInitialNewsletterLoading] =
    useState(false);

  const [{ loading }, newsletterReq] = useAxios(
    {
      url: `/user/newsletter?l=${NEWSLETTER_PAGINATION_SIZE}&o=${offset}`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );
  const [, newsletterTotalReq] = useAxios(
    {
      url: `/user/newsletter/total`,
      method: 'GET',
    },
    {
      manual: true,
    }
  );

  const openNewsletter = useCallback(
    async (id) => {
      try {
        setInitialNewsletterLoading(true);
        const response = await api.get(`/user/newsletter/${id}`);
        if (response?.data?.html) {
          const htmlNickname = response.data.html.replaceAll(
            '{nickname}',
            nickname
          );
          const htmlTags = htmlNickname.replaceAll(
            'href="#',
            'href="about:srcdoc#'
          );
          setData({ ...response.data, html: htmlTags });
        } else {
          setData(response.data);
        }
        setInitialNewsletterLoading(false);
      } catch (error) {
        console.log(error);
      }
    },
    [nickname]
  );

  useEffect(() => {
    newsletterTotalReq().then((response) => {
      setTotalItems(response.data?.total);
    });
    newsletterReq().then((response) => {
      setNewsletters(response.data);
      const filteredNewsletters = response.data.filter(
        (letter) => letter.test === false
      );
      if (filteredNewsletters.length && isLargeScreen) {
        openNewsletter(filteredNewsletters[0].id);
      }
    });
  }, [newsletterReq, openNewsletter, newsletterTotalReq, isLargeScreen]);

  return loading || initialNewsletterLoading ? (
    <SpinnerComponent />
  ) : (
    <div className="page-wrapper">
      <Section
        title={intl.formatMessage({ id: 'HEADER_PAGE_NEWSLETTER' })}
        className="component-section"
      >
        <>
          {isLargeScreen ? (
            <div className="component-wrapper-no-header-menu">
              <Scrollbar visible={!isLargeScreen}>
                <div className="support-container newsletter-container">
                  <Scrollbar visible={isLargeScreen}>
                    <div className="data-wrapper">
                      <h1 className="headline">
                        <FormattedMessage id="HEADER_TITLE_NEWSLETTER" />
                      </h1>
                      <div className="newsletter-list">
                        {newsletters.length ? (
                          newsletters.map((item) => {
                            return (
                              <div
                                key={item.id}
                                className={`btn btn-icon btn-primary ${
                                  data?.id === item?.id ? 'active' : ''
                                }${item.test ? 'test' : ''}`}
                              >
                                <div
                                  className="newsletter-item"
                                  onClick={() => openNewsletter(item.id)}
                                >
                                  <span className="subject-item">
                                    {item.subject}
                                  </span>
                                  <span className="icon" />
                                  <DateItem
                                    data={item.date}
                                    className="date-item"
                                  />
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p>
                            <FormattedMessage id="ERROR_NO_NEWS_LETTER" />
                          </p>
                        )}
                      </div>
                      <Pagination
                        offset={offset}
                        totalItems={totalItems}
                        pageNumber={pageNumber}
                        setOffset={setOffset}
                        setPageNumber={setPageNumber}
                        paginationSize={NEWSLETTER_PAGINATION_SIZE}
                      />
                    </div>
                  </Scrollbar>
                  <Scrollbar visible={isLargeScreen} hasBoxShadow={true}>
                    <div className="info-wrapper">
                      <div className="iframe-wrapper">
                        <NewsletterIframe data={data} />
                      </div>
                    </div>
                  </Scrollbar>
                </div>
              </Scrollbar>
            </div>
          ) : (
            <div className="component-wrapper-no-header-menu">
              <Scrollbar visible={!isLargeScreen}>
                <div className="support-container newsletter-container">
                  <div className="data-wrapper">
                    {!data ? (
                      <>
                        <h1 className="headline">
                          <FormattedMessage id="HEADER_TITLE_NEWSLETTER" />
                        </h1>
                        <div className="newsletter-list">
                          {newsletters.length ? (
                            newsletters.map((item) => {
                              return (
                                <div
                                  key={item.id}
                                  className={`btn btn-icon btn-primary ${
                                    data?.id === item?.id ? 'active' : ''
                                  }${item.test ? 'test' : ''}`}
                                >
                                  <div
                                    className="newsletter-item"
                                    onClick={() => openNewsletter(item.id)}
                                  >
                                    <span className="subject-item">
                                      {item.subject}
                                    </span>
                                    <span className="icon" />
                                    <DateItem
                                      data={item.date}
                                      className="date-item"
                                    />
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <p>
                              <FormattedMessage id="ERROR_NO_NEWS_LETTER" />
                            </p>
                          )}
                        </div>
                        <Pagination
                          offset={offset}
                          totalItems={totalItems}
                          pageNumber={pageNumber}
                          setOffset={setOffset}
                          setPageNumber={setPageNumber}
                          paginationSize={NEWSLETTER_PAGINATION_SIZE}
                        />
                      </>
                    ) : (
                      <>
                        <div className="iframe-wrapper">
                          <NewsletterIframe data={data} />
                          <div className="button-wrapper">
                            <Button
                              label="BUTTON_BACK"
                              onClick={() => setData(null)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Scrollbar>
            </div>
          )}
        </>
      </Section>
    </div>
  );
}

export default Newsletter;

