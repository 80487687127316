import useAxios from 'axios-hooks';
import { useFormikContext } from 'formik';
import React, { useContext } from 'react';

import StepperContext from '../../../../../Stepper/StepperContext';
import FileItem from '../../../../../Uploader/FileItem/FileItem';

function FileApi({
  file,
  updateDocuments = () => {},
  spinner,
  user,
  reviewFileDeleted,
}) {
  const [
    { error: fileDeletionError, loading: isFileDeleting },
    documentsRequest,
  ] = useAxios(
    {
      url: `/user/documents/${file.id}`,
      method: 'DELETE',
    },
    { manual: true }
  );

  const { setFieldValue } = useFormikContext();
  const { markCurrentItemAsValid, markCurrentItemAsInvalid } =
    useContext(StepperContext);

  function deleteDocumentRequest() {
    if (!isFileDeleting) {
      spinner(true);
      documentsRequest()
        .then((response) => {
          if (response.status === 204) {
            let documents = [...user.private.documents];
            const index = documents.findIndex(
              (document) => document.id === file.id
            );
            documents.splice(index, 1);
            setFieldValue('documents', [...documents]);
            updateDocuments([...documents]);
            if (user.account.state === 'review_needed') {
              reviewFileDeleted();
            }
            if (documents.length === 0) {
              markCurrentItemAsInvalid();
            } else if (documents.every((document) => document.comment === '')) {
              markCurrentItemAsValid();
            }
            spinner(false);
          }
        })
        .catch((error) => {
          if (user.private.documents.length === 0) {
            markCurrentItemAsInvalid();
          }
          spinner(false);
        });
    }
  }

  return (
    <FileItem
      name={file.description}
      status={file.state}
      error={
        file.comment
          ? 'Error'
          : fileDeletionError
          ? 'Deleting failed. Make sure you are connected to the internet.'
          : null
      }
      onDeleteBtnClick={() => deleteDocumentRequest()}
    />
  );
}

export default FileApi;

