import useAxios from 'axios-hooks';
import React from 'react';

function PdfApi({ pdfUrl, children }) {
  const [{ loading: isFileDownloading }, downloadPdfRequest] = useAxios(
    {
      url: pdfUrl,
      responseType: 'arraybuffer',
    },
    { manual: true }
  );

  function downloadDocumentRequest() {
    if (!isFileDownloading) {
      downloadPdfRequest().then(
        (response) => {
          var file = new Blob([response.data], { type: 'application/pdf' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL, '_blank');
        },
        (error) => {}
      );
    }
  }

  return <span onClick={() => downloadDocumentRequest()}>{children}</span>;
}

export default PdfApi;

