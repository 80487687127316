export default {
  ICEBREAKER_HEADLINE: `Das Community Icebreakersystem`,
  ICEBREAKER_INTRO: `Geschaffen um Dir dabei zu helfen, das Eis zum User zu brechen!`,
  ICEBREAKER_HEADLINE_1: `Funktionsweise Icebreaker System`,
  ICEBREAKER_CONTENT_1: `Unser Icebreaker System übernimmt für Dich automatisch mit Hilfe von intuitiv gesteuerten Nachrichten die Initiative und verhilft Dir sehr schnell zu zahlreichen neuen Kontakten und Dialogen mit Usern. Die verschiedenen Icebreaker Typen basieren auf einer smarten und lernenden Logik, welche aufeinander und auf die Verhaltensmuster der User reagieren und damit die Dialogbereitschaft der User, also Deine Verdienstmöglichkeiten, um ein Vielfaches erhöhen!`,
  ICEBREAKER_HEADLINE_2: `Besonderheit der Performancetexte`,
  ICEBREAKER_CONTENT_2: `Die intelligente Steuerung des Icebreakersystem garantiert die optimale und größte Bandbreite an möglichen Neukontakten. Die bestmögliche Konvertierung, sprich Antwortrate, garantieren unsere sogenannten Performance Icebreakertexte!`,
  ICEBREAKER_CONTENT_2_1: `Jeder Icebreakertyp verfügt über einen eigenen Textpool, welcher hunderte Textvarianten beinhaltet. Alle Texte im Icebreakersystem sind einzigartig und wiederholen sich nicht! Die Besonderheit: Alle Texte werden täglich im Rahmen tausender Dialoge und Erstkontakte analysiert und dahingehend ausgewertet, welche von ihnen die höchsten Antwortraten erzielen. Diese bieten dann stets die höchsten Chancen, User zu einem Dialog zu animieren und damit die bestmögliche Performance für den jeweiligen Icebreakertypen.`,
  ICEBREAKER_CONTENT_2_2: `Das intelligente Icebreakersystem und die Performance Icebreakertexte sichern Dir das Maximum an neuen Usern! 100% automatisch und absolut kostenfrei!`,
  ICEBREAKER_HEADLINE_3: `Basics`,
  ICEBREAKER_CONTENT_3: `Das Icebreakersystem ist kein Bot! Es geht nicht darum, den User mit automatischen Nachrichten zu bespaßen. Das System soll Dir den sehr zeitintensiven Erstkontakt so leicht wie möglich machen. Außerdem stellt es sicher, dass Du zeitnah auf Useraktionen reagieren kannst und keine Chance verpasst, einen interessierten User anzusprechen.`,
  ICEBREAKER_CONTENT_3_1: `Solange das Icebreakersystem aktiviert und Du online bist, reagiert das System auf die Aktionen von aktiven Usern, mit denen Du in der Regel bisher noch nie geschrieben hast oder welche Dir bislang noch nie geantwortet haben.`,
  ICEBREAKER_CONTENT_3_2: `Kommt so ein User also beispielsweise zum ersten Mal online, schickt Dir einen Kuss oder schaut sich Dein Profil an, wird dies vom System registriert und eine passende Initialnachricht wird in Deinem Namen versandt. Das Icebreakersystem achtet selbst darauf, dass die Art und die Reihenfolge der Icebreakertexte einen Sinn ergeben. Du musst Dich diesbezüglich also um nichts kümmern!`,
  ICEBREAKER_CONTENT_3_3: `Beachte bitte, dass das System nicht immer eine Nachricht verschickt!`,
  ICEBREAKER_CONTENT_3_4: `Jeder Icebreakertyp unterliegt bestimmten und unterschiedlichen Bedingungen und Wahrscheinlichkeiten, ob und wann diese ausgelöst werden und eine passende Nachricht senden bzw. einen Dialog einleiten. Außerdem spielt es auch eine Rolle, wie gut {link} ist, wie viele Nachrichten der User in den letzten Minuten bereits vom System erhalten hat, wie viele Nachrichten für Dich gerade versandt wurden, wie viele Deiner offenen Dialoge noch auf eine Antwort von Dir warten und vieles mehr.`,
  ICEBREAKER_CONTENT_3_5: `Erst wenn ein User auf eine dieser Nachrichten antwortet, das Eis also erfolgreich gebrochen wurde, übergibt das Icebreakersystem den Dialog an Dich!<br />
Du siehst also, dass Dir dieses System sehr viel Arbeit abnimmt und es Dir ermöglicht, Deine wertvolle Zeit mit Usern zu verbringen, die ein echtes Interesse an einem Chat mit Dir haben! Bevor Du antwortest, solltest Du am Anfang der Unterhaltung lesen, welcher Icebreakertext in Deinem Namen versendet wurde und nahtlos daran anknüpfen!`,
  ICEBREAKER_CONTENT_3_LINK: `Dein aktuelles Ranking`,
  ICEBREAKER_HEADLINE_4: `Woran erkenne ich Icebreaker?`,
  ICEBREAKER_CONTENT_4: `Erhälst Du eine Nachricht von einem User und siehst, dass er nicht initial geschrieben hat, sondern dass in Deinem Namen bereits eine Kontaktaufnahme erfolgte, auf die der User geantwortet hat, dann handelt es sich um eine erfolgreiche Kontaktübergabe vom Icebreakersystem an Dich!
Mach Dich am besten mit den verschiedenen Icebreakertypen vertraut, um in so einem Moment schnell und richtig an den Start der Unterhaltung anknüpfen zu können! Unter einem Icebreaker findest Du auch einen Hinweis, dass es sich um eine vom System versendete Nachricht handelt:`,
  ICEBREAKER_CONTENT_4_1: `Wie im Beispiel oben wirst Du Gelegentlich sogenannte Sexicons sehen. Sexicons sind kleine Bildchen mit einem passenden Text, die bildhaft Interesse verdeutlichen und den User neugierig machen sollen. Hier sind einige Beispiele:`,
  ICEBREAKER_HEADLINE_5: `Inaktivität / Icebreakerversand pausieren`,
  ICEBREAKER_CONTENT_5: `User möchten eine zeitnahe Antwort von Dir erhalten. Bekommen sie diese nicht, verlieren sie das Interesse und Du verlierst einen potentiellen Kontakt.`,
  ICEBREAKER_CONTENT_5_1: `Wir helfen Dir, dass es nicht dazu kommt! Merkt das Icebreakersystem, dass Du eine Zeit lang inaktiv bist, also keine Nachrichten per Hand versendest oder haben sich bei Dir zu viele unbeantwortete Nachrichten aufgestaut, pausiert sich das System automatisch und versendet erst einmal keine weiteren Icebreakertexte für Dich. Darüber wirst Du durch folgendes Hinweisfenster informiert:`,
  ICEBREAKER_CONTENT_5_2: `Du hast dann die Möglichkeit, Dich wieder aktiv zu setzen, sobald Du Dich wieder dem Chat widmen kannst.`,
  ICEBREAKER_HEADLINE_6: `Bilder und Videos hinzufügen – Chancen erhöhen`,
  ICEBREAKER_CONTENT_6: `Um die bestmögliche Performance zu erzielen, empfehlen wir Dir, zu jedem Icebreakertypen ein individuelles Bild oder ein kurzes Video hinzuzufügen. Damit steigerst Du die hohe Antwortrate der Performance Icebreaktertexte noch einmal deutlich.`,
  ICEBREAKER_CONTENT_6_1: `Und so einfach geht es:`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_HEADLINE_1: `Dateianhang hochladen`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_CONTENT_1: `{link} findest Du ebenfalls in einem Wiki-Eintrag.`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_CONTENT_LINK: `Ausführliche Informationen zu Dateianhängen`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_HEADLINE_2: `Dateianhang einem Icebreakertyp zuweisen`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_CONTENT_2: `Ein Bild oder Video kann nur angehängt werden, wenn es noch keinem Icebreaker zugewiesen ist.`,
  ICEBREAKER_HEADLINE_7: `Aktuelle Icebreaktertypen`,
  ICEBREAKER_CONTENT_7: `Wir arbeiten fortlaufend daran, neue Icebreakertypen zu schaffen, sowie die Pools für die bereits vorhandenen Performance Icebreakertexte auszubauen und zu optimieren. Mache Dich unbedingt mit den verschiedenen Icebreakertypen vertraut, damit Du schnell und passend an die vom Icebreakersystem begonnene Unterhaltung anknüpfen kannst.`,
  ICEBREAKER_HEADLINE_8: `Brauche ich User also nie wieder aktiv anschreiben?`,
  ICEBREAKER_CONTENT_8: `Doch, das kannst und solltest Du natürlich auch weiterhin tun! Das Icebreakersystem ist zwar darauf ausgelegt, Dir Deine Arbeit beim Erstkontakt so einfach wie möglich zu machen, aber es kann nie schaden auch selbst <span class="bold">gezielt</span> aktiv zu werden. Vergiss nicht, dass das System nicht immer eine Nachricht verschickt.`,
  ICEBREAKER_CONTENT_8_1: `Bemerkst Du also, dass ein User Dein Profil besucht oder Dir einen Kuss zugeschickt hat, kannst Du auf Nummer sicher gehen und selbst darauf reagieren!<br />
Das Icebreakersystem bemerkt das und versendet für Dich in diesem Moment dann keinen entsprechenden Icebreaker mehr an diesen User. Du musst nur im Chat darauf achten, dass nicht bereits ein Icebreaker für Dich versandt wurde. Sollte das bereits geschehen sein, der User aber nicht darauf reagiert haben, kannst Du natürlich trotzdem noch etwas dazu passendes schreiben - zum Beispiel “Keine Lust zu antworten?`,
  ICEBREAKER_CONTENT_8_2: `<span class="bold">WICHTIG:</span> Das Icebreakersystem versendet keine Nachrichten an User, die Dir bereits geantwortet haben, mit denen also ein Dialog besteht beziehungsweise bestand! Einen “eingeschlafenen” Dialog musst Du also selbst wieder reaktivieren.`,
  ICEBREAKER_CONTENT_8_3: `<span class="bold blue">Wir wünschen Dir jetzt viel Erfolg und gute Umsätze mit dem Icebreakersystem!</span> <br />
Wende Dich gerne an den Support, falls Du weitere Fragen oder Anregungen hast.`,
};
