import { uuid } from '../../utils';
import { management, openOverlay } from '../';
export default () => (dispatch, getState) => {
  const {
    motto: { text },
    overlays,
    user: {
      account: { motto },
    },
  } = getState();

  const hasMottoOverlay = overlays.find((o) => o.Component === 'Motto');

  if (!text && !hasMottoOverlay && motto === 'enabled') {
    const stamp = uuid();
    return dispatch(
      openOverlay({
        stamp,
        Component: 'Motto',
        props: {
          stamp,
        },
      })
    );
  }

  dispatch(management.connect.start());
};
