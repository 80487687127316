import Spinner from './Spinner';

function SpinnerComponent() {
  return (
    <div className="spinner-wrapper">
      <Spinner />
    </div>
  );
}

export default SpinnerComponent;
