import { typingSender } from '../../../../actions';
import {
  roomType as roomTypeUtils,
  user as userUtils,
} from '../../../../utils';
import { MANAGEMENT_MESSAGE_SENDER_SEND2ALL } from '../../../actions';

const createSuccess = (message, senderName) => ({
  type: MANAGEMENT_MESSAGE_SENDER_SEND2ALL,
  payload: {
    message,
    senderName,
  },
});
const chatMessageCallback = (data) => {
  if (data && data.errorStatus !== 0) {
    console.log(`Error in chat Message: ${data.errorStatus}`);
    return;
  }
};

export default (management) => (message) => {
  return (dispatch, getState) => {
    const {
      camUser: { camUsers },
      dialog: { dialogs },
      sender: {
        roomTypes,
        software: { username: senderName },
      },
    } = getState();

    const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
    const params = {
      is2All: true,
      message,
    };
    management.emit('livecamChatMessage', params, chatMessageCallback);
    dispatch(createSuccess(message, senderName));
    if (roomTypeUtils.isEvent(roomTypes)) {
      return dispatch(typingSender(null, ''));
    }
    if (activeUser) {
      dispatch(typingSender(activeUser.userId, ''));
    }
  };
};
