import { SOCKET_SERVICE_STEP_RECONNECTING } from '../../../constants';
import { CONNECT_SOCKET_SERVICE } from '../../actions';

export default (attempt) => (dispatch) => {
  dispatch({
    type: CONNECT_SOCKET_SERVICE,
    payload: {
      step: SOCKET_SERVICE_STEP_RECONNECTING,
    },
  });
  console.debug({ reconnect: attempt });
};
