import {
  MANAGEMENT_CAM_LOGIN,
  MANAGEMENT_EVENTS_CONNECTION_DISCONNECT,
  MANAGEMENT_LOG_FPS,
} from '../actions/actions';

const initialState = {
  maxRetries: 3,
  min: 35,
  count: 0,
  sent: false,
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case MANAGEMENT_LOG_FPS:
      if (action.meta && action.meta.increment) {
        return Object.assign({}, state, {
          count: state.count + 1,
        });
      }
      return Object.assign({}, state, { sent: true });
    case MANAGEMENT_EVENTS_CONNECTION_DISCONNECT:
      return initialState;
    case MANAGEMENT_CAM_LOGIN:
      if (
        action.error ||
        (action.meta && (action.meta.start || action.meta.end))
      ) {
        return state;
      }
      return Object.assign(initialState, {
        maxRetries: action.payload.fps.times,
        min: action.payload.fps.min,
      });
    default:
      return state;
  }
};

export default reduce;
