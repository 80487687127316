import capacityAvailable from './capacity/available';
import capacityExceeded from './capacity/exceeded';
import close from './close';
import connect from './connect';
import connect_error from './connectError';
import error from './error';
import gameInvitation from './game/invitation';
import gameInvitationUpdated from './game/invitationUpdated';
import gameSessionUpdated from './game/sessionUpdated';
import marco from './marco';
import messageReceived from './messageReceived';
import messageSent from './messageSent';
import ping from './ping';
import reconnect from './reconnect';
import reconnect_attempt from './reconnectAttempt';
import reconnect_failed from './reconnectFailed';
import salesAdd from './salesAdd';
import salesCurrent from './salesCurrent';
import toycontroldataChanged from './toycontroldataChanged';
import userBusy from './userBusy';
import userIdle from './userIdle';
import userKeepAlive from './userKeepAlive';
import userKeepIdle from './userKeepIdle';
import userOffline from './userOffline';
import userOnline from './userOnline';
import veriffStatusChanged from './veriff/statusChanged';
import versionUpdate from './version/update';

export default (socketService) => ({
  marco: marco(socketService),
  error,
  reconnect,
  reconnect_attempt,
  reconnect_failed,
  ping,
  close,
  connect,
  connect_error,
  'message.received': messageReceived,
  'user.online': userOnline,
  'user.offline': userOffline,
  'user.idle': userIdle,
  'user.busy': userBusy,
  'user.keep-idle': userKeepIdle,
  'user.keep-alive': userKeepAlive,
  'message.sent': messageSent,
  'sales.current': salesCurrent,
  'sales.message': salesAdd,
  'sales.livecam': salesAdd,
  'sales.livecamtip': salesAdd,
  'sales.movie': salesAdd,
  'sales.payttachment': salesAdd,
  'sales.picture': salesAdd,
  'sales.regard': salesAdd,
  'sales.sexicon': salesAdd,
  'sales.kiss': salesAdd,
  'sales.game': salesAdd,
  'sales.toycontrol': salesAdd,
  'toycontroldata.changed': toycontroldataChanged,
  'capacity.exceeded': capacityExceeded,
  'capacity.available': capacityAvailable,
  'game.invitation': gameInvitation,
  'game.invitation.updated': gameInvitationUpdated,
  'game.session.updated': gameSessionUpdated,
  'lcr.version.updated': versionUpdate,
  'veriff.status.changed': veriffStatusChanged,
});
