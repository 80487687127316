import { useState } from 'react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import camIcon from '../../../../../../assets/img/svg/cam.svg';
import { STREAMING_TIME_OFFSET } from '../../../../../../constants';
import flags from '../../../../../../utils/flags';

function User({
  name,
  userId,
  profileCountry,
  typing,
  isMobile,
  isLivecam,
  showStartTime,
}) {
  const intl = useIntl();
  const flag = flags[profileCountry];
  const [streamingTime, setStreamingTime] = useState('');

  useEffect(() => {
    if (!showStartTime) return;

    const interval = setInterval(() => {
      let streamingTimeCalc =
        Math.floor((Date.now() - new Date(showStartTime).getTime()) / 1000) -
        STREAMING_TIME_OFFSET;
      if (streamingTimeCalc <= 0) {
        return setStreamingTime('');
      }
      const minutes = Math.floor(streamingTimeCalc / 60);
      const seconds = streamingTimeCalc % 60;

      setStreamingTime(
        ` | ${minutes}:${seconds < 10 ? '0' : ''}${seconds} min`
      );
    }, 1000);

    return () => {
      clearInterval(interval);
      setStreamingTime('');
    };
  }, [setStreamingTime, showStartTime]);

  return isMobile ? (
    <div className="user">
      {isLivecam && (
        <span className="livecam-flag">
          <ReactSVG src={camIcon} wrapper={'span'} />
        </span>
      )}
      <span className="name">
        <span>{userId ? name : intl.formatMessage({ id: 'no.user' })}</span>

        {typing && (
          <span className="is-typing">
            {intl.formatMessage({ id: 'user.is.typing' })}
          </span>
        )}
      </span>
      {flag && (
        <span className="flag">
          <ReactSVG src={flag} wrapper="span" />
        </span>
      )}
    </div>
  ) : (
    <div className="user">
      {flag && (
        <span className="flag">
          <ReactSVG src={flag} wrapper="span" />
        </span>
      )}
      <span className="name">
        <span className="user-name">
          {userId ? name : intl.formatMessage({ id: 'no.user' })}
        </span>

        {isLivecam && streamingTime ? (
          <span className="streaming-time">{streamingTime}</span>
        ) : null}
        {typing && (
          <span className="is-typing">
            {intl.formatMessage({ id: 'user.is.typing' })}
          </span>
        )}
      </span>
    </div>
  );
}

export default User;
