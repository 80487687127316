import './InlinePayoutsTable.scss';

import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';

import NumberItem from '../../TableItems/NumberItem';

function InlinePayoutsTable({ items = [], language, precision, currency }) {
  const history = useHistory();
  const intl = useIntl();
  function redirectToBillDetails(billId) {
    history.push({
      pathname: '/my-account/commission/payout-details',
      search: `?bill_id=${billId}`,
    });
  }

  return (
    <table>
      <thead>
        <tr>
          <th>
            <FormattedMessage id="PARKING_CREDITS_BILL" />
          </th>
          <th>
            <FormattedMessage id="PARKING_CREDITS_BILLINGPERIOD" />
          </th>
          <th className="inline-payouts-last-column">
            <FormattedMessage id="PAYOUT_AMOUNT" />
          </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          return (
            <tr key={`${item.id}.${index}`}>
              <td
                onClick={() => redirectToBillDetails(item.id)}
                className="bill-item"
              >
                {item.bill_id}
              </td>
              <td>
                {item?.start &&
                  dayjs(item.start).format(
                    language === 'de' ? 'D. MMM YYYY' : 'MMM D, YYYY'
                  )}{' '}
                {intl.formatMessage({ id: 'UNTIL' }) + ' '}
                {item?.end &&
                  dayjs(item?.end).format(
                    language === 'de' ? 'D. MMM YYYY' : 'MMM D, YYYY'
                  )}
              </td>
              <td className="inline-payouts-last-column">
                <NumberItem
                  number={item.revenue}
                  precision={precision}
                  currency={currency}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default InlinePayoutsTable;

