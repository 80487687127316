import './Webinar.scss';

import classNames from 'classnames';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import logo from '../../../assets/img/logo-small.svg';
import Button from '../../Button/Button';
import SpinnerComponent from '../../Spinner/SpinnerComponent';

function Webinar({ webinarLoading, webinarData, language }) {
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const classes = classNames('dashboard-col-wrapper banner-wrapper webinar', {
    'webinar-loading': webinarLoading,
  });
  return (
    <div className={classes}>
      <div className="banner">
        {webinarLoading ? (
          <SpinnerComponent />
        ) : (
          <>
            <div className="logo-wrapper">
              <ReactSVG src={logo} className="logo-small" wrapper="span" />
            </div>
            <div className="text-wrapper">
              <h1 className="headline">
                <FormattedMessage id="DASHBOARD_WEBINAR_ONLINE" />
                <span className="small">
                  {' '}
                  <FormattedMessage id="DASHBOARD_WEBINAR_BEGINNERS" />
                </span>
              </h1>
              {webinarData?.title && (
                <p className="banner-text topic">{webinarData?.title}</p>
              )}
              {webinarData?.date && (
                <p className="banner-text">
                  {dayjs(webinarData?.date).format('dddd')}
                  <br />
                  <span className="bold">
                    {language === 'de'
                      ? dayjs(webinarData?.date).format('DD.MM.YYYY - HH:mm') +
                        ' Uhr'
                      : dayjs(webinarData?.date).format('MMM D, YYYY h:mm A')}
                  </span>
                </p>
              )}
              {webinarData?.link && (
                <div className="button-wrapper">
                  <Button
                    label="DASHBOARD_WEBINAR_JOIN_NOW"
                    variant="primary"
                    className="btn-webinar"
                    onClick={() => openInNewTab(webinarData.link)}
                  />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Webinar;

