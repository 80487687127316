import './DashboardLink.scss';

import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import arrowIcon from '../../assets/img/svg/arrow-right.svg';

function DashboardLink({ title = '', content, path }) {
  const className = classNames('dashboard-link-wrapper');
  return (
    <Link className={className} to={path}>
      <div className="link-text-wrapper">
        <span className="link-title-wrapper">{title}</span>
        <span className="link-content-wrapper">{content}</span>
      </div>
      <div className="link-icon-wrapper">
        <ReactSVG src={arrowIcon} wrapper="span" />
      </div>
    </Link>
  );
}

export default DashboardLink;

