import './FieldWithErrors.scss';

import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import React from 'react';

import Field from '../Field';
import InputWithLabel from '../InputWithLabel/InputWithLabel';

function FieldWithErrors({
  id,
  name,
  as = InputWithLabel,
  markAsRequired,
  children,
  label,
  disabled,
  reviewError,
  textarea,
  small,
  trim,
  ...otherProps
}) {
  const [, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const hasError = meta.error && meta.touched;

  const className = classNames('field-error-message-wrapper', {
    [`${id || name}-wrapper`]: id || name,
    'has-error': hasError || reviewError,
    disabled: disabled,
    'text-area': textarea,
  });

  const fieldClassName = classNames({
    small,
  });

  return (
    <InputWithLabel
      id={id}
      name={name}
      markAsRequired={markAsRequired}
      label={label}
      disabled={disabled}
      {...otherProps}
    >
      <div className={className}>
        {children ? (
          children
        ) : (
          <Field
            as={as}
            id={id}
            name={name}
            disabled={disabled}
            className={fieldClassName}
            onChange={(e) => {
              let value = e.target.value || '';
              if (trim) {
                value = value.trim();
              }
              setFieldValue(name, value);
            }}
            {...otherProps}
          />
        )}

        {(hasError || reviewError) && (
          <div className="error-message">
            {reviewError ? reviewError : meta.error}
          </div>
        )}
      </div>
    </InputWithLabel>
  );
}

export default FieldWithErrors;
