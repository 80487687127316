import api from '../../../../utils/api';
import log from '../../../../utils/logApi';
import { userLogout, userRefreshAuthToken } from '../../../user';
import { resetCam } from '../../..';

export default (payload) => (dispatch, getState) => {
  const { user } = getState();

  if (
    ['approved', 'mandatory', 'declined', 'not_mandatory'].includes(
      payload?.payload?.status
    )
  ) {
    if (payload?.payload?.status === 'mandatory') {
      dispatch(resetCam());
    }
    api
      .post(
        '/oauth/token',
        {
          refresh_token: user.refresh_token,
          grant_type: 'refresh_token',
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          is_logged_in: !!user.isLoggedIn,
        },
        { skipAttachingAuthToken: true }
      )
      .then((res) => {
        dispatch(userRefreshAuthToken(res.data));
        window.location.reload();
      })
      .catch((err) => {
        log({
          location: 'veriff',
          message: 'statusChanged',
          userId: user?.account?.id,
          sendername: user?.account?.nickname,
          language: user?.account?.language,
          state: user?.account?.state,
          level: 'warn',
          time: new Date(),
          data: {
            error: err.message,
          },
        });

        dispatch(userLogout());
        window.location.reload();
      });

    return;
  }
};
