// for operators and matching @see https://github.com/lancedikson/bowser
import bowser from 'bowser';

import { COOKIE } from '../actions/actions';

const allowed = [
  {
    name: 'chrome',
    minVersion: '80.0.0',
    operator: '>',
  },
  {
    name: 'firefox',
    minVersion: '75.0.0',
    operator: '>',
  },
  {
    name: 'opera',
    minVersion: '67.0.0',
    operator: '>',
  },
  {
    name: 'edge',
    minVersion: '80.0.0',
    operator: '>',
  },
];

const paramterEncoding = [
  {
    name: 'chrome',
    minVersion: '80.0.0',
    operator: '>',
  },
  {
    name: 'firefox',
    minVersion: '75.0.0',
    operator: '>',
  },
  {
    name: 'opera',
    minVersion: '67.0.0',
    operator: '>',
  },
  {
    name: 'edge',
    minVersion: '80.0.0',
    operator: '>',
  },
];

const getBrowserConfig = (browserArray) => {
  const browserConfig = {};
  browserArray.forEach((b) => {
    browserConfig[b.name] = b.operator + b.minVersion;
  });
  return browserConfig;
};

let browser;
// bowser ist still beta, so it has it's pitfalls, thats why we use a try/catch to avoid errors
try {
  browser = bowser.getParser(window.navigator.userAgent);
  process.env?.BABEL_ENV !== 'test' &&
    console.log(
      `Browser is ${browser.getBrowserName()} in version ${browser.getBrowserVersion()}`
    );
} catch (error) {
  console.log(`unabled to use bowser with ua ${window.navigator.userAgent}`);
  browser = {
    getBrowserName: () => 'none',
    getBrowserVersion: () => 'none',
    getPlatform: () => ({ type: 'desktop' }),
  };
}

const initialState = {
  name: browser.getBrowserName(),
  version: browser.getBrowserVersion(),
  isBot: browser.getPlatformType() === 'bot',
  isMobile:
    browser.getPlatform().type === 'mobile' ||
    browser.getPlatform().type === 'tablet',
  allowed,
  paramterEncodingAllowed: browser.satisfies(
    getBrowserConfig(paramterEncoding)
  ),
  isAllowed: browser.satisfies(getBrowserConfig(allowed)),
  cookiesAllowed: navigator.cookieEnabled,
  thirdPartyCookiesAllowed: false,
  thirdPartyCookiesTested: false,
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case COOKIE:
      return {
        ...state,
        ...action.payload,
        thirdPartyCookiesTested: true,
      };
    default:
      return state;
  }
};

export default reduce;
