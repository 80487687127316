import api from '../../services/sender';
import { TOYCONTROL_CALL } from '../actions';

export default function () {
  return async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }

    try {
      const payload = await api.toycontrol({});

      if (!getState().user.isLoggedIn) {
        return;
      }

      return dispatch({ type: TOYCONTROL_CALL, payload });
    } catch (error) {
      dispatch({ type: TOYCONTROL_CALL, payload: error, error: true });
    }
  };
}
