import './Form.scss';

import useAxios from 'axios-hooks';
import { Field, Formik, Form as FormikForm } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

import loginIcon from '../../../../assets/img/svg/login.svg';
import { lStorage } from '../../../../services/storage';
import { getCookieHintData } from '../../../../utils';
import Button from '../../../Button/Button';
import Checkbox from '../../../Forms/Checkbox/Checkbox';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import FormErrorMessage from '../../../Forms/FormErrorMessage/FormErrorMessage';
import Input from '../../../Forms/Input/Input';
import Password from '../../../Forms/Password/Password';
import Notification from '../../../Notification/Notification';

const getValidationScheme = (email, required) =>
  Yup.object().shape({
    language: Yup.string(),
    username: Yup.string().email(email).required(required),
    password: Yup.string().required(required),
    remember_me: Yup.bool(),
  });

const noNotification = {
  id: 0,
  key: '',
  language: '',
  headline: '',
  text: '',
  type: '',
  created: '',
};

function Form({
  addAuthToken = () => {},
  locale = 'en',
  startAuth = () => {},
  cookies = true,
  cookie = () => {},
  logout,
}) {
  const intl = useIntl();
  const formRef = useRef();
  const [password, setPassword] = useState('');
  const validationSchema = getValidationScheme(
    intl.formatMessage({ id: 'VALIDATION_EMAIL' }),
    intl.formatMessage({ id: 'VALIDATION_REQUIRED_FIELD' })
  );
  const rememberMe =
    '' + lStorage.getItem('rememberMe') === 'true' ? true : false;

  const [notification, setNotification] = useState(noNotification);

  const initialValues = {
    username: '',
    password: '',
    remember_me: !lStorage.getItem('rememberMe') ? true : rememberMe,
  };

  const [{ data: loginRequestData, error: loginError }, loginRequest] =
    useAxios(
      {
        url: '/oauth/token',
        method: 'POST',
      },
      { manual: true }
    );

  useEffect(() => {
    if (!loginRequestData) return;
    if (loginRequestData.token_type === 'Error') {
      setNotification(
        loginRequestData.notification?.tips_texts
          ?.filter(({ language }) => language === locale)
          ?.at(0) ?? noNotification
      );
      return;
    } else {
      setNotification(noNotification);
    }
    lStorage.setItem('rememberMe', formRef.current.values.remember_me);
    addAuthToken(loginRequestData, formRef.current.values.remember_me);

    lStorage.setItem('navigationTooltip', 1);
    lStorage.setItem('finaliseTooltip', 1);
  }, [addAuthToken, loginRequestData, locale, password, startAuth, logout]);

  useEffect(() => {
    if (!loginError) {
      return;
    }

    formRef.current.setSubmitting(false);
  }, [loginError]);

  function handleFormSubmission(values, actions) {
    if (!navigator.cookieEnabled) {
      const cookieHintData = getCookieHintData(locale);

      return cookie(false, cookieHintData);
    }
    const payload = {
      grant_type: 'password',
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      scope: '*',
      ...values,
    };
    setPassword(payload.password);

    loginRequest({
      data: payload,
    })
      .then(() => {
        if (!lStorage.getItem('hasUsedDevice')) {
          lStorage.setItem('hasUsedDevice', true);
        }
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  }

  return (
    <div className="login-form-wrapper">
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmission}
      >
        {({ isSubmitting }) => {
          return (
            <FormikForm className="login-form">
              {notification?.id ? (
                <div className="notification-items-wrapper">
                  <Notification
                    id={notification.key}
                    type={notification.type}
                    title={notification.headline}
                    content={notification.text}
                    pinnable={false}
                    closable={false}
                  />
                </div>
              ) : (
                <FormErrorMessage
                  isShown={
                    loginError || loginRequestData?.token_type === 'Error'
                  }
                  title={intl.formatMessage({ id: 'ERROR' })}
                  message={
                    loginRequestData?.token_type === 'Error'
                      ? loginRequestData?.reason ===
                        'login_restricted_male_creator'
                        ? intl.formatMessage({ id: 'ERROR_MALE_ACCOUNT' })
                        : intl.formatMessage({ id: 'ERROR_BLOCKED_ACCOUNT' })
                      : loginError?.response?.status === 400
                      ? intl.formatMessage({ id: 'ERROR_INVALID_CREDENTIALS' })
                      : intl.formatMessage({ id: 'ERROR_REGISTER_GENERAL' })
                  }
                />
              )}

              <FieldWithErrors
                as={Input}
                name="username"
                label="FIELD_EMAIL"
                trim={true}
              />

              <FieldWithErrors
                as={Password}
                name="password"
                label="FIELD_PASSWORD"
              />

              <div className="form-group">
                <span className="column" />
                <Button
                  type="submit"
                  icon={loginIcon}
                  label={
                    isSubmitting && navigator.cookieEnabled
                      ? 'LOADING'
                      : 'BUTTON_LOGIN'
                  }
                  isLoading={isSubmitting && navigator.cookieEnabled}
                  id="submit-btn"
                  className="btn-form"
                  disabled={!cookies}
                />
              </div>

              <Field
                as={Checkbox}
                name="remember_me"
                label="FIELD_STAY_LOGGED_IN"
                onClick={(el) =>
                  lStorage.setItem('rememberMe', el.target.checked)
                }
                defaultChecked={initialValues.remember_me}
              />
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
}

export default Form;

