import { connect } from 'react-redux';

import { spinner, userUpdate as update } from '../../../../actions';
import ProfileText from './ProfileText';

const mapDispatchToProps = {
  update,
  spinner,
};

export default connect(null, mapDispatchToProps)(ProfileText);
