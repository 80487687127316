import { useRef } from 'react';

import useOutsideClick from '../../../../hooks/useOutsideClick';
import Button from '../../../Button/Button';

function UserTabMenu({
  open,
  username,
  kickUser,
  ignoreUser,
  toggleUserMenu,
  hasUser,
  isLivecamUser,
  disableIgnore,
}) {
  const menuRef = useRef();

  const ignore = () => {
    ignoreUser(hasUser);
  };

  useOutsideClick(menuRef, () => {
    if (open) {
      toggleUserMenu();
    }
  });
  return open && username ? (
    <div className="toggle-menu user-menu" ref={menuRef}>
      {isLivecamUser && (
        <Button
          variant="default"
          className="user-menu-item"
          label="btn.title.kickUser"
          onClick={() => {
            kickUser();
            toggleUserMenu();
          }}
        />
      )}
      <Button
        variant="default"
        className="user-menu-item"
        label="btn.title.ignoreUser"
        disabled={disableIgnore}
        onClick={() => {
          ignore();
          toggleUserMenu();
        }}
      />
    </div>
  ) : null;
}

export default UserTabMenu;
