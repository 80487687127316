import { connect } from 'react-redux';

import { toggleUsergameView } from '../../../../../../actions';
import { user } from '../../../../../../utils';
import Usergame from './Usergame';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
  } = state;

  const activeUser = user.activeUser([...camUsers, ...dialogs]);

  return {
    ...state.view,
    showGame: user.showGame(activeUser),
  };
};

const mapDispatchToProps = {
  toggleUsergameView,
};

export default connect(mapStateToProps, mapDispatchToProps)(Usergame);
