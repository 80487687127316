import './NewsletterIframe.scss';

function NewsletterIframe({ data = {} }) {
  if (!data?.html) {
    return null;
  }
  return (
    <iframe
      id="newsletteriframe"
      srcDoc={data.html}
      width="100%"
      height="100%"
      title="newsletteriframe"
      name="iframenewslettername"
      className="i-frame"
      onLoad={(ev) => {
        if (ev.target instanceof HTMLIFrameElement) {
          ev.target.style.height =
            ev.target.contentDocument.body.scrollHeight + 'px';
        }
      }}
    ></iframe>
  );
}

export default NewsletterIframe;

