import { CAM_STOP } from '../actions';

export default () => (dispatch, getState) => {
  const stream = getState().streaming.stream;
  if (stream && stream.getTracks) {
    for (let track of stream.getTracks()) {
      track.stop();
    }
  }
  dispatch({
    type: CAM_STOP,
  });
};
