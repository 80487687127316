import { COUNTRIES_LIST_UPDATE } from '../actions/actions';

const initialState = [];

function countriesReducer(state = initialState, action) {
  switch (action.type) {
    case COUNTRIES_LIST_UPDATE:
      return action.payload;

    default:
      return state;
  }
}

export default countriesReducer;
