import * as Yup from 'yup';

export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getDeviceOptions = (mediaDevices) =>
  mediaDevices
    .filter(({ kind }) => kind === 'videoinput')
    .map(
      ({ deviceId, label }, index) => ({
        value: deviceId,
        label: label || `Cam ${index + 1}`,
      }) // if we do not get the right names we call the cams Cam 1, Cam 2 and so on
    );

export const validateFilenameScheme = Yup.object().shape({
  filename: Yup.string()
    .required('VALIDATION_FILENAME_REQUIRED')
    .min(8, 'VALIDATION_FILENAME_AT_LEAST_5_CHARACTERS')
    .max(53, 'VALIDATION_FILENAME_AT_MOST_50_CHARACTERS')
    .test({
      test: function (value) {
        if (typeof value === 'string' && !value.match(/(\.jpg){1}$/i)) {
          return this.createError({
            message: 'VALIDATION_FILENAME_ENDING',
          });
        }

        return true;
      },
    }),
});
