import { user as userUtils } from '../../../utils';
import api from '../../../utils/api';
import { GAME_INVITATION_ACCEPT } from '../../actions';

export default (id) => async (dispatch, getState) => {
  try {
    if (
      !userUtils.gamesEnabled(getState()) &&
      userUtils.hasGamesScope(getState())
    )
      return;
    await api.put(`/user/games/invitation-status/${id}`, {
      status: 'accepted',
    });
  } catch (err) {
    dispatch({
      type: GAME_INVITATION_ACCEPT,
      error: true,
    });
    console.log({ gameInvitationAcceptError: err });
    console.log('could not accept game invitation');
    return false;
  }
};
