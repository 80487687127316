import { NOTIFICATION_CLOSE, NOTIFICATION_SEND } from '../actions/actions';

const initialState = [];

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATION_SEND:
      return [
        ...state,
        Object.assign({}, action.payload, { stamp: action.meta.stamp }),
      ];
    case NOTIFICATION_CLOSE:
      return state.filter((n) => n.stamp !== action.meta.stamp);
    default:
      return state;
  }
};

export default reduce;
