import {ALERT_CANCEL} from '../actions';

export default (stamp) => {
  return {
    type: ALERT_CANCEL,
    payload: {
      stamp
    }
  };
};
