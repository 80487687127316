import {STREAM_EVENTS_STATS} from "../actions/actions";

const logger = store => next => action => {
  if (action.type !== STREAM_EVENTS_STATS) {
    console.log('logger: dispatching', action);
    console.log('logger: state before', store.getState());
  }
  let result = next(action);
  if (action.type !== STREAM_EVENTS_STATS) {
    console.log('logger: next state', store.getState());
  }
  return result;
};
export default logger;
