import { connect } from 'react-redux';

import FinishedComponent from './FinishedComponent';

const mapStateToProps = (state) => ({
  user: state.user,
  isMobile: state.view.isMobile,
});

export default connect(mapStateToProps)(FinishedComponent);

