import './Item.scss';

import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

function Item({ isActive, item, disabled, ...other }) {
  if (!item?.text && !item?.icon && !item?.image) {
    return new Error(
      `One of 'text', 'icon' or 'image' is required for the item prop in 'Item'.`
    );
  }

  if (!item?.path && !item?.onClick) {
    return new Error(
      `When a path is not specified, an onClick function is required by 'Item'.`
    );
  }

  const {
    path,
    title,
    text,
    icon,
    image,
    onClick,
    itemClass,
    additionalContent,
    additionalContentClass,
  } = item;

  const wrapperClassNames = classNames('navbar-item-wrapper', {
    active: isActive,
  });

  const itemClassNames = classNames('navbar-item', itemClass, {
    active: isActive,
    text: text,
    icon: icon,
    image: image,
    disabled: disabled,
  });

  let content;
  if (image) {
    content = (
      <div
        style={{
          backgroundImage: `url(${image})`,
        }}
        className="navbar-item-content"
      />
    );
  } else if (text) {
    content = <span>{text}</span>;
  } else if (icon) {
    const additionalContentClasses = classNames('additional-number', {
      [additionalContentClass]: additionalContentClass,
    });
    content = (
      <>
        {!!additionalContent && (
          <span className={additionalContentClasses}>{additionalContent}</span>
        )}
        <ReactSVG src={icon} wrapper="span" />
      </>
    );
  }

  return (
    <div title={title} className={wrapperClassNames}>
      {path && !disabled ? (
        <Link to={path} className={itemClassNames} {...other}>
          {content}
        </Link>
      ) : (
        <div className={itemClassNames} {...other} onClick={onClick || null}>
          {content}
        </div>
      )}
    </div>
  );
}

export default Item;

