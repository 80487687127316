import { connect } from 'react-redux';

import {
  closeOverlay,
  toggleVeriffBar,
  toggleVeriffFrame,
} from '../../../actions';
import VeriffOverlay from './VeriffOverlay';

const mapDispatchToProps = {
  toggleVeriffFrame,
  toggleVeriffBar,
  closeOverlay,
};
const mapStateToProps = (state) => ({
  isMobile: state.browser.isMobile,
});

export default connect(mapStateToProps, mapDispatchToProps)(VeriffOverlay);
