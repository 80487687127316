import './LanguageSelect.scss';

import React from 'react';
import { ReactSVG } from 'react-svg';

import deIcon from '../../../assets/img/svg/flags/de.svg';
import enIcon from '../../../assets/img/svg/flags/gb.svg';

const languageSelectOptions = [
  { value: 'de', icon: deIcon },
  { value: 'en', icon: enIcon },
];

function LanguageSelect({ changeLocale }) {
  return (
    <div className="language-select">
      {languageSelectOptions.map(({ value, icon }) => {
        return (
          <ReactSVG
            key={value}
            src={icon}
            className="language-select-icon"
            wrapper="span"
            onClick={() => changeLocale(value)}
          />
        );
      })}
    </div>
  );
}

export default LanguageSelect;
