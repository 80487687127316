import React from 'react';
import { FormattedMessage } from 'react-intl';
function MessengerItem({
  item = {},
  isActive = false,
  onMenuToggle = () => {},
  additionalContent,
  onClick,
  isMobile,
}) {
  const click = (e) => {
    isMobile && onMenuToggle();
    onClick && onClick(e);
  };

  return (
    <div className={`item ${isActive ? 'active' : ''}`} onClick={click}>
      <span className="menu-name">
        <FormattedMessage id={item.title} />
      </span>
      <span className="additional-content">{additionalContent}</span>
    </div>
  );
}

export default React.memo(MessengerItem);
