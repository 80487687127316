import { connect } from 'react-redux';

import { answerFriendRequest } from '../../../actions';
import MyRelationships from './MyRelationships';

const mapStateToProps = (state) => ({
  user: state.user,
  friendRequests: state.friends.total,
});

const mapDispatchToProps = (dispatch) => ({
  answerFriendRequest: (requestSent, userId) =>
    dispatch(answerFriendRequest(requestSent, userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyRelationships);
