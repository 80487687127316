export default {
  ICEBREAKER_HEADLINE: 'Edit my Icebreakers',
  ICEBREAKER_SETTINGS: 'Icebreaker Settings',
  ICEBREAKER_ADD_ATTACHMENT: 'add',
  ICEBREAKER_SCROLL: 'Read here how the Icebreaker System works',
  ICEBREAKER_PARAGRAPH_CALLTOACTION:
    'Add an appealing image or video to this Icebreaker, increasing the chance of an answer.',
  ICEBREAKER_HEADLINE_PICTURE: 'Picture Icebreaker',
  ICEBREAKER_DUPLICATE_EXPLANATION:
    'You can only use pictures or videos, that have not yet been assigned to any other icebreaker.',
  ICEBREAKER_PARAGRAPH_EXPLANATION_PICTURE:
    'A picture speaks more than a thousand words. Start and sweeten an Icebreaker dialogue with a private picture of you. Allow the icebreaker system to link various other icebreakers to an even more interesting introductory dialog with this image. This will make your first contact with users even more appealing! Icebreakers, which contain and are linked by images, have the highest response rate.Allow the Icebreakersystem to make your first contact with users even more appealing with this picture! Icebreakers that contain and are linked by images have the highest response rate.',
  ICEBREAKER_HEADLINE_FIRST_LOGIN: 'I. First login of a user',
  ICEBREAKER_PARAGRAPH_EXPLANATION_FIRST_LOGIN:
    'Sent as a greeting to users who log into the portal for the first time after registering.',
  ICEBREAKER_HEADLINE_RECURRENT_LOGIN: 'II. Another login of a user',
  ICEBREAKER_PARAGRAPH_EXPLANATION_RECURRENT_LOGIN:
    'A greeting that is sent to users who have been logged into the portal several times, but have not yet had any contact with you.',
  ICEBREAKER_HEADLINE_KISS_SENT: 'III. Kiss sent',
  ICEBREAKER_PARAGRAPH_EXPLANATION_KISS_SENT:
    'If you do not manage to react promptly to a kiss received, this Icebreaker will be sent for you. The text refers to the fact that you received a kiss.',
  ICEBREAKER_HEADLINE_PROFILE_VISIT: 'IV. Profile visit',
  ICEBREAKER_PARAGRAPH_PROFILE_VISIT:
    'If a user has visited your profile, there is a chance that this Icebreaker will be sent and will address the user on the profile visit.',
  ICEBREAKER_HEADLINE_FOLLOWUP: 'V. Follow up on unanswered Icebreaker',
  ICEBREAKER_PARAGRAPH_EXPLANATION_FOLLOWUP:
    'If the user does not respond to your “First login” or “Profile visit” Icebreaker, there is a chance that this Icebreaker will be sent here a few minutes after your first message.',
  ICEBREAKER_HEADLINE_USER_PICTURE_WANTED: 'VI. User picture wanted',
  ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PICTURE_WANTED:
    'This Icebreaker asks the user that he has not yet uploaded a profile picture. If the user does not reply to this, there is a chance that a corresponding follow-up text will be sent that is linked to the first message.',
  ICEBREAKER_HEADLINE_NEW_PROFILEIMAGE: 'VII. User profile picture upload',
  ICEBREAKER_PARAGRAPH_EXPLANATION_NEW_PROFILEIMAGE:
    'This Icebreaker gives the user positive feedback if they have uploaded a profile picture.',
  ICEBREAKER_HEADLINE_USER_PREFERENCES_WANTED: 'VIII. User preferences wanted',
  ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PREFERENCES_WANTED:
    'The user has not yet selected any preferences in his profile and this is used as a hook. If the user does not reply to this, there is also the chance that a follow-up text will be sent that is linked to the first message.',
  ICEBREAKER_HEADLINE_USER_PREFERENCES_MATCH: 'IX. User preferences match',
  ICEBREAKER_PARAGRAPH_EXPLANATION_USER_PREFERENCES_MATCH:
    "This Icebreaker will be sent as soon as individual preferences match. So you don't know beforehand which preferences you have the overlaps and you should quickly check the user's profile before you answer!",
  ICEBREAKER_HEADLINE_HON_MATCH: 'X. Matchmaker hit',
  ICEBREAKER_PARAGRAPH_EXPLANATION_HON_MATCH:
    'The user has rated your profile picture positively and seem to like you. The Icebreaker assumes that you have also rated the user positively and uses a match as a starting point for a dialogue.',
  ICEBREAKER_HEADLINE_HON_PICTURE_WANTED: 'XI. Matchmaker voting',
  ICEBREAKER_PARAGRAPH_EXPLANATION_HON_PICTURE_WANTED:
    'Similar to the Icebreaker X, the user rated your picture positively. In this case, however, the user does not have a profile picture. Since it would be strange if there was a matchmaking hit without a profile picture, this Icebreaker thanks you for the voting and then sends an Icebreaker type VI (User picture wanted) afterwards.',
  ICEBREAKER_HEADLINE_PROFILE_VISIT_LIVECAM: 'XII. Livecam invitation',
  ICEBREAKER_PARAGRAPH_EXPLANATION_PROFILE_VISIT_LIVECAM:
    'This Icebreaker invites the user to your camshow. It is sent when a user looks at your profile while you are doing a live cam show, but leaves it again without visiting your cam show or writing to you.',
  ICEBREAKER_HEADLINE_MEDIA_PURCHASE: 'XIII. Response to purchase of content',
  ICEBREAKER_PARAGRAPH_EXPLANATION_MEDIA_PURCHASE:
    "If a user buys content from you but has not yet had any contact with you, this Icebreaker will be sent to take advantage of the user's obvious interest and to start a dialogue.",
  ATTACHMENT_LABEL_NO_FILES: 'No file attachment uploaded',
  ICEBREAKER_LOADING_ERROR:
    'Loading Icebreaker failed, please reload the page.',
  ICEBREAKER_INFO_HEADLINE: `The Community Icebreaker System<br /><span>Created to help you break the ice to the user!</span>`,
  ICEBREAKER_INFO_TEXT_1: `<span class="bold">How the Icebreaker System works</span><br />
    Our Icebreaker System automatically takes the initiative for you with the help of intuitively controlled messages and helps you very quickly to numerous new contacts and dialogues with users. The different Icebreaker types are based on a smart and learning logic, which react to each other and to the behavioral patterns of the users and thus increase the users' willingness to dialogue, i.e. your earning potential!`,
  ICEBREAKER_INFO_TEXT_2: `<span class="bold">Special feature Performance Texts</span><br />
    The intelligent control of the Icebreaker System guarantees the optimal and largest range of possible new contacts. Our so-called Performance Icebreaker Texts guarantee the best possible conversion, i.e. response rate!`,
  ICEBREAKER_INFO_TEXT_3: `
    Each Icebreaker type has its own text pool, which contains hundreds of text variants. All texts in the Icebreaker System are unique and do not repeat themselves! The peculiarity: All texts are analyzed daily in the course of thousands of dialogues and initial contacts and evaluated to determine which of them achieve the highest response rates. These then always offer the highest chances to animate users to a dialogue and thus the best possible performance for the respective Icebreaker type.`,
  ICEBREAKER_INFO_TEXT_4: `
    The intelligent Icebreaker System and the Performance Icebreaker Texts provide you the maximum number of new users! 100% automatic and absolutely free!`,
  ICEBREAKER_INFO_TEXT_5: `<span class="bold">Add pictures and videos - increase your chances</span><br />
    In order to achieve the best possible performance, we recommend that you add an individual picture or a short video for each type of Icebreaker. This significantly increases the high response rate of the Performance Icebreaker Texts.`,
  ICEBREAKER_INACTIVE: 'inactive',
  ICEBREAKER_ACTIVE: 'active',
};

