import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import Input from '../Input/Input';

function InputWithLabel({
  id,
  name,
  label,
  markAsRequired,
  children,
  className,
  small,
  ...otherProps
}) {
  const intl = useIntl();

  const labelClassName = classNames({
    'field-label-required-mark-container': markAsRequired,
    disabled: otherProps.disabled,
  });
  const translatedLabel = label && intl.formatMessage({ id: label });

  const fieldClassName = classNames({
    small,
  });

  return (
    <div className="form-group">
      {label && (
        <label htmlFor={id || name} className={labelClassName}>
          {translatedLabel}
          {markAsRequired && <sup>*</sup>}
        </label>
      )}

      {children ? (
        children
      ) : (
        <Input id={id} name={name} className={fieldClassName} {...otherProps} />
      )}
    </div>
  );
}

export default InputWithLabel;

