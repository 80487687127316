import './IdleDialog.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import icon from '../../assets/img/svg/icebreaker.svg';
import Button from '../Button/Button';

function IdleDialog({ idledialogstamp, closeOverlay, keepAlive, keepIdle }) {
  return (
    <div className="idle-dialog-wrapper overlay-screen">
      <div className="overlay-wrapper">
        <div className="idle-dialog">
          <div className="left-container">
            <div className="idle-title">
              <FormattedMessage id="IDLE_MODAL_TITLE" />
            </div>
            <div className="idle-text-1">
              <FormattedMessage id="IDLE_MODAL_TEXT_1" />
            </div>
          </div>
          <div className="right-container">
            <ReactSVG src={icon} wrapper="span" className="icon" />
          </div>
        </div>
        <div className="idle-text-2">
          <FormattedMessage id="IDLE_MODAL_TEXT_2" />
        </div>
        <div className="buttons-wrapper">
          <Button
            onClick={() => {
              keepIdle();
              closeOverlay(idledialogstamp);
            }}
            variant="dark"
            label="BUTTON_TITLE_ASK_ME_AGAIN"
          />
          <Button
            onClick={() => {
              keepAlive();
              closeOverlay(idledialogstamp);
            }}
            variant="success"
            label="BUTTON_TITLE_ACTIVATE"
          />
        </div>
      </div>
      <div className="background" />
    </div>
  );
}

export default IdleDialog;
