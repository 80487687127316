import '../../MyContent/MyContent.scss';
import './FreeMovieOfTheDay.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import fmotdImg from '../../../../assets/img/logo-fmotd.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Scrollbar from '../../../Scrollbar/Scrollbar';
import FreeMovieOfTheDayTable from './FreeMovieOfTheDayTable';

function FreeMovieOfTheDay() {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-content-wrapper fmotd-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={false}
        >
          <div className="data-wrapper">
            <FreeMovieOfTheDayTable />
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={false}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            <img
              src={fmotdImg}
              className="fmotd-logo"
              alt="Free Movie of the Day"
            />
            <h2 className="headline">
              <FormattedMessage id="FMOTD_INFO_HEADLINE_1" />
            </h2>
            <p className="bold">
              <FormattedMessage id="FMOTD_INFO_TEXT_1">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="FMOTD_INFO_TEXT_2">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="FMOTD_INFO_TEXT_3">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p className="bold">
              <FormattedMessage id="FMOTD_INFO_TEXT_4">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p className="small">
              <FormattedMessage id="FMOTD_INFO_TEXT_5">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p className="small">
              <FormattedMessage id="FMOTD_INFO_TEXT_6">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default FreeMovieOfTheDay;
