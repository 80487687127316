import {
  MANAGEMENT_LOGGED_IN,
  STREAMING_IS_STARTING,
} from '../../../constants';
import { roomType as roomTypeUtils, uuid } from '../../../utils';
import { management, stream } from '../../';
import { STREAM_EVENTS_ERROR } from '../../actions';

export default (error) => {
  return (dispatch, getState) => {
    const {
      sender: { roomTypes },
      streaming: { step, camLoginData },
    } = getState();
    if (step === STREAMING_IS_STARTING) {
      return dispatch(stream.publish(camLoginData));
    }
    if (getState().streaming.managementStep === MANAGEMENT_LOGGED_IN) {
      dispatch(management.logoutCam({}, 10));
      if (!roomTypeUtils.isEvent(roomTypes)) {
        dispatch(management.logfpsTimer.stop());
      }
      dispatch(management.checkuserTimer.stop());
    }
    if (!roomTypeUtils.isEvent(roomTypes)) {
      dispatch(stream.timerCheck.stop());
    }
    dispatch(stream.timerStop.stop());
    dispatch({
      type: STREAM_EVENTS_ERROR,
      meta: { stamp: uuid() },
      error: true,
      payload: error,
    });
  };
};
