import { getUnixTimestamp } from '.';

const getGame = (state, id) =>
  state.games.games.find((el) => el.partner === id) || {};
const isActiveGame = (game) => game?.session?.status === 'pending';
const isActiveInvitation = (game) => game?.status === 'pending';

const isActive = (game) => isActiveGame(game) || isActiveInvitation(game);
const isActiveTurn = (game) =>
  isActiveGame(game) &&
  game?.session?.turnId &&
  game?.partner &&
  game.session.turnId !== game.partner.replace('@', '-');
const isDecidedGame = (game) => game?.session?.status === 'decided';
const hasSession = (game) => !!game?.session;
const lastUpdated = (game) =>
  hasSession(game) ? game?.session?.updatedAt : game?.updatedAt;
const lastUpdatedTimestamp = (game) => getUnixTimestamp(lastUpdated(game));
const addPartner = (el) => ({
  ...el,
  partner: el.fromId.replace('-', '@'),
});
export default {
  getGame,
  isActiveGame,
  isActiveInvitation,
  isActiveTurn,
  isDecidedGame,
  hasSession,
  lastUpdatedTimestamp,
  isActive,
  lastUpdated,
  addPartner,
};
