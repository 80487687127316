import '../../MyContent/MyContent.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import Scrollbar from '../../../Scrollbar/Scrollbar';
import MyComments from './MyComments';

function Comments() {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-content-wrapper comments-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={false}
        >
          <MyComments />
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={false}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            <h2 className="headline">
              <FormattedMessage id="COMMENTS_INFO_HEADLINE_1" />
            </h2>
            <p>
              <FormattedMessage id="COMMENTS_INFO_TEXT_1" />
            </p>
            <p>
              <FormattedMessage id="COMMENTS_INFO_TEXT_2" />
            </p>
            <p>
              <FormattedMessage id="COMMENTS_INFO_TEXT_3">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Comments;
