import { connect } from 'react-redux';

import { spinner } from '../../../actions';
import Support from './Support';

const mapStateToProps = (state) => ({
  user: state.user,
  isMobile: state.browser.isMobile,
  locale: state.intl.locale,
});

const mapDispatchToProps = {
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Support);

