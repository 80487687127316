import { management as managementActions } from '../../../';
import { MANAGEMENT_EVENTS_SOCKET_RECONNECT } from '../../../actions';
export default (payload) => (dispatch, getState) => {
  const {
    streaming: { isReconnecting },
  } = getState();
  if (isReconnecting) {
    return;
  }
  dispatch({
    type: MANAGEMENT_EVENTS_SOCKET_RECONNECT,
    payload,
  });
  dispatch(managementActions.logoutCam({}, 23));
};
