import { uuid } from '../../../utils';
import { FRIEND_FRIEND_REQUEST_REMOVE_DENIED } from '../../actions';

export default (id, name, requestSent) => (dispatch, getState) => {
  if (requestSent) {
    return dispatch({
      type: FRIEND_FRIEND_REQUEST_REMOVE_DENIED,
      payload: { id, name },
      meta: {
        stamp: uuid(),
      },
    });
  }

  return dispatch({
    type: FRIEND_FRIEND_REQUEST_REMOVE_DENIED,
    payload: { id, name },
    meta: {
      stamp: uuid(),
      start: true,
    },
  });
};
