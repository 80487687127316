import { connect } from 'react-redux';

import {
  askDeleteAttachment,
  attachmentDeleteNotAllowed,
  deleteAttachment,
  openOverlay,
  spinner,
} from '../../../actions';
import AttachmentsTable from './AttachmentsTable';

const mapStateToProps = (state) => ({
  ...state.attachments,
  checkIcebreaker: state.user?.account?.icebreaker !== 'blocked',
  isMobile: state.view.isMobile,
  imageBaseUrl: process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES,
  language: state.user?.account?.language,
});

const mapDispatchToProps = {
  openOverlay,
  deleteAttachment,
  askDeleteAttachment,
  attachmentDeleteNotAllowed,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentsTable);

