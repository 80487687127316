import { Formik } from 'formik';
import { connect } from 'react-redux';

import { closeOverlay, spinner } from '../../../../actions';
import update from '../../../../actions/user/update';
import AddressChanger from './AddressChanger';
import { getValidationScheme } from './AddressChanger.functions';

const mapDispatchToProps = {
  closeOverlay,
  update,
  spinner,
};

const defaultsObj = {
  address1: '',
  address2: '',
  zip: '',
  city: '',
  country_code: '',
};

function getInitialValues(reduxObj) {
  let returnObj = {};
  let reduxValues = { ...reduxObj };
  if (reduxObj.new_address) {
    reduxValues = { ...reduxObj.new_address };
  }
  for (const k in defaultsObj) {
    returnObj[k] = reduxValues[k] || defaultsObj[k];
  }
  return returnObj;
}

const mapStateToProps = (state) => ({
  initialValues: getInitialValues({
    ...state.user.private,
  }),
  user: state.user,
  countries: state.countries,
});

const AddressChangerForm = ({ initialValues, intl, ...props }) => {
  const validationSchema = getValidationScheme(intl);
  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema}>
      <AddressChanger {...props} />
    </Formik>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressChangerForm);

