import './BillArticles.scss';

import useAxios from 'axios-hooks';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import useTranslateTableTitles from '../../../../hooks/useTranslateTableTitles';
import BackButton from '../../../BackButton';
import Button from '../../../Button/Button';
import SkeletonTable from '../../../Tables/SkeletonTable/SkeletonTable';
import DateItem from '../../../Tables/TableItems/DateItem';
import NumberItem from '../../../Tables/TableItems/NumberItem';
import {
  billArticlesTitles,
  supersenderbonusTitles,
} from './BillArticles.functions';

function BillArticles({ spinner, openDialog }) {
  const search = useLocation().search;
  const type = new URLSearchParams(search).get('type');
  const all = new URLSearchParams(search).get('all');
  const billId = new URLSearchParams(search).get('bill_id') || '0';

  const history = useHistory();
  const intl = useIntl();

  const showAll = all === '1';
  const [{ data: billArticlesData, loading: billArticlesLoading }] = useAxios({
    url:
      type !== 'supersenderbonus'
        ? `/user/articles?type=${type}&bill_id=${billId}`
        : `/user/articles/supersenderbonus?${
            showAll ? 'all=1&' : ''
          }bill_id=${billId}`,
  });

  const titles =
    type !== 'supersenderbonus' ? billArticlesTitles : supersenderbonusTitles;
  const translatedTitles = useTranslateTableTitles(titles);
  const filteredTitles = translatedTitles.filter((title) => {
    if (title.nonTypes) {
      return !title.nonTypes.includes(type);
    }
    if (title.types) {
      return title.types.includes(type);
    }
    return true;
  });
  return (
    <div className="table-box">
      <h1 className="headline">
        <BackButton
          onClick={() => {
            history.goBack();
          }}
        />
        <span>
          {type === 'supersenderbonus' && billArticlesData?.bill?.bill_id
            ? intl.formatMessage({ id: `PARKING_CREDITS_BILL` }) +
              ' ' +
              billArticlesData?.bill?.bill_id +
              ' | ' +
              intl.formatMessage({ id: `article_${type}` })
            : intl.formatMessage({ id: `article_${type}` })}
        </span>
      </h1>
      {!billArticlesData && !billArticlesLoading && (
        <div className="no-data-label headline">
          {intl.formatMessage({ id: 'NO_DATA' })}
        </div>
      )}
      {type === 'supersenderbonus' ? (
        <div className="supersenderbonus-link">
          <Link
            to={`/my-account/commission/bill-articles?type=supersenderbonus${
              showAll ? '' : '&all=1'
            }${billId ? '&bill_id=' + billId : ''}`}
          >
            {showAll
              ? intl.formatMessage({ id: 'BILL_ARTICLES_SHOW_LESS' })
              : intl.formatMessage({ id: 'BILL_ARTICLES_SHOW_ALL' })}
          </Link>
        </div>
      ) : null}
      {billArticlesData && (
        <table>
          <thead>
            <tr>
              {Array.isArray(filteredTitles) &&
                filteredTitles?.map((title, index) => (
                  <th
                    key={`${title}.${index}`}
                    className={title.number ? 'text-right' : ''}
                  >
                    {title.title}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(billArticlesData?.revenue) && (
              <>
                {billArticlesData?.revenue?.map((item, index) => {
                  return (
                    <tr key={`revenue${index}`}>
                      <td>{item.nickname}</td>
                      <td className="text-right">
                        {item.number}{' '}
                        {intl.formatMessage({ id: 'BILL_ARTICLES_SALES' })}
                      </td>
                      <td className="text-right">{item.coins} Coins</td>
                      <td className="text-right">
                        <NumberItem number={item.rate} precision={2} /> %
                      </td>
                      <td className="text-right">
                        ~{' '}
                        <NumberItem
                          number={item.price}
                          precision={2}
                          currency={item.currency}
                        />
                      </td>
                    </tr>
                  );
                })}
                {billArticlesData?.total && (
                  <tr className="total">
                    <td>
                      {intl.formatMessage({
                        id: 'BILL_ARTICLES_TOTAL_TURNOVER',
                      })}
                    </td>
                    <td className="text-right"></td>
                    <td className="text-right">
                      {billArticlesData.total.coins} Coins
                    </td>
                    <td className="text-right">
                      {billArticlesData.total.rate % 1 === 0 ? null : 'Ø '}
                      <NumberItem
                        number={billArticlesData.total.rate}
                        precision={1}
                      />{' '}
                      %
                    </td>
                    <td className="text-right">
                      <NumberItem
                        number={billArticlesData.total.price}
                        precision={2}
                        currency={billArticlesData.total.currency}
                      />
                    </td>
                  </tr>
                )}
              </>
            )}
            {Array.isArray(billArticlesData?.articles) &&
              billArticlesData?.articles?.map((item, index) => {
                return (
                  <tr key={`${item.id}${index}`}>
                    <td>{item.id}</td>
                    <td>
                      <DateItem data={item.date} />
                    </td>
                    <td>
                      {item.nickname ? (
                        item.user_id !== '@' ? (
                          <Link
                            onClick={() =>
                              openDialog(item.user_id, item.nickname)
                            }
                            to="/multi-messenger"
                          >
                            {item.nickname}
                          </Link>
                        ) : (
                          item.nickname
                        )
                      ) : (
                        <span className="grayed-text">
                          {intl.formatMessage({
                            id: 'BILL_ARTICLES_HIDDEN_USERS',
                          })}
                        </span>
                      )}
                    </td>
                    <td>{item.portal}</td>
                    {(type === 'pictures' || type === 'movies') && (
                      <td>{item.title}</td>
                    )}
                    {(type === 'livecams' ||
                      type === 'exclusivecams' ||
                      type === 'voyeurcams') && (
                      <td className="text-right">
                        {item.duration}{' '}
                        {intl.formatMessage({ id: 'SECONDS_LABEL' })}
                      </td>
                    )}
                    {type === 'advrev' && (
                      <td className="text-right">
                        <NumberItem
                          number={item.purchase}
                          precision={2}
                          currency={item.currency}
                        />
                      </td>
                    )}
                    {billArticlesData.group === 'extra' ? (
                      <td className="text-right">
                        <NumberItem
                          number={item.amount}
                          precision={2}
                          currency={item.currency}
                        />
                      </td>
                    ) : (
                      <td className="text-right">{item.coins} Coins</td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {billArticlesLoading && (
        <SkeletonTable translatedTitles={filteredTitles} />
      )}
      {billArticlesData && (
        <div className="btn-back">
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              history.goBack();
            }}
            label="BUTTON_BACK"
          />
        </div>
      )}
    </div>
  );
}

export default BillArticles;
