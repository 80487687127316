import { roundToHour } from '../../../utils/date';

export default (socketService) => () => (dispatch, getState) => {
  const {
    user: { access_token: token },
  } = getState();

  let firstHourTime = roundToHour(Date.now());

  setTimeout(() => {
    timeout(token, socketService);
  }, firstHourTime - Date.now());
};

function timeout(token, socketService) {
  let nextHourTime = roundToHour(Date.now());

  const evtid = 'sales.fetch-current-' + Date.now();
  const type = 'sales.fetch-current';
  const options = {
    type,
    token,
    evtid,
  };

  nextHourTime = Date.now() + 60 * 60 * 1000;

  socketService.emit(type, options);
  setTimeout(function () {
    timeout(token, socketService);
  }, nextHourTime - Date.now());
}
