import { CLOSE_OVERLAY, OPEN_OVERLAY } from '../actions/actions';

const initialState = [];

const overlay = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_OVERLAY: {
      if (action.payload.Component === 'IdleDialog') {
        const index = state.find((s) => s.Component === 'IdleDialog');
        if (index !== undefined) return state;
      }
      if (['VeriffOverlay'].includes(action.payload.Component)) {
        action.payload.important = true;
      }
      return [...state, action.payload];
    }
    case CLOSE_OVERLAY:
      if (action.payload.overlayType === 'IdleDialog') {
        return state.filter((overlay) => overlay.Component !== 'IdleDialog');
      }
      return state.filter((overlay) => overlay.stamp !== action.payload);
    default:
      return state;
  }
};
export default overlay;
