import { management } from '../../services';
import { toggleToycontrol } from '../';
import { TOY_SET_ACTIVE } from '../actions';
export default function (toyId, save = true, reset = false) {
  return async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }
    const uid = getState().sender?.toycontrol?.uid;
    const toys = getState().sender?.toycontrol?.connected?.toys || {};
    const toyName = (toys[toyId] || {})?.name || '';

    if (!uid) {
      return;
    }

    if (!management?.connected()) {
      return dispatch({
        type: TOY_SET_ACTIVE,
        meta: { save },
        payload: toyId,
      });
    }
    management.emit(
      'setActiveToy',
      { toyId, uid, toyName },
      (error, payload) => {
        if (!getState().user.isLoggedIn) {
          return;
        }

        dispatch({
          type: TOY_SET_ACTIVE,
          meta: { save },
          payload,
        });
        if (reset) {
          dispatch(toggleToycontrol());
        }
      }
    );
  };
}
