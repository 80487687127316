import { uuid } from '../../../utils';
import { FRIEND_FRIEND_REQUEST_ANSWER } from '../../actions';
import { updateListFriendRequest } from '..';
export default (requestSent, userId, nickname, friendRequestType) => async (
  dispatch,
  getState
) => {
  const {
    friends: { requests },
  } = getState();

  if (requestSent) {
    const newRequestsList = requests.filter(
      (request) =>
        request.id !== userId && request.id !== userId.replace('@', '-')
    );
    dispatch(updateListFriendRequest({ data: newRequestsList }));
    return dispatch({
      type: FRIEND_FRIEND_REQUEST_ANSWER,
      payload: { userId, nickname, friendRequestType },
      meta: {
        stamp: uuid(),
      },
    });
  }
  return dispatch({
    type: FRIEND_FRIEND_REQUEST_ANSWER,
    payload: { userId, nickname },
    meta: {
      start: true,
      stamp: uuid(),
    },
  });
};
