import './Items.scss';

import React, { useCallback, useContext } from 'react';

import StepperContext from '../StepperContext';
import Item from './Item/Item';
import { getItemColor, getLineColor } from './Items.functions';
import Line from './Line/Line';

function Items({ state }) {
  const { items, currentItemIndex, setNextItemIndex, isDisabled, isFinished } =
    useContext(StepperContext);

  const renderItems = useCallback(() => {
    const allItems = items.map((i, index) => {
      const { disabled, component, ...rest } = i;
      const onClick = !(disabled || isDisabled)
        ? { onClick: () => setNextItemIndex(index) }
        : {};

      return (
        <React.Fragment key={index}>
          <Line
            color={getLineColor(
              state,
              items,
              isFinished,
              currentItemIndex,
              index
            )}
          />

          <Item
            color={getItemColor(items, isFinished, currentItemIndex, index)}
            isCurrentItem={currentItemIndex === index}
            isSuccess={isFinished && items[index] && items[index].isSuccess}
            disabled={disabled}
            pendingState={state === 'pending'}
            {...onClick}
            {...rest}
          />
        </React.Fragment>
      );
    });

    return (
      <>
        {allItems}

        <Line
          color={getLineColor(
            state,
            items,
            isFinished,
            currentItemIndex,
            items.length - 1,
            true
          )}
        />
      </>
    );
  }, [
    currentItemIndex,
    isDisabled,
    isFinished,
    items,
    setNextItemIndex,
    state,
  ]);

  return <div className="stepper-items">{renderItems()}</div>;
}

export default Items;

