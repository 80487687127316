import { connect } from 'react-redux';

import { muteMicrophone } from '../../../../../actions';
import MuteButton from './MuteButton';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  microphoneMuted: state.streaming.mute,
  microphoneEnabled: state.cam.selectedMicrophone.value !== false,
});

const mapDispatchToProps = {
  muteMicrophone,
};

export default connect(mapStateToProps, mapDispatchToProps)(MuteButton);
