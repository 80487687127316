import './Error.scss';

import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Section from '../Section/Section';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true });
    let errorObject = {
      level: 'error',
      location: 'error',
      error,
    };
    this.props.errorLog(errorObject);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-page">
          <Section className="error-container">
            <h1 className="headline">
              <FormattedMessage id="ERROR" />
            </h1>

            <div className="error-text-container">
              <p>
                <FormattedMessage id="ERROR_MESSAGE" />
              </p>
            </div>
          </Section>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

