import './PhotoItem.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import checkIcon from '../../../assets/img/svg/check.svg';
import backIcon from '../../../assets/img/svg/chevron-single-left.svg';
import forwardIcon from '../../../assets/img/svg/chevron-single-right.svg';
import deleteIcon from '../../../assets/img/svg/delete.svg';
import menuIcon from '../../../assets/img/svg/edit.svg';
import noPhoto from '../../../assets/img/svg/no-picture.svg';
import searchIcon from '../../../assets/img/svg/search.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import { uuid } from '../../../utils';
import api from '../../../utils/api';
import Button from '../../Button/Button';
import { checkImage, getImageSource } from '../../Uploader/Uploader.functions';

const statusLabelMap = {
  fsk12: 'FSK_CASUAL_LABEL',
  fsk16: 'FSK_SOFT_LABEL',
  fsk18: 'FSK_HARD_LABEL',
};

const statusProfilePic = {
  in_review: 'pending',
  rejected: 'LABEL_REJECTED',
};

function PhotoItem({
  item,
  statusClassName,
  openPictureCropper,
  galleryStatus,
  spinner,
  itemDeleted,
  activeGallery,
  galleryThumbnail,
  openThumbnail,
  setImageError,
  menuVisible,
  setMenuVisible,
  openOverlay,
  thumbnailRejected,
  changeItemPosition,
  isMobile,
  tickOrText,
  photoGalleryDeleteDisabled,
  showMediumOnPreview,
  canDeleteImage,
  showEditIcon,
  squareImage,
  rejectedProfilePic,
  setDefaultPictureId,
  component,
  setImageAsDefault,
  setProfilePicError,
}) {
  const itemPath = item.file
    ? `${item.file.slice(0, -4)}${
        galleryThumbnail || squareImage ? '' : '-pw'
      }.jpg`
    : '';
  const largeItemPath =
    item.large_file && !galleryThumbnail
      ? item.large_file
      : item.file
      ? `${item.file.slice(0, -4)}.jpg`
      : '';

  const intl = useIntl();

  const menuRef = useRef();
  const openingDocumentRef = useRef();

  async function openImage() {
    if (setImageAsDefault) {
      await api
        .put(`/user/profile-pictures/${item.id}/default`)
        .then(() => {
          setDefaultPictureId(item.id);
        })
        .catch((errors) => {
          setImageError(errors?.response?.data?.errors[0]);
        });
    } else {
      try {
        spinner(true);
        const response = await fetch(
          `${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${largeItemPath}`
        );
        let data = await response.blob();
        let metadata = {
          type: 'image/jpeg',
        };
        let file = new File(
          [data],
          `preview${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
          metadata
        );
        const iSource = await checkImage(
          await getImageSource(file, intl),
          intl,
          {
            width: 1024,
            height: 576,
          }
        );
        openPictureCropper(file, iSource);
        spinner(false);
      } catch (error) {
        setImageError(error.message);
      }
    }
  }

  const [, deletePhotoRequest] = useAxios(
    {
      url: `/galleries/${activeGallery?.id}/picture/${item.id}`,
      method: 'DELETE',
    },
    { manual: true }
  );

  async function deleteImage() {
    if (canDeleteImage) {
      await api
        .delete(`/user/profile-pictures/${item.id}`)
        .then((res) => {
          itemDeleted(item.id);
        })
        .catch((errors) => {
          setProfilePicError(errors?.response?.data?.errors[0]);
        });
    } else {
      spinnerRequest({
        request: deletePhotoRequest,
        spinner,
      })
        .then(() => itemDeleted(item.id))
        .catch(() => {});
    }
  }

  const menuClass = classNames('toggle-menu', {
    'mobile-photo-item': galleryThumbnail,
  });

  const thumbnailClass = classNames('thumbnail-wrapper', {
    'gallery-thumbnail': galleryThumbnail,
  });

  const buttonMenuClass = classNames('btn-menu', {
    open: menuVisible,
  });

  useOutsideClick(menuRef, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  const openDocument = (title, fileSource) => {
    openingDocumentRef.current = true;
    spinner(true);
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Document',
      props: {
        openDocumentStamp: stamp,
        title,
        fileSource: showMediumOnPreview
          ? fileSource.replace('-pw', '-medium')
          : fileSource,
      },
    });
    spinner(false);

    openingDocumentRef.current = false;
  };

  return (
    <div
      className={`thumbnail-item ${rejectedProfilePic ? 'rejected' : ''}`}
      id={`thumbnail-item-${item.id ? item.id : 'empty'}`}
    >
      <div className={thumbnailClass}>
        <div className="thumbnail">
          {itemPath.length !== 0 ? (
            <div
              className="thumbnail-image"
              style={{
                backgroundImage: `${`url('${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${itemPath}')`}`,
              }}
            />
          ) : (
            <div className="empty-online-item-wrapper">
              <div className="image-wrapper">
                <div className="img">
                  <ReactSVG src={noPhoto} wrapper="span" />
                </div>
              </div>
            </div>
          )}
          <div className="thumbnail-options">
            {item.status === 'confirmed' && isMobile && (
              <>
                <Button
                  intlTranslate={false}
                  icon={backIcon}
                  variant="icon-only"
                  className="btn-swap-left"
                  onClick={() => changeItemPosition(item.id, 'backward')}
                />{' '}
                <Button
                  intlTranslate={false}
                  icon={forwardIcon}
                  variant="icon-only"
                  className="btn-swap-right"
                  onClick={() => changeItemPosition(item.id, 'forward')}
                />
              </>
            )}
            {itemPath.length !== 0 && !rejectedProfilePic ? (
              <Button
                intlTranslate={false}
                icon={searchIcon}
                variant="icon-only"
                className="btn-size"
                onClick={() =>
                  !openingDocumentRef.current &&
                  openDocument(
                    component === 'profilePicture'
                      ? intl.formatMessage({ id: 'PROFILE_PIC_LABEL' })
                      : intl.formatMessage({ id: 'GALLERY_IMAGE_LABEL' }),
                    `${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${largeItemPath}`
                  )
                }
              />
            ) : null}
            {!showEditIcon && !rejectedProfilePic ? (
              <Button
                intlTranslate={false}
                icon={menuIcon}
                variant="icon-only"
                className={buttonMenuClass}
                onClick={() => {
                  if (!menuVisible) {
                    setMenuVisible(true);
                  }
                }}
              />
            ) : null}
            {rejectedProfilePic ? (
              <Button
                intlTranslate={false}
                icon={deleteIcon}
                variant="icon-only"
                className="btn-delete"
                onClick={() => deleteImage()}
              />
            ) : null}
            {menuVisible && (
              <div
                className={menuClass}
                id={`photo-menu${item.id}`}
                ref={menuRef}
              >
                {galleryThumbnail ? (
                  <div
                    className="menu-item"
                    onClick={() => {
                      openThumbnail();
                      setMenuVisible(false);
                    }}
                  >
                    {intl.formatMessage({
                      id: 'GALLERY_MENU_UPLOAD_NEW_PREVIEW',
                    })}
                  </div>
                ) : (
                  <>
                    {item.state === 'in_review' ? null : (
                      <div
                        className="menu-item"
                        onClick={() => {
                          openImage();
                          setMenuVisible(false);
                        }}
                      >
                        {intl.formatMessage({
                          id:
                            component === 'profilePicture'
                              ? 'PHOTO_PROFILE_PICTURE_AS_DEFAULT'
                              : 'PHOTO_GALLERY_MENU_USE_AS_PREVIEW',
                        })}
                      </div>
                    )}
                    {item.status !== 'confirmed' &&
                      !photoGalleryDeleteDisabled && (
                        <div
                          className="menu-item"
                          onClick={() => {
                            deleteImage();
                            setMenuVisible(false);
                          }}
                        >
                          {intl.formatMessage({ id: 'GALLERY_MENU_DELETE' })}
                        </div>
                      )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {item.state === 'in_review' ? (
        <div className="badge orange">
          {intl.formatMessage({ id: statusProfilePic[item.state] })}
        </div>
      ) : null}
      {item.state === 'rejected' ? (
        <>
          <div className="reason">{item.comment}</div>
          <div className="badge red">
            {intl.formatMessage({ id: statusProfilePic[item.state] })}
          </div>
        </>
      ) : null}
      {item.state === 'approved' || item.state === 'enabled' ? (
        <div className={statusClassName}>
          {intl.formatMessage({ id: statusLabelMap[item.fsk] })}
        </div>
      ) : null}
      {(galleryStatus === 'published' ||
        galleryStatus === 'archived' ||
        galleryStatus === 'blocked') &&
        !galleryThumbnail && (
          <>
            <div className="textbox">
              <div className="textbox-stats">
                <span className="number">{item.sales !== 0 && item.sales}</span>{' '}
                {item.sales
                  ? intl.formatMessage({ id: 'TIMES_SOLD' })
                  : intl.formatMessage({ id: 'NOT_SOLD_YET' })}{' '}
              </div>
            </div>
            {item.status === 'confirmed' && (
              <div className={statusClassName}>
                {tickOrText === 'text' ? (
                  intl.formatMessage({ id: statusLabelMap[item.fsk] })
                ) : (
                  <ReactSVG
                    src={checkIcon}
                    wrapper="span"
                    className="fsk12-badge-icon"
                  />
                )}
              </div>
            )}
          </>
        )}
      {galleryThumbnail && (
        <div className={statusClassName}>
          {' '}
          {thumbnailRejected
            ? intl.formatMessage({ id: 'LABEL_REJECTED' })
            : intl.formatMessage({ id: 'LABEL_PREVIEW' })}
        </div>
      )}
    </div>
  );
}

export default PhotoItem;

