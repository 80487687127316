import {
  ADD_CAM,
  CAM_STOP,
  MANAGEMENT_CAM_LOGIN,
  MANAGEMENT_CAM_LOGOUT,
  MANAGEMENT_CONNECT,
  MANAGEMENT_CONNECT_START,
  MANAGEMENT_CONNECT_STOP,
  MANAGEMENT_EVENTS_CONNECTION_CONNECT,
  MANAGEMENT_EVENTS_CONNECTION_DISCONNECT,
  MANAGEMENT_EVENTS_CONNECTION_TRANSPORT_CLOSE,
  MANAGEMENT_EVENTS_RECONNECT,
  MANAGEMENT_EVENTS_RESOLUTION_SENDER_CHANGE,
  MANAGEMENT_EVENTS_SOCKET_RECONNECT,
  MANAGEMENT_EVENTS_STREAMINFO_START,
  MANAGEMENT_EVENTS_STREAMINFO_STOP,
  MICROPHONE_MUTE,
  RECONNECT_ZEROBYTES_INCREMENT,
  RESET_CAM,
  RESET_MICROPHONE,
  SENDER_LOGIN_DATA,
  SENDER_LOGOUT,
  STREAM_EVENTS_BANDWIDTH,
  STREAM_EVENTS_PUBLISH,
  STREAM_PUBLISH,
  STREAM_RECONNECT,
  STREAM_STOP,
  STREAM_STOP_TIMER_START,
  STREAM_STOP_TIMER_STOP,
} from '../actions/actions';
import {
  MANAGEMENT_CONNECT_STARTED,
  MANAGEMENT_LOGGED_IN,
  MANAGEMENT_LOGGED_OUT,
  MANAGEMENT_LOGIN_STARTED,
  MANAGEMENT_LOGOUT_STARTED,
  STREAMING_IS_STARTING,
  STREAMING_RECONNECTING,
  STREAMING_STARTED,
  STREAMING_STOPPED,
} from '../constants';
import { lStorage } from '../services/storage';
import crypt from '../utils/crypt';

const initialState = {
  stream: {},
  camLoginData: {},
  mute: false,
  step: STREAMING_STOPPED,
  managementStep: MANAGEMENT_LOGGED_OUT,
  camInitialized: false,
  permanentStream: false,
  streamInfoCounter: 0,
  stopTimerRunning: false,
  streamStopTimeout: 50000,
  idleStreamOffset: 30000,
  availableMediaServer: [],
  connectionData: {},
  idleStreamBandwidth: -1,
  permanentCam2CamStream: false,
  currentBandwidth: -1,
  streambuttonlock: false,
  idleStreamStartTimeMs: NaN,
  statsInterval: 3000,
  isReconnecting: false,
  silentReconnect: false,
  transportCloseReconnectDelay: 30000,
  maxConnects: 30,
  connectCounter: 0,
  zeroBytesReconnects: 0,
};
const streaming = (state = initialState, action) => {
  switch (action.type) {
    case SENDER_LOGOUT:
      return {
        ...state,
        streambuttonlock: false,
        zeroBytesReconnects: 0,
        transportCloseReconnectDelay: initialState.transportCloseReconnectDelay,
      };
    case MANAGEMENT_EVENTS_RESOLUTION_SENDER_CHANGE: {
      const { camLoginData } = state;
      camLoginData.sdpOpts.videoBitrate = action.payload.bandwidth;
      return { ...state, camLoginData };
    }
    case MICROPHONE_MUTE:
      lStorage.setItem(`${crypt.secretHash()}:mute`, action.payload.mute);
      return Object.assign({}, state, { mute: action.payload.mute });
    case CAM_STOP:
      return Object.assign({}, state, { stream: initialState.stream });
    case RESET_CAM:
      return Object.assign({}, state, initialState, { mute: state.mute });
    case RESET_MICROPHONE:
      return Object.assign({}, state, { mute: initialState.mute });
    case MANAGEMENT_EVENTS_STREAMINFO_START:
      return Object.assign({}, state, {
        streamInfoCounter: state.streamInfoCounter + 1,
      });
    case MANAGEMENT_EVENTS_STREAMINFO_STOP:
      if (state.streamInfoCounter === 0) {
        return state;
      }
      return Object.assign({}, state, {
        streamInfoCounter: state.streamInfoCounter - 1,
      });
    case ADD_CAM:
      if (action.error) {
        return Object.assign({}, state, initialState, { mute: state.mute });
      }
      return Object.assign({}, state, {
        stream: action.payload.stream,
        camInitialized: true,
      });
    case MANAGEMENT_CAM_LOGOUT:
      if (action.meta && action.meta.stop) {
        return {
          ...state,
          streambuttonlock: false,
          isReconnecting: false,
          zeroBytesReconnects: 0,
        };
      }
      if (action.meta && action.meta.start) {
        return {
          ...state,
          managementStep: MANAGEMENT_LOGOUT_STARTED,
          streambuttonlock: true,
          isReconnecting: false,
        };
      }
      return {
        ...state,
        managementStep: MANAGEMENT_LOGGED_OUT,
        streambuttonlock: false,
        isReconnecting: false,
        zeroBytesReconnects: 0,
      };
    case MANAGEMENT_CONNECT_STOP:
      return {
        ...state,
        managementStep: MANAGEMENT_LOGGED_OUT,
        connectCounter: 0,
        isReconnecting: false,
        streambuttonlock: false,
        zeroBytesReconnects: 0,
        silentReconnect: false,
        transportCloseReconnectDelay: initialState.transportCloseReconnectDelay,
      };
    case MANAGEMENT_CONNECT_START:
      return {
        ...state,
        managementStep: MANAGEMENT_CONNECT_STARTED,
        connectCounter: state.connectCounter + 1,
      };
    case MANAGEMENT_CONNECT:
      if (action.error) {
        return {
          ...state,
          managementStep: MANAGEMENT_LOGGED_OUT,
          isReconnecting: false,
          streambuttonlock: false,
          connectCounter: 0,
          silentReconnect: false,
          transportCloseReconnectDelay:
            initialState.transportCloseReconnectDelay,
        };
      }
      return {
        ...state,
        managementStep: MANAGEMENT_LOGIN_STARTED,
        transportCloseReconnectDelay: initialState.transportCloseReconnectDelay,
      };
    case MANAGEMENT_EVENTS_CONNECTION_CONNECT:
      if (action.error) {
        return {
          ...state,
          managementStep: MANAGEMENT_LOGGED_OUT,
          isReconnecting: false,
          streambuttonlock: false,
          connectCounter: 0,
        };
      }
      return state;
    case MANAGEMENT_EVENTS_CONNECTION_DISCONNECT:
      return Object.assign({}, state, {
        camLoginData: {},
        streamInfoCounter: 0,
        managementStep: MANAGEMENT_LOGGED_OUT,
      });
    case MANAGEMENT_CAM_LOGIN: {
      if (action.meta && (action.meta.start || action.meta.end)) {
        if (action.meta.end) {
          return {
            ...state,
            silentReconnect: false,
            connectCounter: 0,
          };
        }
        return state;
      }
      if (action.error) {
        return {
          ...state,
          managementStep: MANAGEMENT_LOGGED_OUT,
          isReconnecting: false,
          silentReconnect: false,
          streambuttonlock: false,
        };
      }
      const {
        idleStreamBandwidth,
        streamStopTimeout,
        permanentCam2CamStream,
        availableMediaServer,
        statsInterval,
      } = action.payload;
      const changeSet = {
        managementStep: MANAGEMENT_LOGGED_IN,
        idleStreamBandwidth,
        permanentStream: idleStreamBandwidth !== -1,
        camLoginData: action.payload,
        availableMediaServer,
        streamStopTimeout,
        permanentCam2CamStream: permanentCam2CamStream || false,
        statsInterval,
      };
      return Object.assign({}, state, changeSet);
    }
    case MANAGEMENT_EVENTS_RECONNECT: {
      if (action.meta && action.meta.start) {
        return { ...state, isReconnecting: true };
      }
      return {
        ...state,
        step: STREAMING_IS_STARTING,
        availableMediaServer: action.payload.availableMediaServer,
        camLoginData: { ...state.camLoginData, ...action.payload },
      };
    }
    case STREAM_EVENTS_PUBLISH:
      return {
        ...state,
        step: STREAMING_STARTED,
        currentBandwidth: action.payload.videoBitrate,
        idleStreamStartTimeMs:
          action.payload.publishTime + state.idleStreamOffset,
        isReconnecting: false,
        zeroBytesReconnects: 0,
      };
    case STREAM_EVENTS_BANDWIDTH:
      return {
        ...state,
        currentBandwidth: action.payload.videoBitrate,
      };
    case STREAM_STOP_TIMER_START:
      return { ...state, stopTimerRunning: true };
    case STREAM_STOP_TIMER_STOP:
      return { ...state, stopTimerRunning: false };
    case STREAM_PUBLISH:
      if (action.error) {
        return Object.assign({}, state, {
          step: STREAMING_STOPPED,
          idleStreamStartTimeMs: NaN,
          currentBandwidth: -1,
        });
      }
      return Object.assign({}, state, {
        connectionData: action.payload,
        idleStreamStartTimeMs: NaN,
        currentBandwidth: -1,
        availableMediaServer: state.availableMediaServer.slice(1),
        step: STREAMING_IS_STARTING,
      });
    case STREAM_STOP:
      return {
        ...state,
        idleStreamStartTimeMs: NaN,
        step: STREAMING_STOPPED,
        currentBandwidth: -1,
      };
    case STREAM_RECONNECT:
      if (
        state.step === STREAMING_IS_STARTING ||
        state.step === STREAMING_STOPPED
      ) {
        return state;
      }
      return {
        ...state,
        step: STREAMING_RECONNECTING,
        idleStreamStartTimeMs: NaN,
        currentBandwidth: -1,
      };
    case MANAGEMENT_EVENTS_SOCKET_RECONNECT:
      return {
        ...state,
        silentReconnect: true,
        transportCloseReconnectDelay:
          action.payload && typeof action.payload.delay === 'number'
            ? action.payload.delay
            : state.transportCloseReconnectDelay,
      };
    case MANAGEMENT_EVENTS_CONNECTION_TRANSPORT_CLOSE:
      return {
        ...state,
        silentReconnect: true,
      };
    case RECONNECT_ZEROBYTES_INCREMENT:
      return { ...state, zeroBytesReconnects: action.payload + 1 };
    case SENDER_LOGIN_DATA:
      return {
        ...state,
        mute: action.payload.storedMute,
      };
    default:
      return state;
  }
};

export default streaming;
