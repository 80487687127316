import { user as userUtils } from '../../../utils';
import api from '../../../utils/api';
import add from './add';

export default () => async (dispatch, getState) => {
  try {
    if (userUtils.gamesBlocked(getState())) return;
    const response = await api.get('/user/games/distinct');

    if (userUtils.gamesBlocked(getState())) return;
    dispatch(add(response?.data));
  } catch (err) {
    console.log({ gameInvitationsError: err });
    console.log('could not load game invitations');
  }
};
