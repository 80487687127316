export default {
  en: 'englisch',
  es: 'spanisch',
  zh: 'chinesisch',
  hi: 'hindi',
  ar: 'arabisch',
  pt: 'portugiesisch',
  bn: 'bengalisch',
  ru: 'russisch',
  ja: 'japanisch',
  pa: 'punjabi',
  de: 'deutsch',
  jv: 'javanisch',
  te: 'telugu',
  vi: 'vietnamesisch',
  ko: 'koreanisch',
  fr: 'französisch',
  mr: 'marathi',
  ta: 'tamil',
  ur: 'urdu',
  tr: 'türkisch',
  it: 'italienisch',
  th: 'thailändisch',
  gu: 'gujarati',
  pl: 'polnisch',
  uk: 'ukrainisch',
  ro: 'rumänisch',
  nl: 'niederländisch',
  hu: 'ungarisch',
  sv: 'schwedisch',
  cs: 'tschechisch',
  el: 'griechisch',
  fi: 'finnisch',
  da: 'dänisch',
  sk: 'slowakisch',
  no: 'norwegisch',
  he: 'hebräisch',
  id: 'indonesisch',
  ms: 'malaiisch',
  fa: 'persisch',
  hr: 'kroatisch',
  sq: 'albanisch',
  kn: 'kannada',
  ml: 'malayalam',
  or: 'oriya',
  as: 'assamesisch',
  bh: 'bihari',
  ks: 'kashmiri',
  kok: 'konkani',
  sd: 'sindhi',
  ne: 'nepali',
  si: 'singhalesisch',
  om: 'oromo',
  ti: 'tigrinya',
  so: 'somali',
  am: 'amharisch',
  rw: 'kinyarwanda',
  rn: 'kirundi',
  mg: 'malagasy',
  ny: 'chichewa',
  ee: 'ewe',
  ha: 'hausa',
  ig: 'igbo',
  yo: 'yoruba',
  zu: 'zulu',
  st: 'sesotho',
  xh: 'xhosa',
  tn: 'setswana',
  ts: 'tsonga',
  ve: 'venda',
  sn: 'shona',
  af: 'afrikaans',
  az: 'aserbaidschanisch',
  be: 'weißrussisch',
  bs: 'bosnisch',
  ca: 'katalanisch',
  et: 'estnisch',
  tl: 'filipino (tagalog)',
  gl: 'galizisch',
  ht: 'haitianisch',
  is: 'isländisch',
  ku: 'kurdisch',
  lv: 'lettisch',
  lt: 'litauisch',
  lb: 'luxemburgisch',
  mk: 'mazedonisch',
  mt: 'maltesisch',
  mn: 'mongolisch',
  ps: 'paschtu',
  sr: 'serbisch',
  sl: 'slowenisch',
  sw: 'swahili',
  tg: 'tadschikisch',
  tk: 'turkmenisch',
  uz: 'usbekisch',
  cy: 'walisisch',
  yi: 'jiddisch',
  eu: 'baskisch',
  co: 'korsisch',
  fo: 'färöisch',
  fy: 'friesisch',
  gd: 'gälisch (schottisch)',
  haw: 'hawaiisch',
  mi: 'maori',
  sm: 'samoanisch',
  to: 'tongaisch',
};

