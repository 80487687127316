import favoriteSVG from '../../../../../../assets/img/svg/favorite.svg';
import noFavoriteSVG from '../../../../../../assets/img/svg/noFavorite.svg';
import Button from '../../../../../Button/Button';

function Favorite({
  isFavorite = false,
  show,
  favoriteUser,
  requestStarted,
  isActivated,
  isIgnored,
}) {
  const checkFavorite = requestStarted ? !isFavorite : isFavorite;
  const icon = checkFavorite ? favoriteSVG : noFavoriteSVG;

  return show ? (
    <div className="control-item favorite-icon">
      <Button
        icon={icon}
        intlTranslate={false}
        disabled={!!requestStarted || !isActivated || isIgnored}
        variant="icon-only"
        renumerateIRIElements={false}
        onClick={favoriteUser}
      />
    </div>
  ) : null;
}

export default Favorite;
