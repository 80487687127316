import { auth, stream } from '../../actions';
import { MANAGEMENT_LOGGED_IN } from '../../constants';
import { sender } from '../../services';
import { uuid } from '../../utils';
import { management } from '../';
import { SENDER_LOGOUT } from '../actions';

export default (reason, camLogoutReason = 15, logoutReason = 1) => {
  return (dispatch, getState) => {
    if (getState().streaming.camInitialized) {
      const stream = getState().streaming.stream;
      if (!!stream && stream.getTracks) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }

      if (getState().streaming.managementStep === MANAGEMENT_LOGGED_IN) {
        dispatch(management.logoutCam({}, camLogoutReason));
      }
    }
    dispatch(auth.stop());
    dispatch({
      type: SENDER_LOGOUT,
      payload: reason,
      meta: { start: true, stamp: uuid() },
    });
    dispatch(stream.stop());
    return sender
      .logout(logoutReason)
      .then((data) => {
        if (data.error) {
          return dispatch({
            type: SENDER_LOGOUT,
            error: true,
            payload: data.error,
          });
        }
        dispatch({ ...data, type: SENDER_LOGOUT });
      })
      .catch((err) => {
        dispatch({
          type: SENDER_LOGOUT,
          payload: err,
          error: true,
        });
      });
  };
};
