import { USER_NOTES_GET, USER_NOTES_UPDATE } from '../actions/actions';

const initialState = {
  userId: null,
  message: false,
  error: null,
  invalid: false,
  livecam: false,
};

const usernote = (state = initialState, action) => {
  switch (action.type) {
    case USER_NOTES_GET:
      if (action?.meta?.start) {
        return {
          ...state,
          message: '',
          error: null,
        };
      }
      if (action?.meta?.error) {
        return {
          ...state,
          error: action.payload.error,
          invalid: true,
          message: '',
          userId: action.payload.userId,
        };
      }
      return {
        ...state,
        error: null,
        invalid: false,
        userId: action.payload.userId,
        message: action.payload.message,
      };
    case USER_NOTES_UPDATE:
      if (action?.meta?.start) {
        return {
          ...state,
          error: null,
        };
      }
      if (action?.meta?.error) {
        return {
          ...state,
          error: action.payload.error,
          userId: action.payload.userId,
        };
      }
      return {
        ...state,
        error: null,
        userId: action.payload.userId,
        message: action.payload.message,
      };
    default:
      return state;
  }
};

export default usernote;
