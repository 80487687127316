import axios from 'axios';

import {
  userLogout as logout,
  userRefreshAuthToken as refreshAuthToken,
} from '../actions';
import store from '../store';

let isRefreshing = false;
let refreshQueue = [];

const authTokenRefreshmentDisabledForRoutes = ['/oauth/token'];

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

function processQueue(error) {
  refreshQueue.forEach((promise) => {
    if (error) {
      promise.reject(error);
    } else {
      promise.resolve();
    }
  });

  refreshQueue = [];
}

// Auth
function authRequestInterceptor(request) {
  if (!request.skipAttachingAuthToken) {
    const accessToken = store.getState().user?.access_token;

    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
  }

  return request;
}

function authTokenRefreshResponseInterceptor(error) {
  let originalRequest = error.config;

  if (
    error.response?.status === 401 &&
    !originalRequest._retry &&
    !authTokenRefreshmentDisabledForRoutes.includes(originalRequest.url)
  ) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        refreshQueue.push({ resolve, reject });
      })
        .then(() => {
          return axiosInstance(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const user = store.getState().user;

    if (!user?.refresh_token) {
      return Promise.reject(error);
    }
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(
          '/oauth/token',
          {
            refresh_token: user.refresh_token,
            grant_type: 'refresh_token',
            client_id: process.env.REACT_APP_CLIENT_ID,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
            is_logged_in: !!user.isLoggedIn,
          },
          { skipAttachingAuthToken: true }
        )
        .then((res) => {
          store.dispatch(refreshAuthToken(res.data));
          processQueue();

          resolve(axiosInstance(originalRequest));
        })
        .catch((err) => {
          console.log({ err });
          store.dispatch(logout());
          processQueue(err);

          reject(err);
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }
  if (error?.response?.status === 500) {
    error.response.data.translatedError = 'ERROR_REGISTER_GENERAL';
  }
  return Promise.reject(error);
}

// Axios instance
axiosInstance.interceptors.request.use(authRequestInterceptor);
axiosInstance.interceptors.response.use(
  undefined,
  authTokenRefreshResponseInterceptor
);

export default axiosInstance;

