import { lStorage, sStorage } from '../../services/storage';
import { USER_ADD_AUTH_TOKEN } from '../actions';

export default function (payload, shouldPersistToLocalStorage) {
  const storage = shouldPersistToLocalStorage ? lStorage : sStorage;

  storage.setItem('user', JSON.stringify(payload));
  return { type: USER_ADD_AUTH_TOKEN, payload, shouldPersistToLocalStorage };
}

