import { uuid } from '../../utils';
import { SONUS_ERROR } from '../actions';

export default (number, token = false) => ({
  type: SONUS_ERROR,
  payload: {
    number,
  },
  meta: {
    stamp: uuid(),
    token,
  },
});
