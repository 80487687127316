import { uuid } from '../../utils';
import { DO_NOT_SHOW_IN } from '../actions';

export default (requestSent) => (dispatch) => {
  if (requestSent) {
    return dispatch({
      type: DO_NOT_SHOW_IN,
      meta: {
        stamp: uuid(),
      },
    });
  }

  return dispatch({
    type: DO_NOT_SHOW_IN,
    meta: {
      stamp: uuid(),
      start: true,
    },
  });
};

