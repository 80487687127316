export default {
  DIARY_HEADLINE: `Dein Tagebuch`,
  DIARY_INTRO: `Mit einem Tagebucheintrag kannst Du Deinem Profil einen persönlichen Touch verleihen. Regelmäßige Tagebucheinträge bieten Dir die Chance auf täglich mehr und neue Besucher, denn jeder Eintrag wird auf der Startseite gezeigt.`,
  DIARY_INTRO_1: `Ermögliche neuen Besuchern und Deinen Stammusern einen Einblick in das Leben „ihrer“ Lieblingsamateurin und halte sie mit Updates, über neue Galerien oder Deine nächste Livecam-Show, auf dem Laufenden. Das wird Dir helfen eine starke Bindung aufzubauen und Deinen Umsatz fortwährend zu steigern.`,
  DIARY_CONTENT: `Einen Tagebucheintrag schreiben`,
  DIARY_CONTENT_1: `Im Hauptmenü unter „Mein Content“ wählst Du „Tagebuch“ aus. Hier siehst Du eine Übersicht Deiner bereits erstellten Tagebucheinträge. Durch Klicken auf die drei Punkte (das Eintragsmenü), kannst Du jeden Eintrag bearbeiten, archivieren oder löschen.`,
  DIARY_CONTENT_2: `Um einen neuen Tagebucheintrag zu erstellen, klicke oben auf den Button „Neuen Eintrag erstellen“.`,
  DIARY_CONTENT_3: `Diese Angaben kannst Du bearbeiten`,
  DIARY_CONTENT_4: `Hast Du Deinen Eintrag erstellt, hast Du zwei Optionen:`,
  DIARY_CONTENT_5: `
    <ul class='checklist'>
      <li>Klickst Du auf „Veröffentlichen“, wird Dein Eintrag auf Deinem Profil veröffentlicht und ist für alle Besucher sichtbar.</li>
      <li>Klickst Du auf „Speichern“ wird Dein Eintrag gespeichert und ist auf der Tagebuchübersicht zu sehen. Dort kannst Du ihn jederzeit durch Klicken auf das Eintragsmenü weiterbearbeiten oder veröffentlichen. </li>
    </ul>`,
  DIARY_CONTENT_6: `Wichtig:`,
  DIARY_CONTENT_7: `
    <ul>
      <li>Automatische Einträge (Profilbilder, Galerien) können nicht bearbeitet werden.</li>
      <li>Die Bilder in einem Tagebucheintrag dürfen allen FSK-Levels entsprechen. Du musst nicht selbst zu sehen sein, Du kannst auch ein Bild von Deiner Tasse Kaffee am Morgen hinzufügen oder mit einem Bild Deiner Shoppingeinkäufe eine gewisse Neugier aufbauen.</li>
      <li>Die Angabe Deiner Kontaktdaten ist verboten!</li>
    </ul>`,

  DIARY_CONTENT_STEP_1: `Titel`,
  DIARY_CONTENT_STEP_1_CONTENT: `Hier gibst Du die Überschrift für den neuen Tagebuch-Eintrag an.`,
  DIARY_CONTENT_STEP_2: `Beschreibung`,
  DIARY_CONTENT_STEP_2_CONTENT: `Schreibe etwas aus dem Leben. Dies kann ein einfacher Gruß oder sogar eine sexy Story sein, die Du gerade erlebt hast. Bewerbe Deine neueste Galerie oder schreibe, wann Du das nächste Mal in der Livecam zu sehen bist. Halte so Deine Stammkunden auf dem Laufenden und mache neue Besucher auf Dich aufmerksam. Lasse Deiner Fantasie freien Lauf.`,
  DIARY_CONTENT_STEP_3: `Bild hinzufügen`,
  DIARY_CONTENT_STEP_3_CONTENT: `Optional kannst Du hier ein Foto hochladen um Deinen Eintrag noch interessanter zu gestalten.`,
};
