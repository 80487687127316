import { connect } from 'react-redux';

import MyPublicProfile from './MyPublicProfile';

const mapStateToProps = (state) => ({
  nickname: state.user?.account?.nickname,
});

export default connect(mapStateToProps)(MyPublicProfile);

