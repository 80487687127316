import './UserProfileIndicator.scss';

import classNames from 'classnames';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import lovenseIcon from '../../../../assets/img/lovense/lush.svg';
import arrowIcon from '../../../../assets/img/svg/arrow-down.svg';
import attachmentsIcon from '../../../../assets/img/svg/attachment.svg';
import galleriesIcon from '../../../../assets/img/svg/content.svg';
import exclusiveCamIcon from '../../../../assets/img/svg/exclusive.svg';
import gamesIcon from '../../../../assets/img/svg/games.svg';
import voiceMessageIcon from '../../../../assets/img/svg/mic.svg';
import payttachmentsIcon from '../../../../assets/img/svg/payttachment.svg';
import virtualGiftsIcon from '../../../../assets/img/svg/virtualGifts.svg';
import UserProfileIndicatorItem from './UserProfileIndicatorItem';

function UserProfileIndicator({
  exclusive = false,
  toycontrol = false,
  galleries = false,
  games = false,
  voiceMessage = false,
  attachment = false,
  payttachment = false,
  virtualGift = false,
  isAdmin = false,
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  const intl = useIntl();

  if (isAdmin) {
    return null;
  }
  const indicatorClass = classNames('feature-indicator', {
    'is-expanded': isExpanded,
  });
  const iconClass = classNames('icon-arrow', {
    active: isExpanded,
  });
  return (
    <div className="feature-indicator-wrapper area">
      <div className={indicatorClass}>
        {isExpanded ? (
          <div className="feature-header">
            {intl.formatMessage({ id: 'feature.headline' })}
            <span className="icon-arrow-wrapper">
              <ReactSVG
                src={arrowIcon}
                wrapper="span"
                className={iconClass}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </span>
          </div>
        ) : null}
        <UserProfileIndicatorItem
          icon={exclusiveCamIcon}
          title={
            exclusive
              ? intl.formatMessage({
                  id: 'feature.exclusive',
                })
              : intl.formatMessage({
                  id: 'feature.exclusive.unavailable',
                })
          }
          isExpanded={isExpanded}
          className="feature-exclusive"
          active={exclusive}
        />
        <UserProfileIndicatorItem
          icon={lovenseIcon}
          title={
            toycontrol
              ? intl.formatMessage({
                  id: 'feature.toycontrol',
                })
              : intl.formatMessage({
                  id: 'feature.toycontrol.unavailable',
                })
          }
          isExpanded={isExpanded}
          className="feature-toycontrol"
          active={toycontrol}
        />
        <UserProfileIndicatorItem
          icon={galleriesIcon}
          title={
            galleries
              ? intl.formatMessage({
                  id: 'feature.galleries',
                })
              : intl.formatMessage({
                  id: 'feature.galleries.unavailable',
                })
          }
          isExpanded={isExpanded}
          className="feature-galleries"
          active={galleries}
        />
        <UserProfileIndicatorItem
          icon={gamesIcon}
          title={
            games
              ? intl.formatMessage({
                  id: 'feature.game',
                })
              : intl.formatMessage({
                  id: 'feature.game.unavailable',
                })
          }
          isExpanded={isExpanded}
          className="feature-games"
          active={games}
        />
        <UserProfileIndicatorItem
          icon={voiceMessageIcon}
          title={
            voiceMessage
              ? intl.formatMessage({
                  id: 'feature.voiceMessage',
                })
              : intl.formatMessage({
                  id: 'feature.voiceMessage.unavailable',
                })
          }
          isExpanded={isExpanded}
          className="feature-voice-message"
          active={voiceMessage}
        />
        <UserProfileIndicatorItem
          icon={virtualGiftsIcon}
          title={
            virtualGift
              ? intl.formatMessage({
                  id: 'feature.virtualGifts',
                })
              : intl.formatMessage({
                  id: 'feature.virtualGifts.unavailable',
                })
          }
          isExpanded={isExpanded}
          className="feature-virtual-gifts"
          active={virtualGift}
        />
        <UserProfileIndicatorItem
          icon={attachmentsIcon}
          title={
            attachment
              ? intl.formatMessage({
                  id: 'feature.attachments',
                })
              : intl.formatMessage({
                  id: 'feature.attachments.unavailable',
                })
          }
          isExpanded={isExpanded}
          className="feature-attachment"
          active={attachment}
        />
        <UserProfileIndicatorItem
          icon={payttachmentsIcon}
          title={
            payttachment
              ? intl.formatMessage({
                  id: 'feature.payttachments',
                })
              : intl.formatMessage({
                  id: 'feature.payttachments.unavailable',
                })
          }
          isExpanded={isExpanded}
          className="feature-payttachment"
          active={payttachment}
        />

        {!isExpanded ? (
          <span className="icon-arrow-wrapper">
            <ReactSVG
              src={arrowIcon}
              wrapper="span"
              className={iconClass}
              onClick={() => setIsExpanded(!isExpanded)}
            />
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default UserProfileIndicator;
