export default {
  LEGAL_PRIVACY: `Datenschutz`,
  LEGAL_PRIVACY_INTRO: `Vielen Dank, dass Sie unser Angebot nutzen. Wir möchten Sie mit diesem Dokument über unsere Verarbeitung personenbezogener Daten informieren.`,
  LEGAL_PRIVACY_RESPONSIBLE_HEADER: `Verantwortlicher`,
  LEGAL_PRIVACY_RESPONSIBLE_DATA: `IP Broadcasting B.V.<br />
  Lottumseweg 43<br />
  5971 BV Grubbenvorst<br />
  Niederlande<br />
  <br />
  E-Mail: <a href="mailto:compliance@livecreator.com">compliance@livecreator.com</a>`,
  LEGAL_PRIVACY_DATA_PROTECTION_HEADER: `Fragen zum Datenschutz und Wahrnehmung Ihrer Rechte`,
  LEGAL_PRIVACY_DATA_PROTECTION: `Wenn Sie Fragen zum Datenschutz haben oder von Ihren Rechten Gebrauch machen möchten, wenden Sie sich bitte an <a href="mailto:compliance@livecreator.com">compliance@livecreator.com</a> oder mit dem Zusatz „Datenschutz“ an die oben genannte Adresse.`,
  LEGAL_PRIVACY_PROCESS_DATA_HEADER: `Wann verarbeiten wir personenbezogene Daten`,
  LEGAL_PRIVACY_PROCESS_DATA: `Wir verarbeiten personenbezogener Daten, die Sie aktiv durch Ihre Eingaben uns übermitteln. Ferner verarbeiten wir automatisiert personenbezogene Daten aufgrund der Nutzung unseres Angebotes. Insbesondere in folgenden Fällen kann es daher zu einer Verarbeitung Ihrer personenbezogenen Daten kommen: 
  <br /><br />
  Wegen der Details verweisen wir auf die nachfolgenden Ausführungen.`,
  LEGAL_PRIVACY_VISIT_WEBSITE_HEADER: `Besuch unserer Webseite`,
  LEGAL_PRIVACY_VISIT_WEBSITE: `Wenn Sie unsere Webseite aufrufen, speichert das mit dem Betrieb der Webseite beauftragte Unternehmen die öffentliche IP-Adresse des Rechners, mit dem Sie unsere Webseite besuchen, samt Datum und Uhrzeit des Zugriffs. Bei der IP-Adresse handelt es sich um eine eindeutige numerische Adresse, unter der Ihr Rechner Daten in das Internet sendet bzw. abruft. Uns oder unserem Dienstleister ist im Regelfall nicht bekannt, wer sich hinter einer IP-Adresse verbirgt, es sei denn, Sie teilen uns während der Nutzung unserer Webseite Daten mit, welche uns Ihre Identifizierung ermöglichen. Ferner kann es zur Identifizierung eines Nutzers kommen, wenn wir rechtliche Schritte einleiten (z.B. bei Angriffen gegen unsere Webseite) und im Rahmen des Ermittlungsverfahrens Kenntnis von der Identität eines Nutzers bekommen. Sie müssen also im Regelfall keine Sorge haben, dass wir Ihre IP-Adresse Ihnen zuordnen können.
  <br /><br />
  Unser Dienstleister verwendet die IP-Adresse, damit Sie unsere Webseite abrufen und nutzen können, zur Erkennung und Abwehr von Angriffen gegen unsere Webseite. Leider gibt es immer wieder Angriffe gegen Webseiten, um deren Betreibern oder Nutzern Schaden zuzufügen (z.B. Verhindern des Zugriffs auf die Seite, Ausspionieren von Daten, Verbreiten von Schadsoftware (z.B. Viren) oder andere unrechtmäßige Zwecke). Durch solche Angriffe würde die bestimmungsgemäße Nutzung unserer Webseite und deren Funktionsfähigkeit sowie die Sicherheit der Besucher unserer Webseite beeinträchtigt werden. Die Verarbeitung der IP-Adresse samt des Zeitpunkts des Zugriffes erfolgt zur Abwehr solcher Angriffe und damit von potentiellen Gefahren für uns und die Nutzer unserer Webseite. Wir verfolgen über unseren Dienstleister mit dieser Verarbeitung das berechtigte Interesse der Aufrechterhaltung unseres Geschäftsbetriebes sowie der Abwehr rechtswidriger Störungen gegen uns sowie die Besucher unserer Webseite. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 f) DSGVO. Die gespeicherten IP-Daten werden durch Anonymisierung gelöscht, wenn sie nicht mehr für die Erkennung oder Abwehr eines Angriffs benötigt werden.
  <br /><br />
  Zum schnelleren Ausliefern unserer Daten in der jeweiligen Region setzen wir Content Delivery Netzwerke ein, um ihnen die größtmöglichen Kapazitäten und Datendurchsatz zu gewähren. Hierbei wird die öffentliche IP-Adresse des Rechners (siehe oben) übermittelt. Weitere Informationen zum Highwinds CDN und deren Privacy Policy können sie hier entnehmen. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 f) DSGVO`,
  LEGAL_PRIVACY_CREATE_ACCOUNT_HEADER: `Anlegen eines Kundenkontos`,
  LEGAL_PRIVACY_CREATE_ACCOUNT: `Sie nutzen unser Angebot, um erotische Abenteuer zu erleben und um mit anderen Nutzern in Kontakt treten zu können. Um alle Funktionen unseres Angebotes nutzen zu können, müssen Sie ein Kundenkonto anlegen. Mit Ausnahme der bei der Registrierung anzugebenden Informationen, sind alle weiteren Angaben zu Ihrer Person freiwillig. Sie allein entscheiden, welche Angaben Sie in Ihr Nutzerprofil aufnehmen. Sie können Ihre Angaben jederzeit bearbeiten und auch wieder löschen. Je mehr Angaben Sie machen, umso besser können die anderen Nutzer Sie finden und erkennen, welche gemeinsame Interessen sie haben. Da unsere Webseite über das Internet für jeden zugänglich ist, sind alle von Ihnen angegebenen Informationen öffentlich.
  <br /><br />
  Sofern Sie keine Daten angeben, welche Ihre Identifizierung ermöglichen (z.B. Ihre Handynummer) können Sie unser Angebot so nutzen, dass wir nicht wissen wer Sie sind. Sie müssen zwar Ihren Namen und Ihre Adresse angeben, wenn Sie Coins kaufen, aber diese Daten erhält nur unser Zahlungsdienstleister, der sie nicht an uns weitergibt.  Ihnen ist folglich eine pseudonyme Nutzung unseres Angebotes möglich.
  <br /><br />
  Wenn Sie ein Kundenkonto anlegen und Ihr Profil um Angaben ergänzen, verarbeiten wir die hierfür von Ihnen angegebenen Daten, um Ihr Kundenkonto anzulegen, zu verwalten und um Ihnen die Inanspruchnahme unserer Leistungen zu ermöglichen, die mit Ihrem Kundenkonto zusammenhängen. Ferner werden die Daten bestimmungsgemäß für unser Angebot genutzt und anderen Nutzern zugänglich gemacht (z.B. Suche nach bestimmen Neigungen oder Interessen, Matchcheck zur Erkennung, welche gemeinsamen Interessen und Vorlieben man hat).  Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 b) DSGVO.
  <br /><br />
  Beim Anlegen Ihres Nutzerkontos erkennen wir anhand Ihrer IP-Adresse Ihren ungefähren Aufenthaltsort. Diesen verwenden wir, um diesen Ort bereits in Ihr Nutzerprofil einzutragen, wobei Sie diese Angabe ändern können. Dies erfolgt, damit eine örtliche Suche nach Mitgliedern unseres Angebotes auch den richtigen Aufenthaltsort verwendet und Mitglieder daher - wenn sie den Ort nicht ändern - gefunden werden können.  Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 f) DSGVO.
  <br /><br />
  Die Daten werden bis zur Löschung Ihres Kundenkontos gespeichert. Sofern wir gesetzlich zu einer längeren Speicherung verpflichtet sind (z.B. zur Erfüllung von Buchhaltungspflichten) oder gesetzlich zu einer längeren Speicherung berechtigt sind (z.B. wegen eines laufenden Rechtsstreits gegen den Inhaber eines Benutzerkontos), erfolgt die Löschung nach Ablauf der Speicherverpflichtung bzw. der gesetzlichen Berechtigung.`,
  LEGAL_PRIVACY_GDPR_ARTICLE_9_HEADER: `Verarbeitung besonderer Kategorien personenbezogener Daten im Sinne von Art. 9 DSGVO`,
  LEGAL_PRIVACY_GDPR_ARTICLE_9: `Soweit Sie in Ihr Profil Daten eintragen oder uns sonst zur Verarbeitung mitteilen, die als besondere Kategorien personenbezogener Daten im Sinne von Art. 9 DSGVO gelten könnten (z.B. ethnische Herkunft, religiöse oder weltanschauliche Überzeugungen, Daten zum Sexualleben oder der sexuellen Orientierung), verarbeiten wir diese allein aufgrund Ihrer freiwilligen Angaben und ausschließlich für die Erbringung der Funktionen unseren Angebotes, für die diese Daten abgefragt und verwendet werden. Hierzu gehören die öffentliche Angabe dieser Daten im Zusammenhang mit Ihrem Kundenkonto für andere Nutzer unseres Angebotes, öffentliche Suchfunktion für Nutzer, Matchcheck und sämtliche anderen Funktionen, für die für Sie als Nutzer erkennbar ist, dass hierfür diese Daten verwendet werden. Wenn Sie dies nicht wünschen, geben Sie in Ihrem Profil bitte keine Daten an und teilen Sie uns oder anderen Nutzern keine Daten mit, welche Art. 9 DSGVO unterfallen.`,
  LEGAL_PRIVACY_CONTACT_HEADER: `Kontaktmöglichkeiten`,
  LEGAL_PRIVACY_CONTACT: `Wir bieten unseren Mitgliedern verschiedene Möglichkeit in Kontakt zu treten. Neben der bei der Anmeldung angegebenen Email-Adresse sind hierfür weitere, freiwillige Angaben erforderlich. Soweit die Kommunikation über unser Angebot erfolgt, werden die Kommunikationsinhalte gespeichert, damit Sie diese später wieder abrufen können. Für Kontakte speichern wir die Historie Ihrer Kontakte für Sie abrufbar für 180 Tage, damit Sie ihre bisherigen Kontakte, schnell wiederfinden können.
  <br /><br />
  Wenn Sie diese löschen möchten, wenden Sie sich bitte an unseren Support.
  <br /><br />
  Rechtsgrundlage für diese Verarbeitung der Kommunikationsdaten ist Art. 6 Abs. 1 b) DSGVO, soweit die Verarbeitung für die Erfüllung einer uns Ihnen gegenüber obliegenden vertraglichen Pflicht erforderlich ist. Für die darüber hinausgehende Verarbeitung ist Art. 6 Abs. 1f) DSGVO Rechtsgrundlage. Unser zu dieser Verarbeitung berechtigtes Interesse liegt darin, dass wir Ihnen Ihre bisherigen Kommunikationsinhalte zur Verfügung stellen möchten, damit Sie nachvollziehen können mit welchem unsere Nutzer sie welche Nachrichten ausgetauscht haben. Für die Speicherung der Historie Ihrer Livecam-Kontakte ist das berechtigtes Interesse, dass wir Ihnen das Wiederauffinden von Livecam-Partnern ermöglichen möchten, mit denen Sie besonders viel Spaß hatten
  <br /><br />
  Wenn Sie uns über eine der angebotenen Kontaktmöglichkeiten eine Mitteilung zukommen lassen, verwenden wir Ihre uns mitgeteilten Daten für die Bearbeitung Ihrer Anfrage. Rechtsgrundlage hierfür ist unser berechtigtes Interesse an der Beantwortung Ihres Anliegens gemäß Art. 6 Abs. 1 f DSGVO. Sofern Ihre Anfrage dem Abschluss oder der Durchführung eines Vertrages mit uns dient, ist zusätzliche Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1 b DSGVO. Die Daten werden nach Erledigung Ihrer Anfrage gelöscht. Sofern wir gesetzlich zu einer längeren Speicherung verpflichtet sein sollten, erfolgt die Löschung nach Ablauf der entsprechenden Frist.`,
  LEGAL_PRIVACY_COINS_HEADER: `Kauf von Coins`,
  LEGAL_PRIVACY_COINS: `Wenn Sie Coins kaufen, verarbeiten wir die von Ihnen angegebenen Daten für den Abschluss des Vertrages und seine Durchführung. Im erforderlichen Umfang werden dabei Daten an Dienstleister für die Abrechnung Ihres Kaufes übergeben.  Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 b) DSGVO.
  <br /><br />
  Gemeinsam mit unserem Zahlungsdienstleister verarbeiten wir diese Daten in Verbindung mit Ihrer IP-Adresse zur Erkennung und Abwehr von Betrugsversuchen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 f. DSGVO. Wir verfolgen damit das Ziel, uns jeweils vor betrügerischen Transaktionen zu schützen.
  <br /><br />
  Daten, die im Zusammenhang mit dem Abschluss eines Vertrages über den Kauf von Coins gespeichert werden, werden nach Ablauf der gesetzlichen Aufbewahrungspflicht gelöscht. Soweit aufgrund der Abwicklung eines Kaufvertrages rechtliche Aufzeichnungs- und Aufbewahrungspflichten (z.B. Aufbewahrung von Rechnungen nach dem Steuerrecht) bestehen, ist Rechtsgrundlage der Verarbeitung Art. 1 Abs. 1 c) DSGVO.
  <br /><br />
  Wir löschen oder anonymisieren die Daten, wenn sie nicht mehr für die Durchführung des jeweiligen Vertrages benötigt werden und keine gesetzlichen Aufbewahrungspflichten mehr bestehen.`,
  LEGAL_PRIVACY_COOKIES_HEADER: `Verwendung von Cookies und vergleichbaren Technologien`,
  LEGAL_PRIVACY_COOKIES: `Wir nutzen für den Betrieb unserer Webseite Cookies und vergleichbare Technologien (Local Storage, Pixel-Tags, Zählpixel und andere Identifikatoren), um zu verstehen, wie Besucher unsere Website nutzen und Voreinstellungen, die ein Nutzer vorgenommen hat, in seinem Browser zu speichern. GGF: Wir nutzen diese auch, um unsere Websites für jeden Nutzer zu personalisieren, und unsere Werbemaßnahmen anzupassen.
  <br /><br />
  Bei einem Cookie handelt es sich um kleine Textdateien, die bei Aufruf unserer Webseite durch Ihren Browser auf Ihrem Computer gespeichert werden. Wenn Sie unsere Website später erneut aufrufen, können wir diese Cookies aus Ihrem Browser wieder auslesen. Cookies werden unterschiedliche lange gespeichert. Sie haben jederzeit die Möglichkeit, in Ihrem Browser einzustellen, welcher Cookies er annehmen soll, was aber zur Folge haben kann, dass unsere Webseite nicht mehr richtig funktioniert. Ferner können Sie Cookies selbständig jederzeit löschen. Wenn Sie das nicht machen, können wir beim Speichern angeben, wie lange ein Cookie auf Ihrem Rechner gespeichert werden soll. Hier ist zu unterscheiden, zwischen sog. Sitzungs-Cookies und dauerhaften Cookies. Sitzungs-Cookies werden von Ihrem Browser gelöscht, wenn Sie unsere Webseite verlassen oder Sie den Browser beenden. Dauerhafte Cookies werden für die Dauer gespeichert, welche wir bei der Speicherung angeben.`,
  LEGAL_PRIVACY_COOKIES_PURPOSE_HEADER: `Wir nutzen Cookies zu folgenden Zwecken:`,
  LEGAL_PRIVACY_COOKIES_PURPOSE: `Technisch erforderliche Cookies, die für die Nutzung der Funktionen unserer Webseite zwingend erforderlich sind (z.B. Erkennung ob Sie sich angemeldet haben). Ohne diese Cookies könnten bestimmte Funktionen nicht bereitgestellt werden.
  <br /><br />
  Funktionale Cookies, die eingesetzt werden, um bestimmte Funktionen, welche Sie nutzen wollen technisch durchführen zu können. GGF Wir nutzen solche Cookies auch, um die Webseite für Sie zu personalisieren.
  <br /><br />
  Analyse-Cookies, die dazu dienen Ihr Nutzerverhalten zu analysieren. Zu Details lesen Sie bitte die Information zu „Auswertungen“.
  <br /><br />
  Cookies von Drittanbietern. Cookies von Drittanbietern werden von Dritten gespeichert, deren Funktionen wir auf unserer Webseite einbinden, um bestimmte Funktionen zu ermöglichen. Sie können ebenfalls zur Analyse des Nutzerverhaltens eingesetzt werden. Zu Details lesen Sie bitte die Information zu „Auswertungen“.
  <br /><br />
  Die meisten Browser, die unsere Nutzer verwenden, erlauben es, einzustellen, welche Cookies gespeichert werden sollen und ermöglichen es, (bestimmte) Cookies wieder zu löschen.  Wenn Sie das Speichern von Cookies auf bestimmte Webseiten einschränken oder keine Cookies von dritten Webseiten zulassen, kann es unter Umständen dazu führen, dass unsere Webseite nicht mehr in vollem Umfang genutzt werden kann. Hier finden Sie Informationen, wie Sie für die üblichsten Browser die Cookie-Einstellungen anpassen können:
  <ul>
  <li>Google Chrome (<a rel="external" target="_blank" href="https://support.google.com/chrome/answer/95647?hl=de">support.google.com/chrome/answer/95647?hl=de</a>)</li>
  <li>Internet Explorer (<a rel="external" target="_blank" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a>)</li>
  <li>Firefox (<a rel="external" target="_blank" href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen</a>)</li>
  <li>Safari (<a rel="external" target="_blank" href="https://support.apple.com/kb/PH21411?locale=de_DE">https://support.apple.com/kb/PH21411?locale=de_DE</a>)</li>
  </ul>`,
  LEGAL_PRIVACY_EVALUATIONS_HEADER: `Auswertungen`,
  LEGAL_PRIVACY_EVALUATIONS: `Von uns beauftragte Dienstleister erstellen für uns nicht personenbezogene Auswertungen, welche Nutzergruppen wie auf unsere Webseite zugreifen und wie sie diese nutzen. Hierfür werden z.B. allgemeine Informationen verarbeitet, wie die örtliche Herkunft, der verwendete Browser, von welcher Webseite zu uns gewechselt wurde und welche Suchbegriffe gegebenenfalls verwendet wurden, um unsere Seite zu finden. Ferner wird analysiert, wie unsere Webseite genutzt wird, welche Seiten wie häufig und wie lange aufgerufen werden und welche Funktionen unserer Webseite wie genutzt werden. Diese Daten helfen uns besser zu verstehen, wie unsere Website gefunden wird, um unsere Suchmaschinenoptimierung und unsere Werbemaßnahmen zu verbessern. Anhand der Informationen können wir ferner besser verstehen, wie unsere Webseite von den Nutzern genutzt wird und welche Funktionen von besonderen Interesse für sie sind, um diese benutzerfreundlicher zu gestalten und um solche Funktionen zu erweitern, von denen wir meinen, dass Sie im Interesse unserer Nutzer sind. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 f) DSGVO.
  <br /><br />
  Wenn Sie dies nicht wünschen, installieren Sie bitte in Ihrem Browser eine sog. Erweiterung, die solche Analysen unterbindet. Zwei bekannt Tools sind Ghostery(<a rel="external" target="_blank" href="https://www.ghostery.com/">https://www.ghostery.com/</a>) und uBlock Origin (<a rel="external" target="_blank" href="https://github.com/gorhill/uBlock/">https://github.com/gorhill/uBlock/</a>). Ferner sollten Sie Ihren Browser so einstellen, dass dieser keine Cookies von dritten Seiten annimmt. Hier finden Sie Informationen, wie Sie für die üblichsten Browser die Cookie-Einstellungen anpassen können:
  <ul>
  <li>Google Chrome (<a rel="external" target="_blank" href="https://support.google.com/chrome/answer/95647?hl=de">https://support.google.com/chrome/answer/95647?hl=de</a>)</li>
  <li>Internet Explorer (<a rel="external" target="_blank" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a>)</li>
  <li>Firefox (<a rel="external" target="_blank" href="https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen">https://support.mozilla.org/de/kb/cookies-erlauben-und-ablehnen</a>)</li>
  <li>Safari (<a rel="external" target="_blank" href="https://support.apple.com/kb/PH21411?locale=de_DE">https://support.apple.com/kb/PH21411?locale=de_DE</a>)</li>
  </ul>`,
  LEGAL_PRIVACY_GOOGLE_ANALYTICS_HEADER:
    `Google Analytics Datenschutzerklärung `,
  LEGAL_PRIVACY_GOOGLE_ANALYTICS: `Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Die Benutzung erfolgt auf der Grundlage des Art. 6 Abs. 1 S. 1 lit. f. DSGVO. Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung der Webseite wie
<ul>
<li>Browser-Typ/-Version,</li>
<li>verwendetes Betriebssystem,</li>
<li>Referrer-URL (die zuvor besuchte Seite),</li>
<li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
<li>Uhrzeit der Serveranfrage</li>
</ul>
werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.  Wir verwenden Google Analytics so, dass Ihre IP-Adresse nur in anonymisierter Form verwendet wird. Diese Anonymisierung erfolgt nach Mitteilung von Google in der Europäischen Union oder einem Mitgliedstaat des EWR. Nur in Ausnahmefällen soll die volle IP-Adresse an einen Server von Google in den USA übertragen und erst dort gekürzt werden. Nach Auskunft von Google erfolgt die Anonymisierung bevor die IP-Adresse erstmals auf einen dauerhaften Datenträger gespeichert wird. Wegen der Einzelheiten verweisen wir auf die Datenschutzerklärung von Google, abrufbar unter <a rel="external" target="_blank" href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>.
<br /><br />
Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: <a rel="external" target="_blank" href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>.
<br /><br />
Google ist Mitglied des PrivacyShield Übereinkommens und hat mit uns einen Auftragsverarbeitungsvertrag für Google Analytics abgeschlossen.
<br /><br />
Wir nutzen Google Analytics weiterhin dazu, Daten aus Double-Click-Cookies und auch Google Ads zu statistischen Zwecken auszuwerten. Sollten Sie dies nicht wünschen, können Sie dies über den Anzeigenvorgaben-Manager (<a rel="external" target="_blank" href="http://www.google.com/settings/ads/onweb/?hl=de">http://www.google.com/settings/ads/onweb/?hl=de</a>) deaktivieren.
<br /><br />
Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe <a rel="external" target="_blank" href="https://support.google.com/analytics/answer/6004245?hl=de">https://support.google.com/analytics/answer/6004245?hl=de</a>.`,
  LEGAL_PRIVACY_GOOGLE_ADWORDS_HEADER:
    `Google Googleadservices / Google Ads Conversion`,
  LEGAL_PRIVACY_GOOGLE_ADWORDS: `Diese Webseite verwendet Tools für “Online-Marketing” der Firma Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Dies dient dem erkennen, dass ein Besucher über eine Google-Anzeige auf unsere Internetseite gestoßen ist. Google verwendet Cookies, die auf Ihrem Computer gespeichert werden und eine Analyse der Benutzung der Website ermöglicht. Die Cookies für das sogenannte “Conversion-Tracking” werden gesetzt, wenn Sie auf eine von Google geschaltete Anzeige klicken. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung. Wenn Sie das “Conversion-Tracking” verhindern möchten, können Sie Ihren Browser so einstellen, dass Cookies von der Domain “<a rel="external" target="_blank" href="https://googleadservices.com">googleadservices.com</a>” blockiert werden. Sie können auch Ihr Recht auf Opt-Out wahrnehmen über die Seite: <a rel="external" target="_blank" href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a>. Falls Sie mehr über diese Methoden erfahren möchten oder wissen möchten, welche Möglichkeiten Sie haben, damit diese Informationen nicht von Google verwendet werden können, klicken Sie hier: <a rel="external" target="_blank" href="https://www.google.com/settings/u/0/ads/authenticated">https://www.google.com/settings/u/0/ads/authenticated</a>`,
  LEGAL_PRIVACY_EMAILS_HEADER: `Emails`,
  LEGAL_PRIVACY_EMAILS: `Wir übersenden Ihnen per Email Nachrichten anderer Nutzer sowie Informationen zur Nutzung unseres Angebotes. Rechtsgrundlage der Verarbeitung ist Art. 6 Abs. 1 b) DSGVO. Für den Versand der Emails verwenden wir einen externen Dienstleister, an den wir im erforderlichen Umfang Ihre personenbezogenen Daten weitergeben.
  <br /><br />
  Sie können diese Nachrichten jederzeit abbestellen. Einen entsprechenden Link finden Sie am Ende einer jeden Email oder auch auf der Webseite.`,
  LEGAL_PRIVACY_NEWSLETTER_HEADER: `Newsletter`,
  LEGAL_PRIVACY_NEWSLETTER: `Mit Ihrer Anmeldung zu unserem Angebot haben wir Sie darüber informiert, dass wir Ihre Email-Adresse gem. § 7 Abs. 3 UWG für die Übersendung unseres Email-Newsletters verwenden. Sie können der Verwendung jederzeit widersprechen, wir werden Sie dann aus unserem Verteiler streichen. Sie finden einen entsprechenden Link am Ende eines jeden Newsletters.
  <br /><br />
  Rechtsgrundlage der Verarbeitung ist § 7 Abs. 3 UWG in Verbindung mit Art. 6 Abs. 1 f) DSGVO. Unser berechtigtes Interesse hierfür ist Ihre laufende Information über unser Angebot auf Grundlage der Erlaubnis im Sinne des § 7 Abs. 3 UWG  Für unsere Newsletter nutzen wir einen externen Dienstleister, der als Auftragsverarbeiter für uns tätig wird.`,
  LEGAL_PRIVACY_CHAT_HEADER: `Chat zwischen Nutzern`,
  LEGAL_PRIVACY_CHAT: `Wir bieten unseren Nutzern als Teil unserer vertraglichen Leistungen die Möglichkeit, Chat-Nachrichten zu versenden und zu empfangen. Rechtsgrundlage der entsprechenden Verarbeitung der personenbezogenen Daten ist Art. 6 Abs. 1 b) DSGVO. Die entsprechenden Nachrichten werden gespeichert, bis Sie diese löschen lassen, sofern wir nicht gesetzlich zu einer weiteren Speicherung berechtigt oder verpflichtet sind.`,
  LEGAL_PRIVACY_LOCATION_HEADER: `Ermittlung Ihres Standortes`,
  LEGAL_PRIVACY_LOCATION: `Um Ihnen andere Nutzer unseres Angebotes aus Ihrer Nachbarschaft empfehlen zu können, ermitteln wir mittels Ihres Browsers Ihren Standort. Hierfür ist es erforderlich, dass Sie auf die entsprechende Frage Ihres Browsers Ihren Standort freigeben. Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 a) DSGVO. Sie können diese Freigabe jederzeit wieder in den Einstellungen Ihres Browsers ändern.`,
  LEGAL_PRIVACY_ACCESS_RIGHT_HEADER: `Recht auf Auskunft`,
  LEGAL_PRIVACY_ACCESS_RIGHT: `Nach Art. 15 DSGVO haben Sie das Recht, von uns eine Bestätigung darüber zu verlangen, ob durch uns Sie betreffende personenbezogene Daten verarbeitet werden. Wenn dies der Fall, so haben Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf weitergehende Informationen, die in Art. 15 DSGVO genannt sind.`,
  LEGAL_PRIVACY_RECTIFIY_RIGHT_HEADER: `Recht auf Berichtigung`,
  LEGAL_PRIVACY_RECTIFIY_RIGHT: `Nach Art. 16 DSGVO haben Sie das Recht, von uns unverzüglich die Berichtigung unrichtiger personenbezogener Daten zu verlangen, die Sie betreffen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie ferner das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.`,
  LEGAL_PRIVACY_ERASURE_RIGHT_HEADER: `Recht auf Löschung`,
  LEGAL_PRIVACY_ERASURE_RIGHT: `Sie haben das Recht von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, Wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern die entsprechenden Voraussetzungen des Art. 17 DSGVO vorliegen. Wegen der Einzelheiten verweisen wir auf Art. 17 DSGVO.`,
  LEGAL_PRIVACY_RESTRICT_RIGHT_HEADER: `Recht auf Einschränkung der Verarbeitung`,
  LEGAL_PRIVACY_RESTRICT_RIGHT: `Nach Maßgabe des Art. 18 DSGVO haben Sie unter bestimmten Voraussetzungen das Recht, von uns die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.`,
  LEGAL_PRIVACY_PORTABILITY_RIGHT_HEADER: `Recht auf Datenübertragbarkeit`,
  LEGAL_PRIVACY_PORTABILITY_RIGHT: `Nach Art 20 DSGVO haben Sie das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern die Verarbeitung auf einer Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe a oder Artikel 9 Absatz 2 Buchstabe a oder auf einem Vertrag gemäß Artikel 6 Absatz 1 Buchstabe b beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt.`,
  LEGAL_PRIVACY_OBJECT_RIGHT_HEADER: `Widerspruchsrecht`,
  LEGAL_PRIVACY_OBJECT_RIGHT: `Nach Art. 21 DSGVO haben Sie das Recht gegen die Verarbeitung Sie betreffender personenbezogener Daten, die auf Grundlage von Artikel 6 Absatz 1 Buchstaben e oder f DSGVO erfolgt, Widerspruch einzulegen.
  <br /><br />
  Wenn Sie von einem Ihnen zustehenden Recht Gebrauch machen möchten, wenden Sie sie bitte an uns als Verantwortlichen unter den oben angegebenen Kontaktdaten oder nutzen Sie eine der anderen von uns angebotenen Arten und diese Mitteilung zukommen zu lassen. Wenn Sie hierzu Fragen haben, nehmen Sie bitte Kontakt mit uns auf.`,
  LEGAL_PRIVACY_SUPERVISORY_AUTHORITY_HEADER: `Bestehen eines Beschwerderechts bei der Aufsichtsbehörde`,
  LEGAL_PRIVACY_SUPERVISORY_AUTHORITY: `Sie haben nach Art. 77 DSGVO hat unbeschadet eines anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs das Recht, sich bei der Aufsichtsbehörde zu beschweren. Dieses Recht besteht insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt.`,
};
