import {
  ALERT_CLOSE,
  ATTACHMENT_DELETE_NOT_ALLOWED,
  BROWSER_NOT_ALLOWED,
  FRIEND_ERROR,
  NEW_DIALOG,
  PROFILE_PICTURE_ERROR,
  SOUND_PLAY_CONFIRM,
  TOKEN_LOGIN_ERROR,
  USER_SHOW_LOGOUT_ALERT,
} from '../../actions/actions';
import {
  ALERT_ACTION_USER_LOGOUT,
  ALERT_LEVEL_ERROR,
  ALERT_LEVEL_INFO,
  ALERT_LEVEL_NONE,
  ALERT_TYPE_CLOSE,
  ALERT_TYPE_CONFIRM,
  ALERT_TYPE_INFO,
} from '../../constants';
import { ucfirst } from '../../utils';

const initialState = [];

const allowed = [
  {
    name: 'chrome',
    minVersion: '80.0.0',
    operator: '>',
  },
  {
    name: 'firefox',
    minVersion: '75.0.0',
    operator: '>',
  },
  {
    name: 'opera',
    minVersion: '67.0.0',
    operator: '>',
  },
  {
    name: 'edge',
    minVersion: '80.0.0',
    operator: '>',
  },
];

const reduce = (state = initialState, action) => {
  switch (action.type) {
    // general alert actions
    case ALERT_CLOSE:
      return state.filter((alert) => alert.stamp !== action.payload.stamp);
    case SOUND_PLAY_CONFIRM: {
      if (state.find((a) => a?.message?.id === 'alert.infoSound')) {
        return state;
      }

      return [
        ...state,
        {
          type: ALERT_TYPE_INFO,
          stamp: action.meta.stamp,
          modal: true,
          level: ALERT_LEVEL_INFO,
          message: {
            translate: true,
            id: 'alert.infoSound',
            values: {},
          },
        },
      ];
    }
    case BROWSER_NOT_ALLOWED:
      return [
        ...state,
        {
          stamp: action.meta.stamp,
          type: ALERT_TYPE_INFO,
          level: ALERT_LEVEL_NONE,
          scope: 'livecam',
          message: {
            translate: true,
            id: 'browser.notAllowed',
            values: {
              browsers: allowed.map(
                (b) => `${ucfirst(b.name)} ${b.operator} ${b.minVersion}`
              ).join(`,
                `),
            },
          },
        },
      ];
    case ATTACHMENT_DELETE_NOT_ALLOWED:
      return [
        ...state,
        {
          stamp: action.payload.stamp,
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          scope: 'general',
          message: {
            translate: true,
            id: 'alert.attachmentInUse',
            values: {},
          },
        },
      ];
    case NEW_DIALOG:
      if (action.error && action.meta?.stamp) {
        return [
          ...state,
          {
            stamp: action.meta.stamp,
            type: ALERT_TYPE_INFO,
            level: ALERT_LEVEL_NONE,
            scope: 'livecam',
            message: {
              translate: true,
              id: 'alert.dialogFetchError',
            },
          },
        ];
      }
      return state;
    case FRIEND_ERROR:
    case TOKEN_LOGIN_ERROR:
      return [
        ...state,
        {
          stamp: action.payload.stamp,
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          scope: 'general',
          message: {
            translate: true,
            id: 'ERROR_DURING_ACTION',
            values: {},
          },
        },
      ];
    case PROFILE_PICTURE_ERROR:
      return [
        ...state,
        {
          stamp: action.payload.stamp,
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          scope: 'general',
          message: {
            translate: true,
            id: 'ERROR_DURING_ACTION',
            values: {},
          },
        },
      ];
    case USER_SHOW_LOGOUT_ALERT:
      const index = state.findIndex(
        (alert) => alert.alertAction === ALERT_ACTION_USER_LOGOUT
      );
      if (index !== -1) return state;
      return [
        ...state,
        {
          stamp: action.payload.stamp,
          type: ALERT_TYPE_CONFIRM,
          level: ALERT_LEVEL_NONE,
          alertAction: ALERT_ACTION_USER_LOGOUT,
          scope: 'general',
          message: {
            translate: true,
            id: 'ALERT_USER_LOGOUT',
          },
        },
      ];
    default:
      return state;
  }
};

export default reduce;

