import { MANAGEMENT_USER_FAVORITE } from '../actions';

export default (id) => async (dispatch) => {
  return dispatch({
    type: MANAGEMENT_USER_FAVORITE,
    payload: { userId: id },
    meta: {
      remove: true,
    },
  });
};
