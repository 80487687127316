import './SettingsButton.scss';

import classNames from 'classnames';
import React from 'react';

import settingsIcon from '../../assets/img/svg/settings.svg';
import Button from '../Button/Button';

function SettingsButton({ active = false, onClick }) {
  const classes = classNames('btn-settings', {
    active,
  });

  return (
    <Button
      intlTranslate={false}
      icon={settingsIcon}
      variant="icon-only"
      className={classes}
      onClick={onClick}
      renumerateIRIElements={false}
    />
  );
}

export default SettingsButton;
