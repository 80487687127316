import { connect } from 'react-redux';

import { contestUtils } from '../../utils';
import Contest from './Contest';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  contest: contestUtils.getContest(state),
});
export default connect(mapStateToProps)(Contest);

