import api from '../../utils/api';
import { updateLanguages, userLogout } from '../';

const languages = {
  en: {
    en: 'English',
    de: 'Englisch',
  },
  fr: {
    en: 'French',
    de: 'Französisch',
  },
  it: {
    en: 'Italian',
    de: 'Italienisch',
  },
  es: {
    en: 'Spanish',
    de: 'Spanisch',
  },
  pl: {
    en: 'Polish',
    de: 'Polnisch',
  },
  nl: {
    en: 'Dutch',
    de: 'Niederländisch',
  },
  el: {
    en: 'Greek',
    de: 'Griechisch',
  },
  sv: {
    en: 'Swedish',
    de: 'Schwedisch',
  },
  ru: {
    en: 'Russian',
    de: 'Russisch',
  },
  cs: {
    en: 'Czech',
    de: 'Tschechisch',
  },
  de: {
    en: 'German',
    de: 'Deutsch',
  },
  ro: {
    en: 'Romanian',
    de: 'Rumänisch',
  },
  ba: {
    en: 'Bosnian',
    de: 'Bosnisch',
  },
  rs: {
    en: 'Serbian',
    de: 'Serbisch',
  },
  th: {
    en: 'Thai',
    de: 'Thai',
  },
  ph: {
    en: 'Filipino',
    de: 'Philippinisch',
  },
};

function getLanguagesOptions(apiData = [], language = 'en') {
  return apiData
    .filter((l) => !!languages[l])
    .map((l) => ({ value: l, label: languages[l][language] }));
}

export default () => async (dispatch, getState) => {
  try {
    if (!getState().user?.isLoggedIn) return;
    const response = await api.get('/data/languages');

    if (!getState().user?.isLoggedIn) return;
    dispatch(
      updateLanguages(
        getLanguagesOptions(response.data, getState().intl.locale)
      )
    );
  } catch (err) {
    dispatch(userLogout(false, 1, true));
  }
};
