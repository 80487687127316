import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { redirectedSettings } from '../../../actions';
import Redirect from './Redirect';

export default connect(
  (state) => ({
    redirectSettings: state.sender.askInitCamAlertConfirmed,
  }),
  (dispatch) => ({
    redirectDone: () => dispatch(redirectedSettings()),
  })
)(withRouter(Redirect));
