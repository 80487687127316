import { connect } from 'react-redux';

import { spinner } from '../../../../actions';
import BillDetails from './BillDetails';

const mapStateToProps = (state) => {
  return {
    language: state.user.account?.language,
  };
};

const mapDispatchToProps = {
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillDetails);
