import { FormattedMessage } from 'react-intl';

import BannerDE from '../../../../../assets/img/wiki/wiki_user_banner_de.jpg';
import BannerEN from '../../../../../assets/img/wiki/wiki_user_banner_en.jpg';
import BackButton from '../../../../BackButton';
import CopyInput from '../../../../Forms/CopyInput/CopyInput';

function UserAdvertisingContainer({
  locale,
  advId,
  nickname,
  onBackButtonClicked,
}) {
  return (
    <div className="wiki-content user-advertising-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="USER_ADVERTISING_TITLE" />
      </h1>
      <p>
        <FormattedMessage id="USER_ADVERTISING_CONTENT_1" />
      </p>

      {locale === 'en' ? (
        <img className="img-responsive" src={BannerEN} alt="User Advertising" />
      ) : (
        <img className="img-responsive" src={BannerDE} alt="User werben" />
      )}
      <CopyInput
        label="USER_ADVERTISING_LINK_AMX"
        value={`${process.env.REACT_APP_AMX_URL}/?ACADID=${advId}#/sedcard/${nickname}`}
      />
      <CopyInput
        label="USER_ADVERTISING_LINK_FUN"
        value={`${process.env.REACT_APP_FUN_URL}/?ACADID=${advId}#/sedcard/${nickname}`}
      />
      <CopyInput
        label="USER_ADVERTISING_LINK_AMF"
        value={`${process.env.REACT_APP_AMF_URL}/?ACADID=${advId}#/sedcard/${nickname}`}
      />
      <p>
        <FormattedMessage id="USER_ADVERTISING_CONTENT_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
    </div>
  );
}
export default UserAdvertisingContainer;

