import { connect } from 'react-redux';

import {
  cancelMotto,
  closeOverlay,
  management,
  setMotto,
} from '../../../actions';
import Motto from './Motto';

const mapDispatchToProps = (dispatch) => {
  return {
    closeOverlay: (stamp) => dispatch(closeOverlay(stamp)),
    setMotto: (motto) => dispatch(setMotto(motto)),
    cancelMotto: () => dispatch(cancelMotto()),
    startStream: () => dispatch(management.connect.start()),
  };
};

export default connect(null, mapDispatchToProps)(Motto);
