import {
  upgradetimer as upgradetimerUtils,
  user as userUtils,
} from '../../../utils';
import { EXCLUSIVE_ASK_SENDER } from '../../actions';

export default () => (dispatch, getState) => {
  const {
    upgradetimer,
    camUser: { camUsers },
    dialog: { dialogs },
  } = getState();
  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
  const timer = upgradetimerUtils.findExclusiveTimer(activeUser, upgradetimer);
  if (timer && activeUser) {
    dispatch({
      type: EXCLUSIVE_ASK_SENDER,
      payload: activeUser,
      meta: {
        stamp: timer.senderRequestStamp,
      },
    });
  }
};
