import { uuid } from '../../utils';
import api from '../../utils/api';
import { PREFERENCES_ADD } from '../actions';
export default () => async (dispatch, getState) => {
  const { preferences } = getState();

  const data = {
    ...preferences,
    safeMode: !preferences?.safeMode,
  };

  try {
    await api.put('/user/preferences', { data });

    dispatch({
      type: PREFERENCES_ADD,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PREFERENCES_ADD,
      payload: getState().preferences,
      error: true,
      meta: { stamp: uuid() },
    });

    console.log({ error });
  }
};

