import useAxios from 'axios-hooks';
import React from 'react';

import FileItem from '../../../Uploader/FileItem/FileItem';

function MyAccountFileApi({
  file,
  updateDocuments = () => {},
  user,
  spinner = () => {},
  isDeleteBtnShown = true,
}) {
  const [
    { error: fileDeletionError, loading: isFileDeleting },
    deleteDocumentRequest,
  ] = useAxios(
    {
      url: `/user/documents/${file.id}`,
      method: 'DELETE',
    },
    { manual: true }
  );

  function deleteDocument() {
    if (!isFileDeleting) {
      spinner(true);
      deleteDocumentRequest()
        .then((response) => {
          if (response.status === 204) {
            let documents = [...user.private.documents];
            const index = documents.findIndex(
              (document) => document.id === file.id
            );
            documents.splice(index, 1);
            updateDocuments([...documents]);
            spinner(false);
          }
        })
        .catch((error) => {
          spinner(false);
        });
    }
  }
  return (
    <FileItem
      name={file.description}
      status={file.state}
      isDeleteBtnShown={isDeleteBtnShown}
      error={
        file.comment !== ''
          ? file.comment
          : fileDeletionError
          ? 'Deleting failed. Make sure you are connected to the internet.'
          : null
      }
      onDeleteBtnClick={() => deleteDocument()}
    />
  );
}

export default MyAccountFileApi;

