import '../Uploader.scss';

import classNames from 'classnames';
import React, { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useIntl } from 'react-intl';

import DiaryPhotoUploader from '../DiaryPhotoUploader/DiaryPhotoUploader';
import { checkImage, getImageSource } from '../Uploader.functions';

function DiaryUploader({
  label,
  markAsRequired,
  acceptedFileTypes,
  openCam,
  handleError,
  dropzoneProps,
  isMobile,
  imageError,
  minDimensions,
  className,
  additionalAcceptedFileTypes,
  activeGallery,
  deleteDiaryImage,
  setISource,
  setFilename,
  outsideISource,
}) {
  const intl = useIntl();
  const translatedLabel = intl.formatMessage({ id: label });
  const [diaryImageSource, setDiaryImageSource] = useState();

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        handleError(`Error: ${rejectedFiles[0].errors[0].message}`);
      }

      try {
        const iSource = await checkImage(
          await getImageSource(acceptedFiles[0], intl),
          intl,
          minDimensions
        );
        setFilename(acceptedFiles[0]?.name);
        setISource(iSource);
        return setDiaryImageSource(iSource);
      } catch (error) {
        return handleError(error.message);
      }
    },
    [handleError, minDimensions, intl, setISource, setFilename]
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    ...dropzoneProps,
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: acceptedFileTypes.concat(additionalAcceptedFileTypes).join(', '),
  });

  const wrapperClassName = classNames('uploader', className, {
    'drag-active': isDragActive,
    'uploader-has-error': imageError && imageError !== '',
  });

  const labelClassName = classNames('label', {
    'field-label-required-mark-container': markAsRequired,
  });

  useEffect(() => {
    if (outsideISource) setDiaryImageSource(outsideISource);
    if (outsideISource === -1) setDiaryImageSource(null);
  }, [outsideISource, setDiaryImageSource]);

  return (
    <div>
      <DiaryPhotoUploader
        wrapperClassName={wrapperClassName}
        labelClassName={labelClassName}
        markAsRequired={markAsRequired}
        isMobile={isMobile}
        openCam={openCam}
        imageError={imageError}
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        open={open}
        translatedLabel={translatedLabel}
        activeGallery={activeGallery}
        deleteImage={deleteDiaryImage}
        diaryImageSource={diaryImageSource}
        removeDiaryImageSource={() => {
          setISource(null);
          setDiaryImageSource(null);
          setFilename(null);
        }}
      />
    </div>
  );
}

export default DiaryUploader;
