import './ArticlesMobile.scss';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import arrowRigthIcon from '../../../../assets/img/svg/arrow-right.svg';
import NumberItem from '../../../Tables/TableItems/NumberItem';

function ArticlesMobile({ revenueTotal, currency }) {
  const intl = useIntl();
  return (
    <div className="articles-mobile">
      <Link
        className="results-row"
        to={'/my-account/commission/billing-period'}
      >
        <ReactSVG src={arrowRigthIcon} wrapper="span" className="icon" />
        <span>
          {intl.formatMessage({
            id: 'DASHBOARD_BILLING_SEE_DETAILS',
          })}
        </span>
      </Link>{' '}
      <span className="end-result">
        <NumberItem number={revenueTotal} precision={2} currency={currency} />
      </span>
    </div>
  );
}

export default ArticlesMobile;
