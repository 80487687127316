import { WebRTC } from '../../services';
import { USERAUDIO_START } from '../actions';
import useraudio from './';

export default (userId, data) => {
  return (dispatch) => {
    const webRTCConnection = new WebRTC();

    dispatch({
      type: USERAUDIO_START,
      meta: { start: true },
      payload: {
        userId,
        webRTCConnection,
      },
    });

    webRTCConnection.on('start', (streams) => {
      dispatch(useraudio.startEvent(userId, streams[0]));
    });
    webRTCConnection.on('stop', () => {
      dispatch(useraudio.stopEvent(userId));
    });
    webRTCConnection.start(data);
  };
};
