import { audio, sendSystemChatMessage } from '../../../../../actions';
import { createIncomingMessagePayload } from '../../../../../utils/chat';
import { MANAGEMENT_EVENTS_MESSAGE_ADMIN_ADD } from '../../../../actions';
export default (data) => {
  return (dispatch, getState) => {
    if (data && data.type === 'systemUser') {
      return dispatch(
        sendSystemChatMessage({
          id: data.message,
        })
      );
    }
    data.userId = data.adminSocketId; // need to map this
    dispatch({
      type: MANAGEMENT_EVENTS_MESSAGE_ADMIN_ADD,
      payload: createIncomingMessagePayload(data, getState()),
    });
    dispatch(audio.newMessage());
  };
};
