import { connect } from 'react-redux';

import { spinner, uploadFile } from '../../../../actions';
import ProfileVideo from './ProfileVideo';

const mapStateToProps = (state) => ({
  isMobile: state.browser.isMobile,
  uploads: state.upload.uploads,
});

const mapDispatchToProps = {
  spinner,
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileVideo);

