import './TermsAndConditions.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import PageSection from '../../../PageSection/PageSection';
import terms from './TermsAndConditions.text';

function TermsAndConditions({ locale = 'en', isMobile }) {
  const isContentSectionDisplayed = useMediaQuery('(min-width: 1600px)');

  const className = classNames('terms-container', {
    mobile: isMobile,
  });

  return (
    <PageSection
      title={<FormattedMessage id="LEGAL_TERMS" />}
      rightColumn={terms(locale)}
      rightHeader={true}
      className={className}
    >
      <h1 className="headline">
        <FormattedMessage id="LEGAL_TERMS_HEADLINE" />
      </h1>
      <div className="terms-text-container">
        <p>
          <FormattedMessage id="LEGAL_TERMS_INTRO" />
        </p>
        {!isContentSectionDisplayed && terms(locale)}
      </div>
    </PageSection>
  );
}

export default TermsAndConditions;

