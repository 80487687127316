import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { findFirstEnabledItemIndex } from '../Stepper.functions';
import StepperContext from '../StepperContext';

function Content(props) {
  const { defaultPath, items, isFinished, finishedComponent } =
    useContext(StepperContext);

  const routes = items
    // Filtering removes indexes which is an issue for properly switching beteween steps
    // .filter((i) => !i.disabled)
    .map((i, index) => {
      const Component = i.component;
      return (
        <Route
          key={`${defaultPath}${i.path}`}
          path={`${defaultPath}${i.path}`}
          render={() => <Component item={items[index]} {...props} />}
        />
      );
    });

  return (
    <Switch>
      {props.state === 'pending' ? null : routes}

      <Route
        path="/"
        component={() =>
          isFinished ? (
            finishedComponent
          ) : (
            <Redirect
              to={`${defaultPath}${
                items[findFirstEnabledItemIndex(items)].path
              }`}
            />
          )
        }
      />
    </Switch>
  );
}

export default Content;
