import { connect } from 'react-redux';

import MyVideos from './MyVideos';

const mapStateToProps = (state) => ({
  language: state.user.account?.language,
});

export default connect(mapStateToProps)(MyVideos);

