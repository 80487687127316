export default {
  KPI_PROFILE_IMAGE: `Profilbild`,
  KPI_PROFILE_IMAGE_TOOLTIP: `Dieser Wert zeigt, wie ansprechend Kunden Dein aktuelles Profilbild finden. Der Wert ermittelt sich anhand des Votings der Kunden.`,
  KPI_ICEBREAKER: `Icebreaker`,
  KPI_ICEBREAKER_TOOLTIP: `Dieser Wert gibt Auskunft darüber, wie gut Kunden bei einem Kontakt mittels Icebreaker im Chat antworten. 
  Beeinflusst wird dieser Wert unter anderem von Deinem Profilbild und Chatverhalten (Qualität, Antwortzeit, Antwortrate).`,
  KPI_ONLINE_TIME: `Onlinezeit`,
  KPI_ONLINE_TIME_TOOLTIP: `Dieser Wert steht für Deine tägliche Onlinezeit im Portal bzw. Messenger.`,
  KPI_CHAT_QUALITY: `Chatqualität`,
  KPI_CHAT_QUALITY_TOOLTIP: `Dieser Wert gibt an, wie lange Du jeden Kunden im Chat an Dich binden kannst. Einen niedriger Wert ist ein Zeichen für viele kurze Chats.`,
  KPI_RESPONSE_RATE: `Antwortrate`,
  KPI_RESPONSE_RATE_TOOLTIP: `Die Antwort Rate steht dafür, wie zuverlässig Du auf Nachrichten der Kunden antwortest. 
  Antwortest Du nicht auf alle Nachrichten, wirkt sich das negativ aus.`,
  KPI_RESPONSE_TIME: `Antwortzeit`,
  KPI_RESPONSE_TIME_TOOLTIP: `Dieser Wert gibt an, wie lange der Kunde durchschnittlich auf eine Antwort von Dir wartet. `,
  KPI_PREMIUM_ATTACHMENTS: `Premium Dateianhänge`,
  KPI_PREMIUM_ATTACHMENTS_TOOLTIP: `Dieser Wert gibt an, wie viele Deiner versendeten kostenpflichtigen Dateianhänge von Kunden gekauft werden.`,
  KPI_QUOTA: `Quote`,
};
