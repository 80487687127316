export default {
  PROFILE_HEADLINE: `Your profile, your figurehead`,
  PROFILE_INTRO: `You can complete or add to {link} at any time. This information does not necessarily have to correspond to reality, e.g. your zip code. Make the user curious about you and present yourself in such a way that the user really wants to get to know you. Your profile is the first contact the user has with you.`,
  PROFILE_INTRO_LINK: `your profile`,
  PROFILE_HEADLINE_2: `The areas are divided into:`,
  PROFILE_CONTENT_STEP_1: `Profile Pictures`,
  PROFILE_CONTENT_STEP_1_CONTENT: `Your profile pictures are the business card of your profile. Choose beautiful photos, in good quality. {link}`,
  PROFILE_CONTENT_STEP_1_CONTENT_LINK: `You can find detailed information here.`,
  PROFILE_CONTENT_STEP_1_1_CONTENT: `We offer you the opportunity to upload up to 20 profile pictures. After being checked by support, you will find your pictures in the profile picture gallery. There you can choose which photo you would like to use as the main profile picture in our customer portals. The other pictures in your gallery will also be displayed on your profile in the community portals.`,
  PROFILE_CONTENT_STEP_1_2_CONTENT: `<span class="bold">Note:</span> Photos cannot be deleted afterwards. But you can replace it with another photo at any time.`,

  PROFILE_CONTENT_STEP_2: `Profile Video`,
  PROFILE_CONTENT_STEP_2_CONTENT: `With a personal profile video you can present yourself in a short film. Facial expressions, gestures and your voice are ideally suited to arouse the user's interest.`,
  PROFILE_CONTENT_STEP_2_1_CONTENT: `Hints:`,
  PROFILE_CONTENT_STEP_2_2_CONTENT: `
  <ul>
    <li>No hardcore content is allowed</li>
    <li>The video should not be too long, a maximum of 1 minute is sufficient</li>
    <li>Light and sound ok? Put yourself in the spotlight!</li>
  </ul>`,

  PROFILE_CONTENT_STEP_3: `Profile Text`,
  PROFILE_CONTENT_STEP_3_CONTENT: `Your profile text should give the user a first impression of you and encourage them to write to you. In this text, describe who you are, what you are looking for or what you might still want to experience. Describe your interests, hobbies or preferences. The more precisely you describe yourself, the more the user can respond to you. But also be a little non-binding, because this will keep you interesting and a user will have more reasons to ask.`,

  PROFILE_CONTENT_STEP_4: `Public Details`,
  PROFILE_CONTENT_STEP_4_CONTENT: `All information you provide here appears on your public profile and is therefore visible to all users.`,
  PROFILE_CONTENT_STEP_4_1_CONTENT: `You don't have to reveal everything about yourself. However, remember: the more you say, the easier it is for users to find you and the more attractive you can stand out.`,

  PROFILE_CONTENT_STEP_5: `View my public profile`,
  PROFILE_CONTENT_STEP_5_CONTENT: `Your profile will be visible on 20+ community portals at the same time. It makes sense to supplement your profile with additional content and regular updates.`,
  PROFILE_CONTENT_STEP_5_1_CONTENT: `Of course, not all community portals look the same. There are big differences in appearance, both in terms of content and theme as well as color and structure.`,
  PROFILE_CONTENT_STEP_5_2_CONTENT: `You can use our three partner portals to get an exemplary impression of what your profile might look like.`,
};
