export default {
  LIVECAM_FAQ_HEADLINE: `The most frequently asked questions about the Livecam`,
  LIVECAM_FAQ_QUESTION_1: `What do the black borders on my cam image mean?`,
  LIVECAM_FAQ_ANSWER_1: `If black bars are displayed left/right or top/bottom in the preview image, you have to adjust the video resolution, because the bars reduce the maximum possible video display for the user.<br />
  <ul>
    <li>If you have set an aspect ratio of 4:3 and you see black bars at the top and bottom, then choose a similar resolution with an aspect ratio of 16:9.</li>
    <li>If you have set an aspect ratio of 16:9 and black bars can be seen on the left and right, then select a similar resolution with an aspect ratio of 4:3.</li>
  </ul>`,
  LIVECAM_FAQ_ANSWER_1_2: `Please make sure that NO black bars are visible in the preview image and thus also in the video image transmitted to the user.`,

  LIVECAM_FAQ_QUESTION_2: `What do the white-transparent edges on my cam image mean?`,
  LIVECAM_FAQ_ANSWER_2: `If a customer is in your Livecam show, it may be that he does not see the entire cam image. So that you know exactly what he sees, the image section that is not visible to the customer is shown in white and transparent. This image section can (depending on the viewer's monitor) differ from customer to customer, so that it changes when you switch between customers. If the image section changes for the customer, it will also change in your preview after a short time.`,

  LIVECAM_FAQ_QUESTION_3: `What does the yellow / red highlighting of the "frames/s" above my cam image mean?`,
  LIVECAM_FAQ_ANSWER_3: `This mark tells you that you are not transmitting enough frames per second and your stream is therefore of poor quality. The user may only see a faltering, pixelated or even stationary image.`,
  LIVECAM_FAQ_ANSWER_3_1: 'Possible measures to improve the image quality:',
  LIVECAM_FAQ_ANSWER_3_1_1: `Close unused programs that unnecessarily consume your computer's processing power.`,
  LIVECAM_FAQ_ANSWER_3_1_2:
    'Check the stream settings under {link}. Try a lower resolution and thus relieve your computer.',
  LIVECAM_FAQ_ANSWER_3_2: `Contact our support for further measures.`,
  LIVECAM_FAQ_ANSWER_3_3: `<span class="bold">Note:</span> On smaller screen resolutions, the background of the cam icon flashes in the chat view. In addition, you will receive a frames/s warning.`,

  LIVECAM_FAQ_QUESTION_4: `What do the icons mean after starting my stream?`,
  LIVECAM_FAQ_ANSWER_4: `You are streaming in normal mode.`,
  LIVECAM_FAQ_ANSWER_4_1: `Voyeurs can enter your show.`,
  LIVECAM_FAQ_ANSWER_4_2: `You are activated for the Exclusive mode.`,
  LIVECAM_FAQ_ANSWER_4_3: `You are streaming in Exclusive mode. The normal and the voyeur mode are inactive during this time.`,

  LIVECAM_FAQ_QUESTION_5: `What is Exclusive mode?`,
  LIVECAM_FAQ_ANSWER_5: `An Exclusive Show can only be visited by one user at a time, it is EXCLUSIVE for that one user. You set the price for the Exclusive Show in your account: {link}`,

  LIVECAM_FAQ_QUESTION_6: `What is there to consider when switching to Exclusive mode?`,
  LIVECAM_FAQ_ANSWER_6: `If you switch to Exclusive mode with a customer while there are other users in your show, they will be kicked. If a user sends you an Exclusive Show request, you only have a limited amount of time to accept. After the time has expired, the request is considered rejected.`,
  LIVECAM_FAQ_ANSWER_6_1: `If you reject an Exclusive Show request from the user, he can only request it again after a certain time has elapsed.`,

  LIVECAM_FAQ_QUESTION_7: `How can I start the Exclusive mode?`,
  LIVECAM_FAQ_ANSWER_7: `If you have a user in your Livecam, you can invite him to an Exclusive Show. To do this, select the user and then press the button "Invite XXX to an Exclusive Show" in the chat menu.`,
  LIVECAM_FAQ_ANSWER_7_1: `In addition, you can no longer send an Exclusive Show request to a user who has rejected your request.`,

  LIVECAM_FAQ_QUESTION_8: `What is voyeur mode?`,
  LIVECAM_FAQ_ANSWER_8: `In voyeur mode, users can watch your show anonymously, but they cannot chat with you. Above the user list you can see the number of voyeurs who are in your show.`,
  LIVECAM_FAQ_ANSWER_8_1: `No voyeur in your show`,
  LIVECAM_FAQ_ANSWER_8_2: `2 voyeurs in your show`,

  LIVECAM_FAQ_QUESTION_9: `What do the symbols before/after the username mean?`,
  LIVECAM_FAQ_ANSWER_9: `The user is new to your show.`,
  LIVECAM_FAQ_ANSWER_9_1: `The user has written you a new message.`,
  LIVECAM_FAQ_ANSWER_9_2: `An admin is in your show.`,
  LIVECAM_FAQ_ANSWER_9_3: `The user is in your show with a PC.`,
  LIVECAM_FAQ_ANSWER_9_4: `The user is in your show with a mobile device.`,
  LIVECAM_FAQ_ANSWER_9_5: `This user can not hear you.`,

  LIVECAM_FAQ_QUESTION_10: `What should I know about mobile users?`,
  LIVECAM_FAQ_ANSWER_10: `These users may leave your Livecam Show unexpectedly if their mobile connection is not stable.`,

  LIVECAM_FAQ_QUESTION_11: `Why can't I see a microphone icon (on/off) over my cam image?`,
  LIVECAM_FAQ_ANSWER_11: `You have not yet selected a microphone in the {link}.`,
  LINK_TEXT_STREAM_SETTINGS: `Stream settings`,

  LIVECAM_FAQ_QUESTION_12: `How can I kick a user?`,
  LIVECAM_FAQ_ANSWER_12: `By clicking on the button in the User Info menu.`,
  LIVECAM_FAQ_ANSWER_12_1: `<span class="bold">Note!</span> You may only kick users who grossly violate our product guidelines or who engage in illegal activities! If you kick users without a sufficient reason, this can lead to the blocking of your Livecam account!`,

  LIVECAM_FAQ_QUESTION_13: `What is the use of the list containing F1, F2, etc?`,
  LIVECAM_FAQ_ANSWER_13: `In this list you can save short texts, which will be sent to the user by pressing the corresponding F-Key. In that way you are able to greet users only by pressing one key. You can also use placeholders to insert the username or the local time.`,
  LIVECAM_FAQ_ANSWER_13_1: `
    <table class="noBorder noHover">
      <thead>
        <tr>
          <td style='width: 100px;' class='bold'>Placeholder</td>
          <td class='bold'>will be replaced by</td>
        </tr>
      </thead>
      <tr>
        <td>%t%</td>
        <td>Current time</td>
      </tr>
       <tr>
        <td>%u%</td>
        <td>Username</td>
       </tr>
     </table>`,

  LIVECAM_FAQ_QUESTION_14: `Why can't I see the user in front of his webcam?`,
  LIVECAM_FAQ_ANSWER_14: `If a user wants to transmit his webcam image, he must allow access to his cam in the Livecam Show settings.`,

  LIVECAM_FAQ_QUESTION_15: `How can I change the volume of the notification sounds?`,
  LIVECAM_FAQ_ANSWER_15: `In the Sound settings under {link} you can set the volume of the tones in several steps between 50% (half volume) and 300% (triple volume). The selected volume is the same for all notification tones.`,
};

