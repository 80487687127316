import api from '../../utils/api';
import { errorLog } from '../';
import { DIALOG_READ } from '../actions';
export default function (dialogId) {
  return async (dispatch, getState) => {
    if (!getState().user?.isLoggedIn) {
      return;
    }
    try {
      await api.put(`/message/${dialogId}/read`);

      dispatch({
        type: DIALOG_READ,
        payload: { dialogId },
      });
    } catch (error) {
      const errorObject = {
        location: 'messageReceived',
        error,
      };

      dispatch(errorLog(errorObject));
      console.log({ markreaderror: error });
    }
  };
}

