import ratatoskrService from '../../services/ratatoskr';
import { default as close } from './close';
import { default as event } from './event';
import { default as open } from './open';

export default {
  event: event(ratatoskrService),
  open: open(ratatoskrService),
  close: close(ratatoskrService),
};
