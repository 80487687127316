/* eslint eqeqeq: "off" */
import { EventEmitter } from 'events';

import management from './management';
const maxRetries = 30;
const retryInterval = 1000;
const spdParser = (sdp) => {
  const sdpLines = sdp.split('\r\n');
  let h264Line = sdpLines.find((line) =>
    /a=rtpmap:[0-9]{1,3} H264\/[0-9]{1,5}/.test(line)
  );
  const hasH264 = !!h264Line;
  if (!h264Line) {
    h264Line = '';
  }
  const h264Number = h264Line.split(' ').shift().split(':').pop(); // e.g. h264Line="a=rtpmap:101 H264/9000"
  const profileLevelLineRegEx = new RegExp('a=fmtp:' + h264Number + ' .*');
  const profileLevelLine = sdpLines.find((line) =>
    profileLevelLineRegEx.test(line)
  );

  let profileLevel = '';
  if (profileLevelLine) {
    profileLevel = profileLevelLine
      .match(/profile-level-id=([0-9a-fA-F]{6})/)
      .pop();
  }

  return {
    hasH264,
    isBaseline: /^42[a-fA-F0-9]{4}$/.test(profileLevel),
  };
};
class WebRTC extends EventEmitter {
  constructor() {
    super();

    this.errorHandler = this.errorHandler.bind(this);
    this.gotDescription = this.gotDescription.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.reset = this.reset.bind(this);
    this.sendGetOffer = this.sendGetOffer.bind(this);
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.wsConnect = this.wsConnect.bind(this);
    this.userId = '';
    this.reset();
  }

  reset() {
    this.peerConnection = null;
    this.wsConnection = null;

    this.wsURL = '';
    this.streamInfo = { applicationName: '', streamName: '', sessionId: '' };
    this.userData = {};

    this.repeaterRetryCount = 0;
    this.stopped = false;
  }

  onOpen() {
    console.log('WebRTCreceive: wsConnection.onopen');

    this.peerConnection = new RTCPeerConnection({ iceServers: [] });

    this.peerConnection.onicecandidate = (event) => {
      if (event.candidate != null) {
        console.log(
          `WebRTCreceive: gotIceCandidate: ${JSON.stringify(event.candidate)}`
        );
      }
    };
    this.peerConnection.ontrack = (event) => {
      console.log(
        `WebRTCreceive: gotRemoteTrack: kind: ${event.track.kind} stream: ${event.streams[0]}`
      );
      this.emit('start', event.streams);
    };

    console.log(`WebRTCreceive: wsURL: ${this.wsURL}`);

    this.sendGetOffer();
  }

  sendGetOffer() {
    console.log(
      `WebRTCreceive: sendGetOffer: ${JSON.stringify(this.streamInfo)}`
    );
    this.wsConnection.send(
      `{"direction":"play", "command":"getOffer", "streamInfo":${JSON.stringify(
        this.streamInfo
      )}, "userData":${JSON.stringify(this.userData)}}`
    );
  }

  onMessage(evt) {
    console.log(`WebRTCreceive: wsConnection.onmessage: ${evt.data}`);

    const msgJSON = JSON.parse(evt.data);

    const msgStatus = Number(msgJSON.status);
    const msgCommand = msgJSON.command;

    if (msgStatus == 514 || msgStatus == 504 || msgStatus == 502) {
      // repeater stream not ready
      this.repeaterRetryCount++;
      if (this.repeaterRetryCount < maxRetries && !this.stopped) {
        setTimeout(this.sendGetOffer, retryInterval);
      } else {
        console.log('WebRTCreceive: Live stream repeater timeout');
        this.stop();
      }
    } else if (msgStatus != 200) {
      console.log(
        `WebRTCreceive: Status not 200: ${msgJSON.statusDescription}`
      );
      this.stop();
    } else {
      console.log('WebRTCreceive: msgStatus is 200');

      const streamInfoResponse = msgJSON.streamInfo;
      if (streamInfoResponse !== undefined) {
        this.streamInfo.sessionId = streamInfoResponse.sessionId;
      }

      const sdpData = msgJSON.sdp;
      if (sdpData !== undefined) {
        const parsed = spdParser(sdpData.sdp);
        if (parsed.hasH264 && !parsed.isBaseline) {
          const profileLevel = '42e01F';
          sdpData.sdp = sdpData.sdp.replace(
            /profile-level-id=[0-9a-fA-F]{6}/i,
            `profile-level-id=${profileLevel}`
          );
          management.log.warn(
            `cam2cam-baseline-downgrade userId: ${this.userId}`
          );
        }
        console.log(`WebRTCreceive: sdp: ${JSON.stringify(sdpData)}`);
        this.peerConnection.setRemoteDescription(
          new RTCSessionDescription(sdpData),
          () => {
            this.peerConnection.createAnswer(
              this.gotDescription,
              this.errorHandler
            );
          },
          this.errorHandler
        );
      }

      const iceCandidates = msgJSON.iceCandidates;
      if (iceCandidates !== undefined) {
        for (let index in iceCandidates) {
          console.log(
            `WebRTCreceive: iceCandidates: ${JSON.stringify(
              iceCandidates[index]
            )}`
          );
          this.peerConnection.addIceCandidate(
            new RTCIceCandidate(iceCandidates[index])
          );
        }
      }
    }

    if ('sendResponse'.localeCompare(msgCommand) == 0) {
      if (this.wsConnection != null) {
        this.wsConnection.close();
      }
      this.wsConnection = null;
    }
  }

  wsConnect() {
    this.wsConnection = new WebSocket(this.wsURL);

    this.wsConnection.binaryType = 'arraybuffer';

    this.wsConnection.onopen = this.onOpen;
    this.wsConnection.onmessage = this.onMessage;
    this.wsConnection.onclose = () => {
      console.log('WebRTCreceive: wsConnection.onclose');
    };
    this.wsConnection.onerror = (evt) => {
      console.log(
        `WebRTCreceive: wsConnection.onerror: ${JSON.stringify(evt)}`
      );
    };
  }

  start({ sdpUrl, applicationName, streamName, userId }) {
    this.repeaterRetryCount = 0;
    this.stopped = false;
    this.userId = userId;
    this.wsURL = sdpUrl;
    this.streamInfo.applicationName = applicationName;
    this.streamInfo.streamName = streamName;

    console.log(
      `WebRTCreceive: start: wsURL:${this.wsURL} streamInfo:${JSON.stringify(
        this.streamInfo
      )}`
    );

    this.wsConnect();
  }

  stop() {
    this.stopped = true;

    if (this.peerConnection != null) {
      this.peerConnection.close();
    }

    if (this.wsConnection != null) {
      this.wsConnection.close();
    }

    this.reset();
    this.emit('stop');
    console.log('WebRTCreceive: stop');
  }

  gotDescription(description) {
    console.log(
      `WebRTCreceive: gotDescription: ${JSON.stringify(description)}`
    );
    console.log(
      `WebRTCreceive: sendAnswer1, streamInfo: ${JSON.stringify(
        this.streamInfo
      )}`
    );
    this.peerConnection.setLocalDescription(
      description,
      () => {
        console.log(
          `WebRTCreceive: sendAnswer, streamInfo: ${JSON.stringify(
            this.streamInfo
          )}`
        );
        this.wsConnection.send(
          `{"direction":"play", "command":"sendResponse", "streamInfo":${JSON.stringify(
            this.streamInfo
          )}, "sdp":${JSON.stringify(description)}, "userData":${JSON.stringify(
            this.userData
          )}}`
        );
      },
      () => {
        console.log('WebRTCreceive: set description error');
      }
    );
  }

  errorHandler(error) {
    management.log.warn(`cam2cam-error userId: ${this.userId}`);
    console.log({ WebRTCreceive: 1, error });
  }
}
export default WebRTC;
