import classNames from 'classnames';
import { Field } from 'formik';
import React from 'react';

import InputWithLabel from '../InputWithLabel/InputWithLabel';

function StandaloneFieldWithErrors({
  id,
  name,
  as = InputWithLabel,
  children,
  disabled,
  error,
  ...otherProps
}) {
  const className = classNames('field-error-message-wrapper', {
    [`${id || name}-wrapper`]: id || name,
    'has-error': error,
    disabled: disabled,
  });

  return (
    <InputWithLabel id={id} name={name} {...otherProps}>
      <div className={className}>
        {children ? (
          children
        ) : (
          <Field as={as} id={id} name={name} {...otherProps} />
        )}

        {error ? <div className="error-message">{error}</div> : null}
      </div>
    </InputWithLabel>
  );
}

export default StandaloneFieldWithErrors;

