import './AcceptGalleryRow.scss';

import React from 'react';

import Badge from '../../Badge/Badge';
import Preview from '../../Preview/Preview';
import Stats from '../../Stats/Stats';

function AcceptGalleryRow({ item }) {
  const state = item?.state;

  return (
    <div className="fmotd-gallery-row accepted">
      <Preview item={item} />
      <div className="content">
        <div className="title">{item?.title?.content}</div>
        <div className="badges">
          <Badge item={item} />
          {state === 'current' || state === 'archive' ? (
            <Stats item={item} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AcceptGalleryRow;
