import { user as userUtils } from '../../utils';
import api from '../../utils/api';
import { DIALOG_PERSIST } from '../actions';
export default (user) => async (dispatch) => {
  const hasPartner = !!user?.partner || userUtils.hasPartner(user);

  if (
    !user ||
    !user?.flags ||
    !hasPartner ||
    !user?.flags?.includes('gameInvitation') ||
    user?.persisted
  ) {
    return;
  }

  const payload = !user.partner ? userUtils.getPartner(user) : user.partner;
  try {
    await api.put(`/message/${payload}/persist`);
    dispatch({ type: DIALOG_PERSIST, payload });
  } catch (error) {
    console.log(error);
  }
};
