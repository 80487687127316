import './Xmas.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import XmasLogoDE from '../../../../assets/img/wiki/xmasCalendar/xmas-logo-2024-de.png';
import XmasLogoEN from '../../../../assets/img/wiki/xmasCalendar/xmas-logo-2024-en.png';
import BannerImageDE from '../../../../assets/img/wiki/xmasCalendar/xmas2024-de.jpg';
import BannerImageEN from '../../../../assets/img/wiki/xmasCalendar/xmas2024-en.jpg';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import PageSection from '../../../PageSection/PageSection';

function RightColumn({ locale }) {
  return (
    <div className="right-column">
      {locale === 'en' ? (
        <img
          className="img-responsive"
          src={BannerImageEN}
          alt="Xmas Calendar"
        />
      ) : (
        <img
          className="img-responsive"
          src={BannerImageDE}
          alt="Xmas Kalender"
        />
      )}

      <p>
        <FormattedMessage id="XMAS_WIKI_INFO_TEXT_1" />
      </p>
      <p>
        <FormattedMessage id="XMAS_WIKI_INFO_TEXT_1_1">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <h2 className="headline">
        <FormattedMessage id="XMAS_WIKI_INFO_HEADLINE_1" />
      </h2>
      <p>
        <FormattedMessage id="XMAS_WIKI_INFO_TEXT_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <h3 className="headline red">
        <FormattedMessage id="XMAS_WIKI_INFO_HEADLINE_2" />
      </h3>
      <p>
        <FormattedMessage id="XMAS_WIKI_INFO_TEXT_3">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="XMAS_WIKI_INFO_TEXT_4">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="XMAS_WIKI_INFO_TEXT_5">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p className="bold">
        <FormattedMessage id="XMAS_WIKI_INFO_TEXT_6" />
      </p>
    </div>
  );
}

function Xmas({ isMobile, locale }) {
  const isContentSectionDisplayed = useMediaQuery('(min-width: 1600px)');
  const className = classNames('wiki-container', 'xmas-container', {
    mobile: isMobile,
    [`locale-${locale}`]: !!locale,
  });

  return (
    <PageSection
      rightColumn={<RightColumn locale={locale} />}
      className={className}
      containsHeader={true}
    >
      <h1 className="headline">
        <FormattedMessage id="XMAS_WIKI_HEADLINE" />
      </h1>
      <div className="text-container">
        {locale === 'en' ? (
          <img className="xmas-logo" src={XmasLogoEN} alt="Xmas" />
        ) : (
          <img className="xmas-logo" src={XmasLogoDE} alt="Xmas" />
        )}
        <div className="intro-text">
          <p>
            <FormattedMessage id="XMAS_WIKI_INTRO_1" />
          </p>
          <p className="bold">
            <FormattedMessage id="XMAS_WIKI_INTRO_2" />
          </p>
        </div>
      </div>
      {!isContentSectionDisplayed && <RightColumn locale={locale} />}
    </PageSection>
  );
}

export default Xmas;
