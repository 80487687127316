import classNames from 'classnames';
import { Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';

import useMediaQuery from '../../../hooks/useMediaQuery';
import Chat from '../../Livecam/Chat';
import UserGame from '../../Livecam/UserGame';
import Streaming from './Streaming';
import Userlist from './UserList';
import UserTab from './UserTab';

function Livecam({ isMobile, cam }) {
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const isMediumScreen = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1599px)'
  );
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');

  const mediumStreamingTab = useRef(null);
  const [mediumStreamingTabWidth, setMediumStreamingTabWidth] = useState(150);

  useEffect(() => {
    // Resize observer for the width of the tab so that the react-resizable can be adjusted
    if (!mediumStreamingTab || !mediumStreamingTab.current) {
      return;
    }
    let resizeObserver = new ResizeObserver((entries) => {
      const width = entries[0].target.clientWidth;
      setMediumStreamingTabWidth(width);
    });
    resizeObserver.observe(mediumStreamingTab.current);

    return () => {
      resizeObserver.disconnect();
      resizeObserver = null;
    };
  }, [isMediumScreen]);

  const largeScreenStreamingTabClass = classNames(
    'large-screen-streaming-tab',
    {
      'no-cam': !cam,
    }
  );

  return (
    <Formik initialValues={{ message: '' }}>
      <div className="wrapper">
        <Userlist />
        {isMobileScreen && (
          <>
            <Chat />
            <div className="mobile-screen-streaming-tab">
              {!isMobile && (
                <Streaming mediumStreamingTabWidth={mediumStreamingTabWidth} />
              )}
              <UserTab />
            </div>
          </>
        )}
        {isMediumScreen && (
          <>
            <div
              className="medium-screen-streaming-tab"
              ref={mediumStreamingTab}
            >
              <UserGame mediumStreamingTabWidth={mediumStreamingTabWidth} />
              <Streaming mediumStreamingTabWidth={mediumStreamingTabWidth} />
              <Chat />
            </div>
            <UserTab />
          </>
        )}
        {isLargeScreen && (
          <>
            <Chat />
            <div className={largeScreenStreamingTabClass}>
              {<Streaming mediumStreamingTabWidth={mediumStreamingTabWidth} />}
              <UserTab />
            </div>
          </>
        )}
      </div>
    </Formik>
  );
}

export default Livecam;
