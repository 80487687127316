import { connect } from 'react-redux';

import {
  deleteDiaryEntry,
  spinner,
  updateActiveGallery,
} from '../../../../actions';
import DiaryItem from './DiaryItem';

const mapDispatchToProps = {
  updateActiveGallery,
  spinner,
  deleteDiaryEntry,
};

export default connect(null, mapDispatchToProps)(DiaryItem);
