export default {
  'alert.yes': 'Yes',
  'alert.no': 'No',
  'alert.confirm': 'Confirm',
  'alert.unIgnore': 'Unignore',
  'alert.delete': 'Delete',
  'alert.deny': 'Deny',
  'alert.decideLater': 'Decide later',

  'browser.notAllowed': `Your browser is not supported for using our livecam software, please use one of the following:

     {browsers}`,
  'alert.areYouSure': 'Are you sure?',
  'alert.becomeFriends': 'Become friends',
  'alert.testCam': 'Testing cam {suffix}',
  'alert.switching':
    'Attention! An error occurred while switching. You will be logged out.',
  'alert.camAccess': 'Access denied by cam ({error})',
  'alert.camLoginError6': 'New software version is available. Please reload.',
  'alert.camLoginError11': 'Your login data is not valid.',
  'alert.camLoginError20': 'You cannot login. Please call our support.',
  'alert.camLoginError21':
    'You cannot login at the moment. Please try again later',
  'alert.camLoginError22':
    'You are already logged in with this nickname. Please logoff first and than try again',
  'alert.camLoginError26':
    'Too many logins within 60 seconds. Please try again later.',
  'alert.camLoginError33':
    'You already run an instance of the software on your pc. Close the other first, before open a new one!!!',
  'alert.camLoginError38':
    'Your Lovense app is offline. We deleted your Lovense connection, please go to the settings, reactivate "Lovense toy" and reconnect your device in the Lovense toy tab. Check also, that there is no energy saving mode or screensaver active. Please stop the stream and reconnect your Lovense Connect App to use your toy again.',
  'alert.kickUser': 'Are you sure you want to kick the user out of the show?',
  'alert.ignoreUser': 'Are you sure you want to ignore the user?',
  'alert.unignoreUser':
    'You cannot write messages to the user, because you are ignoring him. Do you want to unignore the user?',
  'alert.exclusiveChangeNotPossible':
    'It is not possible to change into Exclusive mode',
  'alert.noWebcamFound': 'No webcam found. Do you want to select a webcam now?',
  'alert.noResolutionFound':
    'The cam resolution is not available anymore. Do you want to adapt your cam options?',
  'alert.kickUserWarning':
    'When you switch to Exclusive Mode, all other users will be kicked',
  'alert.exclusive': 'Exclusive show request',
  'alert.exclusiveTooltip':
    'An Exclusive Show is limited to one user at a time. It is only possible to have an Exclusive Show at one portal. The price per minute has to be set for each portal individually (Profile -> Livecams). It will then be automatically applied.',
  'alert.askExclusive': '{username} has invited you to an Exclusive show.',
  'alert.exclusivePrice': '{coins} coins per minute',
  'alert.notEnoughCoins':
    '{username} has not enough coins to enter an Exclusive show',
  'alert.exclusiveAccept': 'Accept {tick}',
  'alert.exclusiveDeny': 'Decline',
  'alert.sender.exclusive': 'Exclusive show request',
  'alert.sender.ok': 'OK',
  'alert.sender.cancel': 'Cancel',
  'alert.sender.askExclusive': 'Invite {username} to an Exclusive show',
  'alert.bandwidthCheckError':
    'Bandwidth check not completed. Please start again to get the best cam quality.',
  'alert.notEnoughBandwidth': 'Not enough bandwidth available.',
  'alert.infoSound': 'Press OK to play sound',
  'alert.camAbort': 'Unable to access cam. Error number {number}',
  'alert.camAbortNoResolutions':
    'We could not access your webcam. Please check if any other software or site is accessing your webcam or if the webcam is blocked in your browser. Please contact our support team for any further question.',
  'alert.changeBandwidthError':
    'The bandwidth could not be adjusted, please contact support',
  'alert.streamingError': 'Lost connection to streaming server ({message})',
  'alert.chatError': 'Lost connection to chat server ({message})',
  'alert.streamLostInternet':
    'Your stream got terminated. Please check your internet connection and restart your stream.',
  'alert.noh264Error':
    'The H.264 video codec is either not actived or not installed. Please activate or install. If this is not possible, contact the support.',
  'alert.login.1':
    'CamLogin failed, Errorcode 1. Please try again or contact support',
  'alert.login.2':
    'CamLogin failed, Errorcode 2. Please try again or contact support',
  'alert.login.3':
    'You were logged out because your session was invalid. This may be due to the software being used in multiple tabs. Please open the software in only one tab and try again or contact support.',
  'alert.lowFps':
    'We logged you out because your stream has not got any frames per second. Please make sure to use Google Chrome for our Livecam Tool! If possible, do not use Chrome for streaming to other sites at the same time. Please contact our support, if the problem continues!',
  'alert.sessionTimeout': 'Your session has expired, please login in again.',
  'alert.failedSenderLogin': 'No login possible. Try again or contact support.',
  'alert.failedCamLogin':
    'No cam login possible. Try again or contact support.',
  'alert.messageLimit':
    'You can only send a maximum of 5 messages without a reply from the user',
  'alert.dayLimit': 'This user is inactive and cannot be contacted proactively',
  'alert.attachementError': 'The attachement could not be loaded',
  'alert.dialogFetchError':
    'The dialog could not be fetched. Please try again.',
  'alert.favoriteError':
    'There has been an error while updating the favorite status of the user. Please try again.',
  'alert.friendRequestError':
    'There was an error with sending the friend request. Please try again.',
  'alert.sendFriendRequest':
    '{name} gets a request immediately. We will inform you as soon as your request has been confirmed.',
  'alert.answerFriendRequest': '{name} wants to be your friend!',
  'alert.deleteDiaryEntry': 'Do you really want to delete the diary entry?',
  'alert.deleteFriend': 'Do you want to end the friendship with {name}?',
  'alert.doNotShowIn': `Are you sure you don't want to be shown in your home country? You could lose a lot of revenue as a result.`,
  'alert.favoriteAddSuccess': 'Favorite was succesfully added',
  'alert.favoriteRemoveSuccess': 'Favorite was succesfully removed',
  'alert.lowFpsStream': 'Your frames per second are too low.',
  'alert.lowFpsStreamHeadline': 'Frames per second warning',
  'alert.friendRequestSentSuccess': 'Friend request was successfully sent',
  'alert.isFriend': '{name} is your friend now',
  'alert.attachmentInUse':
    'The attachment can not be deleted, since it is used in an icebreaker.',
  'alert.friendRequestAlreadySent':
    'A request has already been sent to this user',
  'alert.button.sendFriendRequest': 'Send friend request',
  'alert.button.cancel': 'Cancel',
  'alert.friendRequestDenied': '{name} has denied your request.',
  'alert.button.deleteRefusal': 'Delete refusal',
  'alert.deleteDeniedRequest':
    'You have refused the request from {name} previously.',
  'alert.payttachmentAlreadyReceived':
    'The user has already received this payttachment',
  'alert.payttachmentsDisabled':
    'Premium attachment are deactivated for your account',
  'alert.payttachmentsDisabledForUser':
    'Premium attachments are deactivated for this user',
  'alert.payttachmentNotFound':
    'The premium attachment was not found on the server',
  'alert.noDialog': 'This user cannot be contacted proactively',
  'alert.contactTooOld': 'You can not contact this user',
  'alert.noExistingConversation': 'The dialogue does not exist',
  'alert.noExistingConversationGameTimeout': `The dialogue does not exist anymore, after you didn't accept the game invitation in time`,
  'alert.invalidMessage':
    'The message you are trying to send is in invalid format',
  'alert.ignoring': 'You are ignoring this user',
  'alert.allMessages': 'Messages could not be loaded',
};
