export const billArticlesTitles = [
  { title: 'BILL_ARTICLES_ARTICLE' },
  { title: 'BILL_ARTICLES_TIME' },
  { title: 'BILL_ARTICLES_CUSTOMER' },
  { title: 'BILL_ARTICLES_PORTAL' },
  { title: 'BILL_ARTICLES_COIN_PURCHASES', types: ['advrev'], number: true },
  { title: 'BILL_ARTICLES_GALLERY', types: ['pictures', 'movies'] },
  {
    title: 'BILL_ARTICLES_DURATION',
    types: ['livecams', 'exclusivecams', 'voyeurcams'],
    number: true,
  },
  {
    title: 'BILL_ARTICLES_TURNOVER',
    number: true,
    nonTypes: ['advrev', 'advppl'],
  },
  {
    title: 'BILL_ARTICLES_COMMISSION',
    types: ['advrev', 'advppl'],
    number: true,
  },
];
export const supersenderbonusTitles = [
  { title: 'BILL_ARTICLES_CREATOR' },
  { title: 'BILL_ARTICLES_NUMBER', number: true },
  { title: 'BILL_ARTICLES_TURNOVER', number: true },
  { title: 'BILL_ARTICLES_SHARE', number: true },
  { title: 'BILL_ARTICLES_COMMISSION', number: true },
];

