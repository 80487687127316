import { MESSAGE_TYPE_USER, SHOW_TYPE_VOYEUR } from '../../../constants';
import { BEEP } from '../../../constants/sound';
import { messageUtils, user as userUtils } from '../../../utils';
import api from '../../../utils/api';
import { audio, camUser as userActions } from '../../';
import { dialogRead, newDialog } from '../../';
import { MESSAGE_RECEIVED } from '../../actions';

export default (payload) => async (dispatch, getState) => {
  const {
    user: {
      account: { messagingId, nickname: sendername },
    },
    chat: { selectedUser },
    camUser: { camUsers },
    dialog: { dialogs },
    user: {
      account: { scopes },
    },
  } = getState();
  if (getState().ignored.ignoredIds.includes(payload.sender)) {
    return;
  }

  const allDialogs = [...dialogs];
  const hasMessageScope = scopes.includes('send_message');
  const isCamMessengerRoute = window?.location?.pathname === '/multi-messenger';
  const activeUser = userUtils.activeUser([...camUsers, ...allDialogs]);
  const dialogIds = allDialogs.map((d) => d.partner);
  const isNewDialog =
    messagingId === payload.recipient && dialogIds.indexOf(payload.sender) < 0;
  if (isNewDialog) {
    // the sender of this dialog is not in our list
    let isSupport = false;
    let name;
    try {
      const { data } = await api.get(`/user/dialog/${payload.sender}/info`);
      isSupport = data?.flags?.includes('is_support');
      name = data.name;
    } catch (err) {
      console.log(err);
    }
    await dispatch(
      newDialog({
        id: payload.sender,
        time: payload.time,
        hasMessageReceived: true,
        done: payload.done,
        isSupport,
        name,
      })
    );
  }
  const partner =
    payload.sender === messagingId ? payload.recipient : payload.sender;
  const activeCamUser =
    camUsers.find(
      (u) =>
        userUtils.getPartner(u) === partner && u.showType !== SHOW_TYPE_VOYEUR
    ) || {};

  if (activeCamUser?.type) {
    activeCamUser.partner = userUtils.hasPartner(activeCamUser)
      ? userUtils.getPartner(activeCamUser)
      : null;
  }
  const attachmentUser = userUtils.get([...camUsers, ...allDialogs], partner);

  const isSelected =
    partner === selectedUser || partner === activeCamUser.partner;

  const msg = messageUtils.create(
    payload,
    partner,
    messagingId,
    activeCamUser,
    attachmentUser,
    sendername
  );
  msg.done = !!msg?.done;

  const isLivecamMessage = !!msg?.msg?.lc_id;
  if (
    msg?.done &&
    (isSelected || isLivecamMessage) &&
    hasMessageScope &&
    (isCamMessengerRoute || isLivecamMessage)
  ) {
    // confirm dialog read, this happens only once per dialog,
    // but it also happens if dialog is already read
    dispatch(dialogRead(activeCamUser?.partner || selectedUser));
  }
  dispatch({
    type: MESSAGE_RECEIVED,
    payload: msg,
    meta: {
      isSelected,
      selectedUser,
      activeCamUser,
      isCamMessengerRoute,
      hasMessageScope,
    },
  });

  if (!msg.evtid.match(/\|-\|/) && msg.type === MESSAGE_TYPE_USER) {
    dispatch(audio.play(BEEP));
  }

  if (!activeUser) {
    dispatch(userActions.select(msg.userId));
  }
};
