import { user as userUtils } from '../../utils';
import api from '../../utils/api';
import { addRegards } from './';

export default () => async (dispatch, getState) => {
  try {
    if (!userUtils.userLoggedInAndConfirmed(getState())) return;
    const response = await api.get('/data/regards');

    if (!userUtils.userLoggedInAndConfirmed(getState())) return;
    dispatch(addRegards(response.data));
  } catch (err) {
    console.log({ addRegardsError: err });
    console.log('could not load regards');
  }
};

