import {
  ALERT_CANCEL,
  ALERT_OK,
  ATTACHMENT_ADD,
  ATTACHMENT_DELETE,
  ATTACHMENT_DELETE_ASK,
  ATTACHMENT_SELECT,
  MESSAGE_CHAT_SEND,
  SELECT_USER,
  UPLOAD_FILE,
  USER_LOGOUT,
} from '../actions/actions';
const indicator = 'attachments';
const initialState = {
  deleteFileId: null,
  deleteFileStamp: null,
  deleteFileConfirmed: false,
  files: [],
  selectedAttachment: '',
  selectedAttachmentName: '',
};
const reduce = (state = initialState, action) => {
  switch (action.type) {
    case ALERT_OK: {
      if (action.payload?.stamp === state.deleteFileStamp) {
        return { ...state, deleteFileConfirmed: true };
      }
      return state;
    }
    case ALERT_CANCEL: {
      if (action.payload?.stamp === state.deleteFileStamp) {
        return {
          ...state,
          deleteFileConfirmed: false,
          deleteFileId: null,
          deleteFileStamp: null,
        };
      }
      return state;
    }
    case ATTACHMENT_DELETE_ASK:
      return {
        ...state,
        deleteFileId: action.payload.id,
        deleteFileStamp: action.payload.stamp,
      };
    case ATTACHMENT_DELETE: {
      if (action.error) {
        return state;
      }
      return {
        ...state,
        deleteFileConfirmed: false,
        deleteFileId: null,
        deleteFileStamp: null,
        files: state.files.filter((a) => a.id !== action.payload),
      };
    }
    case ATTACHMENT_ADD:
      return { ...state, files: [...state.files, ...action.payload] };
    case USER_LOGOUT:
      return initialState;
    case UPLOAD_FILE:
      if (
        action?.payload?.data &&
        !action?.error &&
        action?.meta?.indicator === indicator
      ) {
        return { ...state, files: [...action.payload.data] };
      }
      return state;
    case ATTACHMENT_SELECT:
      return {
        ...state,
        selectedAttachment: action.payload.number,
        selectedAttachmentName: action.payload.name,
      };
    case MESSAGE_CHAT_SEND:
    case SELECT_USER:
      return { ...state, selectedAttachment: '', selectedAttachmentName: null };
    default:
      return state;
  }
};

export default reduce;
