export default {
  VERIFF_VERIFY_BUTTON: 'Überprüfung starten',
  VERIFF_BAR_MANDATORY_TEXT:
    'Dein Account muss geprüft werden. Bitte starte den Prozess hier.',
  VERIFF_BAR_PENDING_TEXT:
    'Dein Account wird gerade vom Support geprüft und ist in wenigen Minuten wieder einsatzfähig. Wir bitten um etwas Geduld. Vielen Dank.',
  VERIFF_OVERLAY_HEADLINE: 'Dein Account muss geprüft werden',
  VERIFF_OVERLAY_TEXT_1:
    'Um Deinen Creator Account weiterhin nutzen zu können, müssen wir Deine persönlichen Daten und Ausweisdokumente überprüfen. Das ist notwendig aufgrund unserer AGB und den gesetzlichen Bestimmungen (Geldwäschegesetz). Solange die Überprüfung nicht abgeschlossen ist, kannst Du den Multi Messenger nicht nutzen und Deine Auszahlung ist gesperrt.',
  VERIFF_OVERLAY_TEXT_2:
    'Wir arbeiten dafür mit dem zertifizierten und unabhängigen Dienstleister Veriff.com zusammen.',
  VERIFF_OVERLAY_TEXT_3: `So funktioniert es:<br />
    <ul>
    <li>Du benötigst ein Smartphone mit Kamera oder eine Webcam an Deinem Computer und Deine Ausweisdokumente.</li>
    <li>Veriff wird Dich Schritt für Schritt durch den Überprüfungsprozess führen.</li>
    <li>Sobald die Überprüfung abgeschlossen ist, wird Dein Account innerhalb weniger Minuten wieder vollständig freigeschaltet.</li>
    </ul>
    `,
  'alert.veriffError':
    'Verbindungsfehler. Bitte versuche es in ein paar Minuten erneut indem Du die Seite neu lädst. Falls es nach etwa 30 Minuten immer noch nicht funtioniert, kontaktiere den Support.',
};
