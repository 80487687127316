import { connect } from 'react-redux';

import { userUpdate as update } from '../../../../actions';
import OnboardingStepper from './OnboardingStepper';

const mapStateToProps = (state) => ({
  user: state.user,
  isMobile: state.view.isMobile,
});

const mapDispatchToProps = {
  update,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingStepper);

