import { useIntl } from 'react-intl';

const bytesToSize = (bytes, fixed, intl) => {
  const sizes = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte'];

  if (bytes === 0) return 0;

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

  if (i === 0) {
    return intl.formatNumber(bytes, {
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
    });
  }

  const size = Number((bytes / 1024 ** i).toFixed(fixed));

  return intl.formatNumber(size, {
    style: 'unit',
    unit: sizes[i],
    unitDisplay: 'narrow',
  });
};

function FileSizeItem({ sizeInBytes = 0, fixed = 2 }) {
  const intl = useIntl();

  return (
    <span className="file-size">{bytesToSize(sizeInBytes, fixed, intl)}</span>
  );
}

export default FileSizeItem;
