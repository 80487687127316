import ac from '../assets/img/svg/flags/ac.svg';
import ad from '../assets/img/svg/flags/ad.svg';
import ae from '../assets/img/svg/flags/ae.svg';
import af from '../assets/img/svg/flags/af.svg';
import ag from '../assets/img/svg/flags/ag.svg';
import ai from '../assets/img/svg/flags/ai.svg';
import al from '../assets/img/svg/flags/al.svg';
import am from '../assets/img/svg/flags/am.svg';
import ao from '../assets/img/svg/flags/ao.svg';
import aq from '../assets/img/svg/flags/aq.svg';
import ar from '../assets/img/svg/flags/ar.svg';
import as from '../assets/img/svg/flags/as.svg';
import at from '../assets/img/svg/flags/at.svg';
import au from '../assets/img/svg/flags/au.svg';
import aw from '../assets/img/svg/flags/aw.svg';
import ax from '../assets/img/svg/flags/ax.svg';
import az from '../assets/img/svg/flags/az.svg';
import ba from '../assets/img/svg/flags/ba.svg';
import bb from '../assets/img/svg/flags/bb.svg';
import bd from '../assets/img/svg/flags/bd.svg';
import be from '../assets/img/svg/flags/be.svg';
import bf from '../assets/img/svg/flags/bf.svg';
import bg from '../assets/img/svg/flags/bg.svg';
import bh from '../assets/img/svg/flags/bh.svg';
import bi from '../assets/img/svg/flags/bi.svg';
import bj from '../assets/img/svg/flags/bj.svg';
import bl from '../assets/img/svg/flags/bl.svg';
import bm from '../assets/img/svg/flags/bm.svg';
import bn from '../assets/img/svg/flags/bn.svg';
import bo from '../assets/img/svg/flags/bo.svg';
import bq from '../assets/img/svg/flags/bq.svg';
import br from '../assets/img/svg/flags/br.svg';
import bs from '../assets/img/svg/flags/bs.svg';
import bt from '../assets/img/svg/flags/bt.svg';
import bv from '../assets/img/svg/flags/bv.svg';
import bw from '../assets/img/svg/flags/bw.svg';
import by from '../assets/img/svg/flags/by.svg';
import bz from '../assets/img/svg/flags/bz.svg';
import ca from '../assets/img/svg/flags/ca.svg';
import cc from '../assets/img/svg/flags/cc.svg';
import cd from '../assets/img/svg/flags/cd.svg';
import cf from '../assets/img/svg/flags/cf.svg';
import cg from '../assets/img/svg/flags/cg.svg';
import ch from '../assets/img/svg/flags/ch.svg';
import ci from '../assets/img/svg/flags/ci.svg';
import ck from '../assets/img/svg/flags/ck.svg';
import cl from '../assets/img/svg/flags/cl.svg';
import cm from '../assets/img/svg/flags/cm.svg';
import cn from '../assets/img/svg/flags/cn.svg';
import co from '../assets/img/svg/flags/co.svg';
import cp from '../assets/img/svg/flags/cp.svg';
import cr from '../assets/img/svg/flags/cr.svg';
import cu from '../assets/img/svg/flags/cu.svg';
import cv from '../assets/img/svg/flags/cv.svg';
import cw from '../assets/img/svg/flags/cw.svg';
import cx from '../assets/img/svg/flags/cx.svg';
import cy from '../assets/img/svg/flags/cy.svg';
import cz from '../assets/img/svg/flags/cz.svg';
import de from '../assets/img/svg/flags/de.svg';
import dg from '../assets/img/svg/flags/dg.svg';
import dj from '../assets/img/svg/flags/dj.svg';
import dk from '../assets/img/svg/flags/dk.svg';
import dm from '../assets/img/svg/flags/dm.svg';
import dom from '../assets/img/svg/flags/do.svg';
import dz from '../assets/img/svg/flags/dz.svg';
import ea from '../assets/img/svg/flags/ea.svg';
import ec from '../assets/img/svg/flags/ec.svg';
import ee from '../assets/img/svg/flags/ee.svg';
import eg from '../assets/img/svg/flags/eg.svg';
import eh from '../assets/img/svg/flags/eh.svg';
import er from '../assets/img/svg/flags/er.svg';
import esCt from '../assets/img/svg/flags/es-ct.svg';
import esGa from '../assets/img/svg/flags/es-ga.svg';
import es from '../assets/img/svg/flags/es.svg';
import et from '../assets/img/svg/flags/et.svg';
import eu from '../assets/img/svg/flags/eu.svg';
import fi from '../assets/img/svg/flags/fi.svg';
import fj from '../assets/img/svg/flags/fj.svg';
import fk from '../assets/img/svg/flags/fk.svg';
import fm from '../assets/img/svg/flags/fm.svg';
import fo from '../assets/img/svg/flags/fo.svg';
import fr from '../assets/img/svg/flags/fr.svg';
import ga from '../assets/img/svg/flags/ga.svg';
import gbEng from '../assets/img/svg/flags/gb-eng.svg';
import gbNir from '../assets/img/svg/flags/gb-nir.svg';
import gbSct from '../assets/img/svg/flags/gb-sct.svg';
import gbWls from '../assets/img/svg/flags/gb-wls.svg';
import gb from '../assets/img/svg/flags/gb.svg';
import gd from '../assets/img/svg/flags/gd.svg';
import ge from '../assets/img/svg/flags/ge.svg';
import gf from '../assets/img/svg/flags/gf.svg';
import gg from '../assets/img/svg/flags/gg.svg';
import gh from '../assets/img/svg/flags/gh.svg';
import gi from '../assets/img/svg/flags/gi.svg';
import gl from '../assets/img/svg/flags/gl.svg';
import gm from '../assets/img/svg/flags/gm.svg';
import gn from '../assets/img/svg/flags/gn.svg';
import gp from '../assets/img/svg/flags/gp.svg';
import gq from '../assets/img/svg/flags/gq.svg';
import gr from '../assets/img/svg/flags/gr.svg';
import gs from '../assets/img/svg/flags/gs.svg';
import gt from '../assets/img/svg/flags/gt.svg';
import gu from '../assets/img/svg/flags/gu.svg';
import gw from '../assets/img/svg/flags/gw.svg';
import gy from '../assets/img/svg/flags/gy.svg';
import hk from '../assets/img/svg/flags/hk.svg';
import hm from '../assets/img/svg/flags/hm.svg';
import hn from '../assets/img/svg/flags/hn.svg';
import hr from '../assets/img/svg/flags/hr.svg';
import ht from '../assets/img/svg/flags/ht.svg';
import hu from '../assets/img/svg/flags/hu.svg';
import ic from '../assets/img/svg/flags/ic.svg';
import id from '../assets/img/svg/flags/id.svg';
import ie from '../assets/img/svg/flags/ie.svg';
import il from '../assets/img/svg/flags/il.svg';
import im from '../assets/img/svg/flags/im.svg';
import ind from '../assets/img/svg/flags/in.svg';
import io from '../assets/img/svg/flags/io.svg';
import iq from '../assets/img/svg/flags/iq.svg';
import ir from '../assets/img/svg/flags/ir.svg';
import is from '../assets/img/svg/flags/is.svg';
import it from '../assets/img/svg/flags/it.svg';
import je from '../assets/img/svg/flags/je.svg';
import jm from '../assets/img/svg/flags/jm.svg';
import jo from '../assets/img/svg/flags/jo.svg';
import jp from '../assets/img/svg/flags/jp.svg';
import ke from '../assets/img/svg/flags/ke.svg';
import kg from '../assets/img/svg/flags/kg.svg';
import kh from '../assets/img/svg/flags/kh.svg';
import ki from '../assets/img/svg/flags/ki.svg';
import km from '../assets/img/svg/flags/km.svg';
import kn from '../assets/img/svg/flags/kn.svg';
import kp from '../assets/img/svg/flags/kp.svg';
import kr from '../assets/img/svg/flags/kr.svg';
import kw from '../assets/img/svg/flags/kw.svg';
import ky from '../assets/img/svg/flags/ky.svg';
import kz from '../assets/img/svg/flags/kz.svg';
import la from '../assets/img/svg/flags/la.svg';
import lb from '../assets/img/svg/flags/lb.svg';
import lc from '../assets/img/svg/flags/lc.svg';
import li from '../assets/img/svg/flags/li.svg';
import lk from '../assets/img/svg/flags/lk.svg';
import lr from '../assets/img/svg/flags/lr.svg';
import ls from '../assets/img/svg/flags/ls.svg';
import lt from '../assets/img/svg/flags/lt.svg';
import lu from '../assets/img/svg/flags/lu.svg';
import lv from '../assets/img/svg/flags/lv.svg';
import ly from '../assets/img/svg/flags/ly.svg';
import ma from '../assets/img/svg/flags/ma.svg';
import mc from '../assets/img/svg/flags/mc.svg';
import md from '../assets/img/svg/flags/md.svg';
import me from '../assets/img/svg/flags/me.svg';
import mf from '../assets/img/svg/flags/mf.svg';
import mg from '../assets/img/svg/flags/mg.svg';
import mh from '../assets/img/svg/flags/mh.svg';
import mk from '../assets/img/svg/flags/mk.svg';
import ml from '../assets/img/svg/flags/ml.svg';
import mm from '../assets/img/svg/flags/mm.svg';
import mn from '../assets/img/svg/flags/mn.svg';
import mo from '../assets/img/svg/flags/mo.svg';
import mp from '../assets/img/svg/flags/mp.svg';
import mq from '../assets/img/svg/flags/mq.svg';
import mr from '../assets/img/svg/flags/mr.svg';
import ms from '../assets/img/svg/flags/ms.svg';
import mt from '../assets/img/svg/flags/mt.svg';
import mu from '../assets/img/svg/flags/mu.svg';
import mv from '../assets/img/svg/flags/mv.svg';
import mw from '../assets/img/svg/flags/mw.svg';
import mx from '../assets/img/svg/flags/mx.svg';
import my from '../assets/img/svg/flags/my.svg';
import mz from '../assets/img/svg/flags/mz.svg';
import na from '../assets/img/svg/flags/na.svg';
import nc from '../assets/img/svg/flags/nc.svg';
import ne from '../assets/img/svg/flags/ne.svg';
import nf from '../assets/img/svg/flags/nf.svg';
import ng from '../assets/img/svg/flags/ng.svg';
import ni from '../assets/img/svg/flags/ni.svg';
import nl from '../assets/img/svg/flags/nl.svg';
import no from '../assets/img/svg/flags/no.svg';
import np from '../assets/img/svg/flags/np.svg';
import nr from '../assets/img/svg/flags/nr.svg';
import nu from '../assets/img/svg/flags/nu.svg';
import nz from '../assets/img/svg/flags/nz.svg';
import om from '../assets/img/svg/flags/om.svg';
import pa from '../assets/img/svg/flags/pa.svg';
import pe from '../assets/img/svg/flags/pe.svg';
import pf from '../assets/img/svg/flags/pf.svg';
import pg from '../assets/img/svg/flags/pg.svg';
import ph from '../assets/img/svg/flags/ph.svg';
import pk from '../assets/img/svg/flags/pk.svg';
import pl from '../assets/img/svg/flags/pl.svg';
import pm from '../assets/img/svg/flags/pm.svg';
import pn from '../assets/img/svg/flags/pn.svg';
import pr from '../assets/img/svg/flags/pr.svg';
import ps from '../assets/img/svg/flags/ps.svg';
import pt from '../assets/img/svg/flags/pt.svg';
import pw from '../assets/img/svg/flags/pw.svg';
import py from '../assets/img/svg/flags/py.svg';
import qa from '../assets/img/svg/flags/qa.svg';
import re from '../assets/img/svg/flags/re.svg';
import ro from '../assets/img/svg/flags/ro.svg';
import rs from '../assets/img/svg/flags/rs.svg';
import ru from '../assets/img/svg/flags/ru.svg';
import rw from '../assets/img/svg/flags/rw.svg';
import sa from '../assets/img/svg/flags/sa.svg';
import sb from '../assets/img/svg/flags/sb.svg';
import sc from '../assets/img/svg/flags/sc.svg';
import sd from '../assets/img/svg/flags/sd.svg';
import se from '../assets/img/svg/flags/se.svg';
import sg from '../assets/img/svg/flags/sg.svg';
import sh from '../assets/img/svg/flags/sh.svg';
import si from '../assets/img/svg/flags/si.svg';
import sj from '../assets/img/svg/flags/sj.svg';
import sk from '../assets/img/svg/flags/sk.svg';
import sl from '../assets/img/svg/flags/sl.svg';
import sm from '../assets/img/svg/flags/sm.svg';
import sn from '../assets/img/svg/flags/sn.svg';
import so from '../assets/img/svg/flags/so.svg';
import sr from '../assets/img/svg/flags/sr.svg';
import ss from '../assets/img/svg/flags/ss.svg';
import st from '../assets/img/svg/flags/st.svg';
import sv from '../assets/img/svg/flags/sv.svg';
import sx from '../assets/img/svg/flags/sx.svg';
import sy from '../assets/img/svg/flags/sy.svg';
import sz from '../assets/img/svg/flags/sz.svg';
import ta from '../assets/img/svg/flags/ta.svg';
import tc from '../assets/img/svg/flags/tc.svg';
import td from '../assets/img/svg/flags/td.svg';
import tf from '../assets/img/svg/flags/tf.svg';
import tg from '../assets/img/svg/flags/tg.svg';
import th from '../assets/img/svg/flags/th.svg';
import tj from '../assets/img/svg/flags/tj.svg';
import tk from '../assets/img/svg/flags/tk.svg';
import tl from '../assets/img/svg/flags/tl.svg';
import tm from '../assets/img/svg/flags/tm.svg';
import tn from '../assets/img/svg/flags/tn.svg';
import to from '../assets/img/svg/flags/to.svg';
import tr from '../assets/img/svg/flags/tr.svg';
import tt from '../assets/img/svg/flags/tt.svg';
import tv from '../assets/img/svg/flags/tv.svg';
import tw from '../assets/img/svg/flags/tw.svg';
import tz from '../assets/img/svg/flags/tz.svg';
import ua from '../assets/img/svg/flags/ua.svg';
import ug from '../assets/img/svg/flags/ug.svg';
import um from '../assets/img/svg/flags/um.svg';
import un from '../assets/img/svg/flags/un.svg';
import us from '../assets/img/svg/flags/us.svg';
import uy from '../assets/img/svg/flags/uy.svg';
import uz from '../assets/img/svg/flags/uz.svg';
import va from '../assets/img/svg/flags/va.svg';
import vc from '../assets/img/svg/flags/vc.svg';
import ve from '../assets/img/svg/flags/ve.svg';
import vg from '../assets/img/svg/flags/vg.svg';
import vi from '../assets/img/svg/flags/vi.svg';
import vn from '../assets/img/svg/flags/vn.svg';
import vu from '../assets/img/svg/flags/vu.svg';
import wf from '../assets/img/svg/flags/wf.svg';
import ws from '../assets/img/svg/flags/ws.svg';
import xk from '../assets/img/svg/flags/xk.svg';
import xx from '../assets/img/svg/flags/xx.svg';
import ye from '../assets/img/svg/flags/ye.svg';
import yt from '../assets/img/svg/flags/yt.svg';
import za from '../assets/img/svg/flags/za.svg';
import zm from '../assets/img/svg/flags/zm.svg';
import zw from '../assets/img/svg/flags/zw.svg';
const flags = {
  ac,
  ad,
  ae,
  af,
  ag,
  ai,
  al,
  am,
  ao,
  aq,
  ar,
  as,
  at,
  au,
  aw,
  ax,
  az,
  ba,
  bb,
  bd,
  be,
  bf,
  bg,
  bh,
  bi,
  bj,
  bl,
  bm,
  bn,
  bo,
  bq,
  br,
  bs,
  bt,
  bv,
  bw,
  by,
  bz,
  ca,
  cc,
  cd,
  cf,
  cg,
  ch,
  ci,
  ck,
  cl,
  cm,
  cn,
  co,
  cp,
  cr,
  cu,
  cv,
  cw,
  cx,
  cy,
  cz,
  de,
  dg,
  dj,
  dk,
  dm,
  do: dom,
  dz,
  ea,
  ec,
  ee,
  eg,
  eh,
  er,
  'es-ct': esCt,
  'es-ga': esGa,
  es,
  et,
  eu,
  fi,
  fj,
  fk,
  fm,
  fo,
  fr,
  ga,
  'gb-eng': gbEng,
  'gb-nir': gbNir,
  'gb-sct': gbSct,
  'gb-wls': gbWls,
  gb,
  gd,
  ge,
  gf,
  gg,
  gh,
  gi,
  gl,
  gm,
  gn,
  gp,
  gq,
  gr,
  gs,
  gt,
  gu,
  gw,
  gy,
  hk,
  hm,
  hn,
  hr,
  ht,
  hu,
  ic,
  id,
  ie,
  il,
  im,
  in: ind,
  io,
  iq,
  ir,
  is,
  it,
  je,
  jm,
  jo,
  jp,
  ke,
  kg,
  kh,
  ki,
  km,
  kn,
  kp,
  kr,
  kw,
  ky,
  kz,
  la,
  lb,
  lc,
  li,
  lk,
  lr,
  ls,
  lt,
  lu,
  lv,
  ly,
  ma,
  mc,
  md,
  me,
  mf,
  mg,
  mh,
  mk,
  ml,
  mm,
  mn,
  mo,
  mp,
  mq,
  mr,
  ms,
  mt,
  mu,
  mv,
  mw,
  mx,
  my,
  mz,
  na,
  nc,
  ne,
  nf,
  ng,
  ni,
  nl,
  no,
  np,
  nr,
  nu,
  nz,
  om,
  pa,
  pe,
  pf,
  pg,
  ph,
  pk,
  pl,
  pm,
  pn,
  pr,
  ps,
  pt,
  pw,
  py,
  qa,
  re,
  ro,
  rs,
  ru,
  rw,
  sa,
  sb,
  sc,
  sd,
  se,
  sg,
  sh,
  si,
  sj,
  sk,
  sl,
  sm,
  sn,
  so,
  sr,
  ss,
  st,
  sv,
  sx,
  sy,
  sz,
  ta,
  tc,
  td,
  tf,
  tg,
  th,
  tj,
  tk,
  tl,
  tm,
  tn,
  to,
  tr,
  tt,
  tv,
  tw,
  tz,
  ua,
  ug,
  um,
  un,
  us,
  uy,
  uz,
  va,
  vc,
  ve,
  vg,
  vi,
  vn,
  vu,
  wf,
  ws,
  xk,
  xx,
  ye,
  yt,
  za,
  zm,
  zw,
};
export default flags;
