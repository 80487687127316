import './VideoOverlay.scss';

import { useEffect, useRef, useState } from 'react';

import closeIcon from '../../../assets/img/svg/close.svg';
import VideoPlayerItem from '../../../components/VideoPlayerItem/VideoPlayerItem';
import useWindowResize from '../../../hooks/useWindowResize';
import Button from '../../Button/Button';

function VideoOverlay({
  fileSource,
  closeOverlay,
  openDocumentStamp,
  opacity,
}) {
  const windowResize = useWindowResize();
  const [width, setWidth] = useState('');
  const aspectRatio = 'vjs-16-9';
  const fillOption = 'vjs-fill';
  const ref = useRef();

  useEffect(() => {
    const currentHeight = ref.current.offsetHeight;
    const windowHeightWithPaddings = window.innerHeight - 200;
    const windowWidthWithPaddings = window.innerWidth * 0.8;

    if (currentHeight > windowHeightWithPaddings) {
      const widthCalculated = (window.innerHeight / 9) * 16 - 200;
      if (widthCalculated > windowWidthWithPaddings) {
        setWidth(`${windowWidthWithPaddings}px`);
      } else {
        setWidth(`${widthCalculated}px`);
      }
    } else {
      setWidth(`${windowWidthWithPaddings}px`);
    }
  }, [ref, windowResize]);
  return (
    <>
      <div className="video-overlay-wrapper" style={{ width }}>
        <VideoPlayerItem
          videoOverlayRef={ref}
          autoplay
          data={fileSource}
          aspectRatio={aspectRatio}
          fillOption={fillOption}
        />

        <Button
          type="button"
          classNamesOnly="close-btn"
          icon={closeIcon}
          onClick={() => closeOverlay(openDocumentStamp)}
          intlTranslate={false}
        />
      </div>

      <div
        className="background"
        style={opacity ? { background: `rgba(0, 0, 0, ${opacity})` } : null}
      />
    </>
  );
}

export default VideoOverlay;

