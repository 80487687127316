import { connect } from 'react-redux';

import { openDialog, spinner } from '../../../../actions';
import BillArticles from './BillArticles';

const mapDispatchToProps = (dispatch) => ({
  spinner: (state) => dispatch(spinner(state)),
  openDialog: (id, name) => dispatch(openDialog(id, name)),
});
export default connect(null, mapDispatchToProps)(BillArticles);
