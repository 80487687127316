import './LivecamOverviewTable.scss';

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import DateItem from '../TableItems/DateItem';

function LivecamOverviewTable({ items = [], translatedTitles, dateFormat }) {
  const intl = useIntl();

  return (
    <>
      <table>
        <thead>
          <tr>
            {translatedTitles.map((title, index) => {
              return (
                <th
                  style={{ width: title.width, textAlign: title.position }}
                  key={`${title}.${index}`}
                >
                  {title.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr
                key={`${item.key}.${index}`}
                className="livecam-overview-table-row"
              >
                <td>{item.id}</td>
                <td>
                  {' '}
                  <DateItem data={item.date} dateFormat={dateFormat} />
                </td>
                <td>{item.nickname}</td>
                <td>{item.portal}</td>
                <td className="text-right">
                  {intl.formatNumber(item.duration)}{' '}
                  <FormattedMessage id="SECONDS_LABEL" />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default LivecamOverviewTable;

