import React from 'react';
import { FormattedMessage } from 'react-intl';

function PanelSubtitle({ messageId }) {
  return (
    <span>
      <FormattedMessage id={messageId}>
        {(message) => (
          <span dangerouslySetInnerHTML={{ __html: message[0] }} />
        )}
      </FormattedMessage>{' '}
    </span>
  );
}

export default PanelSubtitle;
