import './Overlay.scss';

import { useState } from 'react';

import gameOver from '../../../../assets/img/4score/gameOver.svg';
import youLose from '../../../../assets/img/4score/youLose.svg';
import youWin from '../../../../assets/img/4score/youWin.svg';

function Overlay({ gameState }) {
  const [showOverLay, setShowOverlay] = useState(true);
  if (!gameState || !showOverLay) return null;
  const source =
    gameState === 'won' ? youWin : gameState === 'lost' ? youLose : gameOver;
  return (
    <div className="game-overlay" onClick={() => setShowOverlay(!showOverLay)}>
      <img src={source} alt="game over" />
    </div>
  );
}

export default Overlay;
