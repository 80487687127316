import Button from '../../../Button/Button';

function EditDiaryButtons({
  saveDiary,
  publishDisabled,
  publishDiary,
  publishBtnLabel,
  cancel,
  cancelDisabled,
}) {
  return (
    <div className="btn-diary-wrapper">
      <Button
        type="button"
        variant="dark"
        onClick={cancel}
        label={'CANCEL'}
        disabled={cancelDisabled}
      />
      <Button
        type="button"
        variant="primary"
        onClick={saveDiary}
        label={'SAVE'}
        disabled={publishDisabled}
      />
      <Button
        type="button"
        variant="primary"
        onClick={publishDiary}
        label={publishBtnLabel}
        disabled={publishDisabled}
      />
    </div>
  );
}

export default EditDiaryButtons;
