export default {
  FIRST_STEPS_HEADLINE: `Dein Start in LiveCreator`,
  FIRST_STEPS_CONTENT_1: `Um erfolgreich auf LiveCreator zu starten zeigen wir Dir hier wie die Oberfläche aufgebaut ist.`,
  FIRST_STEPS_HEADLINE_2: `LiveCreator ist in drei funktionale Bereiche aufgeteilt`,
  FIRST_STEPS_CONTENT_STEP_1: `Hauptmenü`,
  FIRST_STEPS_CONTENT_MENU_1: `Hier kannst Du Deine persönlichen Daten und Accountdaten ändern, Deine Dokumente und {link} verwalten, Abrechnungen einsehen und alle notwendigen {link1} vornehmen.`,
  FIRST_STEPS_CONTENT_MENU_1_LINK: `Dein öffentliches Profil`,
  FIRST_STEPS_CONTENT_MENU_1_LINK_1: `Einstellungen`,
  FIRST_STEPS_CONTENT_MENU_2: `Der {link} ist Dein Tool um mit Kunden in Kontakt zu bleiben, per Chat oder in der Livecam.`,
  FIRST_STEPS_CONTENT_MENU_2_LINK: `Multi Messenger`,
  FIRST_STEPS_CONTENT_MENU_3: `Hier hast Du die Möglichkeit, Deine Dateien zu verwalten, die Du Kunden per Nachricht als {link} mitschicken kannst. Außerdem kannst Du Deine {link1} mit Dateianhängen individualisieren.`,
  FIRST_STEPS_CONTENT_MENU_3_LINK: `Dateianhang`,
  FIRST_STEPS_CONTENT_MENU_3_LINK_1: `Icebreaker`,
  FIRST_STEPS_CONTENT_MENU_4: `Überprüfe und ändere den Status der Beziehung mit Usern.`,
  FIRST_STEPS_CONTENT_MENU_5: `Erstelle und bearbeite hier Deine {link} und lese oder beantworte erhaltene Kommentare. Außerdem verwaltest Du hier Dein {link1}.`,
  FIRST_STEPS_CONTENT_MENU_5_LINK: `Foto- und Videogalerien`,
  FIRST_STEPS_CONTENT_MENU_5_LINK_1: `Tagebuch`,
  FIRST_STEPS_CONTENT_MENU_6: `Dieser Menüpunkt enthält eine Übersicht der Top-25-Kunden, die am meisten Coins für Dich ausgegeben haben. Des weiteren findest Du auch Deine letzten 30 {link} Spiele.`,
  FIRST_STEPS_CONTENT_MENU_7: `Besonders zum Start empfehlen wir Dir, hier einmal reinzuschauen. Im Wiki und den letzten Newslettern findest Du viele hilfreiche Tipps und Anleitungen. Sollten dann noch Fragen offen sein, sind im Hilfezentrum die Kontaktdaten Deines Coaches Kathrin und die Termine für die mehrmals wöchentlich stattfindenden Zoom-Hilfe-Calls hinterlegt.`,
  FIRST_STEPS_CONTENT_MENU_8: `Diese Pflichtangaben beinhalten neben Impressum und Datenschutz auch die nachträgliche Änderungsmöglichkeit Deiner Cookie Einstellungen.`,

  FIRST_STEPS_CONTENT_STEP_2: `Arbeitsbereich`,
  FIRST_STEPS_CONTENT_STEP_2_CONTENT: `Je nachdem auf welchem Menüpunkt Du Dich befindest, nimmst Du in diesem Bereich Deine Einstellungen vor, erstellst Galerien oder Tagebucheinträge und bearbeitest Dein Profil.`,
  FIRST_STEPS_CONTENT_STEP_2_1_CONTENT: `Die Funktionen des Multi Messenger sind so umfassend, dass sich der Arbeitsbereich nochmals in drei Bereiche gliedert:`,
  FIRST_STEPS_CONTENT_STEP_2_1_LIST: `die Userliste`,
  FIRST_STEPS_CONTENT_STEP_2_2_LIST: `den Chat`,
  FIRST_STEPS_CONTENT_STEP_2_3_LIST: `und eine {link} des ausgewählten Users`,
  FIRST_STEPS_CONTENT_STEP_2_LINK: `Infocard`,
  FIRST_STEPS_CONTENT_STEP_3: `Infobox mit Erklärungen, Tipps und Hinweisen`,
  FIRST_STEPS_CONTENT_STEP_3_CONTENT: `Passend zu Deinem gewählten Arbeitsbereich findest Du hier wichtige Informationen und Hinweise. Wir erklären Dir zum Beispiel in der Infobox welche Möglichkeiten Du unter {link} bei der Erstellung/Bearbeitung von Galerien hast und wie es funktioniert.`,
  FIRST_STEPS_CONTENT_STEP_3_1_CONTENT: `<span class="bold">Wichtig:</span> Wenn Du eine kleinere Bildschirmauflösung nutzt, werden die Informationen unter dem Arbeitsbereich angezeigt!`,
};
