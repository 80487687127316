import { USERAUDIO_EVENT_START } from '../actions/actions';

const initialState = {
  stream: null,
  running: false,
};

const useraudio = (state = initialState, action) => {
  switch (action.type) {
    case USERAUDIO_EVENT_START:
      if (action.error) {
        return Object.assign({}, initialState);
      }
      return Object.assign({}, state, {
        stream: action.payload.stream,
        running: true,
      });
    default:
      return state;
  }
};

export default useraudio;
