export default {
  LEGAL_TERMS: `Allgemeine Geschäftsbedingungen`,
  LEGAL_TERMS_HEADLINE: `Allgemeine Geschäftsbedingungen für Darsteller`,
  LEGAL_TERMS_INTRO: `Die nachfolgenden Geschäftsbedingungen regeln das Vertragsverhältnis zwischen der IP Broadcasting B.V., Lottumseweg 43, 5971 BV Grubbenvorst, Niederlande (nachfolgend kurz: IPB) und den Kunden ihrer Telemediendienste (nachfolgend: Darsteller). Sie definieren die Bedingungen, unter denen die Nutzung der Dienste der IPB erfolgt und dienen der Sicherheit der Kommunikation der Darsteller und Nutzer der Dienstleistungen von IPB.`,

  LEGAL_TERMS_SUBJECT: `Gegenstand des Vertrages; Leistungsumfang`,
  LEGAL_TERMS_SUBJECT_1: `IPB bietet den Darstellern die Möglichkeit zur Erstellung eines Profils auf einem von IPB betriebenen zentralen Datenbanksystem. Das erstellte Profil ermöglicht den Darstellern das Einstellen von Datenmaterial, beispielsweise, aber nicht nur, von Lichtbildern, Filmwerken und Texten, auf dem von IPB betriebenen zentralen Datenbanksystem. Auf dieses Datenmaterial können daraufhin Dritte zugreifen, die sich als Nutzer auf verschiedenen von IPB betriebenen Portalen angemeldet haben (nachfolgend: Nutzer). Der Zugriff auf das Datenmaterial kann für den Nutzer kostenpflichtig sein. In diesem Fall steht dem Darsteller eine Provision zu. Die Nutzer können weiterhin mit den Darstellern über das webseiteninterne Nachrichtensystem (Messenger) sowie über Webcam und teilweise Telefon oder SMS in Kontakt treten; auch diese Dienste können für den Nutzer kostenpflichtig sein und einen Provisionsanspruch für den Darsteller begründen.`,
  LEGAL_TERMS_SUBJECT_2: `IPB betreibt neben diesem Portal auch Portale unter anderen Bezeichnungen und anderen Domains. Um für die Darsteller eine größtmögliche Anzahl an Nutzern bereithalten zu können, ermöglicht es IPB den Nutzern der jeweiligen Portale diensteübergreifend auf die Profile der Darsteller zuzugreifen. Aus diesem Grund bewirbt IPB die Profile der Darsteller auch auf Partnerseiten anderer Anbieter.`,
  LEGAL_TERMS_SUBJECT_3: `Zeitweilige Störungen oder Unterbrechungen der Dienstleistungen von IPB können sich aus Gründen höherer Gewalt sowie wegen technischer Änderungen (Wartungsarbeiten, Unterbrechungen für offline Sicherungen, Softwareupdates) ergeben. IPB wird alle zumutbaren Anstrengungen unternehmen, um den unterbrechungsfreien Abruf der Dienste zu gewährleisten. Die Verfügbarkeit der Dienste beschränkt sich auf eine Abrufbarkeit mit unwesentlichen Beeinträchtigungen.`,
  LEGAL_TERMS_SUBJECT_3_1: `Eine unwesentliche Beeinträchtigung der Verfügbarkeit der Dienste liegt dann vor, wenn der Zeitraum der Störung oder Unterbrechung 24 Stunden in einem Monat nicht überschreitet.`,
  LEGAL_TERMS_SUBJECT_4: `IPB behält sich vor, die Leistungs- und Dienstebeschreibung zu ändern, wenn die Änderung wegen gesetzlicher oder behördlicher Vorgaben erfolgt oder wenn eine Anpassung an den technischen Fortschritt nötig wird, soweit die Änderung nicht deutlich von der ursprünglichen Leistungs- oder Dienstebeschreibung abweicht und der Darsteller dadurch nicht schlechter gestellt wird.`,
  LEGAL_TERMS_SUBJECT_5: `IPB kann sich zum Angebot ihrer Telemediendienste extern beauftragter Dienstleister bedienen. Hierzu zählen insbesondere Payment-Provider, Content-Provider, Versender von Newslettern und Inkassounternehmen. Beauftragte externe Dienstleister, die im Auftrag von IPB Leistungen erbringen, gelten nicht als Dritte im Sinne dieser Allgemeinen Geschäftsbedingungen. IPB übernimmt keinerlei Haftung für die Richtigkeit und Zuverlässigkeit von Informationen, Meinungen oder anderen Mitteilungen dieser Anbieter.`,

  LEGAL_TERMS_CONCLUSION: `Vertragsabschluss und Zugang`,
  LEGAL_TERMS_CONCLUSION_1: `Der Zugang zum Content-Bereich von IPB setzt die Anmeldung voraus, bei der ein von dem Darsteller ausgewähltes persönliches Passwort und der vom Darsteller ausgewählte Benutzername verwendet werden. Mit der Anmeldung wird der Darsteller zum aktiven Darsteller und kann die Dienstleistungen von IPB in Anspruch nehmen.`,
  LEGAL_TERMS_CONCLUSION_2: `Der Darsteller erhält nach dem erstmaligen Einstellen seines Datenmaterials eine Mitteilung per E-Mail die den Eingang seiner Daten bekannt gibt. Der Vertrag wird mit der Bestätigung dieser Mail durch den Darsteller geschlossen.`,
  LEGAL_TERMS_CONCLUSION_3: `Personen, die nicht volljährig sind, sind von der Nutzung der Dienstleistungen und Services von IPB ausgeschlossen. Der Nachweis über die Volljährigkeit und der Identität hat in schriftlicher Form (beidseitige Kopie des Personalausweises, Reisepasses oder Führerscheins) zu erfolgen. Bei Datenmaterial, auf denen mehr Personen als der Darsteller selbst zu sehen ist, muss der Altersnachweis in der eben beschriebenen Form für alle teilnehmenden Personen erbracht werden. Falschangaben beim Altersnachweis führen stets zur sofortigen Sperrung des Zugangs und Einbehalt sämtlicher für den Darsteller generierten Umsätze.`,
  LEGAL_TERMS_CONCLUSION_4: `Personen, die im Auftrag eines Dritten handeln, sind von der Nutzung der Dienste von IPB ausgeschlossen.`,
  LEGAL_TERMS_CONCLUSION_5: `Mit der Registrierung bestätigt der Darsteller die Einbeziehung dieser Allgemeinen Geschäftsbedingungen. Dadurch entsteht ein Vertragsverhältnis zwischen IPB und dem Darsteller.`,
  LEGAL_TERMS_CONCLUSION_6: `Ein Rechtsanspruch auf die Registrierung als Darsteller besteht nicht. IPB ist berechtigt, Darsteller jederzeit ohne Angabe von Gründen von der Teilnahme auszuschließen bzw. abzulehnen. IPB hat das Recht, den Zugang eines Darstellers jederzeit zu sperren, wenn das System von IPB durch den Darsteller rechtswidrig genutzt wird, insbesondere – aber nicht ausschließlich – bei einem Verstoß gegen die in Abschnitt VI. aufgeführten Pflichten`,

  LEGAL_TERMS_IPB_SERVICES: `Leistungen von IPB`,
  LEGAL_TERMS_IPB_SERVICES_1: `IPB stellt mit dem Zugang zur Datenbank eine IT-Dienstleistung zur Verfügung und schuldet keinen Erfolg.`,

  LEGAL_TERMS_PERFORMER_SERVICES: `Leistungen der Darsteller`,
  LEGAL_TERMS_PERFORMER_SERVICES_1: `Vorliegend wird der Darsteller als Content-Provider für IPB tätig.`,
  LEGAL_TERMS_PERFORMER_SERVICES_2: `Der Darsteller kann auf seinem Profil Datenmaterial zum Zugriff durch Dritte bereitstellen. Datenmaterial sind insbesondere
  <ul>
    <li>Lichtbilder und Lichtbildwerke,</li>
    <li>Filmwerke,</li>
    <li>redaktionelle Texte auf dem Profil des Darstellers (nachfolgend: Blog) sowie</li>
    <li>Cam-shows</li>
  </ul>`,
  LEGAL_TERMS_PERFORMER_SERVICES_3: `Zusätzlich kann der Darsteller über den Messenger sowie eine Webcam und teilweise telefonisch oder per SMS mit einem Nutzer in Kontakt treten.`,

  LEGAL_TERMS_RENUMERATION: `Vergütung der Darsteller`,
  LEGAL_TERMS_RENUMERATION_1: `Greift ein Nutzer auf das von den Darstellern bereitgestellte Datenmaterial zu, muss er dafür den vom Darsteller festgelegten Preis bezahlen. Von den so erzielten Erlösen erhält der Darsteller eine Provision von bis zu 32 % des Netto-Umsatzes.`,
  LEGAL_TERMS_RENUMERATION_1_1: `Die Provisionsansprüche sind wie folgt gestaffelt:
  <ol>
    <li>Ein Darsteller erhält 32 % der so erzielten Erlöse, wenn er pro Abrechnungsperiode (14 Tage) mehr als 10.000 Euro Brutto Umsatz erzielt.</li>
    <li>Ein Darsteller erhält 31 % der so erzielten Erlöse, wenn er pro Abrechnungsperiode (14 Tage) mehr als 5.000 Euro Brutto Umsatz erzielt.</li>
    <li>Ein Darsteller erhält 30 % der so erzielten Erlöse, wenn er pro Abrechnungsperiode (14 Tage) mehr als 2.500 Euro Brutto Umsatz erzielt.</li>
    <li>Ein Darsteller erhält 27,5 % der so erzielten Erlöse, wenn er pro Abrechnungsperiode (14 Tage) mehr als 999,90 Euro Brutto Umsatz erzielt.</li>
    <li>Ein Darsteller erhält 25 % der so erzielten Erlöse, wenn er pro Abrechnungsperiode (14 Tage) mehr als 200 Euro Brutto Umsatz erzielt.</li>
  </ol>`,
  LEGAL_TERMS_RENUMERATION_1_2: `Als Bemessungsgrundlage für die Provisionsansprüche wird der jeweilige Nettoerlös herangezogen, der durch den Inhalt des Darstellers pro Abrechnungsperiode (14 Tage) erzielt worden ist. Im Bereich der Telefonmehrwertdienste und/oder Premium-SMS ist Bemessungsgrundlage die Netto-Ausschüttung der Netzbetreiber an IPB. Bei anderen Auswertungsformen ist Bemessungsgrundlage der Provision immer die Nettosumme, die von Nutzern oder Partnern (Drittprogramme, Zahlungsanbieter, etc.) an IPB gezahlt wird.`,
  LEGAL_TERMS_RENUMERATION_2: `Der Darsteller kann einen weiteren zusätzlichen Provisionsanspruch von 25% der Nettoumsätze eines Nutzers erzielen. Der Provisionsanspruch wird dadurch begründet, dass der Nutzer durch den Darsteller über einen von IPB generierten und zur Verfügung gestellten Affiliate-Hyperlink geworben wird.`,
  LEGAL_TERMS_RENUMERATION_3: `Beispielrechnung:<br />
  Bei einem Umsatz eines Nutzers von 1210 Euro in einer Abrechnungsperiode verbleibt zunächst ein Netto-Endkundenumsatz von 1000 Euro. Nach der Abrechnungsperiode entsteht dem Darsteller durch diesen Umsatz ein Provisionsanspruch von 275 Euro. Beträgt der Netto-Gesamtumsatz des Darstellers 20.000 Euro (Brutto-Umsatz 24200 Euro) in einer Abrechnungsperiode erhält der Darsteller durch diesen Umsatz einen Provisionsanspruch in Höhe von 6.400 Euro.`,
  LEGAL_TERMS_RENUMERATION_3_1: `Wurde der Nutzer vom Darsteller über einen Affiliate-Link geworben, entsteht für diesen Umsatz ein weiterer Provisionsanspruch in Höhe von 250 Euro.`,
  LEGAL_TERMS_RENUMERATION_4: `Die Provisionen werden jeweils zum 1. und zum 16. eines Monats für den vorhergehenden Abrechnungszeitraum abgerechnet. Der Abrechnungszeitraum für die Abrechnung am 1. des Monats ist der Zeitraum vom 16. bis zum Monatsende des Vormonats. Der Abrechnungszeitraum für die Abrechnung am 16. des Monats ist der Zeitraum vom 1. bis zum 15. des Monats. IPB erstellt für den Darsteller eine schriftliche Provisionsabrechnung und sendet diese per Post oder stellt diese zum Download im Darstellerbereich bereit.`,
  LEGAL_TERMS_RENUMERATION_5: `IPB wird nur Provisionen mit einem Abrechnungsbetrag von mindestens 50,- Euro auszahlen. Voraussetzung hierfür ist die Hinterlegung einer Kontoverbindung innerhalb des Euro-Zahlungsverkehrsraumes (SEPA). Wird der Provisionsbetrag von 50,- Euro in einer Abrechnungsperiode nicht erreicht, wird die nicht erfolgte Auszahlung in die nächste Abrechnungsperiode übernommen.`,
  LEGAL_TERMS_RENUMERATION_5_1: `Auszahlungen auf Kontoverbindungen außerhalb des oben genannten SEPA-Raumes erfolgen erst ab einem Abrechnungsbetrag von mindestens 250,- Euro. Sofern dieser Provisionsbetrag von 250,- Euro nicht in einer Abrechnungsperiode erreicht wird, wird die nicht erfolgte Auszahlung in die nächste Abrechnungsperiode übernommen.`,
  LEGAL_TERMS_RENUMERATION_6: `Die Auszahlung der Provision erfolgt stets netto ohne Mehrwertsteuer. Die Mehrwertsteuer wird ausschließlich an niederländische Darsteller mit einem entsprechenden Nachweis über die Umsatzsteuerpflicht ausgezahlt.`,
  LEGAL_TERMS_RENUMERATION_7: `Die Auszahlung der Provision erfolgt bei Darstellern, die über einen Studio Account angemeldet sind, über den Betreiber des Studio Accounts. IPB zahlt in diesem Fall die Summe der Provisionen aller Darsteller direkt an den Betreiber des Studio Accounts. Ein Auszahlungsanspruch der Darsteller gegenüber IPB besteht in diesem Fall nicht.`,
  LEGAL_TERMS_RENUMERATION_8: `IPB behält sich vor im Wege von Werbekampagnen sogenannte „Freecoins“ an die Nutzer auszuzahlen. Mit den „Freecoins“ wird kein Erlös erzielt. Werden die von den Darstellern bereitgestellten Datenmaterialien sowie Cam-Shows mit diesen „Freecoins“ bezahlt, haben die Darsteller keinen Anspruch auf eine Provision.`,
  LEGAL_TERMS_RENUMERATION_9: `Darsteller können durch Werbung neuer Darsteller unter Verwendung ihrer individuellen Werbe-ID weitere Umsätze generieren. Der Provisionsanteil an den jeweiligen Umsätzen der geworbenen Darsteller beträgt dabei bis zu 12% gemäß der folgenden Tabelle:`,
  LEGAL_TERMS_RENUMERATION_9_1: `
    <table class="has-border noHover table400">
       <tr>
          <td>5,0 % Provision</td>
          <td class="align-right">Umsätze bis 2.499,99 EUR</td>
       </tr>
       <tr>
          <td>7,5 % Provision</td>
          <td class="align-right">Umsätze ab 2.500,00 EUR</td>
       </tr>
       <tr>
          <td>10,0 % Provision</td>
          <td class="align-right">Umsätze ab 7.000,00 EUR</td>
       </tr>
       <tr>
          <td>12,0 % Provision</td>
          <td class="align-right">Umsätze ab 10.000,00 EUR</td>
       </tr>
     </table>`,
  LEGAL_TERMS_RENUMERATION_9_2: `Der Provisionsanteil bestimmt sich dabei bei mehreren geworbenen Darstellern nach den Umsätzen des jeweiligen Darstellers und nicht nach den Gesamtumsätzen aller geworbenen Darsteller.`,
  LEGAL_TERMS_RENUMERATION_9_3: `Die Auszahlung der Provision an den werbenden Darsteller erfolgt durch IPB jeweils am Ende der 14-tägigen Abrechnungsperiode (siehe auch V. Nr. 1). Die Auszahlung der Provision an die geworbenen Darsteller richtet sich dabei nach V. Nr. 1, der geworbene Darsteller wird demnach regulär vergütet und hat durch die Provisionsausschüttung an den werbenden Darsteller keine Einbußen.`,

  LEGAL_TERMS_OBLIGATIONS: `Pflichten und Verantwortlichkeit der Darsteller`,
  LEGAL_TERMS_OBLIGATIONS_1: `Der Darsteller ist für den Inhalt des von ihm eingestellten Datenmaterials und damit für die Informationen, die er über sich bereitstellt, allein verantwortlich. Er versichert, dass die angegebenen Daten der Wahrheit entsprechen. Er versichert weiterhin, Inhaber sämtlicher erforderlicher Nutzungsrechte an dem eingestellten Datenmaterial zu sein, insbesondere die Rechte der Rechteinhaber und ggf. von weiteren in dem Datenmaterial erscheinenden Person eingeholt zu haben.`,
  LEGAL_TERMS_OBLIGATIONS_2: `Ferner verpflichtet sich der Darsteller, die Dienste von IPB nicht missbräuchlich oder vertragswidrig zu nutzen, insbesondere
  <ol>
    <li>über Dritte kein diffamierendes, anstößiges oder in sonstiger Weise rechtswidriges Material oder derartige Informationen zu verbreiten;</li>
    <li>illegale Pornografie, vor allem Kinderpornografie, zur Verfügung zu stellen oder zu verbreiten;</li>
    <li>diese nicht zu nutzen, um andere Personen zu bedrohen, zu belästigen oder die Rechte (einschließlich Persönlichkeitsrechte) Dritter zu verletzen;</li>
    <li>keine Daten in das System oder die Datenbank zu einzubringen, die einen Virus enthalten oder die Software oder anderes Material enthalten, das urheberrechtlich oder durch sonstige Schutzrechte geschützt ist, es sei denn, der Nutzer ist Inhaber der jeweiligen Rechte oder besitzt die erforderliche Zustimmung zur Nutzung;</li>
    <li>diese nicht in einer Art und Weise zu nutzen, welche die Verfügbarkeit der Dienste für andere Darsteller und Nutzer negativ beeinflusst;</li>
    <li>keine Nachrichten, die einem gewerblichen Zweck dienen, zu versenden und</li>
    <li>in der Personenbeschreibung keine Namen, Adressen, Telefon- oder Faxnummern, Messengeradressen, Emails oder URLs zu nennen;</li>
    <li>keine persönlichen Daten über andere Nutzer oder Darsteller zu sammeln, zu speichern oder zu verarbeiten.</li>
  </ol>`,
  LEGAL_TERMS_OBLIGATIONS_3: `Dem Darsteller ist es auch untersagt, IPB durch unlautere Wettbewerbspraktiken einen Schaden zuzufügen. Dabei reicht bereits der Versuch aus, der Nachweis eines Schadens durch IPB ist in beiden Fällen nicht erforderlich. Dem Darsteller muss durch das wettbewerbswidrige Verhalten kein direkter Vermögensvorteil entstehen, ein Tätigwerden für und zugunsten Dritter ist zudem ausdrücklich miterfasst.`,
  LEGAL_TERMS_OBLIGATIONS_3_1: `Unlautere Wettbewerbspraktiken sind insbesondere, aber nicht abschließend:
  <ol>
    <li>Das Abwerben von Darstellern oder Usern für andere, nicht von IPB betrieben Plattformen, Agenturen oder Geschäftsmodelle (z.B. durch Kontaktaufnahme über E-Mail, Chats oder Telefon oder Werbung mittels Banner, Suchmaschinen Keywords etc.)</li>
    <li>Das Bewerben anderer Plattformen, Agenturen oder Geschäftsmodelle auf livecreator.com und/oder über die von IPB zur Verfügung gestellten Kommunikationskanäle und/oder durch IPB ermöglichte Kontaktaufnahmen zu Darstellern, Usern oder Dritten</li>
    <li>Das Bieten auf einschlägige Suchbegriffe/Keywords von IPB (z.B. „Livecreator“) bei Suchmaschinen (z.B. Google, Bing, Yandex) mit dem Ziel, Traffic auf eine andere Website umzuleiten („Brand Bidding“)</li>
    <li>Durch Nachahmen der optischen Gestaltung (UX & UI) und/oder Verwendung ähnlicher oder gleichlautender Domains andere Darsteller oder User (über den Betreiber der Website) zu täuschen und zur Anmeldung auf einer anderen Plattform oder unter Verwendung eines Affiliate Links zur Anmeldung auf Livecreator.com zu bewegen, um auf diesem Wege regelwidrig eine Provision auf die Umsätze dieser Darsteller im Rahmen eines Affiliate-Programms zu erhalten („Brand Counterfeiting“)</li>
  </ol>`,
  LEGAL_TERMS_OBLIGATIONS_3_2: `IPB ist in den vorgenannten Fällen dazu berechtigt, den Vertrag mit dem Darsteller sofort und ohne Mitteilung an den Darsteller zu kündigen. Ansprüche des Darstellers gegen IPB (aus Affiliate-Programmen) werden eingefroren und bereits gezahlte Erlöse (aus Affiliate-Programmen) können von IPB zurückgefordert werden.`,
  LEGAL_TERMS_OBLIGATIONS_3_3: `Darüber hinaus verpflichtet sich der Darsteller, eine Vertragsstrafe zu zahlen, deren Höhe in das Ermessen des zuständigen Gerichtes gestellt wird, mindestens jedoch 5.000,00 EUR pro Wettbewerbsverstoß.`,
  LEGAL_TERMS_OBLIGATIONS_3_4: `In einfach gelagerten Fällen geringen Umfangs, in denen nach Rücksprache mit dem Darsteller von einem fahrlässigen Verstoß ausgegangen werden kann, tritt an Stelle der Vertragskündigung und der Zurückbehaltung von Provisionen eine Abmahnung. Ob ein einfach gelagerter Fall gegeben ist, liegt dabei allein im Ermessen von IPB und ist nicht angreifbar.`,
  LEGAL_TERMS_OBLIGATIONS_4: `Es ist dem Darsteller nicht gestattet, Nutzer von IPB auf andere Internetseiten zu lenken oder aufmerksam zu machen oder auf andere Systeme wie Telefonmehrwertdienste etc. aufmerksam zu machen oder hierfür in seinem eingestellten Datenmaterial Werbung zu machen.`,
  LEGAL_TERMS_OBLIGATIONS_5: `Das aktive Bewerben von Konkurrenzseiten berechtigt IPB zum sofortigen Ausschluss und Einbehalt sämtlicher vom Darsteller generierten Umsätze.`,
  LEGAL_TERMS_OBLIGATIONS_6: `Dem Darsteller ist es ausdrücklich untersagt Personen, Geräte, Vereinbarungen oder sonstige Hilfsmittel in betrügerischer Absicht einzusetzen. IPB darf nach freiem Ermessen Entscheidungen treffen, wie sie mit betrügerischen Aktivitäten umgeht. IPB ist berechtigt Provisionsansprüche im Falle des Verdachts auf betrügerische Aktivitäten bis zum Abschluss einer Überprüfung einzufrieren und bei Bestätigung des Verdachtes die Auszahlung zu verweigern. Der Darsteller kann gegen eine solche Entscheidung innerhalb von 10 Tagen schriftlich einen begründeten Widerspruch einlegen.`,
  LEGAL_TERMS_OBLIGATIONS_7: `Weiterhin ist es dem Darsteller nicht gestattet, bereits registrierte Nutzer auf seinen Affiliate-Link gemäß V Ziffer 2 zu verweisen und Nutzer aufzufordern eine weitere Registrierung vorzunehmen um die Provision nach V Ziffer 2 zu erlangen.`,
  LEGAL_TERMS_OBLIGATIONS_8: `Dem Darsteller ist es weiterhin untersagt, sich als Nutzer zu registrieren.`,
  LEGAL_TERMS_OBLIGATIONS_9: `Der Darsteller ist verantwortlich für den Inhalt der Blogbeiträge auf seiner Profilseite. Der Darsteller kann für geschäftsschädigende Behauptungen, die nicht den Tatsachen entsprechen, von Dritten haftbar gemacht werden. Wenn IPB für die Folgen der im Blog aufgestellten Behauptungen haften muss, verpflichtet sich der Darsteller IPB von allen Kosten freizustellen und den IPB entstandenen Schaden zu ersetzen. Der Darsteller versichert, keine Urheberrechte oder andere Rechte Dritter mit dem Inhalt seines Blogs zu verletzen.`,
  LEGAL_TERMS_OBLIGATIONS_10: `Die Nichtbeachtung einer der in diesem Abschnitt genannten Verhaltensverpflichtungen kann sowohl zu einer sofortigen und fristlosen Kündigung der vertraglichen Beziehungen durch IPB führen, als auch zivil- und strafrechtliche Folgen nach sich ziehen. IPB behält sich in diesem Zusammenhang das Recht vor den Darsteller von seinem Service auszuschließen.`,
  LEGAL_TERMS_OBLIGATIONS_11: `Soweit der Darsteller mit dem Abschluss des Vertragsverhältnisses geschäftliche Interessen entgegen den Regelungen dieser Allgemeinen Geschäftsbedingungen verbindet, insbesondere die ihm von IPB übermittelten Daten Dritter zu kommerziellen Zwecken verwendet, verpflichtet er sich, an IPB eine Vertragsstrafe in Höhe von € 5.001,-- für jede nachgewiesene Zuwiderhandlung zu zahlen. Weitergehende Schadensersatzansprüche bleiben hiervon unberührt.`,
  LEGAL_TERMS_OBLIGATIONS_12: ``,
  LEGAL_TERMS_OBLIGATIONS_12_1: ``,

  LEGAL_TERMS_LIABILITY: `Haftung`,
  LEGAL_TERMS_LIABILITY_1: `Da IPB außerhalb der eigenen IT-Infrastruktur keinerlei Einfluss auf den Transport von Daten über das Internet hat, sowie aufgrund der Eigenarten und Unsicherheiten des Internet kann IPB keine Haftung für solch externe Datenverluste und/oder Fehler im Bereich der Datenübertragung übernehmen. IPB haftet nicht für Ausfälle des Angebotes, welche außerhalb des Einflussbereichs von IPB begründet liegen (z.B. wegen höherer Gewalt oder technischer Störungen des Internets).`,
  LEGAL_TERMS_LIABILITY_2: `IPB übernimmt keine Haftung für den Missbrauch von Informationen die der Darsteller selbst Nutzern oder anderen Dritten zur Verfügung gestellt hat. Ebenso haftet IPB nicht für die unbefugte Kenntniserlangung Dritter von persönlichen Daten der Darsteller (beispielsweise durch einen unbefugten Zugriff auf die zentrale Datenbank), es sei denn IPB hat die unbefugte Kenntniserlangung durch Dritte vorsätzlich oder grob fahrlässig möglich gemacht.`,
  LEGAL_TERMS_LIABILITY_3: `IPB haftet – außer bei Verletzung wesentlicher Vertragspflichten, bei Verletzung von Leben, Körper oder Gesundheit oder bei Ansprüchen aus dem Produkthaftungsgesetz – nur für Vorsatz und grobe Fahrlässigkeit. Wesentliche Vertragspflichten sind solche, deren Erfüllung zur Erreichung des Vertragszweckes notwendig ist.`,
  LEGAL_TERMS_LIABILITY_4: `Bei leicht fahrlässiger Verletzung einer Pflicht, die wesentlich für die Erreichung des Vertragszwecks ist (Kardinalpflicht), ist die Haftung von IPB der Höhe nach begrenzt auf den Schaden, der nach der Art des fraglichen Geschäftes vorhersehbar und typisch ist.`,
  LEGAL_TERMS_LIABILITY_5: `Die vorstehende Haftungsbeschränkung gilt auch für die persönliche Haftung der Mitarbeiter, Vertreter und Organe von IPB.`,
  LEGAL_TERMS_LIABILITY_6: `Eine weitergehende Haftung von IPB besteht nicht.`,

  LEGAL_TERMS_INDEMNIFICATION: `Freistellung`,
  LEGAL_TERMS_INDEMNIFICATION_1: `Der Darsteller stellt IPB von jeglicher Haftung und von allen weiteren Kosten, Verpflichtungen, Ansprüchen, Forderungen und Aufwendungen, die sich aus von ihm zu vertretenden Handlungen und Schäden wegen Beleidigung, übler Nachrede, Verletzung von Immaterialgüter-, Persönlichkeits- oder sonstigen Rechten Dritter und wegen des Ausfalls von Dienstleistungen für andere Darsteller oder Nutzer, frei.`,

  LEGAL_TERMS_OPERATION: `Betrieb und Rechte`,
  LEGAL_TERMS_OPERATION_1: `Der Darsteller räumt IPB ein einfaches, zeitlich, räumlich und inhaltlich unbeschränktes Recht (§ 79 UrhG) ein, die in die Datenbank von IPB eingestellten Werke des Darstellers umfassend, auch mit dem Ziel einer kommerziellen Vermarktung, zu nutzen und zu verwerten. Die Rechtseinräumung umfasst ausdrücklich alle bekannten und unbekannten Formen von Angebotsmöglichkeiten im Internet, insbesondere die Möglichkeit zur Einbindung innerhalb kostenpflichtiger Online-Dienste und Websites sowie innerhalb des frei zugänglichen Internets. Die Rechtsübertragung schließt insbesondere alle aus den Leistungsschutzrechten fließenden Befugnisse und Ansprüche insbesondere aus den §§ 73 ff., 77 bis 79 UrhG sowie aus § 94 UrhG, die Rechte des Datenbankherstellers aus § 87b UrhG, und alle anderen durch das Urheberrecht sowie sonstige Gesetze gewährten Rechte und Ansprüche an den Vertragsaufnahmen (insbesondere der Vergütungsansprüche für erlaubnisfreie, aber vergütungspflichtige Nutzungen durch Dritte, §§ 46, 47, 52, 54, 76, 77, 86, 94 Abs. 4 UrhG) einschließlich sämtlicher gewerblichen Schutzrechte und sonstigen Befugnisse, die erforderlich sind, um die vertragsgegenständlichen Nutzungshandlungen auszuüben, ein.`,
  LEGAL_TERMS_OPERATION_2: `Der Darsteller überträgt IPB insbesondere folgende Rechte:
  <ol>
    <li>Das Recht, die die Werke des Darstellers auf einem oder mehreren Online-Portalen auszuwerten:
    <ol>
      <li>durch auf das oder die Portale übertragene Bilder (Vorschaubilder, Bilder als Teil sog. Bildergalerien, Einzelbilder) via der Upload Funktion des Portals.</li>
      <li>durch auf das und/oder die Portale übertragene Videos (Bildtonaufnahmen) inklusive der dazugehörigen Tonspur (Vorschauvideos, Verkaufsvideos etc.).</li>
      <li>in Dateiform (via Email, Skype und sonstige digitale Übertragungsmöglichkeiten), auch für sonstige Marketingzwecke von Firma außerhalb von Online-Portalen.</li>
    </ol>
    <li>Das Recht, die eingestellten Werke des Darstellers nicht-körperlich zu verwerten („Nicht-Körperliche Auswertung“), insbesondere in Datenbanken, in Datennetzen (z.B. Internet), in sonstigen Speichermedien flüchtiger oder nicht-flüchtiger Art, Telefon- bzw. Telekommunikationsdiensten (unter Verwendung aller Systeme, insbesondere aller Mobilfunknetze und Mobilfunksysteme, wie z.B. WAP und UMTS) oder ähnlichen Technologien in jeder Auswahl und Anordnung für alle im Rahmen solcher Technologien möglichen Nutzungen zu verwenden, die eingestellten Werke des Darstellers dort insbesondere einzuspeisen, zu archivieren, zu speichern und/oder zum Abruf bereit zu halten und auf Abruf zum Zweck der akustischen und/oder optischen Wahrnehmung, Vervielfältigung und/oder nicht-körperlichen Verbreitung entgeltlich oder unentgeltlich, leitungsgebunden und/oder drahtlos zu übermitteln, insbesondere in sämtlichen Audio-File-Konfigurationen wie z.B. Liquid Audio, MP3, SDMI-Standards, Real Audio etc., und dafür - soweit technisch erforderlich - umzugestalten. Mit übertragen ist damit das Recht, die eingestellten Werke des Darstellers der Öffentlichkeit auf unkörperlichem Wege anzubieten, verfügbar zu machen und an individuelle Kunden (z.B. private Konsumenten, Händler, Sender etc.) zu übermitteln, insbesondere zum Zweck des sog. Realtime-play, Realtime-Streaming, sog. Download zur zeitlich begrenzten oder dauerhaften Speicherung auf Speichermedien des Kunden, des zeitversetzten Streamings (z.B. im Rahmen sog. On-Demand-Dienste oder über Abrufdienste, die sich sogenannter Stand-Alone-Geräte bedienen). IPB entscheidet insofern über die Verbindung mit anderen vertragsgegenständlichen und/oder nicht-vertragsgegenständlichen Aufnahmen. IPB entscheidet für jede vertragsgegenständliche Einzelaufnahme gesondert über Systemqualität, Speicherberechtigung beim Endkunden sowie Dauer der Speicherung.</li>
    <li>Das Recht, die eingestellten Werke des Darstellers für Marketing- und Werbezwecke auch außerhalb der Portale der IPB zu nutzen.</li>
  </ol>`,
  LEGAL_TERMS_OPERATION_3: `IPB ist berechtigt, aber nicht verpflichtet, den Inhalt des eingestellten Datenmaterials auf Vereinbarkeit mit den allgemeinen Gesetzen oder diesen AGB zu überprüfen und – sofern erforderlich – die jeweiligen Inhalte nicht zu veröffentlichen. IPB und seine Nutzer haben darüber hinaus einen besonders hohen Anspruch an Qualität, Sicherheit und Niveau der durch und über IPB erbrachten Dienstleistungen. In diesem Zusammenhang behält sich IPB ausdrücklich vor, Datenmaterial, welches den hohen Ansprüchen der Nutzer von IPB nicht genügt, von einer Veröffentlichung auszuschließen.`,
  LEGAL_TERMS_OPERATION_4: `IPB übernimmt keine redaktionelle Kontrolle über die Inhalte des Darstellers auf seinem Blog.`,

  LEGAL_TERMS_CANCELLATION: `Kündigung und Vertragsaufhebung`,
  LEGAL_TERMS_CANCELLATION_1: `Der Darsteller kann von IPB jederzeit verlangen, die Verfügbarkeit des auf der Datenbank von IPB eingestellten Datenmaterials sowie das Profil des Darstellers einzustellen. Sollte der Darsteller dieses Verlangen hinsichtlich von prämierten Material verlangen, ist eine bereits gezahlte Prämie nach Wahl von IPB vom Darsteller zurückzuerstatten oder wird mit anderweitig erzielten Provisionen verrechnet.`,
  LEGAL_TERMS_CANCELLATION_2: `Nach Beendigung des Vertrages werden auf Wunsch sämtliche personenbezogenen Daten des Teilenehmers von IPB gelöscht, sofern dies nach den gesetzlichen Bestimmungen zulässig ist. Davon ausgenommen sind Inhalte, an denen der Darsteller IPB ein zeitlich unbegrenztes Nutzungsrecht eingeräumt hat.`,
  LEGAL_TERMS_CANCELLATION_3: `IPB ist jederzeit berechtigt seinen Service für einzelne Darsteller ohne Angabe von Gründen ganz oder teilweise einzustellen.`,

  LEGAL_TERMS_PRIVACY: `Datenschutz`,
  LEGAL_TERMS_PRIVACY_1: `IPB erhebt, verarbeitet und nutzt personenbezogene Daten des Nutzers wie in diesen Bedingungen und in der {link} beschrieben. Die Verarbeitung und Speicherung personenbezogener Daten richtet sich nach den gesetzlichen Vorgaben.`,
  LEGAL_TERMS_PRIVACY_LINK: `Datenschutzerklärung`,

  LEGAL_TERMS_MODIFICATION: `Änderung der Allgemeinen Geschäftsbedingungen`,
  LEGAL_TERMS_MODIFICATION_1: `IPB behält sich das Recht vor, diese Allgemeinen Geschäftsbedingungen jederzeit zu ändern, soweit hierdurch wesentliche Regelungen des Vertragsverhältnisses nicht berührt werden und dies zur Anpassung an Entwicklungen erforderlich ist, welche bei Vertragsschluss für IPB nicht vorhersehbar waren und deren Nichtberücksichtigung die Ausgewogenheit des Vertragsverhältnisses merklich stören würde. Wesentliche Regelungen sind insbesondere solche über Art und Umfang der vereinbarten Leistungen sowie die Laufzeit einschließlich der Regelungen zur Kündigung. IPB ist berechtigt Veränderungen vorzunehmen um Vertragslücken zu schließen, die insbesondere durch gesetzliche Änderungen und Änderungen in der Rechtsprechung auftreten.`,
  LEGAL_TERMS_MODIFICATION_2: `IPB wird den Nutzer unter Zusendung der zu ändernden Allgemeinen Geschäftsbeziehungen per E-Mail auf die Änderungen hinweisen. Statt der Beifügung des vollständigen Textes ist ein Verweis auf die Adresse im Internet, unter der die neue Fassung abrufbar ist, ausreichend. Der Nutzer hat die Möglichkeit mit einer Frist von zwei Wochen den Änderungen zu widersprechen. Wenn der Nutzer innerhalb dieser Frist den Änderungen nicht widerspricht, gelten die geänderten Allgemeinen Geschäftsbedingungen ab dem Tag des Fristablaufs.`,

  LEGAL_TERMS_FINAL: `Schlussbestimmungen`,
  LEGAL_TERMS_FINAL_1: `Die zwischen IPB und dem Darsteller bestehenden Rechtsverhältnisse unterliegen vorbehaltlich zwingender Vorschriften dem Recht der Bundesrepublik Deutschland.`,
  LEGAL_TERMS_FINAL_2: `Gerichtsstand für alle Ansprüche aus diesem Vertragsverhältnis ist Berlin.`,
  LEGAL_TERMS_FINAL_3: `Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen unwirksam sein oder werden, berührt dies die Gültigkeit der übrigen Bestimmungen dieser Vereinbarung nicht. Die Vertragspartner werden die unwirksame Bestimmung durch eine Regelung ersetzen, die dem wirtschaftlichen Zweck der ursprünglichen Regelung möglichst nahe kommt. Ebenso ist im Falle des Auftretens einer Vertragslücke zu verfahren.`,
  LEGAL_TERMS_FINAL_4: `Änderungen und/oder Ergänzungen dieser Vereinbarung bedürfen zu ihrer Gültigkeit der Schriftform, sofern in diesen Allgemeinen Geschäftsbedingungen nichts anderes vereinbart wurde. Dies gilt auch für einen eventuellen Verzicht auf das Schriftformerfordernis.`,
  LEGAL_TERMS_FINAL_5: `Abweichende Geschäftsbedingungen des Darstellers/Nutzers werden nicht akzeptiert.`,
};
