import { connect } from 'react-redux';

import { spinner, userTokenLogin } from '../../../actions';
import ConfirmEmail from './ConfirmEmail';

const mapDispatchToProps = {
  spinner,
  userTokenLogin,
};
const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmEmail);
