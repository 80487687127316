import {
  MAX_ZEROBYTES_RECONNECT_TRIES,
  STREAMING_IS_STARTING,
  STREAMING_STARTED,
} from '../../../constants';
import { management as managementService } from '../../../services';
import { roomType as roomTypeUtils } from '../../../utils';
import { STREAM_EVENTS_PUBLISH } from '../../actions';
import { incrementZerobytesReconnect, management, stream } from '../..';
export default (videoBitrate, bytesSent) => {
  return (dispatch, getState) => {
    const {
      camUser: { camUsers },
      sender: { roomTypes },
      streaming: {
        zeroBytesReconnects,
        step,
        connectionData: { mediaServerUrl, application },
      },
    } = getState();

    if (bytesSent === 0) {
      if (zeroBytesReconnects < MAX_ZEROBYTES_RECONNECT_TRIES) {
        dispatch(incrementZerobytesReconnect(zeroBytesReconnects));
        managementService.log.splunk.info(
          `reconnect_zero_bytes:${zeroBytesReconnects}`
        );
        return dispatch(management.events.streamReconnect());
      }
      managementService.log.splunk.info(
        `${mediaServerUrl},${application}, Initial BytesSent failed: ${bytesSent}`
      );
      return dispatch(stream.stop(false));
    }
    dispatch(stream.changeBandwidth(videoBitrate));
    if (step === STREAMING_STARTED) {
      managementService.log.splunk.warn(
        'stream events publish called with step = STREAMING_STARTED'
      );
      return;
    }
    if (step === STREAMING_IS_STARTING) {
      dispatch(management.startedSenderStream());

      camUsers.forEach((userInfo) => {
        dispatch(management.startedStream(userInfo));
      });
      if (!roomTypeUtils.isEvent(roomTypes)) {
        dispatch(management.logfpsTimer.start());
        dispatch(management.checkuserTimer.start());
      }
    }
    if (!roomTypeUtils.isEvent(roomTypes)) {
      dispatch(stream.timerCheck.start());
    }
    dispatch({
      type: STREAM_EVENTS_PUBLISH,
      payload: {
        videoBitrate,
        publishTime: Date.now(),
      },
    });
  };
};
