import './SearchInput.scss';

import React from 'react';
import { ReactSVG } from 'react-svg';

import closeIcon from '../../../assets/img/svg/close.svg';
import searchIcon from '../../../assets/img/svg/search.svg';
import Button from '../../Button/Button';
import Input from '../Input/Input';

function SearchInput({ id, name, className, clearInput, ...otherProps }) {
  return (
    <div className="search-input">
      <Input id={id} name={name} {...otherProps} className={className} />

      <ReactSVG src={searchIcon} className="search-icon" />
      {otherProps.value !== '' && (
        <Button
          className="btn-close"
          type="button"
          onClick={() => clearInput()}
          icon={closeIcon}
          intlTranslate={false}
          variant="icon-only"
        />
      )}
    </div>
  );
}

export default SearchInput;

