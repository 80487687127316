export default {
  diary_weblog: 'Tagebuch Eintrag',
  diary_automatic_entry: 'Automatischer Eintrag',
  diary_profile_image: 'Profilbild',
  CREATE_NEW_DIARY_ENTRY: 'Neuen Eintrag erstellen',
  DIARY_DATE: 'Datum',
  DIARY_TYPE: 'Typ',
  DIARY_TITLE: 'Titel',
  DIARY_MENU_EDIT: 'Eintrag bearbeiten',
  DIARY_MENU_CREATE: 'Eintrag erstellen',
  DIARY_MENU_PUBLISH: 'Veröffentlichen',
  DIARY_MENU_UNPUBLISH: 'Archivieren',
  DIARY_MENU_DELETE: 'Löschen',
  DIARY_EDIT_HEADLINE: 'Bearbeite Deinen Eintrag',
  DIARY_ERROR_LOADING:
    'Der Tagebucheintrag konnte nicht geladen werden. Versuche es bitte noch einmal.',
  DIARY_FILE_OPTIONAL: '(optional)',
  DIARY_FILE: 'Bild hinzufügen',
  DIARY_BUTTON_UNPUBLISH: 'Archivieren',
  DIARY_BUTTON_PUBLISH: 'Veröffentlichen',
  ROUTE_MY_DIARY_HEADLINE: 'Verwalte hier Dein Tagebuch',
  DIARY_NEW_ENTRY: 'Neuer Eintrag',
  DIARY_INFO_HEADLINE_1:
    '3 gute Gründe, warum es gut ist, Dein Tagebuch zu führen',
  DIARY_INFO_1: `
  <ol type="1">
    <li>Jeder neue Eintrag in Deinem Tagebuch wird auf der Startseite und auf Deinem Profil angezeigt. Damit steigt die Chance auf mehr Profilbesucher oder neue potenzielle Kunden.</li>
    <li>Mit Deinem Tagebuch kannst Du einen Einblick in Deinen Tag geben, die nächste Camshow ankündigen oder neue Galerien bewerben.</li>
    <li>Durch das Tagebuch kannst Du Deine Stammkunden an Deinem Leben teilhaben lassen und weckst zudem das Interesse anderer Kunden, mit denen Du noch nicht in Kontakt stehst.</li>
  </ol>
  `,
  DIARY_INFO_HEADLINE_2: 'So einfach geht’s:',
  DIARY_INFO_2: `
  <span class="bold">Titel</span></br>
  Hier gibst Du die Überschrift für den neuen Tagebuch-Eintrag an.`,
  DIARY_INFO_3: `
  <span class="bold">Eintrag</span></br>
  Schreibe etwas aus dem Leben. Dies kann ein einfacher Gruß oder sogar eine sexy Story sein, die Du gerade erlebt hast. Bewerbe Deine neueste Galerie oder schreibe, wann Du das nächste Mal in der Livecam zu sehen bist. Halte so Deine Stammkunden auf dem Laufenden und mache neue Kunden auf Dich aufmerksam. Lasse Deiner Fantasie freien Lauf.`,
  DIARY_INFO_4: `
  <span class="bold">Foto</span></br>
  Hier kannst Du ein Foto hochladen oder direkt mit der Kamera aufnehmen, um Deinen Eintrag noch interessanter zu gestalten.`,
};
