import { connect } from 'react-redux';

import { openOverlay } from '../../actions';
import VideoWithOverlay from './VideoWithOverlay';

const mapDispatchToProps = {
  openOverlay,
};

export default connect(null, mapDispatchToProps)(VideoWithOverlay);

