export const fullCustomersTableTitles = [
  { title: 'TABLE_TITLE_TIMESTAMP', width: '15%', position: 'left' },
  {
    title: 'TABLE_TITLE_CUSTOMER',
    width: '45%',
    position: 'left',
    icon: true,
  },
  { title: 'TABLE_TITLE_PORTAL', width: '15%', position: 'left' },
  { title: 'TABLE_TITLE_ZIP', width: '15%', position: 'left' },
  { title: 'TABLE_TITLE_COUNTRY', width: '15%', position: 'left' },
];

export const mediumCustomersTableTitles = [
  { title: 'TABLE_TITLE_TIMESTAMP', width: '15%', position: 'left' },
  {
    title: 'TABLE_TITLE_CUSTOMER',
    width: '45%',
    position: 'left',
    icon: true,
  },
  { title: 'TABLE_TITLE_PORTAL', width: '20%', position: 'left' },
  { title: 'TABLE_TITLE_COUNTRY', width: '20%', position: 'left' },
];

export const mobileCustomersTableTitles = [
  { title: 'TABLE_TITLE_TIMESTAMP', width: '15%', position: 'left' },
  {
    title: 'TABLE_TITLE_CUSTOMER',
    width: '70%',
    position: 'left',
    icon: true,
  },
  { title: 'TABLE_TITLE_PORTAL', width: '15%', position: 'left' },
];

