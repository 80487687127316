import React from 'react';
import { FormattedMessage } from 'react-intl';

import ConvertDE from '../../../../../assets/img/wiki/wiki_voice_convert_de.png';
import ConvertEN from '../../../../../assets/img/wiki/wiki_voice_convert_en.png';
import ConvertedDE from '../../../../../assets/img/wiki/wiki_voice_converted_de.png';
import ConvertedEN from '../../../../../assets/img/wiki/wiki_voice_converted_en.png';
import InputDE from '../../../../../assets/img/wiki/wiki_voice_input_de.png';
import InputEN from '../../../../../assets/img/wiki/wiki_voice_input_en.png';
import Recorded from '../../../../../assets/img/wiki/wiki_voice_recorded.png';
import BackButton from '../../../../BackButton';

function VoiceMessage({ locale, onBackButtonClicked }) {
  return (
    <div className="wiki-content wiki-voice-message-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="VOICE_MESSAGE_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="VOICE_MESSAGE_INTRO" />
      </p>
      <p>
        <FormattedMessage id="VOICE_MESSAGE_INTRO_1" />
      </p>

      <h2 className="headline">
        <FormattedMessage id="VOICE_MESSAGE_CONTENT" />
      </h2>
      <p>
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_1">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <h2 className="headline">
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_2" />
      </h2>
      <p className="list-headline">
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_3" />
      </p>
      <p>
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_3_1" />
      </p>
      <p className="list-headline">
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_4" />
      </p>
      <p>
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_4_1" />
      </p>
      <p className="list-headline">
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_5" />
      </p>
      <p>
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_5_1" />
      </p>
      <p className="list-headline">
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_6" />
      </p>
      <p>
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_6_1" />
      </p>

      <h2 className="headline">
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_7" />
      </h2>
      <p className="has-margin-bottom">
        <FormattedMessage id="VOICE_MESSAGE_CONTENT_7_1" />
      </p>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="VOICE_MESSAGE_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="VOICE_MESSAGE_CONTENT_STEP_1_CONTENT" />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img
                className="img-responsive border-light-gray"
                src={InputEN}
                alt="Multi Messenger"
              />
            ) : (
              <img
                className="img-responsive border-light-gray"
                src={InputDE}
                alt="Multi Messenger"
              />
            )}
          </div>
          <p>
            <FormattedMessage id="VOICE_MESSAGE_CONTENT_STEP_1_1_CONTENT" />
          </p>
          <div className="image-wrapper">
            <img
              className="img-responsive border-light-gray"
              src={Recorded}
              alt="Voice message"
            />
          </div>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="VOICE_MESSAGE_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="VOICE_MESSAGE_CONTENT_STEP_2_CONTENT" />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img
                className="img-responsive border-light-gray"
                src={ConvertEN}
                alt="Convert"
              />
            ) : (
              <img
                className="img-responsive border-light-gray"
                src={ConvertDE}
                alt="Konvertieren"
              />
            )}
          </div>
          <p className="list-headline">
            <FormattedMessage id="VOICE_MESSAGE_CONTENT_STEP_2_1_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="VOICE_MESSAGE_CONTENT_STEP_2_2_CONTENT" />
          </p>
          <div className="image-wrapper">
            {locale === 'en' ? (
              <img
                className="img-responsive border-light-gray"
                src={ConvertedEN}
                alt="Text output"
              />
            ) : (
              <img
                className="img-responsive border-light-gray"
                src={ConvertedDE}
                alt="Textausgabe"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default VoiceMessage;
