import './ForgotPassword.scss';

import useAxios from 'axios-hooks';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';

import loginIcon from '../../../assets/img/svg/login.svg';
import Button from '../../Button/Button';
import Input from '../../Forms/Input/Input';
import StandaloneFieldWithErrors from '../../Forms/StandaloneFieldWithErrors/StandaloneFieldWithErrors';
import PageSection from '../../PageSection/PageSection';

function ForgotPassword() {
  const intl = useIntl();
  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [validationError, setValidationError] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);

  const forgotEmailSchema = Yup.object().shape({
    forgot_password_email: Yup.string().email(
      intl.formatMessage({ id: 'VALIDATION_EMAIL' })
    ),
  });

  function resetPasswordEmailHandler(email) {
    try {
      forgotEmailSchema.validateSync({ forgot_password_email: email });
      setValidationError(null);
    } catch (e) {
      setValidationError(e.errors[0]);
    }
    setResetPasswordEmail(email);
  }

  const [, forgotPasswordRequest] = useAxios(
    {
      url: '/reset-password',
      method: 'POST',
      data: {
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        email: resetPasswordEmail,
      },
    },
    {
      manual: true,
    }
  );

  function sendForgotPasswordRequest() {
    forgotPasswordRequest().finally(() => {
      setShowSuccess(true);
    });
  }

  return (
    <PageSection
      title={<FormattedMessage id="ACCOUNT_LOGIN" />}
      className="forgot-password-container"
    >
      <h1 className="headline">
        <FormattedMessage id="ACCOUNT_FORGOT_PASSWORD" />
      </h1>
      {!showSuccess && (
        <form className="forgot-password-form">
          <StandaloneFieldWithErrors
            name={'forgot_password_email'}
            label="FIELD_EMAIL"
            error={validationError}
          >
            <Input
              id={'forgot_password_email'}
              name={'forgot_password_email'}
              value={resetPasswordEmail}
              onChange={(event) =>
                resetPasswordEmailHandler(event.target.value)
              }
            />
          </StandaloneFieldWithErrors>

          <div className="form-group">
            <span className="column" />
            <Button
              icon={loginIcon}
              label={'RESET_PASSWORD_LABEL'}
              id="submit-btn"
              className="btn-form"
              onClick={() => sendForgotPasswordRequest()}
              disabled={validationError || resetPasswordEmail === ''}
            />
          </div>
        </form>
      )}

      {showSuccess && (
        <div className="success-wrapper">
          <div className="success-title">
            {' '}
            <FormattedMessage id="SUCCESS_TITLE" />
          </div>
          <hr />
          <div className="success-content">
            <FormattedMessage id="FORGOT_PASSWORD_SUCCESS_MESSAGE" />
          </div>
        </div>
      )}
    </PageSection>
  );
}

export default ForgotPassword;
