export const fullCustomersTableTitles = [
  { title: 'TABLE_TITLE_NO', width: '50px', position: 'left' },
  {
    title: 'TABLE_TITLE_CUSTOMER',
    width: 'calc(45% - 50px)',
    position: 'left',
    icon: true,
  },
  { title: 'TABLE_TITLE_PORTAL', width: '15%', position: 'left' },
  { title: 'TABLE_TITLE_COUNTRY', width: '15%', position: 'left' },
  { title: 'TABLE_TITLE_LAST_ON', width: '10%', position: 'left' },
  { title: 'TABLE_TITLE_TURNOVER', width: '15%', position: 'right' },
];

export const mediumCustomersTableTitles = [
  {
    title: 'TABLE_TITLE_CUSTOMER',
    width: '45%',
    position: 'left',
    icon: true,
  },
  { title: 'TABLE_TITLE_COUNTRY', width: '20%', position: 'left' },
  { title: 'TABLE_TITLE_LAST_ON', width: '15%', position: 'left' },
  { title: 'TABLE_TITLE_TURNOVER', width: '20%', position: 'right' },
];

export const mobileCustomersTableTitles = [
  {
    title: 'TABLE_TITLE_CUSTOMER',
    width: '70%',
    position: 'left',
    icon: true,
  },
  { title: 'TABLE_TITLE_TURNOVER', width: '30%', position: 'right' },
];

const monthNames = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export function getMonthOptions(yearFilter) {
  const returnArr = [{ label: 'TABLE_LABEL_MONTH', value: '0' }];
  const currentYear = new Date().getFullYear().toString();
  const currentMonth = new Date().getMonth() + 1;

  for (let i = 0; i <= 11; i++) {
    returnArr.push({
      label: monthNames[i],
      value: i + 1,
    });
  }
  if (yearFilter === currentYear) {
    for (let i = currentMonth + 1; i < 13; i++) {
      returnArr[i].disabled = true;
    }
  }
  return returnArr;
}

export function getYearOptions(intl) {
  const returnArr = [
    { label: intl.formatMessage({ id: 'TABLE_LABEL_YEAR' }), value: '0' },
  ];

  const minDate = 2021;
  const maxDate = new Date().getFullYear();

  for (let i = maxDate; i > minDate; i--) {
    returnArr.push({
      label: i,
      value: i,
    });
  }

  return returnArr;
}

