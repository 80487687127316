import { connect } from 'react-redux';

import { user as userUtils } from '../../../utils';
import UserProfile from './UserProfile';

const mapStateToProps = (state) => {
  const activeUser = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);

  return {
    personalAvailable: !!activeUser?.profile?.personal,
    camDisplayed: state.view.cam,
    activeUser,
  };
};

export default connect(mapStateToProps)(UserProfile);
