export default {
  DIARY_HEADLINE: `Your diary`,
  DIARY_INTRO: `With a diary entry, you can add a personal touch to your profile. Regular diary entries offer you the chance to get more and new visitors every day, because every entry is shown on the home page.`,
  DIARY_INTRO_1: `Give new visitors and your regular users an insight into the life of "their" favorite amateur and keep them up to date with updates about new galleries or your next livecam show. This will help you build a strong bond and continuously increase your sales.`,
  DIARY_CONTENT: `Writing a diary entry`,
  DIARY_CONTENT_1: `In the main menu under "My Content" you select the item "Diary". Here you will find an overview of your created diary entries. By clicking on the three dots (Item menu), you can edit, archive or delete any entry at the end.`,
  DIARY_CONTENT_2: `To write a new journal entry, click on the "Create new entry" button.`,
  DIARY_CONTENT_3: `You can edit this information`,
  DIARY_CONTENT_4: `Once you've created your listing, you have two options:`,
  DIARY_CONTENT_5: `
    <ul class='checklist'>
      <li>If you click "Publish now", your entry will be published on your profile and will be visible to all visitors.</li>
      <li>If you click "Save", your entry will be saved and can be seen on the diary overview. There you can edit or publish it at any time by clicking on the item mneu. </li>
    </ul>`,
  DIARY_CONTENT_6: `Important:`,
  DIARY_CONTENT_7: `
    <ul>
      <li>Automatic entries (profile pictures, galleries) cannot be edited.</li>
      <li>The images in a diary entry may correspond to all FSK levels. You don't have to be visible yourself, you can also add a picture of your morning cup of coffee or build a certain curiosity with a picture of your shopping purchases.</li>
      <li>Providing your contact details is forbidden!</li>
    </ul>`,

  DIARY_CONTENT_STEP_1: `Title`,
  DIARY_CONTENT_STEP_1_CONTENT: `This is where you enter the heading for the new diary entry.`,
  DIARY_CONTENT_STEP_2: `Description`,
  DIARY_CONTENT_STEP_2_CONTENT: `Write something from life. This can be a simple greeting or even a sexy story you've just experienced. Promote your latest gallery or write when you'll next be on the live cam. Keep your regular customers up to date and attract new visitors. Let your imagination run wild.`,
  DIARY_CONTENT_STEP_3: `Add a picture`,
  DIARY_CONTENT_STEP_3_CONTENT: `Optionally, you can upload a photo here to make your entry even more interesting.`,
};
