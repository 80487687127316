import { management, stream } from '../../../actions';
import { roomType as roomTypeUtils } from '../../../utils';
export default (WebRTCPublisher) => () => (dispatch, getState) => {
  const {
    sender: { roomTypes },
  } = getState();

  if (roomTypeUtils.isEvent(roomTypes)) {
    return;
  }

  dispatch(management.logfpsTimer.stop());

  dispatch(stream.timerCheck.stop());

  WebRTCPublisher.reconnect();
};
