export default {
  LANDING_PAGE_HEADLINE_1: `Warum Social Media Plattformen nutzen ohne Geld zu verdienen?`,
  LANDING_PAGE_HEADLINE_2_1: `Online Geld verdienen`,
  LANDING_PAGE_HEADLINE_2_2: `Wann immer und wo immer Du magst`,
  LANDING_PAGE_HEADLINE_2_3: `Starte sofort als Creator`,
  LANDING_PAGE_HEADLINE_2_4: `Wir bringen Follower und Fans direkt zu Dir`,
  LANDING_PAGE_HEADLINE_2_5: `Über 4,5 Millionen Follower`,
  LANDING_PAGE_HEADLINE_2_6: `Monatlich ca. 850.000 neue Follower`,
  LANDING_PAGE_HEADLINE_3: `Häufig gestellte <span class="bold">Fragen - FAQ</span`,

  CAROUSEL_ITEM_1: `
  <div class="item-headline">
  Einfacher Einstieg mit der 
    <br />
    <span class="bold">Video-Tour!</span>
  </div>
  <div clasS="item-content">
  Lerne in einer Minute, wie Du sofort mit uns als Creator starten kannst.
  Registriere Dich unverbindlich und nutze unseren persönlichen Support und zahlreiche kostenfreie Coachings!
  </div>`,

  CAROUSEL_ITEM_2: `
    <div class="item-headline">
      <span class="bold">Content Creator</span>
      <br />
      Vermarktung ohne Limit
      </div>
    <div clasS="item-content">
      Verdiene Geld bei freier Zeiteinteilung von Zuhause oder remote. Sei Deine eigene Chefin - Verdiene mit Deinem Content mehr als 60 EUR pro Stunde!
    </div>`,
  CAROUSEL_ITEM_3: `
    <div class="item-headline">
      Top
      <br />
      <span class="bold">Verdienstmöglichkeiten</span>
    </div>
    <div class="item-content">
      Erhalte als Creator bis zu 32% Provision.
      Bringe Deine Follower zu LiveCreator und sichere Dir bis zu 57% Provision!
    </div>`,
  CAROUSEL_ITEM_4: `
    <div class="item-headline">
      Ausgewogene <span class="bold">Work-Life-Balance</span> und selbstbestimmtes Arbeiten
    </div>
    <div class="item-content">
      Lege Angebot und Preis Deiner persönlichen bis intimen Inhalte fest und wie Du diese mit der Community teilst. Anonym, im Chat - mit oder ohne Cam. Sogar sexy Content ist möglich!
    </div>`,
  CAROUSEL_ITEM_5: `
    <div class="item-headline">
      <span class="bold">Keine</span> Vorkenntnisse
      <br /> erforderlich!
    </div>
    <div class="item-content">
      Profitiere von unseren kostenfreien Webinaren und Trainings! Support und Coaching für Neuanfänger und erfahrene Creator.
    </div>`,

  PANEL_TITLE_1: `Welche Vorteile bietet Dir LiveCreator?`,
  PANEL_SUBTITLE_1: `Beginne Deine Karriere als Creator! LiveCreator stellt Dich Millionen Fans und Followern vor! Starte sofort auch ohne Vorkenntnisse. Wir zeigen Dir
in kostenfreien Webinaren und Trainings worauf es ankommt... auf Deinem PC oder Deinem Smartphone.
`,
  PANEL_CONTENT_1: `
    {image}
     <p>
      Jeden Monat melden sich ca. 850.000 neue Mitglieder bei uns an. Du musst Dich nicht darum kümmern, dass diese Dich finden und auch Deine Follower/Fans werden können. Verbringe Deine Zeit mit Ihnen im Messenger - wahlweise mit oder ohne Webcam. Führe dort interessante Chats oder unterhalte Dich direkt im Cam-Stream. Du verdienst an jeder erhaltenen Message im Chat und jeder Minute, die Du mit Deinen Followern/Fans in der Webcam verbringst! Jederzeit von Zuhause oder unterwegs – Du entscheidest!

    </p>
    <p>
      Die Anmeldung als Creator ist für Dich kostenfrei! Es fallen keine Gebühren oder Kosten für Dich an. Probiere es einfach unverbindlich aus! Du wirst sehen wie viel Spaß es macht – und das Du viel Geld verdienen wirst.
    </p>
    <ul>
      <li>Du teilst Deine Zeit selbst ein (Vollzeit, Teilzeit, nur ein paar Stunden pro Woche)</li>
      <li>Du bestimmst Deine Work-Life-Balance</li>
      <li>Du bist Deine eigene Chefin</li>
      <li>Du bestimmst, wie viel Geld Du verdienen möchtest.</li>
    </ul>`,

  PANEL_TITLE_2: `Welche Verdienstmöglichkeiten bietet Dir LiveCreator?`,
  PANEL_SUBTITLE_2: `Ein Durchschnittsverdienst in Höhe von 31 EUR/Stunde ist problemlos zu erreichen. Besonders fleißige Creator verdienen sogar über 60 EUR pro Stunde! Du entscheidest selbst, wann und wie viel Du arbeiten möchtest. Sei Deine eigene Chefin!`,
  PANEL_CONTENT_2: `
    <p>
      Bestimme selbst und absolut flexibel über Deine Arbeitszeiten. Vollzeit oder Teilzeit, ein paar Stunden pro Woche, nur am Wochenende oder nur abends für 2 Stunden nach Deinem Hauptberuf. Es liegt bei Dir, wie viel Geld Du verdienen möchtest. Je häufiger Du auf der Plattform aktiv bist, desto mehr Follower/Fans werden Dir folgen und auf Deinem Profil Umsatz generieren. Und dabei verdienst Du bis zu 32% Provision!
    </p>
    {image}
    <p>Erreiche die finanzielle Unabhängigkeit, die Du schon immer angestrebt hast. Urlaub, schöne Kleidung, Designer-Schuhe oder auch ein neues Auto.
    Deine Provision zahlen wir Dir sogar zweimal im Monat aus, ganz automatisch.</p>
    Wie Du sogar noch mehr Provision erhältst, erfährst Du {link}`,

  PANEL_TITLE_3: `Wie funktioniert LiveCreator?`,
  PANEL_SUBTITLE_3: `LiveCreator bietet Dir exklusiven Zugang zu Social Media Plattformen mit mehreren Millionen aktiven Mitgliedern und monatlich ca. 850.000 Neuanmeldungen.<br />
    Damit bringt Dir LiveCreator täglich neue Follower/Fans auf Dein Profil, die mit Dir kostenpflichtig interagieren und Dir somit einen attraktiven Verdienst ermöglichen.
`,
  PANEL_CONTENT_3: `
    <p>
      Du kannst an Deinem Computer oder Laptop arbeiten oder auch mit Deinem Smartphone. Zeige Dich via Webcam, wenn Du möchtest, und schaffe weitere Reize und Verdienstmöglichkeiten mit ansprechenden Bildern und Video Content. Egal, ob Du von Zuhause arbeitest oder wenn Du unterwegs bist. Du kannst immer Geld verdienen. Das ganze System ist extrem einfach aufgebaut, so dass es eine Freude ist, damit zu arbeiten. Du brauchst keine besonderen technischen Fähigkeiten.
    </p>
    {image}
    <p>
      Die Plattform ist darauf ausgelegt, dass Creator (Content Creator, Webcam Models, Influencer) jederzeit live im Chat – mit und ohne Webcam - mit den Followern/Fans interagieren. Das können angeregte, persönliche Gespräche sein, Small Talk oder auch erotische Inhalte. Auch wenn Du Dein Gesicht nicht zeigen und anonym bleiben möchtest, ist das möglich. Du bestimmst, was Du im Chat teilen oder vor der Webcam machen möchtest. Du musst Dich niemals leicht bedeckt oder nackt zeigen, wenn Du das nicht möchtest.
    </p>
    <p>
      Egal wie Du Dich entscheidest, für jede Zielgruppe gibt es genügend Follower/Fans, die gerne für Deinen Content und Zeit mit Dir im Chat und/oder vor der Webcam bezahlen. Den Preis dafür kannst Du natürlich selbst festlegen.
    </p>`,

  PANEL_TITLE_4: `Wie kannst Du noch mehr Geld mit LiveCreator verdienen?`,
  PANEL_SUBTITLE_4: `Wir haben bereits Millionen Mitglieder auf unseren Plattformen und sorgen dafür, dass diese Dein Profil kennenlernen. Aber auch Deine Follower von anderen Plattformen sind herzlich auf LiveCreator willkommen und werden Dir zusätzliche Provision bringen.`,
  PANEL_CONTENT_4: `
    <p>
      Denn solltest Du bereits über eigene Social Media Profile verfügen und Deine Follower neu zu LiveCreator bringen, zahlen wir Dir für diese zusätzlich 25% Provision! Das Beste ist, Du erhältst diese 25% Provision auf alle Umsätze Deiner mitgebrachten Follower - also auch auf deren Käufe bei anderen Creatorn!
    </p>
    {image}
    <p>
      Aber auch ohne, dass Du Follower „mitbringst“, kannst Du mit unserer Unterstützung ein erfolgreicher Creator werden. Insbesondere wenn Du bisher keine oder wenig Erfahrung hast, profitierst Du von unseren kostenlosen Webinaren (Trainings und Weiterbildungen). Auf Wunsch erhältst Du außerdem ein begleitendes, individuelles Coaching. Solltest Du trotzdem einmal nicht weiterwissen, freut sich unser Support-Team darauf, Deine Fragen zu beantworten. Sprich uns einfach an und wir unterstützen Dich bestmöglich!
    </p>`,

  PANEL_TITLE_5: `Warum sind so viele Content Creator und Influencer so erfolgreich?`,
  PANEL_SUBTITLE_5: `Ein großer Erfolgsfaktor ist die Gewissheit, dass Du nur für Dich selbst arbeitest. Damit hast Du es selbst in der Hand, wie viel Geld Du im Monat verdienst und kein Chef verdient daran mit. Das ist sehr motivierend und Erfolgsversprechend.`,
  PANEL_CONTENT_5: `
    {image}
    <p>
      Du musst Dich keinem Chef gegenüber rechtfertigen oder Arbeiten ausführen, die Dir keinen Spaß machen. Arbeite nur für Dich und teile Deine Zeit beliebig und frei ein. Auf der LiveCreator Plattform sind jederzeit viele Mitglieder unterwegs, so dass Du nicht an feste Zeiten gebunden bist.
    </p>
    <p>
      Eine ausgeglichene Work-Life-Balance ist in der heutigen Zeit immer wichtiger. Sie steigert Deine Zufriedenheit, innere Ruhe und Dein Wohlbefinden. Und das wiederum sind großartige Voraussetzungen, um erfolgreich zu arbeiten.
    </p>
    <p>
      Soziale Medien und Webcam Plattformen gelten als sehr krisensicher und als Zukunfts-Branche. Das gibt Dir die Sicherheit, langfristig erfolgreich arbeiten und viel Geld verdienen zu können.
    </p>`,

  PANEL_TITLE_6: `Ich habe noch nie vor der Webcam gearbeitet, welche Voraussetzungen muss ich erfüllen?`,
  PANEL_SUBTITLE_6: `Die wichtigste Voraussetzung ist, dass Du kommunikativ bist und gerne mit Menschen sprichst. Du brauchst keine speziellen Erfahrungen oder Vorkenntnisse. Unser Support-Team unterstützt Dich bei den ersten Schritten.`,
  PANEL_CONTENT_6: `
    <p>
      Du wirst sehen, dass Du schnell lernen wirst, wie Du Dich am besten vor der Webcam verhältst. Den größten Erfolg hast Du, wenn Du Dich vor der Webcam einfach so gibst, wie Du wirklich bist. Mach Dir keine Sorgen, falls Du anfangs noch nervös und unsicher bist. Das ist ganz normal und wird sich schnell legen.
    </p>
    {image}
    <p>
      Wir bieten auch kostenlose Webinare (spezielle Trainings und Weiterbildungen) an. Dort erklären wir Dir grundsätzliche Erfolgsfaktoren, z.B. die Einstellungen Deiner Webcam, das richtige Ausleuchten mit Licht, was im Hintergrund zu sehen sein soll und was nicht, wie Du Dich am besten vor der Webcam präsentierst, wie Du ein Gespräch anfängst und auch wie Du Deine Follower besser an Dich binden kannst.
    </p>
    <p>
      Außerdem beantwortet unser Support-Team gerne alle Deine Fragen und gibt nützliche Tipps. Melde Dich gerne, wir freuen uns auf Dich!
    </p>`,

  PANEL_TITLE_7: `Warum sollte ich ein Creator werden?`,
  PANEL_SUBTITLE_7: `Du kannst ohne Vorkenntnisse und ohne Erfahrung sofort anfangen und auch sofort Geld verdienen. Auf LiveCreator kann sich jeder anmelden und Geld verdienen, der mindestens 18 Jahre alt ist.`,
  PANEL_CONTENT_7: `
    <p>
      Die Anmeldung ist kostenfrei und Du kannst Dir schnell ein schönes Profil erstellen. Interessierte Mitglieder werden Follower von Dir und können mit Dir über Dein Profil via Chat und Webcam Kontakt aufnehmen. Und Du wirst für jede erhaltene Message im Chat und jede Minute, die ein Follower/Fan mit Dir in der Webcam verbringt, bezahlt.
    </p>
    <p>
      Vor der Webcam oder auch nur im Chat aktiv zu sein macht viel Spaß, Du kommst mit unterschiedlichsten Menschen in Kontakt, hast eine großartige Zeit und wirst auch noch dafür bezahlt. Du wirst viele Komplimente und Aufmerksamkeit erhalten. Außerdem teilst Du Dir selbst ein, wann und von wo Du arbeiten möchtest. Du bist Deine eigene Chefin und verdienst überdurchschnittlich gut.
    </p>`,

  PANEL_TITLE_8: `Welche Erfahrung brauche ich?`,
  PANEL_SUBTITLE_8: `Um Creator zu werden, benötigst Du keine Erfahrung in diesem Bereich. Du bist kommunikativ und hast gern mit anderen Menschen zu tun? Das ist die beste Voraussetzung, um erfolgreich zu sein.`,
  PANEL_CONTENT_8: `
    <p>
      LiveCreator bietet kostenfreie Seminare und Trainings an, sowohl für Beginner als auch für Fortgeschrittene. Du kannst auch jederzeit mit Deinem persönlichen Support Manager sprechen, wenn Du Fragen hast. Unser Team freut sich, wenn Du mit Fragen oder Anregungen Kontakt aufnimmst.
    </p>
    <p>
      Im Chat und in den Webcam-Sessions machst und zeigst Du auch immer nur so viel, womit Du Dich wohlfühlst. Das gilt auch für Deine Bilder und Videos! Einige Creator zeigen nicht mal ihr Gesicht, bleiben also vollkommen anonym. Andere zeigen ihr Gesicht, aber nicht mehr und wieder andere zeigen auch ihren Körper und geben sich sexy vor der Webcam. Du entscheidest für Dich, wie Du es machen möchtest. Da es für jede Art genügend Fans gibt, wirst Du immer Geld verdienen.
    </p>`,

  PANEL_TITLE_9: `Benötige ich eine spezielle technische Ausstattung?`,
  PANEL_SUBTITLE_9: `Ein Smartphone mit Internetverbindung reicht zum Start. Effizienter arbeiten und Geld verdienen kannst Du aber mit einem Laptop oder Desktop PC. Möchtest Du Dich auch via Cam-Stream und Videos zeigen, wird zumindest eine integrierte Kamera benötigt.`,
  PANEL_CONTENT_9: `
    <p>
      Erfolgreiche Creator, die schon länger dabei sind, kaufen sich teilweise eine bessere Cam und spezielle Beleuchtung für den Livestream oder für Content Shootings. Das ist aber – besonders für den Anfang – nicht notwendig.
    </p>
    <p>
      Hast Du ein Smartphone oder einen Computer? Dann kannst Du jetzt sofort starten und Geld verdienen!
    </p>`,

  PANEL_TITLE_10: `Muss ich wie ein Top-Model aussehen, um Geld zu verdienen?`,
  PANEL_SUBTITLE_10: `Nein! Aussehen spielt eine untergeordnete Rolle. Model Maße oder besonders gutes Aussehen sind unerheblich. Es kommt sehr auf Deine Persönlichkeit an und wie Du mit den Followern/Fans umgehst.`,
  PANEL_CONTENT_10: `
    <p>
      Aufmerksamkeit, Freundlichkeit und Interesse sind den meisten Fans viel wichtiger als das Aussehen.
    </p>
    <p>
      Es gibt so viele Follower auf LiveCreator, die alle nach ganz unterschiedlichen Frauen suchen, so dass garantiert viele Fans nach genau Deinem „Typ Frau“ suchen. Viele Fans finden die „Frau von nebenan“ auch viel nahbarer und interessanter. Sei wie Du bist und Du wirst großen Erfolg haben.
    </p>`,

  PANEL_TITLE_11: `Wie viel Geld kann ich pro Woche verdienen?`,
  PANEL_SUBTITLE_11: `Der Durchschnittsverdienst pro Stunde liegt bei ca. 31 EUR. Das schwankt von Creator zu Creator. Je aktiver Du bist und je mehr Follower/Fans Du Dir aufbaust, desto mehr wirst Du verdienen.`,
  PANEL_CONTENT_11: `
    <p>
      Unsere Top-Creator verdienen über 60 EUR pro Stunde. Häufig sind das die Creator, die sehr aktiv sind und viele Follower/Fans haben. Es dauert erfahrungsgemäß ein paar Wochen, bis man so viel verdienen kann. Aber mit Freundlichkeit, einem netten Umgangston und Fleiß kann man das schnell erreichen.
    </p>`,

  PANEL_TITLE_12: `Muss ich mein Gesicht zeigen oder kann ich anonym bleiben?`,
  PANEL_SUBTITLE_12: `Du entscheidest, was und wie viel Du zeigen möchtest. Die meisten Creator zeigen ihr Gesicht, aber es gibt ebenso erfolgreiche Creator, die ihr Gesicht nicht zeigen und anonym bleiben.`,
  PANEL_CONTENT_12: `
    <p>
      Es ist allein Deine Entscheidung, ob Du Dein Gesicht zeigen möchtest oder nicht. Mach genau das, womit Du Dich wohlfühlst.
    </p>`,

  PANEL_TITLE_13: `Muss ich mich vor der Webcam ausziehen?`,
  PANEL_SUBTITLE_13: `Nein. Du machst und zeigst nur das, womit Du Dich wohlfühlst. Bei LiveCreator geht es in erster Linie darum, mit Deinen Followern/Fans aktiv zu interagieren. Dazu muss sich niemand ausziehen.`,
  PANEL_CONTENT_13: `
    <p>
      Es gibt Creator, die gerne erotische Inhalte und sexy Shows zeigen. Und damit auch sehr erfolgreich sind. Aber es ist Dir überlassen, was Du von Dir zeigen möchtest. Viele Fans wollen einfach nur ein nettes Gespräch führen. Und dafür zahlen sie, ohne das Du mehr von Dir zeigst.
    </p>`,

  PANEL_TITLE_14: `Bin ich an feste Arbeitszeiten oder eine Mindestanzahl Stunden gebunden?`,
  PANEL_SUBTITLE_14: `Nein. Du arbeitest immer dann, wenn Du es möchtest. Es gibt auch keine Anforderung, eine bestimmte Anzahl von Stunden pro Tag oder pro Woche zu arbeiten. Das entscheidest Du!`,
  PANEL_CONTENT_14: `
    <p>
      Natürlich verdienst Du mehr Geld, wenn Du mehr arbeitest. Wir empfehlen Dir einen Plan zu erstellen, zu welchen Zeiten Du arbeiten möchtest. So ein Zeitplan macht es für Dich einfacher und Du hast immer ein gutes Gefühl, wenn Du ihn für Dich selbst eingehalten hast.
    </p>
    <p>
      Gern berät Dich unser Support-Team, wenn Du noch Fragen hast, wie Du Dich am besten organisieren kannst, um besonders erfolgreich zu sein.
    </p>`,

  PANEL_TITLE_15: `Muss ich selbst Fans und Follower auf mein Profil bringen?`,
  PANEL_SUBTITLE_15: `Nein, die Social Media und Community Seiten im LiveCreator Netzwerk haben viele Millionen Mitglieder und wir sorgen dafür, dass diese Dein Profil kennenlernen. Das ist für Dich natürlich kostenfrei.`,
  PANEL_CONTENT_15: `
    <p>
      Wenn Du aber schon Social Media Profile mit vielen Followern hast, kannst Du diese Follower zu Deinem Profil auf LiveCreator schicken. Dafür erhältst Du sogar eine zusätzliche Provision von uns, wenn sie Geld ausgeben. Auch wenn diese User bei anderen Creator Geld ausgeben, erhältst Du von uns Geld. So kannst Du gleich zweimal verdienen! Du erhältst auf alle Umsätze Deiner Follower, die Du neu auf LiveCreator schickst, eine Provision von 25%.
    </p>`,

  PANEL_TITLE_16: `Muss ich meine Einnahmen versteuern?`,
  PANEL_SUBTITLE_16: `Ja, natürlich. Wie bei jeder Arbeit musst Du auch die Provisionen von LiveCreator bei Deinem zuständigen Finanzamt als Einkommen angeben und versteuern.`,
  PANEL_CONTENT_16: `
    <p>
      Wenn Du noch keinen Gewerbeschein besitzt, melde Dich bei Deinem zuständigen Gewerbeamt und bei Deinem Finanzamt. Du erhältst dann alle Unterlagen und erfährst die weiteren Schritte. Die Anmeldung ist ein einmaliger Prozess und es ist nicht viel Arbeit und geht recht schnell.
    </p>
    <p>
      Wir können und dürfen keine steuerliche Beratung anbieten, aber Du kannst Dich an Dein Gewerbe- und Finanzamt wenden. Oder sprich mit einem Steuerberater, der Dir auch Fragen beantworten kann.
    </p>`,

  PANEL_TITLE_17: `Gibt es einen Chef, der mir sagt, wann und wie ich zu arbeiten habe?`,
  PANEL_SUBTITLE_17: `Nein! Du bist Deine eigene Chefin und nur Du bestimmst, wann, wie häufig und wie Du arbeitest. Es ist ein sehr befreiendes Gefühl, wenn man selbst die Chefin ist und nur für sich selbst arbeitet.`,
  PANEL_CONTENT_17: `
    <p>
      Vor allem, was den finanziellen Aspekt angeht, denn Du machst keinen Chef reich, sondern Dich! Wenn Du fleißig und diszipliniert arbeiten kannst, wirst Du ganz bestimmt auf LiveCreator mehr Geld verdienen als bei den meisten anderen Jobs. Und Du bist Deine eigene Chefin!
    </p>`,

  PANEL_TITLE_18: `Fallen Kosten für mich an?`,
  PANEL_SUBTITLE_18: `Nein. Alles was Du auf LiveCreator machst ist kostenfrei. Wir berechnen niemals Gebühren oder Kosten. Es ist also vollkommen risikofrei, wenn Du Dich jetzt bei LiveCreator anmeldest.`,
  PANEL_CONTENT_18: `
    <p>
      Es ist also sehr lohnenswert, denn Du erhältst von LiveCreator eine Provision für alle Umsätze, die Deine Follower bei Dir machen – ohne das Kosten anfallen!
    </p>`,

  PANEL_TOGGLE_MORE: `mehr`,
  PANEL_TOGGLE_LESS: `weniger`,
  CAROUSEL_BUTTON_LABEL: `Erfahre mehr`,
  LANDING_REGISTRATION_BUTTON: `Jetzt kostenlos registrieren`,
};
