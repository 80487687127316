import './Veriff.scss';

import { MESSAGES, createVeriffFrame } from '@veriff/incontext-sdk';
import useAxios from 'axios-hooks';
import { useEffect } from 'react';

function VeriffComponent({
  locale,
  openVeriff,
  toggleVeriffFrame,
  showVeriffReminder,
  toggleVeriffBar,
  veriffError,
  spinner,
}) {
  const [, initVeriff] = useAxios(
    {
      url: '/user/identity/init',
      method: 'POST',
    },
    {
      manual: true,
    }
  );

  useEffect(() => {
    (async () => {
      const el = document.getElementById('veriffFrame');
      if (!el) {
        spinner(true);
        try {
          const response = await initVeriff();
          const url = response?.data?.data?.url;
          spinner(false);
          if (!url) {
            console.error(`missing veriff url`);
          } else {
            createVeriffFrame({
              url,
              onEvent: function (msg) {
                const { CANCELED, FINISHED } = MESSAGES;
                switch (msg) {
                  case CANCELED:
                    if (openVeriff) {
                      toggleVeriffFrame();
                    }
                    break;
                  case FINISHED:
                    if (showVeriffReminder) {
                      toggleVeriffBar();
                    }
                    if (openVeriff) {
                      toggleVeriffFrame();
                    }
                    window.location.reload();
                    break;
                  default:
                    console.log({ casedefault: 'casedefault' });
                }
              },
            });
          }
        } catch (veriffSessionError) {
          spinner(false);
          if (String(veriffSessionError) === 'Cancel') {
            console.log({ veriffSessionError }); // this is not realy an error
          } else {
            if (openVeriff) {
              toggleVeriffFrame();
            }
            veriffError(veriffSessionError);
          }
        }
      }
    })();
  }, [
    locale,
    initVeriff,
    openVeriff,
    toggleVeriffFrame,
    toggleVeriffBar,
    showVeriffReminder,
    veriffError,
    spinner,
  ]);

  return null;
}

export default VeriffComponent;
