import {
  CAM2CAM_EVENT_START,
  CAM2CAM_EVENT_STOP,
  CAM2CAM_ROTATE,
  CAM2CAM_START,
  CAM2CAM_ZOOM,
  DIALOGS_SORT,
  DIALOG_DUPLICATE_NAME,
  DIALOG_PERSIST,
  FRIEND_DELETE,
  FRIEND_FRIEND_REQUEST_ANSWER,
  GAMES_STATS_ADD,
  GAME_INVITATION,
  GAME_INVITATION_STATUS,
  GAME_SESSION_STATUS,
  MANAGEMENT_EVENTS_ADMIN_ADD,
  MANAGEMENT_EVENTS_ADMIN_REMOVE,
  MANAGEMENT_EVENTS_CAM2CAM_DISABLE,
  MANAGEMENT_EVENTS_CAM2CAM_ENABLE,
  MANAGEMENT_EVENTS_CLIENT_DIMENSIONS,
  MANAGEMENT_EVENTS_CONNECTION_DISCONNECT,
  MANAGEMENT_EVENTS_MESSAGE_ADMIN_ADD,
  MANAGEMENT_EVENTS_MESSAGE_USER_ADD,
  MANAGEMENT_EVENTS_STREAM_START,
  MANAGEMENT_EVENTS_STREAM_STOP,
  MANAGEMENT_EVENTS_USER_ADD,
  MANAGEMENT_EVENTS_USER_REMOVE,
  MANAGEMENT_EVENTS_USER_TYPING,
  MANAGEMENT_PRIVATE_AUDIO_SESSION_REQUEST,
  MANAGEMENT_SENDER_TYPING,
  MANAGEMENT_USER_AUDIO_STREAM,
  MANAGEMENT_USER_FAVORITE,
  MESSAGE_CHAT_SEND,
  MESSAGE_RECEIVED,
  MESSAGE_SENT,
  SELECT_USER,
  SENDER_TYPING,
  UPGRADE_START,
  USERAUDIO_EVENT_START,
  USERAUDIO_EVENT_STOP,
  USERAUDIO_START,
  USER_MENU_CLOSE,
  USER_MENU_TOGGLE,
  USER_PROFILE_GET,
  USER_UNIGNORE_ID,
} from '../actions/actions';
import { SORT_BY_TIME_ASC, SORT_BY_TIME_DESC } from '../constants';
import { TYPE_ADMIN } from '../constants/user';
import { arrayUtils, user as userUtils } from '../utils';
import { user } from '../utils';

const initialState = {
  camUsers: [],
  sort: SORT_BY_TIME_DESC,
};

const sortByTimeDesc = (a, b) => {
  if (a.streamingStartTime < b.streamingStartTime) return 1;
  if (a.streamingStartTime > b.streamingStartTime) return -1;
  return 0;
};
const sortByTimeAsc = (a, b) => {
  if (a.streamingStartTime < b.streamingStartTime) return -1;
  if (a.streamingStartTime > b.streamingStartTime) return 1;
  return 0;
};

let sortingMethodsMap = new Map([
  [SORT_BY_TIME_DESC, sortByTimeDesc],
  [SORT_BY_TIME_ASC, sortByTimeAsc],
]);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MANAGEMENT_EVENTS_STREAM_START:
      return {
        ...state,
        camUsers: user
          .add(state.camUsers, action.payload.userId, action.payload)
          .sort(sortingMethodsMap.get(state.sort)),
      };
    case MANAGEMENT_EVENTS_STREAM_STOP: {
      return {
        ...state,
        camUsers: user.remove(state.camUsers, action.payload.userId),
      };
    }
    case MANAGEMENT_EVENTS_CONNECTION_DISCONNECT:
      return initialState;
    case MANAGEMENT_EVENTS_USER_ADD: {
      const type = user.getType(action.payload.origin, action.payload.systemId);
      return {
        ...state,
        camUsers: user
          .show(
            state.camUsers,
            action.payload.userId,
            type,
            action.payload,
            action.meta.dialog
          )
          .sort(sortingMethodsMap.get(state.sort)),
      };
    }
    case USER_UNIGNORE_ID:
      return {
        ...state,
        camUsers: state.camUsers.map((d) => {
          if (d.partner === action.payload) {
            d.flags = d.flags.filter((flag) => flag !== 'ignored');
          }
          return d;
        }),
      };
    case USER_PROFILE_GET:
      return {
        ...state,
        camUsers: state.camUsers.map((user) => {
          if (user.userId === action.payload.userId) {
            user.profile = {
              ...user.profile,
              ...action.payload,
            };
          }
          return user;
        }),
      };
    case MANAGEMENT_EVENTS_USER_REMOVE: {
      return {
        ...state,
        camUsers: user.remove(state.camUsers, action.payload.userId),
      };
    }
    case MANAGEMENT_EVENTS_ADMIN_ADD: {
      action.payload.name = action.payload.adminName;
      return {
        ...state,
        camUsers: user
          .show(
            state.camUsers,
            action.payload.adminSocketId,
            TYPE_ADMIN,
            action.payload,
            action.meta.dialog
          )
          .sort(sortingMethodsMap.get(state.sort)),
      };
    }
    case MANAGEMENT_EVENTS_ADMIN_REMOVE: {
      return {
        ...state,
        camUsers: user.hide(state.camUsers, action.payload.adminSocketId),
      };
    }
    case MANAGEMENT_EVENTS_USER_TYPING:
      return {
        ...state,
        camUsers: user.typing(state.camUsers, action.payload),
      };
    case MANAGEMENT_EVENTS_MESSAGE_ADMIN_ADD:
    case MANAGEMENT_EVENTS_MESSAGE_USER_ADD:
      return {
        ...state,
        camUsers: user.addMessage(state.camUsers, action.payload),
      };
    case MANAGEMENT_SENDER_TYPING:
      return {
        ...state,
        camUsers: user.notifyTyping(state.camUsers, action.payload),
      };
    case SENDER_TYPING: {
      if (action.payload && action.payload.event) {
        return state;
      }
      return {
        ...state,
        camUsers: user.senderTyping(state.camUsers, action.payload),
      };
    }
    case SELECT_USER:
      return {
        ...state,
        camUsers: user.selectUser(
          state.camUsers,
          action.payload.userId,
          action.meta?.text
        ),
      };
    case UPGRADE_START:
      return { ...state, camUsers: user.startUpgrade(state.camUsers, action) };
    case MANAGEMENT_PRIVATE_AUDIO_SESSION_REQUEST:
      return {
        ...state,
        camUsers: userUtils.requestPrivateAudioSession(
          state.camUsers,
          action.payload
        ),
      };
    case USERAUDIO_START:
      if (action.meta && action.meta.start) {
        return {
          ...state,
          camUsers: arrayUtils.updateItem(
            state.camUsers,
            action.payload.userId,
            (user) =>
              Object.assign(user, {
                webRTCConnection: action.payload.webRTCConnection,
              })
          ),
        };
      }
      return state;
    case USERAUDIO_EVENT_START:
      if (action.error) {
        return {
          ...state,
          camUsers: userUtils.disablePrivateAudioSession(
            state.camUsers,
            action.payload.userId
          ),
        };
      }
      return {
        ...state,
        camUsers: userUtils.enablePrivateAudioSession(
          state.camUsers,
          action.payload.userId
        ),
      };
    case USERAUDIO_EVENT_STOP:
      return {
        ...state,
        camUsers: userUtils.disablePrivateAudioSession(
          state.camUsers,
          action.payload.userId
        ),
      };
    case MANAGEMENT_EVENTS_CAM2CAM_ENABLE:
      return {
        ...state,
        camUsers: userUtils.enableCam2Cam(
          state.camUsers,
          action.payload.userId
        ),
      };
    case MANAGEMENT_EVENTS_CAM2CAM_DISABLE:
      return {
        ...state,
        camUsers: userUtils.disableCam2Cam(
          state.camUsers,
          action.payload.userId
        ),
      };
    case CAM2CAM_START:
      if (action.meta && action.meta.start) {
        return {
          ...state,
          camUsers: arrayUtils.updateItem(
            state.camUsers,
            action.payload.userId,
            (user) =>
              Object.assign(user, {
                cam2camConnection: action.payload.cam2camConnection,
              })
          ),
        };
      }
      return state;
    case CAM2CAM_EVENT_START:
      return {
        ...state,
        camUsers: userUtils.startCam2Cam(state.camUsers, action.payload.userId),
      };
    case CAM2CAM_EVENT_STOP:
      return {
        ...state,
        camUsers: userUtils.stopCam2Cam(state.camUsers, action.payload.userId),
      };
    case MANAGEMENT_EVENTS_CLIENT_DIMENSIONS:
      return {
        ...state,
        camUsers: arrayUtils.updateItem(
          state.camUsers,
          action.payload.userId,
          (user) => {
            const { windowHeight, windowWidth, resizedHeight, resizedWidth } =
              action.payload;
            return Object.assign(user, {
              dimensions: {
                percentHeight: windowHeight / resizedHeight,
                percentWidth: windowWidth / resizedWidth,
              },
            });
          }
        ),
      };
    case USER_MENU_TOGGLE:
      return {
        ...state,
        camUsers: state.camUsers.map((user) => {
          if (user.userId === action.payload) {
            user.menuOpen = user.menuOpen ? !user.menuOpen : true;
          } else {
            user.menuOpen = false;
          }
          return user;
        }),
      };
    case USER_MENU_CLOSE:
      return {
        ...state,
        camUsers: state.camUsers.map((user) => {
          user.menuOpen = false;
          return user;
        }),
      };
    case CAM2CAM_ROTATE:
      return {
        ...state,
        camUsers: state.camUsers.map((user) => {
          if (user.userId === action.payload.userId) {
            user.cam2camRotate = user.cam2camRotate || 0;
            user.cam2camRotate += 90;
            if (user.cam2camRotate === 360) {
              user.cam2camRotate = 0;
            }
          }
          return user;
        }),
      };
    case CAM2CAM_ZOOM:
      return {
        ...state,
        camUsers: state.camUsers.map((user) => {
          if (user.userId === action.payload.userId) {
            user.cam2camZoom = !user.cam2camZoom;
          }
          return user;
        }),
      };
    case MANAGEMENT_USER_AUDIO_STREAM:
      return {
        ...state,
        camUsers: state.camUsers.map((user) => {
          if (user.userId === action.payload.socketId) {
            user.audioStream = action.payload.audioStream === 1;
          }
          return user;
        }),
      };
    case MESSAGE_SENT:
      if (action?.meta?.selectedLivecamUser && action?.meta?.selectedUser) {
        return {
          ...state,
          camUsers: state.camUsers.map((u) => {
            if (u.userId === action?.meta?.selectedUser) {
              u.text = '';
            }
            return u;
          }),
        };
      }
      return state;
    case MANAGEMENT_USER_FAVORITE:
      if (action.error) {
        return {
          ...state,
          camUsers: state.camUsers.map((d) => {
            const actionUserId = action?.payload.clientProductId
              ? `${action?.payload.clientProductId}@${action?.payload.clientCustomerId}`
              : action.payload.userId.replace('-', '@');
            const dialogUserId = `${d.clientProductId}@${d.clientCustomerId}`;
            if (actionUserId === dialogUserId) {
              d.requestStarted = false;
            }
            return d;
          }),
        };
      }

      if (action.meta.start) {
        return {
          ...state,
          camUsers: state.camUsers.map((d) => {
            const actionUserId = action?.payload.clientProductId
              ? `${action?.payload.clientProductId}@${action?.payload.clientCustomerId}`
              : action.payload.userId.replace('-', '@');
            const dialogUserId = `${d.clientProductId}@${d.clientCustomerId}`;
            if (actionUserId === dialogUserId) {
              d.requestStarted = true;
            }
            return d;
          }),
        };
      }

      return {
        ...state,
        camUsers: state.camUsers.map((d) => {
          const actionUserId = action?.payload.clientProductId
            ? `${action?.payload.clientProductId}@${action?.payload.clientCustomerId}`
            : action.payload.userId.replace('-', '@');
          const dialogUserId = `${d.clientProductId}@${d.clientCustomerId}`;
          if (actionUserId === dialogUserId) {
            d.text = '';
            if (action.meta.remove) {
              d.flags = [...d.flags.filter((flag) => flag !== 'favorite')];
            } else {
              d.flags = [...d.flags, 'favorite'];
            }
            d.requestStarted = false;
          }
          return d;
        }),
      };
    case FRIEND_FRIEND_REQUEST_ANSWER:
      if (
        action.meta?.start ||
        action.payload?.friendRequestType === 'denied'
      ) {
        return state;
      }
      // Update dialog flags here
      return {
        ...state,
        camUsers: state.camUsers.map((d) => {
          if (
            `${d.clientProductId}@${d.clientCustomerId}` ===
              action?.payload.userId ||
            `${d.clientProductId}@${d.clientCustomerId}` ===
              action?.payload.userId.replace('-', '@')
          ) {
            d.text = '';
            d.flags = [...d.flags, 'friend'];
          }
          return d;
        }),
      };
    case FRIEND_DELETE:
      if (action.meta) {
        return state;
      }
      return {
        ...state,
        camUsers: state.camUsers.map((d) => {
          if (
            `${d.clientProductId}@${d.clientCustomerId}` ===
              action?.payload.id ||
            `${d.clientProductId}@${d.clientCustomerId}` ===
              action?.payload.id.replace('-', '@')
          ) {
            d.flags = [...d.flags.filter((flag) => flag !== 'friend')];
          }
          return d;
        }),
      };
    case DIALOGS_SORT:
      const sortingMethod = sortingMethodsMap.get(action.payload);
      return {
        ...state,
        sort: action.payload,
        camUsers: state.camUsers.slice().sort(sortingMethod),
      };
    case DIALOG_DUPLICATE_NAME:
      const index = state.camUsers.findIndex(
        (d) => d.name?.toLowerCase() === action.payload.name?.toLowerCase()
      );
      if (index !== -1) {
        let updatedCamUsers = [...state.camUsers];
        updatedCamUsers[index].duplicateName = true;
        return {
          ...state,
          camUsers: updatedCamUsers,
        };
      }
      return state;
    case MESSAGE_RECEIVED: {
      if (
        action.payload?.payload?.lc_id &&
        action?.meta?.selectedUser !== action.payload?.userId &&
        action.payload?.done &&
        !action.payload?.evtid.match(/\|-\|/)
      ) {
        return {
          ...state,
          camUsers: state.camUsers.map((u) => {
            if (u.userId === action.payload.userId) {
              u.newMessage = true;
              u.hasText = true;
            }
            return u;
          }),
        };
      }
      if (
        action.payload?.payload?.lc_id &&
        action?.meta?.selectedUser === action.payload?.userId &&
        action.payload?.done &&
        !action.payload?.evtid.match(/\|-\|/)
      ) {
        return {
          ...state,
          camUsers: state.camUsers.map((u) => {
            if (u.userId === action.payload.userId) {
              u.hasText = true;
            }
            return u;
          }),
        };
      }

      return state;
    }
    case GAME_INVITATION: {
      if (!action?.payload?.fromId) {
        return state;
      }
      const id = action.payload.partner;
      return {
        ...state,
        camUsers: state.camUsers.map((u) => {
          const hasPartner = !!userUtils.hasPartner(u);
          if (!hasPartner) return u;

          const partner = userUtils.getPartner(u);
          if (partner === id && !u.flags.includes('gameInvitation')) {
            u.flags = u.flags.filter((el) => el !== 'gameDecided');
            u.flags.push('gameInvitation');
          }
          return u;
        }),
      };
    }
    case GAME_INVITATION_STATUS: {
      if (!action?.payload?.partner) {
        return state;
      }
      const id = action.payload.partner;
      if (['declined', 'timedOut'].includes(action?.payload?.status)) {
        return {
          ...state,
          camUsers: state.camUsers.map((u) => {
            const hasPartner = !!userUtils.hasPartner(u);
            if (!hasPartner) return u;

            const partner = userUtils.getPartner(u);
            if (partner === id && u.flags.includes('gameInvitation')) {
              u.flags = u.flags.filter(
                (el) => el !== 'gameInvitation' && el !== 'gameDecided'
              );
            }
            return u;
          }),
        };
      }
      return state;
    }
    case GAME_SESSION_STATUS: {
      if (!action?.payload?.partner || !action?.payload?.session) return state;

      const { status, turnId } = action.payload.session;

      const id = action.payload.partner;

      switch (status) {
        case 'decided':
          return {
            ...state,
            camUsers: state.camUsers.map((u) => {
              const hasPartner = !!userUtils.hasPartner(u);
              if (!hasPartner) return u;

              const partner = userUtils.getPartner(u);
              if (partner === id && u.flags.includes('game')) {
                u.flags = u.flags.filter(
                  (el) => !['game', 'gameTurn'].includes(el)
                );
                u.flags.push('gameDecided');
              }
              return u;
            }),
          };
        case 'pending': {
          return {
            ...state,
            camUsers: state.camUsers.map((u) => {
              const partner = userUtils.getPartner(u);
              if (partner === id) {
                u.flags = u.flags.filter(
                  (el) => el !== 'gameInvitation' && el !== 'gameDecided'
                );
                if (!u.flags.includes('game')) {
                  u.flags.push('game');
                }
                if (turnId.replace('-', '@') !== partner) {
                  u.flags.push('gameTurn');
                } else if (turnId.replace('-', '@') === partner) {
                  u.flags = u.flags.filter((el) => el !== 'gameTurn');
                }
              }
              return u;
            }),
          };
        }
        default:
          return state;
      }
    }
    case GAMES_STATS_ADD:
      return {
        ...state,
        camUsers: state.camUsers.map((u) => {
          const hasPartner = !!userUtils.hasPartner(u);
          if (!hasPartner) return u;

          const partner = userUtils.getPartner(u);

          if (partner === action.meta.userId) {
            u.gameStats = { ...action.payload };
          }
          return u;
        }),
      };
    case DIALOG_PERSIST:
      return {
        ...state,
        camUsers: state.camUsers.map((u) => {
          const hasPartner = !!userUtils.hasPartner(u);
          if (!hasPartner) return u;

          const partner = userUtils.getPartner(u);

          if (partner === action.payload) {
            u.persisted = true;
          }
          return u;
        }),
      };
    case MESSAGE_CHAT_SEND: {
      if (action.meta?.selectedCamUserId?.includes('@')) return state;
      return {
        ...state,
        camUsers: state.camUsers.map((u) => {
          if (u.userId === action.meta.selectedCamUserId) {
            if (!u.flags) {
              u.flags = [];
            }
            u.flags.push('answered');
          }
          return u;
        }),
      };
    }
    default:
      return state;
  }
};

export default reducer;
