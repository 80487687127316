import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import exclusiveSVG from '../../../../assets/img/svg/exclusive.svg';

const Voyeur = ({
  voyeurCount,
  livecamCount,
  hasVoyeur,
  isExclusive,
  text = '',
  headline = '',
}) => {
  const intl = useIntl();
  const classes = classNames('livecam-header', {
    'has-voyeur': voyeurCount > 0,
  });
  const classesExclusive = classNames('cam-icon', 'icon-exclusive', {
    active: isExclusive,
  });
  const tooltipExclusiveShow = intl.formatMessage({
    id: 'tooltip.exclusiveShow',
  });
  return (
    <div className={classes}>
      <span className="livecam-title">
        {headline}{' '}
        {isExclusive && (
          <div className="icon-wrapper" title={tooltipExclusiveShow}>
            <ReactSVG
              src={exclusiveSVG}
              className={classesExclusive}
              wrapper={'span'}
            />
          </div>
        )}
        {!!livecamCount && !isExclusive && (
          <div className="additional-number">{livecamCount}</div>
        )}
      </span>
      {hasVoyeur ? (
        <span className="voyeur-title">
          {text}
          <span> {voyeurCount}</span>
        </span>
      ) : null}
    </div>
  );
};

export default Voyeur;
