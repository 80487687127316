import {
  MANAGEMENT_CONNECT,
  MOTTO_CANCEL,
  MOTTO_SET,
  STREAM_STOP,
  USER_LOGOUT,
  USER_LOGOUT_FORCED,
} from '../actions/actions';
import { lStorage } from '../services/storage';

const initialState = {
  text: '',
  reconnecting: false,
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case MOTTO_CANCEL:
      return {
        ...state,
        text: '',
      };
    case MOTTO_SET: {
      if (action.payload === '') lStorage.removeItem('motto');
      if (action.payload)
        lStorage.setItem(
          'motto',
          JSON.stringify({ time: Date.now(), text: action.payload })
        );

      return {
        ...state,
        text: action.payload || '',
      };
    }
    case STREAM_STOP: {
      if (!(action.meta.isReconnecting || action.meta.silentReconnect)) {
        return initialState;
      }
      return state;
    }
    case MANAGEMENT_CONNECT: {
      if (action.error) {
        return initialState;
      }
      return state;
    }
    case USER_LOGOUT:
    case USER_LOGOUT_FORCED:
      return initialState;
    default:
      return state;
  }
};

export default reduce;
