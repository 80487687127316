export default {
  GAME_TURN_WAIT: 'Waiting...',
  GAME_TURN_YOUR_TURN: `It's your turn`,
  GAME_TIME_LEFT: `Remaining time: `,
  GAMESTATS_GAMES: 'Games',
  GAMESTATS_GAME: 'Game',
  GAMESTATS_WINS: 'Wins',
  GAMESTATS_WIN: 'Win',
  GAMESTATS_RANK: 'Ranking',
  GAME_WON: 'You win!',
  GAME_WON_TIMEOUT: 'You win! (Timeout)',
  GAME_LOST: 'You lose!',
  GAME_LOST_TIMEOUT: 'You lose! (Timeout)',
  GAME_DRAW: 'Draw!',
  ROUTE_GAME4SCORE_HEADLINE: 'Look at your lastest games',
  ROUTE_GAME4SCORE_TITEL: 'Game 4Score',
  GAME_WON_OR_LOST: 'Won or lost?',
  GAME_INVITATION_TIMEOUT_REFUND:
    'The invitations timed out, the coins were refunded!',
  GAME_MATCH_WINNER: 'You are the winner of this match!',
  GAME_MATCH_WINNER_TIMEOUT: 'You won the game due to a timeout!',
  GAME_MATCH_LOSER: 'You are the loser of this match!',
  GAME_MATCH_TIMEOUT_REFUND:
    'You lost the game due to a timeout, the coins were refunded!',
  GAME_MATCH_DRAW: 'The game ended in a draw!',
  GAME_MATCH_FROM: 'Match from',
  GAME_INVITATION_FROM: 'Invitation from',
  GAME_MATCH_WITH: 'with',
};
