import { gameUtils, user as userUtils, uuid } from '../../utils';
import api from '../../utils/api';
import { getPortal } from '../../utils/portals';
import { errorLog } from '../';
import { NEW_DIALOG } from '../actions';
import { duplicateName } from '.';

export default ({
    id,
    name: nameParam,
    time: timeParam = Math.floor(Date.now() / 1000),
    hasMessageReceived: hasMessageReceivedParam = false,
    done = true,
    noRemoteDialog = false,
    isSupport = false,
    userInfo,
  }) =>
  async (dispatch, getState) => {
    if (!getState().user?.account?.id) {
      return;
    }

    const {
      camUser: { camUsers },
      dialog: { dialogs },
    } = getState();
    let time = timeParam;
    let hasMessageReceived = hasMessageReceivedParam;
    let flags = hasMessageReceived ? ['unanswered'] : [];
    let read = !hasMessageReceived;
    let name = nameParam;

    if (isSupport) {
      flags.push('is_support');
    }

    try {
      if (!name) {
        const response = await api.get(`/profile/${id}/minimal`, {
          skipAttachingAuthToken: true,
        });
        name = userUtils.createName(response.data.info.nickname, flags);
      }

      if (!name) {
        throw new Error(`call to retrieve profile failed for userId: ${id}`);
      }

      const profileMinimal = {
        info: {
          nickname: name,
          largeLoaded: false,
        },
        turnover: {},
      };
      const hasLivecamUser = userUtils.hasLivecamUser(id, camUsers);
      const dialogProductIdArray = id?.split('@');
      const productId =
        dialogProductIdArray?.length === 2
          ? parseInt(dialogProductIdArray[0])
          : null;
      if (productId) {
        try {
          const portals = await getPortal(productId);
          profileMinimal.info.whitelabelLong = portals.whitelabelLong;
          profileMinimal.info.whitelabelShort = portals.whitelabelShort;
        } catch (err) {
          console.log('portal not found');
        }
      }
      const newDialog = {
        type: 'dialog.fetched',
        isDialogUser: true,
        productId,
        partner: id,
        time,
        read,
        flags,
        profile: profileMinimal,
        evtid: '',
        text: '',
        active: false,
        id,
        userId: id,
        name,
        visible: !hasLivecamUser,
        newMessage: hasMessageReceived,
        done,
        newInList: hasMessageReceived,
      };
      if (noRemoteDialog) {
        newDialog.flags.push('noRemoteDialog');
      }

      if (productId) {
        newDialog.portals = await getPortal(productId);
      }
      if (getState().ignored.ignoredIds.includes(id)) {
        newDialog.flags.push('ignored');
      }
      if (getState().friends.ids.includes(id)) {
        newDialog.flags.push('friend');
      }
      if (getState().favorites.ids.includes(id)) {
        newDialog.flags.push('favorite');
      }
      if (getState().onlineStatus.online.includes(id)) {
        newDialog.flags.push('online');
      }
      if (!userUtils.gamesBlocked(getState())) {
        const game = gameUtils.getGame(getState(), id);
        if (gameUtils.isActiveInvitation(game)) {
          newDialog.flags.push('gameInvitation');
        }
        if (gameUtils.isActiveGame(game)) {
          newDialog.flags.push('game');
        }
        if (gameUtils.isActiveTurn(game)) {
          newDialog.flags.push('gameTurn');
        }
        if (gameUtils.isDecidedGame(game)) {
          newDialog.flags.push('gameDecided');
        }
      }
      const combinedList = [...camUsers, ...dialogs];

      const duplicateIndex = combinedList.findIndex(
        (item) => item.name?.toLowerCase() === newDialog.name?.toLowerCase()
      );

      if (duplicateIndex !== -1) {
        newDialog.duplicateName = true;
        dispatch(duplicateName(name));
      }

      dispatch({
        type: NEW_DIALOG,
        payload: newDialog,
      });
    } catch (error) {
      if (!hasMessageReceived) {
        dispatch({
          type: NEW_DIALOG,
          error: true,
          meta: { stamp: uuid() },
        });
      }

      const errorObject = {
        location: 'newDialog',
        error,
      };

      if (userInfo) {
        errorObject.data = userInfo;
      }

      dispatch(errorLog(errorObject));
      console.log({ getProfileError: error });
    }
  };

