import api from '../../utils/api';
import userUtils from '../../utils/user';
import { errorLog } from '../';
import { USER_PROFILE_GET } from '../actions';
import {
  getIsIgnored,
  getPersonalUserProfile,
  getTurnoverUserProfile,
} from '.';

export default function (user) {
  return async (dispatch, getState) => {
    if (!getState().user?.isLoggedIn) {
      return;
    }
    let userId = user.userId;
    let partner;
    if (user?.partner) {
      partner = user?.partner;
    } else if (userUtils.hasPartner(user)) {
      partner = userUtils.getPartner(user);
    } else {
      return;
    }
    try {
      const profileResponse = await api.get(`/profile/${partner}`);
      const profile = profileResponse.data;
      profile.info.largeLoaded = true;
      if (user?.profile?.info) {
        profile.info.nickname = user.profile.info.nickname;
        profile.info.whitelabelLong = user.profile.info.whitelabelLong;
        profile.info.whitelabelShort = user.profile.info.whitelabelShort;
      }
      dispatch({
        type: USER_PROFILE_GET,
        payload: { userId, ...profile },
      });

      dispatch(getPersonalUserProfile(user));
      dispatch(getTurnoverUserProfile(user));
      dispatch(getIsIgnored(user));
    } catch (error) {
      const errorObject = {
        location: 'getUserProfile',
        error,
      };
      dispatch(errorLog(errorObject));

      console.error(error);
    }
  };
}
