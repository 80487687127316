import './FinishedComponent.scss';

import React from 'react';
import { useIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';

function FinishedComponent({ user = {}, isMobile }) {
  const intl = useIntl();
  return (
    <>
      {isMobile ? (
        <>
          <div className="intro-text">
            <h1 className="headline">
              {intl.formatMessage({ id: 'ONBOARDING_TITLE' })}
            </h1>
            <p>
              {intl.formatMessage({
                id: 'ONBOARDING_HEADLINE_PENDING_1',
              })}
            </p>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'ONBOARDING_HEADLINE_PENDING_2',
                  }),
                }}
              />
            </p>
          </div>
        </>
      ) : null}

      <div className="content-inner finalise-step">
        <div className="finalise-text">
          <h1 className="step-headline">
            <FormattedMessage id="FINISHED_HEADLINE" />
          </h1>
          <p>
            <FormattedMessage id="FINISHED_TEXT_1" />
          </p>
          <p>
            <FormattedMessage id="FINISHED_TEXT_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
        <div className="finalise-img">
          <div className="img" />
        </div>
      </div>
    </>
  );
}

export default FinishedComponent;

