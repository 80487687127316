import './GalleryRow.scss';

import React from 'react';

import Badge from '../../Badge/Badge';
import DeclinedFmotd from '../../DeclinedFmotd/DeclinedFmotd';
import Menu from '../../Menu';
import Preview from '../../Preview/Preview';

function GalleryRow({ item, editFmotd, deleteFmotd }) {
  const state = item?.state;

  return (
    <div className="fmotd-gallery-row">
      <Preview item={item} />
      <div className="content">
        <div className="title">{item?.title?.content}</div>
        {state === 'declined' ? (
          <DeclinedFmotd item={item} />
        ) : (
          <div className="state">
            <Badge item={item} />
          </div>
        )}
      </div>
      {state === 'applied' || state === 'declined' ? (
        <Menu item={item} editFmotd={editFmotd} deleteFmotd={deleteFmotd} />
      ) : null}
    </div>
  );
}

export default GalleryRow;
