import { connect } from 'react-redux';

import { user as userUtils } from '../../../../utils';
import Stats from './Stats';

const mapStateToProps = (state) => {
  const user = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);

  if (!user || userUtils.isAdmin(user)) {
    return {
      noUser: true,
    };
  }

  return {
    wins: user.gameStats?.wins,
    games: user.gameStats?.games,
    rank: user.gameStats?.rank,
  };
};

export default connect(mapStateToProps)(Stats);
