import { SOCKET_SERVICE_STEP_CONNECTED } from '../../../constants';
import { CONNECT_SOCKET_SERVICE } from '../../actions';
import {
  fetchAllDialog,
  getCapacityUser,
  socketService,
  updateSales,
} from '../..';

export default () => (dispatch) => {
  dispatch({
    type: CONNECT_SOCKET_SERVICE,
    payload: {
      step: SOCKET_SERVICE_STEP_CONNECTED,
    },
  });

  dispatch(updateSales());
  dispatch(fetchAllDialog());
  dispatch(socketService.salesFetchCurrent());
  dispatch(getCapacityUser());
  console.debug({ socketServiceConnect: true });
};
