const store = (storage) => {
  return {
    clear: () => {
      try {
        return window[storage].clear();
      } catch (error) {
        console.log(
          `${storage}.clear() not working with error ${error.message}`
        );
      }
    },
    setItem: (key, value) => {
      try {
        return window[storage].setItem(key, value);
      } catch (error) {
        console.log(
          `${storage}.setItem() not working with error ${error.message}`
        );
      }
    },
    removeItem: (key) => {
      try {
        return window[storage].removeItem(key);
      } catch (error) {
        console.log(
          `${storage}.removeItem() not working with error ${error.message}`
        );
      }
    },
    getItem: (key) => {
      try {
        return window[storage].getItem(key);
      } catch (error) {
        console.log(
          `${storage}.getItem() not working with error ${error.message}`
        );
      }
    },
    test: () => {
      try {
        const value = 'wss_test_value';
        window[storage].setItem('wss_test', value);
        const result = window[storage].getItem('wss_test');
        if (result !== value) {
          return false;
        }
        window[storage].removeItem('wss_test');
        return true;
      } catch (error) {
        console.log(
          `${storage}.test() not working with error ${error.message}`
        );
        return false;
      }
    },
  };
};

export const lStorage = store('localStorage');
export const sStorage = store('sessionStorage');
