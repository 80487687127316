import SockJS from 'sockjs-client';

import { ratatoskr } from '../actions';

let socket = null;

const init = (url, dispatch) => {
  socket = SockJS(url);
  const { error, open, close, message } = ratatoskr.event;
  socket.onerror = (err) => dispatch(error(err));
  socket.onopen = () => dispatch(open());
  socket.onclose = () => dispatch(close());
  socket.onmessage = (data) => dispatch(message(data));
};

const send = (data) => {
  if (socket !== null) {
    socket.send(JSON.stringify(data));
  }
};

const close = () => {
  if (socket !== null) {
    socket.close();
  }
};

const connected = () => {
  if (socket !== null) {
    return socket.connected;
  }
  return false;
};

export default {
  send,
  init,
  close,
  connected,
};
