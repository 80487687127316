import {
  SELECT_USER,
  USER_LOGOUT,
  VOICEMESSAGE_LOADING,
  VOICEMESSAGE_PLAY,
  VOICEMESSAGE_STOP,
  VOICEMESSAGE_TEXT,
} from '../actions/actions';

const initialState = {
  playing: null,
  texts: {},
  loading: false,
};

const voicemessage = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_USER:
      return {
        ...state,
        playing: null,
      };
    case VOICEMESSAGE_PLAY:
      return {
        ...state,
        playing: action.payload,
      };
    case VOICEMESSAGE_STOP:
      if (action.payload === state.playing)
        return {
          ...state,
          playing: null,
        };
      return state;
    case VOICEMESSAGE_TEXT:
      return {
        ...state,
        texts: {
          ...state.texts,
          [action.payload.id]: action.payload.text,
        },
      };
    case USER_LOGOUT:
      return initialState;
    case VOICEMESSAGE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default voicemessage;
