import { debounce } from 'lodash';

import { functionLog } from '../';
import { CAMUSER_FILTER } from '../actions';

const log = debounce((dispatch, data) => {
  dispatch(functionLog({ function: 'filter', data }));
}, 1000);
export default function (filter) {
  return (dispatch) => {
    log(dispatch, filter);
    dispatch({
      type: CAMUSER_FILTER,
      payload: filter,
    });
  };
}
