import '../MyContent/MyContent.scss';

import { Formik } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import Scrollbar from '../../Scrollbar/Scrollbar';
import { getValidationScheme } from './Diary.functions';
import DiaryInfo from './DiaryInfo';
import EditDiary from './EditDiary';

function Diary({ items, initialValues }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const history = useHistory();
  const routes = items.map((route, i) => {
    return (
      <Route
        key={`/my-content/diary${route.path}`}
        path={`/my-content/diary${route.path}`}
        component={route.component}
      />
    );
  });

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-content-wrapper diary-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          {history.location.pathname.includes('/diary/edit') &&
          !isLargeScreen ? (
            <Switch>
              {routes}
              <Redirect to="/" />
            </Switch>
          ) : (
            <div className="data-wrapper">
              <Switch>
                {routes}
                <Redirect to="/" />
              </Switch>
            </div>
          )}
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            {history.location.pathname.includes('my-diary') && <DiaryInfo />}
            {history.location.pathname.includes('edit') && (
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={getValidationScheme(intl)}
              >
                <EditDiary />
              </Formik>
            )}
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Diary;

