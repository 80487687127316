import {
  SOCKET_SERVICE_OPTIONS,
  SOCKET_SERVICE_STEP_STARTED,
  SOCKET_SERVICE_URL,
} from '../../constants';
import { CONNECT_SOCKET_SERVICE } from '../actions';

export default (socketService) => () => (dispatch, getState) => {
  const {
    user: { access_token },
  } = getState();

  const options = {
    ...SOCKET_SERVICE_OPTIONS,
    query: {
      fetchAll: false,
      token: access_token,
    },
  };

  socketService.init(SOCKET_SERVICE_URL, options, dispatch);

  dispatch({
    type: CONNECT_SOCKET_SERVICE,
    payload: {
      step: SOCKET_SERVICE_STEP_STARTED,
    },
  });
};
