import soundOffIcon from '../../../../../assets/img/svg/sound-off.svg';
import soundOnIcon from '../../../../../assets/img/svg/sound-on.svg';
import Button from '../../../../Button/Button';

function MuteButton({
  muteMicrophone,
  microphoneEnabled,
  microphoneMuted,
  muteRef,
}) {
  const icon = microphoneMuted ? soundOffIcon : soundOnIcon;

  return microphoneEnabled ? (
    <Button
      innerRef={muteRef}
      icon={icon}
      onClick={() => muteMicrophone(!microphoneMuted)}
      intlTranslate={false}
      variant="icon-only"
      className="btn-mute"
    />
  ) : null;
}

export default MuteButton;
