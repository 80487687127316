export default {
  MULTI_MESSENGER_HEADLINE: `Das Hauptmenü des Multi Messenger`,
  MULTI_MESSENGER_INTRO: `Mit dem Multi Messenger von LiveCreator hast Du stets die volle Übersicht und managst spielend einfach und zeitgleich alle Deine Chats, Kunden und Cam-Shows.`,
  MULTI_MESSENGER_INTRO_1: `Verpasse keine Nachrichten, keine Neu- oder Stammkunden!<br />
  Behalte immer alles im Blick – zu jeder Zeit an jedem Ort – sogar auf Deinem Smartphone.<br />
  Arbeite Deine Chats in kürzester Zeit ab - egal, ob Du eine Cam Show machst oder nur chattest!`,
  MULTI_MESSENGER_INTRO_2: `Der Aufbau des Multi Messengers Menüs ist logisch und intuitiv:`,
  MULTI_MESSENGER_INTRO_3: `In Deinem Messenger sind Deine aktiven Chats unter "Alle" zu sehen. Wir haben sie für Dich in die Filter "Ungelesen", "Unbeantwortet" und "Neue User" unterteilt, damit Du schnell erkennen kannst, welche Chats unbearbeitet sind.<br /> Deine gesetzten Favoriten und Freunde erscheinen zusätzlich in den entsprechenden Filtern.
  Die Filter gelten für die aktuell geladenen Chats. Neu eintreffende Chats werden automatisch kategorisiert, je nachdem welcher Filter zutrifft. Ältere Kontakte findest Du im Menüpunkt "Beziehungen", in der entsprechenden Kategorie Freunde oder Favoriten.`,

  MULTI_MESSENGER_HEADLINE_1: `Die Nachrichten-Filter`,

  MULTI_MESSENGER_FILTER_STEP_1: `Alle`,
  MULTI_MESSENGER_FILTER_STEP_1_CONTENT: `Hier siehst Du Deine bisherigen Konversationen. Mit diesem Filter behältst Du stets den Überblick über all Deine Chats und kannst ohne Umwege zwischen ihnen wechseln.`,
  MULTI_MESSENGER_FILTER_STEP_1_1_CONTENT: `<span class="bold">Tipp:</span> Nutze die Pfeiltasten auf Deiner Tastatur, um noch schneller zu navigieren! Dadurch sparst Du wertvolle Zeit und kannst Dich voll und ganz auf Deine Gespräche konzentrieren.`,

  MULTI_MESSENGER_FILTER_STEP_2: `Ungelesen`,
  MULTI_MESSENGER_FILTER_STEP_2_CONTENT: `Ungelesene Nachrichten sind solche, die Du empfangen, aber noch nicht gelesen hast. Es ist wichtig, dass Du Deine ungelesenen Nachrichten im Blick behältst, um sicherzustellen, dass Du immer auf dem neuesten Stand bist und Deine User nicht warten lässt. Indem Du schnell antwortest, zeigst Du ihnen, dass Du ihre Gespräche ernst nimmst und ihnen volle Aufmerksamkeit schenkst.`,
  MULTI_MESSENGER_FILTER_STEP_2_1_CONTENT: `Bedenke, dass ein Chat nur erfolgreich ist, wenn beide Seiten aktiv daran teilnehmen. Wenn eine Nachricht zu lange ungelesen bleibt, stört dies den Fluss der Konversation und kann dazu führen, dass sich Deine User von Dir abwenden. Zögere also nicht, Deine ungelesenen Nachrichten zu öffnen und zu beantworten.`,

  MULTI_MESSENGER_FILTER_STEP_3: `Unbeantwortet`,
  MULTI_MESSENGER_FILTER_STEP_3_CONTENT: `Unbeantwortete Nachrichten sind solche, die Du bereits gelesen, aber noch nicht beantwortet hast. Es ist wichtig, dass Du nicht nur Nachrichten empfängst, sondern auch darauf antwortest. Nur so kannst Du den Chat am Laufen halten und eine gute Kommunikation aufrechterhalten. Die User, die Dir geschrieben haben, warten darauf, dass Du ihnen antwortest, und sind gespannt auf Deine Reaktion. Indem Du ihnen antwortest, zeigst Du ihnen, dass Du ihre Nachrichten gelesen hast und dass sie Dir wichtig sind. `,
  MULTI_MESSENGER_FILTER_STEP_3_1_CONTENT: `Es gibt gute Gründe, warum Nachrichten manchmal unbeantwortet bleiben, vielleicht hattest Du einfach keine Zeit, um zu antworten, oder Du hast die Nachricht vergessen. Aber es ist wichtig, dass Du Dir Zeit nimmst, um auf die Nachrichten zu antworten, da dies eine Möglichkeit ist, um eine Verbindung aufrechtzuerhalten.`,

  MULTI_MESSENGER_FILTER_STEP_4: `Neue User`,
  MULTI_MESSENGER_FILTER_STEP_4_CONTENT: `Wenn Du online bist und Nachrichten von Usern bekommst, knüpfst Du neue Kontakte und gewinnst potenzielle Kunden. Deshalb zeigen wir Dir in diesem Menüpunkt die Chats, in denen User Dir das erste Mal nach einem versendeten Icebreaker schreiben. Es ist wichtig, schnell zu handeln, damit der User nicht enttäuscht wird. Indem Du ihm sofort antwortest, kannst Du ihn an Dich binden und einen neuen Stammkunden gewinnen.`,

  MULTI_MESSENGER_FILTER_STEP_5: `Favoriten`,
  MULTI_MESSENGER_FILTER_STEP_5_CONTENT: `Dieser Menüpunkt ist eine großartige Möglichkeit, um Deine Chats zu organisieren und Deine Stammkunden zu markieren. Du hast die volle Kontrolle darüber, wen Du als Favorit markierst. Wenn Du das Gefühl hast, dass Du mit einem User einen tollen Chat hattest und er ein potenzieller Stammkunde sein könnte, dann markiere ihn als Favorit, indem Du in eurem Chat oben auf den Stern klickst. Auf diese Weise kannst Du schnell und einfach auf seine Nachrichten zugreifen und den Chat erneut aufleben lassen oder fortsetzen. Aber sei vorsichtig, nicht jeden User in diese Liste aufzunehmen. Nutze diesen Filter, um Deine guten Kunden für Dich einfach zugänglich zu organisieren.`,
  MULTI_MESSENGER_FILTER_STEP_5_1_CONTENT: `<span class="bold">Tipp:</span> Kunden sehen nicht, wenn Du sie als "Favorit" markierst.`,

  MULTI_MESSENGER_FILTER_STEP_6: `Freunde`,
  MULTI_MESSENGER_FILTER_STEP_6_CONTENT: `Dieser Filter mag anfangs leer erscheinen, aber das wird sich schnell ändern. Sobald Du einige Male online bist, wirst Du feststellen, dass Du von vielen Usern Freundschaftsanfragen erhältst. Doch bevor Du diese einfach annimmst, solltest Du Dir Zeit nehmen, um den jeweiligen User anzuschreiben und eine aktive Kommunikation aufzubauen. Denn was bringt es Dir, einen Haufen Freunde zu haben, mit denen Du nie sprichst? Deine Freundschaftsanfragen findest Du zum einen in Deinen Aktivitäten im Multi Messenger oder unter dem blauen Herz im Hauptmenü.`,

  MULTI_MESSENGER_FILTER_STEP_7: `Games`,
  MULTI_MESSENGER_FILTER_STEP_7_CONTENT: `Einladungen zu einem {link} Match und laufende Spiele zeigen wir Dir hier.`,
  MULTI_MESSENGER_FILTER_STEP_7_LINK: `4Score`,

  MULTI_MESSENGER_HEADLINE_2: `Aktivitäten`,
  MULTI_MESSENGER_ACT_STEP_1: `Profilbesuche`,
  MULTI_MESSENGER_ACT_STEP_1_CONTENT: `Hier siehst Du neue Kunden, die gerade Dein Profil besuchen. Wenn Du auf den Namen eines Kunden klickst, kannst Du möglicherweise auch schon den ersten Icebreaker im Chat sehen.`,
  MULTI_MESSENGER_ACT_STEP_1_1_CONTENT: `Jetzt wird es besonders spannend, denn der User betrachtet gerade Dein Profil. Wenn ihm gefällt, was er liest, und ihn Dein Bild anspricht, ist die Wahrscheinlichkeit hoch, dass er Dich anschreibt und Kontakt mit Dir aufnimmt. Du selbst kannst diesen Kunden jedoch noch nicht kontaktieren, solange er nicht reagiert und Dir geschrieben hat. Keine Sorge, falls der User nicht direkt reagiert. Wir werden ihm weitere Icebreaker in Deinem Namen schicken, um sein Interesse an Dir zu wecken.`,

  MULTI_MESSENGER_ACT_STEP_2: `Kunden Logins`,
  MULTI_MESSENGER_ACT_STEP_2_CONTENT: `Kunden sind User, die bereits Geld bei Dir ausgegeben haben. Das kann durch den Chat, die Webcam oder den Kauf einer Deiner Galerien erfolgt sein. Du hast den Kunden von Dir überzeugt, sei es mit Deinen Bildern oder Deiner Persönlichkeit. Da er gerade online ist, nutze die Chance, ihn wieder an Dich zu erinnern. Schreibe ihm keine vorgefertigte Copy-and-Paste Nachricht, sondern eine persönliche mit Informationen die Du vorher über ihn in Deinen Notizen gesammelt. Damit sind die Chancen hoch den Kunden wieder in einen aktiven Chat zu verwickeln.`,

  MULTI_MESSENGER_ACT_STEP_3: `Freunde Logins`,
  MULTI_MESSENGER_ACT_STEP_3_CONTENT: `Freunde sind User, deren Freundschaftsanfrage Du bereits angenommen hast. Wenn sich einer Deiner Freunde gerade einloggt, zeigen wir ihn Dir sofort unter diesem Punkt an. So musst Du nicht ständig Deine Freundesliste durchsuchen, um herauszufinden, wer von ihnen gerade online ist. Du siehst sofort, wer online ist, und kannst ihm eine nette Nachricht schreiben, um den Chat wieder aufleben zu lassen oder fortzuführen.`,

  MULTI_MESSENGER_ACT_STEP_4: `Freundschaftsanfragen`,
  MULTI_MESSENGER_ACT_STEP_4_CONTENT: `Unter Freundschaftsanfragen findest Du die User, die entweder schon Kontakt zu Dir haben oder mit einer Freundschaftsanfrage möglicherweise Kontakt herstellen möchten. Schau Dir den Chat und das Profil des Users an, um zu sehen, ob ihr bereits Kontakt habt. Wenn ihr noch keine Nachricht miteinander geschrieben habt, ist das Deine Chance, den User an Dich zu binden und zu sehen, ob eure Interessen zueinander passen, bevor Du die Freundschaftsanfrage annimmst. Kunden, die Dir eine Freundschaftsanfrage gestellt haben, kannst Du anschreiben.`,

  MULTI_MESSENGER_ACT_STEP_5: `Contentkäufe`,
  MULTI_MESSENGER_ACT_STEP_5_CONTENT: `Hier zeigen wir Dir jeden User, der gerade etwas bei Dir gekauft hat. Er ist aktiv auf Deinem Profil, und Dein Content hat ihn angesprochen. Hinterlasse ihm ein Dankeschön oder beginne das Gespräch mit ihm. Vielleicht verrät er Dir, worauf er Lust hat oder was er als Nächstes von Dir kaufen möchte.`,

  MULTI_MESSENGER_HEADLINE_3: `Features`,
  MULTI_MESSENGER_FEAT_STEP_1: `Icebreaker`,
  MULTI_MESSENGER_FEAT_STEP_1_CONTENT: `Neben der Anzeige im Hauptmenü siehst Du auch hier, ob aktuell Icebreaker für Dich versendet werden.`,

  MULTI_MESSENGER_FEAT_STEP_2: `Lovense Toy`,
  MULTI_MESSENGER_FEAT_STEP_2_CONTENT: `Wenn Du ein {link} mit Deinem LiveCreator-Account verbunden hast, ist es hier möglich das Toy auszuwählen und zu starten.`,
  MULTI_MESSENGER_FEAT_STEP_2_LINK: `Lovense Toy`,

  MULTI_MESSENGER_FEAT_STEP_3: `Livecam`,
  MULTI_MESSENGER_FEAT_STEP_3_CONTENT: `Hast Du Deine Webcam unter {link} ausgewählt, kannst Du direkt im Multi Messenger Deinen Stream starten und beenden.`,
  LINK_TEXT_CAM_SETTINGS: `{account} - Einstellungen - Livecam`,
  MULTI_MESSENGER_FEAT_STEP_3_1_CONTENT: `<span class="bold">Starten</span><br />Nach dem Klick auf den grünen Button „Stream starten“ wirst Du aufgefordert ein Motto für Deine Show anzugeben. Dies ist kein Muss, Du kannst den Stream auch ohne Motto starten.`,
  MULTI_MESSENGER_FEAT_STEP_3_2_CONTENT: `<span class="bold">Beenden</span><br />Nach dem Klick auf den roten Button „Stream stoppen“ geht Deine Livecam in LiveCreator und in allen unseren Communities offline.`,
  MULTI_MESSENGER_FEAT_STEP_3_3_CONTENT: `Die {link} zur Livecam beantworten wir im Wiki.`,
  MULTI_MESSENGER_FEAT_STEP_3_3_CONTENT_LINK: `häufigsten Fragen`,
};
