import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// import sortAZIcon from '../../../../assets/img/svg/sort-a-z.svg';
// import sortZAIcon from '../../../../assets/img/svg/sort-z-a.svg';
import { PAGINATION_PAGE_SIZE } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { spinnerRequest } from '../../../../shared/spinnerRequest';
import { addPortals } from '../../../../utils/portals';
import Pagination from '../../../Pagination/Pagination';
import Scrollbar from '../../../Scrollbar/Scrollbar';
import ThumbnailItem from '../../../ThumbnailItem';

function Favorites({ spinner, dialog }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const [items, setItems] = useState([]);
  // const [favoritesSearch, setFavoritesSearch] = useState('');
  // const [sortingDirection, setSortingDirection] = useState('asc');
  const [favorites, setFavorites] = useState([]);
  const initialItems = useRef(null);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [noDialogsCounter, setNoDialogsCounter] = useState(0);

  const [{ loading: favoritesDataLoading }, favoritesDataRequest] = useAxios(
    {
      url: `/user/favorites`,
    },
    { manual: true }
  );
  const getFavoritesData = useCallback(() => {
    spinnerRequest({
      request: favoritesDataRequest,
      spinner,
    })
      .then(async (response) => {
        const data = await addPortals([...response.data?.data]);
        setFavorites(data);
        setTotalItems(response?.data.total);
      })
      .catch(() => {});
  }, [spinner, favoritesDataRequest]);

  useEffect(() => {
    let items = [];
    let counter = 0;
    if (dialog.length) {
      items = favorites.filter((item) => {
        const index = dialog?.findIndex((i) => {
          return (
            i.partner === item.id.replace('-', '@') || i.partner === item.id
          );
        });
        if (index !== -1) {
          return true;
        }
        counter++;
        return false;
      });
    }
    setNoDialogsCounter(counter);
    initialItems.current = [...items];
    if (initialItems.current.length > PAGINATION_PAGE_SIZE) {
      items = items.slice(0, PAGINATION_PAGE_SIZE);
    }
    setItems([...items]);
  }, [dialog, favorites, spinner]);

  useEffect(() => {
    getFavoritesData();
  }, [getFavoritesData]);

  const updateList = useCallback(
    (id) => {
      if (!id) return;
      if (offset >= totalItems - 1) {
        setOffset(offset - PAGINATION_PAGE_SIZE);
        setPageNumber(pageNumber - 1);
        return;
      }
      getFavoritesData();
    },
    [getFavoritesData, offset, pageNumber, totalItems]
  );

  const temporaryUpdateList = (id) => {
    setFavorites(
      favorites.map((favorite) => {
        if (id === favorite.id) {
          favorite.hidden = true;
        }
        return favorite;
      })
    );
  };

  // SEARCH TO BE ADDED BACK LATER

  // const searchFavoritesHandler = (value) => {
  //   const filteredItems = initialItems.current.filter((item) => {
  //     if (value === '') {
  //       return true;
  //     }
  //     return item.nickname.toLowerCase().includes(value.toLowerCase());
  //   });
  //   setItems(filteredItems);
  //   setFavoritesSearch(value);
  // };

  // const clearSearchInput = () => {
  //   setFavoritesSearch('');
  //   searchFavoritesHandler('');
  // };

  // const sortFavorites = () => {
  //   const sortedItems = [...initialItems.current];
  //   if (sortingDirection === 'desc') {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname > b.nickname) {
  //         return -1;
  //       } else if (b.nickname < a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('asc');
  //   } else {
  //     sortedItems.sort((a, b) => {
  //       if (a.nickname < b.nickname) {
  //         return -1;
  //       } else if (b.nickname > a.nickname) {
  //         return 1;
  //       }
  //       return 0;
  //     });
  //     setSortingDirection('desc');
  //   }
  //   setItems(sortedItems);
  //   initialItems.current = [...sortedItems];
  // };

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="relationships-wrapper">
        <Scrollbar visible={isLargeScreen} isLargeScreen={isLargeScreen}>
          <div className="data-wrapper">
            <h1 className="headline">
              <FormattedMessage id="RELATIONSHIP_HEADLINE_FAVORITES" />
            </h1>
            {!!initialItems.current?.length ? (
              <>
                {/*<div className="searchbar">*/}
                {/*  <SearchInput*/}
                {/*    id={'search_favorites'}*/}
                {/*    name={'search_favorites'}*/}
                {/*    placeholder={`${intl.formatMessage({*/}
                {/*      id: 'SEARCH',*/}
                {/*    })}`}*/}
                {/*    value={favoritesSearch}*/}
                {/*    onChange={(event) =>*/}
                {/*      searchFavoritesHandler(event.target.value)*/}
                {/*    }*/}
                {/*    clearInput={clearSearchInput}*/}
                {/*  />*/}
                {/*  <ReactSVG*/}
                {/*    onClick={() => sortFavorites()}*/}
                {/*    src={sortingDirection === 'asc' ? sortAZIcon : sortZAIcon}*/}
                {/*    className={'sort-icon'}*/}
                {/*    wrapper="span"*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="image-grid">
                  {items.map((item, index) => (
                    <ThumbnailItem
                      item={item}
                      type="relationship"
                      key={`${item}-${index}`}
                      relationshipType="favorites"
                      relationshipMenuType="favorites"
                      updateList={updateList}
                      temporaryUpdateList={temporaryUpdateList}
                    />
                  ))}
                </div>
                <Pagination
                  offset={offset}
                  totalItems={totalItems - noDialogsCounter}
                  pageNumber={pageNumber}
                  setOffset={setOffset}
                  setPageNumber={setPageNumber}
                  initialItems={initialItems.current}
                  setItems={setItems}
                />
              </>
            ) : (
              !favoritesDataLoading && (
                <div className="no-items">
                  {intl.formatMessage({
                    id: 'RELATIONSHIP_LABEL_NO_FAVORITES',
                  })}
                </div>
              )
            )}
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            <h2 className="headline">
              <FormattedMessage id="RELATIONSHIPS_INFO_HEADLINE" />
            </h2>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_1">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_2">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_3">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
            <p>
              <FormattedMessage id="RELATIONSHIPS_INFO_4">
                {(message) => (
                  <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                )}
              </FormattedMessage>
            </p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Favorites;

