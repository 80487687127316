import { connect } from 'react-redux';

import {
  openOverlay,
  spinner,
  userUpdate as update,
  userUpdateDocuments as updateDocuments,
  uploadFile,
} from '../../../../../actions';
import UploadDocuments from './UploadDocuments';

const mapStateToProps = (state) => ({
  isMobile: state.browser.isMobile,
  user: state.user,
  uploads: state.upload.uploads,
});

const mapDispatchToProps = {
  updateDocuments,
  update,
  openOverlay,
  uploadFile,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadDocuments);

