import { connect } from 'react-redux';

import { user as userUtils } from '../../../../utils';
import StreamingInfo from './StreamingInfo';

export default connect((state) => {
  const stats = userUtils.hasVisibleUserOrVoyeur(state.camUser.camUsers)
    ? state.streamingstats
    : {
        ...state.streamingstats,
        bandwidth: 0,
        fps: 0,
      };

  return {
    ...state.streaming,
    ...stats,
    selectedCam: state.cam.selectedCam.label,
    selectedResolution: state.cam.selectedResolution.label,
  };
})(StreamingInfo);
