import { CHAT_ATTACHEMENT_ERROR } from '../../actions/actions';
import { ALERT_LEVEL_ERROR, ALERT_TYPE_CLOSE } from '../../constants';
import { hashAlert, uuid } from '../../utils';

const initialState = [];

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case CHAT_ATTACHEMENT_ERROR:
      const alert = {
        type: ALERT_TYPE_CLOSE,
        level: ALERT_LEVEL_ERROR,
        modal: false,
        scope: 'general',
        message: {
          translate: true,
          id: action?.payload ? action.payload : 'alert.attachementError',
          headlineId: 'ERROR',
        },
        stamp: uuid(),
      };

      // make alert unique
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    default:
      return state;
  }
};

export default reduce;
