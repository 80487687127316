import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { checkBandwidth } from '../../../actions';
import { MANAGEMENT_LOGGED_OUT } from '../../../constants';
import { INACTIVE } from '../../../constants/bandwidth';
import BandwidthCheck from './BandwidthCheck';

const mapStateToProps = (state) =>
  Object.assign({}, state.bandwidth, {
    disabled:
      state.bandwidth.check !== INACTIVE ||
      state.streaming.managementStep !== MANAGEMENT_LOGGED_OUT ||
      state.cam.selectedCam.value === '0' ||
      !!state.cam.checkingCam,
  });

const mapDispatchToProps = (dispatch) => ({
  onClick: () => dispatch(checkBandwidth()),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(BandwidthCheck)
);
