import sender from '../../services/sender';
import { userLogout } from '../';
import { SENDER_AUTH } from '../actions';
// import logoutSender from './logout';

let timer = null;

const start = (interval) => {
  console.log(`starting auth with interval ${interval}`);
  return (dispatch, getState) => {
    if (!interval) {
      return;
    }
    clearInterval(timer);
    timer = setInterval(() => tick(dispatch, getState), interval);
    dispatch({ type: SENDER_AUTH, meta: { start: true } });
  };
};
const tick = (dispatch) => {
  console.log('auth tick');
  sender
    .auth()
    .then((data) => {
      if (data && data.status === 400) {
        dispatch(userLogout(false, 2));
      }
      // @todo check if we need this, in the wss this is dispatched but never read
      // dispatch(
      //   {
      //     type: SENDER_AUTH,
      //     payload: data
      //   }
      // );
    })
    .catch((err) => {
      console.log(`auth error ${err.message}`);
      // @todo check if we need this, in the wss this is dispatched but never read
      // dispatch({
      //   type: SENDER_AUTH,
      //   error: true,
      //   payload: err
      // });
    });
};

const stop = () => {
  clearInterval(timer);
  return { type: SENDER_AUTH, meta: { stop: true } };
};

export const auth = {
  start,
  stop,
};
