export default {
  COMMENTS_ANSWER_DESCRIPTION: 'Schreibe Deinen Kommentar und klicke auf „Senden“. Der Kommentar muss mindestens 16 Zeichen enthalten.',
  COMMENTS_ANSWER_HEADLINE: 'Schreibe einen Kommentar',
  COMMENTS_DATE: 'Datum',
  COMMENTS_FORM_LABEL: 'Kommentar',
  COMMENTS_FROM: 'von',
  COMMENTS_GALLERY_TITLE_NOT_AVAILABLE: 'Kein Galerietitel auf Deutsch',
  COMMENTS_INFO_HEADLINE_1: 'Verwalte Deine Galerie Kommentare',
  COMMENTS_INFO_TEXT_1: `In dieser Übersicht siehst Du alle User Kommentare zu Deinen Content Galerien. Du kannst positive Beiträge direkt innerhalb der Galerie mit einem eigenen Kommentar beantworten. Dein Kommentar ist dann ebenfalls für alle User sichtbar. Alternativ kannst Du User, die einen Kommentar hinterlassen haben, auch direkt via Messenger anschreiben.`,
  COMMENTS_INFO_TEXT_2: `Negative, unangebrachte oder unnötige Kommentare kannst Du natürlich ebenfalls direkt löschen.`,
  COMMENTS_INFO_TEXT_3: `<span class="bold">Tipp:</span> Nur Kunden können Kommentare schreiben. Diese User haben also bereits bei Dir gekauft und tun dies potenziell wieder! Nutze positive Kommentare für eine Kommunikation! Bedanke Dich via Kommentar oder auch persönlich mit einer Nachricht. Frage auch nach alternativen Wünschen und Fantasien für künftigen Content. Das fördert die Interaktion, zeigt, dass Du aktiv auf Kunden eingehst und wirkt sich positiv auf die Anzahl neuer Userkontakte und Deinen Umsatz aus.`,
  COMMENTS_MENU_ANSWER: 'Schreibe einen Kommentar',
  COMMENTS_MENU_DELETE: 'Kommentar löschen',
  COMMENTS_NO_COMMENTS_AVAILABLE: 'Es gibt keine Galerie Kommentare',
  COMMENTS_TABLE_HEADLINE: 'Deine neuesten Kommentare',
  COMMENTS_TEXT: 'Galerie Titel und Kommentar',
  COMMENTS_USER: 'User',
  ROUTE_MY_COMMENTS: 'Kommentare',
  VALIDATION_COMMENTS_TOO_LONG: 'Dein Kommentar darf maximal {n} Zeichen lang sein',
  VALIDATION_COMMENTS_TOO_SHORT: 'Dein Kommentar muss mindestens {n} Zeichen lang sein',
};
