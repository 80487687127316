import { connect } from 'react-redux';

import { isLivecamCreator } from '../../../../../utils';
import LivecamSetting from './LivecamSetting';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  nickname: state.user?.account?.nickname,
  isLivecamCreator: isLivecamCreator(state.user),
});

export default connect(mapStateToProps)(LivecamSetting);

