import './BankholderDocument.scss';

import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { uuid } from '../../../../../utils';
import { getUpload } from '../../../../../utils/uploadUtils';
import StandaloneErrorWrapper from '../../../../Forms/FieldErrorWrapper/StandaloneErrorWrapper/StandaloneErrorWrapper';
import Uploader from '../../../../Uploader';
import MyAccountFileApi from '../../../Documents/MyAccountFileApi';

function BankHolderDocument({
  isMobile,
  bankholder_document,
  documents,
  uploads = [],
  uploadFile,
  openOverlay,
  updateBankholderDocument,
  bank_holder,
  updateDocuments,
}) {
  const [documentsError, setDocumentsError] = useState(null);
  const [file, setFile] = useState(null);
  const indicator = 'bankHolderdocuments';
  const intl = useIntl();

  const { uploadIndicator, uploadError, uploadResult } = getUpload(
    uploads,
    indicator
  );

  useEffect(() => {
    if (uploadIndicator !== indicator) {
      return;
    }
    if (uploadError) {
      setDocumentsError(uploadError);
      return uploadFile('', null, indicator);
    }
    if (uploadResult && uploadResult?.data) {
      setDocumentsError(null);
      updateBankholderDocument('pending');
      updateDocuments(uploadResult?.data);
      return uploadFile('', null, indicator);
    }
  }, [
    uploadError,
    uploadResult,
    uploadIndicator,
    uploadFile,
    setDocumentsError,
    updateBankholderDocument,
    updateDocuments,
  ]);
  const openCam = () => {
    const stamp = uuid();
    openOverlay({
      stamp,
      Component: 'Cam',
      props: {
        indicator,
        camstamp: stamp,
        uploadPath: '/user/documents/bankholder',
        title: intl.formatMessage({ id: 'TAKE_SNAPSHOT' }),
        minScreenshotWidth: 1600,
        filename: `snapshot${dayjs().format('DDMMYYYYHHmmss')}.jpg`,
        singleFile: true,
        // Button inside the Cam is now the same as in the Onboarding, but the outsideFormik prop is still
        // needed since the regular one contains a wrapper connected to formik
        outsideFormik: true,
      },
    });
  };

  useEffect(() => {
    const lastfile = documents
      .filter(
        (doc) => doc.type === 'bankholder' && doc.description === bank_holder
      )
      .reduce((previous, current) => {
        if (!previous) return current;
        if (previous.created <= current.created) return current;
        return previous;
      }, null);
    if (lastfile?.state === 'pending') {
      return setFile(lastfile);
    }
    setFile(null);
  }, [documents, bank_holder, setFile]);
  if (bankholder_document === 'same_name') {
    return null;
  }

  return (
    <div className="bankholder-document-wrapper">
      {['missing', 'pending', 'rejected'].indexOf(bankholder_document) > -1 ? (
        <div className={`bankholder-document-${bankholder_document}`}>
          <StandaloneErrorWrapper
            name="bankHolderDocuments"
            error={
              documentsError
                ? documentsError?.response?.status === 400
                  ? intl.formatMessage({
                      id: 'ERROR_UPLOAD_DOCUMENT_1',
                    })
                  : intl.formatMessage({
                      id: 'ERROR_UPLOAD_DOCUMENT_2',
                    })
                : null
            }
            noGrid={true}
          >
            <Uploader
              uploadPath="/user/documents/bankholder"
              indicator={indicator}
              label={
                bankholder_document === 'missing'
                  ? 'BANKHOLDER_DOCUMENT_MISSING'
                  : bankholder_document === 'pending'
                  ? 'BANKHOLDER_DOCUMENT_PENDING'
                  : 'BANKHOLDER_DOCUMENT_REJECTED'
              }
              acceptedFileTypes={['.jpg', '.jpeg', '.jpe', '.png', '.pdf']}
              openCam={openCam}
              isMobile={isMobile}
              handleError={setDocumentsError}
              className="documents"
              singleFile={true}
            >
              {file && (
                <MyAccountFileApi
                  key={0}
                  file={file}
                  isDeleteBtnShown={false}
                />
              )}
            </Uploader>
          </StandaloneErrorWrapper>
        </div>
      ) : (
        <p className="bold">
          {intl.formatMessage({ id: 'BANKHOLDER_DOCUMENT_CONFIRMED' })}
        </p>
      )}
    </div>
  );
}

export default BankHolderDocument;
