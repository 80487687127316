import { gameUtils, user as userUtils } from '../../../../utils';
import { GAME_INVITATION } from '../../../actions';
import { persistDialog, toggleUsergameView } from '../../..';
export default (data) => (dispatch, getState) => {
  if (userUtils.gamesBlocked(getState())) return;
  const payload = gameUtils.addPartner(data?.payload);
  dispatch({
    type: GAME_INVITATION,
    payload,
  });
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    view: { usergame },
    chat: { selectedUser },
  } = getState();
  const allDialogs = [...dialogs];
  const selectedLivecamUser = camUsers.find((u) => u.userId === selectedUser);
  const isSelected = !selectedLivecamUser
    ? selectedUser === payload?.partner
    : userUtils.getPartner(selectedLivecamUser) === payload?.partner;

  const user =
    selectedLivecamUser || allDialogs.find((d) => d.partner === selectedUser);
  if (isSelected) {
    dispatch(persistDialog(user));
    if (usergame) {
      dispatch(toggleUsergameView());
    }
  }
};
