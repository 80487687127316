import { WebRTC } from '../../services';
import { CAM2CAM_START } from '../actions';
import cam2cam from './';

export default (userId, data) => {
  return (dispatch) => {
    console.log('Start Cam2Cam called');

    const cam2camConnection = new WebRTC();

    dispatch({
      type: CAM2CAM_START,
      meta: { start: true },
      payload: {
        userId,
        cam2camConnection,
      },
    });

    cam2camConnection.on('start', (streams) => {
      dispatch(cam2cam.startEvent(userId, streams[0]));
    });
    cam2camConnection.on('stop', () => {
      dispatch(cam2cam.stopEvent(userId));
    });
    cam2camConnection.start({ ...data, userId });
  };
};
