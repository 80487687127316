import { Formik } from 'formik';
import { connect } from 'react-redux';

import {
  addAttachment,
  addOptions,
  openOverlay,
  spinner,
} from '../../../../actions';
import Icebreaker from './Icebreaker';
import { initialValues } from './Icebreaker.functions';

const mapStateToProps = (state) => {
  const attachments = state.attachments.files
    .filter((f) => f.status !== 'denied')
    .map((f) => ({
      value: f.id,
      label: f.name,
      file: f.thumbnail,
      type: f.type,
    }));

  return {
    isMobile: state.view.isMobile,
    isLarge: state.view.isLarge,
    attachments,
    icebreaker_fsk: state.options.icebreaker_fsk,
  };
};

const mapDispatchToProps = {
  addAttachment,
  spinner,
  openOverlay,
  addOptions,
};

const IcebreakerForm = (props) => (
  <Formik
    initialValues={{ ...initialValues, icebreaker_fsk: props.icebreaker_fsk }}
  >
    <Icebreaker {...props} />
  </Formik>
);

export default connect(mapStateToProps, mapDispatchToProps)(IcebreakerForm);

