import useAxios from 'axios-hooks';
import { useState } from 'react';

import friendSVG from '../../../../../../assets/img/svg/friend.svg';
import noFriendSVG from '../../../../../../assets/img/svg/noFriend.svg';
import {
  DENIED,
  DENIED_BY_PARTNER,
  FRIEND,
  NONE,
  REQUESTED,
  SELF,
} from '../../../../../../constants';
import Button from '../../../../../Button/Button';

function Friend({
  isFriend = false,
  show,
  userId,
  name,
  sendFriendRequest,
  answerFriendRequest,
  alreadySentFriendRequest,
  deleteFriend,
  deniedFriendRequest,
  removeDeniedFriendRequest,
  isActivated,
  isIgnored,
}) {
  const [disabled, setDisabled] = useState(false);
  const icon = isFriend ? friendSVG : noFriendSVG;

  const [, isFriendRequest] = useAxios(
    {
      url: `/user/friends/${userId}`,
    },
    { manual: true }
  );

  const friendActionHandler = () => {
    if (!isActivated || disabled) return;
    setDisabled(true);
    setTimeout(() => {
      setDisabled(false);
    }, 1000);
    isFriendRequest()
      .then((response) => {
        switch (response.data.status) {
          case REQUESTED:
            answerFriendRequest(false, userId, name);
            break;
          case SELF:
            alreadySentFriendRequest();
            break;
          case FRIEND:
            if (!isFriend) {
              answerFriendRequest(true, userId);
            } else {
              deleteFriend(userId, false, name);
            }
            break;
          case NONE:
            if (isFriend) {
              deleteFriend(userId, true);
            }
            sendFriendRequest();
            break;
          case DENIED:
            removeDeniedFriendRequest(userId, name);
            break;
          case DENIED_BY_PARTNER:
            deniedFriendRequest(name);
            break;
          default:
            return;
        }
      })
      .catch((error) => {});
  };

  return show ? (
    <div className="control-item friend-icon">
      <Button
        icon={icon}
        intlTranslate={false}
        variant="icon-only"
        renumerateIRIElements={false}
        onClick={friendActionHandler}
        disabled={!isActivated || disabled || isIgnored}
      />
    </div>
  ) : null;
}

export default Friend;
