import './Overlay.scss';

import classNames from 'classnames';
import React from 'react';

import Cam from '../Cam';
import DashboardMobileOverlay from '../Content/Dashboard/MobileOverlay';
import Document from '../Content/Documents/Document';
import Motto from '../Content/Motto';
import AddressChanger from '../Content/MyPersonalDetails/AddressChanger';
import NicknameChanger from '../Content/NicknameChanger';
import VeriffOverlay from '../Content/VeriffOverlay';
import VideoOverlay from '../Content/VideoOverlay';
import IdleDialog from '../IdleDialog';
import PictureCropper from '../PictureCropper';

function Overlay({ overlays }) {
  if (overlays.length <= 0) {
    return null;
  }
  return overlays.map((overlay, index) => {
    let Component;
    switch (overlay.Component) {
      case 'Cam':
        Component = Cam;
        break;
      case 'PictureCropper':
        Component = PictureCropper;
        break;
      case 'Document':
        Component = Document;
        break;
      case 'IdleDialog':
        Component = IdleDialog;
        break;
      case 'DashboardMobileOverlay':
        Component = DashboardMobileOverlay;
        break;
      case 'VideoOverlay':
        Component = VideoOverlay;
        break;
      case 'Motto':
        Component = Motto;
        break;
      case 'NicknameChanger':
        Component = NicknameChanger;
        break;
      case 'VeriffOverlay':
        Component = VeriffOverlay;
        break;
      case 'AddressChanger':
        Component = AddressChanger;
        break;
      default:
    }

    const classes = classNames('overlay', {
      'important-overlay': overlay.important,
    });
    return (
      <div key={index} className={classes}>
        <Component {...overlay.props}>{overlay.children}</Component>
      </div>
    );
  });
}

export default Overlay;

