import { MANAGEMENT_EVENTS_RESOLUTION_SENDER_CHANGE } from '../../../../actions';
export default (WebRTCPublisher, management) => ({ resolution }) => {
  return async (dispatch, getState) => {
    try {
      if (
        typeof resolution !== 'string' ||
        !/^\d{3,4}[x]{1}\d{3,4}$/.test(resolution)
      ) {
        throw new Error('wrong resolution');
      }
      const {
        cam: { serverResolutions, selectedFps },
      } = getState();
      // there where several fps before we set fps hard to 30 @see LCJS-4778
      // @todo remove this as we are sure we don't need it anymore
      const serverResolutionByFps = serverResolutions
        .filter((r) => String(r.fps) === String(selectedFps.value))
        .shift().resolution;
      const { bandwidth } = serverResolutionByFps.find(
        (r) => r.width + 'x' + r.height === resolution
      );

      await WebRTCPublisher.changeResolution(resolution);
      WebRTCPublisher.changeBandwidth(bandwidth);
      dispatch({
        type: MANAGEMENT_EVENTS_RESOLUTION_SENDER_CHANGE,
        payload: { resolution, bandwidth },
      });
      management.emit('changedCamResolution', { resolution });
    } catch (error) {
      management.emit('changedCamResolution', {
        resolution,
        error: error.message,
      });
    }
  };
};
