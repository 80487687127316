export default {
  RANKINGS_HEADLINE: `Rankings`,
  RANKINGS_INTRO: `Unsere Rankings geben Dir einen Einblick in Deine Performance auf unserer Seite. So kannst Du schnell und einfach herausfinden, wie gut z.B. Dein Profilbild oder Dein Verhalten im Messenger bei Kunden ankommt. So kannst Du gezielt an den Bereichen arbeiten, in denen Du Verbesserungen erzielen möchtest.`,
  RANKINGS_INTRO_1: `Die Rankings werden im Bereich von -5 bis +5 dargestellt. Solltest Du in einem der Bereiche ein „n/a“ angezeigt bekommen, so haben wir zur Anzeige eines Wertes zum aktuellen Zeitpunkt zu wenig Informationen, um hier eine fundierte Aussage treffen zu können.`,

  RANKINGS_HEADLINE_1: `Die Bereiche im Detail:`,
  RANKINGS_CONTENT_STEP_1: `Profilbild`,
  RANKINGS_CONTENT_STEP_1_CONTENT: `Dieser Wert zeigt, wie gut Dein gewähltes Profilbild beim Kunden ankommt. Du kannst in Deinem Profil wählen, welches Deiner Bilder standardmäßig auf Deinem Profil und damit den Kunden gezeigt werden soll. Je höher der Wert, desto ansprechender finden Deine Kunden Dein Bild. Solltest Du länger kein neues Profilbild hochgeladen haben, könntest Du sofort ein neues hochladen. Deine Kunden werden sich sicher über ein wenig Abwechslung freuen, aber Vorsicht: zu häufige Wechsel können sich auch negativ auswirken.`,
  RANKINGS_CONTENT_STEP_2: `Icebreaker`,
  RANKINGS_CONTENT_STEP_2_CONTENT: `Dieser Wert zeigt Dir nicht, wie viele Icebreaker für Dich versendet werden, sondern wie viele Kunden auf den Icebreaker reagieren und Dir antworten. Hier ist es wichtig zu wissen, dass Dein Profilbild und ggf. vorhandene Dateianhänge bei der Antwort und somit bei der Bewertung eine große Rolle spielen. Je mehr Kunden Dein Profil und Deine Bilder ansprechen, desto höher ist die Wahrscheinlichkeit, dass er Dir auf den Icebreaker schreiben wird.`,
  RANKINGS_CONTENT_STEP_3: `Onlinezeit`,
  RANKINGS_CONTENT_STEP_3_CONTENT: `Dieser Wert ermittelt sich immer aus einem Durchschnitt der letzten Tage. Das heißt, sobald Du regelmäßig und länger online bist, wird Dein Wert steigen. Solltest Du mehrere Tage hintereinander nicht online sein, wird sich dieser Wert nach unten bewegen. Wenn Du Dir jetzt denkst, Du bist dann einfach den nächsten Tag extrem lange präsent und der Wert wird sich dadurch sofort verbessern, müssen wir Dich enttäuschen. Wir möchten, dass Du eine gewisse Regelmäßigkeit in Deiner Onlinezeit hast, denn nur so kannst Du auch Deine Kunden langfristig binden. Wichtig zu wissen: Icebreaker werden nur versandt, solange Du online bist.`,
  RANKINGS_CONTENT_STEP_4: `Chatqualität`,
  RANKINGS_CONTENT_STEP_4_CONTENT: `Dieser Wert zeigt, wie gut Du die Kunden - die mit Dir in Kontakt treten - in einem längeren Chat an Dich binden kannst. Einer niedrigen Rate liegt meistens einem schlechten Schreibstil, Unfreundlichkeit, leeren Versprechungen bzgl. Treffen oder „Abzockversuchen“ zu Grunde.`,
  RANKINGS_CONTENT_STEP_5: `Antwortrate`,
  RANKINGS_CONTENT_STEP_5_CONTENT: `Dieser Wert zeigt, wie gut Du auf Deine Kunden eingehst und ihnen antwortest. Es ist immer wichtig, den Menüpunkt „Ungelesen“ und „Unbeantwortet“ im Blick zu haben. Diese Nachrichten solltest Du schnell beantworten, schließlich möchte ein Kunde mit Dir schreiben und bezahlt dafür.`,
  RANKINGS_CONTENT_STEP_6: `Antwortzeit`,
  RANKINGS_CONTENT_STEP_6_CONTENT: `Dieser Wert zeigt, wie gut und schnell Du auf Nachrichten von Deinen Kunden antwortest. Nur wenn Du in aktiver Kommunikation bleibst, kannst Du den Kunden an Dich binden und wirst ihn zu Deinem Stammkunden machen.`,
  RANKINGS_CONTENT_STEP_7: `Premium Dateianhänge`,
  RANKINGS_CONTENT_STEP_7_CONTENT: `Dieser Wert (Quote) wird Dir gezeigt, sobald Du kostenpflichtige Anhänge an Kunden versendest. Der Wert zeigt Dir in Prozent, wie gut die Verkaufsquote Deiner Premium Dateianhänge ist. Bedenke, dass diese Funktion nicht dazu gedacht ist, Deinen Kunden unaufgefordert Bilder zu schicken, in der Hoffnung, den Verdienst zu steigern. Benutze diese Funktion um Deinem Kunden im Gespräch ein passendes Bild zu verkaufen. Fällt Deine Quote am Ende des Monats unter 50% wird diese Funktion automatisch am Anfang des neuen Monats für Dich deaktiviert. Um sie zu reaktivieren solltest Du Dich in eines der Coachings oder in den offenen Hilferaum begeben, um etwas darüber zu lernen, wie es zukünftig besser mit dem Versenden von Premium Dateianhängen klappt.`,

  RANKINGS_OUTRO: `Solltest Du den Bereich "Meine Leistung" noch nicht auf Deinem Dashboard sehen, liegt es daran, dass sich die Funktion automatisch freischaltet, sobald wir ein gutes Chatranking in Deinem Profil sehen. Sobald die Funktion aktiviert wurde, bekommst Du eine gelbe Notiz im rechten Bereich Deines Dashboards angezeigt.`,
};
