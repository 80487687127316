import { connect } from 'react-redux';

import { spinner, userUpdate as update } from '../../../../actions';
import { doNotShow } from '../../../../actions';
import PublicDetails from './PublicDetails';

const mapDispatchToProps = {
  update,
  spinner,
  doNotShow,
};

const mapStateToProps = (state) => ({
  languages: state.languages,
  user: state.user,
  doNotShowState: state.publicDetails.askDoNotShowConfirmed,
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicDetails);

