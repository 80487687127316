import '../../../../assets/fonts/Montserrat/montserrat.scss';
import './Page404.scss';

import { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

class Page404 extends Component {
  componentDidMount() {
    const pathname = window?.location?.pathname;

    const { userLogout, errorLog } = this.props;

    if (pathname === '/multi-messenger') {
      userLogout();
    }

    errorLog({
      location: 'pageNotFound',
      message: 'pageNotFound',
    });
  }

  render() {
    return (
      <div className="page404">
        <div className="notfound">
          <div className="notfound-404">
            <h2>
              <FormattedMessage id="PAGE404_NOT_FOUND" />
            </h2>
            <h1>
              <span>4</span>
              <span>0</span>
              <span>4</span>
            </h1>
          </div>
          <p>
            <FormattedMessage id="PAGE404_NOT_FOUND_TEXT" />
          </p>
          <Link to="/">
            <FormattedMessage id="PAGE404_NOT_FOUND_LINK" />
          </Link>
        </div>
      </div>
    );
  }
}

export default Page404;

