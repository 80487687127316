import { FormattedMessage } from 'react-intl';

import Spinner from '../../../Spinner/Spinner';
import UserProfileTurnoverItem from './UserProfileTurnoverItem';

function UserProfileTurnover({
  total,
  cams,
  livecam_tips,
  messages,
  payttachments,
  regards,
  sexicons,
  videos,
  pictures,
  games,
  largeLoaded,
  voice_messages,
  isSupport,
}) {
  if (isSupport) {
    return null;
  }
  return (
    <div className="area user-turnover">
      <div className="turnover">
        <span>
          <FormattedMessage id="user.turnover" />
        </span>
        <span className="turnover-summary">
          {typeof total !== 'number' ? (
            <Spinner size={20} thickness={2} />
          ) : (
            total
          )}
        </span>
      </div>
      {typeof total !== 'number' ? null : (
        <table className="noHover">
          <tbody>
            <UserProfileTurnoverItem
              label="turnover.cam"
              value={cams}
              display={true}
              largeLoaded={largeLoaded}
            />
            <UserProfileTurnoverItem
              label="turnover.cam.tip"
              value={livecam_tips}
              largeLoaded={largeLoaded}
            />
            <UserProfileTurnoverItem
              label="turnover.chat"
              value={messages}
              largeLoaded={largeLoaded}
            />
            <UserProfileTurnoverItem
              label="turnover.premium.attachments"
              value={payttachments}
              largeLoaded={largeLoaded}
            />
            <UserProfileTurnoverItem
              label="turnover.regards"
              value={regards}
              largeLoaded={largeLoaded}
            />
            <UserProfileTurnoverItem
              label="turnover.sexicons"
              value={sexicons}
              largeLoaded={largeLoaded}
            />
            <UserProfileTurnoverItem
              label="turnover.video"
              value={videos}
              largeLoaded={largeLoaded}
            />
            <UserProfileTurnoverItem
              label="turnover.photo"
              value={pictures}
              largeLoaded={largeLoaded}
            />
            <UserProfileTurnoverItem
              label="turnover.games"
              value={games}
              largeLoaded={largeLoaded}
            />
            <UserProfileTurnoverItem
              label="turnover.voice.messages"
              value={voice_messages}
              largeLoaded={largeLoaded}
            />
          </tbody>
        </table>
      )}
    </div>
  );
}

export default UserProfileTurnover;
