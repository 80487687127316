import { MANAGEMENT_LOGGED_OUT, STREAMING_STOPPED } from '../../constants';
import { management } from '../';

let visibilityTimeout = null;

export default function () {
  return (dispatch, getState) => {
    if (visibilityTimeout && !document.hidden) {
      clearTimeout(visibilityTimeout);
      visibilityTimeout = null;
      return;
    }

    if (!document.hidden) return;

    visibilityTimeout = setTimeout(() => {
      const { managementStep, step } = getState().streaming;

      if (
        managementStep !== MANAGEMENT_LOGGED_OUT &&
        step !== STREAMING_STOPPED &&
        document.hidden
      ) {
        console.log('logoutCam after a few seconds not visible');
        dispatch(management.logoutCam({}, 24));
      }
      clearTimeout(visibilityTimeout);
      visibilityTimeout = null;
    }, 5000);
  };
}
