import './ChosenDocumentField.scss';

import useAxios from 'axios-hooks';
import React from 'react';
import { useIntl } from 'react-intl';

import deleteIcon from '../../../assets/img/svg/close.svg';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import Button from '../../Button/Button';

function ChosenDocumentField({
  id,
  galleryId,
  name,
  label,
  className,
  value,
  spinner,
  updateActiveGallery,
  activeGallery,
  oneDocumentNeeded,
  ...otherProps
}) {
  const intl = useIntl();

  const [, deleteDocumentRequest] = useAxios(
    {
      url: `galleries/${galleryId}/document`,
      method: 'DELETE',
      data: {
        documents: [id],
      },
    },
    { manual: true }
  );

  function deleteDocument() {
    spinnerRequest({
      request: deleteDocumentRequest,
      spinner,
    }).then(() => {
      const newDocuments = activeGallery.documents.filter(
        (document) => document.id !== id
      );
      updateActiveGallery({
        ...activeGallery,
        documents: newDocuments,
      });
    });
  }

  const translatedLabel = label ? intl.formatMessage({ id: label }) : label;

  return (
    <div className="form-group">
      {label && <label htmlFor={id || name}>{translatedLabel}</label>}
      {
        <div className="chosen-document">
          {value}
          {oneDocumentNeeded && (
            <Button
              variant="icon-only"
              className="btn-delete"
              onClick={() => deleteDocument()}
              intlTranslate={false}
              icon={deleteIcon}
            />
          )}
        </div>
      }
    </div>
  );
}

export default ChosenDocumentField;

