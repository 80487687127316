import { connect } from 'react-redux';

import { user as userUtils } from '../../../../utils';
import UserProfileInfo from './UserProfileInfo';

const mapStateToProps = (state) => {
  const activeUser = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);
  if (activeUser?.profile?.info) {
    return {
      ...activeUser?.profile?.info,
      is_support: activeUser?.flags?.includes('is_support'),
    };
  }
  if (userUtils.isAdmin(activeUser)) {
    return {
      nickname: activeUser.name,
      is_support: true,
      largeLoaded: true,
    };
  }
  return {};
};

export default connect(mapStateToProps)(UserProfileInfo);
