import { connect } from 'react-redux';

import ZipCodeSelect from './ZipCodeSelect';

const mapStateToProps = (state) => ({
  user: state.user,
  publicZip: state.user.public.zip,
});

export default connect(mapStateToProps)(ZipCodeSelect);

