export default {
  TIME_MANAGEMENT_HEADLINE: `Zeitmanagement - Tipps für mehr Effizienz und Erfolg`,
  TIME_MANAGEMENT_INTRO: `Heue widmen wir uns dem Thema Zeitmanagement und geben wertvolle Tipps für mehr
Effizienz und Erfolg. Als Creator bist Du Dein eigener Boss! Das bringt viele Vorteile mit
sich, erfordert jedoch auch eine gewisse Organisation und Selbstdisziplin. Das Drehen von
Videos, das Beantworten von Nachrichten, das Pflegen des Profils ... Das sind nur einige
offensichtliche Aufgaben. Dazu kommen organisatorische Aufgaben, Büroarbeiten und
Ähnliches. Du möchtest all Deine Ziele erreichen und dennoch selbst entscheiden können,
wann Du welche Aufgabe erledigst und vor allem wann Feierabend ist?
Hier sind ein paar Tipps zum Thema Zeitmanagement für Dich:`,

  TIME_MANAGEMENT_STEP_1_HEADER: `Persönliche Produktivitätszeiten erkennen und nutzen`,
  TIME_MANAGEMENT_STEP_1_CONTENT: `Es ist entscheidend, dass Du Dir darüber bewusstwirst, ob Du zu den "Early Birds"
(Morgenmensch) gehörst, die ihre produktivsten Stunden am Morgen haben?
Oder ob Du eher zu den "Late Risers" (Nachtmensch) zählst, die erst am Abend so richtig in
Fahrt kommen. Jeder Mensch hat individuelle Phasen, in denen er besonders
leistungsfähig ist. Nutze diese Erkenntnis, um Deinen Tagesablauf entsprechend
anzupassen und in den Produktivzeiten Deine Aufgaben anzugehen.`,
  TIME_MANAGEMENT_STEP_2_HEADER: `Art der Beschäftigung festlegen`,
  TIME_MANAGEMENT_STEP_2_CONTENT: `Entscheide, ob Deine Tätigkeit als Creator haupt- oder nebenberuflich ist. Im Nebenberuf
wirst Du um eine besonders genaue Planung nicht herumkommen, um beide Bereiche unter
einen Hut zu bekommen. Im Hauptberuf kannst Du flexibler sein, solltest aber dennoch
darauf achten, Deine Arbeitszeit nicht ausufern zu lassen.`,
  TIME_MANAGEMENT_STEP_3_HEADER: `Setze klare Ziele`,
  TIME_MANAGEMENT_STEP_3_CONTENT: `Bevor Du Deinen Zeitplan festlegst, solltest Du Dir darüber im Klaren sein, was Du erreichen
möchtest. Indem Du konkrete und messbare Ziele für den Tag, die Woche, den Monat, als
auch das Jahr setzt, schaffst Du eine klare Richtlinie für Deine Aufgaben. Dadurch bist Du in
der Lage, Deine täglichen Aktivitäten besser zu priorisieren und gleichzeitig Deine
langfristigen Ziele nicht aus den Augen zu verlieren. Mit einem klaren Ziel vor Augen fällt es
Dir leichter, fokussiert und motiviert zu bleiben. Daher ist die Festlegung von Zielen der
Schlüssel für eine effiziente Zeitplanung und langfristigen Erfolg.`,
  TIME_MANAGEMENT_STEP_4_HEADER: `Vorplanung und Automatisierung`,
  TIME_MANAGEMENT_STEP_4_CONTENT: `Analysiere Deine Routineaufgaben und finde heraus, welche davon Du automatisieren oder
vorplanen kannst. Wenn Du gerade in einer kreativen Phase steckst, empfiehlt es sich, Deine
Ideen für zukünftige Fotos oder Videos festzuhalten oder bereits die Locations zu
recherchieren oder zu notieren. Sobald Deine Foto- und Video-Galerien fertig sind, kannst
Du sie hochladen und mit einem Zeitplan versehen. Auf diese Weise kannst Du Deine Foto-
und Video Galerien zu einem von Dir festgelegten Datum veröffentlichen. Auch die Bilder
und Texte Deiner nächsten Tagebucheinträge kannst Du im Voraus erstellen, so dass Du am
jeweiligen Tag nur noch auf "veröffentlichen" klicken musst. Nutze diese Tools, um große
Arbeitsaufgaben schon im Vorfeld zu erledigen und ruhigere Phasen effizient zu nutzen.
Damit schaffst Du Dir mehr Freiraum für neue Projekte und Ideen.`,
  TIME_MANAGEMENT_STEP_5_HEADER: `Priorisiere Aufgaben`,
  TIME_MANAGEMENT_STEP_5_CONTENT: `Jede Aufgabe hat einen anderen Stellenwert und nicht alles ist gleich wichtig. Du solltest
daher entscheiden, welche Aufgaben am dringendsten oder am wichtigsten sind und diese
zuerst erledigen. So kannst Du sicherstellen, dass Du Deine Ziele effektiv verfolgst und
erreichen kannst. Es ist auch ratsam, ähnliche Aufgaben zu bündeln und sie in einem
Durchgang zu erledigen. Ein gutes Beispiel dafür ist die Vorproduktion von mehreren Fotos
oder Videos an einem Tag. Durch diese Vorgehensweise kannst Du Deine Zeit optimal nutzen
und effizient arbeiten. Also denke stets daran, die richtigen Prioritäten zu setzen und Deine
Aufgaben entsprechend zu organisieren.`,
  TIME_MANAGEMENT_STEP_6_HEADER: `Pausen einplanen`,
  TIME_MANAGEMENT_STEP_6_CONTENT: `Plane regelmäßige Pausen in Deinen Arbeitsalltag mit ein. Wenn Du merkst, dass Deine
Konzentration nachlässt, solltest Du Dich nicht zwingen, weiterzuarbeiten, da dies
kontraproduktiv sein kann. Um langfristig produktiv zu bleiben, ist es unerlässlich, kurze
Erholungspausen einzulegen. Nutze diese Pausen, um frisch und motiviert zu bleiben.
Vermeide es jedoch, in diesen Erholungsphasen nur schnell Nachrichten zu beantworten
oder andere Aufgaben zu erledigen, da dies die Regeneration Deines Geistes und Körpers
beeinträchtigen kann. Gönn Dir also bewusste Auszeiten, um effektiv und gesund arbeiten zu
können.`,
  TIME_MANAGEMENT_STEP_7_HEADER: `Feste Zeiten und Feierabend definieren`,
  TIME_MANAGEMENT_STEP_7_CONTENT: `Es ist unbestritten, dass die Flexibilität des Creator-Daseins viele Vorteile mit sich bringt.
Dennoch kann es auch äußerst hilfreich sein, klare und feste Arbeitszeiten zu definieren.
Durch diese Maßnahme schaffst Du nicht nur eine gewisse Routine, sondern gibst auch
Deinen Freunden und Deiner Familie die Möglichkeit, zu wissen, wann Du für sie erreichbar
bist. Darüber hinaus empfiehlt es sich, einen festen Zeitpunkt für Deinen Feierabend
festzulegen, um eine klare Trennung zwischen Arbeit und Privatleben zu gewährleisten. So
kannst Du besser abschalten und Deine Erholungsphasen effektiver nutzen.`,
  TIME_MANAGEMENT_CONCLUSION_HEADER: `Fazit`,
  TIME_MANAGEMENT_CONCLUSION_CONTENT: `Ein ausgewogener Zeitplan als Creator beinhaltet also eine Mischung aus Selbstkenntnis,
Disziplin, Flexibilität und strategischer Planung. Mit dieser Herangehensweise kannst Du
Deine Arbeit effizient gestalten, ohne dabei das persönliche Wohlbefinden und die
Kreativität zu vernachlässigen.`,
};
