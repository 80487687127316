import { connect } from 'react-redux';

import { TYPE_ACNG, TYPE_AC_EU, TYPE_ADMIN } from '../../../../constants/user';
import { user as userUtils } from '../../../../utils';
import UserProfileIndicator from './UserProfileIndicator';

const mapStateToProps = (state) => {
  const {
    chat: { isMessageUser },
    camUser: { camUsers },
    dialog: { dialogs },
  } = state;

  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);

  if (!activeUser) {
    return {};
  }

  const isAppsUser = userUtils.isAppUser(activeUser);
  let isACNGUser;
  let isACorACNGUser;
  let isAdmin;

  if (isMessageUser) {
    isACNGUser = !isAppsUser && activeUser.productId !== 2;
    isACorACNGUser = !isAppsUser;
    isAdmin = activeUser.flags?.includes('is_support');
  } else {
    isACNGUser = activeUser.type === TYPE_ACNG;
    isACorACNGUser = isACNGUser || activeUser.type === TYPE_AC_EU;
    isAdmin = activeUser.type === TYPE_ADMIN;
  }

  return {
    exclusive: isACorACNGUser,
    toycontrol: isACNGUser,
    galleries: isACorACNGUser,
    games: isACNGUser,
    voiceMessage: activeUser.profile?.active_features?.voice_messages,
    attachment: isACorACNGUser || !!activeUser.portals?.allowAttachments,
    payttachment: isACorACNGUser,
    virtualGift: isACorACNGUser,
    isAdmin,
  };
};

export default connect(mapStateToProps)(UserProfileIndicator);
