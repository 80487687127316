import { PAYTTACHMENT_ADD, UPLOAD_FILE, USER_LOGOUT } from '../actions/actions';

const indicator = 'payttachments';
const initialState = {
  files: [],
};
const reduce = (state = initialState, action) => {
  switch (action.type) {
    case PAYTTACHMENT_ADD:
      return { ...state, files: [...action.payload] };
    case USER_LOGOUT:
      return initialState;
    case UPLOAD_FILE:
      if (
        action?.payload?.data &&
        !action?.error &&
        action?.meta?.indicator === indicator
      ) {
        return { ...state, files: [...action.payload.data] };
      }
      return state;
    default:
      return state;
  }
};

export default reduce;

