import { connect } from 'react-redux';

import { userLogout as logout, spinner } from '../../../actions';
import UnsubscribeNewsletter from './UnsubscribeNewsletter';

const mapDispatchToProps = {
  spinner,
  logout,
};
const mapStateToProps = (state) => ({
  user: state.user,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribeNewsletter);
