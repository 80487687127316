import {
  MANAGEMENT_EVENTS_ADMIN_REMOVE,
  MANAGEMENT_EVENTS_USER_REMOVE,
  SENDER_LOGIN,
  SMILEYOVERLAY_TOGGLE,
  STREAM_STOP,
  USER_LOGGED_IN,
} from '../actions/actions';
const initialState = {
  overlay: false,
  globalPattern: '',
  smileyDirectory: {},
  smileysTop: [],
  smileysBottom: [],
  items: [
    {
      name: 'smile 1',
      position: 'top',
      style: {},
      code: ':)',
      pattern: ['\\:\\)', '\\:\\-\\)'],
    },
    {
      name: 'disappointed',
      position: 'top',
      style: {},
      code: ':-|',
      pattern: ['\\:-\\|'],
    },
    {
      name: 'sad 1',
      position: 'top',
      style: {},
      code: ':(',
      pattern: ['\\:\\(', '\\:-\\('],
    },
    {
      name: 'confused 1',
      position: 'top',
      style: {},
      code: ':-[',
      pattern: ['\\:-\\['],
    },
    {
      name: 'bigsmile 1',
      position: 'top',
      style: {},
      code: ':D',
      pattern: ['\\:D', '\\:-D'],
    },
    {
      name: 'confused 2',
      position: 'top',
      style: {},
      code: ':-S',
      pattern: ['\\:-S'],
    },
    {
      name: 'sticks out tongue 1',
      position: 'top',
      style: {},
      code: ';-(',
      pattern: [';-\\('],
    },
    {
      name: 'sticks out tongue 2',
      position: 'top',
      style: {},
      code: ':-L',
      pattern: ['\\:-L', '\\:-p', '\\:-P'],
    },
    {
      name: 'shocked 1',
      position: 'top',
      style: {},
      code: ':-O',
      pattern: ['\\:-O', '\\:-o', '\\:-0'],
    },
    {
      name: 'wink 1',
      position: 'top',
      style: {},
      code: ';)',
      pattern: [';\\)', ';-\\)'],
    },
    {
      name: 'hello',
      position: 'bottom',
      style: {
        animation: 'livecam-move250 1s steps(5) infinite',
      },
      code: '[hello]',
      pattern: ['\\[hello\\]'],
    },
    {
      name: 'bye',
      position: 'bottom',
      style: {
        animation: 'livecam-move250 1s steps(5) infinite',
      },
      code: '[bye]',
      pattern: ['\\[bye\\]'],
    },
    {
      name: 'wave',
      position: 'bottom',
      style: {
        animation: 'livecam-move250 1s steps(5) infinite',
      },
      code: '[wave]',
      pattern: ['\\[wave\\]'],
    },
    {
      name: 'thumbup',
      position: 'bottom',
      style: {
        animation: 'livecam-move500 2s steps(10) infinite',
      },
      code: '[thumbup]',
      pattern: ['\\[thumbup\\]'],
    },
    {
      name: 'erection',
      position: 'bottom',
      style: {
        animation: 'livecam-move250 1s steps(5) infinite',
      },
      code: '[erection]',
      pattern: ['\\[erection\\]'],
    },
    {
      name: 'wank',
      position: 'bottom',
      style: {
        animation: 'livecam-move450 2s steps(9) infinite',
      },
      code: '[wank]',
      pattern: ['\\[wank\\]'],
    },
    {
      name: 'blowjob',
      position: 'bottom',
      style: {
        animation: 'livecam-move500 3s steps(10) infinite',
      },
      code: '[blowjob]',
      pattern: ['\\[blowjob\\]'],
    },
    {
      name: 'cunnilingus',
      position: 'bottom',
      style: {
        animation: 'livecam-move500 3s steps(10) infinite',
      },
      code: '[cunnilingus]',
      pattern: ['\\[cunnilingus\\]'],
    },
    {
      name: 'exhibitonist',
      position: 'bottom',
      style: {
        animation: 'livecam-move200 1s steps(4) infinite',
      },
      code: '[exhibitonist]',
      pattern: ['\\[exhibitonist\\]'],
    },
    {
      name: 'laugh',
      position: 'bottom',
      style: {
        animation: 'livecam-move300 1s steps(6) infinite',
      },
      code: '[laugh]',
      pattern: ['\\[laugh\\]'],
    },
    {
      name: 'amor',
      position: 'bottom',
      style: {
        animation: 'livecam-move300 1s steps(6) infinite',
      },
      code: '[amor]',
      pattern: ['\\[amor\\]'],
    },
    {
      name: 'hdl',
      position: 'bottom',
      style: {
        animation: 'livecam-move250 1s steps(5) infinite',
      },
      code: '[hdl]',
      pattern: ['\\[hdl\\]'],
    },
    {
      name: 'lol',
      position: 'bottom',
      style: {
        animation: 'livecam-move250 1s steps(5) infinite',
      },
      code: '[lol]',
      pattern: ['\\[lol\\]'],
    },
    {
      name: 'inlove',
      position: 'bottom',
      style: {
        animation: 'livecam-move350 2s steps(7) infinite',
      },
      code: '[inlove]',
      pattern: ['\\[inlove\\]'],
    },
    {
      name: 'heart',
      position: 'bottom',
      style: {
        animation: 'livecam-move300 1s steps(6) infinite',
      },
      code: '[heart]',
      pattern: ['\\[heart\\]'],
    },
    {
      name: 'kiss',
      position: 'top',
      style: {},
      code: ':*',
      pattern: [':\\*'],
    },
    {
      name: 'cool',
      position: 'top',
      style: {},
      code: '8)',
      pattern: ['8\\)'],
    },
    {
      name: 'cry',
      position: 'top',
      style: {},
      code: ';(',
      pattern: [';\\('],
    },
    {
      name: 'speechless',
      position: 'top',
      style: {},
      code: ':|',
      pattern: [':\\|'],
    },
    {
      name: 'tongue',
      position: 'top',
      style: {},
      code: ':p',
      pattern: [':p', ':P'],
    },
    {
      name: 'blush',
      position: 'top',
      style: {},
      code: ':$',
      pattern: [':\\$'],
    },
    {
      name: 'confused',
      position: 'top',
      style: {},
      code: ':s',
      pattern: [':s', ':S'],
    },
    {
      name: 'lol2',
      position: 'top',
      style: {},
      code: ':lol:',
      pattern: [':lol:'],
    },
    {
      name: 'wasntme',
      position: 'top',
      style: {},
      code: ':wasntme:',
      pattern: [':wasntme:'],
    },
    {
      name: 'angry',
      position: 'top',
      style: {},
      code: 'x(',
      pattern: ['x\\(', 'X\\('],
    },
    {
      name: 'evil',
      position: 'top',
      style: {},
      code: ':evil:',
      pattern: [':evil:'],
    },
    {
      name: 'eek',
      position: 'top',
      style: {},
      code: '8|',
      pattern: ['8\\|'],
    },
    {
      name: 'yell',
      position: 'top',
      style: {},
      code: ':yell:',
      pattern: [':yell:'],
    },
    {
      name: 'love',
      position: 'top',
      style: {},
      code: ':love:',
      pattern: [':love:'],
    },
    {
      name: 'down',
      position: 'top',
      style: {},
      code: ':down:',
      pattern: [':down:'],
    },
  ],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SMILEYOVERLAY_TOGGLE:
      return Object.assign({}, state, { overlay: !state.overlay });
    // close the overlay if the stream is stopped
    case STREAM_STOP:
      return Object.assign({}, state, { overlay: false });
    // close the overlay if the  active visible user leaves chat
    case MANAGEMENT_EVENTS_ADMIN_REMOVE:
    case MANAGEMENT_EVENTS_USER_REMOVE:
      if (
        action.meta &&
        action.meta.nextActiveUser &&
        action.meta.nextActiveUser.userId
      ) {
        return Object.assign({}, state, { overlay: false });
      }
      return state;
    case USER_LOGGED_IN:
    case SENDER_LOGIN: {
      if (action.error || (action.meta && action.meta.start)) {
        return state;
      }
      let globalPattern = '(';
      const patternArray = [];
      const smileyDictionary = {};
      state.items.forEach((smiley) => {
        smiley.pattern.forEach((pattern) => {
          let index = pattern.replace(/\\/g, ''); // remove escape backslashes to use it as index
          smileyDictionary[index] = smiley;
          patternArray.push(pattern);
        });
      });

      globalPattern += patternArray.join('|') + '| )'; // the empty space at the end split down to a single word
      const smileysTop = state.items.filter(
        (smiley) => smiley.position === 'top'
      );
      const smileysBottom = state.items.filter(
        (smiley) => smiley.position === 'bottom'
      );

      return {
        ...state,
        globalPattern,
        smileyDictionary,
        smileysTop,
        smileysBottom,
      };
    }
    default:
      return state;
  }
};

export default reducer;
