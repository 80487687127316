import { uuid } from '../../../../utils';
import { MANAGEMENT_EVENTS_BROADCAST_MESSAGE } from '../../../actions';

export default (payload) => (dispatch, getState) => {
  const message = payload[getState().intl.locale];
  dispatch({
    type: MANAGEMENT_EVENTS_BROADCAST_MESSAGE,
    meta: { stamp: uuid() },
    payload: message,
  });
};
