import { FormattedMessage } from 'react-intl';

export default (
  <>
    {[
      'LEGAL_PRIVACY_DATA_PROTECTION',
      'LEGAL_PRIVACY_PROCESS_DATA',
      'LEGAL_PRIVACY_VISIT_WEBSITE',
      'LEGAL_PRIVACY_CREATE_ACCOUNT',
      'LEGAL_PRIVACY_GDPR_ARTICLE_9',
      'LEGAL_PRIVACY_CONTACT',
      'LEGAL_PRIVACY_COINS',
      'LEGAL_PRIVACY_COOKIES',
      'LEGAL_PRIVACY_COOKIES_PURPOSE',
      'LEGAL_PRIVACY_EVALUATIONS',
      'LEGAL_PRIVACY_GOOGLE_ANALYTICS',
      'LEGAL_PRIVACY_GOOGLE_ADWORDS',
      'LEGAL_PRIVACY_EMAILS',
      'LEGAL_PRIVACY_NEWSLETTER',
      'LEGAL_PRIVACY_CHAT',
      'LEGAL_PRIVACY_LOCATION',
      'LEGAL_PRIVACY_ACCESS_RIGHT',
      'LEGAL_PRIVACY_RECTIFIY_RIGHT',
      'LEGAL_PRIVACY_ERASURE_RIGHT',
      'LEGAL_PRIVACY_RESTRICT_RIGHT',
      'LEGAL_PRIVACY_PORTABILITY_RIGHT',
      'LEGAL_PRIVACY_OBJECT_RIGHT',
      'LEGAL_PRIVACY_SUPERVISORY_AUTHORITY',
    ].map((type, index) => (
      <div key={index}>
        <h2 className="headline">
          <FormattedMessage id={`${type}_HEADER`} />
        </h2>
        <p>
          <FormattedMessage id={`${type}`}>
            {(message) => (
              <span dangerouslySetInnerHTML={{ __html: message[0] }} />
            )}
          </FormattedMessage>
        </p>
      </div>
    ))}
  </>
);
