import './Imprint.scss';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import PageSection from '../../../PageSection/PageSection';

function Imprint() {
  return (
    <PageSection
      title={<FormattedMessage id="LEGAL_IMPRINT" />}
      className="imprint-container"
    >
      <h1 className="headline">
        <FormattedMessage id="LEGAL_IMPRINT_COMPANY" />
      </h1>

      <div className="imprint-text-container">
        <p>
          <FormattedMessage id="LEGAL_IMPRINT_ADDRESS">
            {(message) => (
              <span dangerouslySetInnerHTML={{ __html: message[0] }} />
            )}
          </FormattedMessage>
        </p>
        <p>
          <FormattedMessage id="LEGAL_IMPRINT_TAX" />
        </p>
        <p>
          <FormattedMessage id="LEGAL_IMPRINT_KVK" />
        </p>
        <p>
          <FormattedMessage id="LEGAL_IMPRINT_ODR">
            {(message) => (
              <span dangerouslySetInnerHTML={{ __html: message[0] }} />
            )}
          </FormattedMessage>
        </p>
      </div>
    </PageSection>
  );
}

export default Imprint;
