import { SALES_ADD, SALES_UPDATE } from '../actions/actions';

const initialState = {};

function salesReducer(state = initialState, action) {
  switch (action.type) {
    case SALES_UPDATE:
      return { ...action.payload };
    case SALES_ADD:
      return {
        ...state,
        total: state.total + parseInt(action.payload.coins),
        timestamp_ms: action.payload.timestamp_ms,
      };
    default:
      return state;
  }
}

export default salesReducer;
