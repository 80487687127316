import gamesSVG from '../../../../../../assets/img/svg/games.svg';
import Button from '../../../../../Button/Button';

function Usergame({ showGame, usergame, toggleUsergameView }) {
  if (!showGame) return null;
  return (
    <div className="control-item usergame-icon">
      <Button
        icon={gamesSVG}
        svgClassname={usergame ? 'active' : ''}
        onClick={toggleUsergameView}
        disabled={false}
        intlTranslate={false}
        variant="icon-only"
      />
    </div>
  );
}

export default Usergame;
