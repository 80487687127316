import './Navigator.scss';
import '../../NavigationTooltip/NavigationTooltip.scss';

import React, { useContext } from 'react';

import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../Button/Button';
import { itemTabIndexes } from '../../Content/Onboarding/Onboarding.functions';
import StepperContext from '../StepperContext';

function Navigator({
  user,
  allItemsAreValid,
  leftContent,
  areNavigationBtnsShown = true,
  isFinaliseBtnShown,
  onFinaliseBtnClick = () => {},
  openTooltip = () => {},
}) {
  const { items, currentItemIndex, setNextItemIndex, isDisabled } =
    useContext(StepperContext);

  const isMobileScreen = useMediaQuery('(max-width: 1023px)');

  const isBackButtonDisabled =
    currentItemIndex === 0 ||
    items.slice(0, currentItemIndex).every((i) => i.disabled);

  const isNextButtonDisabled =
    currentItemIndex === items.length - 1 ||
    items
      .slice(Math.min(currentItemIndex + 1, items.length - 1), items.length)
      .every((i) => i.disabled);

  function handlePrevBtnClick() {
    setNextItemIndex(() => {
      if (!items[currentItemIndex - 1].disabled) {
        return currentItemIndex - 1;
      }

      const prevAvailableIndex = items
        .slice(0, currentItemIndex)
        .reverse()
        .findIndex((i) => !i.disabled);

      return prevAvailableIndex > -1
        ? currentItemIndex - 1 - prevAvailableIndex
        : 0;
    });
  }

  function handleNextBtnClick() {
    setNextItemIndex(() => {
      if (!items[currentItemIndex + 1].disabled) {
        return currentItemIndex + 1;
      }

      const nextAvailableIndex = items.findIndex(
        (i, index) => index > currentItemIndex && !i.disabled
      );

      return nextAvailableIndex > -1 ? nextAvailableIndex : 0;
    });
  }

  return (
    <div className="stepper-navigator">
      {leftContent && <div className="support">{leftContent}</div>}
      <div className="buttons">
        {areNavigationBtnsShown && (
          <>
            {(user.account.state === 'onboarding' ||
              user.account.state === 'review_needed') &&
            !allItemsAreValid ? (
              <div
                onClick={() => {
                  openTooltip('navigationTooltip');
                }}
                className="support-image support-image-extra "
              />
            ) : null}
            <Button
              label="BUTTON_BACK"
              tabIndex={
                itemTabIndexes.get(currentItemIndex) &&
                itemTabIndexes.get(currentItemIndex).back
              }
              variant={
                isMobileScreen
                  ? 'primary'
                  : isBackButtonDisabled
                  ? 'dark'
                  : 'primary'
              }
              disabled={isBackButtonDisabled || isDisabled}
              onClick={handlePrevBtnClick}
            />

            <Button
              label="BUTTON_NEXT"
              tabIndex={
                itemTabIndexes.get(currentItemIndex) &&
                itemTabIndexes.get(currentItemIndex).next
              }
              variant={
                isMobileScreen
                  ? 'primary'
                  : isNextButtonDisabled
                  ? 'dark'
                  : 'primary'
              }
              disabled={isNextButtonDisabled || isDisabled}
              onClick={handleNextBtnClick}
            />
          </>
        )}

        {isFinaliseBtnShown && (
          <Button
            label="BUTTON_FINALISE"
            tabIndex={
              itemTabIndexes.get(currentItemIndex) &&
              itemTabIndexes.get(currentItemIndex).next + 1
            }
            variant="success"
            className="finalise-btn"
            onClick={onFinaliseBtnClick}
          />
        )}
      </div>
    </div>
  );
}

export default Navigator;

