import './NicknameAlternatives.scss';

import React from 'react';
import { useIntl } from 'react-intl';

import Tag from '../../Forms/Tag/Tag';

function NicknameAlternatives({ nicknameError, click }) {
  const intl = useIntl();

  return (
    <div className="alternatives-box">
      {nicknameError?.response?.data['alternatives'] && (
        <>
          <div className="title">
            {intl.formatMessage({ id: 'NICKNAME_ALTERNATIVE' })}
          </div>
          <div className="tags-wrapper">
            {nicknameError?.response?.data['alternatives'].map(
              (item, index) => (
                <Tag
                  name={item}
                  key={`${item}.${index}`}
                  click={() => click(item)}
                  intlTranslate={false}
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default NicknameAlternatives;

