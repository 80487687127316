import './ControlPanel.scss';

import React from 'react';

import MuteButton from '../../Content/Livecam/Streaming/MuteButton';
import StreamingInfo from '../StreamingButtonBar/StreamingInfo';

function ControlPanel() {
  return (
    <div className="top-bar streaming-control">
      <StreamingInfo />
      <div className="control-item">
        <MuteButton />
      </div>
    </div>
  );
}

export default ControlPanel;
