import React from 'react';
import { FormattedMessage } from 'react-intl';

function DiaryInfo() {
  return (
    <>
      <h2 className="headline">
        <FormattedMessage id="DIARY_INFO_HEADLINE_1" />
      </h2>
      <p>
        <FormattedMessage id="DIARY_INFO_1">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <h3 className="headline">
        <FormattedMessage id="DIARY_INFO_HEADLINE_2" />
      </h3>
      <p>
        <FormattedMessage id="DIARY_INFO_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="DIARY_INFO_3">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="DIARY_INFO_4">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
    </>
  );
}

export default DiaryInfo;
