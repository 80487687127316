import { user as userUtils } from '../../../../utils';
import { management } from '../../../';
import { MANAGEMENT_EVENTS_CAM2CAM_ENABLE } from '../../../actions';

export default (data) => (dispatch, getState) => {
  const userId = data.userId;
  const {
    camUser: { camUsers },
    streaming: { permanentCam2CamStream },
  } = getState();
  const user = userUtils.get(camUsers, userId);
  if (!user || !user.visible) {
    return;
  }
  dispatch({
    type: MANAGEMENT_EVENTS_CAM2CAM_ENABLE,
    payload: data,
  });
  if (user.active || permanentCam2CamStream) {
    dispatch(management.startCam2Cam(userId));
  }
};
