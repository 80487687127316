import { uuid } from '../../utils';
import { VERIFF_ERROR } from '../actions';
import { errorLog } from '..';

export default function (error) {
  return (dispatch) => {
    const errorObject = {
      location: 'veriff',
      error,
    };
    dispatch(errorLog(errorObject));
    dispatch({
      type: VERIFF_ERROR,
      meta: {
        stamp: uuid(),
      },
    });
  };
}
