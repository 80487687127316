import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import {
  changeCamResolution,
  changeMicrophone,
  checkBandwidth,
  resetCam,
  resetMicrophone,
  stopCam,
} from '../../../actions';
import { MANAGEMENT_LOGGED_OUT } from '../../../constants';
import { INACTIVE } from '../../../constants/bandwidth';
import StreamingOptions from './StreamingOptions';

const changeFuncs = {
  checkBandwidth,
  changeCamResolution,
  changeMicrophone,
};

const mapStateToProps = (state) =>
  Object.assign({}, state.cam, {
    disabled:
      state.streaming.managementStep !== MANAGEMENT_LOGGED_OUT ||
      state.bandwidth.check !== INACTIVE ||
      !!state.cam.checkingCam,
  });

const mapDispatchToProps = (dispatch) => ({
  handleChange: (type) => {
    return (e) => {
      const index = e.target.selectedIndex;
      let label = e.target.options[index].innerHTML;
      dispatch(changeFuncs[type](e.target.value, label));
    };
  },
  reset: () => {
    dispatch(stopCam());
    dispatch(resetCam());
    dispatch(resetMicrophone());
  },
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StreamingOptions)
);
