import { connect } from 'react-redux';

import {
  addVoyeurRoomTypes,
  changeSafeMode,
  removeVoyeurRoomTypes,
  spinner,
  toggleToycontrol,
  userUpdate,
} from '../../../../actions';
import {
  STREAMING_RECONNECTING,
  STREAMING_STARTED,
} from '../../../../constants';
import { user as userUtils } from '../../../../utils';
import Options from './Options';

const mapStateToProps = (state) => ({
  user: state.user,
  livecamAllowed: state.user?.account?.livecam === 'true',
  isMobile: state.view.isMobile,
  toycontrolActive: !!state.sender?.toycontrol?.active,
  isStreaming:
    state.streaming.step === STREAMING_STARTED ||
    state.streaming.step === STREAMING_RECONNECTING ||
    state.streaming.isReconnecting ||
    state.streaming.silentReconnect,
  toycontrolEnabled:
    state.user?.account?.toy_control === 'enabled' &&
    state.user?.account?.livecam === 'true' &&
    state.browser.thirdPartyCookiesTested &&
    state.browser.thirdPartyCookiesAllowed,
  safeMode: state.preferences.safeMode,
  gamesBlocked: userUtils.gamesBlocked(state),
});

const mapDispatchToProps = {
  spinner,
  toggleToycontrol,
  changeSafeMode,
  addVoyeurRoomTypes,
  userUpdate,
  removeVoyeurRoomTypes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
