export default {
  ATTACHMENT_UPLOAD: 'Upload',
  ATTACHMENT_TEXT_1:
    'You can manage your files and send them as an attachment with your private message or with an icebreaker to users.',
  ATTACHMENT_AVAILABLE_SLOTS: `Available file slots: <span class="bold">{number}</span>`,
  ATTACHMENT_UPLOADER_LABEL: 'New image or video:',
  AVAILABLE_FILES: 'Available files ({number})',
  ATTACHMENT_DENIED: 'We have rejected this file. Please delete this!',
  MESSAGE_ATTACHMENT: 'Attachment',
  MESSAGE_ATTACHMENT_NOT_AVAILABLE: 'The attachment is not available anymore',

  ATTACHMENT_TABLE_HEADER_PREVIEW: 'Preview',
  ATTACHMENT_TABLE_HEADER_NAME: 'Name',
  ATTACHMENT_TABLE_HEADER_SIZE: 'Size',
  ATTACHMENT_TABLE_HEADER_TYPE: 'Type',
  ATTACHMENT_TABLE_HEADER_UPLOADTIME: 'Upload time',
  ATTACHMENT_LABEL: 'Attachment',
  PREMIUM_ATTACHMENT: 'Send a premium attachment',

  DELETE_ATTACHMENT: 'Delete attachment',
  DELETE_ATTACHMENT_FAILED: 'Failed to delete the file, please try again.',
  ATTACHMENT_LABEL_NONE: 'none',
  ADD_ATTACHMENT_LABEL: `Add an attachment to your message`,
  ATTACHMENTS_NO_FILES: `You haven't uploaded any files yet.`,

  UPLOAD_LABEL: 'Select an image or video from your device',
  UPLOAD: 'Upload:',
  UPLOAD_BTN: 'Upload',
  CHOOSE_A_FILE: 'Choose a file',
  SET_PRICE: 'Set your price:',
  COINS: 'Coins',
  UPLOAD_AND_SEND: 'Upload and send file',
  SEND_FILE_AGAIN: 'Or send a file again:',
  SEND: 'Send',
  FILE_SOLD: `File is sold`,
  FILE_NOT_SOLD: `File is not sold yet`,
};

