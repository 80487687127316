import { camUser, sendSystemChatMessage } from '../../../../actions';
import userUtils from '../../../../utils/user';
import { MANAGEMENT_EVENTS_ADMIN_ADD } from '../../../actions';

export default (userInfo) => (dispatch, getState) => {
  const payload = {
    ...userInfo,
    adminName: userUtils.getAdminName(userInfo.adminName),
  };
  const params = {
    state: getState(),
    type: MANAGEMENT_EVENTS_ADMIN_ADD,
    payload,
  };
  const action = userUtils.createAddEventAction(params);
  dispatch(action);
  if (
    action?.meta?.nextActiveUser?.userId &&
    action.meta.nextActiveUser.userId !== 'system'
  ) {
    dispatch(camUser.select(action?.meta?.nextActiveUser?.userId));
  }
  dispatch(
    sendSystemChatMessage({
      id: 'systemMessage.userJoined',
      values: { user: payload.adminName },
    })
  );
};
