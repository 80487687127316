import './Password.scss';

import React, { useState } from 'react';

import eyeOffIcon from '../../../assets/img/svg/eye-off.svg';
import eyeIcon from '../../../assets/img/svg/eye.svg';
import Button from '../../Button/Button';
import Input from '../Input/Input';

function Password({ id, name, label, ...otherProps }) {
  const [isHidden, setIsHidden] = useState(true);

  function handleToggleBtnClick() {
    setIsHidden((state) => !state);
  }
  const icon = isHidden ? eyeOffIcon : eyeIcon;
  return (
    <div className="password-input">
      <Input
        id={id}
        name={name}
        type={isHidden ? 'password' : 'text'}
        {...otherProps}
      />

      <Button
        variant="icon-only"
        onClick={handleToggleBtnClick}
        intlTranslate={false}
        icon={icon}
      />
    </div>
  );
}

export default Password;

