import { default as startEvent } from './event/start';
import { default as stopEvent } from './event/stop';
import { default as rotate } from './rotate';
import { default as start } from './start';
import { default as stop } from './stop';
import { default as zoom } from './zoom';

export default {
  startEvent,
  stopEvent,
  start,
  stop,
  rotate,
  zoom,
};
