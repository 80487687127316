import { useEffect } from 'react';

let resizeTimer;
function useResize({ resize }) {
  useEffect(() => {
    resize();

    const resizeHandler = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(resize, 200);
    };

    window.removeEventListener('resize', resizeHandler); // make sure to avoid many handlers
    window.removeEventListener('orientationchange', resizeHandler);
    window.addEventListener('orientationchange', resizeHandler);
    window.addEventListener('resize', resizeHandler);
  }, [resize]);
}

export default useResize;
