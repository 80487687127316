import { connect } from 'react-redux';

import { gameUtils, user as userUtils } from '../../../../utils';
import Turn from './Turn';

const mapStateToProps = (state) => {
  const user = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);

  if (!user || userUtils.isAdmin(user)) {
    return {
      noUser: true,
    };
  }

  if (
    !user?.partner &&
    userUtils.isDialogUser(user.type) &&
    !!userUtils.hasPartner(user)
  ) {
    user.partner = userUtils.getPartner(user);
  }

  const game = gameUtils.getGame(state, user.partner);

  if (!game) {
    return {
      noUser: true,
    };
  }

  let turn =
    (game?.session?.turnId || '').replace('-', '@') === user.partner
      ? 'GAME_TURN_WAIT'
      : 'GAME_TURN_YOUR_TURN';
  if (game?.session?.status === 'decided') {
    if (game?.session?.decision === 'winner') {
      turn =
        game?.session?.winnerId.replace('-', '@') === user.partner
          ? 'GAME_LOST'
          : 'GAME_WON';
      if (game?.session?.decisionReason === 'timeout') {
        turn += '_TIMEOUT';
      }
    } else if (game?.session?.decision === 'draw') {
      turn = 'GAME_DRAW';
    }
  }
  return {
    turn,
  };
};

export default connect(mapStateToProps)(Turn);
