import {connect} from 'react-redux';
import {injectIntl} from 'react-intl';
import Notification from './Notification';
import {bindActionCreators} from 'redux';
import {closeNotification} from '../../../actions';

const mapStateToProps = state => ({notifications: [...state.notifications]});
const mapDispatchToProps = dispatch => bindActionCreators({closeNotification}, dispatch);

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Notification));
