import { connect } from 'react-redux';

import { roomType, user } from '../../../../utils';
import { getRatio } from '../../../../utils/cam';
import Overlay from './Overlay';

const mapStateToProps = (state, ownProps) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    cam: { overlayHeight: height = 0, resizing },
    sender: { roomTypes },
    cam,
  } = state;
  const ratio = getRatio(cam);
  const width = Math.ceil(height * ratio);
  let verticalBorder = 0;
  let horizontalBorder = 0;

  if (roomType.isEvent(roomTypes)) {
    return {
      ...ownProps,
      width,
      height,
      verticalBorder: Math.ceil(width * 0.33),
      horizontalBorder,
    };
  }
  const activeUser = user.activeUser([...camUsers, ...dialogs]);

  if (activeUser && activeUser.dimensions) {
    if (activeUser.dimensions.percentHeight < 1) {
      horizontalBorder = Math.ceil(
        (height * (1 - activeUser.dimensions.percentHeight)) / 2
      );
    }
    if (activeUser.dimensions.percentWidth < 1) {
      verticalBorder = Math.ceil(
        (width * (1 - activeUser.dimensions.percentWidth)) / 2
      );
    }
  }
  return {
    ...ownProps,
    width,
    height,
    verticalBorder,
    horizontalBorder,
    resizing,
  };
};

export default connect(mapStateToProps)(Overlay);
