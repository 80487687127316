import { intlReducer as intl } from 'react-intl-redux';
import { combineReducers } from 'redux';

import { SENDER_LOGOUT } from '../actions/actions';
import { lStorage } from '../services/storage';
import alert from './alert';
import attachments from './attachments';
import bandwidth from './bandwidth';
import browser from './browser';
import cam from './cam';
import cam2cam from './cam2cam';
import camUser from './camUser';
import camUserFilter from './camUserFilter';
import chat from './chat';
import checkuser from './checkuser';
import contests from './contests';
import countries from './countries';
import dialog from './dialog';
import favorites from './favorites';
import friends from './friends';
import galleries from './galleries';
import games from './games';
import ignored from './ignored';
import kpi from './kpi';
import languages from './languages';
import locales from './locales';
import log from './log';
import logFps from './logFps';
import motto from './motto';
import notifications from './notifications';
import onlineStatus from './onlineStatus';
import options from './options';
import overlays from './overlays';
import payttachments from './payttachments';
import preferences from './preferences';
import publicDetails from './publicDetails';
import sales from './sales';
import sender from './sender';
import smiley from './smiley';
import socketService from './socketService';
import sounds from './sounds';
import spinner from './spinner';
import streaming from './streaming';
import streamingstats from './streamingstats';
import tip from './tip';
import upgradetimer from './upgradetimer';
import upload from './upload';
import user from './user';
import useraudio from './useraudio';
import usercolor from './usercolor';
import usernote from './usernote';
import veriff from './veriff';
import version from './version';
import view from './view';
import viewDimensions from './viewDimensions';
import voicemessage from './voicemessage';
import webcam from './webcam';

const reducers = combineReducers({
  intl,
  user,
  browser,
  webcam,
  sender,
  cam,
  streaming,
  bandwidth,
  sounds,
  locales,
  alert,
  chat,
  camUser,
  countries,
  friends,
  galleries,
  ignored,
  languages,
  tip,
  logFps,
  checkuser,
  cam2cam,
  notifications,
  smiley,
  streamingstats,
  upgradetimer,
  useraudio,
  usercolor,
  sales,
  overlays,
  upload,
  spinner,
  view,
  viewDimensions,
  camUserFilter,
  socketService,
  dialog,
  usernote,
  options,
  attachments,
  preferences,
  payttachments,
  kpi,
  favorites,
  games,
  contests,
  version,
  publicDetails,
  motto,
  onlineStatus,
  log,
  voicemessage,
  veriff,
});

const outerReducer = (state, action) => {
  let reduced = state;
  switch (action.type) {
    case SENDER_LOGOUT:
      if (!action.meta) {
        reduced = {
          alert: state.alert.filter((a) => !!a.persistAfterLogout),
          intl: state.intl,
          view: state.view,
        };
      }
      break;
    default:
  }
  const reducedState = reducers(reduced, action);
  if (reducedState.user?.isLoggedIn) {
    lStorage.setItem('reducedState', JSON.stringify(reducedState));
  }
  return reducedState;
};

export default outerReducer;

