import * as Yup from 'yup';

export const getValidationScheme = (intl) => {
  const checkUrlsAndTags = {
    test: function (value) {
      const useme = String(value).trim();
      if (
        typeof useme === 'string' &&
        useme.match(/^.*(https?:\/\/|www\..+)+.*$/gim)
      ) {
        return this.createError({
          message: t('VALIDATION_NO_URLS'),
        });
      }
      if (typeof useme === 'string' && useme.match(/<[/?a-zA-Z]+[/?0-9]*>/g)) {
        return this.createError({
          message: t('VALIDATION_NO_TAGS'),
        });
      }

      return true;
    },
  };

  const t = (id) => intl.formatMessage({ id });

  const validationTextarea = Yup.string()
    .trim()
    .min(32, t('VALIDATION_DIARY_DESCRIPTION_TO_SHORT'))
    .max(3000, t('VALIDATION_DIARY_DESCRIPTION_TO_LONG'))
    .required(t('VALIDATION_REQUIRED_FIELD'))
    .test(checkUrlsAndTags);

  const validationTitle = Yup.string()
    .trim()
    .required(t('VALIDATION_REQUIRED_FIELD'))
    .test(checkUrlsAndTags);

  return Yup.object().shape({
    title: validationTitle,
    description: validationTextarea,
  });
};
