import { useEffect, useState } from 'react';

function useMediaQuery(query) {
  const [isMatching, setIsMatching] = useState(
    window.matchMedia(query).matches
  );

  function mediaQueryListener(e) {
    setIsMatching(e.matches);
  }

  useEffect(() => {
    const mediaQuery = window.matchMedia(query);
    //AP-889 some browser do not support the change event. Nevertheless the initial matchMedia works,
    //so that only changes of the size is not detected in these browser
    if (!mediaQuery || typeof mediaQuery.addEventListener !== 'function') {
      return;
    }
    mediaQuery.addEventListener('change', mediaQueryListener);

    return () => {
      mediaQuery.removeEventListener('change', mediaQueryListener);
    };
  }, [query]);

  return isMatching;
}

export default useMediaQuery;

