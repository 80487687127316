import './ProfileText.scss';

import { useFormikContext } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import useButtonChange from '../../../../hooks/useButtonChange';
import ButtonFieldWithErrors from '../../../Forms/ButtonFieldWithErrors';

function ProfileText({ update = () => {}, spinner = () => {} }) {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const baseChangeOpts = {
    updateField: 'public',
    requestUrl: '/user/public',
    update,
    spinner,
  };
  const updateOptions = {
    description: useButtonChange({
      ...baseChangeOpts,
      name: 'description',
    }),
    about_myself: useButtonChange({
      ...baseChangeOpts,
      name: 'about_myself',
    }),
    said_about_me: useButtonChange({
      ...baseChangeOpts,
      name: 'said_about_me',
    }),
    erotic_fantasy: useButtonChange({
      ...baseChangeOpts,
      name: 'erotic_fantasy',
    }),
  };
  return (
    <div className="profile-text-wrapper">
      <div className="headline">
        <FormattedMessage id="PROFILE_TEXT_HEADLINE_1" />
      </div>
      <div className="descriptions-wrapper">
        <ButtonFieldWithErrors
          as="textarea"
          id="description"
          name="description"
          label="FIELD_DESCRIPTION"
          maxLength="500"
          textarea={true}
          onChange={(event) => {
            setFieldTouched('description', true);
            setFieldValue('description', event.target.value);
          }}
          updateOptions={updateOptions['description']}
          invalidDataLabel="INVALID_DESCRIPTION"
        />
        <div className="form-group">
          <span className="column" />
          <p className="textarea-length-indicator">
            <FormattedMessage id="REMAINING_CHARS" />:{' '}
            {500 - values.description.length}
          </p>
        </div>
      </div>
      <div className="descriptions-wrapper">
        <ButtonFieldWithErrors
          as="textarea"
          id="about_myself"
          name="about_myself"
          label="PROFILE_TEXT_LABEL_ABOUT_MYSELF"
          maxLength="500"
          textarea={true}
          onChange={(event) => {
            setFieldTouched('about_myself', true);
            setFieldValue('about_myself', event.target.value);
          }}
          updateOptions={updateOptions['about_myself']}
          invalidDataLabel="INVALID_ABOUT_MYSELF"
        />
        <div className="form-group">
          <span className="column" />
          <p className="textarea-length-indicator">
            <FormattedMessage id="REMAINING_CHARS" />:{' '}
            {500 - values.about_myself.length}
          </p>
        </div>
      </div>
      <div className="descriptions-wrapper">
        <ButtonFieldWithErrors
          as="textarea"
          id="said_about_me"
          name="said_about_me"
          label="PROFILE_TEXT_LABEL_ABOUT_ME"
          maxLength="500"
          textarea={true}
          onChange={(event) => {
            setFieldTouched('said_about_me', true);
            setFieldValue('said_about_me', event.target.value);
          }}
          updateOptions={updateOptions['said_about_me']}
          invalidDataLabel="INVALID_ABOUT_ME"
        />
        <div className="form-group">
          <span className="column" />
          <p className="textarea-length-indicator">
            <FormattedMessage id="REMAINING_CHARS" />:{' '}
            {500 - values.said_about_me.length}
          </p>
        </div>
      </div>
      <div className="descriptions-wrapper">
        <ButtonFieldWithErrors
          as="textarea"
          id="erotic_fantasy"
          name="erotic_fantasy"
          label="PROFILE_TEXT_LABEL_EROTIC_FANTASY"
          maxLength="500"
          textarea={true}
          onChange={(event) => {
            setFieldTouched('erotic_fantasy', true);
            setFieldValue('erotic_fantasy', event.target.value);
          }}
          updateOptions={updateOptions['erotic_fantasy']}
          invalidDataLabel="INVALID_EROTIC_FANTASY"
        />
        <div className="form-group">
          <span className="column" />
          <p className="textarea-length-indicator">
            <FormattedMessage id="REMAINING_CHARS" />:{' '}
            {500 - values.erotic_fantasy.length}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ProfileText;

