import _ from 'lodash';

import StateRoute from '../components/Routing/StateRoute';

export function renderRoutes(routes, user) {
  return routes
    .filter((r) => {
      if (user?.account && r.livecam) {
        return user?.account?.state === 'confirmed';
      }
      if (r.icebreaker) {
        return user?.account?.icebreaker !== 'blocked';
      }
      return true;
    })
    .map((r, index) => {
      const RouteComponent = StateRoute;
      return <RouteComponent key={index} {...r} />;
    });
}

export function filterRouteProps(props) {
  return _.omit(props, [
    'title',
    'subtitle',
    'headline',
    'states',
    'componentNotAllowed',
  ]);
}

