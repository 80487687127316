import { lStorage } from '../../../services/storage';
import crypt from '../../../utils/crypt';
import { SENDER_LOGIN_DATA } from '../../actions';

export default () => {
  return (dispatch) => {
    let storedBandwidth = null;
    const bandwidth = lStorage.getItem(`${crypt.secretHash()}:bandwidth`);
    if (bandwidth) {
      try {
        storedBandwidth = JSON.parse(bandwidth);
      } catch (error) {
        storedBandwidth = null;
      }
    }

    const camItem = `${crypt.secretHash()}:cams`;
    const cams = lStorage.getItem(camItem);

    let storedCams = null;
    if (cams) {
      storedCams = crypt.run(cams);
    }

    const storedMute =
      String(lStorage.getItem(`${crypt.secretHash()}:mute`)) === 'true';

    dispatch({
      type: SENDER_LOGIN_DATA,
      payload: {
        storedCams,
        storedBandwidth,
        storedMute,
      },
    });
  };
};
