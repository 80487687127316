/* eslint eqeqeq: "off" */
import { COOKIE_BASENAME, COOKIE_EXPIRES_DAYS } from '../constants';

let cookieStore = {};

/**
 * Updates the cookieStore from the cookie data
 *
 * keeps the values in the cookieStore if there are some
 */
const readToStore = function (data) {
  if (typeof data !== 'string') {
    return false;
  }

  const tmpStore = {};

  data.split(',').forEach((keyValuePair) => {
    if (typeof keyValuePair === 'string') {
      const splitPair = keyValuePair.split(':');
      if (splitPair.length === 2) {
        tmpStore[splitPair[0]] = splitPair[1] + '';
      }
    }
  });

  Object.keys(tmpStore).forEach((key) => {
    if (typeof cookieStore[key] === 'undefined') {
      cookieStore[key] = tmpStore[key];
    }
  });

  return true;
};

const Cookie = {
  get: (key) => {
    readToStore(Cookie.read());

    if (typeof cookieStore[key] !== 'undefined') {
      return cookieStore[key];
    }

    return '';
  },
  add: (key, value) => {
    readToStore(Cookie.read());
    cookieStore[key] = value;
    Cookie.write();
  },

  write: () => {
    const d = new Date();

    d.setTime(d.getTime() + COOKIE_EXPIRES_DAYS * 24 * 60 * 60 * 1000);

    const expires = 'expires=' + d.toUTCString();
    const valueArray = [];

    Object.keys(cookieStore).forEach((key) => {
      let s = key + ':' + cookieStore[key];
      valueArray.push(s);
    });

    document.cookie =
      COOKIE_BASENAME +
      '=' +
      valueArray.join(',') +
      ';' +
      expires +
      ';SameSite=None; Secure';
  },

  read: (overwriteBasename) => {
    let cname = COOKIE_BASENAME;
    if (typeof overwriteBasename === 'string') {
      cname = overwriteBasename;
    }
    const name = cname + '=';
    const ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1);
      if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return '';
  },
  test: () => {
    const name = 'wsstest';

    Cookie.add(name, 'success');
    cookieStore = {};
    const test = Cookie.get(name) === 'success';

    return test;
  },
};

export default Cookie;
