import { uuid } from '../../utils';
import { muteMicrophone } from '../../utils/cam';
import { ADD_CAM } from '../actions';
import { sendSystemChatMessage } from '..';

export default (err, stream) => (dispatch, getState) => {
  if (err) {
    return dispatch({
      type: ADD_CAM,
      payload: err,
      error: true,
      meta: { stamp: uuid() },
    });
  }
  let streaming = getState().streaming;
  muteMicrophone(stream, streaming.mute);
  dispatch(sendSystemChatMessage({ id: 'systemMessage.welcome' }));
  dispatch({
    type: ADD_CAM,
    payload: { stream },
  });
};
