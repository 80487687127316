import { connect } from 'react-redux';

import { spinner } from '../../../../actions';
import Prices from './Prices';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Prices);
