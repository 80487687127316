import _ from 'lodash';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { user as userUtils } from '../utils';

const sepaSchema = Yup.object().shape({
  bank_sepa_iban: Yup.string().required(),
  bank_sepa_swift: Yup.string().required(),
});

const nonSepaSchema = Yup.object().shape({
  bank_nonsepa_iban: Yup.string().required(),
  bank_nonsepa_swift: Yup.string().required(),
  bank_nonsepa_name: Yup.string().required(),
  bank_nonsepa_address: Yup.string().required(),
  bank_nonsepa_city: Yup.string().required(),
  bank_nonsepa_country: Yup.string().required(),
});

const paxumSchema = Yup.object().shape({
  bank_pax_email: Yup.string().required(),
});

function useGetIncompleteBankDetails(user) {
  const [incompleteBankDetails, setIncompleteBankDetails] = useState(null);

  useEffect(() => {
    // Check bank details info and set incomplete state
    if (user.private?.bank_location === 'none') {
      setIncompleteBankDetails(true);
      return;
    }
    if (!userUtils.checkBankholderAddress(user.private)) {
      setIncompleteBankDetails(true);
      return;
    }
    if (
      ['same_name', 'confirmed'].indexOf(user.private?.bankholder_document) < 0
    ) {
      setIncompleteBankDetails(true);
      return;
    }
    const schemaToValidate =
      user.private?.bank_location === 'eu'
        ? sepaSchema
        : user.private?.bank_location === 'paxum'
        ? paxumSchema
        : nonSepaSchema;

    const fieldsToValidate = _.pick(user.private, schemaToValidate._nodes);
    try {
      schemaToValidate.validateSync(fieldsToValidate);
      setIncompleteBankDetails(false);
    } catch (e) {
      setIncompleteBankDetails(true);
    }
  }, [user.private]);

  return incompleteBankDetails;
}

export default useGetIncompleteBankDetails;

