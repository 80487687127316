import './ParkingCredits.scss';

import useAxios from 'axios-hooks';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { PAGINATION_PAGE_SIZE } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useTranslateTableTitles from '../../../../hooks/useTranslateTableTitles';
import Pagination from '../../../Pagination/Pagination';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import ParkingCreditsTable from '../../../Tables/ParkingCreditsTable/ParkingCreditsTable';
import {
  fullParkingCreditsTableTitles,
  mediumParkingCreditsTableTitles,
  mobileParkingCreditsTableTitles,
} from './ParkingCredits.functions';

function ParkingCredits() {
  const intl = useIntl();
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const isMediumScreen = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1599px)'
  );

  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const titles = isMobileScreen
    ? mobileParkingCreditsTableTitles
    : isMediumScreen
    ? mediumParkingCreditsTableTitles
    : fullParkingCreditsTableTitles;

  const translatedTitles = useTranslateTableTitles(titles);

  const [{ data: parkingCreditsData, loading: parkingCreditsLoading }] =
    useAxios({
      url: `/user/parking-credits?l=${PAGINATION_PAGE_SIZE}&o=${offset}`,
    });

  return (
    <div className="parking-credits-wrapper">
      <div className="table-box">
        {parkingCreditsLoading ? (
          <SpinnerComponent />
        ) : (
          <>
            <h1 className="headline">
              <FormattedMessage id="PARKING_CREDITS_REVENUE" />
            </h1>
            {parkingCreditsData ? (
              <div className="parking-credits-table">
                <ParkingCreditsTable
                  items={parkingCreditsData?.bills}
                  dateFormat={'DD/MM/YYYY'}
                  precision={2}
                  currency={parkingCreditsData?.currency}
                  translatedTitles={translatedTitles}
                  isMediumScreen={isMediumScreen}
                  isMobileScreen={isMobileScreen}
                  total={parkingCreditsData?.total}
                />
              </div>
            ) : (
              <>{intl.formatMessage({ id: 'NO_DATA' })}</>
            )}
          </>
        )}
      </div>
      {!parkingCreditsLoading && parkingCreditsData && (
        <Pagination
          offset={offset}
          totalItems={parkingCreditsData?.bill_number}
          pageNumber={pageNumber}
          setOffset={setOffset}
          setPageNumber={setPageNumber}
        />
      )}
    </div>
  );
}

export default ParkingCredits;
