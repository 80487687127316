import './BillDetails.scss';

import useAxios from 'axios-hooks';
import dayjs from 'dayjs';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router';

import BackButton from '../../../BackButton';
import Button from '../../../Button/Button';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import ArticlesCoinsTable from '../../../Tables/ArticlesCoinsTable';
import ArticlesExtrasTable from '../../../Tables/ArticlesExtrasTable';
import ModelsTableWrapper from '../../../Tables/ModelsTable';

function BillDetails({ language, spinner = () => {} }) {
  const search = useLocation().search;
  const billId = new URLSearchParams(search).get('bill_id') || '0';
  const history = useHistory();
  const intl = useIntl();

  const [{ loading, data: billDetailsData }] = useAxios({
    url: `/user/bills/detail?bill_id=${billId}`,
  });

  return (
    <div className="bill-details-wrapper">
      <div className="table-box">
        {loading ? (
          <SpinnerComponent />
        ) : (
          <>
            {' '}
            <h1 className="headline table">
              <BackButton
                onClick={() => {
                  history.goBack();
                }}
              />
              <span>
                {intl.formatMessage({ id: 'PARKING_CREDITS_BILL' })}
                {` ${
                  billDetailsData?.bill_id && billDetailsData?.bill_id
                } ${intl.formatMessage({ id: 'PAYOUT_FROM' })} ${dayjs(
                  billDetailsData?.bill_date
                ).format(language === 'de' ? 'D. MMM YYYY' : 'MMM D, YYYY')}`}
              </span>
            </h1>
            <p className="billing-period-paragraph">
              {intl.formatMessage({ id: 'PARKING_CREDITS_BILLINGPERIOD' })}
              {' : '}
              <span>
                {billDetailsData?.start && language === 'de'
                  ? dayjs(billDetailsData?.start).format(
                      'DD.MM.YYYY - HH:mm '
                    ) + 'Uhr'
                  : dayjs(billDetailsData?.start).format('MMM D, YYYY hh:mm a')}
              </span>{' '}
              {intl.formatMessage({ id: 'UNTIL' })}{' '}
              <span>
                {billDetailsData?.end && language === 'de'
                  ? dayjs(billDetailsData?.end).format('DD.MM.YYYY - HH:mm ') +
                    'Uhr'
                  : dayjs(billDetailsData?.end).format('MMM D, YYYY hh:mm a')}
              </span>
            </p>
            {billDetailsData?.articles?.coin && (
              <div className="article-coins-table">
                <ArticlesCoinsTable
                  items={Object.values(billDetailsData.articles.coin)}
                  dateFormat={'DD/MM/YYYY'}
                  results={[
                    {
                      data: intl.formatMessage({ id: 'TOTAL_TURNOVER' }),
                      width: '50%',
                    },
                    { data: billDetailsData?.coin_total, width: '50%' },
                  ]}
                  location={'bill-details'}
                  linksAvailable={false}
                />
              </div>
            )}
          </>
        )}
      </div>

      {billDetailsData && (
        <div className="models-table">
          <div className="table-box">
            <ModelsTableWrapper
              items={[
                {
                  status: 'current',
                  key: billDetailsData.affected_model,
                  rate: billDetailsData.affected_rate,
                  threshold: billDetailsData.affected_rate,
                },
              ]}
              precision={2}
              currency={billDetailsData?.currency}
              billDetailsTable={true}
              affectedRevenue={billDetailsData.affected_revenue}
            />
          </div>
        </div>
      )}

      {billDetailsData?.articles?.extra && (
        <div className="article-extras-table">
          <div className="table-box">
            <h2 className="headline">
              <FormattedMessage id="BILLING_PERIOD_CURRENT_CREDITS" />
            </h2>
            <ArticlesExtrasTable
              items={Object.values(billDetailsData.articles.extra)}
              dateFormat={'DD/MM/YYYY'}
              results={[
                { data: intl.formatMessage({ id: 'CREDITS' }), width: '50%' },
                { data: billDetailsData?.revenue_total, width: '50%' },
              ]}
              additionalInfo={{
                turnover: billDetailsData?.coin_total,
                share: billDetailsData?.affected_rate,
                premium: billDetailsData?.affected_revenue,
              }}
              precision={2}
              currency={billDetailsData?.currency}
              location={'bill-details'}
            />
          </div>
        </div>
      )}
      {billDetailsData && (
        <div className="btn-back">
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              history.goBack();
            }}
            label="BUTTON_BACK"
          />
        </div>
      )}
    </div>
  );
}

export default BillDetails;
