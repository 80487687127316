import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';

import Overlay from './Overlay';

class Cam extends React.Component {
  constructor(props) {
    super(props);
    this.checkVideo = this.checkVideo.bind(this);
    this.camContainer = React.createRef();
    if (props.videoRef) {
      this.video = props.videoRef;
    } else {
      this.video = React.createRef();
    }
    this.overlayRef = React.createRef();
    this.timer = null;
    this.resizeObserver = null;
    this.onVideoLoaded = props.onVideoLoaded || (() => {});
  }

  shouldComponentUpdate(nextProps) {
    const shouldUpdate =
      this.props.selectedCam.value !== nextProps.selectedCam.value ||
      this.props.camInitialized !== nextProps.camInitialized ||
      this.props.stream.id !== nextProps.stream.id ||
      this.props.selectedResolution.value !==
        nextProps.selectedResolution.value ||
      this.props.change !== nextProps.change ||
      this.props.motto !== nextProps.motto;
    console.debug(`Cam Component should update ${shouldUpdate}`);
    return shouldUpdate;
  }

  checkVideo() {
    if (!this.video?.current) {
      return;
    }
    if (!this.resizeObserver) {
      this.resizeObserver = new ResizeObserver((entries) => {
        clearTimeout(this.timer);
        this.timer = null;
        this.timer = setTimeout(() => {
          console.log('check video resizeobserver');
          if (
            this.video?.current &&
            this.camContainer?.current &&
            selectedCam.value !== '0' &&
            camInitialized &&
            stream.id &&
            enableOverlay
          ) {
            if (!this.video?.current) {
              return;
            }
            const delayedOverlayHeigth = Math.ceil(
              this.video.current.getBoundingClientRect().height
            );
            changeOverlayheightCam(delayedOverlayHeigth);
          }
        }, 100);
      });
      this.resizeObserver.observe(this.video.current);
    }
    const {
      selectedCam,
      camInitialized,
      enableOverlay = false,
      changeOverlayheightCam,
      stream,
    } = this.props;

    if (
      this.video?.current &&
      this.camContainer?.current &&
      selectedCam.value !== '0' &&
      camInitialized &&
      stream.id
    ) {
      this.video.current.srcObject = stream;
      if (!enableOverlay) {
        return;
      }
      setTimeout(() => {
        if (!this.video.current) {
          return;
        }
        const delayedOverlayHeigth = Math.ceil(
          this.video.current.getBoundingClientRect().height
        );
        changeOverlayheightCam(delayedOverlayHeigth);
        this.onVideoLoaded();
      }, 100);
    }
  }
  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver?.disconnect();
      this.resizeObserver = null;
    }
  }
  componentDidMount() {
    this.checkVideo();
  }

  componentDidUpdate() {
    this.checkVideo();
  }

  render() {
    const {
      selectedCam,
      camInitialized,
      enableOverlay,
      children,
      intl,
      motto,
      showMotto = true,
    } = this.props;
    return selectedCam.value !== '0' && camInitialized ? (
      <div className="cam" ref={this.camContainer}>
        {motto !== '' && showMotto ? (
          <div className="show-motto">{motto}</div>
        ) : null}
        <video id="video" autoPlay muted ref={this.video} />
        {enableOverlay && <Overlay overlayRef={this.overlayRef} />}
        {children}
      </div>
    ) : (
      <div className="cam no-cam">
        {intl.formatMessage({ id: 'cam.noCam' })}
      </div>
    );
  }
}

export default Cam;
