import axios from 'axios';

import { sonusError } from '../actions';
import { addSonusToken } from '../actions';
import store from '../store';
import api from './api';

const axiosSonusInstance = axios.create({
  baseURL: process.env.REACT_APP_SONUS_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

let promiseRunning = false;
let p;

function authSonusRequestInterceptor(request) {
  const user = store.getState().user;

  if (!user?.isLoggedIn) {
    return Promise.reject();
  }

  if (user?.sonusToken?.timeStamp && user?.sonusToken?.token) {
    const difference = Date.now() - parseInt(user.sonusToken.timeStamp, 10);
    const minutesDifference = difference / (1000 * 60);

    if (minutesDifference < 55) {
      request.headers['X-Token'] = user.sonusToken.token;
      return Promise.resolve(request);
    }
  }

  if (promiseRunning && p) {
    return new Promise((resolve, reject) => {
      p.then((data) => {
        request.headers['X-Token'] = data?.headers['X-Token'];
        resolve(request);
      }).catch((err) => reject(err));
    });
  }
  promiseRunning = true;
  p = new Promise((resolve, reject) => {
    api
      .post('/user/voicemessage/token')
      .then((res) => {
        request.headers['X-Token'] = res?.data?.token;
        store.dispatch(addSonusToken(res?.data?.token, Date.now()));
        resolve(request);
        promiseRunning = false;
      })
      .catch((err) => {
        const matches = err.message?.match(/(\d{3})/g);
        const errorNumber = matches?.length ? matches[0] : err?.message || '';
        store.dispatch(sonusError(errorNumber, true));
        reject(err);
        promiseRunning = false;
      });
  });
  return p;
}

axiosSonusInstance.interceptors.request.use(authSonusRequestInterceptor);
export default axiosSonusInstance;
