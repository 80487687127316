import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import placeholderImageDe from '../../../../assets/img/galleries_de.jpg';
import placeholderImageEn from '../../../../assets/img/galleries_en.jpg';
import placeholderImageDeMobile from '../../../../assets/img/galleries_mobile_de.jpg';
import placeholderImageEnMobile from '../../../../assets/img/galleries_mobile_en.jpg';
import sortNewOldIcon from '../../../../assets/img/svg/sort-new-old.svg';
import sortOldNewIcon from '../../../../assets/img/svg/sort-old-new.svg';
import { GALLERY_PAGINATION_SIZE } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import SearchInput from '../../../Forms/SearchInput/SearchInput';
import Pagination from '../../../Pagination/Pagination';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import ThumbnailItem from '../../../ThumbnailItem';

function MyVideos({ language }) {
  const intl = useIntl();

  const isMobileScreen = useMediaQuery('(max-width: 1023px)');

  const [items, setItems] = useState([]);
  const [galleriesSearch, setGalleriesSearch] = useState('');
  const [sortingDirection, setSortingDirection] = useState('desc');
  const initialItems = useRef([]);
  const [totalItems, setTotalItems] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [{ loading: loadingGalleries }, galleryListRequest] = useAxios(
    {
      url: `galleries?l=${GALLERY_PAGINATION_SIZE}&o=${offset}&type=movies`,
    },
    { manual: true }
  );

  const [{ loading: loadingTotal }, totalMoviesReq] = useAxios(
    {
      url: `galleries/total?type=movies`,
    },
    { manual: true }
  );

  const searchGalleriesHandler = (value) => {
    const filteredItems = initialItems.current.filter((item) => {
      if (value === '' && !item.title?.content) {
        return true;
      }
      return item.title?.content?.toLowerCase().includes(value.toLowerCase());
    });

    setItems(filteredItems);
    setGalleriesSearch(value);
  };

  const clearSearchInput = () => {
    setGalleriesSearch('');
    searchGalleriesHandler('');
  };

  const sortGalleries = () => {
    const sortedItems = [...initialItems.current];
    if (sortingDirection === 'desc') {
      sortedItems.sort((a, b) => new Date(a.created) - new Date(b.created));
      setSortingDirection('asc');
    } else {
      sortedItems.sort((a, b) => new Date(b.created) - new Date(a.created));
      setSortingDirection('desc');
    }
    setItems(sortedItems);
    initialItems.current = [...sortedItems];
  };

  const getGalleries = useCallback(() => {
    galleryListRequest()
      .then((response) => {
        initialItems.current = [...response?.data];
        setItems(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });

    totalMoviesReq()
      .then((response) => {
        setTotalItems(response.data.total);
      })
      .catch((error) => console.log(error));
  }, [galleryListRequest, totalMoviesReq]);

  useEffect(() => {
    getGalleries();
  }, [getGalleries]);

  function galleryDeleted(id) {
    const newItems = items.filter((item) => item.id !== id);
    setItems([...newItems]);
    initialItems.current = [...newItems];
  }

  return (
    <div className="gallery-container">
      {!loadingGalleries && !loadingTotal ? (
        <>
          {!!initialItems.current?.length && (
            <>
              <div className="searchbar">
                <SearchInput
                  id={'search_galleries'}
                  name={'search_galleries'}
                  placeholder={`${intl.formatMessage({
                    id: 'SEARCH',
                  })}`}
                  value={galleriesSearch}
                  className="search-galleries-input"
                  onChange={(event) =>
                    searchGalleriesHandler(event.target.value)
                  }
                  clearInput={clearSearchInput}
                />

                <ReactSVG
                  onClick={() => sortGalleries()}
                  src={
                    sortingDirection === 'asc' ? sortOldNewIcon : sortNewOldIcon
                  }
                  className={'sort-icon'}
                  wrapper="span"
                />
              </div>
              <div className="galleries-wrapper">
                {items.map((item) => (
                  <ThumbnailItem
                    item={item}
                    type="gallery"
                    key={item.id}
                    itemDeleted={galleryDeleted}
                    itemUpdated={getGalleries}
                    language={language}
                  />
                ))}
              </div>
              <Pagination
                offset={offset}
                totalItems={totalItems}
                pageNumber={pageNumber}
                setOffset={setOffset}
                setPageNumber={setPageNumber}
                paginationSize={GALLERY_PAGINATION_SIZE}
              />
            </>
          )}
        </>
      ) : (
        <SpinnerComponent />
      )}
      {initialItems?.current?.length === 0 &&
        !loadingGalleries &&
        !loadingTotal && (
          <div
            className="no-galleries"
            style={{
              backgroundImage: `url(${
                language === 'de'
                  ? isMobileScreen
                    ? placeholderImageDeMobile
                    : placeholderImageDe
                  : isMobileScreen
                  ? placeholderImageEnMobile
                  : placeholderImageEn
              })`,
            }}
          />
        )}
    </div>
  );
}

export default MyVideos;

