import { user as userUtils } from '../../utils';
import select from './select';

export default () => (dispatch, getState) => {
  const {
    dialog: { dialogs },
    camUser: { camUsers },
    camUserFilter: { filter, messagesFilter, activities },
  } = getState();

  const dialogUserFiltered = userUtils.filter(
    filter,
    messagesFilter,
    [...userUtils.visibleUser(camUsers), ...userUtils.visibleUser(dialogs)],
    activities
  );

  const firstUser = dialogUserFiltered.shift();

  if (firstUser) {
    dispatch(select(firstUser.id));
  }
};
