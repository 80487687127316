import {
  USER_LOGOUT,
  VERIFF_BAR_TOGGLE,
  VERIFF_FRAME_TOGGLE,
} from '../actions/actions';

const initialState = {
  openVeriff: false,
  showVeriffReminder: false,
};

const reduce = (state = initialState, action) => {
  switch (action.type) {
    case VERIFF_FRAME_TOGGLE:
      return {
        ...state,
        openVeriff: !state.openVeriff,
      };
    case VERIFF_BAR_TOGGLE:
      return {
        ...state,
        showVeriffReminder: !state.showVeriffReminder,
      };
    case USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default reduce;
