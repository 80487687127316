import { user as userUtils } from '../../../utils';
import { MANAGEMENT_PRIVATE_AUDIO_SESSION_REQUEST } from '../../actions';

export default (management) => () => {
  return (dispatch, getState) => {
    const {
      camUser: { camUsers },
      dialog: { dialogs },
    } = getState();
    const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);

    if (!activeUser) {
      return;
    }
    const userId = activeUser.userId;
    console.log(`Request Private Audio Session started for ${userId}`);

    management.emit('requestPrivateAudioSession', { userId });
    dispatch({
      type: MANAGEMENT_PRIVATE_AUDIO_SESSION_REQUEST,
      payload: {
        userId,
      },
    });
  };
};
