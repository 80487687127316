import './Icebreaker.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import { Field, useFormikContext } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import errorSVG from '../../../../assets/img/svg/error.svg';
import icebreakerIcon from '../../../../assets/img/svg/icebreaker.svg';
import useButtonChange from '../../../../hooks/useButtonChange';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Button from '../../../Button/Button';
import Checkbox from '../../../Forms/Checkbox/Checkbox';
import FieldErrorWrapper from '../../../Forms/FieldErrorWrapper/FieldErrorWrapper';
import Scrollbar from '../../../Scrollbar';
import Section from '../../../Section/Section';
import IcebreakerAttachments from '../IcebreakerAttachments/IcebreakerAttachments';
import { getIcebreakers } from './Icebreaker.functions';

function Icebreaker({
  isMobile,
  attachments,
  spinner,
  openOverlay,
  addOptions,
}) {
  const intl = useIntl();
  const topRef = useRef();
  const bottomRef = useRef();
  const { values, setFieldValue } = useFormikContext();
  const [updateData, setUpdateData] = useState([]);
  const [localIcebreakers, setLocalIcebreakers] = useState(getIcebreakers());
  const [icebreakersLoaded, setIcebreakersLoaded] = useState(false);
  const [
    {
      data: icebreakerData,
      error: icebreakerError,
      loading: icebreakerLoading,
    },
  ] = useAxios({
    url: '/user/icebreakers',
  });
  const [{ loading: isOptionsLoading, error: optionsError }, optionsRequest] =
    useAxios(
      {
        url: '/user/options',
        method: 'PUT',
      },
      {
        manual: true,
      }
    );
  const isLarge = useMediaQuery('(min-width: 1600px)');
  useEffect(() => {
    if (icebreakerError && !icebreakerLoading) {
      return;
    }

    if (!icebreakerData || icebreakerData.length === 0) {
      return;
    }

    if (icebreakersLoaded) {
      return;
    }

    const data = updateData.length > 0 ? updateData : icebreakerData;
    const IBDataMap = data.reduce((map, obj) => {
      map[obj.type] = obj;
      return map;
    }, {});

    const checkAttachments = [...attachments];
    checkAttachments.shift();
    const newLocalIcebreakers = localIcebreakers.map((ib) => {
      ib.data = IBDataMap[ib.type] || {};
      if (
        ib.data?.attachment &&
        checkAttachments.findIndex((a) => a.id === ib.data?.attachment_id) < 0
      ) {
        ib.data.attachment = null;
      }

      return ib;
    });
    setLocalIcebreakers(newLocalIcebreakers);
    setIcebreakersLoaded(true);
  }, [
    icebreakerData,
    icebreakerError,
    icebreakerLoading,
    localIcebreakers,
    icebreakersLoaded,
    attachments,
    updateData,
  ]);

  const updateOptions = {};
  for (let i = 0; i < localIcebreakers.length; i++) {
    let obj = localIcebreakers[i];
    const updateData = {
      requestUrl: '/user/icebreaker',
      method: 'POST',
      noUpdateField: true,
      update: (data) => {
        const changed = data.find((d) => d.type === obj.type);
        if (!changed) {
          return;
        }
        setUpdateData(data);
        setIcebreakersLoaded(false);
      },
      spinner,
      name: obj.type,
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    updateOptions[obj.type] = useButtonChange(updateData);
  }

  const icebreakerWrapper = classNames('icebreaker-wrapper', {
    'is-mobile': isMobile,
  });
  const icebreakersIds =
    updateData.length > 0
      ? updateData.map((ice) => ice.attachment_id)
      : icebreakerData
      ? icebreakerData.map((ice) => ice.attachment_id)
      : [];

  const clickHandler = (type) => {
    const newLocalIcebReakers = localIcebreakers.map((ice) => {
      if (ice.type === type) {
        ice.menu = !ice.menu;
      } else {
        ice.menu = false;
      }
      return ice;
    });
    setLocalIcebreakers(newLocalIcebReakers);
  };
  const handleIcebreakerFskEvent = async (event) => {
    const icebreaker_fsk = event.target.value;
    if (icebreaker_fsk === values.icebreaker_fsk) {
      return;
    }
    try {
      await optionsRequest({ data: { icebreaker_fsk } });
      setFieldValue('icebreaker_fsk', icebreaker_fsk);
      addOptions({ icebreaker_fsk: icebreaker_fsk });
    } catch (err) {
      console.log('icebreakerFskEvent Error', err);
    }
  };
  const scrollIntoView = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="page-wrapper" ref={topRef}>
      <Section
        title={intl.formatMessage({
          id: 'MONEYMAKERS_HEADLINE',
        })}
        className="component-section icebreaker-container"
      >
        <Scrollbar visible={!isLarge}>
          <div className={icebreakerWrapper}>
            <Scrollbar visible={isLarge}>
              <div className="data-wrapper">
                {icebreakerError && !icebreakerLoading ? (
                  <div>
                    {intl.formatMessage({ id: 'ICEBREAKER_LOADING_ERROR' })}
                  </div>
                ) : (
                  <>
                    {values?.icebreaker_fsk !== 'blocked' ? (
                      <div className="icebreaker-settings">
                        <h1 className="headline">
                          {intl.formatMessage({ id: 'ICEBREAKER_SETTINGS' })}
                        </h1>
                        <p>
                          {intl.formatMessage({ id: 'FIELD_ICEBREAKER_FSK' })}
                        </p>
                        <FieldErrorWrapper
                          name="icebreaker-fsk"
                          error={
                            optionsError
                              ? intl.formatMessage({
                                  id: 'FIELD_ICEBREAKER_SAVING_ERROR',
                                })
                              : null
                          }
                        >
                          <Field
                            as={Checkbox}
                            value="fsk16"
                            id="icebreaker_fsk16"
                            label="FIELD_ICEBREAKER_FSK16"
                            disabled={isOptionsLoading}
                            tabIndex="5"
                            checked={values?.icebreaker_fsk === 'fsk16'}
                            name="icebreaker_fsk"
                            onChange={handleIcebreakerFskEvent}
                          />
                          <Field
                            as={Checkbox}
                            id="icebreaker_fsk18"
                            value="fsk18"
                            disabled={isOptionsLoading}
                            label={
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: intl.formatMessage({
                                    id: 'FIELD_ICEBREAKER_FSK18',
                                  }),
                                }}
                              />
                            }
                            intlTranslate={false}
                            tabIndex="6"
                            checked={values?.icebreaker_fsk === 'fsk18'}
                            name="icebreaker_fsk"
                            onChange={handleIcebreakerFskEvent}
                          />
                        </FieldErrorWrapper>
                      </div>
                    ) : null}
                    <h2 className="headline">
                      {intl.formatMessage({ id: 'ICEBREAKER_HEADLINE' })}
                    </h2>
                    {!isLarge && (
                      <div
                        className="icebreaker-scroll"
                        onClick={() =>
                          bottomRef.current.scrollIntoView({
                            left: 0,
                            block: 'start',
                            behavior: 'smooth',
                          })
                        }
                      >
                        <Button
                          icon={errorSVG}
                          intlTranslate={false}
                          variant="icon-only"
                        />
                        <span className="anchor-link">
                          {intl.formatMessage({ id: 'ICEBREAKER_SCROLL' })}
                        </span>
                      </div>
                    )}
                    <p>
                      {intl.formatMessage({
                        id: 'ICEBREAKER_DUPLICATE_EXPLANATION',
                      })}
                    </p>
                  </>
                )}

                <>
                  {!icebreakerError &&
                    icebreakerData &&
                    localIcebreakers.map((i, index) => {
                      return (
                        <IcebreakerAttachments
                          key={index}
                          attachments={attachments}
                          icebreakerData={icebreakerData[index]}
                          updateData={updateData[index]}
                          updateOptions={updateOptions[i.type]}
                          clickHandler={clickHandler}
                          menuOpen={i.menu}
                          type={i.type}
                          standard={true}
                          icebreakersIds={icebreakersIds}
                          scrollIntoView={scrollIntoView}
                          index={index}
                          item={i}
                          openOverlay={openOverlay}
                          spinner={spinner}
                        />
                      );
                    })}
                </>
              </div>
            </Scrollbar>
            <Scrollbar visible={isLarge} hasBoxShadow={true}>
              <div className="info-wrapper">
                {!isMobile && (
                  <ReactSVG
                    src={icebreakerIcon}
                    wrapper="span"
                    className="icebreaker-icon"
                  />
                )}
                <h2 className="headline" ref={bottomRef}>
                  <FormattedMessage id="ICEBREAKER_INFO_HEADLINE">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </h2>
                <p>
                  <FormattedMessage id="ICEBREAKER_INFO_TEXT_1">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <p>
                  <FormattedMessage id="ICEBREAKER_INFO_TEXT_2">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <p>
                  <FormattedMessage id="ICEBREAKER_INFO_TEXT_3" />
                </p>
                <p className="bold italic">
                  <FormattedMessage id="ICEBREAKER_INFO_TEXT_4" />
                </p>
                <p>
                  <FormattedMessage id="ICEBREAKER_INFO_TEXT_5">
                    {(message) => (
                      <span dangerouslySetInnerHTML={{ __html: message[0] }} />
                    )}
                  </FormattedMessage>
                </p>
                <p className="bold">
                  {intl.formatMessage({
                    id: 'ICEBREAKER_DUPLICATE_EXPLANATION',
                  })}
                </p>
              </div>
            </Scrollbar>
          </div>
        </Scrollbar>
      </Section>
    </div>
  );
}

export default Icebreaker;

