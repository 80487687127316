import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  acceptInvitationsGames,
  declineInvitationsGames,
} from '../../../../actions';
import { gameUtils, user as userUtils } from '../../../../utils';
import GameInvitation from './GameInvitation';

const mapStateToProps = (state, ownProps) => {
  if (!ownProps.userId || !userUtils.gamesEnabled(state)) {
    return {};
  }
  const game = gameUtils.getGame(state, ownProps.userId);
  if (!gameUtils.isActiveInvitation(game)) {
    return {};
  }
  return game;
};
const mapDispatchToProps = (dispatch) => {
  return {
    ...bindActionCreators(
      {
        acceptInvitationsGames,
        declineInvitationsGames,
      },
      dispatch
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GameInvitation);
