import { MANAGEMENT_LOGGED_IN } from '../../constants';
import { NO_SERVER_AVAILABLE, STREAMING_NOT_POSSIBLE } from '../../errors';
import { uuid } from '../../utils';
import { createPublishOptions } from '../../utils/cam';
import { management } from '../';
import { STREAM_PUBLISH } from '../actions';
export default (WebRTCPublisher) => (data) => {
  return (dispatch, getState) => {
    const {
      streaming: { managementStep, availableMediaServer },
    } = getState();
    if (!WebRTCPublisher.test()) {
      if (managementStep === MANAGEMENT_LOGGED_IN) {
        dispatch(management.logoutCam({}, 11));
      }
      dispatch(management.loginCam.stop());
      return dispatch({
        type: STREAM_PUBLISH,
        error: true,
        payload: new Error(STREAMING_NOT_POSSIBLE),
        meta: {
          stamp: uuid(),
        },
      });
    }
    if (availableMediaServer.length === 0) {
      if (managementStep === MANAGEMENT_LOGGED_IN) {
        dispatch(management.logoutCam({}, 20));
      }
      dispatch(management.loginCam.stop());
      return dispatch({
        type: STREAM_PUBLISH,
        error: true,
        payload: new Error(NO_SERVER_AVAILABLE),
        meta: {
          stamp: uuid(),
        },
      });
    }
    const mediaServer = availableMediaServer[0];
    const connectionData = {
      ...data,
      ...mediaServer,
    };
    dispatch({
      type: STREAM_PUBLISH,
      payload: mediaServer,
    });
    const options = createPublishOptions(connectionData, getState());

    WebRTCPublisher.init(options, dispatch);
    WebRTCPublisher.publish(getState().streaming.stream);
  };
};
