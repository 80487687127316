import { connect } from 'react-redux';

import Diary from './Diary';

const defaultsObj = {
  title: '',
  description: '',
  date: '',
  published: false,
  image: null,
};

function getInitialValues(reduxObj) {
  let returnObj = {};
  let reduxValues = { ...reduxObj };

  for (const k in defaultsObj) {
    returnObj[k] = reduxValues[k] || defaultsObj[k];
  }

  returnObj.description = '';

  if (reduxObj?.description !== undefined) {
    returnObj.description = reduxObj.description;
  }

  returnObj.title = '';

  if (reduxObj?.title !== undefined) {
    returnObj.title = reduxObj.title;
  }

  return returnObj;
}

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state.galleries.active),
});

export default connect(mapStateToProps)(Diary);

