import './Menu.scss';

import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import logo from '../../assets/img/logo.svg';
import chevronSvg from '../../assets/img/svg/chevron.svg';
import hamburgerIcon from '../../assets/img/svg/hamburger-menu.svg';
import userlistIcon from '../../assets/img/svg/userlist.svg';
import useMediaQuery from '../../hooks/useMediaQuery';
import useOutsideClick from '../../hooks/useOutsideClick';
import { routes } from '../../routes';
import Button from '../Button/Button';
import CamMessengerSubMenu from '../Content/Livecam/SubMenu';
import CurrentSales from '../Livecam/CurrentSales';
import Scrollbar from '../Scrollbar';
import Item from './Item/Item';
import { getActiveItem } from './Menu.functions';
import SubmenuItem from './SubmenuItem/SubmenuItem';

function Menu({
  title,
  items = [],
  isSubmenuShown,
  onMenuToggle,
  userlist,
  toggleUserlistView,
  isMobile,
  additionalContent,
}) {
  const location = useLocation();
  const intl = useIntl();

  const [isCamMessenger, setIsCamMessenger] = useState(false);
  useEffect(() => {
    setIsCamMessenger(location.pathname === '/multi-messenger');
  }, [setIsCamMessenger, location]);

  const { activeItem, pageNotFound } = getActiveItem(items, location.pathname);
  const { activeItem: activeSubItem } = getActiveItem(
    activeItem?.children,
    location.pathname
  );

  const menuRef = useRef();

  const onClick = (item) => {
    if (item?.path === activeItem?.path || !isSubmenuShown) {
      onMenuToggle();
    }
  };

  const isChevronBtnShown = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1599px)'
  );

  const isMobileScreen = useMediaQuery('(max-width: 1023px)');

  const menuWrapperClassnames = classNames('menu-wrapper', {
    expanded: isSubmenuShown,
  });

  useOutsideClick(menuRef, () => {
    if (isSubmenuShown && isMobileScreen) {
      onMenuToggle();
    }
  });

  if (!title) {
    let route;

    if (activeItem.path === '/') {
      route = routes.find((r) => activeItem.path.includes(r.path));
    } else {
      route = routes.find(
        (r) =>
          r.path !== '/' &&
          (activeItem.path.includes(r.path) ||
            activeItem.path + '/'.includes(r.path))
      );
    }
    title = intl.formatMessage({
      id: route.headline,
    });
  }

  const hamburgerBtnClasses = classNames('hamburger-btn', {
    active: isSubmenuShown,
  });
  const userlistBtnClasses = classNames('userlist-btn', {
    active: userlist,
  });

  const chevronButton = (
    <Button
      type="button"
      className="chevron-btn"
      onClick={(e) => {
        onMenuToggle();
        e.stopPropagation();
      }}
      intlTranslate={false}
      variant="icon-only"
      icon={chevronSvg}
    />
  );
  return (
    <div className={menuWrapperClassnames} ref={menuRef}>
      <div className="main-menu">
        <Scrollbar visible={true}>
          <div className="hamburger-btn-container">
            <Button
              type="button"
              className={hamburgerBtnClasses}
              onClick={onMenuToggle}
              icon={hamburgerIcon}
              intlTranslate={false}
              variant="icon-only"
            />
          </div>

          {items.map((item, i) => (
            <Item
              key={i}
              item={item}
              onClick={item.disabled ? () => {} : () => onClick(item)}
              isActive={activeItem && item.path === activeItem.path}
              disabled={item.disabled}
            />
          ))}
        </Scrollbar>
      </div>
      <div className="submenu-wrapper">
        <Scrollbar visible={true}>
          <div className="submenu">
            <div className="mobile-title">
              <div className="logo-wrapper">
                <ReactSVG src={logo} wrapper="span" />
              </div>
              {isMobile && isCamMessenger && (
                <Button
                  type="button"
                  className={userlistBtnClasses}
                  onClick={(e) => {
                    onMenuToggle();
                    toggleUserlistView();
                    e.stopPropagation();
                  }}
                  icon={userlistIcon}
                  intlTranslate={false}
                  variant="icon-only"
                />
              )}
            </div>

            <div className="items">
              {!isCamMessenger && (
                <div className="submenu-header-item">
                  <span className="title">{title}</span>
                  {isChevronBtnShown && chevronButton}
                </div>
              )}
              {activeItem?.children?.map((c, index) => {
                const isActive =
                  c.path === activeSubItem?.path && !pageNotFound;
                if (c.livecamButton) {
                  return (
                    <div key={index} className="submenu-messenger">
                      <CamMessengerSubMenu
                        onMenuToggle={onMenuToggle}
                        chevronButton={isChevronBtnShown && chevronButton}
                      />
                    </div>
                  );
                }
                if (c.currentSales) {
                  return (
                    <CurrentSales
                      key={index}
                      label="SALES_TODAY"
                      classes="submenu-header-item sales no-hover"
                    />
                  );
                }
                return (
                  <SubmenuItem
                    key={index}
                    item={c}
                    isActive={isActive}
                    onMenuToggle={onMenuToggle}
                    additionalContent={
                      c.defaultPath === '/my-relationships/friends' &&
                      additionalContent
                    }
                  />
                );
              })}
            </div>
          </div>
        </Scrollbar>
      </div>
    </div>
  );
}

export default Menu;

