import './ToySlider.scss';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import Button from '../Button/Button';

let timer = null;
function Slider({ intensity = 100, setIntensityToy, activeToy, testToy }) {
  const intl = useIntl();
  const step = 25;
  const [value, setValue] = useState(intensity);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (disabled) {
      setTimeout(() => {
        setDisabled(false);
      }, 16000);
    }
  }, [disabled]);

  return (
    <div className="range">
      <input
        type="range"
        min={25}
        max={100}
        value={value}
        step={step}
        className={`track-${value}`}
        onChange={(e) => {
          setValue(e.target.value);
          clearTimeout(timer);
          timer = null;
          timer = setTimeout(() => setIntensityToy(e.target.value), 300);
        }}
      />

      <div className="range-labels">
        {[1, 2, 3, 4].map((d) => {
          const percent = d * step;
          const selected = percent === parseInt(value, 10);
          const classes = classNames(`step step-${d}`, {
            selected,
          });

          return (
            <span className={classes} key={d}>
              {percent}%
            </span>
          );
        })}
      </div>

      {activeToy !== '' ? (
        <div className="btn-container">
          <Button
            disabled={disabled}
            type="button"
            className="btn-test-toy"
            variant="primary"
            label={intl.formatMessage({ id: 'TOYCONTROL_TEST_BUTTON' })}
            intlTranslate={false}
            onClick={(e) => {
              setDisabled(true);
              testToy(activeToy);
            }}
          />
        </div>
      ) : (
        <p className="bold">
          {intl.formatMessage({ id: 'TOYCONTROL_TEST_NO_ACTIVE_TOY' })}
        </p>
      )}
    </div>
  );
}

export default Slider;
