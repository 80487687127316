import { useState } from 'react';
import { useIntl } from 'react-intl';

import { CHAT_LAYOUT_SINGLE } from '../../../../constants';
import Button from '../../../Button/Button';
import ChatTime from '../Time/Time';

function GameInvitation({
  id,
  createdAt,
  declineInvitationsGames,
  acceptInvitationsGames,
  layout,
  user,
}) {
  const intl = useIntl();
  const createdAtDate = new Date(createdAt);
  const [isLoading, setIsLoading] = useState(false);
  if (!id) {
    return null;
  }

  async function respond(decline) {
    setIsLoading(true);
    let response;

    if (!decline) {
      response = await acceptInvitationsGames(id);
    } else {
      response = await declineInvitationsGames(id);
    }

    if (response === false) {
      setIsLoading(false);
    }
  }

  return (
    <div className="chat-message type-user game-invitation-message-wrapper">
      <div className="chat-message-inner game-invitation-message">
        {layout === CHAT_LAYOUT_SINGLE && <div>{user.name}:</div>}
        <span className="message">
          {intl.formatMessage({ id: 'GAME_INVITATION_MESSAGE_HEADLINE' })}
        </span>
        <div className="game-invitation-message-buttons">
          <Button
            className="game-no-button"
            onClick={() => respond(true)}
            variant="primary"
            label="gameinvite.button.no"
            isLoading={isLoading}
          />
          <Button
            onClick={() => respond(false)}
            variant="success"
            label="gameinvite.button.yes"
            isLoading={isLoading}
          />
        </div>
      </div>
      <ChatTime date={createdAtDate} />
    </div>
  );
}

export default GameInvitation;
