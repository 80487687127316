import api from '../../utils/api';
import { SALES_UPDATE } from '../actions';

export default () => async (dispatch) => {
  try {
    const response = await api.get(`/user/coins/today`);
    dispatch({
      type: SALES_UPDATE,
      payload: {
        type: 'sales.current',
        total: response?.data?.coins,
        done: true,
        timestamp_ms: Date.now(),
      },
    });
  } catch (err) {
    console.log(err);
  }
};
