import './BankDetails.scss';

import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

import useButtonChange from '../../../../hooks/useButtonChange';
import { user as userUtils } from '../../../../utils';
import ButtonFieldWithErrors from '../../../Forms/ButtonFieldWithErrors';
import Checkbox from '../../../Forms/Checkbox/Checkbox';
import CountriesSelect from '../../../Forms/CountriesSelect';
import Field from '../../../Forms/Field';
import FieldWithErrors from '../../../Forms/FieldWithErrors/FieldWithErrors';
import Input from '../../../Forms/Input/Input';
import InputWithLabel from '../../../Forms/InputWithLabel/InputWithLabel';
import MultipleFields from '../../../Forms/MultipleFields';
import RequestSelect from '../../../Forms/RequestSelect';
import BankholderDocument from './BankholderDocument';

function BankDetails({
  update,
  spinner = () => {},
  minimum_payout,
  bank_location,
  bank_pax_email,
  bank_holder,
  bank_holder_data,
  usePrivateData,
  private_surname,
  private_forename,
  bankholder_document,
  user,
}) {
  const [bankHolderRequest, setBankHolderRequest] = useState(false);
  const [bankHolderError, setBankHolderError] = useState(false);

  const intl = useIntl();

  const [{ loading }, privateDataRequest] = useAxios(
    {
      url: '/user/private',
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  const bankLocationOptions = [
    { value: 'eu', label: 'SEPA_ACCOUNT' },
    { value: 'ext', label: 'NONE_SEPA' },
    { value: 'paxum', label: 'PAXUM' },
    { value: 'none', label: 'ACCOUNT_DETAILS_LATER' },
  ];
  const handleBankLocationChange = (event) => {
    if (bank_location === event.target.value) {
      return;
    }

    privateDataRequest({ data: { bank_location: event.target.value } })
      .then((response) => {
        update({
          private: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (loading || bankHolderRequest || bankHolderError) {
      return;
    }
    if (bank_holder_data !== bank_holder) {
      setBankHolderRequest(true);
      let data = { bank_holder };
      if (usePrivateData) {
        data.bank_forename = private_forename;
        data.bank_surname = private_surname;
      }
      privateDataRequest({ data })
        .then((response) => {
          setBankHolderError(false);
          update({
            private: response.data,
          });
          setBankHolderRequest(false);
        })
        .catch((error) => {
          const errors = error?.response?.data?.errors;
          if (
            usePrivateData &&
            (errors?.bank_forename?.[0] === 'regex' ||
              errors?.bank_surname?.[0] === 'regex')
          ) {
            setBankHolderError(true);
          }
          setBankHolderRequest(false);
          console.log(error);
        });
    }
  }, [
    bank_holder,
    bank_holder_data,
    usePrivateData,
    private_forename,
    private_surname,
    update,
    loading,
    bankHolderError,
    bankHolderRequest,
    setBankHolderRequest,
    privateDataRequest,
  ]);
  const baseChangeOpts = {
    updateField: 'private',
    requestUrl: '/user/private',
    update,
    spinner,
  };
  const updateOptions = {
    bank_forename: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_forename',
    }),
    bank_surname: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_surname',
    }),
    bank_address1: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_address1',
    }),
    bank_address2: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_address2',
    }),
    bank_sepa_iban: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_sepa_iban',
    }),
    bank_nonsepa_iban: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_nonsepa_iban',
    }),
    bank_sepa_swift: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_sepa_swift',
    }),
    bank_nonsepa_swift: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_nonsepa_swift',
    }),
    bank_nonsepa_name: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_nonsepa_name',
    }),
    bank_nonsepa_address: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_nonsepa_address',
    }),
    bank_nonsepa_city: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_nonsepa_city',
    }),
    bank_zip_city: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_zip_city',
    }),
    bank_country: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_country',
    }),
    bank_nonsepa_country: useButtonChange({
      ...baseChangeOpts,
      name: 'bank_nonsepa_country',
    }),
  };

  const disableFields = bankholder_document === 'confirmed';
  return (
    <div className="bank-details-wrapper">
      <h1 className="headline">
        <FormattedMessage id="BANK_DETAILS_HEADLINE" />
      </h1>
      <div className="data-columns">
        <div className="forms-left">
          <p>
            <FormattedMessage id="BANK_DETAILS_TEXT_1" />
          </p>
          <p className="bold">
            <FormattedMessage id="BANK_DETAILS_TEXT_2" />
          </p>
        </div>
        <div className="data-right">
          {bankHolderError && (
            <div className="bank-error error-message">
              <FormattedMessage id="BANK_ERROR" />
            </div>
          )}
          <ButtonFieldWithErrors
            as={Input}
            name="bank_forename"
            label="FIELD_FIRST_NAME"
            updateOptions={updateOptions['bank_forename']}
            invalidDataLabel="INVALID_FORENAME"
          />
          <ButtonFieldWithErrors
            as={Input}
            name="bank_surname"
            label="FIELD_LAST_NAME"
            updateOptions={updateOptions['bank_surname']}
            invalidDataLabel="INVALID_SURNAME"
          />
          <ButtonFieldWithErrors
            as={Input}
            name="bank_address1"
            label="FIELD_STREET_ADDRESS"
            updateOptions={updateOptions['bank_address1']}
            invalidDataLabel="INVALID_STREET_ADDRESS"
            disabled={disableFields}
          />
          <ButtonFieldWithErrors
            as={Input}
            name="bank_address2"
            label="FIELD_STREET_ADDRESS2"
            updateOptions={updateOptions['bank_address2']}
            invalidDataLabel="INVALID_STREET_ADDRESS_2"
            disabled={disableFields}
          />
          <MultipleFields
            fields={[
              {
                name: 'bank_zip',
                id: 'bank_zip',
                label: 'FIELD_ZIP',
                width: '25%',
              },
              {
                name: 'bank_city',
                id: 'bank_city',
                label: 'FIELD_CITY',
                width: '75%',
              },
            ]}
            commonLabel="FIELD_ZIP_AND_CITY"
            commonId={'bank_zip_city'}
            updateOptions={updateOptions['bank_zip_city']}
            invalidDataLabel="INVALID_ZIP_CITY"
            disabled={disableFields}
          />
          <FieldWithErrors
            as={CountriesSelect}
            asSelect={RequestSelect}
            name="bank_country"
            label="FIELD_LIVINGCOUNTRY"
            intlTranslate={false}
            updateOptions={updateOptions['bank_country']}
            disabled={disableFields}
          />
          {!userUtils.checkBankholderAddress(user.private) && (
            <div className="bank-error error-message">
              {intl.formatMessage({ id: 'BANK_DETAILS_ADDRESS_ERROR' })}
            </div>
          )}
          <BankholderDocument />
        </div>
      </div>
      <h2 className="headline">
        <FormattedMessage id="BANK_DETAILS_HEADLINE_2" />
      </h2>
      <div className="data-columns">
        <div className="forms-left">
          <p>
            {/* Do not remove the spans here, otherwise Google translate does not work anymore */}
            {bank_location !== 'none' ? (
              <span>
                <span className="bold">
                  {intl.formatMessage(
                    { id: 'BANK_DETAILS_MINIMUM_PAYOUT' },
                    { minimum_payout }
                  )}
                </span>
                <br />
              </span>
            ) : (
              <span></span>
            )}
            {bank_location === 'eu'
              ? intl.formatMessage({ id: 'BANK_DETAILS_PAYOUT_NO_COST' })
              : bank_location === 'ext'
              ? intl.formatMessage({ id: 'BANK_DETAILS_PAYOUT_COST' })
              : bank_location === 'none'
              ? intl.formatMessage({ id: 'BANK_DETAILS_PAYOUT_NONE' })
              : null}
          </p>
          {bankLocationOptions
            .filter((l) => bank_location === 'paxum' || l.value !== 'paxum')
            .map((l) => (
              <Field
                key={l.value}
                as={Checkbox}
                id={l.value}
                value={l.value}
                htmlFor={l.value}
                label={l.label}
                checked={bank_location === l.value}
                name="bank_location"
                intlTranslate={false}
                onChange={(event) => {
                  handleBankLocationChange(event);
                }}
              />
            ))}
        </div>
        <div className="data-right">
          {bank_location === 'paxum' && (
            <InputWithLabel
              name="bank_pax_email"
              value={bank_pax_email}
              label="FIELD_BANK_PAX_EMAIL"
              disabled={true}
            />
          )}
          {bank_location !== 'none' && bank_location !== 'paxum' && (
            <>
              {' '}
              <InputWithLabel
                name="bank_holder"
                value={bank_holder}
                label="FIELD_ACCOUNT_HOLDER"
                disabled={true}
              />
              <ButtonFieldWithErrors
                as={Input}
                name={
                  bank_location === 'eu'
                    ? 'bank_sepa_iban'
                    : 'bank_nonsepa_iban'
                }
                label="FIELD_IBAN"
                updateOptions={
                  bank_location === 'eu'
                    ? updateOptions['bank_sepa_iban']
                    : updateOptions['bank_nonsepa_iban']
                }
                invalidDataLabel="INVALID_IBAN_LABEL"
              />
              <ButtonFieldWithErrors
                as={Input}
                name={
                  bank_location === 'eu'
                    ? 'bank_sepa_swift'
                    : 'bank_nonsepa_swift'
                }
                label="FIELD_BIC"
                updateOptions={
                  bank_location === 'eu'
                    ? updateOptions['bank_sepa_swift']
                    : updateOptions['bank_nonsepa_swift']
                }
                invalidDataLabel="INVALID_BIC_SWIFT_LABEL"
              />
            </>
          )}
          {bank_location === 'ext' && (
            <>
              <ButtonFieldWithErrors
                as={Input}
                name="bank_nonsepa_name"
                label="FIELD_BANK_NAME"
                updateOptions={updateOptions['bank_nonsepa_name']}
                invalidDataLabel="INVALID_BANK_NAME"
              />
              <ButtonFieldWithErrors
                as={Input}
                name="bank_nonsepa_address"
                label="FIELD_BANK_ADDRESS"
                updateOptions={updateOptions['bank_nonsepa_address']}
                invalidDataLabel="INVALID_BANK_ADDRESS"
              />
              <ButtonFieldWithErrors
                as={Input}
                name="bank_nonsepa_city"
                label="FIELD_BANK_CITY"
                updateOptions={updateOptions['bank_nonsepa_city']}
                invalidDataLabel="INVALID_BANK_CITY"
              />
              <FieldWithErrors
                as={CountriesSelect}
                asSelect={RequestSelect}
                name="bank_nonsepa_country"
                label="FIELD_BANK_COUNTRY"
                updateOptions={updateOptions['bank_nonsepa_country']}
                intlTranslate={false}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default BankDetails;

