import classNames from 'classnames';
import { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';

import Button from '../../Button/Button';

let timeoutTimer;
function VersionUpdateAlert({ type, level, mandatory, onAbort, stamp }) {
  const intl = useIntl();
  const [timer, setTimer] = useState(0);
  const [timerStarted, setTimerStarted] = useState(false);
  const [doReload, setDoReload] = useState(false);
  const cssClasses = classNames(
    'alert',
    `alert-${type.toLowerCase().replace('_', '-')}`,
    `alert-${level.toLowerCase()}`,
    'has-headline'
  );
  useEffect(() => {
    if (timerStarted || timer > 0 || !mandatory) {
      return;
    }
    setTimer(60);
    setTimerStarted(true);

    const makeTimer = (count) => () => {
      clearTimeout(timeoutTimer);
      if (count >= 0) {
        if (count > 0) setTimer(--count);

        if (count === 0) {
          return setDoReload(true);
        }
        timeoutTimer = setTimeout(makeTimer(count), 1000);
      }
    };

    timeoutTimer = setTimeout(makeTimer(60), 1000);
  }, [timer, setTimer, timerStarted, setTimerStarted, mandatory]);

  useEffect(() => {
    doReload && window.location.reload();
  }, [doReload]);
  const reloadBtnLabel = mandatory
    ? `(${timer}) ${intl.formatMessage({
        id: 'NEW_VERSION_RELOAD',
      })}`
    : intl.formatMessage({
        id: 'NEW_VERSION_RELOAD',
      });
  return (
    <div className={cssClasses}>
      <div>
        <div>
          <div className="headline">
            <h3 className="version-update-headline">
              <FormattedMessage id="NEW_VERSION_HEADLINE" />
            </h3>
          </div>
          <FormattedMessage id="NEW_VERSION_TEXT_1" />
          {mandatory ? (
            <>
              <br />
              <FormattedMessage id="NEW_VERSION_TEXT_2" />
            </>
          ) : null}
        </div>
        <div className="alert-buttons">
          <Button
            label={reloadBtnLabel}
            className={'button-reload'}
            intlTranslate={false}
            onClick={() => window.location.reload()}
          />
          {!mandatory && (
            <Button label="NEW_VERSION_LATER" onClick={() => onAbort(stamp)} />
          )}
        </div>
      </div>
    </div>
  );
}

export default VersionUpdateAlert;
