import './Checkbox.scss';

import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

function Checkbox({
  id,
  name,
  label,
  htmlFor,
  disabled,
  checked,
  intlTranslate,
  ...other
}) {
  const wrapperClassname = classNames('input-checkbox', {
    disabled: disabled,
    checked: checked,
  });
  const intl = useIntl();
  // Equality comparison used so that only in cases where intlTranslate is explicitly set as false
  // the label is not translated.
  const translatedLabel =
    intlTranslate === false ? label : intl.formatMessage({ id: label });

  return (
    <div className={wrapperClassname}>
      <input
        type="checkbox"
        id={id || name}
        name={name}
        disabled={disabled}
        checked={checked}
        {...other}
      />

      {label && (
        <label htmlFor={htmlFor || id || name}>{translatedLabel}</label>
      )}
    </div>
  );
}

export default Checkbox;

