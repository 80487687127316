import { MENU_PAYTTACHMENTS_TOGGLE } from '../../actions';
export default function (value) {
  return (dispatch) => {
    dispatch({
      type: MENU_PAYTTACHMENTS_TOGGLE,
      payload: value,
    });
  };
}

