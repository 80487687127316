import './GalleryVideosUploader.scss';

import classNames from 'classnames';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import addIcon from '../../../assets/img/svg/add.svg';
import previewImageIcon from '../../../assets/img/svg/preview-image.svg';
import useGetVideoGalleriesDropzone from '../../../hooks/useGetVideoGalleriesDropzone';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Button from '../../Button/Button';
import ThumbnailItem from '../../ThumbnailItem/ThumbnailItem';
import { checkImage, getImageSource } from '../Uploader.functions';

function GalleryVideosUploader({
  wrapperClassName = '',
  labelClassName = '',
  isMobile = false,
  dropzoneProps,
  uploadFile,
  uploadPath,
  uploadFormData,
  indicator,
  title,
  activeGallery,
  thumbnailUrl,
  handleError,
  minDimensions,
  openPictureCropper,
  uploadThumbnail,
  videoError,
  videoSuccess,
  containsVideo,
  thumbnailComment,
}) {
  const intl = useIntl();
  // Hardcoded since there are separate uploaders, no reasons to pass them as props, can be changed in the photo uploader as well
  const acceptedVideoFileTypes = [
    '.mov',
    '.avi',
    'video/mp4',
    'video/quicktime',
    'video/x-msvideo',
    'video/x-ms-wmv',
  ];
  const acceptedThumbnailFileTypes = ['.jpg', '.jpeg', '.jpe', '.png'];
  const uploadingThumbnail = useRef(false);
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');

  const doUpload = useCallback(
    async (acceptedFiles) => {
      const data = new FormData();

      let path = uploadPath;
      acceptedFiles.forEach((file) => {
        data.append('file', file, file.name);
      });

      for (let pair of uploadFormData.entries()) {
        data.append(pair[0], pair[1]);
      }
      uploadFile(path, data, indicator, true, activeGallery?.id);
      // Setting the focus for the tab index after upload
      document.getElementById('chooser-picture-upload').focus();
    },
    [indicator, uploadFile, uploadFormData, uploadPath, activeGallery?.id]
  );

  const onDrop = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        handleError(`Error: ${rejectedFiles[0].errors[0].message}`);
      }
      if (uploadingThumbnail.current) {
        if (minDimensions?.width && minDimensions?.height) {
          try {
            const iSource = await checkImage(
              await getImageSource(acceptedFiles[0], intl),
              intl,
              minDimensions
            );
            openPictureCropper(
              acceptedFiles,
              iSource,
              uploadingThumbnail.current ? uploadThumbnail : null
            );
            uploadingThumbnail.current = false;
          } catch (error) {
            uploadingThumbnail.current = false;
            return handleError(error.message);
          }
        }
      } else {
        return doUpload(acceptedFiles);
      }
    },
    [
      doUpload,
      handleError,
      intl,
      minDimensions,
      openPictureCropper,
      uploadThumbnail,
    ]
  );

  const onDropThumbnail = useCallback(
    async (acceptedFiles, rejectedFiles) => {
      uploadingThumbnail.current = true;
      onDrop(acceptedFiles, rejectedFiles);
    },
    [onDrop]
  );

  const {
    getRootProps,
    getInputProps,
    openVideo,
    getRootPropsThumbnail,
    getInputPropsThumbnail,
    openThumbnail,
  } = useGetVideoGalleriesDropzone({
    acceptedVideoFileTypes,
    acceptedThumbnailFileTypes,
    dropzoneProps,
    onDrop,
    onDropThumbnail,
    containsVideo,
  });

  const openThumbnailUploader = () => {
    if (!activeGallery?.active?.thumbnail?.thumbnail) {
      openThumbnail();
    }
  };

  const buttonsClass = classNames('buttons', {
    'disabled-buttons': containsVideo,
  });

  return (
    <div {...getRootProps({ className: wrapperClassName })}>
      <span className={labelClassName}>
        {title ? title : intl.formatMessage({ id: 'UNNAMED_GALLERY' })}
      </span>
      <div className="gallery-inner-content">
        <input {...getInputProps()} />

        <span className="top-wrapper">
          <div className={buttonsClass}>
            <Button
              type={'button'}
              onClick={() => {
                !containsVideo && openVideo();
              }}
              icon={addIcon}
              tabIndex="1"
              id="chooser-picture-upload"
              classNamesOnly="chooser-btn"
              svgClassname="svg-wrapper"
              label="BUTTON_CHOOSE_FILE"
              addEmptySpan={true}
              disabled={containsVideo}
            />
          </div>
          <div className="uploader-description">
            {!isMobile && !thumbnailComment && (
              <p className="title">
                <FormattedMessage id="GALLERY_VIDEOS_UPLOADER_HEADLINE" />
              </p>
            )}
            {thumbnailComment ? (
              <div className="thumbnail-comment-error">
                <span className="comment-headline">
                  {intl.formatMessage({ id: 'UPLOAD_COMMENT' })}
                </span>
                <div className="text-error">{thumbnailComment}</div>
                <div className="text-error">
                  <span className="underlined" onClick={openThumbnail}>
                    {intl.formatMessage({ id: 'GALLERY_UPLOAD_NEW_PREVIEW' })}
                  </span>
                  <span className="text-error">
                    {intl.formatMessage({ id: 'GALLERY_OR_SELECT_NEW_IMAGE' })}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <p>
                  <FormattedMessage id="GALLERY_VIDEOS_UPLOADER_TEXT_1" />
                </p>
                <p>
                  <FormattedMessage id="GALLERY_UPLOADER_TEXT_2" />
                </p>
              </>
            )}
            {videoError !== '' && !isMobileScreen && (
              <div className="text-error">{videoError}</div>
            )}
            {videoSuccess !== '' && (
              <div className="text-pending">{videoSuccess}</div>
            )}
          </div>
        </span>
        <span className="bottom-wrapper">
          {videoError !== '' && isMobileScreen && (
            <div className="text-error">{videoError}</div>
          )}
          <div
            className="empty-online-item-wrapper"
            {...getRootPropsThumbnail()}
          >
            <input {...getInputPropsThumbnail()} />
            <div className="image-wrapper">
              {thumbnailUrl ? (
                <ThumbnailItem
                  item={{ file: thumbnailUrl }}
                  type="photo"
                  galleryThumbnail={true}
                  showMediumOnPreview={true}
                  openThumbnail={openThumbnail}
                />
              ) : (
                <div className="img" onClick={() => openThumbnailUploader()}>
                  <span />
                  <span className="svg-wrapper">
                    <ReactSVG src={previewImageIcon} wrapper="span" />
                  </span>
                  <span className="preview-image-label">
                    {intl.formatMessage({ id: 'GALLERY_PREVIEW_IMAGE' })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </span>
      </div>

      <div className="drop-indicator">
        <FormattedMessage id="UPLOAD_DROP_INDICATOR" />
      </div>
    </div>
  );
}

export default GalleryVideosUploader;

