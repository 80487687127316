import {
  audio,
  sendSystemChatMessage,
  camUser as userActions,
} from '../../../../actions';
import userUtils from '../../../../utils/user';
import { MANAGEMENT_EVENTS_ADMIN_REMOVE } from '../../../actions';

export default (userInfo) => (dispatch, getState) => {
  const admin = getState().camUser.camUsers.find(
    (user) => user.userId === userInfo.adminSocketId
  );
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    camUserFilter,
    sender: {
      feature: { powersender },
    },
  } = getState();
  const { action } = userUtils.createStopEventAction(
    camUsers,
    dialogs,
    MANAGEMENT_EVENTS_ADMIN_REMOVE,
    userInfo,
    powersender,
    camUserFilter
  );

  dispatch(action);

  if (
    action?.meta?.nextActiveUser?.userId &&
    action.meta.nextActiveUser.userId !== 'system'
  ) {
    dispatch(userActions.select(action?.meta?.nextActiveUser?.userId));
  }
  if (!admin || !admin.visible) {
    return;
  }

  dispatch(
    sendSystemChatMessage({
      id: 'systemMessage.userLeft',
      values: { user: admin.name },
    })
  );
  dispatch(audio.leaveShow());
};
