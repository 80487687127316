import './ToycontrolSettings.scss';

import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import appleStoreImage from '../../../../assets/img/appstore.png';
import placeholderImageDe from '../../../../assets/img/no-toy_de.jpg';
import placeholderImageEn from '../../../../assets/img/no-toy_en.jpg';
import placeholderImageDeMobile from '../../../../assets/img/no-toy-mobile_de.jpg';
import placeholderImageEnMobile from '../../../../assets/img/no-toy-mobile_en.jpg';
import googlePlayImage from '../../../../assets/img/playstore.png';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import Button from '../../../Button/Button';
import ToySlider from '../../../ToySlider';

function ToycontrolSettings({
  language,
  qr: qrCode,
  code: numberCode,
  callToycontrol,
  connected,
  active,
}) {
  const intl = useIntl();
  const isConnected = Object.keys(connected || {}).length > 0;
  const connectedText = isConnected
    ? 'TOYCONTROL_CONNECTED'
    : 'TOYCONTROL_NOT_CONNECTED';

  const connectedClass = classNames('connection', {
    connected: isConnected,
  });

  const isMobileScreen = useMediaQuery('(max-width: 1023px)');

  const appleLink =
    'https://apps.apple.com/us/app/lovense-connect/id1273067916';
  const androidLink =
    'https://play.google.com/store/apps/details?id=com.lovense.connect';

  return active ? (
    <div className="toycontrol-wrapper">
      <div className="data-full">
        <h2 className="headline">
          {intl.formatMessage({ id: 'TOYCONTROL_HEADLINE' })}
        </h2>
      </div>

      <div className="data-columns">
        <div className="data-left">
          <div className="group-container">
            <div className={connectedClass}>
              {intl.formatMessage({ id: 'TOYCONTROL_CURRENT_STATE' })}
              {': '}
              <span className="status">
                {intl.formatMessage({ id: connectedText })}
              </span>
            </div>
            <p>{intl.formatMessage({ id: 'TOYCONTROL_CONNECTION_TEXT' })}</p>
          </div>
          {isConnected ? (
            <div className="group-container">
              <h2 className="headline">
                {intl.formatMessage({ id: 'TOYCONTROL_TOY_INTENSITY' })}
              </h2>
              <p>
                {intl.formatMessage({ id: 'TOYCONTROL_TOY_INTENSITY_TEXT' })}
              </p>
              <ToySlider />{' '}
            </div>
          ) : null}
        </div>

        <div className="data-right">
          <h3>
            {intl.formatMessage({ id: 'TOYCONTROL_CONNECTION_ASSISTENT' })}
          </h3>
          <p>
            {intl.formatMessage({ id: 'TOYCONTROL_CONNECTION_DESCRIPTION' })}
          </p>
          <div className="appstore">
            <a href={appleLink} target="_blank" rel="noopener noreferrer">
              <img src={appleStoreImage} alt="App Store" />
            </a>
            <a href={androidLink} target="_blank" rel="noopener noreferrer">
              <img src={googlePlayImage} alt="Google Play" />
            </a>
          </div>
          <div className="container">
            <div
              className={classNames('container-item', {
                active: !isConnected && !qrCode,
                done: isConnected || qrCode,
              })}
            >
              <div className="left">
                <div className="step">1</div>
                <div className="container-line" />
              </div>
              <div className="container-content">
                <h4>
                  {intl.formatMessage({
                    id: 'TOYCONTROL_ASSISTENT_HEADLINE_1',
                  })}
                </h4>
                <p>
                  {intl.formatMessage({ id: 'TOYCONTROL_ASSISTENT_TEXT_1' })}
                </p>
                <>
                  {!isConnected && !qrCode ? (
                    <Button
                      onClick={callToycontrol}
                      variant="primary"
                      label="TOYCONTROL_NEXT_BTN_LABEL"
                    />
                  ) : null}
                </>
              </div>
            </div>
            <div
              className={classNames('container-item', {
                active: qrCode,
                done: isConnected && !qrCode,
              })}
            >
              <div className="left">
                <div className="step">2</div>
                <div className="container-line" />
              </div>
              <div className="container-content">
                <h4>
                  {intl.formatMessage({
                    id: 'TOYCONTROL_ASSISTENT_HEADLINE_2',
                  })}
                </h4>
                <p>
                  {numberCode ? (
                    <span>
                      {intl.formatMessage({
                        id: 'TOYCONTROL_ASSISTENT_TEXT_2',
                      })}
                      <span className="bold">{`: ${numberCode}`}</span>
                    </span>
                  ) : null}
                </p>
                {qrCode && <img src={qrCode} alt="QR Code" />}
              </div>
            </div>
            <div
              className={classNames('container-item', {
                done: isConnected && !qrCode,
              })}
            >
              <div className="left">
                <div className="step">3</div>
              </div>
              <div className="container-content">
                <h4>
                  {intl.formatMessage({
                    id: 'TOYCONTROL_ASSISTENT_HEADLINE_3',
                  })}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className="no-toy"
      style={{
        backgroundImage: `url(${
          language === 'de'
            ? isMobileScreen
              ? placeholderImageDeMobile
              : placeholderImageDe
            : isMobileScreen
            ? placeholderImageEnMobile
            : placeholderImageEn
        })`,
      }}
    />
  );
}

export default ToycontrolSettings;

