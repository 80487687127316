import { FormattedMessage } from 'react-intl';

function SignUpText() {
  return (
    <div className="desc-text-wrapper">
      <h2 className="headline">
        <FormattedMessage id="SIGNUP_TEXT_HEADLINE" />
      </h2>

      <p>
        <FormattedMessage id="SIGNUP_TEXT_CONTENT_1" />
      </p>

      <p>
        <FormattedMessage id="SIGNUP_TEXT_CONTENT_2" />
      </p>

      <p>
        <FormattedMessage id="SIGNUP_TEXT_CONTENT_3" />
      </p>

      <p>
        <FormattedMessage id="SIGNUP_TEXT_CONTENT_4" />
      </p>

      <p>
        <FormattedMessage id="SIGNUP_TEXT_CONTENT_5" />
      </p>
    </div>
  );
}

export default SignUpText;
