export default (WebRTCPublisher) => (bandwidth) => {
  return (dispatch, getState) => {
    const {
      streaming: { currentBandwidth },
    } = getState();
    console.log(
      `change bandwidth called with ${bandwidth}, currentBandwidth is ${currentBandwidth}`
    );
    if (currentBandwidth !== bandwidth) {
      WebRTCPublisher.changeBandwidth(bandwidth);
    }
  };
};
