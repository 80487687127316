import './ImageItem.scss';

import classNames from 'classnames';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

function ImageItem({ src, labelTitle, labelContent, status }) {
  const imageRef = useRef();
  const titleRef = useRef();

  const className = classNames('uploader-image-item', {
    'has-label': !!labelTitle,
  });

  const labelTitleClassName = classNames('badge', {
    confirmed: status === 'confirmed',
    pending: status === 'pending',
    rejected: status === 'rejected',
    disabled: status === 'disabled',
  });

  const labelContentCommentClassName = classNames('label-content-comment', {
    rejected: status === 'rejected',
    disabled: status === 'disabled',
  });

  return (
    <div className={className}>
      <div className="image-wrapper">
        <div
          className="img"
          style={{
            backgroundImage: `url('${src}')`,
          }}
        >
          <img ref={imageRef} src={src} alt="" className="display-none" />
        </div>
        {labelTitle && (
          <span ref={titleRef} className={labelTitleClassName}>
            {labelTitle}
          </span>
        )}
      </div>

      {labelTitle && labelContent && (
        <div className="label-content">
          <div className="label-content-headline">
            <FormattedMessage id="UPLOAD_COMMENT" />
          </div>
          <div className={labelContentCommentClassName}>{labelContent}</div>
        </div>
      )}
    </div>
  );
}

export default ImageItem;

