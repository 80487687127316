import { STREAMING_IS_STARTING } from '../../../constants';
import { management, stream } from '../../';
import { STREAM_STOP } from '../../actions';

export default (WebRTCPublisher) =>
  (close = true) => {
    return (dispatch, getState) => {
      const {
        streaming: { step, isReconnecting, silentReconnect },
      } = getState();
      console.log('Stop Stream called');
      if (step === STREAMING_IS_STARTING && close) {
        dispatch(management.loginCam.stop());
        dispatch({
          type: STREAM_STOP,
          meta: {
            isReconnecting,
            silentReconnect,
          },
        });
      }
      WebRTCPublisher.stop();
      dispatch(stream.timerCheck.stop());
    };
  };
