import { connect } from 'react-redux';

import { userUpdate as update } from '../../../../../actions';
import OnboardingDoi from './OnboardingDoi';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  update,
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingDoi);
