import { SENDER_LOGIN } from '../../actions';

export default ({
  error = '',
  modal = false,
  id = '',
  meta = true,
  links = null,
}) => {
  const action = {
    type: SENDER_LOGIN,
    error: true,
    payload: error,
  };
  if (meta) {
    action.meta = { id, modal, scope: 'livecam' };
    if (links) {
      action.meta.links = links;
    }
  }
  return action;
};
