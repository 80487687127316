import { CHAT_LAYOUT_MULTI } from '../../constants';
import { sender as senderService } from '../../services';
import { objectUtils as _, uuid } from '../../utils';
import { requestCamPermissions } from '../../utils/cam';
import {
  browserNotAllowed,
  changeLayoutChat,
  changeMicrophone,
  changedCam,
  chooseCam,
  endLoadingCam,
  initCam,
  requestFailedCamPermissions,
  resetCam,
  resetMicrophone,
  saveServerResolutions,
  startLoadingCam,
  testCam,
} from '../';

const findMic = (deviceInfos, selectedMicrophone) => {
  if (!selectedMicrophone) {
    return false;
  }
  const microphones = deviceInfos.filter(
    (device) => device.kind === 'audioinput'
  );
  let foundMic = microphones.find(
    (mic) =>
      mic.deviceId === selectedMicrophone.value ||
      mic.label === selectedMicrophone.label
  );
  if (
    !foundMic &&
    /Microphone \d/gi.test(selectedMicrophone.label) &&
    microphones.length > 0
  ) {
    const index = parseInt(selectedMicrophone.label.split(' ').pop()) - 1;
    if (!isNaN(index) && typeof microphones[index] === 'object') {
      foundMic = microphones[index];
    }
  }
  return !foundMic ? false : foundMic.deviceId;
};

const getCams = (deviceInfos) =>
  deviceInfos.filter((device) => device.kind === 'videoinput');

const findCam = (cams, selectedCam) => {
  let foundCam = cams.find(
    (cam) =>
      cam.deviceId === selectedCam.value || cam.label === selectedCam.label
  );
  if (!foundCam && /Cam \d/gi.test(selectedCam.label) && cams.length > 0) {
    const index = parseInt(selectedCam.label.split(' ').pop()) - 1;
    if (!isNaN(index) && typeof cams[index] === 'object') {
      foundCam = cams[index];
    }
  }
  return foundCam;
};

const filterServerResolutions = (serverResolutions) =>
  serverResolutions.map((fpsResolution) => ({
    fps: fpsResolution.fps,
    resolution: fpsResolution.resolution.map((res) => ({
      width: res.width,
      height: res.height,
    })),
  }));

export default () => async (dispatch, getState) => {
  const state = getState();
  const {
    cam: {
      selectedCam,
      selectedMicrophone,
      resolutions,
      oldServerResolutions,
      serverResolutions,
    },
    browser,
  } = state;

  if (!browser.isAllowed) {
    dispatch(browserNotAllowed());
    return;
  }

  if (
    state.preferences.chatlayout === 'single' &&
    !state.sender.feature.powersender
  ) {
    dispatch(changeLayoutChat(CHAT_LAYOUT_MULTI));
  }

  const checkForPermission = navigator.mediaDevices.getUserMedia.bind(
    navigator.mediaDevices
  );

  const stamp = uuid();

  dispatch(startLoadingCam(stamp));

  const sortDesc = (a, b) => {
    if (a.bandwidth < b.bandwidth) return 1;
    if (a.bandwidth > b.bandwidth) return -1;
    return 0;
  };
  const resolutionsToSort = serverResolutions[0]?.resolution || [];
  const sortedResolutions = [...resolutionsToSort].sort(sortDesc);

  const logger = senderService.logger();
  const permissionResult = await requestCamPermissions(
    sortedResolutions,
    checkForPermission,
    dispatch,
    testCam,
    stamp,
    logger
  );

  dispatch(endLoadingCam(stamp));

  if (!permissionResult) {
    return dispatch(requestFailedCamPermissions());
  }

  try {
    const deviceInfos = await navigator.mediaDevices.enumerateDevices();
    const cams = getCams(deviceInfos);
    const foundCam = findCam(cams, selectedCam);
    const microphone = findMic(deviceInfos, selectedMicrophone);
    dispatch(saveServerResolutions(serverResolutions));
    if (!microphone) {
      dispatch(resetMicrophone());
    }

    if (
      !foundCam ||
      !resolutions ||
      !resolutions.length ||
      !oldServerResolutions ||
      !serverResolutions ||
      !_.equal(
        filterServerResolutions(serverResolutions),
        filterServerResolutions(oldServerResolutions)
      )
    ) {
      dispatch(resetCam({ showAlert: true }));
      return dispatch(initCam(null, deviceInfos));
    }
    dispatch(saveServerResolutions(serverResolutions));
    dispatch(initCam(null, deviceInfos));

    if (foundCam.deviceId !== selectedCam.value) {
      dispatch(changedCam(foundCam.deviceId, foundCam.label));
    }
    if (microphone !== selectedMicrophone.value) {
      dispatch(changeMicrophone(microphone, selectedMicrophone.label, false));
    }
    dispatch(chooseCam());
  } catch (error) {
    dispatch(initCam(error));
  }
};
