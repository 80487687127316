import { connect } from 'react-redux';

import {
  openOverlay,
  spinner,
  userUpdate as update,
} from '../../../../actions';
import PersonalDetails from './PersonalDetails';

const mapDispatchToProps = {
  update,
  spinner,
  openOverlay,
};

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  user: state.user,
  countries: state.countries,
});

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);

