import { connect } from 'react-redux';

import {
  errorDisabledMicrophone,
  loadingVoicemessage,
  playVoicemessage,
  sendChatMessage,
  sonusError,
  stopVoicemessage,
} from '../../../../../actions';
import VoiceRecorderControl from './VoiceRecorderControl';

const mapStateToProps = (state) => ({
  voicemessagePlaying: state.voicemessage.playing,
  sendername: state.user?.account?.nickname,
});

const mapDispatchToProps = {
  sendChatMessage,
  sonusError,
  playVoicemessage,
  stopVoicemessage,
  errorDisabledMicrophone,
  loadingVoicemessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VoiceRecorderControl);

