import './Badge.scss';

import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

function Badge({ item }) {
  const intl = useIntl();
  const state = item?.state;
  let dateString = item?.dateString;

  const badgeClassName = classNames('badge', {
    blue: state === 'archive',
    green: state === 'current' || state === 'schedule',
    orange: state === 'applied',
  });

  let badgeText;
  switch (state) {
    case 'archive':
      badgeText = intl.formatMessage({ id: 'FMOTD_BADGE_ARCHIVE' });
      break;
    case 'current':
      badgeText = intl.formatMessage({ id: 'FMOTD_BADGE_CURRENT' });
      break;
    case 'schedule':
      badgeText = intl.formatMessage({ id: 'FMOTD_BADGE_SCHEDULE' });
      break;
    case 'applied':
      badgeText = intl.formatMessage({ id: 'FMOTD_BADGE_APPLIED' });
      break;
    default:
      badgeText = '';
  }
  return (
    <span className={badgeClassName}>
      {badgeText}
      {state === 'archive' || state === 'schedule' ? (
        <span className="date">{dateString}</span>
      ) : null}
    </span>
  );
}

export default Badge;
