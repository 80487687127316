import useAxios from 'axios-hooks';
import { useField } from 'formik';

import { spinnerRequest } from '../shared/spinnerRequest';

function useButtonChange({
  validate,
  updateField,
  name,
  requestUrl,
  update,
  spinner,
  method,
  noUpdateField,
  action,
}) {
  const [{ loading: isDataLoading, error: dataRequestError }, dataRequest] =
    useAxios(
      {
        url: requestUrl,
        method: method ? method : 'PUT',
      },
      {
        manual: true,
      }
    );

  const [, meta, helper] = useField(name);
  const change = (data) => {
    if (validate) {
      try {
        validate.validateSync(data);
      } catch (error) {
        !meta.touched && helper.setTouched(true);
        helper.setError(error.message);
        return;
      }
    }

    if (typeof action === 'function') {
      return action(data);
    }

    spinnerRequest({
      request: dataRequest,
      spinner,
      payload: { data },
    })
      .then((response) => {
        if (noUpdateField) {
          update(response.data);
        } else {
          update({
            [updateField]: response.data,
          });
        }
      })
      .catch((error) => {
        console.log('[Error]:', error);
        !meta.touched && helper.setTouched(true);
        helper.setError(error.message);
      });
  };
  return {
    change,
    isDataLoading,
    dataRequestError,
    action,
  };
}

export default useButtonChange;
