import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { cam2cam } from '../../../../actions';
import {
  roomType as roomTypeUtils,
  user as userUtils,
} from '../../../../utils';
import Cam2Cam from './Cam2Cam';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    cam2cam,
    sender: { roomTypes },
    view: { isMobile },
  } = state;
  const {
    userId,
    cam2camRotate: rotate,
    cam2camZoom: zoom,
  } = userUtils.activeUser([...camUsers, ...dialogs]) || {};
  const streams = cam2cam;
  const stream = userId && streams[userId] ? streams[userId] : null;
  const running = !!stream;
  return {
    isEvent: roomTypeUtils.isEvent(roomTypes),
    stream,
    running,
    rotate,
    isMobile,
    zoom,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onRotateClick: () => dispatch(cam2cam.rotate()),
  onZoomClick: () => dispatch(cam2cam.zoom()),
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(Cam2Cam)
);
