export default {
  'livecam.settings.headline':
    'Hier kannst Du Einstellungen für die Livecam vornehmen oder ändern',
  'streamingOptions.title': 'Stream Einstellungen',
  'streamingOptions.cam': 'Webcam',
  'streamingOptions.fps': 'Bilder pro Sekunde',
  'streamingOptions.resolution': 'Auflösung',
  'streamingOptions.mic': 'Mikrofon',
  'streamingOptions.selectCam': 'Wähle eine Webcam aus',
  'streamingOptions.noMic': 'Kein Mikrofon',
  'cam.noCam':
    'Keine Webcam gefunden. Bitte schließe eine Webcam an und starte die Software erneut.',
  'bandwidthCheck.title': 'Bandbreite prüfen',
  'bandwidthCheck.loading': 'Ladevorgang läuft:',
  'bandwidthCheck.current': 'Aktuelle Bandbreite:',
  'bandwidthCheck.average': 'Durchschnittliche Bandbreite:',
  'check.bandwidth': 'Bandbreite prüfen',
  'bandwidthCheck.check': 'Bandbreite prüfen {suffix}',

  'chatTemplates.title': 'Voreingestellte Texte für die F-Tasten',
  'chatTemplates.key': 'Taste',
  'chatTemplates.text': 'Text',

  'userColors.title': 'User Farben',
  'showSendername.title': 'Eigenen Namen anzeigen',
  'fontSize.title': 'Schriftgröße',

  'info.setting.video.title': `Video Livecam Einstellungen`,
  'info.setting.title': 'Die Bedeutung der Einstellungen',
  'info.text.streamSettings':
    '<span class="bold">Wichtige Hinweise zu den Stream Einstellungen</span><br />Wenn eine Webcam bzw. ein Webcamsplitter installiert ist und die Nutzung im Browser erlaubt wurde, wird diese im Auswahlmenü angezeigt. Bei mehreren Webcamsplittern oder Webcams hast Du die Möglichkeit eine auszuwählen.',
  'info.text_2.streamSettings':
    'Windows Nutzer: Bei mehreren Anwendungen, die gleichzeitig auf die Webcam zugreifen, muss zwingend ein Webcamsplitter installiert sein, da nur über diesen das Videosignal verteilt werden kann.',
  'info.text_3.streamSettings':
    'Wähle hier auch die Videoauflösung, mit der das Webcambild übertragen werden soll. Die Software überprüft Deine Hard- und Softwarekonfiguration sowie die Bandbreite Deines Internetanschlusses und ermittelt hieraus den bestmöglichen Übertragungswert.',
  'info.text_4.streamSettings':
    'Die Auflösung wird als Breite x Höhe in Pixel angegeben, in Klammern siehst Du das Seitenverhältnis der Auflösung (4:3 oder 16:9).',
  'info.text_5.streamSettings':
    'Wenn im Vorschaubild links/rechts oder oben/unten schwarze Balken dargestellt werden, musst Du die Videoauflösung anpassen, denn die Balken reduzieren die maximal mögliche Videodarstellung für den User.',
  'info.text_6.streamSettings':
    'Hast Du ein Seitenverhältnis mit 4:3 eingestellt und oben und unten sind schwarze Balken zu sehen, dann wähle eine ähnliche Auflösung mit Seitenverhältnis 16:9.',
  'info.text_7.streamSettings':
    'Hast Du ein Seitenverhältnis mit 16:9 eingestellt und links und rechts sind schwarze Balken zu sehen, dann wähle eine ähnliche Auflösung mit Seitenverhältnis 4:3.',
  'info.text_8.streamSettings':
    'Bitte achte darauf, dass im Vorschaubild und damit auch im an den User übertragenen Videobild KEINE schwarzen Balken sichtbar sind.',
  'info.text_9.streamSettings':
    'Insbesondere nach Updates und Änderungen der Einstellungen in der Splitter Software oder beim Wechsel zwischen z.B. Handy/Tablet und PC können die Einstellungen verloren gehen und die Auflösung muss erneut eingestellt werden.',
  'info.text.sounds':
    '<span class="bold">Benachrichtigungstöne und deren Lautstärke</span><br />Wähle für verschiedene Situationen einen Benachrichtigungston, der Dich auch akustisch hinweist, wenn z.B. eine neue Nachricht eingeht. Die Lautstärke der Töne kannst Du in mehreren Schritten zwischen 50% (halbe Lautstärke) und 300% (dreifache Lautstärke) einstellen. Die ausgewählte Lautstärke ist für alle Benachrichtigungstöne gleich.',
  'info.text.fKeys':
    '<span class="bold">Wozu ist die Liste mit F1, F2, usw.?</span><br />Du kannst hier Kurztexte eingeben, die Du mit einem Druck auf die entsprechende F-Taste auf Deiner Tastatur abrufen kannst. So bist Du in der Lage, mit nur einem Tastendruck einen ganzen Satz in die Chat-Eingabe zu schreiben, z.B. für eine schnelle Begrüßung. Auch Platzhalter für das automatisierte einsetzen der Uhrzeit und des Usernamens sind möglich.',
  'info.currentTime': 'Aktuelle Zeit',
  'info.customerName': 'Name des Kunden',
  'info.placeholder': 'Platzhalter',
  'info.replace': 'wird ersetzt durch',

  'audio.title': 'Sound',
  'audio.volume': 'Lautstärke',
  'audio.connectionLost': 'Verbindungsabbruch',
  'audio.userLeavesChat': 'Ein User verlässt den Chat',
  'audio.newChatMessage': 'Eine neue Chat Nachricht',

  f1: 'F1',
  f2: 'F2',
  f3: 'F3',
  f4: 'F4',
  f5: 'F5',
  f6: 'F6',
  f7: 'F7',
  f8: 'F8',
};

