import { connect } from 'react-redux';

import { user as userUtils } from '../../../../../utils';
import FourScore from './FourScore';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  gamesBlocked: userUtils.gamesBlocked(state),
  unconfirmed: state.user?.account?.state === 'unconfirmed',
});

export default connect(mapStateToProps)(FourScore);

