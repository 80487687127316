import './VideoItem.scss';

import useAxios from 'axios-hooks';
import classNames from 'classnames';
import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import menuIcon from '../../../assets/img/svg/edit.svg';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { spinnerRequest } from '../../../shared/spinnerRequest';
import Button from '../../Button/Button';
import VideoPlayerItem from '../../VideoPlayerItem/VideoPlayerItem';

function VideoItem({
  item,
  spinner,
  itemDeleted,
  activeGallery,
  galleryThumbnail,
  menuVisible,
  setMenuVisible,
  data,
  setDeletionError,
  videoGalleryDeleteDisabled,
}) {
  const menuRef = useRef();
  const [, deleteVideoRequest] = useAxios(
    {
      url: `/galleries/${activeGallery?.id}/movie`,
      method: 'DELETE',
    },
    { manual: true }
  );

  function deleteVideo() {
    spinnerRequest({
      request: deleteVideoRequest,
      spinner,
    })
      .then(() => {
        setDeletionError(null);
        itemDeleted();
      })
      .catch(() => {
        setDeletionError(true);
      });
  }

  const menuClass = classNames('toggle-menu', {
    'mobile-photo-item': galleryThumbnail,
  });

  const thumbnailClass = classNames('thumbnail-wrapper', {
    'gallery-thumbnail': galleryThumbnail,
  });

  const buttonMenuClass = classNames('btn-menu', {
    open: menuVisible,
  });

  useOutsideClick(menuRef, () => {
    if (menuVisible) {
      setMenuVisible(false);
    }
  });

  return (
    <div className="gallery-item thumbnail-item">
      <div className={thumbnailClass}>
        <div className="thumbnail">
          <div className="thumbnail-image thumbnail-video">
            <VideoPlayerItem data={data} />
          </div>
          {item.status !== 'confirmed' && !videoGalleryDeleteDisabled && (
            <div className={classNames('video-options', 'thumbnail-options')}>
              <Button
                intlTranslate={false}
                icon={menuIcon}
                variant="icon-only"
                className={buttonMenuClass}
                onClick={() => {
                  if (!menuVisible) {
                    setMenuVisible(true);
                  }
                }}
              />
            </div>
          )}
          {menuVisible &&
            item.status !== 'confirmed' &&
            !videoGalleryDeleteDisabled && (
              <div
                className={menuClass}
                id={`photo-menu${item.id}`}
                ref={menuRef}
              >
                <div
                  className="menu-item"
                  onClick={() => {
                    deleteVideo();
                    setMenuVisible(false);
                  }}
                >
                  <FormattedMessage id="GALLERY_MENU_DELETE" />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default VideoItem;

