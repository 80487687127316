import './Header.scss';

import Cams from './Item/Cams';
import Favorite from './Item/Favorite';
import Friend from './Item/Friend';
import NavButton from './Item/NavButton';
import User from './Item/User';
import Usergame from './Item/Usergame';
import Userinfo from './Item/Userinfo';
import Usernote from './Item/Usernote';

function ChatHeader({ isMobile, browserAllowed }) {
  return (
    <div className="top-bar chatheader">
      {!isMobile && <User />}
      <div className="control">
        {isMobile && <NavButton dir="left" />}
        <Favorite />
        <Friend />
        <Usergame />
        <Userinfo />
        <Usernote />
        {browserAllowed && <Cams />}
        {isMobile && <NavButton dir="right" />}
      </div>
    </div>
  );
}

export default ChatHeader;

