import '../MyContent/MyContent.scss';

import useAxios from 'axios-hooks';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';
import Scrollbar from '../../Scrollbar/Scrollbar';
import GalleryInfo from './../GalleryInfo';
import { getValidationScheme } from './Photos.functions';
import PhotosInfo from './PhotosInfo';
import StartingPhotosInfo from './StartingPhotosInfo';

function Photos({ items, initialValues }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const history = useHistory();
  const [totalPictures, setTotalPictures] = useState(null);

  const [, totalPicturesReq] = useAxios(
    {
      url: `galleries/total?type=pictures`,
    },
    { manual: true }
  );

  useEffect(() => {
    totalPicturesReq().then((response) => {
      setTotalPictures(response?.data?.total);
    });
  }, [totalPicturesReq]);

  const routes = items.map((route, i) => {
    return (
      <Route
        key={`/my-content/photos${route.path}`}
        path={`/my-content/photos${route.path}`}
        component={route.component}
      />
    );
  });

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="my-content-wrapper photos-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <Switch>
              {routes}
              <Redirect to="/" />
            </Switch>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
          hasBoxShadow={true}
        >
          <div className="info-wrapper">
            {history.location.pathname.includes('my-photos') && (
              <>{totalPictures > 0 ? <PhotosInfo /> : <StartingPhotosInfo />}</>
            )}
            {history.location.pathname.includes('edit-gallery') && (
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={getValidationScheme(intl)}
              >
                <GalleryInfo />
              </Formik>
            )}
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default Photos;

