import './UserProfile.scss';

import classNames from 'classnames';
import React, { useState } from 'react';
import { useEffect } from 'react';

import { user as userUtils } from '../../../utils';
import Button from '../../Button/Button';
import Scrollbar from '../../Scrollbar/Scrollbar';
import ProfileImageAndCam from './ProfileImageAndCam';
import UserProfileIndicator from './UserProfileIndicator';
import UserProfileInfo from './UserProfileInfo';
import UserProfilePersonal from './UserProfilePersonal';
import UserProfileTurnover from './UserProfileTurnover';

const INFO_PAGE = 'INFO_PAGE';
const PERSONAL_PAGE = 'PERSONAL_PAGE';
function UserProfile({ camDisplayed, personalAvailable, activeUser }) {
  const [page, setPage] = useState(INFO_PAGE);

  const isAdmin =
    userUtils.isAdmin(activeUser) || activeUser?.flags?.includes('is_support');

  useEffect(() => {
    if (!personalAvailable) {
      setPage(INFO_PAGE);
    }
  }, [personalAvailable, setPage]);
  const togglePage = () => {
    if (page === INFO_PAGE) {
      setPage(PERSONAL_PAGE);
    } else {
      setPage(INFO_PAGE);
    }
  };
  const infoWrapperClasses = classNames('user-info-wrapper', {
    'cam-displayed': camDisplayed,
  });

  return (
    <div className={infoWrapperClasses} id="user-info-wrapper">
      <Scrollbar visible={true}>
        <div className="user-tab-info">
          {page === INFO_PAGE ? (
            <>
              <ProfileImageAndCam />
              <UserProfileIndicator />
              <UserProfileInfo />
              {personalAvailable && !isAdmin ? (
                <div className="user-info-button">
                  <Button label="showMore" onClick={togglePage} />
                </div>
              ) : null}
              <UserProfileTurnover />
            </>
          ) : (
            <>
              <UserProfilePersonal togglePage={togglePage} />
              {personalAvailable && (
                <div className="user-info-button">
                  <Button label="back" onClick={togglePage} />
                </div>
              )}
            </>
          )}
        </div>
      </Scrollbar>
    </div>
  );
}

export default UserProfile;
