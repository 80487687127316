import './User.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import adminIcon from '../../../../assets/img/svg/admin.svg';
import chatBubbleIcon from '../../../../assets/img/svg/chatbubble.svg';
import desktopIcon from '../../../../assets/img/svg/desktop.svg';
import gameIcon from '../../../../assets/img/svg/games.svg';
import mobileIcon from '../../../../assets/img/svg/mobile.svg';
import statusIcon from '../../../../assets/img/svg/on-offline.svg';
import speakerOffIcon from '../../../../assets/img/svg/speaker-off.svg';
import {
  TYPE_ACNG,
  TYPE_AC_EU,
  TYPE_ADMIN,
  TYPE_DATING,
} from '../../../../constants/user';

const User = ({
  id,
  name,
  type,
  active,
  onClick,
  usercolor,
  color,
  newMessage,
  newInList,
  mobile = false,
  typing = false,
  audioStream,
  hasMic,
  read,
  duplicateName,
  profile,
  dialogUserIsAdmin,
  portals,
  hasGame,
  hasInvitation,
  gameTurn,
  isOnline,
  portalShortName,
}) => {
  const newMessageAndGameTurn =
    !!(newMessage || (typeof read === 'boolean' && read === false)) &&
    hasGame &&
    gameTurn;

  const blink =
    (newMessage || (typeof read === 'boolean' && read === false)) &&
    !newMessageAndGameTurn;

  const classes = classNames(
    'userbox-inner',
    `user-${type.toLowerCase().replace('_', '-')}`,
    dialogUserIsAdmin ? 'user-type-admin' : '',
    {
      active: active,
      class1: !active,
      [color]: usercolor && color,
      'usercolor-default': !usercolor || !color,
      blink,
      'new-in-list': newInList,
    }
  );
  const isAdmin = type === TYPE_ADMIN || dialogUserIsAdmin;
  const isLivecamUser = [TYPE_ACNG, TYPE_AC_EU, TYPE_DATING].indexOf(type) >= 0;

  const duplicateNameShort = portals?.portalShortName
    ? portals?.portalShortName
    : profile?.info?.whitelabelShort
    ? profile?.info?.whitelabelShort
    : portalShortName;

  return (
    <div className="userbox">
      <div className={classes} onClick={() => onClick(id)}>
        <div className="default-icons">
          {isLivecamUser &&
            newInList &&
            !typing &&
            !newMessage &&
            !isAdmin &&
            !gameTurn &&
            !hasInvitation &&
            !hasGame && (
              <div className="new-in-cam">
                <span className="new">
                  <FormattedMessage id="new.user" />
                </span>
              </div>
            )}
          {blink && !hasInvitation && (
            <ReactSVG
              src={chatBubbleIcon}
              className="new-message-icon"
              wrapper="span"
            />
          )}
          {typing && !isAdmin && (
            <div className="typing">
              <div className="circle" />
              <div className="circle" />
              <div className="circle" />
            </div>
          )}
          {(isAdmin || dialogUserIsAdmin) && !blink && (
            <ReactSVG src={adminIcon} className="admin-icon" wrapper="span" />
          )}
          {hasGame && !gameTurn && !blink && !newMessageAndGameTurn && (
            <ReactSVG src={gameIcon} className="game-icon" wrapper="span" />
          )}
          {gameTurn && !newMessageAndGameTurn && (
            <ReactSVG
              src={gameIcon}
              className="game-turn-icon"
              wrapper="span"
            />
          )}
          {newMessageAndGameTurn && (
            <>
              <ReactSVG
                src={gameIcon}
                className="game-turn-icon game-turn-icon-2"
                wrapper="span"
              />
              <ReactSVG
                src={chatBubbleIcon}
                className="new-message-icon new-message-icon-2"
                wrapper="span"
              />
            </>
          )}

          {hasInvitation && (
            <ReactSVG
              src={gameIcon}
              className="invitation-icon"
              wrapper="span"
            />
          )}
        </div>

        <span className="user-name">
          {name}
          {duplicateName && duplicateNameShort && (
            <span className="whitelabel"> | {duplicateNameShort}</span>
          )}
        </span>

        <div className="userbox-icons">
          {isLivecamUser && (
            <>
              {mobile ? (
                <ReactSVG src={mobileIcon} className="icon" wrapper="span" />
              ) : (
                <ReactSVG src={desktopIcon} className="icon" wrapper="span" />
              )}
              {!audioStream && hasMic && (
                <ReactSVG
                  src={speakerOffIcon}
                  className="icon"
                  wrapper="span"
                />
              )}
            </>
          )}
          {isOnline && !isLivecamUser && (
            <ReactSVG src={statusIcon} className="status-icon" wrapper="span" />
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(React.memo(User));

