import api from '../../utils/api';
import { answerFriendRequest, deleteFriend } from '..';
export default (id) => async (dispatch, getState) => {
  if (!getState().user.isLoggedIn) {
    return;
  }
  try {
    const isFriend = await api.get('/user/friends/' + id);
    if (!getState().user.isLoggedIn) {
      return;
    }
    const {
      dialog: { dialogs },
    } = getState();
    const dialog = dialogs.find((d) => d.userId === id);
    if (!dialog?.flags) {
      return;
    }
    if (isFriend.data.status === 'friend' && !dialog.flags.includes('friend')) {
      dispatch(answerFriendRequest(true, id));
    } else if (
      isFriend.data.status !== 'friend' &&
      dialog.flags.includes('friend')
    ) {
      dispatch(deleteFriend(id, true));
    }
  } catch (error) {
    console.log({ getFriendStatusError: error });
  }
};
