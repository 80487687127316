import { audio } from '../';

export default ({ systemId }) => (dispatch, getState) => {
  const sounds =
    getState().intl.locale === 'de'
      ? getState().sounds.userEntersShow.de
      : getState().sounds.userEntersShow.en;
  if (systemId > 1 && systemId <= 10000) {
    return dispatch(audio.play(sounds.dating));
  }
  dispatch(audio.play(sounds.ac));
};
