import { updateIntl } from 'react-intl-redux';

export default function (opts) {
  return function (dispatch) {
    if (opts.locale) {
      document.documentElement.lang = opts.locale;
    }
    dispatch(updateIntl(opts));
  };
}
