import './HeaderMenuItem.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

function HeaderMenuItem({
  title = '',
  click = () => {},
  active,
  additionalContent,
  ...props
}) {
  const itemClass = classNames('header-menu-item', {
    active: active,
  });

  return (
    <div
      className={itemClass}
      onClick={active ? () => {} : click}
      id={props.id}
    >
      <FormattedMessage id={title} />
      {!!additionalContent && (
        <div className="additional-number">{additionalContent}</div>
      )}
    </div>
  );
}

export default HeaderMenuItem;

