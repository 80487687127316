import useAxios from 'axios-hooks';
import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import supportIcon from '../../../../assets/img/svg/support.svg';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { lStorage } from '../../../../services/storage';
import NavigationTooltip from '../../../NavigationTooltip/NavigationTooltip';
import Navigator from '../../../Stepper/Navigator/Navigator';
import StepperContext from '../../../Stepper/StepperContext';
import { itemTabIndexes } from '../Onboarding.functions';

function OnboardingNavigator({
  user,
  update,
  allItemsAreValid,
  spinner,
  userFinaliseBtnShown,
  logout,
  locale,
}) {
  const {
    setCurrentItemIndex,
    setNextItemIndex,
    setItems,
    markCurrentItemAsValid,
    sendingToReview,
    setSendingToReview,
    currentItemIndex,
  } = useContext(StepperContext);

  const intl = useIntl();
  const history = useHistory();
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');

  const [tooltips, setTooltips] = useState({
    navigationTooltip: parseInt(lStorage.getItem('navigationTooltip')),
    finaliseTooltip: parseInt(lStorage.getItem('finaliseTooltip')),
  });

  const navigatorLeftContent = isMobileScreen ? (
    allItemsAreValid && user.account.state !== 'pending' ? null : (
      <Link to="/support" className="support-btn">
        <ReactSVG src={supportIcon} wrapper="span" />
      </Link>
    )
  ) : (
    <>
      <span className="help-content">
        {intl.formatMessage({ id: 'HINT_NEED_HELP' }) + ' '}
      </span>
      <span>
        {intl.formatMessage({ id: 'HINT_CONTACT' }) + ' '}
        <Link
          to="/support"
          tabIndex={
            itemTabIndexes.get(currentItemIndex) &&
            (allItemsAreValid && user.account.state !== 'pending'
              ? itemTabIndexes.get(currentItemIndex).support + 1
              : itemTabIndexes.get(currentItemIndex).support)
          }
        >
          {intl.formatMessage({ id: 'HINT_CREATOR_SUPPORT' })}
        </Link>
      </span>
    </>
  );

  const [
    { error: finaliseError, response: finaliseResponse },
    sendToReviewRequest,
  ] = useAxios(
    {
      url: '/user/send-to-review',
      method: 'POST',
    },
    {
      manual: true,
    }
  );

  function onFinaliseBtnClick() {
    setSendingToReview('pending');
    markCurrentItemAsValid();
    spinner(true);
  }

  useEffect(() => {
    if (!finaliseError) {
      return;
    }
    setSendingToReview('none');
    spinner(false);
  }, [finaliseError, setSendingToReview, spinner, setNextItemIndex]);

  useEffect(() => {
    if (sendingToReview !== 'ready') {
      return;
    }
    sendToReviewRequest();
  }, [
    sendToReviewRequest,
    sendingToReview,
    setSendingToReview,
    setNextItemIndex,
  ]);

  useEffect(() => {
    if (!finaliseResponse || user.account.state === 'pending') {
      return;
    }
    if (finaliseResponse.status === 204) {
      setItems((items) =>
        items.map((item) => {
          item.disabled = true;
          return item;
        })
      );
      setCurrentItemIndex(-1);
      setNextItemIndex(-1);
      setSendingToReview('done');
      history.replace({
        pathname: `/onboarding`,
      });
      spinner(false);
      update({ account: { ...user.account, state: 'pending' } });
    }
  }, [
    finaliseResponse,
    setItems,
    setCurrentItemIndex,
    update,
    user.account,
    setNextItemIndex,
    setSendingToReview,
    spinner,
    history,
  ]);

  function closeTooltip(type) {
    lStorage.setItem(type, 0);
    setTooltips({ ...tooltips, [type]: false });
  }

  function openCloseTooltip(type) {
    const item = lStorage.getItem(type);
    if (item === '1') {
      lStorage.setItem(type, 0);
      setTooltips({ ...tooltips, [type]: false });
    } else {
      lStorage.setItem(type, 1);
      setTooltips({ ...tooltips, [type]: true });
    }
  }

  useEffect(() => {
    userFinaliseBtnShown(allItemsAreValid && user.account?.state !== 'pending');
  }, [allItemsAreValid, user.account?.state, userFinaliseBtnShown]);

  useEffect(() => {
    if (user.account?.logoutUser) {
      logout(false);
    }
  }, [logout, user.account?.logoutUser]);

  return (
    <>
      {(user.account.state === 'onboarding' ||
        user.account.state === 'review_needed') &&
      tooltips.navigationTooltip &&
      !allItemsAreValid ? (
        <NavigationTooltip
          type={'navigationTooltip'}
          onClose={() => closeTooltip('navigationTooltip')}
          locale={locale}
          user={user}
        />
      ) : null}
      {tooltips.finaliseTooltip &&
      allItemsAreValid &&
      user.account.state === 'onboarding' ? (
        <NavigationTooltip
          type={'finaliseTooltip'}
          onClose={() => closeTooltip('finaliseTooltip')}
        />
      ) : null}
      <Navigator
        user={user}
        allItemsAreValid={allItemsAreValid}
        areNavigationBtnsShown={user.account.state !== 'pending'}
        leftContent={navigatorLeftContent}
        isFinaliseBtnShown={
          allItemsAreValid && user.account.state !== 'pending'
        }
        openTooltip={(input) => {
          openCloseTooltip(input);
        }}
        onFinaliseBtnClick={onFinaliseBtnClick}
      />
    </>
  );
}

export default OnboardingNavigator;

