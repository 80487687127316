export default {
  en: 'english',
  es: 'spanish',
  zh: 'chinese',
  hi: 'hindi',
  ar: 'arabic',
  pt: 'portuguese',
  bn: 'bengali',
  ru: 'russian',
  ja: 'japanese',
  pa: 'punjabi',
  de: 'german',
  jv: 'javanese',
  te: 'telugu',
  vi: 'vietnamese',
  ko: 'korean',
  fr: 'french',
  mr: 'marathi',
  ta: 'tamil',
  ur: 'urdu',
  tr: 'turkish',
  it: 'italian',
  th: 'thai',
  gu: 'gujarati',
  pl: 'polish',
  uk: 'ukrainian',
  ro: 'romanian',
  nl: 'dutch',
  hu: 'hungarian',
  sv: 'swedish',
  cs: 'czech',
  el: 'greek',
  fi: 'finnish',
  da: 'danish',
  sk: 'slovak',
  no: 'norwegian',
  he: 'hebrew',
  id: 'indonesian',
  ms: 'malay',
  fa: 'persian',
  sr: 'serbian',
  hr: 'croatian',
  sq: 'albanian',
  kn: 'kannada',
  ml: 'malayalam',
  or: 'odia',
  as: 'assamese',
  bh: 'bihari',
  ks: 'kashmiri',
  kok: 'konkani',
  sd: 'sindhi',
  ne: 'nepali',
  si: 'sinhala',
  om: 'oromo',
  ti: 'tigrinya',
  so: 'somali',
  am: 'amharic',
  sw: 'swahili',
  rw: 'kinyarwanda',
  rn: 'kirundi',
  mg: 'malagasy',
  ny: 'chichewa',
  ee: 'ewe',
  ha: 'hausa',
  ig: 'igbo',
  yo: 'yoruba',
  zu: 'zulu',
  st: 'sesotho',
  xh: 'xhosa',
  tn: 'setswana',
  ts: 'tsonga',
  ve: 'venda',
  sn: 'shona',
  af: 'afrikaans',
  az: 'azerbaijani',
  be: 'belarusian',
  bs: 'bosnian',
  ca: 'catalan',
  et: 'estonian',
  tl: 'filipino',
  gl: 'galician',
  ht: 'haitian',
  is: 'icelandic',
  ku: 'kurdish',
  lv: 'latvian',
  lt: 'lithuanian',
  lb: 'luxembourgish',
  mk: 'macedonian',
  mt: 'maltese',
  mn: 'mongolian',
  ps: 'pashto',
  sl: 'slovenian',
  tg: 'tajik',
  tk: 'turkmen',
  uz: 'uzbek',
  cy: 'welsh',
  yi: 'yiddish',
  eu: 'basque',
  co: 'corsican',
  fo: 'faroese',
  fy: 'frisian',
  gd: 'gaelic',
  haw: 'hawaiian',
  mi: 'maori',
  sm: 'samoan',
  to: 'tongan',
};

