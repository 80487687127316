import { STREAMING_STOPPED } from '../../../../constants';
import { stream } from '../../../';

export default () => {
  return (dispatch, getState) => {
    const {
      streaming: { step, camLoginData },
    } = getState();
    console.log(`prewarmStream: ${step}`);
    if (step === STREAMING_STOPPED) {
      dispatch(stream.publish(camLoginData));
    }
    dispatch(stream.timerStop.start());
  };
};
