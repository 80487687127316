import './PremiumAttachmentIndicator.scss';

import classNames from 'classnames';
import { useIntl } from 'react-intl';

import notAvailableIcon from '../../../assets/img/svg/question-mark.svg';
import TooltipIcon from '../../TooltipIcon/TooltipIcon';

function PremiumAttachmentIndicator({ label, value, tooltip, position }) {
  let displayValue;
  const quotaClass = classNames('premium-attachment-quota', {
    green: value > 60,
    red: value < 50,
    yellow: value >= 50 && value <= 60,
    na: typeof value !== 'number',
  });
  const intl = useIntl();
  try {
    displayValue = value.toFixed(1) + '%';
  } catch (err) {
    displayValue = 'n/a';
  }
  return (
    <div className="premium-attachment-indicator">
      <div className="premium-attachment-title">
        <div className="wrapper">
          <span className="premium-attachment-label">{label}</span>
          {tooltip && (
            <TooltipIcon
              position={position}
              tooltip={tooltip}
              icon={notAvailableIcon}
            />
          )}
        </div>
      </div>
      <div className={quotaClass}>
        <div className="inner">
          <span>{intl.formatMessage({ id: 'KPI_QUOTA' })}</span>
          <span className="premium-attachment-quota-value">{displayValue}</span>
        </div>
      </div>
    </div>
  );
}

export default PremiumAttachmentIndicator;
