import './Stats.scss';

import { useIntl } from 'react-intl';

function Stats({ wins, games, rank, noUser }) {
  const intl = useIntl();

  return !noUser ? (
    <div className="stats">
      {typeof games === 'number' && games >= 0 ? (
        <span className="games">
          <span className="value">{games}</span>{' '}
          {games === 1
            ? intl.formatMessage({ id: 'GAMESTATS_GAME' })
            : intl.formatMessage({ id: 'GAMESTATS_GAMES' })}
        </span>
      ) : null}
      {typeof wins === 'number' && wins >= 0 ? (
        <span className="wins">
          <span className="value">{wins}</span>{' '}
          {wins === 1
            ? intl.formatMessage({ id: 'GAMESTATS_WIN' })
            : intl.formatMessage({ id: 'GAMESTATS_WINS' })}
        </span>
      ) : null}
      {typeof rank === 'number' && rank > 0 ? (
        <span className="rank">
          {intl.formatMessage({ id: 'GAMESTATS_RANK' })}
          <span className="value">{rank}</span>
        </span>
      ) : null}
    </div>
  ) : null;
}

export default Stats;
