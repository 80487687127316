import { connect } from 'react-redux';

import {
  addOptions,
  spinner,
  userUpdate as update,
} from '../../../../../actions';
import ProfileDetails from './ProfileDetails';

const mapStateToProps = (state) => ({
  user: state.user,
  languages: state.languages,
  isMobile: state.view.isMobile,
});

const mapDispatchToProps = {
  update,
  spinner,
  addOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetails);

