export default {
  PROFILE_IMAGE_HEADLINE: `Wie sollte ein Profilbild aussehen`,
  PROFILE_IMAGE_CONTENT_1: `Als oberste Regel gilt: plane Zeit ein, um Dein "perfektes" Profilbild zu machen. Ein "Schnappschuss im Vorbeigehen" oder ein schnelles Selfie wird meist Deine eigenen Erwartungen nicht erfüllen.`,
  PROFILE_IMAGE_CONTENT_2: `Ein Profilbild sollte Dich so zeigen, wie Du Dich wohlfühlst. Du musst Dich nicht verstellen, nur weil Du denkst, dass der Kunde alles sehen möchte. Du kannst selbst entscheiden, was und wie viel Du zeigen möchtest. Wenn Du Dich am Anfang noch nicht traust, Dein Gesicht auf Deinem Profilbild zu zeigen, ist das vollkommen in Ordnung. Du kannst Dein Gesicht im Bild angeschnitten, mit Deinen Haaren verdeckt oder mit einer Maske versteckt zeigen, sodass man Dich nicht sofort erkennt.`,
  PROFILE_IMAGE_CONTENT_STEP_1: `Aufräumen`,
  PROFILE_IMAGE_CONTENT_STEP_1_CONTENT: `Achte beim Fotografieren darauf, dass der Bereich, der im Bild zu sehen sein wird, aufgeräumt ist. Verstecke Bilder, Fotografien, Spielzeug Deines Nachwuchses oder Ähnliches, sonst gibst Du schnell mehr Preis, als Du vielleicht wolltest.`,
  PROFILE_IMAGE_CONTENT_STEP_2: `Beleuchtung`,
  PROFILE_IMAGE_CONTENT_STEP_2_CONTENT: `Ein Fenster oder zu helles Licht im Hintergrund verdunkelt Dein Bild. Licht frontal von vorn lässt Konturen flacher wirken und wirkt meist wenig schmeichelnd. Ideal ist seitliches Licht, da es Deine Konturen betont und Deinem Bild Tiefe verleiht. Wenn Du Deine Bilder allein erstellst, verwende ein Stativ.`,
  PROFILE_IMAGE_CONTENT_STEP_2_2_CONTENT: `Noch besser ist ein Ringlicht mit Stativ. Das hat den Vorteil, dass Du gut ausgeleuchtet bist und Deine Bilder nicht verwackeln. Neben einer guten Ausleuchtung, schmeichelt es Deinen Augen, indem es einen Lichtkreis in Deiner Pupille entstehen und Deine Augen stärker leuchten lässt. Ein Ringlicht erzeugt in der Regel genug Licht, um eine gute Qualität Deines Bildes zu gewährleisten.`,
  PROFILE_IMAGE_CONTENT_STEP_3: `Schärfe`,
  PROFILE_IMAGE_CONTENT_STEP_3_CONTENT: `Achte bei der Kamera oder dem Handy immer auf den Autofokus. Ideal ist, den Autofokus vorher auf eine Position fest einzustellen, am besten Dein Gesicht oder direkt die Augen, damit die Kamera nicht versehentlich einen anderen Bereich im Bild fokussiert, der näher am Objektiv ist.`,
  PROFILE_IMAGE_CONTENT_STEP_4: `Format`,
  PROFILE_IMAGE_CONTENT_STEP_4_CONTENT: `Erstelle Dein Profilbild möglichst immer im Querformat und positioniere Dich in der Mitte des Bildes.`,
  PROFILE_IMAGE_CONTENT_TIPP: `<span class="bold">Tipp:</span> Du kannst in der Profilbildgalerie wählen, welches Deiner Profilbilder standardmäßig in Deinem Profil angezeigt werden soll.`,
};
