import socketService from '../../services/socketService';
import { default as connect } from './connect';
import { default as events } from './events';
import { default as salesFetchCurrent } from './sales/fetchCurrent';
import { default as keepAlive } from './user/keepAlive';
import { default as keepIdle } from './user/keepIdle';

export default {
  events: events(socketService),
  connect: connect(socketService),
  salesFetchCurrent: salesFetchCurrent(socketService),
  keepIdle: keepIdle(socketService),
  keepAlive: keepAlive(socketService),
};
