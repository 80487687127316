import {
  CONNECT_SOCKET_SERVICE,
  LOG_DIALOGLOAD_SEND,
  LOG_FETCHED_DIALOG,
  LOG_FIRST_DIALOG_DISPLAYED,
  LOG_NO_DIALOG,
  USER_LOGGED_IN,
} from '../actions/actions';
import { getTimestamp } from '../utils';

const initialState = {
  dialogloadLogged: false,
  firstDialogLogged: false,
};

function logReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGGED_IN: {
      return {
        ...state,
        userLoggedInTime: getTimestamp(),
      };
    }
    case LOG_DIALOGLOAD_SEND: {
      return {
        ...state,
        ...action.payload,
        dialogloadLogged: true,
      };
    }
    case LOG_NO_DIALOG: {
      return {
        ...state,
        ...action.payload,
        dialogloadLogged: true,
        firstDialogLogged: true,
      };
    }
    case LOG_FETCHED_DIALOG: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case LOG_FIRST_DIALOG_DISPLAYED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case CONNECT_SOCKET_SERVICE: {
      if (action?.payload?.step === 'SOCKET_SERVICE_STEP_CONNECTED') {
        return {
          ...state,
          socketServiceConnectedTime: getTimestamp(),
        };
      }
      return state;
    }
    default:
      return state;
  }
}

export default logReducer;
