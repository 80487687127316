import './StreamingButton.scss';

import React from 'react';

import {
  MANAGEMENT_CONNECT_STARTED,
  MANAGEMENT_LOGGED_IN,
  MANAGEMENT_LOGIN_STARTED,
  MANAGEMENT_LOGOUT_STARTED,
  STREAMING_IS_STARTING,
  STREAMING_RECONNECTING,
  STREAMING_STARTED,
} from '../../../../constants';
import Button from '../../../Button/Button';

const StreamingButton = ({
  managementStep,
  camInitialized,
  logout,
  stop,
  start,
  streambuttonlock,
  startButtonId,
  stopButtonId,
  step,
  isReconnecting,
  silentReconnect,
  checkingBandwidth,
  checkingCam,
}) => {
  let classNames = ['btn-streaming'];
  let clickHandler = start;
  if (managementStep === MANAGEMENT_LOGGED_IN) {
    clickHandler = logout;
  } else if (managementStep === MANAGEMENT_CONNECT_STARTED) {
    clickHandler = stop;
  }
  const isLoadingId = 'streamingButton.isLoading';
  const isStreaming =
    step === STREAMING_STARTED ||
    step === STREAMING_RECONNECTING ||
    isReconnecting ||
    silentReconnect;

  if (isStreaming) {
    classNames.push('is-streaming');
  }

  let isLoading = false;

  if (
    !silentReconnect &&
    !isReconnecting &&
    ([
      MANAGEMENT_CONNECT_STARTED,
      MANAGEMENT_LOGIN_STARTED,
      MANAGEMENT_LOGOUT_STARTED,
    ].indexOf(managementStep) >= 0 ||
      step === STREAMING_IS_STARTING ||
      streambuttonlock)
  ) {
    classNames.push('is-loading');
    isLoading = true;
    clickHandler = () => {};
  }
  const id = isLoading
    ? isLoadingId
    : isStreaming
    ? stopButtonId
    : startButtonId;
  return camInitialized && !checkingBandwidth && !checkingCam ? (
    <div className="item-stream-btn">
      <Button
        variant="default"
        onClick={clickHandler}
        className={classNames.join(' ')}
        label={id}
        disabled={isLoading}
      />
    </div>
  ) : null;
};

export default StreamingButton;
