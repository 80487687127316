export default {
  ROUTE_COMMISSION_HEADLINE: `Verschaffe Dir einen Überblick über Deinen aktuellen Provisionssatz und alle Abrechnungen`,
  COMMISSION_HEADLINE_1: `Das LiveCreator Provisonsmodell`,
  COMMISSION_HEADLINE_2: `Wann werden Guthaben ausgezahlt?`,
  COMMISSION_TEXT_1: `Für jede Abrechnungsperiode kannst Du bei LiveCreator bis zu 32% Provision erhalten.`,
  COMMISSION_TEXT_2: `Alle Provisionen sind stornofrei!`,
  COMMISSION_TEXT_3: `Dein aktueller Provisionssatz richtet sich nach der Anzahl der erwirtschafteten Coins in unseren Communities.`,
  COMMISSION_TEXT_4: `<span class="bold">Die Coin-basierte Staffel im Detail:</span><br />
  <table class="noBorder noHover">
   <tr>
      <td>bis</td>
      <td class="align-right">9.999 Coins</td>
      <td>25%</td>
   </tr>
   <tr>
      <td>ab</td>
      <td class="align-right">10.000 Coins</td>
      <td>27,5%</td>
   </tr>
   <tr>
      <td>ab</td>
      <td class="align-right">25.000 Coins</td>
      <td>30%</td>
   </tr>
   <tr>
      <td>ab</td>
      <td class="align-right">50.000 Coins</td>
      <td>31%</td>
   </tr>
   <tr>
      <td>ab</td>
      <td class="align-right">100.000 Coins</td>
      <td>32%</td>
   </tr>
  </table>`,
  COMMISSION_TEXT_PAY_1: `Es gibt im Monat zwei Abrechnungszeiträume/ Abrechnungsperioden, jeweils eine vom 1. bis zum 15. des Monats, welche am 16. eines Monats gezogen wird und vom 16. bis zum Ultimo, die am 1. des Folgemonats gezogen wird.`,
  COMMISSION_TEXT_PAY_2: `In diesen Abrechnungen werden alle von Dir verkauften Artikel abgerechnet, wobei einige Coin-basiert (wie Nachrichten, Livecam, Movies und Bilder, Sexicons, Aufmerksamkeiten), andere statisch (wie die Provision aus geworbenen Usern und Creator) sind.`,
  COMMISSION_TEXT_PAY_3: `Die Abrechnung erfolgt in der Regel unmittelbar in den nächsten 2-3 Werktagen nach Abschluss eines Abrechnungszeitraumes.`,
  COMMISSION_TEXT_PAY_4: `Voraussetzung dafür ist das Überschreiten des Mindestauszahlungsbetrages in Höhe von 50 EUR oder eines höheren Betrages, den Du `,
  COMMISSION_TEXT_PAY_5: `hier`,
  COMMISSION_TEXT_PAY_6: ` ändern kannst. Außerdem müssen Deine hinterlegten Bankdaten geprüft und freigeschaltet sein!`,

  PAYOUTS_TABLE_BILL: `Abrechnungen`,
  BILL_ARTICLES_ARTICLE: `Artikel`,
  BILL_ARTICLES_TIME: `Zeitpunkt`,
  BILL_ARTICLES_CUSTOMER: `Kunde`,
  BILL_ARTICLES_PORTAL: `Portal`,
  BILL_ARTICLES_GALLERY: `Galerie`,
  BILL_ARTICLES_COIN_PURCHASES: `Coinkauf`,
  BILL_ARTICLES_COMMISSION: `Provision`,
  BILL_ARTICLES_DURATION: `Dauer`,
  BILL_ARTICLES_TURNOVER: `Umsatz`,
  BILL_ARTICLES_HIDDEN_USERS: `Versteckter User`,
  BILL_ARTICLES_CREATOR: `Creator`,
  BILL_ARTICLES_NUMBER: `Anzahl`,
  BILL_ARTICLES_SHARE: `Anteil`,
  BILL_ARTICLES_SALES: `Verkäufe`,
  BILL_ARTICLES_TOTAL_TURNOVER: `Gesamtumsatz`,
  BILL_ARTICLES_SHOW_ALL: `Alle anzeigen`,
  BILL_ARTICLES_SHOW_LESS: `Weniger anzeigen`,

  ROUTE_BILLING_PERIOD_TITLE: `Aktuelle Umsätze`,
  ROUTE_PARKING_CREDITS_TITLE: `Parkendes Guthaben`,
  ROUTE_PAYOUT_TITLE: `Auszahlung`,
  ROUTE_LIVECAM_OVERVIEW_TITLE: `Livecam Übersicht`,
  ARTICLE_COINS_TABLE_ARTICLE: `Artikel`,
  ARTICLE_COINS_TABLE_NUMBER: `Anzahl`,
  ARTICLE_COINS_TABLE_FIRST_ON: `zuerst am`,
  ARTICLE_COINS_TABLE_LAST_ON: `zuletzt am`,
  ARTICLE_COINS_TABLE_TURNOVER: `Umsatz`,
  ARTICLE_COINS_TABLE_SHARE: `Anteil`,
  ARTICLE_COINS_TABLE_PREMIUM: `Provision`,
  MODELS_TABLE_MODEL: `Model`,
  MODELS_TABLE_COMMISSION_RATE: `Provisionsstufe`,
  MODELS_TABLE_PROGRESS: `Fortschritt`,
  MODELS_TABLE_YOUR_COMMISSION: `Deine Provision`,
  MODELS_TABLE_MINIMUM_TURNOVER: `Mindestumsatz`,
  MODELS_TABLE_YOUR: `Du befindest`,
  MODELS_TABLE_CURRENT_LEVEL: `Dich auf dieser Stufe`,
  MODELS_TABLE_FROM_COINS: `(ab {coins} Coins)`,
  MODELS_TABLE_YOU_REACHED_COMMISSION_RATE: `Hier wurde ein Provisionsatz in Höhe von {rate} erreicht`,

  PAYOUT_PAYOUT: `Auszahlung`,
  PAYOUT_FROM: `vom`,
  PAYOUT_AMOUNT: `Betrag`,
  PARKING_CREDITS_BILL: `Abrechnung`,
  PARKING_CREDITS_FROM: `vom`,
  PARKING_CREDITS_BILLINGPERIOD: `Abrechnungszeitraum`,
  PARKING_CREDITS_MODEL: `Stufe`,
  PARKING_CREDITS_PREMIUM: `Provision`,
  LIVECAM_OVERVIEW_NUMBER: `Nr`,
  LIVECAM_OVERVIEW_TIME: `Zeitpunkt`,
  LIVECAM_OVERVIEW_CUSTOMER: `Kunde`,
  LIVECAM_OVERVIEW_PORTAL: `Portal`,
  LIVECAM_OVERVIEW_DURATION: `Dauer`,
  UNTIL: `bis`,

  TOTAL_TURNOVER: `Gesamtumsatz`,
  BILLING_PERIOD_CURRENT_CREDITS: `Aktuelles Guthaben`,
  PARKING_CREDITS_REVENUE: `Parkendes Guthaben`,
  PARKING_TOTAL_REVENUE: 'Gesamt',
  PAYOUTS_PAYOUTS: `Auszahlungen`,
  LIVECAM_OVERVIEW_HEADLINE: `Livecam Übersicht`,
  BILLING_PERIOD_PAYOUT_INFO: `Das Guthaben wird am {end} ausgezahlt, sollte die Summe den Mindestauszahlungsbetrag in Höhe von `,
  BILLING_PERIOD_PAYOUT_INFO_2: ' erreicht haben.',
  CREDITS: `Guthaben`,
  article_message: `Private Nachricht`,
  article_livecams: `Livecam`,
  article_movies: `Video`,
  article_pictures: `Foto`,
  article_sexicon: `Sexicon`,
  article_regard: `Aufmerksamkeit`,
  article_exclusivecams: `Exklusiv Show`,
  article_voyeurcams: `Voyeur Show`,
  article_livecamtip: `Livecam Trinkgeld`,
  article_payttachment: `Premium Dateianhang`,
  article_advrev: `Provision aus Umsätzen gew. User`,
  article_advppl: `Provision User Registrierung (PPL)`,
  article_supersenderbonus: `Prämie aus Umsätzen gew. Creator`,
  article_bonus: `Bonus`,
  article_adjustment: `Korrektur`,
  article_ext_livecams: `Externe Livecam`,
  article_toycontrol: `Toycontrol`,
  article_ghostmessage: `Private Nachricht`,
  article_ghostregard: `Aufmerksamkeit`,
  article_ghostsexicon: `Sexicon`,
  article_voicemessage: `Voice Message`,
  article_game: `Community Game`,
  ARTICLES_OTHER: `Provision aus aktuellen Umsätzen`,
  ARTICLES_SEE_ABOVE: `(siehe oben)`,
  PROGRESS_ITEM_LEFT: `noch {data} {value}`,

  SALES_TODAY: `Umsatz heute`,
  SALES_TODAY_SHORT: `Heute`,
};

