import './ModelsTableWrapper.scss';

import React from 'react';

import useMediaQuery from '../../../hooks/useMediaQuery';
import {
  billDetailsModelsTableTitles,
  fullModelsTableTitles,
  mediumModelsTableTitles,
  mobileModelsTableTitles,
} from '../../Content/Commission/BillingPeriod/BillingPeriod.functions';
import ModelsTable from './ModelsTable';

function ModelsTableWrapper({
  items = [],
  isSubmenuShown,
  precision,
  currency,
  billDetailsTable,
  ...otherProps
}) {
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const isMediumScreen = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1599px)'
  );
  const tableTitles = billDetailsTable
    ? billDetailsModelsTableTitles
    : isMobileScreen
    ? mobileModelsTableTitles
    : isMediumScreen && isSubmenuShown
    ? mediumModelsTableTitles
    : fullModelsTableTitles;
  return (
    <ModelsTable
      items={items}
      titles={tableTitles}
      currency={currency}
      precision={precision}
      mediumScreen={isMediumScreen && isSubmenuShown}
      mobileScreen={isMobileScreen}
      billDetailsTable={billDetailsTable}
      {...otherProps}
    />
  );
}

export default ModelsTableWrapper;

