import { connect } from 'react-redux';

import { muteMicrophone } from '../../../../../actions';
import MobileStreaming from './MobileStreaming';

const mapStateToProps = (state) => {
  const {
    cam: {
      selectedMicrophone: { value: micValue },
    },
    streaming: { mute: microphoneMuted },
  } = state;

  return {
    microphoneEnabled: micValue !== false,
    microphoneMuted,
  };
};

const mapDispatchToProps = {
  muteMicrophone,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileStreaming);
