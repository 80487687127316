import './IndicatorInput.scss';

import classNames from 'classnames';
import React from 'react';
import { ReactSVG } from 'react-svg';

import Input from '../Input/Input';

function IndicatorInput({
  id,
  name,
  icon,
  color,
  text,
  secondText,
  ...otherProps
}) {
  const inputWrapperClass = classNames('input-wrapper', {
    red: color === 'red',
    orange: color === 'orange',
    blue: color === 'blue',
    green: color === 'green',
  });

  const iconClass = classNames('icon-wrapper', {
    red: color === 'red',
    orange: color === 'orange',
    blue: color === 'blue',
    green: color === 'green',
  });

  const textClass = classNames('indicator-text', {
    red: color === 'red',
    orange: color === 'orange',
    blue: color === 'blue',
    green: color === 'green',
  });

  return (
    <div className="indicator-input">
      <div className={inputWrapperClass}>
        <Input id={id} name={name} {...otherProps} />

        {icon && <ReactSVG src={icon} className={iconClass} />}
      </div>

      {text && <div className={textClass}>{text}</div>}

      {secondText && <div>{secondText}</div>}
    </div>
  );
}

export default IndicatorInput;

