import { changeRoomTypes, management, saveUpgrade } from '../../actions';
import { MANAGEMENT_LOGGED_IN } from '../../constants';
import {
  objectUtils as _,
  roomType as roomTypeUtils,
  sender as senderUtils,
} from '../../utils';
import { ROOM_TYPES_CHANGE } from '../actions';

export default (save = true, sendToServer = true) => (dispatch, getState) => {
  const {
    sender: {
      roomTypes,
      logins,
      saved: { logins: savedLogins, roomTypes: savedRoomTypes },
    },
    streaming: { managementStep },
  } = _.clone(getState());

  if (save) {
    dispatch(saveUpgrade({}, false));
  }

  if (
    roomTypeUtils.isExclusive(roomTypes) &&
    savedRoomTypes.length > 0 &&
    !senderUtils.hasMultipleOrigins(logins)
  ) {
    return dispatch(changeRoomTypes(false, sendToServer));
  }

  if (savedLogins.length === 0) {
    return;
  }

  if (!senderUtils.hasMultipleOrigins(logins)) {
    return;
  }
  dispatch({
    type: ROOM_TYPES_CHANGE,
    payload: {
      roomTypes: savedRoomTypes,
      logins: savedLogins,
    },
  });
  if (managementStep === MANAGEMENT_LOGGED_IN && sendToServer) {
    dispatch(management.switchOrigins(savedLogins, savedRoomTypes));
  }
};
