import '../../Chat.scss';

function MessageResend({ sendMessage, message, intl }) {
  function resendMessage(message) {
    sendMessage(message.payload?.resendPayload);
  }

  return (
    <>
      {message?.payload?.resendPayload?.id && (
        <span className="not-send">
          {intl.formatMessage({ id: 'messageNotSend' })}
          <a onClick={() => resendMessage(message)}>
            {intl.formatMessage({ id: 'sendAgain' })}
          </a>
        </span>
      )}
    </>
  );
}

export default MessageResend;

