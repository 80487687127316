export const fullParkingCreditsTableTitles = [
  { title: 'PARKING_CREDITS_BILL', width: '15%', position: 'left' },
  { title: 'PARKING_CREDITS_FROM', width: '15%', position: 'left' },
  { title: 'PARKING_CREDITS_BILLINGPERIOD', width: '45%', position: 'left' },
  { title: 'PARKING_CREDITS_MODEL', width: '10%', position: 'right' },
  { title: 'PARKING_CREDITS_PREMIUM', width: '15%', position: 'right' },
];

export const mediumParkingCreditsTableTitles = [
  { title: 'PARKING_CREDITS_BILL', width: '15%', position: 'left' },
  { title: 'PARKING_CREDITS_FROM', width: '55%', position: 'left' },
  { title: 'PARKING_CREDITS_MODEL', width: '10%', position: 'right' },
  { title: 'PARKING_CREDITS_PREMIUM', width: '20%', position: 'right' },
];

export const mobileParkingCreditsTableTitles = [
  { title: 'PARKING_CREDITS_BILL', width: '25%', position: 'left' },
  { title: 'PARKING_CREDITS_FROM', width: '25%', position: 'left' },
  { title: 'PARKING_CREDITS_MODEL', width: '25%', position: 'center' },
  { title: 'PARKING_CREDITS_PREMIUM', width: '25%', position: 'right' },
];
