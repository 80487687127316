import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

import useMediaQuery from '../../../hooks/useMediaQuery';

function SubmenuItem({
  item = {},
  isActive = false,
  onMenuToggle = () => {},
  additionalContent,
}) {
  const isSmall = useMediaQuery('(max-width: 1023px)');
  const itemClassNames = classNames('item', item?.itemClass, {
    active: isActive,
  });

  if (item.separator) {
    return <hr />;
  }

  const onClick = (e) => {
    isSmall && onMenuToggle();
    item.onClick && item.onClick(e);
  };

  return (
    <Link
      to={isActive && !item.path.includes('?') ? '#' : item.path}
      className={itemClassNames}
      onClick={onClick}
    >
      {item.title}
      {!!additionalContent && (
        <span className="additional-number">{additionalContent}</span>
      )}
    </Link>
  );
}

export default SubmenuItem;

