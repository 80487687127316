const activitiesFilter = [
  {
    title: 'MENU_ITEM_ACTIVITIES_PROFILE',
    id: 'activities_profile',
    filter: (camUserFilter) => camUserFilter.activities.profile.length,
  },
  {
    title: 'MENU_ITEM_ACTIVITIES_ONLINE',
    id: 'activities_online',
    filter: (camUserFilter) => camUserFilter.activities.online.length,
  },
  {
    title: 'MENU_ITEM_ACTIVITIES_ONLINE_FRIEND',
    id: 'activities_friend',
    filter: (camUserFilter) => camUserFilter.activities.friend.length,
  },
  {
    title: 'MENU_ITEM_ACTIVITIES_FRIEND',
    id: 'activities_friendRequest',
    filter: (camUserFilter) => camUserFilter.activities.friendRequest.length,
  },
  {
    title: 'MENU_ITEM_ACTIVITIES_CONTENT',
    id: 'activities_content',
    filter: (camUserFilter) => camUserFilter.activities.content.length,
  },
];

export { activitiesFilter };
