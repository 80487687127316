import './NavigationTooltip.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import minimizeIcon from '../../assets/img/svg/arrow-right.svg';
import closeIcon from '../../assets/img/svg/close.svg';
import videoPosterImgDe from '../../assets/img/videoPoster/Onboarding_de.jpg';
import videoPosterImgEn from '../../assets/img/videoPoster/Onboarding_en.jpg';
import useMediaQuery from '../../hooks/useMediaQuery';
import Scrollbar from '../Scrollbar/Scrollbar';
import VideoWithOverlay from '../VideoWithOverlay';

function NavigationTooltip({
  type = 'navigationTooltip',
  onClose = () => {},
  locale,
  user,
}) {
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const intl = useIntl();
  const className = classNames('tooltip-wrapper', {
    support: type === 'navigationTooltip',
  });

  const video =
    locale === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/onboarding_en2.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/onboarding_de.mp4`;
  const videoPosterImg = locale === 'en' ? videoPosterImgEn : videoPosterImgDe;

  return (
    <div className={className}>
      <div className="close-btn" onClick={onClose}>
        <ReactSVG
          src={type === 'navigationTooltip' ? minimizeIcon : closeIcon}
          wrapper="span"
        />
      </div>
      {type !== 'navigationTooltip' && (
        <div className="tooltip-title">
          {intl.formatMessage({ id: 'TOOLTIP_NOW_COMPLETE' })}
        </div>
      )}
      {type === 'navigationTooltip' ? (
        <Scrollbar visible={true}>
          <div className="tooltip-content support">
            {user.account.state === 'onboarding' ? (
              <div className="video">
                <VideoWithOverlay video={video} poster={videoPosterImg} />
              </div>
            ) : null}
            <div className="support-image" />
            <h3>{intl.formatMessage({ id: 'TOOLTIP_SUPPORT_HEADLINE' })}</h3>
            <p className="support-text">
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({ id: 'TOOLTIP_SUPPORT' }),
                }}
              />
            </p>
          </div>
        </Scrollbar>
      ) : (
        <div className="tooltip-content">
          <p>
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'TOOLTIP_NAVIGATION_FINALIZE_1',
                }),
              }}
            />
            {isMobileScreen && (
              <>
                <Link to="/support" className="support-link">
                  <FormattedMessage id="TOOLTIP_NAVIGATION_FINALIZE_NEED_HELP" />
                </Link>
              </>
            )}
          </p>
          <p>
            <FormattedMessage id="TOOLTIP_NAVIGATION_FINALIZE_2">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      )}
    </div>
  );
}

export default NavigationTooltip;

