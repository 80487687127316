import { SPINNER } from '../actions';

export default function (on) {
  return function (dispatch, getState) {
    const { active } = getState().spinner;
    active !== on &&
      dispatch({
        type: SPINNER,
        payload: on,
      });
  };
}
