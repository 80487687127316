import './ProfileVideo.scss';

import useAxios from 'axios-hooks';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import api from '../../../../utils/api';
import { getUpload } from '../../../../utils/uploadUtils';
import Button from '../../../Button/Button';
import SpinnerItem from '../../../ThumbnailItem/SpinnerItem/SpinnerItem';
import Uploader from '../../../Uploader';
import ImageItem from '../../../Uploader/ImageItem/ImageItem';

function ProfileVideo({ isMobile, uploads = [], uploadFile }) {
  const intl = useIntl();
  const uploadFormData = new FormData();
  uploadFormData.append('fsk[]', 'fsk18');
  const intervalRef = useRef(null);
  const setTimeoutRef = useRef(null);

  const [videoError, setVideoError] = useState('');
  const [enabledProfileVideo, setEnabledProfileVideo] = useState(null);
  const [pendingProfileVideo, setPendingProfileVideo] = useState(null);
  const [convertingVideo, setConvertingVideo] = useState(false);
  const [waitForThumbnail, setWaitForThumbnail] = useState(false);

  const indicator = 'profilevideo';

  const { uploadIndicator, uploadError, uploadPercent, uploadResult } =
    getUpload(uploads, indicator);

  const [, getProfileVideosRequest] = useAxios(
    {
      url: '/user/profile-videos',
    },
    { manual: true }
  );

  const getProfileVideosLinks = useCallback(() => {
    getProfileVideosRequest()
      .then((response) => {
        const profileVideo = response.data.find(
          (video) => video.state === 'enabled'
        );

        const pendingProfileVideo = response.data.find(
          (video) => video.state === 'pending'
        );

        if (pendingProfileVideo) {
          if (waitForThumbnail) {
            setTimeoutRef.current = setTimeout(() => {
              setPendingProfileVideo(pendingProfileVideo);
            }, 2000);
          } else {
            setPendingProfileVideo(pendingProfileVideo);
          }
        }

        if (profileVideo) {
          setEnabledProfileVideo(profileVideo);
        }
      })
      .catch((error) => {});
    return () => clearTimeout(setTimeoutRef.current);
  }, [getProfileVideosRequest, waitForThumbnail]);

  useEffect(() => {
    getProfileVideosLinks();
  }, [getProfileVideosLinks]);

  useEffect(() => {
    if (
      (pendingProfileVideo && !pendingProfileVideo.thumbnails.length) ||
      uploadPercent
    ) {
      setConvertingVideo(true);
    } else {
      setConvertingVideo(false);
      clearInterval(intervalRef.current);
    }
    if (convertingVideo) {
      setWaitForThumbnail(true);
      intervalRef.current = setInterval(async () => {
        if (!!pendingProfileVideo?.thumbnails?.length) {
          clearInterval(intervalRef.current);
        } else {
          getProfileVideosLinks();
        }
      }, 5000);
    }
    return () => {
      clearInterval(intervalRef.current);
      setWaitForThumbnail(false);
    };
  }, [
    pendingProfileVideo,
    convertingVideo,
    pendingProfileVideo?.thumbnails?.length,
    uploadPercent,
    getProfileVideosLinks,
  ]);

  useEffect(() => {
    if (uploadIndicator !== indicator) {
      return;
    }

    if (uploadError) {
      setVideoError(intl.formatMessage({ id: 'IMAGE_UPLOAD_ERROR' }));
      return uploadFile('', false, indicator);
    }

    if (uploadResult && uploadResult?.data) {
      const pendingProfileVideo = uploadResult.data.find(
        (video) => video.state === 'pending'
      );

      if (pendingProfileVideo) {
        setPendingProfileVideo(pendingProfileVideo);
      }
      return uploadFile('', false, indicator);
    }
  }, [
    getProfileVideosLinks,
    intl,
    uploadError,
    uploadFile,
    uploadIndicator,
    uploadResult,
  ]);

  const deleteProfileVideo = async () => {
    if (enabledProfileVideo) {
      const response = await api.delete(
        '/user/profile-videos/' + enabledProfileVideo.id
      );
      if (response.status === 204) {
        setEnabledProfileVideo(null);
      }
    }
  };

  return (
    <div className="profile-video-wrapper">
      <h1 className="headline">
        <FormattedMessage id="PROFILE_VIDEO_HEADLINE_1" />
      </h1>
      <p>
        <FormattedMessage id="PROFILE_VIDEO_TEXT_1" />
      </p>
      <p>
        <FormattedMessage id="PROFILE_VIDEO_TEXT_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <div className="uploader-wrapper">
        <Uploader
          uploadPath="/user/profile-videos"
          uploadFormData={uploadFormData}
          minDimensions={{ width: 1365, height: 768 }}
          label="PROFILE_VIDEO_UPLOAD_HEADLINE"
          acceptedFileTypes={[
            '.mov',
            '.avi',
            'video/mp4',
            'video/quicktime',
            'video/x-msvideo',
            'video/x-ms-wmv',
          ]}
          handleError={(error) => {
            setVideoError(error);
          }}
          imageError={videoError}
          hasChildrenWrapper={true}
          dropzoneProps={{ multiple: false }}
          openCam={() => {}}
          isMobile={isMobile}
          onlineImage={null}
          className="profile-video"
          update={() => {
            setVideoError('');
          }}
          videoUrl={
            enabledProfileVideo &&
            process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES +
              '/' +
              enabledProfileVideo.movies.s
          }
          poster={
            !!enabledProfileVideo?.thumbnails?.length &&
            process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES +
              enabledProfileVideo.thumbnails[0].file
          }
          withVideo={true}
          profileVideosLoading={false}
          indicator={indicator}
        >
          {!!pendingProfileVideo?.thumbnails?.length && !uploadPercent && (
            <ImageItem
              src={
                process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES +
                pendingProfileVideo.thumbnails[0].file
              }
              labelTitle={intl.formatMessage({
                id: 'LABEL_PENDING',
              })}
              labelContent={intl.formatMessage({
                id: 'PROFILE_PICTURES_COMMENT_UNDER_REVIEW',
              })}
              status={'pending'}
            />
          )}

          {((pendingProfileVideo && !pendingProfileVideo.thumbnails.length) ||
            uploadPercent > 0) && (
            <div className="thumbnail-converting">
              <SpinnerItem
                uploadPercent={uploadPercent < 100 ? uploadPercent : false}
              />
            </div>
          )}
        </Uploader>
      </div>
      {enabledProfileVideo && (
        <div className="delete-video-wrapper">
          <Button
            type="button"
            variant="dark"
            onClick={() => deleteProfileVideo()}
            label="PROFILE_VIDEO_DELETE_LABEL"
          />
        </div>
      )}
    </div>
  );
}

export default ProfileVideo;

