import { connect } from 'react-redux';

import {
  askExclusiveSender,
  errorChatAttachment,
  payttachmentAdd,
  selectAttachment,
  sendChatMessage,
  toggleChatMenu,
  togglePayttachmentsMenu,
  uploadFile,
} from '../../../../actions';
import {
  roomType as roomTypeUtils,
  upgradetimer as upgradetimerUtils,
  user as userUtils,
} from '../../../../utils';
import ChatMenu from './ChatMenu';

const mapStateToProps = (state) => {
  const {
    user: {
      account: { payttachments: payttachment },
    },
    sender: { runToy },
    camUser: { camUsers },
    dialog: { dialogs },
    chat: { chatMenuOpen, isMessageUser, payttachmentsMenuOpen },
    sender: { roomTypes },
    view: { cam, isMobile },
    smiley: { overlay },
    attachments: { files, selectedAttachment, selectedAttachmentName },
    payttachments: { files: payttachments },
    upgradetimer,
    upload: { uploads },
  } = state;

  const user = userUtils.activeUser([...camUsers, ...dialogs]) || {};
  const isAdmin = user?.flags?.includes('is_support');
  const attachments = files
    .filter((f) => f.status !== 'denied')
    .map((f) => ({
      label: f.name,
      value: f.id,
      file: f.thumbnail,
      type: f.type,
    }));

  const isAppsUser = userUtils.isAppUser(user);

  const payttachmentsEnabled = payttachment === 'enabled' && !isAppsUser;

  return {
    isMessageUser,
    chatMenuOpen,
    smileyOverlay: overlay,
    payttachmentsMenuOpen,
    username: user.name,
    allowAttachments:
      (isMessageUser && !isAppsUser) || !!user?.portals?.allowAttachments,
    hasCam2Cam: user.hasCam2Cam,
    isExclusive: roomTypeUtils.isExclusive(roomTypes),
    cam,
    isMobile,
    attachmentsCount: files.length,
    attachments,
    selectedAttachment,
    selectedAttachmentName,
    payttachments,
    hasExclusive: roomTypeUtils.hasExclusiveRoomType(roomTypes),
    exclusiveAllowed: upgradetimerUtils.exclusiveAllowed(user, upgradetimer),
    uploads,
    payttachmentsEnabled,
    userId: user.id,
    isAdmin,
    isToyActive: runToy?.countDown > 0,
  };
};

const mapDispatchToProps = {
  askExclusiveSender,
  toggleChatMenu,
  selectAttachment,
  sendChatMessage,
  uploadFile,
  payttachmentAdd,
  togglePayttachmentsMenu,
  errorChatAttachment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatMenu);
