import '../Streaming.scss';

import Cam from '../../../../Livecam/Cam';
import Cam2Cam from '../../../../Livecam/Cam/Cam2Cam';
import MuteButton from '../MuteButton';

function MobileStreaming({ myCam }) {
  return (
    <div className="mobile-cam">
      <div className="cam-wrapper">
        {myCam ? (
          <Cam enableOverlay={true}>
            <div className="control-item">
              <MuteButton />
            </div>
          </Cam>
        ) : (
          <div className="cam">
            <Cam2Cam />
          </div>
        )}
      </div>
    </div>
  );
}

export default MobileStreaming;
