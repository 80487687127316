import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

import { getMyContentRoutes, routeFunctionsMap } from '../../../routes';
import HeaderMenu from '../../HeaderMenu/HeaderMenu';
import Section from '../../Section/Section';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import MyContentContext from './MyContentContext';

function MyContent({ user, isMobile }) {
  const intl = useIntl();
  const [items, setItems] = useState(null);
  const [pathname, setPathname] = useState(null);
  const [routes, setRoutes] = useState(null);
  const [headerTitle, setHeaderTitle] = useState('');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const id = parseInt(useLocation().pathname.split('/').pop(), 10);

  const contextValue = useMemo(() => {
    return { setIsLoading };
  }, []);

  useEffect(() => {
    if (history.location.pathname === '/my-content') {
      history.push('/my-content/photos');
    }
  }, [history]);

  useEffect(() => {
    let items = getMyContentRoutes().filter((item) => !item.notInMenu);
    const pathname = `/${history.location.pathname.split('/')[2]}`;
    let routeFunctionsMapCopy = _.cloneDeep(routeFunctionsMap);
    if (user?.account?.intimate_diary !== 'enabled') {
      routeFunctionsMapCopy.delete('/diary');
      items = items.filter((item) => item.path !== '/diary');
    }
    const routesFunction = routeFunctionsMapCopy.get(pathname);

    const headerItems = routesFunction
      ? routesFunction(user?.account?.slave, history.location.pathname)
          ?.filter((item) => {
            if (!item.notInMenu) {
              return true;
            } else if (item.notInMenu === 'current-path') {
              return history.location.pathname.includes(item.path);
            } else {
              return false;
            }
          })
          .map((route) => {
            if (
              route.path === '/edit' &&
              history.location.pathname.includes('diary/edit/') &&
              isNaN(id)
            ) {
              route.title = 'DIARY_MENU_CREATE';
            }
            return route;
          })
      : null;

    const routes = items
      ? items.map((route, i) => {
          if (history.location.pathname.includes(route.path)) {
            setHeaderTitle(intl.formatMessage({ id: route.headerTitle }));
          }
          const Component = route.component;
          const componentRoutesFunction = routeFunctionsMapCopy.get(route.path);

          return (
            <Route
              key={`/my-content${route.path}`}
              path={`/my-content${route.path}`}
              component={() => (
                <Component
                  items={
                    componentRoutesFunction
                      ? componentRoutesFunction(user?.account?.slave)
                      : null
                  }
                />
              )}
            />
          );
        })
      : null;

    routes.push(<Redirect key="redirect-to/photos" to="/my-content/photos" />);
    setItems(headerItems);
    setPathname(pathname);
    setRoutes(routes);
  }, [
    history.location.pathname,
    intl,
    user?.account?.slave,
    user?.account?.intimate_diary,
    id,
  ]);

  return (
    <div className="page-wrapper">
      <Section title={headerTitle} className="component-section">
        <>
          {items &&
          !history.location.pathname.includes('/comments/') &&
          !history.location.pathname.includes('/free-movie-of-the-day/') ? (
            <HeaderMenu
              className="header-menu-wrapper"
              defaultPath={`/my-content${pathname}`}
              items={items}
              showButton={
                history.location.pathname.includes('my-photos') ||
                history.location.pathname.includes('my-videos') ||
                history.location.pathname.includes('my-diary') ||
                (history.location.pathname.includes('diary/edit/') &&
                  id > 0 &&
                  !isMobile)
              }
            />
          ) : null}
          <div
            className={
              items &&
              !history.location.pathname.includes('/comments/') &&
              !history.location.pathname.includes('/free-movie-of-the-day/')
                ? 'component-wrapper'
                : 'component-wrapper-no-header-menu'
            }
          >
            {isLoading && <SpinnerComponent />}
            <MyContentContext.Provider value={contextValue}>
              <Switch>{routes}</Switch>
            </MyContentContext.Provider>
          </div>
        </>
      </Section>
    </div>
  );
}

export default MyContent;

