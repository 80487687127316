import { UPGRADE_SAVE } from '../actions';

export default ({ logins = [], roomTypes = [] }, add = true) => ({
  type: UPGRADE_SAVE,
  payload: {
    logins,
    roomTypes,
  },
  meta: {
    add,
  },
});
