export function getItemColor(items, isFinished, currentItemIndex, index) {
  const item = items[index];

  if (item.isError) {
    return 'red';
  }

  if (currentItemIndex === index) {
    return 'blue';
  }

  if (item.isSuccess || isFinished) {
    return 'green';
  }

  if (item.disabled) {
    return 'gray';
  }

  return null;
}

export function getLineColor(
  state,
  items,
  isFinished,
  currentItemIndex,
  index,
  isLastLine
) {
  const item = items[index];

  const hasPreviousItemError = index > 0 && items[index - 1].isError;
  if (state === 'pending') {
    return 'green';
  }

  if (item.isError || hasPreviousItemError) {
    return 'red';
  }

  if (isFinished || (item.isSuccess && item.disabled)) {
    return 'green';
  }

  if (index <= currentItemIndex && !isLastLine) {
    return 'blue';
  }

  return null;
}
