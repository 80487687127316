import CryptoJS from 'crypto-js';

import { PERSIST_BASE_KEY, PERSIST_SEPERATOR_KEY } from '../constants';

const AES = CryptoJS.AES;
const UTF8 = CryptoJS.enc.Utf8;
const SHA1 = CryptoJS.SHA1;
let secret;
let secretHash;
export default {
  init: (key) => {
    secret = key;
    secretHash = PERSIST_BASE_KEY + PERSIST_SEPERATOR_KEY + SHA1(secret);
  },
  run: (obj, encrypt) => {
    if (typeof secret === 'undefined') {
      throw new Error('crypt not initialized, secret missing');
    }
    let result;
    if (encrypt) {
      result = AES.encrypt(JSON.stringify(obj), secret).toString();
    } else {
      // some objects to decrypt are null because there may be no outbound state if nothing is stored in the storage
      // so we need to silently handle this as it would destroy rehydration of other states that are given
      // e.g. the localStorage was cleared by the user, but there are states from the api storage that should be restored
      // then JSON.parse would fail on the states from local storage and this errors prevent all state to be restored
      try {
        result = JSON.parse(AES.decrypt(obj, secret).toString(UTF8));
      } catch (error) {
        result = {};
      }
    }
    return result;
  },
  secretHash: () => secretHash,
};
