import { connect } from 'react-redux';

import { favoriteUpdateFromMessenger } from '../../../../../../actions';
import { TYPE_DATING } from '../../../../../../constants/user';
import { user as userUtils } from '../../../../../../utils';
import Favorite from './Favorite';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
  } = state;
  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
  const isAdmin =
    activeUser?.flags?.includes('is_support') || userUtils.isAdmin(activeUser);
  const isIgnored = activeUser?.flags?.includes('ignored');
  let isFavorite = false;
  if (activeUser?.flags?.includes('favorite')) {
    isFavorite = true;
  }
  let show = true;

  if (
    !activeUser?.flags ||
    activeUser?.systemId === 901 ||
    activeUser?.type === TYPE_DATING
  )
    show = false;

  return {
    isFavorite,
    show,
    isActivated:
      !userUtils.disableChat(state, activeUser) &&
      !isAdmin &&
      !userUtils.checkDeleteOrIgnored(activeUser),
    requestStarted: activeUser?.requestStarted,
    isIgnored,
  };
};

const mapDispatchToProps = (dispatch) => ({
  favoriteUser: () => dispatch(favoriteUpdateFromMessenger()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);

