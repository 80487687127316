import { connect } from 'react-redux';

import { openOverlay } from '../../../../actions';
import LandingPage from './LandingPage';

const mapStateToProps = (state) => ({
  isSubmenuShown: state.view.sidebar,
  isMobile: state.browser.isMobile,
  language: state.user.account?.language,
});

const mapDispatchToProps = {
  openOverlay,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);

