import { connect } from 'react-redux';

import {
  spinner,
  userUpdateDocuments as updateDocuments,
} from '../../../../actions';
import MyAccountFileApi from './MyAccountFileApi';

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateDocuments,
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountFileApi);
