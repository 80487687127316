import { FormattedMessage } from 'react-intl';

import BackButton from '../../../BackButton';
import UserProfileList from '../Item/UserProfileList';
import UserProfileText from '../Item/UserProfileText';
import UserProfilePersonalItem from './UserProfilePersonalItem';

function UserProfileInfo({
  personal,
  my_preferences,
  more_info,
  language,
  togglePage,
}) {
  if (!personal && !my_preferences && !more_info) {
    return null;
  }
  return (
    <>
      {personal && (
        <div className="area user-personal">
          <div className="user-title">
            <BackButton onClick={togglePage} />
            <span>
              <FormattedMessage id="user.personal" />
            </span>
          </div>
          <table className="noHover">
            <tbody>
              <UserProfilePersonalItem
                label="user.height"
                postfix=" cm"
                value={personal.height}
                translate={false}
              />
              <UserProfilePersonalItem
                label="user.weight"
                postfix=" kg"
                value={personal.weight}
                translate={false}
              />
              <UserProfilePersonalItem
                label="user.ethnicity"
                value={personal.ethnicity}
              />
              <UserProfilePersonalItem
                label="user.eyeColor"
                value={personal.eye_color}
              />
              <UserProfilePersonalItem
                label="user.hairColor"
                value={personal.hair_color}
              />
              <UserProfilePersonalItem
                label="user.hairLength"
                value={personal.hair_length}
              />
              <UserProfilePersonalItem
                label="user.figure"
                prefix="figure_"
                value={personal.figure}
              />
              <UserProfilePersonalItem
                label="user.bodyHair"
                value={personal.body_hair}
              />
              <UserProfilePersonalItem
                label="user.pubicHair"
                prefix="pubic_hair_"
                value={personal.pubic_hair}
              />
              <UserProfilePersonalItem
                label="user.penisLength"
                postfix=" cm"
                value={personal.penis_length}
                translate={false}
              />
              <UserProfilePersonalItem
                label="user.penisGirth"
                postfix=" cm"
                value={personal.penis_girth}
                translate={false}
              />
              <UserProfilePersonalItem
                label="user.glasses"
                value={personal.glasses}
              />
              <UserProfilePersonalItem
                label="user.tatoos"
                prefix="tattoo_and_piercings_"
                value={personal.tattoos}
              />
              <UserProfilePersonalItem
                label="user.piercings"
                prefix="tattoo_and_piercings_"
                value={personal.piercings}
              />
              <UserProfilePersonalItem
                label="user.maritalStatus"
                value={personal.marital_status}
              />
              <UserProfilePersonalItem
                label="user.smoking"
                value={personal.smoking}
              />
            </tbody>
          </table>
        </div>
      )}
      {my_preferences && (
        <div className="area user-preferences">
          <div className="user-title">
            {!personal && <BackButton onClick={togglePage} />}
            <span>
              <FormattedMessage id="user.preferences" />
            </span>
          </div>
          <table className="noHover">
            <tbody>
              <UserProfileList
                label="user.lookingFor"
                keyPrefix="looking_for_"
                list={my_preferences.looking_for}
              />
              <UserProfileList
                label="user.experience"
                keyPrefix="experience_"
                list={my_preferences.experience}
              />
              <UserProfileList
                label="user.turnsOn"
                keyPrefix="turns_on_"
                list={my_preferences.turn_ons}
              />
            </tbody>
          </table>
        </div>
      )}
      {more_info && (
        <div className="area user-more-info">
          <div className="user-title">
            {!personal && !my_preferences && (
              <BackButton onClick={togglePage} />
            )}
            <span>
              <FormattedMessage id="user.moreInfo" />
            </span>
          </div>
          <table className="noHover">
            <tbody>
              <UserProfileText
                label="user.iSay"
                text={more_info.i_say}
                language={language}
              />
              <UserProfileText
                label="user.otherSay"
                text={more_info.other_say}
                language={language}
              />
              <UserProfileText
                label="user.eroticFantasy"
                text={more_info.erotic_fantasy}
                language={language}
              />
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default UserProfileInfo;
