export function getUpload(uploads, indicator) {
  const upload =
    uploads.find((upload) => upload?.indicator === indicator) || {};
  return {
    uploadIndicator: upload.indicator || null,
    uploadError: upload.error || null,
    uploadPercent: upload.percent || 0,
    uploadResult: upload.result || null,
  };
}

