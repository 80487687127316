import {
  ALERT_TYPE_EXCLUSIVE,
  ALERT_TYPE_EXCLUSIVE_SENDER,
} from '../constants';
const find = (alerts, stamp) => alerts.find((alert) => alert.stamp === stamp);

const has = (alerts, stamp) => (find(alerts, stamp) ? true : false);

const hasMessage = (alerts, id) =>
  alerts.find((alert) => alert.message.id && alert.message.id === id)
    ? true
    : false;
const hasModal = (alerts) =>
  alerts.find((alert) => alert.modal && alert.modal === true) ? true : false;
const createExclusivePosition = (alerts) => {
  const pos = {
    x: 0,
    y: 0,
  };
  const found = alerts.filter(
    (a) =>
      a.type === ALERT_TYPE_EXCLUSIVE || a.type === ALERT_TYPE_EXCLUSIVE_SENDER
  );
  if (found.length > 0) {
    pos.x = 10 * (found.length + 1);
    pos.y = 10 * (found.length + 1);
  }
  return pos;
};
export default {
  find,
  has,
  hasMessage,
  hasModal,
  createExclusivePosition,
};
