import React from 'react';
import { FormattedMessage } from 'react-intl';

function PanelContentWithoutImage({ messageId }) {
  return (
    <p>
      <FormattedMessage id={messageId}>
        {(message) => (
          <span dangerouslySetInnerHTML={{ __html: message[0] }} />
        )}
      </FormattedMessage>
    </p>
  );
}

export default PanelContentWithoutImage;
