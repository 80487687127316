import { connect } from 'react-redux';

import {
  friendsError,
  removeDeniedFriendRequest,
  spinner,
} from '../../../../../actions';
import Denied from './Denied';

const mapStateToProps = (state) => ({
  dialog: state.dialog.dialogs,
  askRemoveDeniedFriendRequestConfirmed:
    state.friends.askRemoveDeniedFriendRequestConfirmed,
  askRemoveDeniedFriendRequestConfirmedAlert:
    state.friends.askRemoveDeniedFriendRequestConfirmedAlert,
  askRemoveDeniedFriendRequestId: state.friends.askRemoveDeniedFriendRequestId,
  askRemoveDeniedFriendRequestNickname:
    state.friends.askRemoveDeniedFriendRequestNickname,
});

const mapDispatchToProps = {
  spinner,
  friendsError,
  removeDeniedFriendRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Denied);
