export default {
  HEADLINE_4SCORE: `Community Game 4SCORE`,
  CONTENT_1_4SCORE: `Want to have an entertaining time with your customers and earn extra coins at the same time? Then discover our new feature: <span class="bold">Community Game 4Score</span>!`,
  CONTENT_2_4SCORE: `<span class="bold">A classic in real time</span><br />Everyone knows the classic game connect 4! Who will be the first to get four pieces in a row? Play in real time with your customers and win guaranteed coins, regardless of the outcome!`,
  CONTENT_3_4SCORE: `<span class="bold">Fun interaction with added value</span><br />With 4Score we offer you not just a game, but a unique opportunity to intensify the interaction with your customers in a fun and easy way! By playing and chatting with your customers, you build a stronger bond and increase your message sales at the same time.`,
  CONTENT_3_1_4SCORE: `Speaking of added value: 4Score is also a perfect addition to a personal livecam session with your customers. Maybe even with a special interaction depending on your success in the game?!`,
  CONTENT_4_4SCORE: `<span class="bold">Transparent process</span><br />Customers can invite you when you are online and 25 coins will be reserved for you. After accepting the invitation, the game begins, and the increased interaction leads to more revenue!`,
  CONTENT_5_4SCORE: `<span class="bold">Fair compensation model</span><br />For every game you are guaranteed to receive 25 coins as soon as the game is successfully completed, regardless of whether you win or lose!`,
  CONTENT_6_4SCORE: `<span class="bold">Overview & control</span><br />Always keep track of your current games, past games and possible invitations! A separate symbol shows you the status of each game and you can see all the game progressions in a new section!`,
  CONTENT_7_4SCORE: `<span class="bold">Flexibility & mobility</span><br />No matter where you are, with 4SCORE on LiveCreator you'll always be ready to play! You can play anywhere and chat with the Messenger at the same time, because all game features in LiveCreator are of course also available in the mobile version.`,
  CONTENT_8_4SCORE: `<span class="bold">Future plans</span><br />This is just the beginning! If 4Score is successful, new features, additional games, rankings, seasons with rewards and specials are planned for creators!`,
  CONTENT_8_1_4SCORE: `Join us and take your customer interactions to the next level!`,

  HEADLINE_DETAIL_4SCORE: `And this is how it works in detail`,
  CONTENT_1_DETAIL_4SCORE: `The customer can invite you to a game via your profile, but only if you are currently online. As soon as you receive the invitation, the prize of <span class="bold">25 Coins</span> will be reserved for you immediately. You will then receive an invitation in the Multi Messenger on LiveCreator.`,
  CONTENT_2_DETAIL_4SCORE: `You now have the option to accept the invitation. As soon as this happens, it will be decided at random who of the two of you starts.  Now you take turns making your moves, given <span class="bold">5 minutes</span> for each turn. If the time runs out, this results in a “timeout”. As soon as a winner is determined or no additional moves are possible, the game is over.`,
  CONTENT_3_DETAIL_4SCORE: `You also have the option to decline the customer's invitation.`,

  LIST_HEADLINE_1_DETAIL_4SCORE: `The compensation`,
  LIST_CONTENT_1_DETAIL_4SCORE: `A game costs the 25 coins. This price is not variable like other options in LiveCreator.`,
  LIST_CONTENT_1_1_DETAIL_4SCORE: `The coins will be refunded to the customer in the following cases:<br />
  <ul>
    <li>You decline the invitation</li>
    <li>The invitation runs into a timeout</li>
    <li>A timeout occurs during your turn</li>
  </ul>`,
  LIST_CONTENT_1_2_DETAIL_4SCORE: `In the following cases, the coins will be credited to you as sales:<br />
  <ul>
    <li>The game ends successfully regardless of whether you win or lose, or whether it ends in a draw.</li>
    <li>A timeout occurs while it is the customer's turn</li>
  </ul>`,
  LIST_HEADLINE_2_DETAIL_4SCORE: `Further information`,
  LIST_CONTENT_2_DETAIL_4SCORE: `You cannot invite customers to games. Of course, you can point out the games in the chat and encourage the user to start a game.`,
  LIST_CONTENT_2_1_DETAIL_4SCORE: `In the userlist of the {link} you will always see who you are currently playing and which game is waiting for your turn.`,
  LIST_CONTENT_2_2_DETAIL_4SCORE: `You can look at each customer's last game together and you will also find small statistics about all the games with him and your number of wins.`,
  LIST_CONTENT_2_3_DETAIL_4SCORE: `Every customer who is in a game with you will be marked with a new symbol in the Multi Messenger. The color of the symbol indicates what status the game is in:`,
  LIST_ICON_INVITATION_4SCORE: `You were invited by a customer`,
  LIST_ICON_RUNNING_4SCORE: `A game is currently active, it's the customer's turn`,
  LIST_ICON_TURN_4SCORE: `It's your turn, the customer is waiting for your turn`,
  LIST_CONTENT_2_4_DETAIL_4SCORE: `There is a new section under {link} where you can view the last 30 game histories of all customers.`,
  LINK_TEXT_STATS_4SCORE: `My stats - Game 4Score`,
  LIST_CONTENT_2_5_DETAIL_4SCORE: `Dating customers cannot play with you because community games are not available on these portals`,
  LIST_CONTENT_2_6_DETAIL_4SCORE: `You can also decide not to offer community games in general. To do this, please go to your {link}.`,
};

