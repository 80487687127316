import { connect } from 'react-redux';

import {
  toggleUserMenu,
  toggleUsergameView,
  toggleUsergamestatView,
  toggleUserinfoView,
  toggleUsernoteView,
} from '../../../../actions';
import { TYPE_ADMIN } from '../../../../constants/user';
import { user as userUtils } from '../../../../utils';
import UserTab from './UserTab';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    view: { userMenuOpen },
    viewDimensions: { largeScreenStreaming },
    ignored: { ignoredIds },
  } = state;
  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
  const isAdmin = (activeUser || {})?.type === TYPE_ADMIN;
  const isIgnored = ignoredIds.includes(activeUser?.partner);
  return {
    ...state.view,
    menuIsActivated: !isAdmin && !userUtils.disableChat(state, activeUser),
    showGame: userUtils.showGame(activeUser),
    open: userMenuOpen,
    hasUser: !!activeUser,
    largeScreenStreaming,
    isIgnored,
  };
};

const mapDispatchToProps = {
  toggleUserMenu,
  toggleUsernoteView,
  toggleUserinfoView,
  toggleUsergameView,
  toggleUsergamestatView,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTab);
