import { FormattedMessage, useIntl } from 'react-intl';

import ButtonSelect from '../../Forms/ButtonSelect/ButtonSelect';
import { generateSoundOptions } from './SoundSelect.functions';

const SoundSelect = ({
  title,
  sounds,
  selected,
  active,
  onChange,
  onActivate,
  onPlay,
}) => {
  const intl = useIntl();

  function onSelectHandler(event) {
    onChange(event);
    if (!active) {
      onActivate(true);
    }
    if (event.target.value === '') {
      onActivate(false);
    }
  }

  return (
    <div className="form-group">
      <label>
        <FormattedMessage id={title} />
      </label>
      <ButtonSelect
        selected={selected}
        onChange={onSelectHandler}
        options={generateSoundOptions(sounds, intl)}
        buttonClick={onPlay}
      />
    </div>
  );
};

export default SoundSelect;
