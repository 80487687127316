export default {
  GALLERIES_HEADLINE: `Photo and Video Galleries`,
  GALLERIES_INTRO: `Your own image and video content offers you an additional, lucrative earning opportunity.Unlike chatting in messenger and livecam, you don't have to be online for this. Upload your content once and it will be available for purchase in your profile from now on.In addition, users will get a foretaste and you can arouse their interest not only to buy content from you, but also to get to know you live in the chat and in the livecam.`,

  GALLERIES_CONTENT_STEP_1: `Preparing the galleries`,
  GALLERIES_CONTENT_STEP_1_CONTENT: `Choose a selection of images that you want to use in your gallery. Look for a selection of different poses or outfits. Avoid duplicate and very similar images. By diversifying your galleries, you arouse the curiosity of your customers and they will always look for new galleries. Choose a suitable thumbnail, because that's the first thing the customer will see. It should match the content of the gallery. Think of a suitable headline and description that will convince the customer to want to buy the gallery.`,
  GALLERIES_CONTENT_STEP_2: `Create a new gallery`,
  GALLERIES_CONTENT_STEP_2_CONTENT: `In the main menu under "My Content", select "Photo" or "Video". Click on the "Create a new gallery" button.`,
  GALLERIES_CONTENT_STEP_2_1_CONTENT: `In order to publish a gallery, the following requirements apply:`,
  GALLERIES_CONTENT_STEP_2_2_CONTENT: `
   <ul class='checklist'>
      <li>at least 3 photos (minimum resolution 800 x 600 pixels) or a video (format: mp4, avi, mpg, wmv) with a minimum length of 1 minute</li>
      <li>a preview image (minimum size 1024 x 576 pixels)</li>
      <li>Title and description</li>
      <li>at least one category</li>
      <li>assigned actor document(s)</li>
    </ul>`,
  GALLERIES_CONTENT_STEP_2_3_CONTENT: `Once you've uploaded everything, you'll have two options for publishing:`,
  GALLERIES_CONTENT_STEP_2_4_CONTENT: `
   <ul class='checklist'>
      <li>If you click on the "Publish now" button, the gallery will be sent to the support for review. This means that your gallery won't be immediately available to the client. This will only be done after verification by the support team.</li>
      <li>The button "Set schedule" allows you to schedule a release in the future. In this case, the date must be at least 2 days in the future. In the background, the support checks the gallery and approves it. On the date you have chosen, the gallery will then be published automatically.</li>
    </ul>`,
  GALLERIES_CONTENT_STEP_3: `Pricing Guidelines`,
  GALLERIES_CONTENT_STEP_3_CONTENT: `Depending on quality and FSK<br />Y
  Video: approx. 10 Coins / minute<br />Images: approx. 2 – 8 Coins / image`,
  GALLERIES_CONTENT_STEP_3_1_CONTENT: `More extreme content such as BDSM, NS, fetish, etc. can also be significantly more expensive!<br />
  You can also offer content as a teaser for free (except FSK18!).`,
  GALLERIES_CONTENT_STEP_4: `Publishing, deleting, and archiving`,
  GALLERIES_CONTENT_STEP_4_CONTENT: `Once galleries have been published, they cannot be deleted. If you no longer want to offer them, you can archive them. This means that they will only be available to you and customers who have already purchased them in your account. Galleries can only be deleted by support if no pictures/movies have been sold from them yet. Unlocking/deleting a gallery can take up to a day!`,
};
