import { CHANGE_CAM } from '../actions';

export default (value, label, possibleResolutions) => ({
  type: CHANGE_CAM,
  payload: {
    value,
    label,
    possibleResolutions,
  },
});
