import { user as userUtils } from '../../utils';
import { CAM2CAM_ROTATE } from '../actions';
export default () => (dispatch, getState) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
  } = getState();
  const { userId } = userUtils.activeUser([...camUsers, ...dialogs]);
  dispatch({
    type: CAM2CAM_ROTATE,
    payload: {
      userId,
    },
  });
};
