import { roomType as roomTypeUtils } from '../../utils';
import { management } from '../';
import { STREAM_RECONNECT } from '../actions';

export default () => (dispatch, getState) => {
  const {
    sender: { roomTypes },
    logFps: { count: logFpsCount, maxRetries },
  } = getState();
  if (!roomTypeUtils.isEvent(roomTypes)) {
    if (logFpsCount < maxRetries) {
      dispatch(management.logfpsTimer.start());
    }
  }
  dispatch({
    type: STREAM_RECONNECT,
  });
};
