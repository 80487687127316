import { user as userUtils } from '../../../utils';
import api from '../../../utils/api';
import add from './add';
export default (userId) => async (dispatch, getState) => {
  if (userUtils.gamesBlocked(getState())) {
    return;
  }

  const response = await api.get(`/user/games/${userId}/statistics`);

  if (userUtils.gamesBlocked(getState())) {
    return;
  }

  if (response?.data) {
    dispatch(add(response.data, userId));
  }
};
