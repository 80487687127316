import { connect } from 'react-redux';

import {
  addAttachment,
  openOverlay,
  spinner,
  uploadFile,
} from '../../../../actions';
import Attachments from './Attachments';

const mapStateToProps = (state) => ({
  isMobile: state.view.isMobile,
  isLarge: state.view.isLarge,
  attachmentsCount: state.attachments.files.length,
  uploads: state.upload.uploads,
});

const mapDispatchToProps = {
  openOverlay,
  uploadFile,
  spinner,
  addAttachment,
};

export default connect(mapStateToProps, mapDispatchToProps)(Attachments);

