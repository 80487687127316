import {
  MANAGEMENT_EVENTS_TIP
} from '../actions/actions';

const initialState = {
  total: 0
};

const tip = (state = initialState, action) => {
  switch (action.type) {
  case MANAGEMENT_EVENTS_TIP:
    return {...state, total: state.total + action.payload.amount};
  default:
    return state;
  }
};

export default tip;
