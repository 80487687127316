export default {
  TOYCONTROL_HEADLINE: 'Verbindung',
  TOYCONTROL_TOY_INTENSITY: 'Toy Intensität',
  TOYCONTROL_TOY_INTENSITY_TEXT:
    'Hier kannst Du die maximale Intensität Deines Lovense Toy festlegen.',
  TOYCONTROL_CURRENT_STATE: 'Aktueller Status',
  TOYCONTROL_CONNECTED: 'verbunden',
  TOYCONTROL_NOT_CONNECTED: 'nicht verbunden',
  TOYCONTROL_CONNECTION_TEXT:
    'Wenn Dein Lovense Toy schon einmal mit LiveCreator verbunden war, schalte es jetzt ein und stelle die Verbindung wieder her.',
  TOYCONTROL_CONNECTION_ASSISTENT: 'Verbindungsassistent',
  TOYCONTROL_CONNECTION_DESCRIPTION:
    'Falls Du Dein Toy noch nicht mit Deinem LiveCreator Account verbunden hast, lade Dir die Lovense Connect App herunter und verbinde Dein Toy mit der App.',
  TOYCONTROL_NEXT_BTN_LABEL: 'weiter',
  TOYCONTROL_ASSISTENT_HEADLINE_1: 'Installiere die App und verbinde Dein Toy',
  TOYCONTROL_ASSISTENT_TEXT_1:
    'Tippe auf weiter, wenn Dein Toy mit der App verbunden ist.',
  TOYCONTROL_ASSISTENT_HEADLINE_2: 'Scanne den QR Code mit der App',
  TOYCONTROL_ASSISTENT_TEXT_2:
    'oder gebe diesen Zahlencode in der Desktop-App ein',
  TOYCONTROL_ASSISTENT_HEADLINE_3: 'Fertig!',
  OPTIONS_FEATURES_TOYCONTROL: 'Lovense Toy',

  toycontrol_marketing_headline: 'Nutze jetzt Deine Love-Toys von Lovense',
  toycontrol_marketing_text1:
    '<span class="bold">Was sind Lovense Toys?</span><br />Die erotischen Love-Toys von Lovense sind online fernsteuerbare Vibratoren. Es gibt sie in vielen verschiedenen Varianten. Vom Vibrator über den Plug, bis hin zum Vibro-Ei oder dem Auflegevibrator, kannst Du bei uns alle bisher bekannten Toys von Lovense nutzen.',
  toycontrol_marketing_text2:
    '<span class="bold">Wie kannst Du das Toy einsetzen?</span><br />Du kannst mit diesen Toys Deine Cam-Shows noch interaktiver und heißer gestalten. Der Kunde kann das verbundene Toy in Intensität und Dauer steuern und Dir damit zusätzliche Lust und Freude bereiten. Damit bietest Du Deinen Kunden die Möglichkeit an Deinem Vergnügen aktiv teilzunehmen.',
  toycontrol_marketing_text3:
    '<span class="bold">Was hast Du als Creator davon?</span><br />Mit dem Toy-Feature bieten wir Dir eine zusätzliche und sehr umsatzstarke Einkommensquelle. Der Kunde kann zwischen 4 verschiedenen Stufen wählen. Mit steigernder Dauer / Intensität der Stufen steigt der Preis und somit Dein Verdienst. Du hast natürlich vorher die Möglichkeit, die Intensität der einzelnen Stufen einzustellen, in der Du sie spüren magst.',
  toycontrol_marketing_text4:
    '<span class="bold">Was brauchst Du um zu starten?</span><br />Du benötigst dafür ein Toy von der Firma Lovense. Um Dein Toy mit Deinem Computer zu verbinden, benötigst Du entweder die Lovense Connect App für Dein Handy oder optional den USB-Stick (separat bei Lovense erhältlich). Dann kannst Du und Deine Kunden sofort loslegen.',
  toycontrol_marketing_text5:
    'Solltest Du Unterstützung bei der Einrichtung in LiveCreator benötigen, helfen wir Dir gerne weiter.',

  TOYCONTROL_NOT_ACTIVE: `Du hast das Feature Lovense Toy nicht aktiviert. Gehe in die Optionen und setze die Einstellung auf "Ja".`,
  TOYCONTROL_NO_TOY: 'Kein Toy verwenden',
  TOYCONTROL_HAS_ACTIVATED_TOY: 'hat Dein Toy aktiviert',
  TOYCONTROL_USERS_SG: 'User',
  TOYCONTROL_USERS_PL: 'User',
  TOYCONTROL_USERS_WAITING_SG: 'wartet',
  TOYCONTROL_USERS_WAITING_PL: 'warten',
  TOYCONTROL_SECONDS: 'sek',
  TOYCONTROL_APP_OFFLINE:
    'Deine Lovense Connect App ist offline. Bitte kontrolliere die Internet Verbindung Deines Geräts und achte darauf, dass die App im Vordergrund ist ohne Energiesparmodus und kein Bildschirmschoner an ist. Um Lovense erneut zu benutzen, stoppe den Camstream und verbinde dich neu mit der Lovense Connect App.',

  TOYCONTROL_PRICE_LIST_HEADLINE: `Die Intensitäten und Preise im Detail:`,
  TOYCONTROL_PRICE_LIST: `<table class="noBorder toyPrice noHover">
         <tr>
            <td class="low">Low</td>
            <td class="align-left">10 Sekunden</td>
            <td>15 Coins</td>
         </tr>
         <tr>
            <td class="medium">Medium</td>
            <td class="align-left">10 Sekunden</td>
            <td>20 Coins</td>
         </tr>
         <tr>
            <td class="high">High</td>
            <td class="align-left">10 Sekunden</td>
            <td>25 Coins</td>
         </tr>
         <tr>
            <td class="omg">OMG</td>
            <td class="align-left">15 Sekunden</td>
            <td>40 Coins</td>
         </tr>
      </table>`,
  TOYCONTROL_TEST_BUTTON: 'Test 15 Sekunden',
  TOYCONTROL_TEST_NO_ACTIVE_TOY: 'Es ist kein Toy aktiv.',
  TOYCONTROL_VIDEO_HEADLINE_1:
    'Einrichtung mit dem Smartphone <span class="textcolor">(empfohlen)</span>',
  TOYCONTROL_VIDEO_HEADLINE_2: 'Einrichtung mit der Desktop-App',
};
