import {
  ACTIVITY_TYPE_CONTENT_PURCHASE,
  ACTIVITY_TYPE_CUSTOMER_LOGIN,
  ACTIVITY_TYPE_FRIEND_LOGIN,
  ACTIVITY_TYPE_FRIEND_REQUEST,
  ACTIVITY_TYPE_PROFILE_VISIT,
} from '../../../constants';

export default {
  [ACTIVITY_TYPE_PROFILE_VISIT]: `Our icebreaker system will try to make the user aware of you.
  As soon as the user shows interest and writes you a message, you take over the further contact.`,
  ACTIVITY_TYPE_PROFILE_VISIT_NON_ICEBREAKER: `As soon as the user shows interest and writes you a message, you take over the further contact.`,
  [ACTIVITY_TYPE_CUSTOMER_LOGIN]: `The user has already bought content from you or was in your webcam.
  Write to him directly and use the chance to inspire him about you again.`,
  [ACTIVITY_TYPE_FRIEND_LOGIN]: `You are friends with this user. Write to him directly and take the chance to get to know him.`,
  [ACTIVITY_TYPE_FRIEND_REQUEST]: `The user has sent you a friend request. A friendship develops from an active contact with each other.
  Write to him directly and take the chance to get to know him.`,
  [ACTIVITY_TYPE_CONTENT_PURCHASE]: `The user has bought content from you. Write to him directly and take the chance to get to know him.`,
};
