import './NoGalleryRow.scss';

import React from 'react';
import { useIntl } from 'react-intl';

import Button from '../../../../../../Button/Button';

function NoGalleryRow({ item, addFmotd }) {
  const intl = useIntl();
  return (
    <div className="fmotd-gallery-row no-gallery">
      <div className="preview-image">{item?.slot}</div>
      <div className="content">
        <span className="text">
          {intl.formatMessage({ id: 'FMOTD_NO_GALLERY_SET' })}
        </span>
        <Button label="FMOTD_APPLY_NOW" onClick={() => addFmotd()} />
      </div>
    </div>
  );
}

export default NoGalleryRow;
