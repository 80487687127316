const sendNotifiction = (body) => {
  try {
    const notification = new window.Notification('', { body });
    setTimeout(() => {
      if (notification instanceof window.Notification) {
        notification.close();
      }
    }, 4000);
  } catch (error) {
    console.log(`Notification not possible error ${error}`);
  }
};
const askNotification = () => {
  if (window.Notification.permission !== 'granted') {
    window.Notification.requestPermission();
  }
};
const send = async ({ title, body, icon }) => {
  const eventName = 'wss-notification';
  const event = new CustomEvent(eventName, { detail: { title, body, icon } });
  window.dispatchEvent(event);
  if ('Notification' in window) {
    if (window.Notification.permission !== 'granted') {
      await window.Notification.requestPermission();
      return sendNotifiction(body);
    }
    sendNotifiction(body);
  }
};
export default {
  askNotification,
  send,
};
