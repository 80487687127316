import { connect } from 'react-redux';

import { openDialog } from '../../../../actions';
import MyVirtualGifts from './MyVirtualGifts';

const mapDispatchToProps = (dispatch) => ({
  openDialog: (id, name) => dispatch(openDialog(id, name)),
});

export default connect(null, mapDispatchToProps)(MyVirtualGifts);

