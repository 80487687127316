import axios from 'axios';

import api from '../../utils/api';
import userUtils from '../../utils/user';
import { errorLog } from '../';
import { USER_NOTES_GET } from '../actions';

const ERROR_MESSAGE = `Notes not loaded, missing userId`;
export default function (user) {
  return async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }
    const {
      chat: { selectedUser },
    } = getState();
    let userId;
    try {
      if (user?.partner) {
        userId = user?.partner;
      } else if (userUtils.hasDatingPartner(user)) {
        userId = userUtils.getDatingPartner(user);
      } else if (userUtils.hasPartner(user)) {
        userId = userUtils.getPartner(user);
      } else {
        throw new Error(ERROR_MESSAGE);
      }

      if (!userId) {
        return;
      }
      if (selectedUser !== user?.userId) {
        return;
      }
      dispatch({
        type: USER_NOTES_GET,
        meta: { start: true },
      });
      const response = await api.get('/user/notes/' + userId);
      const responseUserId = userUtils.adaptUserId(response?.data?.user);
      if (response?.status === 204) {
        return dispatch({
          type: USER_NOTES_GET,
          payload: { userId, message: '' },
        });
      }
      dispatch({
        type: USER_NOTES_GET,
        payload: {
          userId: responseUserId,
          message: response?.data?.message,
        },
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        return;
      }
      if (error?.response?.status === 404) {
        return dispatch({
          type: USER_NOTES_GET,
          payload: { userId, message: '' },
        });
      }
      const errorObject = {
        location: 'getUserNote',
        error,
      };
      if (error.message === ERROR_MESSAGE) {
        errorObject.data = {
          userId: user?.id,
          datingCustomerId: user?.datingCustomerId,
          systemId: user?.systemId,
          clientProductId: user?.clientProductId,
          clientCustomerId: user?.clientCustomerId,
        };
      }
      dispatch(errorLog(errorObject));

      dispatch({
        type: USER_NOTES_GET,
        meta: { error: true },
        payload: { error, userId },
      });
      console.error(error);
    }
  };
}
