import { ORIGIN_EU, ORIGIN_EXCLUSIVE_PRIORITY } from '../constants';
import { roomType } from './';

const activatedOriginsCount = (sender) => activatedOrigins(sender).length;
const activatedOrigins = (sender) =>
  sender.logins.filter((login) => login.activated);

const getLoginData = (logins, roomTypes) => ({
  login: getOriginsWithRoomTypes(logins, roomTypes),
});

const getOriginsWithRoomTypes = (logins, roomTypes) => {
  return logins.reduce((activatedLogins, login) => {
    if (login.activated) {
      activatedLogins.push({
        origin: login.origin,
        roomType: roomType.getActivated(roomTypes),
      });
    }
    return activatedLogins;
  }, []);
};
const getSenderName = (logins, origin) => {
  const login = logins.find((login) => login.origin === origin);
  return login ? login.sendername : '';
};
/**
 * This functions checks, if the first element from ORIGIN_EXCLUSIVE_PRIORITY
 * is in the given logins. If so, it return this origin. If not it  checks the next,
 * and so on. If logins and ORIGIN_EXCLUSIVE_PRIORITY have no origin in common, it
 * returns null.
 *
 * @param {Object} logins
 */
const selectExclusiveOrigins = (logins) => {
  for (let i = 0; i < ORIGIN_EXCLUSIVE_PRIORITY.length; i++) {
    let origin = findSenderOrigin(logins, ORIGIN_EXCLUSIVE_PRIORITY[i]);
    if (origin) {
      return origin.origin;
    }
  }
  return null;
};
/**
 * This function activates the given origin and deactivates all other origins
 * @param {Object} logins
 * @param {string} origin
 */
const onlyActivateOrigin = (logins, origin) => {
  return logins.map((login) => {
    if (login.origin === origin) {
      login.activated = true;
    } else {
      login.activated = false;
    }
    return login;
  });
};
/**
 * If roomType exclusive is activated, the origins have to be in a state,
 * that is allowed for exclusive. Only one origin has to be activated.
 *
 * This function activates the correct origin and deactivate all other origins
 *
 * @param {object} sender - The sender state
 *
 */
const activateOriginsForExclusive = (sender) => {
  let exclusiveOrigin;
  const activeOrigins = activatedOrigins(sender);
  if (activeOrigins.length === 1) {
    //if the selected origin is allowed for exclusive nothing has to be changed
    return sender.logins;
  } else {
    //if more than one origin is selected, try to take the first from ORIGIN_EXCLUSIVE_PRIORITY
    //in our case, if more than one origin is active, then at least one is in ORIGIN_EXCLUSIVE_PRIORITY
    exclusiveOrigin = selectExclusiveOrigins(activeOrigins);
  }
  if (!exclusiveOrigin) {
    return null;
  }
  //activate only the exclusive origin in sender.logins
  return onlyActivateOrigin(sender.logins, exclusiveOrigin);
};
const toggleLogin = (sender, toggleLogin) => {
  return sender.logins.map((login) => {
    if (login.origin === toggleLogin.origin) {
      login.activated = !toggleLogin.activated;
    }
    return login;
  });
};

const findSenderOrigin = (logins, origin) =>
  logins.find((login) => login.origin === origin);
const senderOriginByUser = (logins, user) =>
  findSenderOrigin(logins, user.origin);
const createAccountLink = ({
  password,
  sendername,
  externalId,
  settings: { link },
}) =>
  `${link}?csnn=${sendername}&cshash=${password
    .toUpperCase()
    .slice(1)}&csid=${externalId}`;

const hasOrigin = (logins, origin) =>
  logins.findIndex((login) => login.origin === origin) > -1;

const hasEU = (logins) => hasOrigin(logins, ORIGIN_EU);
const hasMultipleOrigins = (logins) => logins.length > 1;
const activateAll = (logins) =>
  logins.map((login) => {
    login.activated = true;
    return login;
  });
export default {
  onlyActivateOrigin,
  activateOriginsForExclusive,
  activatedOriginsCount,
  getLoginData,
  toggleLogin,
  findSenderOrigin,
  senderOriginByUser,
  createAccountLink,
  hasEU,
  hasMultipleOrigins,
  activateAll,
  getSenderName,
};
