import { user as userUtils } from '../../utils';
import camUser from '.';
export default () => (dispatch, getState) => {
  const {
    camUserFilter: { filter, messagesFilter, activities },
    camUser: { camUsers },
    dialog: { dialogs },
  } = getState();
  const filteredList = userUtils.filter(
    filter,
    messagesFilter,
    [...userUtils.visibleUser(camUsers), ...dialogs],
    activities
  );
  let nextActiveUser =
    userUtils.getNextUser(filteredList) || filteredList.shift();

  if (!nextActiveUser) {
    return;
  }

  dispatch(camUser.select(nextActiveUser.userId));
};
