import React from 'react';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import MuteButton from '../../../Content/Livecam/Streaming/MuteButton';

const Overlay = ({
  width,
  height,
  verticalBorder,
  horizontalBorder,
  overlayRef,
  resizing,
}) => {
  const isMediumScreen = useMediaQuery(
    '(min-width: 1024px) and (max-width: 1599px)'
  );

  const overlay = {
    width,
    height,
  };
  const overlayWindow = {
    borderTopWidth: horizontalBorder,
    borderBottomWidth: horizontalBorder,
    borderLeftWidth: verticalBorder,
    borderRightWidth: verticalBorder,
    borderStyle: 'solid',
    borderColor: '#d0d0d0',
  };

  return resizing ? null : (
    <div className="cam-overlay" style={overlay} ref={overlayRef}>
      <div className="cam-overlay-window" style={overlayWindow} />
      {isMediumScreen && <MuteButton />}
    </div>
  );
};

export default Overlay;
