export default {
  diary_weblog: 'Diary entry',
  diary_automatic_entry: 'Automatic entry',
  diary_profile_image: 'Profile image',
  CREATE_NEW_DIARY_ENTRY: 'Create a new entry',
  DIARY_DATE: 'Date',
  DIARY_TYPE: 'Type',
  DIARY_TITLE: 'Title',
  DIARY_MENU_EDIT: 'Edit entry',
  DIARY_MENU_CREATE: 'Create entry',
  DIARY_MENU_PUBLISH: 'Publish',
  DIARY_MENU_UNPUBLISH: 'Unpublish',
  DIARY_MENU_DELETE: 'Delete',
  DIARY_EDIT_HEADLINE: 'Edit your entry',
  DIARY_ERROR_LOADING:
    'The diary entry could not be loaded. Please try again later.',
  DIARY_FILE_OPTIONAL: '(optional)',
  DIARY_FILE: 'Add a picture',
  DIARY_BUTTON_UNPUBLISH: 'Unpublish',
  DIARY_BUTTON_PUBLISH: 'Publish now',
  ROUTE_MY_DIARY_HEADLINE: 'Manage your diary here',
  DIARY_NEW_ENTRY: 'New Entry',
  DIARY_INFO_HEADLINE_1: '3 good reasons why it is good to keep your diary',
  DIARY_INFO_1: `
  <ol type="1">
    <li>Every new entry in your diary is displayed on the homepage and on your profile. This increases the chance of more profile visitors or new potential customers.</li>
    <li>With your diary you can give an insight into your day, announce the next camshow or promote new galleries.</li>
    <li>hrough the diary you become interesting for users who may not have found you yet and you offer your regulars an insight into your life.</li>
  </ol>
  `,
  DIARY_INFO_HEADLINE_2: "It's as simple as that:",
  DIARY_INFO_2: `
  <b>Title:</b></br>
  Here you enter the title for the new diary entry.
  `,
  DIARY_INFO_3: `
  <b>Entry:</b></br>
  Just write something from your life. This can be erotic or a greeting to your users. Advertise your newest gallery or write when you will be seen in the Livecam next time. Your regular users will certainly look up what you are doing and new customers will be aware of you.
  `,
  DIARY_INFO_4: `
  <b>Photo:</b></br>
  Here you can upload a photo or record directly with the camera to make your contribution even more interesting.
  `,
};
