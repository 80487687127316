import React from 'react';
import { Link } from 'react-router-dom';

import DefaultBannerContent from './DefaultBannerContent';

function DefaultBanner({ isConfirmed }) {
  if (!isConfirmed) return null;

  return (
    <div className="dashboard-col-wrapper banner-wrapper">
      <Link to="/multi-messenger" className="banner">
        <DefaultBannerContent />
      </Link>
    </div>
  );
}

export default DefaultBanner;

