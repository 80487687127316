import './NumberSelect.scss';
import '../RequestSelect/RequestSelect.scss';

import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import arrowDownIcon from '../../../assets/img/svg/arrow-down.svg';
import checkCircleIcon from '../../../assets/img/svg/check-circle-outline.svg';

function NumberSelect({
  id,
  name,
  options,
  label,
  defaultValue = '',
  defaultValueOption,
  subtextValue,
  disabled,
  inline,
  small,
  ...otherProps
}) {
  const selectOptions = options.map((o, key) => (
    <option key={key} value={o.value}>
      {o.label}
    </option>
  ));

  const wrapperClassName = classNames('select-wrapper', 'form-group', {
    inline,
  });

  const selectClassName = classNames('select-container', {
    disabled: disabled,
    small,
  });

  const timeoutRef = useRef(null);
  const labelClassName = classNames('select-label', {
    disabled: disabled,
  });

  const [isSaved, setIsSaved] = React.useState(false);

  const handleChange = (event) => {
    const result = otherProps.onChange(event);

    clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setIsSaved(false);
    }, 1500);
    if (result && typeof result.then === 'function') {
      result
        .then(() => {
          setIsSaved(true);
          clearTimeout(timeoutRef.current);
          timeoutRef.current = setTimeout(() => {
            setIsSaved(false);
          }, 1500);
        })
        .catch((e) => {
          console.error('Error while saving:', e);
          setIsSaved(false);
        });
    } else {
      setIsSaved(true);
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        setIsSaved(false);
      }, 1500);
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <div className={wrapperClassName}>
      {label ? (
        <label htmlFor={id || name} className={labelClassName}>
          <FormattedMessage id={label} />
        </label>
      ) : null}
      <div
        className={classNames(selectClassName, {
          'has-success': isSaved,
        })}
      >
        <select
          id={id || name}
          name={id || name}
          {...otherProps}
          className="small"
          disabled={disabled}
          onChange={handleChange}
        >
          {options.length > 0 ? (
            selectOptions
          ) : (
            <option value="" disabled>
              {defaultValueOption}
            </option>
          )}
        </select>

        {isSaved && (
          <div
            className={classNames('icon-wrapper', {
              'has-success': isSaved,
            })}
          >
            <ReactSVG src={checkCircleIcon} />
          </div>
        )}
        <div
          className={classNames('arrow-down-icon', {
            'has-success': isSaved,
          })}
        >
          <ReactSVG src={arrowDownIcon} />
        </div>
      </div>
      {subtextValue && <div className="subtext-value">{subtextValue}</div>}
    </div>
  );
}

export default NumberSelect;

