import { connect } from 'react-redux';

import { user as userUtils } from '../../../../utils';
import Time from './Time';

const mapStateToProps = (state) => {
  const user = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);

  if (!user || userUtils.isAdmin(user)) {
    return {
      noUser: true,
    };
  }

  if (
    !user?.partner &&
    userUtils.isDialogUser(user.type) &&
    !!userUtils.hasPartner(user)
  ) {
    user.partner = userUtils.getPartner(user);
  }

  const { session } =
    state.games.games.find((el) => el.partner === user.partner) || {};

  if (!session || session?.status !== 'pending') {
    return {
      noUser: true,
    };
  }

  const { turnExpires, turnExpiresAt } = session;

  return {
    display: !state.view.isMobile && !state.view.isLarge,
    turnExpires,
    turnExpiresAt,
  };
};

export default connect(mapStateToProps)(Time);
