import { MANAGEMENT_EVENTS_CONNECTION_CONNECT } from '../../../actions';
import managementActions from '../..';

export default () => {
  return (dispatch) => {
    dispatch({
      type: MANAGEMENT_EVENTS_CONNECTION_CONNECT,
    });
    dispatch(managementActions.loginCam.start());
  };
};
