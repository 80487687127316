import { ALERT_CLOSE } from '../actions';

export default (stamp) => {
  return {
    type: ALERT_CLOSE,
    payload: {
      stamp,
    },
  };
};
