import React from 'react';
import { FormattedMessage } from 'react-intl';

import RankingsDE from '../../../../../assets/img/wiki/wiki_ranking_de.jpg';
import RankingsEN from '../../../../../assets/img/wiki/wiki_ranking_en.jpg';
import BackButton from '../../../../BackButton';

function Rankings({ locale, onBackButtonClicked }) {
  return (
    <div className="wiki-content rankings-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="RANKINGS_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="RANKINGS_INTRO" />
      </p>
      <p>
        <FormattedMessage id="RANKINGS_INTRO_1" />
      </p>

      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive"
            src={RankingsEN}
            alt="My performance"
          />
        ) : (
          <img
            className="img-responsive"
            src={RankingsDE}
            alt="Meine Leistung"
          />
        )}
      </div>

      <h3 className="headline">
        <FormattedMessage id="RANKINGS_HEADLINE_1" />
      </h3>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="RANKINGS_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="RANKINGS_CONTENT_STEP_1_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="RANKINGS_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="RANKINGS_CONTENT_STEP_2_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="RANKINGS_CONTENT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="RANKINGS_CONTENT_STEP_3_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="RANKINGS_CONTENT_STEP_4" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="RANKINGS_CONTENT_STEP_4_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">5</span>
          <span className="bold">
            <FormattedMessage id="RANKINGS_CONTENT_STEP_5" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="RANKINGS_CONTENT_STEP_5_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">6</span>
          <span className="bold">
            <FormattedMessage id="RANKINGS_CONTENT_STEP_6" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="RANKINGS_CONTENT_STEP_6_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">7</span>
          <span className="bold">
            <FormattedMessage id="RANKINGS_CONTENT_STEP_7" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="RANKINGS_CONTENT_STEP_7_CONTENT" />
          </p>
        </div>
      </div>

      <p>
        <FormattedMessage id="RANKINGS_OUTRO" />
      </p>
    </div>
  );
}
export default Rankings;

