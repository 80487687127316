import { upgradetimer, user as userUtils } from '../../utils';
import { camUser as userActions } from '../';
import { UPGRADE_START } from '../actions';
import { duplicateName } from '../dialog';
export default (payload) => (dispatch, getState) => {
  upgradetimer.handleUpgradeTimer(payload, false, getState, dispatch);
  const {
    camUser: { camUsers },
    dialog: { dialogs },
  } = getState();
  const nextActiveUser =
    userUtils.activeUser([...camUsers, ...dialogs]) || payload;
  const user = camUsers.find((u) => u.userId === payload.userId) || {};
  payload.partner = userUtils.getPartner(user);

  const combinedList = [...camUsers, ...dialogs];

  const duplicateIndex = combinedList.findIndex(
    (item) =>
      item.name?.toLowerCase() === user.name?.toLowerCase() &&
      item.partner !== `${user.clientProductId}@${user.clientCustomerId}` &&
      `${item.clientProductId}@${item.clientCustomerId}` !==
        `${user.clientProductId}@${user.clientCustomerId}`
  );

  if (duplicateIndex !== -1) {
    payload.duplicateName = true;
    dispatch(duplicateName(user.name));
  }

  dispatch({
    type: UPGRADE_START,
    payload,
    meta: {
      nextActiveUser,
      dialog: dialogs,
    },
  });
  if (nextActiveUser?.userId && nextActiveUser?.userId === payload.partner) {
    dispatch(userActions.select(payload.userId));
  }
};
