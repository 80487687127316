import './Kpi.scss';

import { useIntl } from 'react-intl';

import useMediaQuery from '../../hooks/useMediaQuery';
import SpinnerPage from '../Spinner/SpinnerPage';
import PerformanceIndicator from './PerformanceIndicator/PerformanceIndicator';
import PremiumAttachmentIndicator from './PremiumAttachmentIndicator/PremiumAttachmentIndicator';

function Kpi({ loading, performanceData }) {
  const intl = useIntl();
  const position = useMediaQuery('(max-width: 768px)') ? 'right' : 'center';
  return loading ? (
    <SpinnerPage />
  ) : (
    <div className="kpi-container">
      <PerformanceIndicator
        label={intl.formatMessage({ id: 'KPI_PROFILE_IMAGE' })}
        value={performanceData?.attractivity}
        tooltip={intl.formatMessage({ id: 'KPI_PROFILE_IMAGE_TOOLTIP' })}
        position={position}
      />
      <PerformanceIndicator
        label={intl.formatMessage({ id: 'KPI_ICEBREAKER' })}
        value={performanceData?.icebreaker_answer_rate}
        tooltip={intl.formatMessage({ id: 'KPI_ICEBREAKER_TOOLTIP' })}
        position={position}
      />
      <PerformanceIndicator
        label={intl.formatMessage({ id: 'KPI_ONLINE_TIME' })}
        value={performanceData?.online_time}
        tooltip={intl.formatMessage({ id: 'KPI_ONLINE_TIME_TOOLTIP' })}
        position={position}
      />
      <PerformanceIndicator
        label={intl.formatMessage({ id: 'KPI_CHAT_QUALITY' })}
        value={performanceData?.long_chat}
        tooltip={intl.formatMessage({ id: 'KPI_CHAT_QUALITY_TOOLTIP' })}
        position={position}
      />
      <PerformanceIndicator
        label={intl.formatMessage({ id: 'KPI_RESPONSE_RATE' })}
        value={performanceData?.answer_rate}
        tooltip={intl.formatMessage({ id: 'KPI_RESPONSE_RATE_TOOLTIP' })}
        position={position}
      />
      <PerformanceIndicator
        label={intl.formatMessage({ id: 'KPI_RESPONSE_TIME' })}
        value={performanceData?.answer_time}
        tooltip={intl.formatMessage({ id: 'KPI_RESPONSE_TIME_TOOLTIP' })}
        position={position}
      />
      <PremiumAttachmentIndicator
        label={intl.formatMessage({ id: 'KPI_PREMIUM_ATTACHMENTS' })}
        value={performanceData?.payttachment}
        tooltip={intl.formatMessage({ id: 'KPI_PREMIUM_ATTACHMENTS_TOOLTIP' })}
        position={position}
      />
    </div>
  );
}

export default Kpi;
