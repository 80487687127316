import { shortenCount } from '../../../../utils';

const messageFilters = [
  {
    title: 'MENU_ITEM_ALL',
    id: 'all',
    filter: (dialog) =>
      shortenCount(
        dialog.dialogs.filter((d) => !d.flags?.includes('noRemoteDialog'))
          .length,
        999
      ),
  },
  {
    title: 'MENU_ITEM_UNREAD',
    id: 'unread',
    filter: (dialog) =>
      shortenCount(
        dialog.dialogs.filter(
          (d) => !d.read && !d.flags?.includes('noRemoteDialog')
        ).length,
        999
      ),
  },
  {
    title: 'MENU_ITEM_UNANSWERED',
    id: 'unanswered',
    filter: (dialog) =>
      shortenCount(
        dialog.dialogs.filter(
          (d) =>
            d.flags?.includes('unanswered') &&
            !d.flags?.includes('noRemoteDialog')
        ).length,
        999
      ),
  },
  {
    id: 's1',
    type: 'separator',
  },
  {
    title: 'MENU_ITEM_NEW_USER',
    id: 'new',
    filter: (dialog) =>
      shortenCount(
        dialog.dialogs.filter(
          (d) => d.newInList && !d.flags?.includes('noRemoteDialog')
        ).length,
        999
      ),
  },
  {
    title: 'MENU_ITEM_FAVORITES',
    id: 'favorite',
    filter: (dialog) =>
      shortenCount(
        dialog.dialogs.filter(
          (d) =>
            d.flags?.includes('favorite') &&
            !d.flags?.includes('noRemoteDialog')
        ).length,
        999
      ),
  },
  {
    title: 'MENU_ITEM_FRIENDS',
    id: 'friend',
    filter: (dialog) =>
      shortenCount(
        dialog.dialogs.filter(
          (d) =>
            d.flags?.includes('friend') && !d.flags?.includes('noRemoteDialog')
        ).length,
        999
      ),
  },
];

const gameFilter = [
  {
    id: 's2',
    type: 'separator',
  },
  {
    title: 'MENU_ITEM_GAMES',
    id: 'games',
    filter: (dialog) =>
      shortenCount(
        dialog.dialogs.filter(
          (d) =>
            (d.flags?.includes('game') ||
              d.flags?.includes('gameInvitation')) &&
            !d.flags?.includes('noRemoteDialog')
        ).length,
        999
      ),
  },
];
export { messageFilters, gameFilter };

