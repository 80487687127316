import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { filterRouteProps } from '../../../utils/routing';

function StateRoute({
  states = ['confirmed'],
  user = {},
  browser = {},
  ...otherProps
}) {
  if (!user.isLoggedIn) {
    if (states.includes('unauthenticated')) {
      return <Route {...filterRouteProps(otherProps)} />;
    }
    return <Redirect to="/login" />;
  }
  return states.includes(user.account.state) ? (
    <Route
      {...filterRouteProps({
        ...otherProps,
        component:
          otherProps.componentNotAllowed && !browser.isAllowed
            ? otherProps.componentNotAllowed
            : otherProps.component,
      })}
    />
  ) : (
    <Redirect to="/" />
  );
}

export default StateRoute;

