import {
  audio,
  changeRoomTypes,
  initUpgrade,
  saveUpgrade,
  sendNotification,
  sendSystemChatMessage,
  startUpgrade,
  toggleOrigin,
} from '../../../../actions';
import { SHOW_TYPE_NORMAL } from '../../../../constants';
import {
  getUnixTimestamp,
  objectUtils,
  sender as senderUtils,
  user as userUtils,
} from '../../../../utils';

export default (payload) => (dispatch, getState) => {
  const user = userUtils.get(getState().camUser.camUsers, payload.userId);
  if (!user || !user.visible) {
    return;
  }
  dispatch(initUpgrade(false)); // sets upgradeStarted to false, so other upgrades can happen

  dispatch(startUpgrade(payload));

  if (payload.showType === SHOW_TYPE_NORMAL) {
    let id = 'systemMessage.userJoined';
    dispatch(sendSystemChatMessage({ id, values: { user: user.name } }));
    if (user.translate) {
      dispatch(
        sendSystemChatMessage({
          id: 'systemMessage.userJoinedTranslate',
          userId: user.userId,
        })
      );
    }
    dispatch(sendNotification({ body: { id, values: { user: user.name } } }));
    return dispatch(audio.enterShow(user));
  }

  // from here is handling exclusive
  dispatch(
    sendSystemChatMessage(
      {
        id: 'systemMessage.startExclusive',
        highlight: true,
        userId: user.userId,
        time: getUnixTimestamp(Date.now()),
      },
      { showInDialog: true }
    )
  );

  const sender = getState().sender;

  // save state before exclusive so we can switch back
  // e.g. sender was online on 2 origins, if client leaves exclusive
  // sender will be switched back to this 2 origins
  dispatch(saveUpgrade(objectUtils.clone(sender)));

  if (senderUtils.hasMultipleOrigins(sender.logins)) {
    sender.logins.forEach((login) => {
      // turn off active origins that are not involved in this exclusive show
      if (login.origin !== user.origin && login.activated) {
        dispatch(toggleOrigin(login, false));
      }
    });
  }

  dispatch(changeRoomTypes(true, false));
};
