export default {
  ICEBREAKER_HEADLINE: `The Community Icebreaker System`,
  ICEBREAKER_INTRO: `Created to help you break the ice to the user!`,
  ICEBREAKER_HEADLINE_1: `How the Icebreaker system works`,
  ICEBREAKER_CONTENT_1: `Our Icebreaker system automatically takes the initiative for you with the help of intuitively controlled messages and helps you very quickly to numerous new contacts and dialogues with users. The different Icebreaker types are based on a smart and learning logic, which react to each other and to the behavioral patterns of the users and thus increase the users willingness to dialogue, i.e. your earning potential!`,
  ICEBREAKER_HEADLINE_2: `Special feature Performance Texts`,
  ICEBREAKER_CONTENT_2: `The intelligent control of the Icebreaker system guarantees the optimal and largest range of possible new contacts. Our so-called Performance Icebreaker Texts guarantee the best possible conversion, i.e. response rate!`,
  ICEBREAKER_CONTENT_2_1: `Each Icebreaker type has its own text pool, which contains hundreds of text variants. All texts in the Icebreaker system are unique and do not repeat themselves! The peculiarity: All texts are analyzed daily in the course of thousands of dialogues and initial contacts and evaluated to determine which of them achieve the highest response rates. These then always offer the highest chances to animate users to a dialogue and thus the best possible performance for the respective Icebreaker type.`,
  ICEBREAKER_CONTENT_2_2: `The intelligent Icebreaker system and the Performance Icebreaker Texts provide you the maximum number of new users! 100% automatic and absolutely free!`,
  ICEBREAKER_HEADLINE_3: `Basics`,
  ICEBREAKER_CONTENT_3: `The Icebreaker system is not a bot! It's not about teasing the user with automatic messages! The system should make the very time-consuming first contact as easy as possible for you. It also ensures that you can react promptly to user actions and never miss a chance to address an interested user.`,
  ICEBREAKER_CONTENT_3_1: `As long as the Icebreaker system is activated and you are online, the system reacts to the actions of active users with whom you have usually never written before or who have never replied to you.`,
  ICEBREAKER_CONTENT_3_2: `So if such a user comes online for the first time, sends you a kiss or looks at your profile, this is noticed by the system and a suitable initial message is sent in your name. The Icebreaker system itself ensures that the type and order of the Icebreaker texts make sense. So you don't have to worry about anything in this regard!`,
  ICEBREAKER_CONTENT_3_3: `Please note that the system does not always send a message!`,
  ICEBREAKER_CONTENT_3_4: `Each icebreaker type is subject to certain and different conditions and probabilities, whether and when these are triggered and send a suitable message or initiate a dialogue. In addition, it also plays a role how good {link} is, how many messages the user has already received from the system in the last few minutes, how many messages have just been sent for you, how many of your open dialogues are still open waiting for an answer from you and much more. `,
  ICEBREAKER_CONTENT_3_5: `Only when a user replies to one of these messages, i.e. the ice has been successfully broken, does the Icebreaker system pass the dialogue on to you!<br />
So you can see that this Icebreaker system does a lot of work for you and enables you to spend your valuable time with users who are genuinely interested in chatting with you! Before you answer, you should read at the beginning of the conversation which Icebreaker text was sent on your behalf and seamlessly tie in with it!`,
  ICEBREAKER_CONTENT_3_LINK: `your current ranking`,
  ICEBREAKER_HEADLINE_4: `How do I recognize Icebreaker?`,
  ICEBREAKER_CONTENT_4: `If you receive a message from a user and see that he did not write initially, but that a contact has already been made in your name to which the user has replied, then it is a successful contact transfer from the Icebreaker system to you! The best thing to do is to familiarize yourself with the different types of Icebreakers so that you can get to the start of the conversation quickly and correctly! Under an icebreaker you will also find an indication that it is a message sent by the system:`,
  ICEBREAKER_CONTENT_4_1: `As in the example above, you will also see so-called Sexicons. Sexicons are small pictures with a suitable text that visualize interest and should make the user curious. Here are some examples:`,
  ICEBREAKER_HEADLINE_5: `Inactivity / Pause Icebreaker sending`,
  ICEBREAKER_CONTENT_5: `Users want to receive a prompt reply from you. If they don't get them, they lose interest and you lose potential contact.`,
  ICEBREAKER_CONTENT_5_1: `We'll help you prevent it from happening! If the Icebreaker system notices that you are inactive for a while, i.e. that you are not sending any messages by hand, or if you have too many unanswered messages, the system pauses automatically and does not send any further Icebreaker texts for you. You will be informed about this by the following message window:`,
  ICEBREAKER_CONTENT_5_2: `You then have the opportunity to be active again as soon as you can devote yourself to the chat again.`,
  ICEBREAKER_HEADLINE_6: `Add pictures and videos - increase your chances`,
  ICEBREAKER_CONTENT_6: `In order to achieve the best possible performance, we recommend that you add an individual picture or a short video for each type of Icebreaker. This significantly increases the high response rate of the Performance Icebreaker Texts!`,
  ICEBREAKER_CONTENT_6_1: `And it's that easy:`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_HEADLINE_1: `Upload file attachment`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_CONTENT_1: ` You can also find {link} in a Wiki entry.`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_CONTENT_LINK: `detailed information about file attachments`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_HEADLINE_2: `Assign an attachment to an icebreaker type`,
  ICEBREAKER_CONTENT_6_1_HOW_TO_CONTENT_2: `You can only use pictures or videos, that have not yet been assigned to any other icebreaker.`,
  ICEBREAKER_HEADLINE_7: `Current Icebreaker types`,
  ICEBREAKER_CONTENT_7: `We are continuously working on creating new Icebreaker types, as well as expanding and optimizing the pools for the existing Performance Icebreaker Texts. Make sure to familiarize yourself with the different types of Icebreaker so that you can quickly and appropriately connect to the conversation started by the Icebreaker system.`,
  ICEBREAKER_HEADLINE_8: `So do I never need to actively write to users again?`,
  ICEBREAKER_CONTENT_8: `Yes, of course you can and should continue to do that! The Icebreaker system is designed to make your work as easy as possible for you when you first come into contact, but it can never hurt to be <span class="bold">specifically active</span> yourself. Don't forget that the system does not always send a message.`,
  ICEBREAKER_CONTENT_8_1: `So if you notice that a user has visited your profile or sent you a kiss, you can play it safe and react to it yourself!<br />
The Icebreaker system notices this and at that moment no longer sends a corresponding Icebreaker to this user for you. You just have to make sure in the chat that an Icebreaker has not already been sent for you. If that has already happened, but the user has not responded, you can of course still write something suitable - for example “Don't feel like answering?”`,
  ICEBREAKER_CONTENT_8_2: `<span class="bold">IMPORTANT:</span> The Icebreaker system does not send messages to users who have already replied to you, i.e. with whom there is or was a dialogue! You have to reactivate a dialogue that has "fallen asleep" yourself.`,
  ICEBREAKER_CONTENT_8_3: `<span class="bold blue">We wish you every success and good sales with the Icebreaker system!</span> <br />
Feel free to contact support if you have any further questions or suggestions.`,
};
