import './UserProfileIndicator.scss';

import classNames from 'classnames';
import { ReactSVG } from 'react-svg';

function UserProfileIndicatorItem({
  title,
  icon,
  isExpanded,
  active,
  className,
}) {
  const iconClassName = classNames('icon', {
    [className]: className,
  });
  const featureClassName = classNames('feature', {
    active,
  });
  return (
    <div className={featureClassName}>
      <ReactSVG
        src={icon}
        wrapper="span"
        className={iconClassName}
        title={title}
      />
      {isExpanded ? <span>{title}</span> : null}
    </div>
  );
}

export default UserProfileIndicatorItem;
