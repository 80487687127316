export default {
  VISIBILITY_HEADLINE: `Sichtbarkeit und Präsenz – Mach auf Dich aufmerksam!`,
  VISIBILITY_INTRO: `In diesem Artikel befassen wir uns mit der Sichtbarkeit und Präsenz Deines Profils in den Community Produkten. Diese wird maßgeblich durch Deine eigene Aktivität beeinflusst und ist Grundvoraussetzung dafür, dass möglichst viele User auf Dich aufmerksam werden und den Weg zu Dir finden.`,
  VISIBILITY_INTRO_1: `Ein entscheidender Aspekt ist die Online-Präsenz und das Überblicken und Bearbeiten der eintreffenden Nachrichten. In der Regel erhalten User im Rahmen des {link} als Initialkontakt automatisiert eine Nachricht von Dir. Dabei ist das Erste, was ihnen neben dem Animations-Text auffällt, entweder ein Bild, welches mit dem Icebreaker kombiniert ist, oder sie werfen einen Blick auf Dein Profil.`,
  VISIBILITY_INTRO_1_LINK: `Icebreakersystems`,
  VISIBILITY_INTRO_2: `Wenn Du also nur kurz jeden Tag online bist, sinkt die Wahrscheinlichkeit, dass ein User Dir antwortet, da er nach aktiver Kommunikation sucht. Dennoch ist Onlinepräsenz nicht der einzige Weg.`,
  VISIBILITY_INTRO_3: `Es gibt viele weitere Möglichkeiten, die Dir helfen können, fortwährend Aufmerksamkeit bei neuen und bekannten Usern zu erzeugen. Im Folgenden erläutern wir detailliert, wie sich Dein Profil durch eine gute Gestaltung, Deinem persönlichen Tagebuch und regelmäßige Galerie Updates, an stabilen und stetig steigenden Profilbesuchen erfreut.`,

  VISIBILITY_HEADLINE_1: `Aufmerksamkeit erreichst Du durch:`,
  VISIBILITY_CONTENT_STEP_1: `Gestaltung Deines Profils`,
  VISIBILITY_CONTENT_STEP_1_CONTENT: `{link} bildet den ersten Eindruck, den ein Kunde von Dir gewinnt. Ein veraltetes oder unscharfes Profilfoto kann potenzielle Kunden schnell abschrecken. Zeige Deine Aktivität, indem Du Dein Bild regelmäßig aktualisierst. Experimentiere mit verschiedenen Outfits oder passe Deine Bilder an die Feiertage/Jahreszeiten an. Betone Deinen Stil und Deine Persönlichkeit. Diese Anpassungen halten Dein Profil frisch und zeigen, dass Du Dir Mühe gibst, es kontinuierlich zu pflegen und zu verbessern.`,
  VISIBILITY_CONTENT_STEP_1_LINK: `Dein Profil`,
  VISIBILITY_CONTENT_STEP_1_1_CONTENT: `<span class="bold">Tipp:</span> Setze unbedingt`,
  VISIBILITY_CONTENT_STEP_1_2_CONTENT: ` {link} in Deinen Einstellungen ein und beschreibe Dich mit einigen Eigenschaften. User, die nach diesen Eigenschaften suchen, erhalten Dein Profil als Vorschlag, wenn sie übereinstimmen.`,
  VISIBILITY_CONTENT_STEP_1_1_LINK: `Creator Tags`,
  VISIBILITY_CONTENT_STEP_2: `Tagebuch`,
  VISIBILITY_CONTENT_STEP_2_CONTENT: `Eine weitere Möglichkeit, um Deine Sichtbarkeit zu erhöhen, ist das Führen eines {link}. Durch regelmäßige Einträge bekommen Deine Stammkunden Einblicke in Deinen Alltag, Deine Gedanken und Ankündigungen zu bevorstehenden Shows oder neuen Galerien. Wenn Du zu Deinem Beitrag noch ein ansprechendes Bild hinzufügst, wird er sofort ins Auge springen. Die Beiträge werden auf der Startseite angezeigt und steigern somit die Chancen entdeckt zu werden. Ein persönlicher Touch kann zudem die Bindung zwischen Dir und Deinen Kunden vertiefen.`,
  VISIBILITY_CONTENT_STEP_2_LINK: `Tagebuchs`,
  VISIBILITY_CONTENT_STEP_3: `Foto- und Video Galerien`,
  VISIBILITY_CONTENT_STEP_3_CONTENT: `Kreativität zahlt sich auch im Bereich {link} aus. Regelmäßig neue Galerien bieten einen zusätzlichen Anreiz für Kunden, Dein Profil erneut zu besuchen. Hier kannst Du Deine Vielfalt unter Beweis stellen oder Kunden nach ihren Wünschen fragen, um stets neue Galerien zu erstellen. Es ist besonders effektiv, feste Veröffentlichungstage festzulegen, damit Kunden darauf aufmerksam werden und regelmäßig Dein Profil besuchen. Mit der Funktion “mit Zeitplan veröffentlichen” können Galerien sogar für die kommenden Wochen geplant werden. Das vereinfacht die Organisation, da Du nicht jedes Mal an die nächste Veröffentlichung denken musst. Alle Galerien werden automatisch auf der Startseite angezeigt, was Deine Sichtbarkeit erhöht und neue User anspricht.`,
  VISIBILITY_CONTENT_STEP_3_LINK: `Foto- und Videogalerien`,
  VISIBILITY_CONTENT_STEP_3_1_CONTENT: `<span class="bold">Tipp:</span> Nutze die Kategorien. Je mehr Kategorien Du auswählst (bis zu 5), desto besser können Deine Fotos und Videos gefunden werden. Viele User nutzen gerne die Suchfunktion, um Fotos oder Videos zu entdecken, die ihrem Geschmack entsprechen. Wenn Du die Kategorien angibst, steigerst Du die Chance, dass Deine Foto- und Videogalerien gesehen und gekauft werden.`,

  VISIBILITY_OUTRO: `Denke daran: Jede Aktivität auf Deinem Profil bietet Dir die Möglichkeit aufzufallen. Ein interaktives Profil mit regelmäßigen Updates weckt Interesse und kann somit Deinen Umsatz steigern.`,
  VISIBILITY_OUTRO_1: `Die Devise lautet klar: Sei präsent, sei authentisch und sei kreativ. Diese Elemente sind Dein Kapital und der Schlüssel, um sich von der Masse abzuheben. Wenn Du diesen Ratschlägen folgst, kannst Du nicht nur Deine Sichtbarkeit erhöhen, sondern auch eine engere Bindung zu Deinen Kunden aufbauen und neue Kunden gewinnen.`,
};
