import { ABORT_ERROR_NAME } from '../constants';
class AbortError extends Error {
  constructor(message, number, stamp) {
    super(message);
    this.name = ABORT_ERROR_NAME;
    this.abortnumber = number;
    this.errorStamp = stamp;
  }
}

export default AbortError;
