export default {
  ROUTE_MY_PROFILE_HEADLINE: `Change your profile pictures and manage all your public details here`,
  PROFILE_PICTURES_UPLOADER: `Upload profile pictures`,
  PROFILE_PICTURES_UPLOAD_TEXT_1: `To upload a picture, drag and drop it into the white area or click the + to manually select a file.`,
  PROFILE_PICTURES_UPLOAD_TEXT_2: `Images in jpg or png format are allowed and must have a resolution of `,
  PROFILE_PICTURES_UPLOAD_TEXT_3: `<span class="bold">at least 1080 x 1080 pixels</span>.`,
  PROFILE_PICTURES_COMMENT_UNDER_REVIEW: `Under review by Support.`,
  PROFILE_PICTURES_DEFAULT_HEADLINE: `Your main profile picture`,
  PROFILE_PICTURES_DEFAULT_TEXT_1: `This is your main profile picture, which is displayed in the lists of our customer portals. Change the picture by selecting a different one from your profile picture gallery.`,
  PROFILE_PICTURES_DEFAULT_TEXT_2: `You can find more information about the perfect profile picture {link}`,
  PROFILE_PICTURES_DEFAULT_TEXT_LINK: `in the wiki`,
  PROFILE_PICTURES_GALLERY: `Profile picture gallery`,
  PROFILE_PICTURES_PROGRESS: `Profile pictures progress`,
  PROFILE_PICTURES_VIDEO_HEADLINE: `Profile picture explanation`,
  PROFILE_PICTURE_POSING_HEADLINE: `Show yourself from your best side`,
  PROFILE_PICTURE_POSING: `Be creative and let yourself be inspired by our example images. Choose a motif that makes you feel comfortable and how you like to be seen.`,
  PROFILE_PICTURE_POSING_NOFACE_HEADLINE: `Don't want to show your face?`,
  PROFILE_PICTURE_NOFACE_POSING: `No problem! There are many attractive alternatives for an attractive profile picture: For example, you can wear a mask, sunglasses, or a large hat to hide your face. Show yourself from behind and pose without a recognizable face. A well-placed section of a great picture of you also works wonderfully without showing your face.`,
  PROFILE_PICTURES_INFO_TEXT: `<span class="bold">Important:</span><br />Images from the Internet are forbidden! No strangers on the profile pictures.`,

  PROFILE_VIDEO_HEADLINE_1: `Upload profile video`,
  PROFILE_VIDEO_TEXT_1: `In addition to your profile pictures, you can also upload an introduction video for your profile.`,
  PROFILE_VIDEO_TEXT_2: `To upload a video, you can simply drag and drop it into the white area with a blue border. Or you click the + to select a file from your computer or smartphone. We accept these formats: wmv, avi oder mpg4.`,
  PROFILE_VIDEO_INFO_HEADLINE: `Tips for your video`,
  PROFILE_VIDEO_INFO_TEXT_1: `With your personal profile video you can present yourself in a short film to arouse the interest of the users.`,
  PROFILE_VIDEO_INFO_TEXT_2: `The video will also be shown in your Livecam profile.`,
  PROFILE_VIDEO_INFO_TEXT_3: `<span class='bold'>Please note:</span>
    <ul>
        <li>Hardcore content is forbidden!</li>
        <li>Keep it short, roughly 1 minute is fine</li>
        <li>Are lighting and sound ok? Put yourself in the limelight</li>
    </ul>`,
  PROFILE_VIDEO_DELETE_LABEL: `Delete current video`,
  PROFILE_VIDEO_UPLOAD_HEADLINE: `Upload`,

  PROFILE_PICTURE_ERROR_SETTING_DEFAULT: `This photo cannot be set as default`,
  PROFILE_PICTURE_NOT_DELETED_ERROR: `This photo was not deleted due to error`,
  PROFILE_PICTURE_CAN_NOT_BE_DELETED_LAST_FSK: `You cannot delete this photo. There must be at least one casual or soft profile picture.`,
  PROFILE_PICTURES_QUEUE_CLEANUP_ERROR: `Deleting broken photos has failed`,
  PROFILE_PICTURE_NOT_FOUND: `Profile photo cannot be found`,

  PROFILE_TEXT_HEADLINE_1: `Arouse the curiosity of community users`,
  PROFILE_TEXT_LABEL_ABOUT_MYSELF: `What I can say about myself:`,
  PROFILE_TEXT_LABEL_ABOUT_ME: `What is said about me:`,
  PROFILE_TEXT_LABEL_EROTIC_FANTASY: `My erotic fantasy:`,
  PROFILE_TEXT_INFO_HEADLINE: `Tips for profile text`,
  PROFILE_TEXT_INFO_TEXT_1: `Your profile description should primarily arouse the interest of the users and encourage them to send you a message. You should describe to the user what you are looking for, why you are registered with our communities, what your preferences are or how others rate you. The more precisely you describe yourself, the better can the user respond to you. But try to be non-commital, this will make you more mysterious and keep the user interested.`,

  PUBLIC_DETAILS_HEADLINE_1: `I come from`,
  PUBLIC_DETAILS_TEXT_1: `Here, you can decide which data you want to show on your public profile. This part is for your public profile only and does not change your account data and address you have provided under "My personal details". Please note that we still have to have your correct data for age verification and payouts. If you change your public data, please be aware that it might take up to 2 hours until it is synchronized all over the communities.`,
  PUBLIC_DETAILS_FIELD_ZIP_AREA: `Zip Area:`,

  PUBLIC_DETAILS_HEADLINE_2: `International settings`,
  PUBLIC_DETAILS_FIELD_NATIVE_LANGUAGE: `My native language:`,
  PUBLIC_DETAILS_HEADLINE_3: `Personal details`,
  PUBLIC_DETAILS_FIELD_AGE: `Age:`,
  PUBLIC_DETAILS_FIELD_HEIGHT: `Height:`,
  PUBLIC_DETAILS_FIELD_WEIGHT: `Weight:`,
  PUBLIC_DETAILS_FIELD_TYPE: `Type:`,
  PUBLIC_DETAILS_FIELD_MARITAL_STATUS: `Marital status:`,
  PUBLIC_DETAILS_FIELD_TATTOOS: `Tattoos:`,
  PUBLIC_DETAILS_FIELD_PIERCINGS: `Piercings:`,
  PUBLIC_DETAILS_FIELD_GLASSES: `Glasses:`,
  PUBLIC_DETAILS_FIELD_EYE_COLOR: `Eye color:`,
  PUBLIC_DETAILS_FIELD_HAIR_COLOR: `Hair color:`,
  PUBLIC_DETAILS_FIELD_HAIR_LENGTH: `Hair length:`,
  PUBLIC_DETAILS_FIELD_FIGURE: `Figure:`,
  PUBLIC_DETAILS_FIELD_BODY_HAIR: `Body hair:`,
  PUBLIC_DETAILS_FIELD_PUBIC_HAIR: `Pubic hair:`,
  PUBLIC_DETAILS_FIELD_CUP_SIZE: `Cup size:`,
  PUBLIC_DETAILS_FIELD_SMOKING: `Smoking:`,
  PUBLIC_DETAILS_HEADLINE_4: `My preferences`,
  PUBLIC_DETAILS_LOOKING_FOR_LABEL: `What I'm looking for, is`,
  PUBLIC_DETAILS_EXPERIENCE_LABEL: `What I'd like to experience, is`,
  PUBLIC_DETAILS_PREFERENCE_LABEL: `What turns me on, is`,
  PUBLIC_DETAILS_INFO_HEADLINE: `Info about your public details`,
  PUBLIC_DETAILS_INFO_TEXT_1: `All information you provide here appears on your profile, visible to all community users.`,
  PUBLIC_DETAILS_INFO_TEXT_2: `You don't have to reveal all of the information about yourself. But remember: the more you share, the better users can find you and the more attractive you can stand out within the communities!`,

  MY_PUBLIC_DETAILS_HEADLINE_1: `Preview AmateurX`,
  MY_PUBLIC_DETAILS_HEADLINE_2: `Preview FunCommunity`,
  MY_PUBLIC_DETAILS_HEADLINE_3: `Preview AmateurFans`,
  LABEL_MY_PROFILE_IN_THIS_VERSION: `My profile in this version`,
  MY_PUBLIC_DETAILS_TEXT_1: `Here we have a bright portal that joins the world of social media.`,
  MY_PUBLIC_DETAILS_TEXT_2: `FunCommunity is a darker style casual dating portal.`,
  MY_PUBLIC_DETAILS_TEXT_3: `AmateurFans is one of our most recent products in DarkMode style.`,
  MY_PUBLIC_DETAILS_INFO_HEADLINE: `Why are there different versions of my profile?`,
  MY_PUBLIC_DETAILS_INFO_TEXT_1: `With LiveCreator you have the advantage of going live on 20+ community portals at the same time. All you need is your creator account and a meaningful profile description with a profile picture. It makes sense to add more content and regular updates to your profile.
  </br>
  </br>
  Of course, the community portals do not all look the same. There are major differences in appearance here, both in terms of content and topic, as well as color and structure. With regard to the different designs, you do not have to be active yourself. Livecreator always embeds your profile content appropriately in the respective community portals. This is how we always provide the best version of your profile.
  </br>
  </br>
  You can use our three partner portals <a href="https://www.AmateurX.com" target="blank">www.AmateurX.com</a>, <a href="https://www.FunCommunity.com" target="blank">www.FunCommunity.com</a> and <a href="https://app.AmateurFans.com" target="blank">www.AmateurFans.com</a> to get an example of what this can look like.`,
};

