import { connect } from 'react-redux';

import { setActiveToy } from '../../../../actions';
import ToycontrolMenu from './ToycontrolMenu';

const mapStateToProps = (state) => ({
  ...(state.sender?.toycontrol || {}),
});

const mapDispatchToProps = {
  setActiveToy,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToycontrolMenu);
