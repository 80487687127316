import activities from './activities';
import alerts from './alerts';
import countryNames from './countryNames';
import settings from './settings';

export default {
  ...countryNames,
  ...alerts,
  ...settings,
  ...activities,

  language: 'Language',
  error: 'Error',
  accept: 'Accept',
  cancel: 'Cancel',
  'streamingButton.start': 'Start streaming',
  'streamingButton.startEvent': 'Start event',
  'streamingButton.stop': 'Stop streaming',
  'streamingButton.stopEvent': 'Stop event',
  'streamingButton.isLoading': 'Loading ...',
  mottoHeadline: 'Add a motto for your Cam show',
  mottoLabel: 'My motto today:',
  mottoPlaceholder: 'No motto',
  'login.password': 'Password',
  'login.callSupport': 'You cannot login. Please call our support.',
  'login.noUsername': 'Unknown username',
  'login.wrongPassword': 'The password is wrong',
  'login.reload':
    'The software version is not up to date. Page will be reloaded.',
  'login.cookieHint':
    'This software requires cookies. Please activate all kind of cookies in your browser.',
  'login.cookie3rdHint':
    'Using livecam streaming requests all kind of cookies to be allowed including third party cookies.',
  'login.livecamErrorHint':
    'The livecam is currently not available. Please retry later.',
  'chat.translated': 'translated',
  'chat.kiss': 'I kiss you',
  'chat.game.invitation': 'Play with me',
  'chat.game.invitationTimeout': 'The invitation has timed out',
  'chat.game.invitationDeclined': 'You declined the invitation',
  'chat.game.draw': 'The game ended in a draw',
  'chat.game.winByTimeout': 'You won the game due to a timeout',
  'chat.game.lostByTimeout': 'You lost the game due to a timeout',
  'chat.game.winByTurn': 'You won the game',
  'chat.game.lostByTurn': 'You lost the game',
  'chat.game.invitation.single': 'Play with me',
  'chat.game.invitationTimeout.single':
    'The invitation from {user} has timed out',
  'chat.game.invitationDeclined.single':
    'You declined the invitation from {user}',
  'chat.game.draw.single': 'The game against {user} ended in a draw',
  'chat.game.winByTimeout.single':
    'You won the game against {user} due to a timeout',
  'chat.game.lostByTimeout.single':
    'You lost the game against {user} due to a timeout',
  'chat.game.winByTurn.single': 'You won the game against {user}',
  'chat.game.lostByTurn.single': 'You lost the game against {user}',
  'chat.match': 'We have a match',
  'chat.regard': 'You received a virtual gift worth ',
  'chat.regardCoins': '{coins} Coins',
  'chat.icebreaker': 'Icebreaker',
  'chat.datingAnimation': 'Dating animation',
  'chat.payttachment':
    'You sent a premium attachment worth <span class="price">{price} Coins</span>',
  'chat.payttachment.loading': 'You sent a premium attachment',

  'logout.title': 'Logout',
  'account.title': 'Account Administration',
  'account.system': 'System',

  'title.voyeur': 'Voyeurs:',
  'title.livecam': 'Livecam',
  'title.messenger': 'Messenger',
  'voyeur.tooltip': 'You are sending in voyeur mode',
  'tip.text': 'Tips:',
  'tip.textusercount': 'User:',
  'systemMessage.loggedIn':
    'You are connected to the livecam server. Please be patient while waiting for a user.',
  'systemMessage.loggedOut':
    'The connection to the livecam server was terminated. Please hit start button to get a new connection.',
  'systemMessage.userJoined': 'User {user} joined your show',
  'systemMessage.userJoinedTranslate':
    'This user does not speak your language. The AC Translator automatically translates your messages.',
  'systemMessage.userLeft': 'User {user} left your show',
  'systemMessage.exclusiveError':
    'Attention! An error occurred while switching to Exclusive mode.',
  'systemMessage.welcome':
    'Please click "Start streaming" to get a connection to the livecam server.',
  'systemMessage.startExclusive': 'The Exclusive Show has started',
  'systemMessage.exclusiveDenied':
    '{user} has declined to enter an Exclusive show.',
  'systemMessage.tip': '{user} has given you a tip of {amount} coins.',
  'systemMessage.kickUser': 'User was kicked by an admin',
  'control.send': 'send',
  'control.sendTooltip': 'Send message to currently selected user',
  'control.2all': '2all',
  'control.2allTooltip': 'Send message to all users',
  'control.smiley': 'smiley',

  'streamingInfo.resolution': 'Resolution:',
  'streamingInfo.upload': 'Upload:',
  'streamingInfo.upload.value': '{bandwidth} kbit/s',
  'streamingInfo.fps': 'Frames/s:',
  'streamingInfo.fpsHint':
    'The performance of your computer and / or your internet connection is insufficient. Please close all unused software and check the Stream Settings. If this does not help, please contact our support.',

  'userlist.exclusive': 'Exclusive',
  'userlist.info': 'User Info',
  'userlist.mic': 'User Mic',
  'userlist.micOff': 'User Mic Off',
  'userlist.note': 'User Note',
  'userlist.ban': 'User Ban',
  'user.origin': 'User comes from {country}',
  'user.exclusiveShowTooltip':
    'An Exclusive show costs the user {price} coins per minute.',

  'usernote.errorPutNotes': 'The notes could not be saved.',
  'usernote.errorGetNotes': 'Error while loading the notes.',
  'btn.title.kickUser': 'Kick out of my cam',
  'btn.title.ignoreUser': 'Ignore user',
  'btn.title.userSoundOn': 'User-Audio on',
  'btn.title.userSoundOff': 'User-Audio off',
  'btn.title.askExclusive': 'Invite {username} to an Exclusive show',
  'btn.title.askExclusiveBlank': 'Invite for an Exclusive show',
  'btn.user.info': 'Info',
  'btn.user.notes': 'Notes',
  'btn.user.connect4': '4score',
  'btn.title.chatMulti': 'Use one chat for all users',
  'btn.title.chatSingle': 'Use bubble chat',
  'btn.title.sortAsc': 'Sorting from old to new',
  'btn.title.sortDesc': 'Sorting from new to old',
  'btn.title.allTime': 'All Time',

  'controlPanel.exclusiveTooltip':
    'This button will activate the Exclusive mode. ATTENTION: In Exclusive mode your livecam will no longer be shown on our associated sites. All active shows will be terminated!',
  reset: 'Reset',

  'search.for.nickname': 'Search for nickname',
  'chat.input.placeholder': 'Write a message (max. 2000 characters)',
  'chat.input.charLimit':
    'Sorry, you have reached the limit of 2000 characters!',
  'no.user': 'No user available',
  'user.isSupport': 'This user is an official administrator. Be gentle :)',

  'user.age': 'Age:',
  'user.age.years': 'years',
  'user.gender': 'Gender:',
  'user.country': 'Origin:',
  'user.language': 'Language:',
  'user.is.typing': 'is typing...',

  'feature.headline':
    'The following chargeable features are available to this user:',
  'feature.exclusive': 'Visit your exclusive cam show',
  'feature.toycontrol': 'Using your Toycontrol toys',
  'feature.game': 'Play community games together with you',
  'feature.galleries': 'Purchase your pictures and videos',
  'feature.voiceMessage': 'Receiving and sending voice messages',
  'feature.attachments': 'Receiving and sending file attachments',
  'feature.payttachments': 'Receiving premium attachments',
  'feature.virtualGifts': 'Sending virtual gifts',
  'feature.exclusive.unavailable': 'Exclusive cam show is not available',
  'feature.toycontrol.unavailable': 'Toycontrol toys are not available',
  'feature.game.unavailable': 'Community games are not available',
  'feature.galleries.unavailable':
    'Purchase your pictures and videos is not available',
  'feature.voiceMessage.unavailable': 'Voice messages are not available',
  'feature.attachments.unavailable':
    'Receiving and sending file attachments is not available',
  'feature.payttachments.unavailable': 'Premium attachments are not available',
  'feature.virtualGifts.unavailable': 'Virtual Gifts are not available',

  'user.turnover': 'User turnover',
  'turnover.cam': 'Livecam',
  'turnover.cam.tip': 'Livecam tip',
  'turnover.chat': 'Private message',
  'turnover.premium.attachments': 'Premium attachment',
  'turnover.regards': 'Virtual gift',
  'turnover.sexicons': 'Sexicon',
  'turnover.video': 'Video',
  'turnover.photo': 'Photo',
  'turnover.games': 'Community Game',
  'turnover.voice.messages': 'Voice Message',
  'not.specified': 'not specified',

  'user.personal': `My Personal Info`,
  'user.height': `Height:`,
  'user.weight': `Weight:`,
  'user.figure': `Figure:`,
  'user.eyeColor': `Eye color:`,
  'user.hairColor': `Hair color:`,
  'user.hairLength': `Hair length:`,
  'user.bodyHair': `Body hair:`,
  'user.pubicHair': `Pubic hair:`,
  'user.penisLength': `Length erect penis:`,
  'user.penisGirth': `Girth erect penis:`,
  'user.piercings': `Piercings:`,
  'user.ethnicity': `Ethnicity:`,
  'user.maritalStatus': `Marital status:`,
  'user.tatoos': `Tattoos:`,
  'user.glasses': `Glasses:`,
  'user.smoking': `Smoking:`,

  'user.preferences': `My preferences`,
  'user.turnsOn': `What turns me on, is`,
  'user.experience': `What I would like to experience, is`,
  'user.lookingFor': `What I'm looking for, is`,

  'user.moreInfo': `More about me`,
  'user.eroticFantasy': `My erotic fantasy`,
  'user.iSay': `What I can tell about myself`,
  'user.otherSay': `What's being said about me`,

  male: 'male',
  female: 'female',
  'no.result': 'No results for "{camUserFilter}"',
  'new.user': 'New',

  'tooltip.normalShow': 'Indicates, that a user can enter your normal show',
  'tooltip.voyeurShow':
    'Indicates, that a user can enter your show as a voyeur',
  'tooltip.exclusiveShow':
    'Indicates, that a user has an exclusive show with you',

  I: 'Me',
  me: 'Me',
  'btn.title.showMyCam': 'Show my cam',
  'btn.title.showCam2cam': 'Show cam of {username}',

  'label.noUserSelected': 'No user is selected',

  showMore: 'More',
  back: 'Back',

  userStateIgnored: 'Looks like you were blocked by {nickname}. ',
  userIgnored: 'Looks like you have ignored {nickname}. ',
  userUnignore: 'Do you want to unignore or delete this dialogue?',
  userStateDeleted: 'Looks like {nickname} was deleted. ',
  deleteDialog: 'Do you want to delete this dialogue?',
  deleteDialogButton: 'Yes, please',

  messageNotSend: 'The message could not be sent. ',
  sendAgain: 'Try again?',
};
