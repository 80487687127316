const updateItem = (array, itemId, updateItemCallback) =>
  array.map((item) => {
    if (item.id !== itemId) {
      return item;
    }

    const updatedItem = updateItemCallback(item);
    return updatedItem;
  });

const findLowestCount = (arr) => {
  const lowestValue = arr.reduce(
    (prev, curr) => (prev.count < curr.count ? prev : curr),
    { count: Infinity }
  );
  const lowestValueArray = arr.filter((obj) => obj.count === lowestValue.count);
  return lowestValueArray[Math.floor(Math.random() * lowestValueArray.length)];
};

export default {
  updateItem,
  findLowestCount,
};
