import './Control.scss';

import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import send2allIcon from '../../../../assets/img/svg/2all.svg';
import leftIcon from '../../../../assets/img/svg/chevron-single-left.svg';
import rightIcon from '../../../../assets/img/svg/chevron-single-right.svg';
import close from '../../../../assets/img/svg/close.svg';
import smileyIcon from '../../../../assets/img/svg/emoji.svg';
import menuIcon from '../../../../assets/img/svg/menu-dots.svg';
import mic from '../../../../assets/img/svg/mic.svg';
import sendIcon from '../../../../assets/img/svg/send.svg';
import stopIcon from '../../../../assets/img/svg/stop.svg';
import userUtils from '../../../../utils/user.js';
import Button from '../../../Button/Button';
import AttachmentItem from '../../../ThumbnailItem/AttachmentItem/AttachmentItem';
import RunToy from '../../RunToy';
import UserGame from '../../UserGame';
import ChatMenu from '../Menu';
import ChatSmileyOverlay from '../Smiley/SmileyOverlay';
import VoiceRecorderControl from './VoiceControl';

class ChatControl extends React.Component {
  constructor(props) {
    super(props);
    this.text = '';
    this.timer = null;
    this.chatInput = React.createRef();
    this.initialRecordingState = {
      recording: false,
      recordingClicked: false,
      sendVoiceClickedState: null,
      recordingStarted: false,
    };

    this.state = {
      ...this.initialRecordingState,
      charLimitReached: false,
    };

    this.toastTimeout = null;
  }
  clearRecordingState = () => {
    this.setState({ ...this.initialRecordingState });
  };

  setRecordingstarted = (recordingStarted) => {
    this.setState({ recordingStarted });
  };
  setRecording = (recording) => {
    this.setState({ recording });
  };
  clickRecordingHandler = (recordingClicked) => {
    this.setState({ recordingClicked });
  };
  sendVoiceClicked = (sendVoiceClickedState) => {
    this.setState({ sendVoiceClickedState });
  };

  shouldComponentUpdate(nextProps, nextState) {
    let isEqual = true;
    Object.keys(this.props).forEach((propName) => {
      if (!isEqual) return;
      if (propName === 'text') {
        isEqual = this.text === nextProps.text;
        return;
      }
      isEqual = this.props[propName] === nextProps[propName];
    });
    return (
      !isEqual ||
      this.state.recordingClicked !== nextState.recordingClicked ||
      this.state.sendVoiceClickedState !== nextState.sendVoiceClickedState ||
      this.state.recording !== nextState.recording ||
      this.state.recordingStarted !== nextState.recordingStarted ||
      this.state.voicemessagesAllowed !== nextState.voicemessagesAllowed ||
      this.state.charLimitReached !== nextState.charLimitReached
    );
  }

  componentDidUpdate(prevProps) {
    const { selectedUser: oldSelectedUser, text: oldtext } = prevProps;
    const { selectedUser: newSelectedUser, text: newText } = this.props;
    if (oldSelectedUser !== newSelectedUser && newSelectedUser !== '') {
      this.clearRecordingState();
      this.setState({ charLimitReached: false });
    }
    if (
      ((oldSelectedUser !== newSelectedUser && newSelectedUser !== '') ||
        (oldtext !== newText && newText !== '')) &&
      this.chatInput?.current
    ) {
      const input = this.chatInput.current;
      input.value = newText;
      if (!this.props.mobileBrowser) {
        const pos = input.value.length;
        input.focus();
        if (oldSelectedUser !== newSelectedUser && newSelectedUser !== '') {
          if (pos === 0) return;
          setTimeout(() => input.setSelectionRange(pos, pos), 1);
        }
      }
    }
  }

  throwToast = () => {
    this.setState({ charLimitReached: true });
    clearTimeout(this.toastTimeout);
    this.toastTimeout = setTimeout(() => {
      this.setState({ charLimitReached: false });
    }, 2000);
  };

  handleChange = (e) => {
    const { typing, selectedUser, typingDelay = 300 } = this.props;

    if (e.target.value.length > 2000) {
      this.throwToast();
      e.target.value = e.target.value.slice(0, 2000);
      return;
    }

    // on the first keystroke send typing - after use delay
    if (this.text === '') {
      this.text = e.target.value;
      return typing(selectedUser, this.text);
    }

    const value = (this.text = e.target.value);

    // only dispatch if text did not change after the delay
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(() => {
      if (this.text === value) {
        typing(selectedUser, value);
      }
    }, typingDelay);
  };

  send(all) {
    if (!this.chatInput?.current) {
      return;
    }
    this.props.sendChat(all, this.chatInput.current.value.replace(/\n/, ''));
    this.text = this.chatInput.current.value = '';
    this.chatInput.current.focus();
  }

  componentDidMount() {
    // make sure we have correct text after navigating in the menu
    if (this.chatInput?.current) {
      this.chatInput.current.value = this.text = this.props.text;
      if (!this.props.mobileBrowser) {
        this.chatInput.current.focus();
      }
    }
  }

  componentWillUnmount() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    if (this.toastTimeout !== null) {
      clearTimeout(this.toastTimeout);
      this.toastTimeout = null;
    }
  }

  render() {
    const {
      disabled,
      intl,
      text,
      selectedUser,
      toggleSmileyOverlay,
      SmileyOverlay = false,
      clickSmiley,
      enable2All,
      isEvent,
      toggleChatMenu,
      chatMenuOpen,
      isAdmin = false,
      layout,
      isMobile,
      navLeft,
      navRight,
      camMenuOpen,
      toggleCamView,
      isDatingUser,
      isAppsUser,
      payttachmentsMenuOpen,
      selectedAttachmentName,
      unSelectAttachment,
      showInputOnMobile,
      usergame,
      disallowAppsAttachments,
      attachment,
      voicemessagesAllowed,
      voicemessageLoading,
    } = this.props;

    if (isMobile && !showInputOnMobile) {
      return null;
    }

    // text from user empty, reset class var, so first typing is sent
    if (text === '') {
      this.text = '';
    }

    const keyUp = (e) => {
      e.preventDefault();
      if (e.keyCode === 13) {
        this.send(isEvent);
      }
    };

    const btnClassNames = classNames('btn-menu', {
      active: chatMenuOpen,
    });

    const isLiveCamUser = userUtils.isLivecamUserId(selectedUser);
    const mobileBtnLeft = classNames('btn-mobile-left');
    const mobileBtnRight = classNames('btn-mobile-right');

    const chatField = classNames('chat-field', {
      'remove-smileys': isAppsUser,
    });

    const inputfieldClasses = classNames('inputfield', {
      'voice-control':
        !isLiveCamUser &&
        !isAppsUser &&
        (this.state.recordingClicked || this.state.recordingStarted),
    });

    return (
      <div className="chat-control">
        {isMobile && <UserGame />}
        {!payttachmentsMenuOpen && (
          <>
            {selectedAttachmentName && (
              <div className="selected-attachment">
                <div className="attachment-item">
                  {attachment?.id && (
                    <AttachmentItem
                      value={attachment.id}
                      file={attachment.file}
                      type={attachment.type}
                      clickable={false}
                    />
                  )}
                  <div className="text">
                    <span className="attachment-label">
                      <FormattedMessage id="ATTACHMENT_LABEL" />
                    </span>
                    <span className="attachment-name">
                      {selectedAttachmentName}{' '}
                    </span>
                  </div>
                </div>
                <Button
                  intlTranslate={false}
                  icon={close}
                  variant="icon-only"
                  className="delete"
                  onClick={() => unSelectAttachment()}
                />
              </div>
            )}
            <div className={inputfieldClasses}>
              {voicemessagesAllowed &&
              (this.state.recordingClicked || this.state.recordingStarted) ? (
                <VoiceRecorderControl
                  recordingClicked={this.state.recordingClicked}
                  clickRecordingHandler={this.clickRecordingHandler}
                  sendVoiceClickedState={this.state.sendVoiceClickedState}
                  sendVoiceClicked={this.sendVoiceClicked}
                  setRecording={this.setRecording}
                  clearRecordingState={this.clearRecordingState}
                  setRecordingstarted={this.setRecordingstarted}
                />
              ) : (
                <>
                  {' '}
                  <div className="button-wrapper menu">
                    <Button
                      className={btnClassNames}
                      title="Menu"
                      onClick={(event) => {
                        if (isMobile && camMenuOpen) {
                          toggleCamView();
                        }
                        if (!chatMenuOpen) {
                          toggleChatMenu();
                        }
                        event.stopPropagation();
                      }}
                      disabled={
                        disabled ||
                        isAdmin ||
                        isDatingUser ||
                        disallowAppsAttachments
                      }
                      intlTranslate={false}
                      icon={menuIcon}
                      variant="icon-only"
                    />
                  </div>
                  <textarea
                    className={chatField}
                    ref={this.chatInput}
                    disabled={disabled}
                    onKeyUp={keyUp}
                    placeholder={intl.formatMessage({
                      id: 'chat.input.placeholder',
                    })}
                    onChange={this.handleChange}
                  />
                  {this.state.charLimitReached && (
                    <div className="error-message chars-limit">
                      {intl.formatMessage({
                        id: 'chat.input.charLimit',
                      })}
                    </div>
                  )}
                  {!isAppsUser && (
                    <div className="button-wrapper smiley">
                      <Button
                        variant="icon-only"
                        disabled={disabled}
                        className="btn-smiley"
                        onClick={(e) => {
                          if (!SmileyOverlay) {
                            toggleSmileyOverlay();
                          }
                          e.stopPropagation();
                        }}
                        title={intl.formatMessage({ id: 'control.smiley' })}
                        icon={smileyIcon}
                        intlTranslate={false}
                      />
                    </div>
                  )}
                </>
              )}

              {(enable2All || isEvent) && layout === 'single' && !isMobile && (
                <div className="button-wrapper send">
                  <Button
                    variant="icon-only"
                    disabled={disabled || isAdmin}
                    className="btn-round btn-2all"
                    title={intl.formatMessage({ id: 'control.2allTooltip' })}
                    onClick={() => {
                      this.send(true);
                    }}
                    intlTranslate={false}
                    icon={send2allIcon}
                  />
                </div>
              )}

              {!isEvent && (
                <>
                  {this.state.recordingClicked ||
                  this.state.recordingStarted ? (
                    this.state.recording ? null : (
                      <div className="button-wrapper send">
                        <Button
                          variant="icon-only"
                          disabled={disabled}
                          className="btn-round btn-send"
                          title={intl.formatMessage({
                            id: 'control.sendTooltip',
                          })}
                          onClick={() => this.sendVoiceClicked(true)}
                          intlTranslate={false}
                          icon={sendIcon}
                        />
                      </div>
                    )
                  ) : (
                    <div className="button-wrapper send">
                      <Button
                        variant="icon-only"
                        disabled={disabled}
                        className="btn-round btn-send"
                        title={intl.formatMessage({
                          id: 'control.sendTooltip',
                        })}
                        onClick={() => {
                          this.send(false);
                        }}
                        intlTranslate={false}
                        icon={sendIcon}
                      />
                    </div>
                  )}

                  {chatMenuOpen ||
                  selectedAttachmentName ||
                  !voicemessagesAllowed ||
                  isLiveCamUser ||
                  isAppsUser ||
                  disabled ||
                  (this.state.recordingStarted &&
                    !this.state.recordingClicked) ? null : (
                    <div className="button-wrapper voice">
                      <Button
                        disabled={voicemessageLoading}
                        variant="icon-only"
                        className="btn-round btn-voice"
                        title={intl.formatMessage({
                          id: this.state.recordingClicked
                            ? 'AUDIO_BUTTON_STOP'
                            : 'AUDIO_BUTTON_RECORD',
                        })}
                        onClick={() => {
                          !this.state.recordingStarted &&
                            this.setRecordingstarted(true);
                          this.clickRecordingHandler(
                            !this.state.recordingClicked
                          );
                        }}
                        intlTranslate={false}
                        icon={this.state.recordingClicked ? stopIcon : mic}
                      />
                    </div>
                  )}
                </>
              )}
              {SmileyOverlay && (
                <ChatSmileyOverlay
                  {...this.props}
                  onClick={clickSmiley}
                  open={SmileyOverlay}
                  chatMenuOpen={chatMenuOpen}
                  toggleSmileyOverlay={toggleSmileyOverlay}
                />
              )}
            </div>
          </>
        )}
        <RunToy />
        {isMobile && !SmileyOverlay && !usergame && (
          <div className="mobile-user-nav">
            <Button
              intlTranslate={false}
              renumerateIRIElements={false}
              icon={leftIcon}
              variant="icon-only"
              onClick={navLeft}
              className={mobileBtnLeft}
            />
            <Button
              intlTranslate={false}
              renumerateIRIElements={false}
              icon={rightIcon}
              variant="icon-only"
              onClick={navRight}
              className={mobileBtnRight}
            />
          </div>
        )}
        <ChatMenu />
      </div>
    );
  }
}

export default injectIntl(ChatControl);
