import '../../Chat.scss';

import classNames from 'classnames';

import Spinner from '../../../../Spinner/Spinner';

function MessageLoading({ loading, color }) {
  const classes = classNames('loadingSpinner', { [color]: color });
  return loading ? (
    <div className={classes}>
      <Spinner />
    </div>
  ) : null;
}

export default MessageLoading;

