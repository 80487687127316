import semver from 'semver';

import { uuid } from '../../utils';
import api from '../../utils/api';
import {
  ALERT_VERSION_UPDATE_MANDATORY,
  ALERT_VERSION_UPDATE_OPTIONAL,
} from '../actions';

export default function (version, direct = false) {
  return async (dispatch, getState) => {
    try {
      const versionResult = await api.get('/version');

      if (!versionResult?.data?.length || versionResult?.status !== 200) {
        throw new Error(
          `versionResult not valid with status ${versionResult?.status}`
        );
      }

      const versions = versionResult.data;
      let nextMandatoryVersion;
      let nextOptionalVersion;

      if (versions.length === 1 || Object.keys(versions[0]).length === 1) {
        nextMandatoryVersion = versions[0].version;
      } else {
        const mandatoryVersionObject = versions.find(
          (v) => v.environment === 'frontend.mandatory'
        );
        nextMandatoryVersion = mandatoryVersionObject?.version;
      }

      const nextOptionalVersionObject = versions.find(
        (v) => v.environment === 'frontend.optional'
      );

      if (nextOptionalVersionObject) {
        nextOptionalVersion = nextOptionalVersionObject.version;
      }

      const { user } = getState();
      const needMandatoryUpdate = semver.lt(version, nextMandatoryVersion);
      const needOptionalUpdate =
        nextOptionalVersion && semver.lt(version, nextOptionalVersion);

      if (
        (!user?.isLoggedIn || direct) &&
        (needMandatoryUpdate || needOptionalUpdate)
      ) {
        return window.location.reload();
      }

      if (needMandatoryUpdate) {
        return dispatch({
          type: ALERT_VERSION_UPDATE_MANDATORY,
          meta: { stamp: uuid() },
        });
      }

      const { optionalstamp, cancel } = getState().version;

      if (needOptionalUpdate) {
        if (optionalstamp || cancel) {
          return;
        }
        dispatch({
          type: ALERT_VERSION_UPDATE_OPTIONAL,
          meta: { stamp: uuid() },
        });
      }
    } catch (error) {
      console.log({ error });
    }
  };
}
