import { getPortals } from '../../utils/portals';

export default () => async (dispatch, getState) => {
  try {
    if (!getState().user?.isLoggedIn) return;
    getPortals(dispatch);
  } catch (err) {
    console.log(err);
  }
};
