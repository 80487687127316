import { connect } from 'react-redux';

import { sendChatMessage } from '../../../../../actions';
import MessageResend from './MessageResend';

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (payload) => dispatch(sendChatMessage(payload)),
});

export default connect(null, mapDispatchToProps)(MessageResend);

