import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import BackButton from '../../../../BackButton';

function Visibility({ onBackButtonClicked }) {
  return (
    <div className="wiki-content visibility-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="VISIBILITY_HEADLINE" />
      </h1>
      <p>
        <FormattedMessage id="VISIBILITY_INTRO" />
      </p>
      <p>
        <FormattedMessage
          id="VISIBILITY_INTRO_1"
          values={{
            link: (
              <Link to="/wiki/moneymakers#icebreaker">
                <FormattedMessage id="VISIBILITY_INTRO_1_LINK" />
              </Link>
            ),
          }}
        />
      </p>
      <p>
        <FormattedMessage id="VISIBILITY_INTRO_2" />
      </p>
      <p>
        <FormattedMessage id="VISIBILITY_INTRO_3" />
      </p>

      <h3 className="headline">
        <FormattedMessage id="VISIBILITY_HEADLINE_1" />
      </h3>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="VISIBILITY_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="VISIBILITY_CONTENT_STEP_1_CONTENT"
              values={{
                link: (
                  <Link to="/wiki/general#profile">
                    <FormattedMessage id="VISIBILITY_CONTENT_STEP_1_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage id="VISIBILITY_CONTENT_STEP_1_1_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
            <FormattedMessage
              id="VISIBILITY_CONTENT_STEP_1_2_CONTENT"
              values={{
                link: (
                  <Link to="/wiki/general#creatorTags">
                    <FormattedMessage id="VISIBILITY_CONTENT_STEP_1_1_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="VISIBILITY_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="VISIBILITY_CONTENT_STEP_2_CONTENT"
              values={{
                link: (
                  <Link to="/wiki/general#diary">
                    <FormattedMessage id="VISIBILITY_CONTENT_STEP_2_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="VISIBILITY_CONTENT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage
              id="VISIBILITY_CONTENT_STEP_3_CONTENT"
              values={{
                link: (
                  <Link to="/wiki/general#galleries">
                    <FormattedMessage id="VISIBILITY_CONTENT_STEP_3_LINK" />
                  </Link>
                ),
              }}
            />
          </p>
          <p>
            <FormattedMessage id="VISIBILITY_CONTENT_STEP_3_1_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>
      <p>
        <FormattedMessage id="VISIBILITY_OUTRO" />
      </p>
      <p>
        <FormattedMessage id="VISIBILITY_OUTRO_1" />
      </p>
    </div>
  );
}
export default Visibility;
