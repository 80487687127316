import { connect } from 'react-redux';

import { cookie, updateIntl, userAddAuthToken } from '../../../../actions';
import { default as locales } from '../../../../locale';
import { lStorage } from '../../../../services/storage';
import Form from './Form';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  cookies: state.browser.cookiesAllowed,
});

const mapDispatchToProps = (dispatch) => ({
  addAuthToken: (token) => dispatch(userAddAuthToken(token)),
  cookie: (thirdPartyCookiesAllowed, data) =>
    dispatch(cookie(thirdPartyCookiesAllowed, data)),
  changeLocale: (locale) => {
    lStorage.setItem('lang', locale);
    return dispatch(
      updateIntl({
        locale,
        messages: locales[locale],
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);

