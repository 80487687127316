export default {
  USER_ADVERTISING_TITLE: `Acquire users and receive an additional 25% commission`,
  USER_ADVERTISING_CONTENT_1: `Use your personal Advertising links to acquire new users and receive an additional 25% commission on top of all generated revenue.`,
  USER_ADVERTISING_CONTENT_2: `<p>Put your link in your social media profiles, e.g. Facebook or Instagram, or post it on your personal blog or on forums that you visit and lead new users directly to your profile on your preferred community</p>
  <p><span class="bold">For every Coin-Purchase</span> that these users make, you will receive <span class="bold green">25% commission - instantly</span>! The commission will be added to the revenue for the current accounting period immediately!</p>
  <p>Furthermore you will receive a <span class="bold green">32% commission</span>, whenever one of these users purchases your media, uses your chat or joins your livecam.</p>
  <p>This gives you the opportunity to increase your revenue up to 57% - <span class="bold">take your chance and make big money</span>!</p>`,
  USER_ADVERTISING_LINK_AMX: `Your personal link for AmateurX`,
  USER_ADVERTISING_LINK_FUN: `Your personal link for FunCommunity`,
  USER_ADVERTISING_LINK_AMF: `Your personal link for AmateurFans`,
};
