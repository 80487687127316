import {
  changeCam,
  chooseCam,
  endCheckBandwidth,
  startCheckBandwidth,
  startLoadingCam,
  stopCam,
} from '../../actions';
import { ABORT, SERVER_ERROR, TIMEOUT } from '../../errors';
import { bandwidth, sender } from '../../services';
import { randomString, uuid } from '../../utils';
import { BANDWIDTH_CHECK } from '../actions';

export default (value, label) => (dispatch, getState) => {
  const {
    bandwidth: { timeout, checkLengths },
    sender: {
      sessionId,
      software: { origin },
      server: { serviceURL },
    },
    cam: {
      selectedCam: { value: currentValue, label: currentLabel },
      selectedResolution: {
        value: currentResolutionValue,
        label: currentResolutionLabel,
      },
    },
  } = getState();

  if (!value || !label) {
    value = currentValue;
    label = currentLabel;
  }

  const stamp = uuid();
  let timeoutError = false;
  dispatch(startLoadingCam(stamp));
  dispatch(startCheckBandwidth());

  const maxTimer = setTimeout(() => {
    timeoutError = true;
  }, timeout);

  let i = 1;
  dispatch(stopCam());
  sender
    .server({ type: 'bandwidthCheck', serviceURL, softwareOrigin: origin })
    .then((serverUrls) =>
      checkLengths.reduce(
        (p, length) =>
          p
            .then(
              () =>
                new Promise((resolve, reject) => {
                  const url = serverUrls[0].host + '/web';
                  const bufferLength = length * 1024;
                  const text = randomString(bufferLength);
                  const startTime = new Date();
                  const abortSelectCam = getState().cam.abortSelectCam;
                  bandwidth
                    .check({ text, url, sessionId, origin })
                    .then((resp) => resp.json())
                    .then((result) => {
                      const abortCheckBandwidth = getState().bandwidth
                        .abortCheck;
                      if (abortSelectCam || abortCheckBandwidth) {
                        return reject(new Error(ABORT));
                      }
                      if (timeoutError) {
                        return reject(new Error(TIMEOUT));
                      }
                      if (!result.success) {
                        return reject(new Error(SERVER_ERROR));
                      }
                      const endTime = new Date();
                      dispatch({
                        type: BANDWIDTH_CHECK,
                        meta: { step: i++, stamp, length: checkLengths.length },
                        payload: { startTime, endTime, bufferLength },
                      });
                      return bandwidth.save({
                        url,
                        sessionId,
                        origin,
                        bandwidth: getState().bandwidth.currentBandwidth,
                      });
                    })
                    .then(resolve)
                    .catch((err) => {
                      return reject(new Error(SERVER_ERROR));
                    });
                })
            )
            .catch((error) => Promise.reject(error)),
        Promise.resolve()
      )
    )
    .then(() => {
      clearTimeout(maxTimer);
      dispatch(endCheckBandwidth());
      dispatch(
        changeCam(
          value,
          label,
          stamp,
          currentResolutionLabel,
          currentResolutionValue
        )
      );
    })
    .catch((error) => {
      clearTimeout(maxTimer);
      const abortCheckBandwidth = getState().bandwidth.abortCheck;
      if (!abortCheckBandwidth) {
        dispatch({
          type: BANDWIDTH_CHECK,
          error: true,
          meta: { stamp, errorStamp: uuid() },
          payload: error,
        });
        if (currentValue !== '0' && value !== '0') {
          dispatch(chooseCam());
        }
      }
    });
};
