import { stream } from '../../../actions';
import { STREAM_STOP_TIMER_START, STREAM_STOP_TIMER_STOP } from '../../actions';
let timer = null;

const start = () => (dispatch, getState) => {
  console.log('Stop Stream timer start called');
  const {
    streaming: { permanentStream, streamStopTimeout },
  } = getState();
  if (permanentStream) {
    return;
  }
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
  timer = setTimeout(() => {
    dispatch({
      type: STREAM_STOP_TIMER_STOP,
    });
    dispatch(stream.checkStop());
  }, streamStopTimeout);
  dispatch({
    type: STREAM_STOP_TIMER_START,
  });
};

const stop = () => (dispatch) => {
  console.log('Stop Stream timer stop called');
  if (timer) {
    clearTimeout(timer);
    timer = null;
  }
  dispatch({
    type: STREAM_STOP_TIMER_STOP,
  });
};

export default {
  start,
  stop,
};
