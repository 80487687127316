import './ActionButtons.scss';

import classNames from 'classnames';
import React, { useState } from 'react';

import rotateLeftIcon from '../../../assets/img/svg/rotate-left.svg';
import rotateRightIcon from '../../../assets/img/svg/rotate-right.svg';
import uploadIcon from '../../../assets/img/svg/upload.svg';
import Button from '../../Button/Button';

function ActionButtons({
  file,
  onUploadClick = () => {},
  onClockwiseRotateBtnClick = () => {},
  onCounterClockwiseRotateBtnClick = () => {},
  isUploadDisabled,
}) {
  const [disabledButtons, setDisabledButtons] = useState(false);

  const className = classNames('rotation-btn', {
    disabled: disabledButtons,
  });

  return (
    <div className="action-buttons">
      <div>
        <div className="filename">{file.name}</div>

        <Button
          onClick={() => {
            setDisabledButtons(true);
            onCounterClockwiseRotateBtnClick();
            setTimeout(() => {
              setDisabledButtons(false);
            }, 500);
          }}
          disabled={disabledButtons}
          className={className}
          icon={rotateLeftIcon}
          variant="icon-only"
          intlTranslate={false}
        />

        <Button
          type="button"
          onClick={() => {
            setDisabledButtons(true);
            onClockwiseRotateBtnClick();
            setTimeout(() => {
              setDisabledButtons(false);
            }, 500);
          }}
          disabled={disabledButtons}
          className={className}
          icon={rotateRightIcon}
          variant="icon-only"
          intlTranslate={false}
        />
      </div>

      <Button
        icon={uploadIcon}
        onClick={onUploadClick}
        label="BUTTON_UPLOAD_IMAGE"
        className="upload-btn"
        disabled={isUploadDisabled}
      />
    </div>
  );
}

export default ActionButtons;

