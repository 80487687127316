import imagePanel1 from '../../../../assets/img/home2/content_1.jpg';
import imagePanel2 from '../../../../assets/img/home2/content_2.jpg';
import imagePanel3 from '../../../../assets/img/home2/content_3.jpg';
import imagePanel4 from '../../../../assets/img/home2/content_4.jpg';
import imagePanel5 from '../../../../assets/img/home2/content_5.jpg';
import imagePanel6 from '../../../../assets/img/home2/content_6.jpg';

export const upperLeftPanels = [
  {
    id: 1,
    image: imagePanel1,
    imageClass: 'panel-image-float-left',
    mobileImageClass: 'panel-image-center',
  },
  {
    id: 2,
    image: imagePanel2,
    imageClass: 'panel-image-float-left',
    mobileImageClass: 'panel-image-center',
  },
  { id: 'button1' },
  {
    id: 3,
    image: imagePanel3,
    imageClass: 'panel-image-float-right',
    mobileImageClass: 'panel-image-float-right',
  },
];

export const upperRightPanels = [
  { id: 4, image: imagePanel4, imageClass: 'panel-image-float-left' },
  { id: 'button2' },
  { id: 5, image: imagePanel5, imageClass: 'panel-image-float-right' },
  { id: 6, image: imagePanel6, imageClass: 'panel-image-float-right' },
];

export const lowerLeftPanels = [7, 8, 'button3', 9, 10, 'button4', 11, 12];

export const lowerRightPanels = [
  'button5',
  13,
  14,
  'button6',
  15,
  16,
  'button7',
  17,
  18,
];
