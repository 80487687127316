import { connect } from 'react-redux';

import { management, showMotto } from '../../../../actions';
import { CHECKING } from '../../../../constants/bandwidth';
import { roomType as roomTypeUtils } from '../../../../utils';
import StreamingButton from './StreamingButton';

const mapStateToProps = (state) => ({
  ...state.streaming,
  startButtonId: roomTypeUtils.isEvent(state.sender.roomTypes)
    ? 'streamingButton.startEvent'
    : 'streamingButton.start',
  stopButtonId: roomTypeUtils.isEvent(state.sender.roomTypes)
    ? 'streamingButton.stopEvent'
    : 'streamingButton.stop',
  checkingBandwidth: state.bandwidth.check === CHECKING,
  checkingCam: state.cam.checkingCam,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(management.logoutCam()),
  stop: () => dispatch(management.connect.stop()),
  start: () => dispatch(showMotto()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StreamingButton);
