import {} from '../../errors';

import { ADD_CAM, RESET_CAM } from '../../actions/actions';
import { ALERT_LEVEL_NONE, ALERT_TYPE_CONFIRM } from '../../constants';
import { hashAlert } from '../../utils';

const initialState = [];
const noWebcamAlertTemplate = {
  type: ALERT_TYPE_CONFIRM,
  level: ALERT_LEVEL_NONE,
  scope: 'livecam',
  message: {
    translate: true,
    id: 'alert.noWebcamFound',
  },
};
const reduce = (state = initialState, action) => {
  switch (action.type) {
    // cam actions
    case RESET_CAM:
      if (!action.payload?.showAlert) {
        return state;
      }
      const alert = {
        ...noWebcamAlertTemplate,
        stamp: action.meta.stamp,
      };
      // make alert unique
      if (
        state.filter((a) => {
          return hashAlert(alert) === hashAlert(a);
        }).length > 0
      ) {
        return state;
      }
      return [...state, alert];
    case ADD_CAM:
      return state.filter((a) => {
        return hashAlert(noWebcamAlertTemplate) !== hashAlert(a);
      });
    default:
      return state;
  }
};

export default reduce;
