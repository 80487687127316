import { RATATOSKR_TIME_FRAME } from '../../../constants';
import { newDialog } from '../../';
import {
  ADD_MESSAGE_EVENT_RATATOSKR,
  REMOVE_MESSAGE_EVENT_RATATOSKR,
} from '../../actions';
export default (message) => async (dispatch, getState) => {
  try {
    if (!getState().user?.account?.id) return;

    const userId = getState().user?.account?.id;
    const payload = JSON.parse(message.data);

    if (!payload?.userId || !payload?.id || !payload.timestamp) return;
    if (getState().ignored.ignoredIds.includes(payload.userId)) {
      return;
    }
    const {
      dialog: { dialogs },
      friends,
    } = getState();
    const allDialogs = [...dialogs];
    const dialog = allDialogs.find((d) => d.partner === payload.userId);
    const isFriend = friends.ids.includes(payload.userId);

    if (isFriend && payload.id === 'buddyrequest') return;

    if (!dialog) {
      await dispatch(
        newDialog({
          id: payload.userId,
          name: payload.nickname,
          noRemoteDialog: true,
          time: Math.floor(payload.timestamp / 1000),
        })
      );
    }

    const timeout = payload.timestamp - Date.now() + RATATOSKR_TIME_FRAME;

    if (timeout <= 0) return;

    if (isFriend && payload.id === 'online') {
      payload.id = 'friend';
    }

    dispatch({
      type: ADD_MESSAGE_EVENT_RATATOSKR,
      payload,
    });

    setTimeout(() => {
      if (getState().user?.account?.id !== userId) return;
      dispatch({
        type: REMOVE_MESSAGE_EVENT_RATATOSKR,
        payload,
      });
    }, timeout);
  } catch (err) {
    console.log(err);
  }
};
