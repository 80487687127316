export default {
  GAME_TURN_WAIT: 'Warte...',
  GAME_TURN_YOUR_TURN: 'Du bist dran',
  GAME_TIME_LEFT: `Verbleibende Zeit: `,
  GAMESTATS_GAMES: 'Spiele',
  GAMESTATS_GAME: 'Spiel',
  GAMESTATS_WINS: 'Siege',
  GAMESTATS_WIN: 'Sieg',
  GAMESTATS_RANK: 'Rang',
  GAME_WON: 'Du hast gewonnen!',
  GAME_WON_TIMEOUT: 'Du hast gewonnen! (Timeout)',
  GAME_LOST: 'Du hast verloren!',
  GAME_LOST_TIMEOUT: 'Du hast verloren! (Timeout)',
  GAME_DRAW: 'Unentschieden!',
  ROUTE_GAME4SCORE_HEADLINE: 'Schau Dir Deine letzten Spiele an',
  ROUTE_GAME4SCORE_TITEL: 'Game 4Score',
  GAME_WON_OR_LOST: 'Gewonnen oder verloren?',
  GAME_INVITATION_TIMEOUT_REFUND:
    'Die Einladung ist abgelaufen, der Kunde bekam die Coins zurück!',
  GAME_MATCH_WINNER: 'Du hast das Spiel gewonnen!',
  GAME_MATCH_WINNER_TIMEOUT:
    'Du hast das Spiel aufgrund eines Timeouts gewonnen!',
  GAME_MATCH_LOSER: 'Du hast das Spiel verloren!',
  GAME_MATCH_TIMEOUT_REFUND:
    'Du hast das Spiel aufgrund eines Timeouts verloren, der Kunde bekam die Coins zurück!',
  GAME_MATCH_DRAW: 'Das Spiel endete unentschieden!',
  GAME_MATCH_FROM: 'Spiel vom',
  GAME_INVITATION_FROM: 'Einladung vom',
  GAME_MATCH_WITH: 'mit',
};
