import { connect } from 'react-redux';

import { spinner } from '../../../../actions';
import Favorites from './Favorites';

const mapStateToProps = (state) => ({
  dialog: state.dialog.dialogs,
});

const mapDispatchToProps = {
  spinner,
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
