import api from '../../utils/api';
import { payttachmentAdd } from '../';

const payttachmentsAllowed = (state) =>
  state.user?.isLoggedIn &&
  state.user?.account?.state === 'confirmed' &&
  state.user?.account?.payttachments === 'enabled';

export default () => async (dispatch, getState) => {
  try {
    if (!payttachmentsAllowed(getState())) return;
    const response = await api.get('/user/payttachments?limit=24');

    if (!payttachmentsAllowed(getState())) return;
    dispatch(payttachmentAdd(response.data));
  } catch (err) {
    console.log({ payttachmentError: err });
    console.log('could not load payttachments');
  }
};
