import { initUpgrade, management, sendSystemChatMessage } from '../../actions';
import { UPGRADE_INIT } from '../actions';
let timer = null;
export default (start) => (dispatch, getState) => {
  if (start) {
    console.log('initUpgrade start');
    timer = setTimeout(() => {
      console.log(`initUpgrade Timeout ${getState().sender.upgradeStarted}`);
      if (getState().sender.upgradeStarted) {
        dispatch(management.logoutCam({}, 12));
        dispatch(initUpgrade(false));
        dispatch(
          sendSystemChatMessage({
            id: 'systemMessage.exclusiveError',
            highlight: true,
          })
        );
      }
    }, 15000);
  } else {
    console.log('initUpgrade stop');
    if (timer) {
      console.log('initUpgrade clearTimeout');
      clearTimeout(timer);
      timer = null;
    }
  }
  dispatch({
    type: UPGRADE_INIT,
    meta: {
      start,
    },
  });
};
