import api from '../../utils/api';
import { updateCountries, userLogout } from '../';

const getCountriesList = (countries, language) => {
  let countriesList = countries.filter((country) => country.important);
  countriesList.sort((a, b) => a[language].localeCompare(b[language]));
  countriesList.push({
    country_code: 'break_line_element',
    en: 'break_line_element',
    de: 'break_line_element',
  });
  const allCountriesList = countries.sort((a, b) =>
    a[language].localeCompare(b[language])
  );

  countriesList = countriesList.concat(allCountriesList);
  countriesList = countriesList.map((country) => {
    return { value: country.country_code, label: country[language] };
  });

  const defaultSelect = {
    de: { value: '', label: 'Bitte wählen...' },
    en: { value: '', label: 'Please select...' },
  };

  countriesList.unshift(defaultSelect[language]);
  return countriesList;
};
export default () => async (dispatch, getState) => {
  try {
    if (!getState().user?.isLoggedIn) return;
    const response = await api.get('/data/countries');

    if (!getState().user?.isLoggedIn || !getState().user?.account?.language)
      return;
    dispatch(
      updateCountries(
        getCountriesList(response.data, getState().user?.account?.language)
      )
    );
  } catch (err) {
    dispatch(userLogout(false, 1, true));
  }
};
