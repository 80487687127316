import { RESIZE } from '../actions';
const isMobileQuery = '(max-width: 1023px)';
const isMediumQuery = '(min-width: 1024px) and (max-width: 1479px)';
const isLargeQuery = '(min-width: 1480px)';
const isMediumLargeQuery = '(min-width: 1480px) and (max-width: 1599px)';
const getMobile = () => window.matchMedia(isMobileQuery).matches;
const getMedium = () => window.matchMedia(isMediumQuery).matches;
const getMediumLarge = () => window.matchMedia(isMediumLargeQuery).matches;
const getLarge = () => window.matchMedia(isLargeQuery).matches;

export default () => {
  //see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  document.documentElement.style.setProperty(
    '--vh',
    `${window.innerHeight / 100}px`
  );
  return {
    type: RESIZE,
    meta: {
      isLarge: getLarge(),
      isMediumLarge: getMediumLarge(),
      isMedium: getMedium(),
      isMobile: getMobile(),
    },
  };
};
