import api from '../../services/sender';

export default function (activeToy) {
  return async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }
    try {
      await api.toycontrolTest(activeToy);

      if (!getState().user.isLoggedIn) {
        return;
      }
    } catch (error) {
      // stay silent
    }
  };
}
