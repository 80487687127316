import { SENDER_LOGIN, SENDER_LOGOUT } from '../../actions/actions';
import {
  ALERT_LEVEL_ERROR,
  ALERT_LEVEL_INFO,
  ALERT_TYPE_CLOSE,
} from '../../constants';
import { hashAlert, uuid } from '../../utils';

const initialState = [];

const reduce = (state = initialState, action) => {
  switch (action.type) {
    // sender login/logout
    case SENDER_LOGIN: {
      if (action.payload.messageOfTheDay) {
        return [
          ...initialState,
          {
            stamp: uuid(),
            type: ALERT_TYPE_CLOSE,
            level: ALERT_LEVEL_INFO,
            scope: 'livecam',
            message: {
              message: action.payload.messageOfTheDay,
            },
          },
        ];
      }

      if (!action.error && !(action.meta && action.meta.start)) {
        return [...initialState];
      }
      if (action.error && typeof action.payload === 'string' && action.meta) {
        const { modal, id, links, scope } = action.meta;
        let alert = {
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          modal,
          scope,
          message: {
            translate: true,
            id,
            links,
          },
          stamp: uuid(),
        };
        // make alert unique
        if (
          state.filter((a) => {
            const param1 = { ...a, stamp: undefined };
            const param2 = { ...alert, stamp: undefined };
            return hashAlert(param1) === hashAlert(param2);
          }).length > 0
        ) {
          return state;
        }

        return [...state, alert];
      }
      if (action.error && action.payload) {
        let alert = {
          type: ALERT_TYPE_CLOSE,
          level: ALERT_LEVEL_ERROR,
          stamp: uuid(),
          scope: 'livecam',
          message: {
            translate: true,
            id: 'alert.failedSenderLogin',
          },
        };

        if (
          state.filter((a) => {
            const param1 = { ...a, stamp: undefined };
            const param2 = { ...alert, stamp: undefined };
            return hashAlert(param1) === hashAlert(param2);
          }).length > 0
        ) {
          return state;
        }

        return [...initialState, alert];
      }
      return state;
    }
    case SENDER_LOGOUT:
      if (action.meta && action.meta.start) {
        if (typeof action.payload === 'string') {
          return [
            ...state,
            {
              stamp: action.meta.stamp,
              type: ALERT_TYPE_CLOSE,
              level: ALERT_LEVEL_ERROR,
              scope: 'livecam',
              message: {
                translate: true,
                id: 'alert.' + action.payload,
              },
              persistAfterLogout: true,
            },
          ];
        }
      }
      return state;
    default:
      return state;
  }
};

export default reduce;
