import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeOverlayheightCam } from '../../../actions';
import Cam from './Cam';

const mapStateToProps = (state, ownProps) => ({
  ...state.cam,
  ...state.streaming,
  ...ownProps,
  change: state.view.resize,
  motto: state.motto.text,
});
const mapDisptachToProps = (dispatch) =>
  bindActionCreators({ changeOverlayheightCam }, dispatch);
export default injectIntl(connect(mapStateToProps, mapDisptachToProps)(Cam));
