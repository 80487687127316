import { management as managementActions } from '../../../actions';
import { STREAMING_STARTED } from '../../../constants';
import { user as userUtils } from '../../../utils';
import { MANAGEMENT_LOG_FPS } from '../../actions';

const interval = 5000;

let timer;

const start = (management) => () => (dispatch, getState) => {
  if (!timer) {
    timer = setInterval(() => {
      const {
        streaming: { step },
        logFps,
        streamingstats,
        camUser: { camUsers },
        cam: {
          selectedCam: { label: camera },
          selectedResolution,
          selectedFps: { value: setFps },
        },
        sender,
      } = getState();

      if (!management.connected() || logFps.sent) {
        return dispatch(stop());
      }

      if (
        step !== STREAMING_STARTED ||
        streamingstats.fps > logFps.min ||
        camUsers.length < 1
      ) {
        return;
      }
      dispatch({
        type: MANAGEMENT_LOG_FPS,
        meta: { increment: true },
      });

      if (logFps.count < logFps.maxRetries) {
        return;
      }

      const userList = camUsers.filter((user) => !userUtils.isAdmin(user));
      const adminList = camUsers.filter((user) => userUtils.isAdmin(user));

      const getId = (user) => user.id;

      const { username: sendername, origin } = sender.software;

      const resolution = selectedResolution.value.split('x');
      const width = parseInt(resolution.shift());
      const height = parseInt(resolution.shift());

      management.emit('logFps', {
        fps: Math.round(streamingstats.fps),
        camera,
        height,
        width,
        setFps,
        sendername,
        origin,
        user: userList.map(getId),
        admins: adminList.map(getId),
        time: Math.round(new Date().time / 1000),
      });

      dispatch({
        type: MANAGEMENT_LOG_FPS,
      });

      dispatch(stop());

      if (!streamingstats.fps) {
        setTimeout(() => {
          dispatch(
            managementActions.logoutCam(
              { kickMessage: 'alert.lowFps', translate: true },
              8
            )
          );
        }, 1000);
      }
    }, interval);
  }
};

const stop = () => () => {
  clearTimeout(timer);
  timer = null;
};

export default (management) => ({
  start: start(management),
  stop,
});
