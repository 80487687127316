import { errorLog } from '../actions';
import api from './api';

const portals = {
  id_1: { id: 1, whitelabelShort: 'ACNG', whitelabelLong: 'AC NextGeneration' },
  id_2: { id: 2, whitelabelShort: 'AC', whitelabelLong: 'AmateurCommunity' },
  id_10: { id: 10, whitelabelShort: 'MGD', whitelabelLong: 'Mega Dating' },
  id_11: {
    id: 11,
    whitelabelShort: 'SPC',
    whitelabelLong: 'Sexpartner Community',
  },
  id_12: { id: 12, whitelabelShort: 'ACC', whitelabelLong: 'AmateurCommunity' },
  id_13: { id: 13, whitelabelShort: 'LCP', whitelabelLong: 'Livecampartner' },
  id_14: { id: 14, whitelabelShort: 'FUN', whitelabelLong: 'FUN Community' },
  id_15: { id: 15, whitelabelShort: 'ERO', whitelabelLong: 'Erocommunity' },
  id_16: { id: 16, whitelabelShort: 'MGC', whitelabelLong: 'Mega Community' },
  id_17: { id: 17, whitelabelShort: 'AMX', whitelabelLong: 'AmateurX' },
  id_18: { id: 18, whitelabelShort: 'MCA', whitelabelLong: 'mCams' },
  id_19: {
    id: 19,
    whitelabelShort: 'STC',
    whitelabelLong: 'Sextreffen Community',
  },
  id_20: { id: 20, whitelabelShort: 'SXC', whitelabelLong: 'Sexx Community' },
  id_21: {
    id: 21,
    whitelabelShort: 'SKT',
    whitelabelLong: 'Sexkontakte Treffpunkt',
  },
  id_22: { id: 22, whitelabelShort: 'SDC', whitelabelLong: 'Sexdateclub' },
  id_23: {
    id: 23,
    whitelabelShort: 'DST',
    whitelabelLong: 'DeinSextreffpunkt',
  },
  id_24: { id: 24, whitelabelShort: 'SDO', whitelabelLong: 'SexDatesOnly' },
  id_25: { id: 25, whitelabelShort: 'AMF', whitelabelLong: 'AmateurFans' },
  id_26: { id: 26, whitelabelShort: 'AFO', whitelabelLong: 'AmateurFansOnly' },
  id_27: { id: 27, whitelabelShort: 'LV6', whitelabelLong: 'Live6' },
  id_28: { id: 28, whitelabelShort: 'FHB', whitelabelLong: 'Fikhub' },
};
const attachmentPortals = [
  1010, 1012, 1017, 1021, 1022, 1026, 1027, 1028, 1031, 1032, 1033,
];
const attachmentPortalsUrls = {
  portal_1010: 'https://cimage.fetoo.com',
  protal_1012: 'https://cimage.milfme.com',
  portal_1017: 'https://cimage.extremechat.com',
  portal_1021: 'https://cimage.milfroom.com',
  portal_1022: 'https://cimage.bbwbook.com',
  portal_1026: 'https://cimage.grannyzone.com',
  portal_1027: 'https://cimage.milfbook.com',
  portal_1028: 'https://cimage.trannybook.com',
  portal_1031: 'https://cimage.dirtyzone.com',
  portal_1032: 'https://cimage.extremesite.com',
  portal_1033: 'https://cimage.tranny1.com',
};
const isAppsV1 = process.env.REACT_APP_APPS_LIST_VERSION === '1';
const appPortals = (id) => {
  if (id === 9999) {
    return { id, whitelabelShort: 'TEST', whitelabelLong: 'Dating' };
  }
  const whitelabelLong = isAppsV1 && id <= 1034 ? 'Dating (APPS)' : 'Dating';
  const whitelabelShort = 'DATE';
  if (attachmentPortals.includes(id)) {
    return {
      id,
      whitelabelShort,
      whitelabelLong,
      allowAttachments: true,
      cdnPath: isAppsV1
        ? 'https://s3-eu-west-1.amazonaws.com/api2-stage-bucket/img'
        : attachmentPortalsUrls[`portal_${id}`],
    };
  }
  return {
    id,
    whitelabelShort,
    whitelabelLong,
    allowAttachments: false,
  };
};

const extractPortal = (id) => {
  if (id > 1000) {
    return appPortals(id);
  }
  return portals[`id_${id}`];
};
const addPortal = (id, portal) => (portals[`id_${id}`] = portal);

const checkPortal = (dispatch) => (portal) => {
  const id = portal.product_id;
  const portalFromArray = extractPortal(id);
  return !portalFromArray;
};
export async function getPortals(dispatch) {
  try {
    const response = await api.get(`/portals`);
    if (response?.data?.length) {
      const newPortals = response.data.filter(checkPortal(dispatch));
      newPortals.forEach((p) => {
        const id = p.product_id;
        const whitelabelLong = p.whitelabelLong;
        const whitelabelShort = p.whitelabelShort;
        addPortal(id, { id, whitelabelShort, whitelabelLong });
      });
      if (newPortals.length > 0) {
        const logObject = {
          level: 'error',
          location: 'getPortals',
          message: `Portal(s) not found: ${newPortals
            .map((p) => p.product_id)
            .toString()}`,
        };
        dispatch(errorLog(logObject));
      }
    }
  } catch (e) {
    console.log(`Error fetching all portals ${e.message}`);
  }
}
export async function getPortal(id) {
  if (!id) {
    return { id, whitelabelShort: 'DATE', whitelabelLong: 'Dating' };
  }
  const portalFromArray = extractPortal(id);
  if (portalFromArray) return portalFromArray;
  try {
    const response = await api.get(`/portal/${id}`);
    const portal = { ...response.data, id };
    addPortal(id, portal);
    return portal;
  } catch {
    console.log('error, portals cannot be fetched');
  }
}

export async function addPortals(items) {
  if (items.length > 0) {
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const portalId = item.id.split('-')[0];
      if (!item?.portals) {
        const portals = await getPortal(portalId);
        items[i].portals = portals;
      }
    }
  }
  return items;
}

