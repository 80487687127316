import * as Yup from 'yup';

export const getValidationScheme = (intl) => {
  const t = (id) => intl.formatMessage({ id });
  const titleRequired = Yup.string().required(t('VALIDATION_REQUIRED_FIELD'));
  const validationTextarea = Yup.string()
    .required(t('VALIDATION_REQUIRED_FIELD'))
    .min(32, t('VALIDATION_VIDEO_DESCRIPTION_TO_SHORT'))
    .max(1000, t('VALIDATION_VIDEO_DESCRIPTION_TO_LONG'))
    .test({
      test: function (value) {
        if (
          typeof value === 'string' &&
          value.match(/^.*(https?:\/\/|www\..+)+.*$/gim)
        ) {
          return this.createError({
            message: t('VALIDATION_NO_URLS'),
          });
        }
        if (
          typeof value === 'string' &&
          value.match(/<[/?a-zA-Z]+[/?0-9]*>/g)
        ) {
          return this.createError({
            message: t('VALIDATION_NO_TAGS'),
          });
        }

        return true;
      },
    });

  return Yup.object().shape({
    title: titleRequired,
    description: validationTextarea,
    free: Yup.bool(),
    price: Yup.number()
      .typeError(t('GALLERY_PRICE_ERROR'))
      .min(1, t('GALLERY_PRICE_ERROR'))
      .max(500, t('GALLERY_PRICE_ERROR')),
    category: Yup.array(),
  });
};

