import React from 'react';
import { useIntl } from 'react-intl';

function ZipCodeSelect({
  user = {},
  publicZip = '',
  asSelect,
  // Dispatch used here so that it is not passed along with '...otherProps' since <select> does not support it
  dispatch,
  ...otherProps
}) {
  const intl = useIntl();
  const zipOptions = [
    { label: intl.formatMessage({ id: 'PLEASE_SELECT' }), value: '' },
    { label: '0xxxx', value: '0xxxx' },
    { label: '1xxxx', value: '1xxxx' },
    { label: '2xxxx', value: '2xxxx' },
    { label: '3xxxx', value: '3xxxx' },
    { label: '4xxxx', value: '4xxxx' },
    { label: '5xxxx', value: '5xxxx' },
    { label: '6xxxx', value: '6xxxx' },
    { label: '7xxxx', value: '7xxxx' },
    { label: '8xxxx', value: '8xxxx' },
    { label: '9xxxx', value: '9xxxx' },
  ];
  if (zipOptions.includes(publicZip)) {
    zipOptions.push({ label: publicZip });
  }

  const Component = asSelect;
  return <Component options={zipOptions} {...otherProps} />;
}

export default ZipCodeSelect;

