import api from '../../../utils/api';
import { ATTACHMENT_DELETE } from '../../actions';

export default (payload) => async (dispatch) => {
  if (!payload) {
    return;
  }
  try {
    await api.delete(`/user/attachment/${payload}`);
  } catch (error) {
    return dispatch({
      type: ATTACHMENT_DELETE,
      error,
    });
  }
  dispatch({
    type: ATTACHMENT_DELETE,
    payload,
  });
};
