export default {
  ROUTE_FMOTD_HEADLINE:
    'Nutze die Gelegenheit, die Aufmerksamkeit von 100.000 Nutzern auf Dich zu ziehen',
  FMOTD_INFO_HEADLINE_1: 'Dein Hotspot zu täglich 100.000+ Usern',
  FMOTD_INFO_TEXT_1:
    'Sichere Dir jetzt einen der wichtigsten Werbe Hotspots in unserer Community! <span class="italic">Free Movie of the Day</span> ermöglicht es Dir täglich mehr als 10.000 Neukunden und weit mehr als 100.000 täglich aktive Bestandskunden zu erreichen. Absolut prominent und exklusiv!',
  FMOTD_INFO_TEXT_2:
    'Bewirb Dich mit einem Premium Movie* und sichere Dir den begehrten Werbe Hotspot in Form eines XXL Banners direkt auf der Startseite und für weitere 14 Tage im <span class="italic">Free Movie of the Day<span> Bereich.',
  FMOTD_INFO_TEXT_3:
    '<span class="bold">Und das Beste:</span> Du kannst Dich beliebig oft bewerben. Der beste Content erhält den Zuschlag!',
  FMOTD_INFO_TEXT_4:
    'Keine Zeit und kein Geld verschenken! Jetzt hier für <span class="italic">Free Movie of the Day</span> bewerben!',
  FMOTD_INFO_TEXT_5:
    '* Die Bewerbung muss ein kostenpflichtiges und hochauflösendes Premium Movie mit einer Mindestlauflänge von 5 Minuten enthalten. Dieses Movie wird bei erfolgreicher Bewerbung für volle 14 Tage kostenfrei im <span class="italic">Free Movie of the Day</span> Bereich bereitgestellt.',
  FMOTD_INFO_TEXT_6:
    'Ob und zu welchem Zeitpunkt das Video für den <span class="italic">Free Movie of the Day</span> Bereich akzeptiert wird, obliegt vollständig der Entscheidungsfreiheit des Portalbetreibers. Wir behalten uns das Recht vor Bewerbungen kommentarlos abzulehnen. Bei Fragen hilft unser Support gerne weiter.',
  FMOTD_NO_GALLERY_SET: `Keine Galerie gesetzt`,
  FMOTD_APPLY_NOW: `Jetzt bewerben`,
  FMOTD_TABLE_HEADLINE: `Bewirb Dich jetzt für Free Movie of the Day`,
  FMOTD_TABLE_HEADLINE_SUB:
    '<span class="bold">Hinweis:</span> Du kannst nur Galerien auswählen, die veröffentlicht sind und nicht kostenlos angeboten werden.',
  FMOTD_BADGE_ARCHIVE: 'Free Movie of the Day',
  FMOTD_BADGE_CURRENT: 'Aktuelles Free Movie of the Day',
  FMOTD_BADGE_SCHEDULE: 'Geplant',
  FMOTD_BADGE_APPLIED: 'In Prüfung',
  FMOTD_VIEWS: 'Views',
  FMOTD_USERS: 'User',
  FMOTD_MENU_DELETE: 'Bewerbung löschen',
  FMOTD_MENU_EDIT: 'Bewerbung bearbeiten',
  FMOTD_AVAILABLE_GALLERIES: 'Verfügbare Galerien',
  FMOTD_NO_GALLERIES: 'Keine Galerie verfügbar! Bitte checke Deine {link}.',
  FMOTD_GALLERIES: 'Galerien',
  FMOTD_APPLY: 'Bewerben',
  FMOTD_REPLACE: 'Ersetze:',
  FMOTD_DECLINED: 'Deine Bewerbung wurde abgelehnt',
  FMOTD_NO_THUMBNAIL: 'Bitte {link} ein neues Galerievorschaubild hoch!',
  FMOTD_NO_THUMBNAIL_UPLOAD: 'lade',
};
