export default {
  SNAPSHOT_PROFILE_PICTURE_TITLE: `Profilbild - Bild aufnehmen`,
  SNAPSHOT: `Aufnahme`,
  TAKE_SNAPSHOT: 'Erstelle ein Bild mit Deiner Webcam',
  UPLOADER_PICTURE_CROPPER_TEXT_1: `Hier kannst Du den genauen Ausschnitt aus Deinem Bild wählen.`,
  UPLOADER_PICTURE_CROPPER_TEXT_2: `In unseren Communities werden die Formate 1:1, 4:3 und 16:9 verwendet. Es empfiehlt sich Bilder im Querformat zu nutzen.`,
  UPLOADER_PICTURE_CROPPER_TEXT_3: `Das Rahmenwerkzeug ermöglicht Dir den besten Bereich Deines Bildes auszuwählen.`,
  UPLOADER_PICTURE_CROPPER_TEXT_4: `Du kannst den Rahmen im Ganzen beliebig verschieben und von der minimalen Größe, durch Festhalten an den Ecken, auf das Maximum großziehen.`,

  GALLERY_PREVIEW_IMAGE: `Vorschaubild`,
  GALLERY_UPLOADER_HEADLINE: `Ziehe hier Deine Fotos oder ein Zip Archiv hin`,
  GALLERY_VIDEOS_UPLOADER_HEADLINE: `Ziehe hier Dein Video hin`,
  GALLERY_VIDEOS_UPLOADER_TEXT_1: `Lade ein Video hoch im mp4, avi, mpg, oder wmv Format.`,
  GALLERY_UPLOADER_TEXT_1: `Fotos in Deiner Galerie müssen mindestens 800 x 600 Pixel groß sein.`,
  GALLERY_UPLOADER_TEXT_2: `Das Vorschaubild braucht mindestens eine Auflösung von 1024 x 576 Pixel.`,

  UPLOAD_COMMENT: `Kommentar:`,
  BUTTON_CHOOSE_FILE: `Datei wählen`,
  BUTTON_OPEN_CAMERA: `Kamera öffnen`,
  BUTTON_ZIP_ARCHIVE: `ZIP Archiv`,
  BUTTON_CROP_IMAGE: `Bild zuschneiden`,
  BUTTON_UPLOADER_UPLOAD_NOW: `Jetzt hochladen`,
  BUTTON_UPLOAD_IMAGE: `Bild hochladen`,
  BUTTON_UPLOAD_TAKE_IMAGE: `Bild benutzen`,
  BUTTON_REVERT: `Abbrechen`,
  UPLOAD_DROP_INDICATOR: `Ziehe Deine Datei hier hin`,
};
