export default {
  ATTACHMENTS_HEADLINE: `What are file attachments?`,
  ATTACHMENTS_CONTENT_1: `File attachments can be uploaded under {link} (green icon in your main menu). You have the option of storing up to 25 files in your account. Please note that the name of the file is visible to the customer at all times. Check your file attachments regularly and replace the images if necessary so that your customers always receive the latest images of you.`,
  ATTACHMENTS_CONTENT_1_LINK: `Money Makers - Attachments`,
  ATTACHMENTS_CONTENT_1_1: `What advantages do the file attachments offer you?`,
  ATTACHMENTS_CONTENT_1_2: `
    <ul class='checklist'>
      <li>You can use file attachments to increase the response rate to your icebreakers by attaching an image or video to one or more icebreakers. It makes sense to use at least one file on the "Picture Icebreaker" in order to send a current picture of you if necessary.</li>
      <li>You can use free file attachments to give a customer a special sense of appreciation.</li>
    </ul>`,

  ATTACHMENTS_HEADLINE_2: `Paid file attachments`,
  ATTACHMENTS_CONTENT_2: `As soon as you have reached a sufficient chat ranking and there are no complaints from customers, the premium file attachments will be activated for you automatically. This function opens up completely new possibilities for you.`,
  ATTACHMENTS_CONTENT_2_1: `What are the benefits of premium file attachments?`,
  ATTACHMENTS_CONTENT_2_2: `
    <ul class='checklist'>
      <li>With the premium file attachments you can respond individually to the wishes and desires of your customers.</li>
      <li>You can upload the file directly in the Messenger, set your desired price and send it to your customer.</li>
      <li>A premium file attachment can be an image or a video as long as it does not exceed 100 MB in size.</li>
      <li>Open up new earning opportunities with premium file attachments. You can expand your offer and offer additional media such as pictures, videos of you, which you can sell at a price you set.</li>
    </ul>`,
  ATTACHMENTS_CONTENT_2_3: `Important:`,
  ATTACHMENTS_CONTENT_2_4: `
    <ul>
      <li>The premium file attachment is not intended to bother your customers unsolicited with masses of file attachments (spam) and to ask them to buy without being asked. You can't send them premium file attachments until you're in an active correspondence with them.</li>
      <li>Do not send any media that you already offer in your galleries. The customer does not want to buy duplicate pictures or videos from you. This may even have a negative impact on your chat ranking.</li>
      <li>As soon as you actively use this feature, you will see your sales quota in your dashboard under "My performance". If your sales rate falls below 50% at the end of the month, the feature will be automatically deactivated at the beginning of the month. In this case, you can contact the support and discuss what changes are required to get this feature back.</li>
    </ul>`,
};
