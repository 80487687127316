import { user as userUtils } from '../../utils';
import api from '../../utils/api';
import { addSexIcons } from '.';

export default () => async (dispatch, getState) => {
  try {
    if (!userUtils.userLoggedInAndConfirmed(getState())) return;
    const response = await api.get('/data/sexicons');

    if (!userUtils.userLoggedInAndConfirmed(getState())) return;
    dispatch(addSexIcons(response.data));
  } catch (err) {
    console.log({ sexIconError: err });
    console.log('could not load sexicons');
  }
};

