import { useIntl } from 'react-intl';

function useTranslateTableTitles(titles) {
  const intl = useIntl();

  return titles.map((t) => {
    if (t.title === '') {
      return { ...t };
    }
    return { ...t, title: intl.formatMessage({ id: t.title }) };
  });
}

export default useTranslateTableTitles;
