import attachments from './attachments';
import icebreaker from './icebreaker';

export default {
  ...attachments,
  ...icebreaker,

  MONEYMAKERS_HEADLINE:
    'Do not miss a chance to generate a paying customer from any user',
};
