import { useIntl } from 'react-intl';

function UserProfileList({
  label,
  list,
  keyPrefix,
  prefix = '',
  postfix = '',
  display = false,
  translate = true,
}) {
  const intl = useIntl();
  const displayItem = display || list?.length;
  return displayItem ? (
    <>
      <tr className="no-border">
        <td className="bold">{intl.formatMessage({ id: label })}</td>
      </tr>
      <tr>
        <td>
          {!list?.length ? (
            intl.formatMessage({ id: 'not.specified' })
          ) : (
            <ul>
              {list.map((el, i) => {
                const item = `${prefix}${el}${postfix}`;
                return (
                  <li key={`${keyPrefix}${i}`}>
                    {translate && item ? intl.formatMessage({ id: item }) : el}
                  </li>
                );
              })}
            </ul>
          )}
        </td>
      </tr>
    </>
  ) : null;
}

export default UserProfileList;
