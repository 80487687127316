import { CONNECT_SOCKET_SERVICE } from '../actions/actions';
import { SOCKET_SERVICE_STEP_NOT_CONNECTED } from '../constants';

const initialState = {
  step: SOCKET_SERVICE_STEP_NOT_CONNECTED,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CONNECT_SOCKET_SERVICE:
      return { ...action.payload };
    default:
      return state;
  }
};

export default reducer;
