import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import useMediaQuery from '../../../../hooks/useMediaQuery';
import PageSection from '../../../PageSection/PageSection';
import MyVirtualGiftsTable from './MyVirtualGiftsTable';

function MyVirtualGifts({ openDialog }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');

  const className = classNames('gifts-container', 'account-container', {});

  return (
    <PageSection
      className={className}
      containsHeader={true}
      rightColumn={
        isLargeScreen ? <MyVirtualGiftsTable openDialog={openDialog} /> : null
      }
    >
      <div className="text-box">
        <h2 className="headline">
          <FormattedMessage id="MY_VIRTUAL_GIFTS_INFO_HEADER" />
        </h2>
        <p>{intl.formatMessage({ id: 'MY_VIRTUAL_GIFTS_INFO_TEXT' })}</p>
        <p>{intl.formatMessage({ id: 'MY_VIRTUAL_GIFTS_INFO_TEXT_1' })}</p>
      </div>
      {!isLargeScreen ? <MyVirtualGiftsTable openDialog={openDialog} /> : null}
    </PageSection>
  );
}

export default MyVirtualGifts;

