export default {
  VOICE_MESSAGE_HEADLINE: `Voice messages now available on LiveCreator`,
  VOICE_MESSAGE_INTRO: `Everyone knows them from everyday life: Voice messages have long accompanied us privately in "everyday messenger life" and it is hard to imagine life without them. No desire or time to write, a personal greeting or just want to hear the voice of the other. Voice messages make it possible and enrich every chat.`,
  VOICE_MESSAGE_INTRO_1: `From now on, we also allow you and your premium customers to send voice messages in the chat. This offers you many advantages for customer loyalty and opportunities to increase sales!`,
  VOICE_MESSAGE_CONTENT: `The most important information`,
  VOICE_MESSAGE_CONTENT_1: `
    <ul class='checklist'>
      <li>Customers will send you voice messages up to 10 seconds long for a fee.</li>
      <li>The price for a voice message corresponds to your set message price.</li>
      <li>Customers’ voice messages can also be output directly in text form. So you don't have to listen to voice messages and can react immediately!</li>
      <li>Creators send voice messages for free, of course!
Your voice messages to customers can even be up to 20 seconds long.</li>
      <li>\t
Intuitive integration in the Multi Messenger</li>
    </ul>`,
  VOICE_MESSAGE_CONTENT_2: `Advantages and possibilities`,
  VOICE_MESSAGE_CONTENT_3: `The power of the voice`,
  VOICE_MESSAGE_CONTENT_3_1: `Your voice has a unique identity and conveys emotions that are quickly lost in text form. Voice messages bring a piece of you to the customer, no matter where they are.`,
  VOICE_MESSAGE_CONTENT_4: `Versatile usage options`,
  VOICE_MESSAGE_CONTENT_4_1: `Do you have a hot story in mind that wouldn't work if you were writing it, or do you just want to send a seductive greeting? With a voice message, you can add your own personal touch to your words.`,
  VOICE_MESSAGE_CONTENT_5: `Increased engagement rate and customer retention`,
  VOICE_MESSAGE_CONTENT_5_1: `Customers feel flattered to hear your voice and mood. This fosters long and lasting conversations and strengthens the bond with your customers.`,
  VOICE_MESSAGE_CONTENT_6: `Alternative to Livecam`,
  VOICE_MESSAGE_CONTENT_6_1: `Voice messages as a supplement to chat, enable a more intense and intimate experience to pure text chat and thus an alternative to livecam interaction.`,
  VOICE_MESSAGE_CONTENT_7: `Get started now`,
  VOICE_MESSAGE_CONTENT_7_1: `The Voice messages feature is now available to you and most customers, with a few exceptions. For customers, availability depends on the portal used. Surprise and delight your customers directly with a personal voice message and take your chat revenue to a new level!`,

  VOICE_MESSAGE_CONTENT_STEP_1: `Record`,
  VOICE_MESSAGE_CONTENT_STEP_1_CONTENT: `In the input area of the Multi Messenger you will now find a button with which you can start recording a voice message.`,
  VOICE_MESSAGE_CONTENT_STEP_1_1_CONTENT: `After recording, you can listen to the message again, delete it or send it.`,
  VOICE_MESSAGE_CONTENT_STEP_2: `User Message`,
  VOICE_MESSAGE_CONTENT_STEP_2_CONTENT: `You can listen to the message as usual using the Play button. If you would prefer to read the message, use the text conversion button.`,
  VOICE_MESSAGE_CONTENT_STEP_2_1_CONTENT: `Please note:`,
  VOICE_MESSAGE_CONTENT_STEP_2_2_CONTENT: `Text conversion only works reliably if the user has spoken clearly.`,
};
