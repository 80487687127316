export const ICON_DATING_USER = 'ICON_DATING_USER';
export const ICON_DATING_USER_MOBILE = 'ICON_DATING_USER_MOBILE';
export const ICON_AC_EU = 'ICON_AC_EU';
export const ICON_AC_EU_MOBILE = 'ICON_AC_EU_MOBILE';
export const ICON_AC = 'ICON_AC';
export const ICON_AC_MOBILE = 'ICON_AC_MOBILE';
export const ICON_ACNG = 'ICON_ACNG';
export const ICON_ACNG_CLEAN = 'ICON_ACNG_CLEAN';
export const ICON_ACNG_MOBILE = 'ICON_ACNG_MOBILE';
export const ICON_ADMIN = 'ICON_ADMIN';

export const TEXT_AC = 'AmateurCommunity.com';
export const TEXT_ADMIN = 'Admin';
export const TEXT_DATING = 'Dating';
export const TEXT_ACNG = 'ACNG';
export const TEXT_AC_EU = 'AmateurCommunity';

export const TYPE_AC_EU = 'TYPE_AC_EU';
export const TYPE_DATING = 'TYPE_DATING';
export const TYPE_ACNG = 'TYPE_ACNG';
export const TYPE_ADMIN = 'TYPE_ADMIN';
