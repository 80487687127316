import { connect } from 'react-redux';

import {
  closeOverlay,
  openOverlay,
  selectWebcam,
  uploadFile,
} from '../../actions';
import Cam from './Cam';

const mapStateToProps = (state) => ({
  selectedCam: state.webcam.selectedCam,
});

const mapDispatchToProps = {
  selectWebcam,
  openOverlay,
  closeOverlay,
  uploadFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cam);
