export default {
  VOICE_MESSAGE_HEADLINE: `Sprachnachrichten jetzt auch bei LiveCreator`,
  VOICE_MESSAGE_INTRO: `Jeder kennt sie aus dem Alltag: Sprachnachrichten begleiten uns schon lange privat im „Messenger-Alltag“ und sind kaum noch wegzudenken. Keine Lust oder Zeit zu schreiben, ein persönlicher Gruß oder einfach mal die Stimme des anderen hören wollen. Sprachnachrichten machen es möglich und bereichern jeden Chat.`,
  VOICE_MESSAGE_INTRO_1: `Ab sofort ermöglichen wir auch Dir und Deinen Premium Kunden Sprachnachrichten im Chat zu versenden. Das bietet Dir viele Vorteile für die Kundenbindung und Möglichkeiten zur Umsatzsteigerung!`,
  VOICE_MESSAGE_CONTENT: `Die wichtigsten Infos`,
  VOICE_MESSAGE_CONTENT_1: `
    <ul class='checklist'>
      <li>Kunden senden Dir kostenpflichtig Sprachnachrichten mit einer Länge bis zu 10 Sekunden.</li>
      <li>Der Preis für eine Sprachnachricht entspricht dabei Deinem eingestellten Message-Preis.</li>
      <li>Die Sprachnachrichten der Kunden können zusätzlich direkt in Textform ausgegeben werden. Du musst Sprachnachrichten also nicht abhören und kannst direkt reagieren!</li>
      <li>Creator versenden Sprachnachrichten natürlich kostenfrei! Deine Sprachnachrichten an Kunden dürfen sogar eine Länge bis zu 20 Sekunden haben.</li>
      <li>Intuitive Einbindung im Multi Messenger</li>
    </ul>`,
  VOICE_MESSAGE_CONTENT_2: `Vorteile und Möglichkeiten`,
  VOICE_MESSAGE_CONTENT_3: `Die Kraft der Stimme`,
  VOICE_MESSAGE_CONTENT_3_1: `Deine Stimme hat eine einzigartige Identität und vermittelt Emotionen, die in Textform schnell verloren gehen. Sprachnachrichten bringen ein Stück von Dir zum Kunden, egal, wo sie sich befinden.`,
  VOICE_MESSAGE_CONTENT_4: `Vielseitige Nutzungsmöglichkeiten`,
  VOICE_MESSAGE_CONTENT_4_1: `Hast Du eine heiße Geschichte im Kopf die beim Schreiben nicht wirken würde oder möchtest Du einfach nur einen verführerischen Gruß senden? Mit einer Sprachnachricht verleihst Du Deinen Worten Deine ganz persönliche Note.`,
  VOICE_MESSAGE_CONTENT_5: `Erhöhte Engagement-Rate und Kundenbindung`,
  VOICE_MESSAGE_CONTENT_5_1: `Kunden fühlen sich geschmeichelt Deine Stimme und Stimmung zu hören. Dies fördert lange und nachhaltige Konversationen und stärkt die Bindung zu Deinen Kunden.`,
  VOICE_MESSAGE_CONTENT_6: `Alternative zur Livecam`,
  VOICE_MESSAGE_CONTENT_6_1: `Sprachnachrichten als Ergänzung zum Chat, ermöglichen eine intensivere und intimere Erfahrung zum reinen Textchat und somit eine Alternative zur Livecam-Interaktion.`,
  VOICE_MESSAGE_CONTENT_7: `Jetzt loslegen`,
  VOICE_MESSAGE_CONTENT_7_1: `Das Feature Sprachnachrichten steht Dir und den meisten Kunden, mit wenigen Ausnahmen, ab sofort zur Verfügung. Für Kunden ist die Verfügbarkeit abhängig vom genutzten Portal. Überrasche und begeistere Deine Kunden am besten direkt mit einer persönlichen Sprachnachricht und bringe Deinen Chatumsatz auf ein neues Level!`,

  VOICE_MESSAGE_CONTENT_STEP_1: `Aufnahme`,
  VOICE_MESSAGE_CONTENT_STEP_1_CONTENT: `Im Eingabebereich des Multi Messenger findest Du jetzt einen Button mit dem Du die Aufnahme einer Sprachnachricht starten kannst.`,
  VOICE_MESSAGE_CONTENT_STEP_1_1_CONTENT: `Nach der Aufnahme kannst Du Dir die Nachricht noch einmal anhören, sie löschen oder abschicken.`,
  VOICE_MESSAGE_CONTENT_STEP_2: `User Nachricht`,
  VOICE_MESSAGE_CONTENT_STEP_2_CONTENT: `Über den Play Button kannst Du wie gewohnt die Nachricht abhören. Möchtest Du die Nachricht lieber lesen, dann nutze den Button zur Text-Konvertierung.`,
  VOICE_MESSAGE_CONTENT_STEP_2_1_CONTENT: `Bitte beachte:`,
  VOICE_MESSAGE_CONTENT_STEP_2_2_CONTENT: `Die Textkonvertierung funktioniert nur zuverlässig, wenn der User deutlich gesprochen hat.`,
};
