import React from 'react';
function CountriesSelect({
  countries = [],
  user = {},
  asSelect,
  // Dispatch used here so that it is not passed along with '...otherProps' since <select> does not support it
  dispatch,
  ...otherProps
}) {
  const Component = asSelect;
  return <Component options={countries} {...otherProps} />;
}

export default CountriesSelect;
