import { uuid } from '../../utils';
import api from '../../utils/api';
import { PREFERENCES_ADD } from '../actions';

export default (sound, type) => async (dispatch, getState) => {
  const { preferences } = getState();

  const sounds = {
    ...preferences.sounds,
    [sound]: {
      type,
      active: true,
    },
  };

  const data = {
    ...preferences,
    sounds,
  };

  try {
    await api.put('/user/preferences', { data });

    dispatch({
      type: PREFERENCES_ADD,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PREFERENCES_ADD,
      payload: getState().preferences,
      error: true,
      meta: { stamp: uuid() },
    });
    console.log({ error });
  }
};
