export default {
  COMMENTS_ANSWER_DESCRIPTION:
    'Write your comment and click the send button. The comment must contain at least 16 characters.',
  COMMENTS_ANSWER_HEADLINE: 'Write a comment',
  COMMENTS_DATE: 'Date',
  COMMENTS_FORM_LABEL: 'Comment',
  COMMENTS_FROM: 'from',
  COMMENTS_GALLERY_TITLE_NOT_AVAILABLE: 'No English gallery title',
  COMMENTS_INFO_HEADLINE_1: 'Manage your Gallery Comments',
  COMMENTS_INFO_TEXT_1: `In this overview, you can see all user comments on your content galleries. You can reply to positive posts directly within the gallery with your own comment. Your comment will then also be visible to all users. Alternatively, you can also write to users who have left a comment directly via Messenger.`,
  COMMENTS_INFO_TEXT_2: `Of course, you can also delete negative, inappropriate or unnecessary comments directly.`,
  COMMENTS_INFO_TEXT_3: `<span class="bold">Tip:</span> Only customers can write comments. These users have already bought from you and will potentially do so again! Use positive comments for communication! Show your gratitude via a comment or personally with a message. Also, ask about alternative desires and fantasies for future content. This promotes interaction, shows that you are actively responding to customers and has a positive effect on the number of new user contacts and your sales.`,
  COMMENTS_MENU_ANSWER: 'Write a comment',
  COMMENTS_MENU_DELETE: 'Delete comment',
  COMMENTS_NO_COMMENTS_AVAILABLE: 'No comments available',
  COMMENTS_TABLE_HEADLINE: 'Your latest comments',
  COMMENTS_TEXT: 'Gallery and Comment',
  COMMENTS_USER: 'User',
  ROUTE_MY_COMMENTS: 'Comments',
  VALIDATION_COMMENTS_TOO_LONG: 'Your comment has to be {n} characters at most',
  VALIDATION_COMMENTS_TOO_SHORT:
    'Your comment has to be at least {n} characters',
};
