import useAxios from 'axios-hooks';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactSVG } from 'react-svg';

import statusIcon from '../../../../assets/img/svg/on-offline.svg';
import { PAGINATION_PAGE_SIZE } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { getPortal } from '../../../../utils/portals';
import Pagination from '../../../Pagination/Pagination';
import Scrollbar from '../../../Scrollbar/Scrollbar';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import DateItem from '../../../Tables/TableItems/DateItem';
import {
  fullCustomersTableTitles,
  mediumCustomersTableTitles,
  mobileCustomersTableTitles,
} from './ReferredCustomers.functions';

function ReferredCustomers({ openDialog, online }) {
  const intl = useIntl();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const isMediumScreen = useMediaQuery('(min-width: 1024px)');

  const paginationSize = PAGINATION_PAGE_SIZE;
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [portalIds, setPortalIds] = useState([]);
  const [portalNames, setPortalNames] = useState();

  const [total, setTotal] = useState(0);
  const [referredCustomers, setReferredCustomers] = useState(null);
  const [{ loading }, referredCustomersReq] = useAxios(
    {
      url: `/user/customers/referred?l=${paginationSize}&o=${offset}`,
    },
    { manual: true }
  );

  useEffect(() => {
    referredCustomersReq()
      .then((response) => {
        setPortalIds(
          response?.data?.data.map((item) => item.user.split('-')[0])
        );
        setReferredCustomers(response?.data?.data);
        setTotal(response?.data?.total);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [referredCustomersReq]);

  const translatedTitles = isLargeScreen
    ? fullCustomersTableTitles
    : isMediumScreen
    ? mediumCustomersTableTitles
    : mobileCustomersTableTitles;

  useEffect(() => {
    async function portals() {
      let array1 = [];
      for (let i = 0; i < portalIds.length; i++) {
        const response = await getPortal(portalIds[i]);
        array1.push(response);
      }
      setPortalNames(array1);
    }
    portals();
  }, [portalIds]);

  return (
    <Scrollbar visible={!isLargeScreen}>
      <div className="stats-wrapper customers-wrapper">
        <Scrollbar
          visible={isLargeScreen}
          isLargeScreen={isLargeScreen}
          containsHeader={true}
        >
          <div className="data-wrapper">
            <div className="table-box">
              <h1 className="headline">
                <FormattedMessage id="HEADLINE_MY_REFERRED_CUSTOMERS" />
              </h1>

              {loading ? (
                <SpinnerComponent />
              ) : (
                <>
                  <table className="my-stats noHover">
                    <thead>
                      <tr>
                        {translatedTitles.map((title, index) => (
                          <th
                            style={{
                              width: title.width,
                              textAlign: title.position,
                            }}
                            key={`${title}.${index}`}
                          >
                            <FormattedMessage id={title.title} />
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {referredCustomers?.map((item, index) => {
                        let isOnline = online?.includes(
                          item?.user?.replace('-', '@')
                        )
                          ? 'online'
                          : 'offline';
                        return (
                          <tr key={`${item.key}.${index}`}>
                            <td>
                              <DateItem data={item?.created} />
                            </td>
                            <td className={`user ${isOnline}`}>
                              <ReactSVG src={statusIcon} wrapper="span" />
                              <Link
                                onClick={() =>
                                  openDialog(item.user, item.nickname)
                                }
                                to="/multi-messenger"
                              >
                                {item.nickname}
                              </Link>
                            </td>

                            <td>{portalNames[index]?.whitelabelShort}</td>

                            {isLargeScreen && <td>{item?.zip_code}</td>}
                            {isMediumScreen && (
                              <>
                                <td>
                                  {item?.country === null ||
                                  item?.country === '-' ||
                                  item?.country === ''
                                    ? ''
                                    : intl.formatMessage({
                                        id: `${item.country}`,
                                      })}
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  <Pagination
                    paginationSize={paginationSize}
                    offset={offset}
                    totalItems={total}
                    pageNumber={pageNumber}
                    setOffset={setOffset}
                    setPageNumber={setPageNumber}
                  />
                </>
              )}
            </div>
          </div>
        </Scrollbar>
        <Scrollbar
          visible={isLargeScreen}
          hasBoxShadow={true}
          containsHeader={true}
        >
          <div className="info-wrapper">
            <h2 className="headline">
              <FormattedMessage id="STATS_INFO_HEADER" />
            </h2>
            <p>{intl.formatMessage({ id: 'STATS_REFERRED_CUSTOMERS' })}</p>
          </div>
        </Scrollbar>
      </div>
    </Scrollbar>
  );
}

export default ReferredCustomers;

