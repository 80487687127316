import { connect } from 'react-redux';

import { toggleVeriffFrame } from '../../actions';
import VeriffBar from './VeriffBar';

const mapStateToProps = (state) => ({
  ...state.veriff,
  locale: state.intl.locale,
  veriffstatus: state.user?.account?.veriff?.status,
});

const mapDispatchToProps = {
  toggleVeriffFrame,
};

export default connect(mapStateToProps, mapDispatchToProps)(VeriffBar);
