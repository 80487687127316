import dayjs from 'dayjs';

export const mobileScrollIntoMap = new Map([
  ['add-images', 'uploader'],
  ['preview', 'preview'],
  ['edit-texts', 'gallery-info-wrapper'],
  ['settings', 'price-toggle-button'],
  ['comments', 'comments-wrapper'],
  ['documents', 'data-actors-document'],
]);

export function getYearOptions() {
  let returnArr = [];

  const minDate = dayjs().year();
  const maxDate = dayjs().year() + 20;

  for (let i = minDate; i < maxDate; i++) {
    returnArr.push({
      label: i,
      value: i,
    });
  }

  return returnArr;
}

export function checkGalleryErrors(gallery, isVideoGallery) {
  let errors = ['GALLERY_ERROR_MISSING'];
  if (!gallery.title?.content) {
    errors.push('GALLERY_ERROR_TITLE');
  }

  if (!gallery.description?.content) {
    errors.push('GALLERY_ERROR_DESCRIPTION');
  }

  if (!gallery.thumbnail?.thumbnail) {
    errors.push('GALLERY_ERROR_PREVIEW');
  }

  if (!gallery.categories?.length) {
    errors.push('GALLERY_ERROR_CATEGORY');
  }

  if (isVideoGallery) {
    if (!gallery.movies?.length) {
      errors.push('GALLERY_ERROR_VIDEO');
    }
  } else {
    if (gallery.pictures?.length < 3) {
      errors.push('GALLERY_ERROR_PHOTOS');
    }
  }

  if (!gallery.documents?.length) {
    errors.push('GALLERY_ERROR_DOCUMENTS');
  }
  if (gallery?.throwError) {
    if (!gallery.documents?.length) {
      // do nothing
    } else {
      errors.push('GALLERY_ERROR_DOCUMENTS');
    }
  }

  return errors.length === 1 ? false : errors;
}

