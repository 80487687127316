export default {
  TIME_MANAGEMENT_HEADLINE: `Time management - tips for more efficiency and success`,
  TIME_MANAGEMENT_INTRO: `Today we dedicate ourselves to the topic of time management and give valuable tips for
more efficiency and success. As a creator, you are your own boss! This has many
advantages, but it also requires a certain amount of organization and self-discipline.
Shooting videos, replying to messages, maintaining the profile ... These are just a few
obvious tasks. In addition, there are organizational tasks, office work and the like. Do you
want to achieve all your goals and still be able to decide for yourself when you do which
task and, above all, when it's time to finish work?
Here are a few tips on time management for you:`,

  TIME_MANAGEMENT_STEP_1_HEADER: `Recognizing and using personal productivity times`,
  TIME_MANAGEMENT_STEP_1_CONTENT: `It is crucial that you become aware of whether you are one of the "early birds" (morning
person) who have their most productive hours in the morning? Or whether you are one of
the "late risers" (night people) who only really get going in the evening. Every person has
individual phases in which they are particularly productive. Use this insight to adjust your
daily routine accordingly and tackle your tasks during productive hours.`,
  TIME_MANAGEMENT_STEP_2_HEADER: `Determine the type of employment`,
  TIME_MANAGEMENT_STEP_2_CONTENT: `Decide whether your work as a creator is full-time or part-time. As a part-time job, you will
not be able to avoid particularly precise planning in order to reconcile both areas. In your
main job, you can be more flexible, but you should still be careful not to let your working
hours get out of hand.`,
  TIME_MANAGEMENT_STEP_3_HEADER: `Set clear goals`,
  TIME_MANAGEMENT_STEP_3_CONTENT: `Before you set your schedule, you should be clear about what you want to achieve. By
setting concrete and measurable goals for the day, week, month, and year, you create a
clear guideline for your tasks. As a result, you will be able to better prioritize your daily
activities while not losing sight of your long-term goals. With a clear goal in mind, it's easier
for you to stay focused and motivated. Therefore, setting goals is key to efficient scheduling
and long-term success.`,
  TIME_MANAGEMENT_STEP_4_HEADER: `Pre-planning and automation`,
  TIME_MANAGEMENT_STEP_4_CONTENT: `Analyze your routine tasks and find out which of them you can automate or pre-schedule. If
you are currently in a creative phase, it is advisable to capture your ideas for future photos
or videos or to research or note down the locations. Once your photo and video galleries are
ready, you can upload them and add a schedule. This way, you can publish your photo and
video galleries on a date you specify. You can also create the images and texts of your next
diary entries in advance, so that you only have to click on "publish" on the respective day.
Use these tools to get big work done ahead of time and make efficient use of quieter
periods. This will give you more freedom for new projects and ideas.`,
  TIME_MANAGEMENT_STEP_5_HEADER: `Prioritize tasks`,
  TIME_MANAGEMENT_STEP_5_CONTENT: `Every task has a different significance and not everything is equally important. You should
therefore decide which tasks are most urgent or important and complete them first. This
way you can ensure that you can effectively pursue and achieve your goals. It is also
advisable to bundle similar tasks and complete them in one go. A good example of this is the
pre-production of several photos or videos in one day. This approach allows you to make the
most of your time and work efficiently. So always remember to set the right priorities and
organize your tasks accordingly.`,
  TIME_MANAGEMENT_STEP_6_HEADER: `Schedule breaks`,
  TIME_MANAGEMENT_STEP_6_CONTENT: `Plan regular breaks into your working day. If you notice that your concentration is waning,
you should not force yourself to continue working, as this can be counterproductive. In order
to remain productive in the long term, it is essential to take short breaks from recovery. Use
these breaks to stay fresh and motivated. However, avoid only quickly answering messages
or completing other tasks during these recovery periods, as this can affect the regeneration
of your mind and body. So treat yourself to conscious time-outs in order to be able to work
effectively and healthily.`,
  TIME_MANAGEMENT_STEP_7_HEADER: `Define fixed times and closing time`,
  TIME_MANAGEMENT_STEP_7_CONTENT: `It is undisputed that the flexibility of being a creator has many advantages. Nevertheless, it
can also be extremely helpful to define clear and fixed working hours. By taking this
measure, you not only create a certain routine, but also give your friends and family the
opportunity to know when you are available for them. In addition, it is advisable to set a
fixed time for your end of the day to ensure a clear separation between work and private
life. This allows you to switch off better and use your recovery phases more effectively.`,
  TIME_MANAGEMENT_CONCLUSION_HEADER: `Result`,
  TIME_MANAGEMENT_CONCLUSION_CONTENT: `So, a balanced schedule as a creator involves a mix of self-awareness, discipline, flexibility,
and strategic planning. With this approach, you can make your work efficient without
neglecting personal well-being and creativity.`,
};
