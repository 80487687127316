import './Stats.scss';

import React from 'react';
import { useIntl } from 'react-intl';

function Stats({ item }) {
  const intl = useIntl();

  return (
    <div className="count-wrapper">
      <span className="badge gray">
        {intl.formatNumber(item?.clicks)}{' '}
        <span>{intl.formatMessage({ id: 'FMOTD_VIEWS' })}</span>
      </span>
      <span className="badge gray">
        {intl.formatNumber(item?.users)}{' '}
        <span>{intl.formatMessage({ id: 'FMOTD_USERS' })}</span>
      </span>
    </div>
  );
}

export default Stats;
