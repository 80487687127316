import { OPTIONS_ADD, USER_LOGOUT } from '../actions/actions';

const initialState = {
  icebreaker_fsk: 'blocked',
};

function options(state = initialState, action) {
  switch (action.type) {
    case USER_LOGOUT:
      return initialState;
    case OPTIONS_ADD:
      return {
        ...state,
        icebreaker_fsk: action.payload.icebreaker_fsk,
      };
    default:
      return state;
  }
}

export default options;
