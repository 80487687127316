export default {
  ROUTE_STATISTICS: 'My stats',
  ROUTE_MY_TOP_CUSTOMERS: 'Get an overview of your customers',
  ROUTE_CUSTOMERS_SUBTITLE: 'Top Customers',
  HEADLINE_MY_BEST_CUSTOMERS: 'My best customers in ',

  ROUTE_REFERRED_CUSTOMERS_SUBTITLE: 'Referred Customers',
  ROUTE_MY_REFERRED_CUSTOMERS: 'Get an overview of your referred customers',
  HEADLINE_MY_REFERRED_CUSTOMERS: 'My referred customers',

  TABLE_TITLE_NO: 'No',
  TABLE_TITLE_CUSTOMER: 'Customer',
  TABLE_TITLE_PORTAL: 'Portal',
  TABLE_TITLE_COUNTRY: 'Country',
  TABLE_TITLE_LAST_ON: 'last on',
  TABLE_TITLE_TURNOVER: 'Turnover',
  TABLE_LABEL_YEAR: 'Year',
  TABLE_LABEL_MONTH: 'Month',
  TABLE_TITLE_TIMESTAMP: 'Timestamp',
  TABLE_TITLE_ZIP: 'ZIP',

  CUSTOMERS_TOTAL_PERIOD: 'total period',

  STATS_INFO_HEADER: 'About this statistic',
  STATS_PERFORMANCE:
    'For reasons of performance the display of this rubric is cached for 5 minutes.',
  STATS_TOP_CUSTOMERS:
    'This is an overview of the top 25 customers who have spent the most coins for you. You can view just a specific month or your entire time at LiveCreator.',

  STATS_GAME:
    'You can find your last 30 games here. Revisit how you won a game or why you lost a match.',
  STATS_GAME_1:
    'Of course, you can also see the coins you have earned and can contact your gaming partner directly.',

  STATS_REFERRED_CUSTOMERS:
    'This is an overview of referred customers. A maximum of the last 500 customers are displayed.',
};

