import bowser from 'bowser';

const serverId = process.env.REACT_APP_CAM_SERVERID || null;
const serviceURL = process.env.REACT_APP_LIVECAM_SERVICE_URL;
const version = process.env.REACT_APP_CAM_VERSION;
const softwareOrigin = process.env.REACT_APP_CAM_SOFTWARE_ORIGIN;
const origin = process.env.REACT_APP_CAM_ORIGIN;

const login = ({ username: sendername, password, token, locale }) => {
  const parser = bowser.parse(window.navigator.userAgent);
  const os = parser.os.name + ' ' + parser.os.versionName;
  const langMapping = {
    de: 'de_DE',
    en: 'en_US',
  };

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      sendername,
      password,
      token,
      origin,
      checksum: '',
      os,
      version,
      airVersion: '',
      web: 1,
      frontend: 'lcr',
      language: langMapping[locale] || 'de_DE',
      windowInfo: {
        height: window.innerHeight,
        width: window.innerWidth,
        maximized: window.screen.availWidth - window.innerWidth === 0,
      },
    }),
  };

  let url = serviceURL + softwareOrigin + '/login';
  // important to test typeof as serverId can be 0
  // serverId can be used to ensure to reach a distinct server mainly for development
  if (serverId) {
    url += '?serverId=' + serverId;
  }

  return fetch(url, requestOptions).then((response) => response.json());
};

const logout = (logoutReason) => {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ logoutReason }),
  };
  return fetch(serviceURL + softwareOrigin + '/logout', requestOptions).then(
    (response) => {
      if (!response.ok) {
        throw new Error(`Logout not ok with status ${response.status}`);
      }
      return response.ok;
    }
  );
};

const auth = () => {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  };
  return fetch(serviceURL + softwareOrigin + '/auth', requestOptions);
};

const toycontrol = (data) => {
  const toggle = data?.toggle;
  const active = data?.active;
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  };

  if (toggle) {
    requestOptions.body = JSON.stringify({ toggle, active });
  }

  return fetch(serviceURL + softwareOrigin + '/toycontrol', requestOptions)
    .then((response) => {
      if (!response.ok) {
        // be silent, if there is no state from api initial will be used
        return Promise.resolve({});
      }
      return response.json();
    })
    .catch((error) => Promise.resolve({}));
};

const toycontrolIntensity = (intensity) => {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ intensity: parseInt(intensity, 10) }),
  };

  return fetch(
    serviceURL + softwareOrigin + '/toycontrolIntensity',
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        // be silent, if there is no state from api initial will be used
        return Promise.resolve({});
      }
      return response.json();
    })
    .catch((error) => Promise.resolve({}));
};

const toycontrolTest = (activeToy) => {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ activeToy }),
  };

  return fetch(serviceURL + softwareOrigin + '/toycontrolTest', requestOptions)
    .then((response) => {
      if (!response.ok) {
        // be silent, if there is no state from api initial will be used
        return Promise.resolve({});
      }
      return response.json();
    })
    .catch((error) => Promise.resolve({}));
};

const server = ({ type }) => {
  let url = serviceURL + softwareOrigin + '/server/' + type;
  if (serverId) {
    url += '?serverId=' + serverId;
  }
  let requestOptions = {
    credentials: 'include',
  };
  requestOptions.method = 'GET';

  return fetch(url, requestOptions).then((response) => {
    if (response.status === 404) {
      return Promise.reject(new Error(`${type} server not found`));
    }
    if (response.status !== 200) {
      return Promise.reject(new Error('Status not 200'));
    }
    return response.json();
  });
};

const store = ({ key }) => {
  const url = serviceURL + softwareOrigin + '/store/' + key;
  let requestOptions = {
    credentials: 'include',
  };

  return {
    get: () => {
      requestOptions.method = 'GET';

      return fetch(url, requestOptions)
        .then((response) => {
          if (!response.ok) {
            // be silent, if there is no state from api initial will be used
            return Promise.resolve({});
          }
          return response.json();
        })
        .catch(() => Promise.resolve({})); // silent
    },
    post: (data) => {
      requestOptions = Object.assign(requestOptions, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });

      return fetch(url, requestOptions);
    },
  };
};
const log = ({ level, message, data = {}, socketId }) => {
  const url = serviceURL + softwareOrigin + '/log';
  const requestOptions = {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data,
      level,
      message,
      socketId,
    }),
  };

  return fetch(url, requestOptions).catch((error) => {
    console.error('Error logging:', error);
  });
};

const logger = (serviceData) => ({
  info: (message, data, socketId) =>
    log({ ...serviceData, level: 'info', message, data, socketId }),
  warn: (message, data, socketId) =>
    log({ ...serviceData, level: 'warn', message, data, socketId }),
  error: (message, data, socketId) =>
    log({ ...serviceData, level: 'error', message, data, socketId }),
});

export default {
  login,
  logout,
  auth,
  store,
  server,
  logger,
  toycontrol,
  toycontrolIntensity,
  toycontrolTest,
};
