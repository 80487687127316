export default {
  ATTACHMENT_UPLOAD: 'Upload',
  ATTACHMENT_TEXT_1:
    'Hier hast Du die Möglichkeit, Deine Dateien zu verwalten, die Du Kunden per Nachricht als Dateianhang mitschicken kannst. Außerdem kannst Du Deine Icebreaker mit Dateianhängen individualisieren.',
  ATTACHMENT_AVAILABLE_SLOTS: `Verfügbare Dateiplätze: <span class="bold">{number}</span>`,
  ATTACHMENT_UPLOADER_LABEL: 'Neues Bild oder Video:',
  AVAILABLE_FILES: 'Verfügbare Dateien ({number})',
  ATTACHMENT_DENIED: 'Diese Datei wurde von uns abgelehnt. Bitte löschen!',
  MESSAGE_ATTACHMENT: 'Anhang',
  MESSAGE_ATTACHMENT_NOT_AVAILABLE: 'Der Anhang ist nicht mehr verfügbar',

  ATTACHMENT_TABLE_HEADER_PREVIEW: 'Vorschau',
  ATTACHMENT_TABLE_HEADER_NAME: 'Name',
  ATTACHMENT_TABLE_HEADER_SIZE: 'Größe',
  ATTACHMENT_TABLE_HEADER_TYPE: 'Format',
  ATTACHMENT_TABLE_HEADER_UPLOADTIME: 'Erstellt am',
  ATTACHMENT_LABEL: 'Dateianhang',
  PREMIUM_ATTACHMENT: 'Kostenpflichtiger Dateianhang',

  DELETE_ATTACHMENT: 'Datei löschen',
  DELETE_ATTACHMENT_FAILED:
    'Das Löschen der Datei schlug fehl, versuche es noch einmal.',
  ATTACHMENT_LABEL_NONE: 'Keine Datei anhängen',
  ADD_ATTACHMENT_LABEL: `Füge Deiner Nachricht einen Anhang hinzu`,
  ATTACHMENTS_NO_FILES: 'Du hast noch keine Dateien hochgeladen.',

  UPLOAD_LABEL: 'Wähle ein Bild oder Video von Deinem Gerät aus',
  UPLOAD: 'Upload:',
  UPLOAD_BTN: 'Hochladen',
  CHOOSE_A_FILE: 'Datei auswählen',
  SET_PRICE: 'Lege Deinen Preis fest:',
  COINS: 'Coins',
  UPLOAD_AND_SEND: 'Hochladen und abschicken',
  SEND_FILE_AGAIN: 'Oder verschicke eine Datei noch einmal',
  SEND: 'Senden',
  FILE_SOLD: `Die Datei ist verkauft`,
  FILE_NOT_SOLD: `Die Datei ist noch nicht verkauft`,
};

