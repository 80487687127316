export default {
  LIVECAM_FAQ_HEADLINE: `Die häufigsten Fragen zur Livecam`,
  LIVECAM_FAQ_QUESTION_1: `Was bedeuten die schwarzen Ränder an meinem Cambild?`,
  LIVECAM_FAQ_ANSWER_1: `Wenn im Vorschaubild links/rechts oder oben/unten schwarze Balken dargestellt werden, musst Du die Videoauflösung anpassen, denn die Balken reduzieren die maximal mögliche Videodarstellung für den User.<br />
  <ul>
    <li>Hast Du ein Seitenverhältnis mit 4:3 eingestellt und oben und unten sind schwarze Balken zu sehen, dann wähle eine ähnliche Auflösung mit Seitenverhältnis 16:9.</li>
    <li>Hast Du ein Seitenverhältnis mit 16:9 eingestellt und links und rechts sind schwarze Balken zu sehen, dann wähle eine ähnliche Auflösung mit Seitenverhältnis 4:3.</li>
  </ul>`,
  LIVECAM_FAQ_ANSWER_1_2: `Bitte achte darauf, dass im Vorschaubild und damit auch im an den User übertragenen Videobild KEINE schwarzen Balken sichtbar sind.`,

  LIVECAM_FAQ_QUESTION_2: `Was bedeuten die weiß-transparenten Ränder an meinem Cambild?`,
  LIVECAM_FAQ_ANSWER_2: `Wenn ein Kunde bei Dir in der Livecam ist, kann es sein, dass er bei sich nicht das ganze Cambild sieht. Damit Du erkennen kannst, was genau er sieht, wird Dir der beim Kunden nicht sichtbare Bildausschnitt weiß-transparent dargestellt. Dieser Bildausschnitt kann (abhängig vom Monitor des Zuschauers) von Kunde zu Kunde unterschiedlich sein, so dass er mit wechselt, wenn Du zwischen den Kunden wechselst. Sollte sich der Bildausschnitt beim Kunden ändern, so ändert er sich nach kurzer Zeit auch in Deiner Vorschau.`,

  LIVECAM_FAQ_QUESTION_3: `Was bedeutet die gelbe / rote Hervorhebung der "Frames/s" über meinem Cambild?`,
  LIVECAM_FAQ_ANSWER_3: `Diese Markierung weist Dich darauf hin, dass Du zu wenig Bilder pro Sekunde überträgst und Dein Stream dadurch eine schlechte Qualität hat. Möglicherweise sieht der User nur ein stockendes, ein verpixeltes oder sogar stehendes Bild.`,
  LIVECAM_FAQ_ANSWER_3_1:
    'Mögliche Maßnahmen, um die Bildqualität zu verbessern:',
  LIVECAM_FAQ_ANSWER_3_1_1:
    'Schließe nicht genutzte Programme, die unnötig Rechenleistung Deines Computers verbrauchen.',
  LIVECAM_FAQ_ANSWER_3_1_2:
    'Überprüfe die Stream-Einstellungen unter {link}. Versuche eine niedrigere Auflösung und entlaste so Deinen Computer.',
  LIVECAM_FAQ_ANSWER_3_2: `Kontaktiere unseren Support für weitergehende Maßnahmen.`,
  LIVECAM_FAQ_ANSWER_3_3: `<span class="bold">Hinweis:</span> Auf kleineren Bildschirmauflösungen blinkt der Hintergrund des Cam Icon in der Chatansicht. Zusätzlich erhältst Du eine Frames/s Warnung.`,

  LIVECAM_FAQ_QUESTION_4: `Was bedeuten die Icons nach dem starten meines Streams?`,
  LIVECAM_FAQ_ANSWER_4: `Du streamst im Normalen Modus.`,
  LIVECAM_FAQ_ANSWER_4_1: `Voyeure können Deine Show betreten.`,
  LIVECAM_FAQ_ANSWER_4_2: `Du bist für den Exklusiv Modus freigeschaltet.`,
  LIVECAM_FAQ_ANSWER_4_3: `Du streamst im Exklusiv Modus. Der Normale und der Voyeur Modus sind in dieser Zeit inaktiv.`,

  LIVECAM_FAQ_QUESTION_5: `Was ist der Exklusiv Modus?`,
  LIVECAM_FAQ_ANSWER_5: `Eine Exklusiv Show kann nur von einem User zur gleichen Zeit besucht werden, sie ist EXKLUSIV für diesen einen User. Den Preis für die Exklusiv Show stellst Du in Deinem Account ein: {link}`,

  LIVECAM_FAQ_QUESTION_6: `Was gibt es beim Wechsel in den Exklusiv Modus zu beachten?`,
  LIVECAM_FAQ_ANSWER_6: `Wechselst Du mit einem Kunden in den Exklusiv Modus, während sich noch weitere User in Deiner Show befinden, werden diese gekickt. Schickt ein User Dir eine Exklusiv Show Anfrage, steht Dir nur eine begrenzte Zeit zur Verfügung, um diese anzunehmen. Nach Ablauf der Zeit gilt die Anfrage als abgelehnt.`,
  LIVECAM_FAQ_ANSWER_6_1: `Lehnst Du eine Exklusiv Show Anfrage des Users ab, so kann er erst nach Ablauf einer bestimmten Zeit erneut anfragen.`,

  LIVECAM_FAQ_QUESTION_7: `Wie kann ich den Exklusiv Modus starten?`,
  LIVECAM_FAQ_ANSWER_7: `Solltest Du einen User in Deiner Livecam haben, so kannst Du ihn zu einer Exklusiv Show einladen. Wähle hierfür den User aus und drücke dann den Button "XXX zu einer Exklusiv Show einladen" im Chat Menü.`,
  LIVECAM_FAQ_ANSWER_7_1: `Einem User, der Deine Anfrage abgelehnt hat, kannst Du keine Exklusiv Show Anfrage mehr schicken.`,

  LIVECAM_FAQ_QUESTION_8: `Was ist der Voyeur Modus?`,
  LIVECAM_FAQ_ANSWER_8: `Im Voyeur Modus können User anonym Deine Show verfolgen, aber nicht mit Dir chatten. Du siehst oberhalb der Userliste die Anzahl der Voyeure, die sich in Deiner Show befinden.`,
  LIVECAM_FAQ_ANSWER_8_1: `Kein Voyeur in Deiner Show`,
  LIVECAM_FAQ_ANSWER_8_2: `2 Voyeure in Deiner Show`,

  LIVECAM_FAQ_QUESTION_9: `Was bedeuten die Symbole vor/nach den Usernamen?`,
  LIVECAM_FAQ_ANSWER_9: `Der User ist neu in Deine Show gekommen.`,
  LIVECAM_FAQ_ANSWER_9_1: `Der User hat Dir eine neue Nachricht geschrieben.`,
  LIVECAM_FAQ_ANSWER_9_2: `Ein Admin ist in Deiner Show.`,
  LIVECAM_FAQ_ANSWER_9_3: `Der User ist mit einem PC in Deiner Show.`,
  LIVECAM_FAQ_ANSWER_9_4: `Der User ist mit einem Mobilgerät in Deiner Show.`,
  LIVECAM_FAQ_ANSWER_9_5: `Dieser User kann Dich nicht hören.`,

  LIVECAM_FAQ_QUESTION_10: `Was gibt es bei Usern zu beachten, die mit einem Mobilgerät im Chat sind?`,
  LIVECAM_FAQ_ANSWER_10: `Diese User verlassen eventuell unerwartet Deine Livecam Show, wenn ihre Mobilfunkverbindung nicht stabil ist.`,

  LIVECAM_FAQ_QUESTION_11: `Warum sehe ich kein Mikrofon Icon (ein/aus) über meinem Cambild?`,
  LIVECAM_FAQ_ANSWER_11: `Du hast noch kein Mikrofon in den {link} gewählt.`,
  LINK_TEXT_STREAM_SETTINGS: `Stream-Einstellungen`,

  LIVECAM_FAQ_QUESTION_12: `Wie kann ich einen User kicken?`,
  LIVECAM_FAQ_ANSWER_12: `Mit Klick auf den Button im User Info Menü.`,
  LIVECAM_FAQ_ANSWER_12_1: `<span class="bold">Achtung!</span> Du darfst nur User kicken, die grob gegen unsere Portalrichtlinien verstoßen oder illegale Handlungen vornehmen! Kickst Du User ohne ausreichenden Grund, kann dies zur Sperrung Deines Livecam Accounts führen!`,

  LIVECAM_FAQ_QUESTION_13: `Wozu ist die Liste mit F1, F2, … in den Optionen?`,
  LIVECAM_FAQ_ANSWER_13: `Du kannst hier Kurztexte eingeben, die Du mit einem Druck auf die entsprechende F-Taste auf Deiner Tastatur abrufen kannst. So bist Du in der Lage, mit nur einem Tastendruck einen ganzen Satz in die Chatleiste zu schreiben, z.B. für eine schnelle Begrüßung. Auch Platzhalter für das automatisierte einsetzen der Uhrzeit und des Usernamens sind möglich.`,
  LIVECAM_FAQ_ANSWER_13_1: `
    <table class="noBorder noHover">
      <thead>
        <tr>
          <td style='width: 100px;' class='bold'>Platzhalter</td>
          <td class='bold'>wird ersetzt durch</td>
        </tr>
      </thead>
      <tr>
        <td>%t%</td>
        <td>Aktuelle Zeit</td>
      </tr>
       <tr>
        <td>%u%</td>
        <td>Name des Kunden</td>
       </tr>
     </table>`,

  LIVECAM_FAQ_QUESTION_14: `Warum kann ich den User vor seiner Webcam nicht sehen?`,
  LIVECAM_FAQ_ANSWER_14: `Wenn ein User sein Webcam-Bild übertragen will, muss er in den Einstellungen der Livecam Show den Zugriff auf seine Cam erlauben.`,

  LIVECAM_FAQ_QUESTION_15: `Wie kann ich die Lautstärke der Benachrichtigungstöne ändern?`,
  LIVECAM_FAQ_ANSWER_15: `In den Sound Einstellungen unter {link} kannst Du die Lautstärke der Töne in mehreren Schritten zwischen 50% (halbe Lautstärke) und 300% (dreifache Lautstärke) einstellen. Die ausgewählte Lautstärke ist für alle Benachrichtigungstöne gleich.`,
};

