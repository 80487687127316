import './LandingPagePanel.scss';

import classNames from 'classnames';
import React from 'react';
import Collapse from 'react-bootstrap/Collapse';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';

import arrowIcon from '../../../../../assets/img/svg/arrow-down.svg';

function LandingPagePanel({
  id,
  title,
  subtitle,
  content,
  isExpanded,
  toggleExpand,
  index,
  faq,
}) {
  const panelClass = classNames('landing-page-panel', {
    expanded: isExpanded[index],
  });

  const subtitleClass = classNames('panel-subtitle', {
    faq,
    open: isExpanded[index],
  });

  return (
    <div className={panelClass} id={id}>
      <div
        className="panel-headline"
        onClick={() => toggleExpand(index)}
        aria-controls="collapse-text"
        aria-expanded={isExpanded[index]}
      >
        <div className="panel-title">
          {' '}
          <div className="title-icon">{isExpanded[index] ? '-' : '+'}</div>
          <FormattedMessage id={title} />
        </div>
        <div className={subtitleClass}>{subtitle}</div>
      </div>
      <Collapse in={isExpanded[index]}>
        <div className="panel-content collapse-text">{content}</div>
      </Collapse>
      <div
        className="toggle-collapse-button"
        onClick={() => toggleExpand(index)}
      >
        <FormattedMessage
          id={isExpanded[index] ? 'PANEL_TOGGLE_LESS' : 'PANEL_TOGGLE_MORE'}
        />
        <ReactSVG src={arrowIcon} wrapper="span" className="arrow-icon" />
      </div>
      {isExpanded[index] && (
        <span onClick={() => toggleExpand(index)}>
          <ReactSVG src={arrowIcon} wrapper="span" className="arrow-up-icon" />
        </span>
      )}
    </div>
  );
}

export default LandingPagePanel;
