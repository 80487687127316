import './CopyInput.scss';

import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import copyIcon from '../../../assets/img/svg/copy.svg';
import Button from '../../Button/Button';

function CopyInput({ id, name, label, ...otherProps }) {
  const intl = useIntl();
  const ref = useRef();
  const translatedLabel = label && intl.formatMessage({ id: label });
  const [clicked, setClicked] = useState(false);
  let timer = null;

  const copyToClipboard = async (e) => {
    try {
      await navigator.clipboard.writeText(ref?.current?.value);
      setClicked(true);
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }
      timer = setTimeout(() => {
        setClicked(false);
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };
  const tooltipClassName = classNames('tooltip-text', {
    'tooltip-visible': clicked,
  });
  return (
    <div className="form-group copy">
      {label && <label htmlFor={id || name}>{translatedLabel}</label>}
      <div className="copy-input-wrapper">
        <div className="copy-input" onClick={copyToClipboard}>
          {
            <span className={tooltipClassName}>
              {intl.formatMessage({ id: 'COPIED' })}
            </span>
          }
          <input
            ref={ref}
            id={id || name}
            name={name}
            disabled
            {...otherProps}
          />
          <Button variant="icon-only" intlTranslate={false} icon={copyIcon} />
        </div>
      </div>
    </div>
  );
}

export default CopyInput;
