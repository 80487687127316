import { UPDATE_LANGUAGES } from '../actions/actions';

const initialState = [];

function languagesReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_LANGUAGES:
      return action.payload;

    default:
      return state;
  }
}

export default languagesReducer;
