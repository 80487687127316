import { connect } from 'react-redux';

import { spinner, userUpdate as update } from '../../../../actions';
import BankDetails from './BankDetails';

const mapDispatchToProps = {
  update,
  spinner,
};

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  user: state.user,
  minimum_payout: state.user.private?.minimum_payout,
  bank_location: state.user.private?.bank_location,
  bank_pax_email: state.user.private?.bank_pax_email,
  bank_holder:
    ((state.user.private?.bank_forename || state.user.private?.bank_surname) &&
      `${
        typeof state.user.private?.bank_forename === 'string'
          ? state.user.private.bank_forename
          : ''
      }  ${
        typeof state.user.private?.bank_surname === 'string'
          ? state.user.private?.bank_surname
          : ''
      }`.trim()) ||
    `${state.user.private.forename} ${state.user.private.surname}`.trim(),
  bank_holder_data: state.user.private?.bank_holder,
  bankholder_document: state.user.private?.bankholder_document,
  usePrivateData: !(
    state.user.private?.bank_forename || state.user.private?.bank_surname
  ),
  private_surname: state.user.private.surname,
  private_forename: state.user.private.forename,
});

export default connect(mapStateToProps, mapDispatchToProps)(BankDetails);

