export default {
  ONBOARDING_TITLE: `Onboarding-Tour`,
  FIRST_STEPS_TITLE: `Erste Schritte`,
  ONBOARDING_HEADLINE_1: `Die folgenden Information benötigen wir zwingend für die Aktivierung Deines Accounts. Du kannst jeden dieser Schritte vorerst überspringen und später vervollständigen.`,
  ONBOARDING_HEADLINE_2: `<span class="bold">Beachte bitte:</span> Deine Angaben werden erst von unserem Support geprüft, sobald Du die Onboarding-Tour abgeschlossen hast!`,
  ONBOARDING_REVIEW_HEADLINE_1: `Unser Support hat ein Problem festgestellt`,
  ONBOARDING_REVIEW_HEADLINE_2: `Unser Support hat mehrere Probleme festgestellt`,
  ONBOARDING_HEADLINE_PENDING_1: `Du hast das Onboarding erfolgreich abgeschlossen.`,
  ONBOARDING_HEADLINE_PENDING_2: `Dein Account wird nun durch unseren Support <span class="bold">geprüft</span>.`,
  FINISHED_HEADLINE: `So geht es weiter`,
  FINISHED_TEXT_1: `Unser Support benachrichtigt Dich nach erfolgter Prüfung Deiner Angaben via E-Mail.`,
  FINISHED_TEXT_2: `Bitte stelle sicher, dass Du folgende E-Mail-Adresse in Deinen Kontakten als bekannt hinterlegst: <a class="bold" href="mailto:support@livecreator.com">support@livecreator.com</a><br />So vermeidest Du, dass unsere E-Mails und Deine Zugangsbenachrichtigung im Spam landen.`,
  HINT_NEED_HELP: `Benötigst Du Hilfe?`,
  HINT_CONTACT: `Kontaktiere gern unseren`,
  HINT_CREATOR_SUPPORT: `Creator Support`,
  ONBOARDING_ITEM_NICKNAME: `Nickname`,
  ONBOARDING_ITEM_PROFILE_PICTURE: `Profilbild`,
  ONBOARDING_ITEM_PROFILE_DETAILS: `Profildaten`,
  ONBOARDING_ITEM_PERSONAL_DATA: `Private Daten`,
  ONBOARDING_ITEM_UPLOAD_DOCUMENTS: `Dokumente`,
  ONBOARDING_ITEM_DOI: `E-Mail Überprüfung`,

  BUTTON_BACK: `zurück`,
  BUTTON_NEXT: `weiter`,
  BUTTON_FINALISE: `abschließen`,

  TOOLTIP_SUPPORT_HEADLINE: 'Hallo!',
  TOOLTIP_SUPPORT: `<p>Ich bin Kathrin, Dein Coach auf LiveCreator.<br />
  Für Fragen zur Onboarding-Tour, den wichtigsten Schritten, Einstellungen oder anderen Fragen zu Deinem Profil stehe ich Dir</p>
  <ul class="bold">
    <li>Dienstags von 10.00 - 11.00 Uhr</li>
    <li>Mittwochs von 16.00 - 17.00 Uhr</li>
    <li>Donnerstags von 14.00 - 15.00 Uhr</li>
  </ul>
  live in <span class="bold">Zoom</span> zur Verfügung. Klicke einfach auf <a class="blue" href="https://us02web.zoom.us/j/89900156510?pwd=am11ME5VVnE4S3BlaWlFZHJCYjFJUT09" target="_blank">diesen Link</a>. </p>
  <p>Beachte bitte, dass der Zoom-Link nur in den oben genannten Zeiträumen funktioniert.</p>
  <p>Viele Grüße und viel Erfolg<br />
  Kathrin</p>`,

  TOOLTIP_NAVIGATION_FINALIZE_1: `Benutze die <span class="bold">"zurück"</span> und <span class="bold">"weiter"</span> Buttons um Deine Eingaben zu speichern und jeden Schritt noch einmal zu kontrollieren.`,
  TOOLTIP_NAVIGATION_FINALIZE_2: `Mit dem <span class="bold">"abschließen"</span> Button gibst Du Deine Daten zur Kontrolle frei.`,
  TOOLTIP_NAVIGATION_FINALIZE_NEED_HELP: `Du benötigst Hilfe?`,
  TOOLTIP_NOW_COMPLETE: `Du bist jetzt fertig`,

  CHOOSE_NICKNAME: `Wähle Deinen Nicknamen`,
  CHOOSE_NICKNAME_SUB: `Mit Deinem Nicknamen erscheint Dein Profil in allen Communities.`,
  MY_NICKNAME: `Mein Nickname:`,
  ONBOARDING_NICKNAME_CONTENT_1: `Tipps zur Wahl Deines Nicknamens:`,
  ONBOARDING_NICKNAME_CONTENT_2: `Der Nickname dient jedem Creator quasi als Künstlername in allen Communities. Somit trittst Du nicht mit Deinem Realnamen in Erscheinung. Der Nickname sollte einprägsam und nicht zu kompliziert sein. Er kann natürlich inhaltlich auch bestimmte Themen und Neigungen von Dir widerspiegeln oder einfach ein Fantasiename sein. Nicht zulässig sind geschützte Namen, Marken und Produkte, sowie illegale Themen.`,

  UPLOAD_PROFILE_PICTURE: `Profilbild Upload`,
  PROFILE_PICTURE_CONTENT_1: `Lade hier ein ansprechendes Bild von Dir für Dein Profil hoch. Dieses ist für alle User sichtbar!`,
  PROFILE_PICTURE_CONTENT_2: `Dein Profilbild darf kein erotisches Motiv sein. Du musst vollständig bekleidet und als Person erkennbar sein (keine reinen Körperteile und keine Nahaufnahmen)!<br />
<span class="bold">Wichtig:</span> Bilder aus dem Internet sind im Profil, wie überall, verboten! Keine fremden Personen auf den Profilbildern!`,
  PROFILE_PICTURE_CONTENT_3: `Profilbilder sind im jpg, jpeg oder png Format erlaubt und benötigen eine Auflösung von <span class="bold">mindestens 1080 x 1080 Pixel</span>.`,
  PROFILE_PICTURE_CONTENT_4: `<span class="bold">Hinweis:</span> Das für die Registrierung benötigte Profilbild wird nicht das einzige mögliche Bild auf Deinem Profil bleiben. Du kannst später noch weitere, auch freizügigere und Bilder mit Teilkörperaufnahmen hochladen, sofern Du das möchtest.`,

  PROFILE_DETAILS: `Wichtige Profilangaben`,
  PROFILE_DETAILS_SUB: `Diese Daten sind für alle Deine Profilbesucher sichtbar.`,
  SELECT_GENDER_DEFAULT: `Geschlecht wählen`,

  PERSONAL_DATA_SUB: `Teile uns hier bitte Deine realen Personendaten mit. Diese erscheinen <span class="bold">nicht</span> öffentlich!`,
  PERSONAL_DATA_DESCRIPTION_CONTENT_1: `<span class="bold">Hinweis:</span> Alle Deine Angaben unterliegen selbstverständlich dem Datenschutz.`,
  PERSONAL_DATA_DESCRIPTION_CONTENT_2: `Damit wir sicherstellen können, dass Du volljährig bist und Deine Anmeldung als Creator auch wirklich von Dir erfolgt ist, gleichen wir Deine Angaben bei der Account Prüfung mit den von Dir hochgeladenen Legitimationsdokumenten ab. Außerdem benötigen wir diese Daten natürlich auch später für die Auszahlung Deiner Provisionen.`,

  UPLOAD_DOCUMENTS_SUB: `Damit wir sicherstellen können, dass Du volljährig bist und Deine Anmeldung als Creator auch wirklich von Dir erfolgt ist, lade hier bitte eine Kopie Deines Personalausweises oder Deines Reisepasses inklusive Meldebescheinigung hoch.`,
  UPLOAD_DOCUMENTS_CONTENT_2: `Achte bitte darauf, dass alle Dokumente aktuell und nicht abgelaufen sind! Ein Führerschein ist kein gültiges Legitimationsdokument! Du kannst Deine Ausweisdokumente als Kopie kennzeichnen. Es müssen dabei aber immer alle Daten und das Ausweisbild deutlich erkennbar sein!`,
  UPLOAD_DOCUMENTS_CONTENT_3: `Lade bitte beide Seiten des Legitimationsdokuments hoch!`,

  DOI_HEADLINE: `Fast geschafft`,
  DOI_TITLE: `Überprüfung Deiner E-Mail-Adresse`,
  DOI_DESCRIPTION_TITLE: `Willkommen bei LiveCreator`,
  DOI_DESCRIPTION_CONTENT_1: `Aktiviere Deinen Account`,
  DOI_DESCRIPTION_CONTENT_2: `Bitte überprüfe Deine E-Mails und klicke auf den Link zur Kontobestätigung, um Deinen Account zu aktivieren.
  <br />Wenn Du in Deinem Posteingang keine E-Mail von uns findest, überprüfe bitte Deinen Spam-Ordner.`,
  DOI_DESCRIPTION_CONTENT_3: `<span class="bold">Hinweis:</span> Deine E-Mail-Adresse unterliegt selbstverständlich dem Datenschutz.`,
  EMAIL_CHANGE_REQUEST: `Hast Du einen Fehler gemacht und möchtest {link}?`,
  EMAIL_CHANGE: `Deine E-Mail-Adresse ändern`,
  EMAIL_CHANGE_NOTIFICATION_1: `Wir senden Dir eine Bestätigungsmail an Deine neue Mailadresse.`,
  EMAIL_CHANGE_NOTIFICATION_2: `Bitte bestätige Deine Mailadresse, indem Du den Link in der Bestätigungsmail drückst und {link}.`,
  EMAIL_RESEND_1: `Hast Du die Bestätigungsmail nicht erhalten?`,
  EMAIL_RESEND_2: `{link} und wir senden sie Dir noch einmal.`,
  EMAIL_RELOAD_HERE: `klicke anschließend hier`,
  EMAIL_VERIFICATION_LABEL: `Deine angegebene E-Mail:`,
  NEW_EMAIL: `Neue E-Mail:`,
  BUTTON_CHANGE: `Ändern`,
};

