import { connect } from 'react-redux';

import { updateIntl } from '../../../actions';
import { default as locales } from '../../../locale';
import { lStorage } from '../../../services/storage';
import LanguageSelect from './LanguageSelect';

const mapDispatchToProps = (dispatch) => ({
  changeLocale: (locale) => {
    lStorage.setItem('lang', locale);
    return dispatch(
      updateIntl({
        locale,
        messages: locales[locale],
      })
    );
  },
});

export default connect(null, mapDispatchToProps)(LanguageSelect);
