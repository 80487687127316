import api from '../../../utils/api';
import { addIdsFavorite } from '..';

export default () => async (dispatch, getState) => {
  try {
    let favoriteIds = [];
    const favoriteResponse = await api.get(`/user/favorites/ids`);
    if (!getState().user?.account?.id) {
      return;
    }
    if (favoriteResponse?.data?.length > 0) {
      favoriteIds = favoriteResponse.data.map((el) => el.replace('-', '@'));
    }
    dispatch(addIdsFavorite(favoriteIds));
  } catch (err) {
    console.log({ fetchFavoriteIdsError: err });
  }
};
