import api from '../../utils/api';
import { addAttachment } from '../';

export default () => async (dispatch, getState) => {
  try {
    if (
      !getState().user?.isLoggedIn ||
      !getState().user?.account?.state === 'confirmed'
    )
      return;
    const response = await api.get('/user/attachments');

    if (
      !getState().user?.isLoggedIn ||
      !getState().user?.account?.state === 'confirmed'
    )
      return;
    dispatch(addAttachment(response.data));
  } catch (err) {
    console.log({ attachmentError: err });
    console.log('could not load attachments');
  }
};
