import { connect } from 'react-redux';

import { setIntensityToy, testToy } from '../../actions';
import ToySlider from './ToySlider';

const mapStateToProps = (state) => ({
  intensity: state.sender?.toycontrol?.intensity,
  activeToy: state.sender?.toycontrol?.activeToy,
});

const mapDispatchToProps = {
  setIntensityToy,
  testToy,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToySlider);
