import './LinkItem.scss';

import classNames from 'classnames';
import React from 'react';

function LinkItem({ data = '', ...props }) {
  const status = props.status ? props.status : props.state;
  const tableItem = classNames('link-item item-content', {
    'disabled-item': status === 'pending' || status === 'rejected',
  });
  return (
    <div className={tableItem} style={props.style}>
      {data}
    </div>
  );
}

export default LinkItem;

