import { MANAGEMENT_LOGGED_IN } from '../../../../constants';
import { SERVER_ERROR } from '../../../../errors';
import { roomType as roomTypeUtils } from '../../../../utils';
import { management as managementActions, stream } from '../../../';
import logout from '../../../user/logout';
export default (management) => () => {
  return (dispatch, getState) => {
    const {
      sender: { roomTypes },
      streaming: {
        connectionData,
        managementStep,
        isReconnecting,
        silentReconnect,
      },
    } = getState();
    console.log(
      `Send stream sender started with ${JSON.stringify(connectionData)}`
    );

    management.emit(
      'streamSenderStarted',
      { ...connectionData, isReconnecting },
      (errorStatus) => {
        if (errorStatus) {
          if (errorStatus === 6279) {
            return dispatch(logout(false));
          }
          if (managementStep === MANAGEMENT_LOGGED_IN) {
            dispatch(managementActions.logoutCam({}, 21));
            if (!roomTypeUtils.isEvent(roomTypes)) {
              dispatch(managementActions.logfpsTimer.stop());
            }
            dispatch(managementActions.checkuserTimer.stop());
          }
          dispatch(stream.timerStop.stop());
          dispatch(
            managementActions.loginCam.error(
              new Error(SERVER_ERROR),
              errorStatus
            )
          );
          return;
        }
        const showMessage = !isReconnecting && !silentReconnect;
        dispatch(managementActions.loginCam.stop(showMessage));
      }
    );
  };
};
