import api from '../../utils/api';
import { tokenLoginError, userAddAuthToken, userLogout } from '..';

export default function (userId, token, grantType, history) {
  return async (dispatch, getState) => {
    try {
      const user = getState().user;
      if (user.isLoggedIn) {
        dispatch(userLogout());
      }

      const payload = {
        grant_type: grantType,
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
        scope: '*',
        user_id: userId,
        token,
      };
      const response = await api.post('/oauth/token', payload);
      dispatch(userAddAuthToken(response.data, false));
    } catch (err) {
      dispatch(tokenLoginError());
    }
    history.push('/');
  };
}
