import React from 'react';
import { FormattedMessage } from 'react-intl';

import { VOLUME_LEVELS } from '../../../constants/sound';
import Select from '../../Forms/Select/Select';
import { getVolumes } from './AudioVolume.functions';

const AudioVolume = ({ volume, onChange }) => (
  <div className="form-group">
    <label>
      <FormattedMessage id="audio.volume" />
    </label>
    <Select
      options={getVolumes(VOLUME_LEVELS)}
      intlTranslate={false}
      value={volume}
      onChange={onChange}
    />
  </div>
);

export default AudioVolume;
