import { UPDATE_FILE_PERCENT, UPLOAD_FILE } from '../actions/actions';

const initialState = {
  uploads: [],
};

const addOrUpdateUpload = (uploads, newUpload) => {
  const uploadExists = uploads.some(
    (upload) => upload.indicator === newUpload.indicator
  );
  if (uploadExists) {
    return uploads.map((upload) =>
      upload.indicator === newUpload.indicator
        ? { ...upload, ...newUpload }
        : upload
    );
  }
  return [...uploads, newUpload];
};

const reducer = (state = initialState, action) => {
  const { meta, payload, error } = action;

  switch (action.type) {
    case UPLOAD_FILE:
      if (error) {
        return {
          ...state,
          uploads: addOrUpdateUpload(state.uploads, {
            indicator: meta.indicator,
            error: payload,
          }),
        };
      }

      if (meta.end) {
        return {
          ...state,
          uploads: state.uploads.filter(
            (upload) => upload.indicator !== meta.indicator
          ),
        };
      }

      return {
        ...state,
        uploads: addOrUpdateUpload(state.uploads, {
          result: payload,
          indicator: meta.indicator,
          percent: 0,
          error: null,
        }),
      };

    case UPDATE_FILE_PERCENT:
      return {
        ...state,
        uploads: addOrUpdateUpload(state.uploads, {
          percent: payload.percent,
          indicator: payload.indicator,
        }),
      };

    default:
      return state;
  }
};

export default reducer;
