import { connect } from 'react-redux';

import Spinner from './UploadSpinner';

const mapStateToProps = (state) => ({
  active: state.spinner.active,
  indicator: state.spinner.indicator,
  uploads: state.upload.uploads,
});

export default connect(mapStateToProps)(Spinner);
