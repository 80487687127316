import { upgradetimer } from '../../../../utils';
import { stopUpgradetimer } from '../../../';
export default (payload) => (dispatch, getState) => {
  const requestActive = upgradetimer.isExclusiveRequestActive(
    getState().upgradetimer,
    payload.userId
  );
  dispatch(stopUpgradetimer(payload));
  upgradetimer.handleUpgradeTimer(payload, requestActive, getState, dispatch);
};
