import React from 'react';

import { chatMessagePlaceholder } from '../../../../utils/chat';
import Smiley from './Smiley';

const SmileyParser = ({
  text,
  cssClass = 'message',
  globalPattern,
  smileyDictionary = {},
  noSingleSmiley = false,
  user,
  nickname,
}) => {
  const textWithPlaceholder = chatMessagePlaceholder(text, user, nickname);
  const matches = textWithPlaceholder.split(new RegExp(globalPattern, 'g'));
  const foundMatches = matches.filter(
    (match) => typeof smileyDictionary[match] === 'object'
  );
  const noWhiteSpaceMatches = matches.filter((match) => match !== '');
  if (
    foundMatches.length === 1 &&
    noWhiteSpaceMatches.length === 1 &&
    !noSingleSmiley
  ) {
    return (
      <Smiley
        cssClass={cssClass}
        single={true}
        {...smileyDictionary[foundMatches.shift()]}
      />
    );
  }
  if (foundMatches.length === 0) {
    return <span className={cssClass}>{textWithPlaceholder}</span>;
  }

  return (
    <span className={cssClass}>
      {matches.map((match, key) => {
        if (typeof smileyDictionary[match] === 'object') {
          return <Smiley key={key} {...smileyDictionary[match]} />;
        }
        if (match === '') {
          return null;
        }
        return <span key={key}>{match}</span>;
      })}
    </span>
  );
};

export default SmileyParser;
