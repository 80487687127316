export default {
  WIKI_LOVENSE_INSTALLATION_HEADLINE: `Setting up the Lovense Toy`,
  WIKI_LOVENSE_INSTALLATION_SETTINGS: `Before you start setting up, make sure you allow the use of Lovense Toys in the {link} under Features.`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_HEADLINE: ` Configuration with the smartphone <span class='textcolor'>(Our recommendation)</span>`,
  WIKI_LOVENSE_INSTALLATION_SUBTEXT: `Here we will show you step by step how you can link your toy with your account in LiveCreator.`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_SUBTEXT: `Install the Lovense Connect app from the App Store or Google Play on your smartphone`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_HEADLINE: `Connect your Lovense toy to the app via Bluetooth`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT: `
  <ol>
    <li>Open the Lovense Connect app</li>
    <li>Turn on your toy</li>
    <li>Add toy by clicking on "Add Toy"</li>
    <li>As soon as your toy is recognized, select it and end the dialog with "Done"</li>
  </ol>`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_1: `Your toy is now connected to the app.`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_2: `<span class="bold">Important:</span> The Lovense Connect app must be open and your toy must be switched on in order to be able to use the function in the cam`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_HEADLINE_2: `Connect your Lovense toy to your LiveCreator account`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_3: `
  <ol>
    <li>By clicking the "next" button in LiveCreator, a QR code is generated</li>
    <li>Scan the QR code with the Lovense Connect app on your mobile phone</li>
  </ol>`,
  WIKI_LOVENSE_INSTALLATION_MOBILE_CONNECT_4: `Now you can see your active LiveCreator account in the Lovense Connect app under Accounts. From now on you can use the toy in our cam.`,

  WIKI_LOVENSE_INSTALLATION_DESKTOP_HEADLINE: `Configuration with the desktop app and using the Lovense USB Bluetooth Adapter`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_ADAPTER: `(The adapter is available separately from Lovense)`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_SUBTEXT: `Install the <a href='https://www.lovense.com/sextoys/download' target='_blank'>Lovense Remote App</a> on your computer.`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_HEADLINE_1: `Connect your Lovense toy to the app via Bluetooth`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT: `
  <ol>
    <li>Connect the USB adapter to your computer</li>
    <li>Open the Lovense Remote app</li>
    <li>Turn on your toy</li>
    <li>Add toy by clicking on "Add Toy"</li>
    <li>As soon as your toy is recognized, select it and end the dialog with "Done"</li>
  </ol>`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_1: `Your toy is now connected to the desktop app`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_2: `<span class="bold">Important:</span> The Lovense Remote app must be open and your toy must be switched on in order to be able to use the function in the cam`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_HEADLINE_2: `Connect your Lovense toy to your LiveCreator account`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_3: `
  <ol>
    <li>By clicking the "next" button in LiveCreator, a number code is generated</li>
    <li>In the Lovense Remote app under "External Control" activate the entry "Allow Control"</li>
    <li>Click on "Settings" and enter the numerical code there</li>
  </ol>`,
  WIKI_LOVENSE_INSTALLATION_DESKTOP_CONNECT_4: `From now on you can use the toy in our cam.`,
};
