import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { changeFunctionkey } from '../../../actions';
import FunctionKeys from './FunctionKeys';

const mapStateToProps = (state) => ({
  functionkeys: state.preferences.functionkeys,
});
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeFunctionkey }, dispatch);

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(FunctionKeys)
);
