import React from 'react';

import { notification } from '../../../services';

class Notification extends React.Component {
  componentDidUpdate() {
    const { intl, closeNotification, notifications = [] } = this.props;
    if (notifications.length > 0) {
      notifications.forEach(
        ({ body: message, title = '', icon = '', stamp }) => {
          if (message && stamp) {
            const body = intl.formatMessage({ id: message.id }, message.values);
            notification.send({ title, body, icon });
            closeNotification(stamp);
          }
        }
      );
    }
  }
  render() {
    return null;
  }
}

export default Notification;
