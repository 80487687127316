import { connect } from 'react-redux';

import { spinner } from '../../actions';
import Notifications from './Notifications';

const mapDispatchToProps = { spinner };
const mapStateToProps = (state) => ({
  user: state.user,
  browserAllowed: state.browser.isAllowed,
  browsers: state.browser.allowed,
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
