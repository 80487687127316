import { management } from '../../../';
import { MANAGEMENT_EVENTS_STREAMINFO_STOP } from '../../../actions';

export default () => (dispatch, getState) => {
  dispatch({
    type: MANAGEMENT_EVENTS_STREAMINFO_STOP,
  });
  if (getState().streaming.streamInfoCounter === 0) {
    dispatch(management.streaminfoTimer.stop());
  }
};
