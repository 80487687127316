import React from 'react';
import { FormattedMessage } from 'react-intl';

import BackButton from '../../../../BackButton';

function TimeManagement({ onBackButtonClicked }) {
  return (
    <div className="wiki-content wiki-settings-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="TIME_MANAGEMENT_HEADLINE" />
      </h1>
      <p className="has-margin-bottom">
        <FormattedMessage id="TIME_MANAGEMENT_INTRO" />
      </p>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="TIME_MANAGEMENT_STEP_1_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="TIME_MANAGEMENT_STEP_1_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="TIME_MANAGEMENT_STEP_2_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="TIME_MANAGEMENT_STEP_2_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="TIME_MANAGEMENT_STEP_3_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="TIME_MANAGEMENT_STEP_3_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="TIME_MANAGEMENT_STEP_4_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="TIME_MANAGEMENT_STEP_4_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">5</span>
          <span className="bold">
            <FormattedMessage id="TIME_MANAGEMENT_STEP_5_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="TIME_MANAGEMENT_STEP_5_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">6</span>
          <span className="bold">
            <FormattedMessage id="TIME_MANAGEMENT_STEP_6_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="TIME_MANAGEMENT_STEP_6_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">7</span>
          <span className="bold">
            <FormattedMessage id="TIME_MANAGEMENT_STEP_7_HEADER" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="TIME_MANAGEMENT_STEP_7_CONTENT" />
          </p>
        </div>
      </div>
      <h2 className="headline">
        <FormattedMessage id="TIME_MANAGEMENT_CONCLUSION_HEADER" />
      </h2>
      <p>
        <FormattedMessage id="TIME_MANAGEMENT_CONCLUSION_CONTENT" />
      </p>
    </div>
  );
}
export default TimeManagement;

