export default {
  CREATOR_REFERRAL_TITLE: `Collect up to 12% commission from referred Creators`,
  CREATOR_REFERRAL_CONTENT_1: `Livecreator.com moves to the next phase. We would likt to thank you very much for being part of our community and want to give you the opportunity to get your friends, acquaintances and other girls interested in our product to earn money with LiveCreator.com. The more successful the Creator you refer is, the more you can earn without doing anything yourself.`,
  CREATOR_REFERRAL_CONTENT_2: `From July 1, 2023 you can <span class="bold">earn up to 12%</span> additional commission, based on the turnover of your referred Creators, and of course absolutely <span class="bold">free of cancellation</span>!`,
  CREATOR_REFERRAL_CONTENT_3: `If you would like to invite a friend as a creator, send her the following link via email or messenger. Please make sure that the link is complete.`,
  CREATOR_REFERRAL_LINK: `Your personal link`,
  CREATOR_REFERRAL_TITLE_1: `The new model for referred Creators at a glance`,
  CREATOR_REFERRAL_CONTENT_4: `The coin-based commission rate increases with the number of coins earned by a referred Creators in the relevant accounting period:`,
  CREATOR_REFERRAL_CONTENT_TABLE: `
    <table class="has-border noHover table300">
       <tr>
          <td>up to 24 999 Coins</td>
          <td class="align-right">5.0 %</td>
       </tr>
       <tr>
          <td>from 25 000 Coins</td>
          <td class="align-right">7.5 %</td>
       </tr>
       <tr>
          <td>from 70 000 Coins</td>
          <td class="align-right">10.0 %</td>
       </tr>
       <tr>
          <td>from 100 000 Coins</td>
          <td class="align-right">12.0 %</td>
       </tr>
     </table>`,
  CREATOR_REFERRAL_CONTENT_5: `There are two accounting periods per month<br />
    <ul>
      <li>from the 1st to the 15th of the month, which is billed on the 16th of the month</li>
      <li>from the 16th to the end of the month, which is billed on the 1st of the following month</li>
    </ul>`,
  CREATOR_REFERRAL_CONTENT_6: `With these, additionally to your own commission, you will also be paid your share of the commission of referred Creators.`,
  CREATOR_REFERRAL_CONTENT_7: `If that's not a reason to recruit even more active Creators for LiveCreator.com!`,
  CREATOR_REFERRAL_TITLE_2: `Here are our top tips for recruiting Creators to LiveCreator.com`,
  CREATOR_REFERRAL_CONTENT_8: `
    <ul class='checklist'>
      <li>share your link on your social media profiles like Facebook, Twitter, Instagram, Pinterest or TikTok. You can place the link in your bio or create a post</li>
      <li>place a text link or banner on your own homepage using the link as its target</li>
      <li>or create your own website with a homepage builder where you can post regularly and promote your link</li>
      <li>place ads on portals such as Quoka or in online classifieds to achieve a greater reach for your advertising link</li>
    </ul>`,
  CREATOR_REFERRAL_CONTENT_9: `As you can see, it doesn't take a lot of effort to implement these tips - but it really helps a lot.`,
  CREATOR_REFERRAL_CONTENT_10: `You are welcome to contact our support personally to discuss further possibilities for Creators recruiting and increasing sales. Also note our online webinars, which take place regularly.`,
};
