import { FormattedMessage } from 'react-intl';

import coinsIcon from '../../../assets/img/svg/coins.png';

function CurrentSales({ key, classes, currentSalesTotal, label }) {
  return (
    <div className={classes} key={key}>
      <div className="sale">
        <FormattedMessage id={label} />{' '}
      </div>
      <div className="sales-number">
        {currentSalesTotal} <img className="icon" src={coinsIcon} alt="" />
      </div>
    </div>
  );
}

export default CurrentSales;
