import { connect } from 'react-redux';

import { user as userUtils } from '../../../../utils';
import UserProfilePersonal from './UserProfilePersonal';

const mapStateToProps = (state) => {
  const activeUser = userUtils.activeUser([
    ...state.camUser.camUsers,
    ...state.dialog.dialogs,
  ]);

  if (activeUser?.profile?.personal) {
    return {
      ...activeUser.profile.personal,
      language: state.user?.account?.language,
    };
  }
  return {};
};

export default connect(mapStateToProps)(UserProfilePersonal);
