import { user as userUtils } from '../../utils';

export default (userId) => {
  return (dispatch, getState) => {
    console.log('Stop Cam2Cam called');
    const {
      camUser: { camUsers },
    } = getState();
    const cam2camUser = userUtils.get(camUsers, userId);
    if (!cam2camUser || !cam2camUser.cam2camConnection) {
      return;
    }

    cam2camUser.cam2camConnection.stop();
  };
};
