import { connect } from 'react-redux';

import ProfileImage from './ProfileImage';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
});

export default connect(mapStateToProps)(ProfileImage);

