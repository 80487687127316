import 'react-image-crop/dist/ReactCrop.css';
import 'detect-autofill';

import './index.scss';
import './components/Content/Livecam/Livecam.scss';

import { configure } from 'axios-hooks';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import { IntlProvider } from 'react-intl-redux';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './components/App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import api from './utils/api';

if (!document.location.href.startsWith(process.env.REACT_APP_PUBLIC_URL)) {
  document.location.href =
    process.env.REACT_APP_PUBLIC_URL +
    document.location.pathname +
    document.location.search;
}

if (process.env.REACT_APP_CONSOLE_LOGGING === 'false') {
  console.log = () => {};
  console.debug = () => {};
}

if (process.env.REACT_APP_CONSOLE_ERROR_LOGGING === 'false') {
  console.error = () => {};
  console.warn = () => {};
}

if (
  process.env.REACT_APP_GOOGLE_GTM === 'active' &&
  !localStorage.getItem('gtmDisabled')
) {
  const tagManagerArgs = {
    gtmId: 'GTM-MSZGLJF',
  };
  TagManager.initialize(tagManagerArgs);
}

dayjs.extend(utc);

// useAxios hook configuration
configure({
  axios: api,
  cache: null,
  defaultOptions: { manual: false, useCache: false, ssr: false },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <IntlProvider textComponent="">
          <App />
        </IntlProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

