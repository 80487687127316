import { connect } from 'react-redux';

import { toyTimer } from '../../../actions';
import RunToy from './RunToy';

const mapStateToProps = (state) => ({
  runToy: state.sender?.runToy,
});

const mapDispatchToProps = (dispatch) => ({
  startToyTimer: () => dispatch(toyTimer.start()),
});
export default connect(mapStateToProps, mapDispatchToProps)(RunToy);
