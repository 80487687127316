import './DashedProgressWrapper.scss';

import classNames from 'classnames';
import React from 'react';

function DashedProgressWrapper({ className = '', percent = 0 }) {
  // The dashed progress wrapper is split into 4 smaller divs each with 25% width.
  // A div can be fully filled, partially filled, or empty, based on the percent
  // helperPercentArray is used so that the percentage split is easily looped through
  const helperPercentArray = [25, 50, 75, 100];

  return (
    <div className={className}>
      {helperPercentArray.map((item, index) => {
        const progressPercentDiv = classNames('dashed-percent-div', {
          'border-left-div': index !== 0,
        });
        if (percent >= item) {
          return (
            <div
              className={progressPercentDiv + ' full-green-percent-div'}
              key={`${item}.${index}`}
            />
          );
        } else if (
          percent < item &&
          index !== 0 &&
          percent <= helperPercentArray[index - 1]
        ) {
          return (
            <div
              className={progressPercentDiv + ' empty-percent-div'}
              key={`${item}.${index}`}
            />
          );
        } else {
          return (
            <div className={progressPercentDiv} key={`${item}.${index}`}>
              <div
                className="full-green-percent-div"
                style={{ width: `${(percent / item) * 100}%` }}
              />
              <div
                className="empty-percent-div"
                style={{ width: `${((item - percent) / item) * 100}%` }}
              />
            </div>
          );
        }
      })}
    </div>
  );
}

export default DashedProgressWrapper;

