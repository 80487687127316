import { closeAlert } from '../../../actions';
import { uuid } from '../../../utils';
import userUtils from '../../../utils/user';
import { MANAGEMENT_USER_KICK } from '../../actions';
export default (management) => (kick, stamp) => (dispatch, getState) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
  } = getState();
  const activeUser = userUtils.activeUser([...camUsers, ...dialogs]);
  if (!activeUser || userUtils.isAdmin(activeUser)) {
    return;
  }
  const userId = activeUser.userId;
  if (!kick) {
    return dispatch({
      type: MANAGEMENT_USER_KICK,
      payload: activeUser,
      meta: {
        start: true,
        stamp: uuid(),
      },
    });
  }
  if (stamp) {
    dispatch(closeAlert(stamp));
  }
  management.emit('kick', { userId });
  dispatch({
    type: MANAGEMENT_USER_KICK,
    payload: {
      userId,
    },
  });
};
