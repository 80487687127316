import ambi from '../assets/img/lovense/ambi.svg';
import dolce from '../assets/img/lovense/dolce.svg';
import domi from '../assets/img/lovense/domi.svg';
import exomoon from '../assets/img/lovense/exomoon.svg';
import ferri from '../assets/img/lovense/ferri.svg';
import gravity from '../assets/img/lovense/gravity.svg';
import hush from '../assets/img/lovense/hush.svg';
import hyphy from '../assets/img/lovense/hyphy.svg';
import lush from '../assets/img/lovense/lush.svg';
import mission from '../assets/img/lovense/mission.svg';
import nora from '../assets/img/lovense/nora.svg';
import osci from '../assets/img/lovense/osci.svg';
import tenera from '../assets/img/lovense/tenera.svg';

export const LOVENSE_TOYS = {
  ambi,
  dolce,
  domi,
  exomoon,
  ferri,
  gravity,
  hush,
  hyphy,
  lush,
  mission,
  nora,
  osci,
  tenera,
};
