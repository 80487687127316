import { uuid } from '../../../utils';
import { FRIEND_FRIEND_REQUEST_DENIED } from '../../actions';

export default function (name) {
  return {
    type: FRIEND_FRIEND_REQUEST_DENIED,
    payload: { name },
    meta: {
      stamp: uuid(),
    },
  };
}
