
class StatsContainer {
  constructor() {
    this.reset();
  }

  add(stats) {
    this.calculateBandwidth(stats);

    this.lastTimestamp = stats.timestamp;
  }
  calculateBandwidth ({bytesSent, timestamp}) {
    if (this.lastBytesSent === -1 || this.lastTimestamp === -1) {
      this.lastBytesSent = bytesSent;
      return;
    }

    this.bandwidth = ((bytesSent - this.lastBytesSent) * 8) / (timestamp - this.lastTimestamp);

    this.lastBytesSent = bytesSent;
  }

  reset() {
    this.lastBytesSent = -1;
    this.lastTimestamp = -1;
  }
}
export default StatsContainer;
