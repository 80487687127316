import './Support.scss';

import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import packageJson from '../../../../package.json';
import videoPosterImgDe from '../../../assets/img/videoPoster/Onboarding_de.jpg';
import videoPosterImgEn from '../../../assets/img/videoPoster/Onboarding_en.jpg';
import useMediaQuery from '../../../hooks/useMediaQuery';
import Scrollbar from '../../Scrollbar/Scrollbar';
import Section from '../../Section/Section';
import VideoWithOverlay from '../../VideoWithOverlay';
import Support from './Support.text';

function Contact({ user, locale, spinner }) {
  const intl = useIntl();
  const isContentSectionDisplayed = useMediaQuery('(max-width: 1599px)');
  const isMobileSize = useMediaQuery('(max-width: 1023px)');
  const lang = locale;
  const [supportForm, setSupportForm] = useState(null);
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const [hideZoom, setHideZoom] = useState(false);

  let version = packageJson.version;
  if (process.env.NODE_ENV !== 'production') {
    version += ` ${process.env.NODE_ENV}`;
  }
  const video =
    locale === 'en'
      ? `${process.env.REACT_APP_VIDEO_URL}/onboarding_en2.mp4`
      : `${process.env.REACT_APP_VIDEO_URL}/onboarding_de.mp4`;
  const videoPosterImg = locale === 'en' ? videoPosterImgEn : videoPosterImgDe;

  return (
    <div className="page-wrapper">
      <Section
        title={intl.formatMessage({
          id: 'HEADER_TITLE_SUPPORT',
        })}
        className="component-section"
      >
        <>
          <div className="component-wrapper-no-header-menu">
            <Scrollbar visible={!isLargeScreen}>
              <div className="support-container support-data-wrapper">
                {isContentSectionDisplayed || isMobileSize ? (
                  <>
                    <Support
                      setSupportForm={setSupportForm}
                      supportForm={supportForm}
                      isMobile={isMobileSize}
                      user={user}
                      lang={lang}
                      spinner={spinner}
                      setHideZoom={setHideZoom}
                    />
                  </>
                ) : null}
                <Scrollbar visible={isLargeScreen}>
                  <div className="data-wrapper">
                    {user && user?.isLoggedIn ? (
                      <>
                        {user.account.state === 'onboarding' ? (
                          <div className="video">
                            <VideoWithOverlay
                              video={video}
                              poster={videoPosterImg}
                            />
                          </div>
                        ) : null}
                        {isMobileSize && hideZoom ? null : (
                          <div className="zoom-support">
                            <div className="support-image" />
                            <h1 className="headline">
                              <FormattedMessage id="SUPPORT_INFO_HEADLINE" />
                            </h1>
                            <p>
                              <FormattedMessage id="SUPPORT_INFO_TEXT">
                                {(message) => (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: message[0],
                                    }}
                                  />
                                )}
                              </FormattedMessage>
                            </p>
                          </div>
                        )}
                      </>
                    ) : (
                      !isMobileSize &&
                      !isContentSectionDisplayed && (
                        <div>
                          <h2 className="headline">
                            <FormattedMessage id="SUPPORT_CONTACT" />
                          </h2>
                          <p className="email">
                            <FormattedMessage id="SUPPORT_EMAIL">
                              {(message) => (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: message[0],
                                  }}
                                />
                              )}
                            </FormattedMessage>
                          </p>
                        </div>
                      )
                    )}
                    <div className="support-footer">
                      {user?.isLoggedIn && (
                        <>
                          <h2 className="headline">
                            <FormattedMessage id="SUPPORT_CONTACT" />
                          </h2>
                          <p className="email">
                            <FormattedMessage id="SUPPORT_EMAIL">
                              {(message) => (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: message[0],
                                  }}
                                />
                              )}
                            </FormattedMessage>
                          </p>
                        </>
                      )}

                      <p>
                        <FormattedMessage id="SUPPORT_VERSION">
                          {(message) => (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: message[0].replace(
                                  '{version}',
                                  version
                                ),
                              }}
                            />
                          )}
                        </FormattedMessage>
                      </p>
                      <p className="small-text">
                        <span>
                          <FormattedMessage
                            id="SUPPORT_COPYRIGHT"
                            values={{
                              year: new Date().getFullYear(),
                            }}
                          />
                        </span>
                        <span>
                          <FormattedMessage id="SUPPORT_ALL_RIGHTS_RESERVED" />
                        </span>
                        <span>
                          <FormattedMessage id="SUPPORT_NO_REPRODUCTIONS" />
                        </span>
                      </p>
                    </div>
                  </div>
                </Scrollbar>
                <Scrollbar visible={isLargeScreen} hasBoxShadow={true}>
                  {!isContentSectionDisplayed && !isMobileSize ? (
                    <>
                      <Support
                        setSupportForm={setSupportForm}
                        supportForm={supportForm}
                        isMobile={isMobileSize}
                        user={user}
                        lang={lang}
                        spinner={spinner}
                        setHideZoom={setHideZoom}
                      />
                    </>
                  ) : null}
                </Scrollbar>
              </div>
            </Scrollbar>
          </div>
        </>
      </Section>
    </div>
  );
}

export default Contact;

