import landingpage from './landingpage';

export default {
  ...landingpage,

  SIGNUP_TEXT_HEADLINE: `Become a part of LiveCreator`,
  SIGNUP_TEXT_CONTENT_1: `As a Creator you will be an integral part of our communities and can earn up to 32% commission! LiveCreator offers the best monetization opportunities and highest commissions among the leading social adult communities.`,
  SIGNUP_TEXT_CONTENT_2: `Get involved, grant thousands of new pay users intimate insights into your life every day, chat with them, share thoughts, fantasies, pictures and videos of yourself or meet live on cam chat.`,
  SIGNUP_TEXT_CONTENT_3: `As a Creator, our communities offer you various possibilities and opportunities. You decide what you are willing to share and what you want to make money with. Complete and use your social media reach and give the users even more intimate insights than on other platforms.`,
  SIGNUP_TEXT_CONTENT_4: `Benefit from over 60,000 advertising partners who advertise our communities every day and rely on the full expertise of our support team.`,
  SIGNUP_TEXT_CONTENT_5: `Start as a Creator today and secure your spot in our communities!`,

  SECTION_SIGNUP_TITLE: `Become a Creator now`,
  SECTION_SIGNUP_HEADLINE: `Signup as Creator in our communities`,
  SECTION_SIGNUP_LOGIN_HINT: `You already have an account?`,
  SECTION_SIGNUP_LOGIN: `Login`,
  SIGNUP_TERMS_1: `By submitting the registration you confirm and accept our`,
  SIGNUP_TERMS_2: `terms and conditions`,
  SIGNUP_TERMS_3: `and`,
  SIGNUP_TERMS_4: `privacy policy`,

  BUTTON_SIGN_UP: `Signup as Creator`,
  PLACEHOLDER_EMAIL: `Enter your email address here`,
  PLACEHOLDER_PASSWORD: `minimum 6 characters`,
  FIELD_CHOOSE_PASSWORD: `Choose a password:`,

  ACCOUNT_LOGIN: `LiveCreator Login`,
  ACCOUNT_LIVECREATOR_SET_PASSWORD: `Set LiveCreator Password`,
  LOGIN_HEADLINE: `Enter your login data here`,
  QUESTION_ACCOUNT: `Not a Creator yet?`,
  ACCOUNT_SIGN_UP: `Sign up`,
  BUTTON_LOGIN: `Login`,
  FIELD_STAY_LOGGED_IN: `stay logged in`,
  ACCOUNT_FORGOT_PASSWORD: `Forgot your password?`,
  ACCOUNT_SET_PASSWORD: `Set Password`,
  RESET_PASSWORD_LABEL: 'Reset password',
  SET_PASSWORD_LABEL: `Set Password`,

  SOCIAL_VISIT: `Visit us on:`,

  CHANGE_NICKNAME_TITLE: 'Please change your nickname',
  CHANGE_NICKNAME_TEXT:
    "Nicknames cannot begin with 'sender-'. Please choose a different nickname.",
  CHANGE_NICKNAME_NEW: 'My new nickname:',
  CHANGE_NICKNAME_CHANGE: 'Change nickname',
  NICKNAME_CHANGER_ALTERNATIVE: 'Alternatives:',
};
