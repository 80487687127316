export default (socketService) => () => (dispatch, getState) => {
  const {
    user: { access_token: token },
  } = getState();

  const evtid = 'user.keep-alive-' + Date.now();
  const type = 'user.keep-alive';
  const options = {
    type,
    token,
    evtid,
  };

  socketService.emit(type, options);
};
