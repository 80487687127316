import { roomType as roomTypeUtils } from '../../../utils';
import { management, stream } from '../..';
export default () => {
  return (dispatch, getState) => {
    const {
      camUser: { camUsers },
      streaming: {
        permanentStream,
        stopTimerRunning,
        idleStreamBandwidth,
        idleStreamStartTimeMs,
        camLoginData,
      },
      sender: { roomTypes },
      streamingstats: { bytesSent },
    } = getState();
    const isEvent = roomTypeUtils.isEvent(roomTypes);
    if (isEvent) {
      return;
    }
    const userCount = camUsers.length;
    const now = Date.now();
    const noUserInStream = userCount < 1 && !stopTimerRunning;
    const fullstreamIntervalFinished =
      typeof idleStreamStartTimeMs === 'number' && now >= idleStreamStartTimeMs;
    console.log(
      `Stream check for stop: ${userCount} user still online, ` +
        `stopTimerRunning: ${stopTimerRunning}, permanentStream ${permanentStream},` +
        `noUserInStream: ${noUserInStream}, fullstreamIntervalFinished: ${fullstreamIntervalFinished}`
    );
    if (!permanentStream && noUserInStream) {
      return dispatch(stream.stop());
    }
    if (!permanentStream) {
      return;
    }
    if (
      !camLoginData ||
      !camLoginData.sdpOpts ||
      typeof camLoginData.sdpOpts.videoBitrate !== 'number'
    ) {
      return;
    }
    const videoBitrate = camLoginData.sdpOpts.videoBitrate;

    if (fullstreamIntervalFinished && bytesSent === 0) {
      return dispatch(
        management.logoutCam(
          { kickMessage: 'alert.lowFps', translate: true },
          14
        )
      );
    }
    if (
      fullstreamIntervalFinished &&
      noUserInStream &&
      idleStreamBandwidth < videoBitrate
    ) {
      return dispatch(stream.changeBandwidth(idleStreamBandwidth));
    }
    dispatch(stream.changeBandwidth(videoBitrate));
  };
};
