import React from 'react';
import { FormattedMessage } from 'react-intl';

function PanelContentWithImage({ messageId, image, link }) {
  return (
    <div>
      <FormattedMessage id={messageId}>
        {(message) => {
          return (
            <>
              <span
                dangerouslySetInnerHTML={{
                  __html: message[0].split('{image}')[0],
                }}
              />
              {image}
              <span
                dangerouslySetInnerHTML={{
                  __html: message[0].split('{image}')[1].replace('{link}', ''),
                }}
              />
              {link && link}
            </>
          );
        }}
      </FormattedMessage>
    </div>
  );
}

export default PanelContentWithImage;
