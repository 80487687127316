export default {
  LEGAL_PRIVACY: 'Privacy',
  LEGAL_PRIVACY_INTRO:
    'Thank you for using our service. This document is to inform you about our processing of personal data.',
  LEGAL_PRIVACY_RESPONSIBLE_HEADER: 'Responsible Party',
  LEGAL_PRIVACY_RESPONSIBLE_DATA: `IP Broadcasting B.V.<br />
  Lottumseweg 43<br />
  5971 BV Grubbenvorst<br />
  The Netherlands<br />
  <br />
  Email: <a href="mailto:compliance@livecreator.com">compliance@livecreator.com</a>`,
  LEGAL_PRIVACY_DATA_PROTECTION_HEADER:
    'Questions Related to Data Protection and Exercising your Rights',
  LEGAL_PRIVACY_DATA_PROTECTION: `If you have any questions related to data protection, or if you wish to exercise your rights, please contact <a href="mailto:compliance@livecreator.com">compliance@livecreator.com</a> or the address stated above, adding the keyword “Data Protection”.`,
  LEGAL_PRIVACY_PROCESS_DATA_HEADER:
    'In which cases will we process personal data',
  LEGAL_PRIVACY_PROCESS_DATA: `We will process personal data which you transfer to us actively by your entries. Additionally, we will process personal data in an automated way, based on the use of our service. Particularly in the cases stated below your personal data may be processed.
  <br /><br />
  For details, please refer to the following explanations.`,
  LEGAL_PRIVACY_VISIT_WEBSITE_HEADER: 'Visiting our Website',
  LEGAL_PRIVACY_VISIT_WEBSITE: `As soon as you visit our website, the company we have entrusted with the website’s operation will store the public IP address of the computer you are using to visit our website. This includes the access date and time. The IP address is a unique numeric address, through which your computer sends and receives data via the internet. Usually, neither we nor our service provider will know whom the IP address belongs to, unless you provide us with any data permitting us to identify you while using our website. Also, a user can be identified if we take legal action (in cases of cyber-attacks on our website, for instance) and are informed of the user’s identity during the process of investigation. So as a general rule, you will not have to worry we will be able to draw a connection between you and your IP address.
  <br /><br />
  Our service provider is using the IP address to enable you to visit and use our website, and to discover and prevent attacks on our websites. Unfortunately, websites are attacked frequently, in order to harm the operators and users (for example blocking the access, spying on data, distributing malware like viruses or other illegal purposes). Such attacks would adversely affect our website’s intended use as well as the security of our website’s visitors. The IP address, along with the visit’s time, is being processed to prevent such attacks and potential dangers resulting from them for ourselves and for our website’s users. Via our service provider we are pursuing the justified interests of maintaining our business operations as well as of preventing unlawful interference affecting ourselves or our website’s visitors. The legal basis of this processing activity is article 6 paragraph 1 f) of the General Data Protection Regulation (GDPR). The stored IP data will be deleted by de-personalization, provided they are no longer necessary for the purpose of identifying or preventing attacks.
  <br /><br />
  For higher speed of data transfer within the respective region we are using content delivery networks, in order to provide you with the highest capacity and data throughput. For this, the public IP address of your computer (see above) will be transferred. For further information about Highwinds CDN and their privacy policy, please click here. The legal basis of this processing activity is article 6 paragraph 1 f) of the General Data Protection Regulation (GDPR).`,
  LEGAL_PRIVACY_CREATE_ACCOUNT_HEADER: 'Creating a Customer Account',
  LEGAL_PRIVACY_CREATE_ACCOUNT: `You are using our service to have erotic adventures and contact other users. To use all of our offer’s functionalities, you need to create a customer account. Apart from the information required during the registration process, all other personal information will be provided voluntarily. You alone decide what information you would like to include in your user profile. You can edit or delete your data at any time. The more information you provide, the higher are the chances for other users to find you and recognize mutual interests you share with them. As our website is accessible for everyone via the internet, all the information provided by you will be public.
  <br /><br />
  If you do not provide any data permitting us to identify you (such as your mobile number), you can use our service without us knowing who you are. If you buy coins, you will have to provide your name and your address, but only our payment service provider will receive this data, which they will not forward to us. Accordingly, you will be able to use our service anonymously.
  <br /><br />
  If you create a customer account and add information to your profile, we are processing the data provided by you for this purpose, to create and administrate your customer account, as well as to enable you to make use of the services related to your customer account. Additionally, the data will be used for our service and made accessible to other users, in accordance with their intended purpose (for example searching for specific inclinations and interests, match checks to identify mutual interests and preferences). The legal basis of this processing activity is article 6 paragraph 1 b) of the General Data Protection Regulation (GDPR).
  <br /><br />
  During the process of creating a user account, we will be able to identify your approximate location, based on your IP address. We will use this to pre-enter this location in your user profile, which you can change. This is done to ensure that the members of our service will use the correct location for local searches and thereby – unless they adapt the location – will find other members. The legal basis of this processing activity is article 6 paragraph 1 f) of the General Data Protection Regulation (GDPR).
  <br /><br />
  The data will be stored until the customer account is deleted. In cases in which we are obligated by law to store the data beyond this event (to fulfill accounting obligations, for instance), or if we are legally entitled to store the data beyond this event (in case of a current lawsuit against the user account owner, for instance), the data will be deleted as soon as the obligation or the legal authorization to store the data ceases to exist.`,
  LEGAL_PRIVACY_GDPR_ARTICLE_9_HEADER:
    'The processing of special categories of personal data in accordance to article 9 of the General Data Protection Regulation (GDPR)',
  LEGAL_PRIVACY_GDPR_ARTICLE_9: `If you add any data to your profile, or otherwise communicate any data to us for processing, which could be considered to be data of special categories within the meaning of article 9 of the General Data Protection Regulation (GDPR) (for example ethnic origin, religious or ideological conviction, data referring to sexual life or sexual orientation), we will process them on the sole basis of your voluntary indications and use them for no other purpose than providing the functionalities of our service, for which these data will be accessed and used. This involves the public indication of these data in relation to your customer account for other users of our service, public search functions for users, match checks and all other functionalities of which it is recognizable to you as a user that these data are being used for them. If you do not wish this to be done, please do not add any data of the nature defined in article 9 of the General Data Protection Regulation (GDPR) to your user profile and do not communicate any such data to us or to other users.`,
  LEGAL_PRIVACY_CONTACT_HEADER: 'Ways of Contact',
  LEGAL_PRIVACY_CONTACT: `We offer our members diverse ways of contact. Apart from the email address provided during the registration process, other voluntary data is also necessary for this. As far as the communication is effected via our service, the communication content will be stored, so you will be able to access it again later. For contacts we will store the history of your contacts and make it accessibly for you for 180 days, so that you will be able to find your previous contacts quickly.
  <br /><br />
  If you would like to delete them, please contact our support.
  <br /><br />
  The legal basis of the processing activity is article 6 paragraph 1 b) of the General Data Protection Regulation (GDPR), as far as the processing is necessary for us to meet a contractual obligation towards you. For processing beyond this the legal basis is article 6 paragraph 1f) of the General Data Protection Gegulation (GDPR). Our justified interest in this process is our wish to make your previous communication content accessible to you, so you will be able to retrace the messages you have exchanged with the respective users. Our justified interest in storing the live cam contact history is to enable you to retrieve live cam partners you had particularly good times with.
  <br /><br />
  Whenever you contact us via one of the ways of contact provided, we will make use of the data provided by you to process your inquiry. The legal basis for this is our justified interest in replying to your request according to article 6 paragraph 1 f of the General Data Protection Regulation (GDPR). If your request is serving the purpose of concluding or processing a contract with us, the additional basis of the processing activity is article 6 paragraph 1 b of the General Data Protection Regulation (GDPR). The data will be deleted after your request has been completed. In cases in which we are obligated by law to store the data for a longer period, they will be deleted after the expiry of this period.`,
  LEGAL_PRIVACY_COINS_HEADER: 'Purchase of Coins',
  LEGAL_PRIVACY_COINS: `If you purchase coins, we will process the data provided by you to conclude and process the contract. To the necessary extent, data will be transferred to service providers to invoice your purchase. The legal basis for this processing activity is article 6 paragraph 1 b) of the General Data Protection Regulation (GDPR).
  <br /><br />
  In cooperation with our payment service provider we will process these data in connection with your IP address to identify and prevent fraud attempts. The legal basis for this is article 6 paragraph 1 f of the General Data Protection Regulation (GDPR). Thus, we are pursuing the aim to protect ourselves from fraudulent transactions.
  <br /><br />
  Data stored in connection with the conclusion of a contract concerning the purchase of a product will be deleted on expiration of the legal retention obligation. In cases in which legal record keeping requirements and retention obligations exist (for example retention of invoices according to tax legislation), the legal basis for this processing activity is article 1 paragraph 1 c) of the General Data Protection Regulation (GDPR).
  <br /><br />
  We will delete or de-personalize the data as soon as they are no longer necessary for the processing of the respective contract and any legal retention obligation has ceased to exist.`,
  LEGAL_PRIVACY_COOKIES_HEADER: 'Use of Cookies and Comparable Technologies',
  LEGAL_PRIVACY_COOKIES: `For our website’s operation we are using cookies comparable technologies (local storage, pixel tags, tracking pixels and other identifiers) to understand how visitors use our website and to store pre-settings users have made in their browsers. Optional: We also use these technologies to personalize our website for each user and to adapt our advertising measures.
  <br /><br />
  Cookies are small text files, which are stored on your computer through your browser when you visit our website. If you re-access our website later, we will be able to read the cookies on your browser again. Cookies are stored for different periods of time. You can adapt the settings in your browser, regard to which cookies it is to accept, at any time, but this may result in our website not working properly. Additionally, you can delete cookies independently at any time. If you don’t do this, we can indicate for how long a cookie is to be stored on your computer during the process of saving. There is a difference between so-called session cookies and permanent cookies. Session cookies will be deleted by your browser when you leave the website or when you quit the browser. Permanent cookies will remain stored for the period of time we have set in the process of saving.`,
  LEGAL_PRIVACY_COOKIES_PURPOSE_HEADER:
    'We are using cookies for the purposes stated below:',
  LEGAL_PRIVACY_COOKIES_PURPOSE: `Technically necessary cookies, which are indispensable for the use of our website’s functions (for example to identify whether you have logged in). Certain functionalities could not be provided without these cookies.
  <br /><br />
  Functional cookies that are being used to technically process certain functionalities, which you would like to use. We are also using such cookies to personalize the website for you.
  <br /><br />
  Analyzing cookies serving the purpose of analyzing user behavior. For details please read the information under the heading “Evaluations”.
  <br /><br />
  Cookies of third party providers. Cookies of third party providers are stored by third parties, whose functionalities we are integrating into our website, in order to enable certain functionalities. They can also be used to analyze user behavior. For details please read the information under the heading “Evaluations”.
  <br /><br />
  Most of the browsers employed by our users permit their settings to be adapted, concerning which cookies are to be saved. They also permit deleting (specific) cookies. If you are restricting cookies to be saved on specific websites, or if you are declining cookies from third party websites, you might not be able to make use of our website in its full extent. Below please find some information concerning how to adapt the cookie settings with the most frequently used browsers:
  <ul>
  <li>Google Chrome (<a rel="external" target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en">support.google.com/chrome/answer/95647?hl=en</a>)</li>
  <li>Internet Explorer (<a rel="external" target="_blank" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a>)</li>
  <li>Firefox (<a rel="external" target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>)</li>
  <li>Safari (<a rel="external" target="_blank" href="https://support.apple.com/kb/PH21411?locale=en_US">https://support.apple.com/kb/PH21411?locale=en_US</a>)</li>
  </ul>`,
  LEGAL_PRIVACY_EVALUATIONS_HEADER: 'Evaluations',
  LEGAL_PRIVACY_EVALUATIONS: `Service providers we have engaged are creating personal evaluations for us, concerning the way how certain groups of users access our website and how they use it. To do this, general data are used, such as local origin, the browser used, the website via which users came to us and which search keywords may have been used to find our website. Additionally, there will be evaluations about how our website is being used, the frequency and periods of time certain sites are being accessed, as well as which functionalities of the website are used and in which way. These data help us improve our understanding about how users find our website, in order to improve search engine optimization and advertising measures. Additionally, the data improve our understanding about how users make use of our website and which functionalities are the most interesting to them. This will enable us to improve these functionalities‘ usability and to enhance functionalities we consider of interest in our users. The legal basis of this processing activity is article 6 paragraph 1 f) of the General Data Protection Regulation (GDPR).
  <br /><br />
  If you do not wish this to be done, please install a so-called extension to prevent such evaluations in your browser. Two well-known tools are Ghostery (<a rel="external" target="_blank" href="https://www.ghostery.com/">https://www.ghostery.com/</a>) and uBlock Origin (<a rel="external" target="_blank" href="https://github.com/gorhill/uBlock/">https://github.com/gorhill/uBlock/</a>). Below please find some information concerning how to adapt the cookie settings with the most frequently used browsers:
  <ul>
  <li>Google Chrome (<a rel="external" target="_blank" href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a>)</li>
  <li>Internet Explorer (<a rel="external" target="_blank" href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a>)</li>
  <li>Firefox (<a rel="external" target="_blank" href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>)</li>
  <li>Safari (<a rel="external" target="_blank" href="https://support.apple.com/kb/PH21411?locale=en_US">https://support.apple.com/kb/PH21411?locale=en_US</a>)</li>
  </ul>`,
  LEGAL_PRIVACY_GOOGLE_ANALYTICS_HEADER:
    'Google Analytics Data Protection Declaration',
  LEGAL_PRIVACY_GOOGLE_ANALYTICS: `This website is using Google Analytics, a service by Google LLC („Google“). The use is based on article article 6 paragraph 1 subparagraph 1 letter f) of the General Data Protection Regulation (GDPR). Google Analytics is making use of so-called „Cookies”, which are small text files, which are stored on your computer, and which permit an analysis of your use of the website. The information regarding your use of the website created by the cookie, such as
  <ul>
  <li>Browser type / version,</li>
  <li>Operating system,</li>
  <li>Referrer URL (the website you have been visiting before),</li>
  <li>Hostname of the accessing computer (IP address),</li>
  <li>Time of server request,</li>
  </ul>
  will usually be transferred to one of Google’s servers within the USA and stored there. The IP address transferred via your browser within the framework of Google Analytics will not be brought together with any other data by Google. The way we use Google Analytics ensures that your IP address will be used in a de-personalized way only. According to Google’s statement this de-personalization will be done within the European Union or within a member state of the European Economic Area. It will only be in exceptional cases in which the complete IP address shall be transferred to one of Google’s servers within the USA and only be abbreviated at that point. According to Google’s statement the de-personalization will be done before the IP address will be stored initially on a permanent data medium. For details, please refer to Google’s privacy policy, accessible via <a rel="external" target="_blank" href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</a>. On behalf of this website’s operator Google will make use of these data to analyze the website’s use, to create reports about the website activities and to perform other services related to the website’s and the internet’s use for the website’s provider. You can prevent the saving of cookies by adapting your browser software settings. We would like to point out that in this case you may not be able to make full use of all the functionalities of this website.
  <br /><br />
  Additionally, you can prevent the registration of data created by cookies and related to your use of the website (including your IP address) by Google as well as the processing of these data by Google by downloading and installing the browser plugin available via this link: <a rel="external" target="_blank" href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>.
  <br /><br />
  Google is a member of the PrivacyShield Agreement and has concluded an order management contract with us for Google Analytics.
  <br /><br />
  Additionally, we are using Google Analytics to evaluate date from double-click-cookies and Google Ads for statistic purposes. If you do not wish this to be done, you can deactivate it via the ads preferences manager (<a rel="external" target="_blank" href="http://www.google.com/settings/ads/onweb/?hl=en">http://www.google.com/settings/ads/onweb/?hl=en</a>).
  <br /><br />
  Please refer to Google Analytics Help for further information related to Google Analytics (<a rel="external" target="_blank" href="https://support.google.com/analytics/answer/6004245?hl=en">https://support.google.com/analytics/answer/6004245?hl=en</a>).`,
  LEGAL_PRIVACY_GOOGLE_ADWORDS_HEADER:
    'Google Googleadservices / Google Ads Conversion',
  LEGAL_PRIVACY_GOOGLE_ADWORDS: `This website is using tools for “Online Marketing” by the company Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. The purpose is to recognize, that a visitor has been directed to our website via a Google advertisement. Google is using cookies, which are saved on your computer and which permit an analysis of the website’s use. The cookies for the so-called “conversion tracking” are set if you click on any advertisement placed by Google. These cookies will cease to be valid after 30 days and do not serve the purpose of personal identification. If you would like to prevent “conversion tracking”, you can adapt your browser settings, so cookies from the domain “<a rel="external" target="_blank" href="https://googleadservices.com">googleadservices.com</a>” will be blocked. You can also exercise your right to opt-out via the website <a rel="external" target="_blank" href="http://www.networkadvertising.org/choices/">http://www.networkadvertising.org/choices/</a>. If you would like to know more about these methods or about the options you have to prevent Google from using this information, please click here: <a rel="external" target="_blank" href="https://www.google.com/settings/u/0/ads/authenticated">https://www.google.com/settings/u/0/ads/authenticated</a>`,
  LEGAL_PRIVACY_EMAILS_HEADER: 'Emails',
  LEGAL_PRIVACY_EMAILS: `We will send you e-mails containing messages from other users as well as information about the use of our service. The legal basis of this processing activity is article 6 paragraph 1 b) of the General Data Protection Regulation (GDPR). To send these emails, we make use of an external service provider, to whom we will forward your personal data to the necessary extent.
  <br /><br />
  You can unsubscribe from these messages at any time. You will find a corresponding link at the end of each e-mail. `,
  LEGAL_PRIVACY_NEWSLETTER_HEADER: 'Newsletter',
  LEGAL_PRIVACY_NEWSLETTER: `On your registration for our offer, we have informed you that we will make use of your e-mail address to send out our newsletter according to § 7 paragraph 3 of the German Fair Trade Practices Act (UWG). You can object to this use at any time, which will result in you being deleted from our email list. You will find a corresponding link at the end of each newsletter.
  <br /><br />
  The legal basis of this processing activity is § 7 paragraph 3 of the German Fair Trade Practices Act (UWG) in connection with article 6 paragraph 1 f) of the General Data Protection Regulation (GDPR). Our justified interest is to keep you informed about our services based on the permission according to § 7 paragraph 3 of the German Fair Trade Practices Act (UWG).
  <br /><br />
  If applicable: For our newsletter we are making use of an external service provider, who is working for us as an order processor.`,
  LEGAL_PRIVACY_CHAT_HEADER: 'Chat between Users',
  LEGAL_PRIVACY_CHAT: `As a part of our contractual services we are offering our users the option of sending and receiving chat messages. The legal basis of the corresponding processing of personal data is article 6 paragraph 1 b) of the General Data Protection Regulation (GDPR). The corresponding messages will be saved until you have them deleted, provided we are neither legally obligated nor entitled to continue the storing.`,
  LEGAL_PRIVACY_LOCATION_HEADER: 'Determination of Location',
  LEGAL_PRIVACY_LOCATION: `To recommend other users from the neighborhood to you, we are determining your location via your browser. For this, it is necessary that you unblock your location on the browser’s corresponding question. The legal basis of this processing activity is article 6 paragraph 1 a) of the General Data Protection Regulation (GDPR). You can re-block your location at any time via your browser settings`,
  LEGAL_PRIVACY_ACCESS_RIGHT_HEADER: 'Right of Access',
  LEGAL_PRIVACY_ACCESS_RIGHT: `According to article 15 of the General Data Protection Regulation (GDPR) you have the right to demand a confirmation from our side, stating whether we are processing personal data concerning you. If that is the case, you are entitled to intelligence about these personal data and to further information, as stated in article 15 of the General Data Protection Regulation (GDPR).`,
  LEGAL_PRIVACY_RECTIFIY_RIGHT_HEADER: 'Right to Rectify',
  LEGAL_PRIVACY_RECTIFIY_RIGHT: `According to article 16 of the General Data Protection Regulation (GDPR) you are entitled to demand of us to rectify any incorrect personal data concerning you with no delay. With regard to the processing purposes you are also entitled to demand incomplete personal data to be completed – also by an additional statement.`,
  LEGAL_PRIVACY_ERASURE_RIGHT_HEADER: 'Right to Erasure',
  LEGAL_PRIVACY_ERASURE_RIGHT: `You have the right to demand of us to delete your personal data with no delay. We are obligated to delete any personal data immediately, if the relevant conditions according to article 17 of the General Data Protection Regulation (GDPR) are met. For details, we would like to refer you to article 17 of the General Data Protection Regulation (GDPR).`,
  LEGAL_PRIVACY_RESTRICT_RIGHT_HEADER: 'Right to Restrict the Processing',
  LEGAL_PRIVACY_RESTRICT_RIGHT: `According to article 18 of the General Data Protection Regulation (GDPR) you are entitled to demand of us to limit the processing of your personal data, provided certain pre-conditions are met.`,
  LEGAL_PRIVACY_PORTABILITY_RIGHT_HEADER: 'Right of Data Portability',
  LEGAL_PRIVACY_PORTABILITY_RIGHT: `According to article 20 of the General Data Protection Regulation (GDPR) you are entitled to receive your personal data you have provided to us in a structured, established and machine-readable format. You are also entitled to transfer the data to another responsible party without any hinderance from our side, provided the processing is based on any agreement based on article 6 paragraph 1 letter a, or article 9 paragraph 2 letter a, or on a contract according to article 6 paragraph 1 letter b, and the transfer is done via automated tools.`,
  LEGAL_PRIVACY_OBJECT_RIGHT_HEADER: 'Right to Object',
  LEGAL_PRIVACY_OBJECT_RIGHT: `According to article 21 of the General Data Protection Regulation (GDPR) you are entitled to object against your personal data’s processing based on article 6 paragraph e or f of the General Data Protection Regulation (GDPR). This does also apply for profiling based on these regulations.
  <br /><br />
  If we are processing your personal data for direct advertisement, you have the right to object to the processing of your personal data for the use of such advertisement at any time. This does also apply to profiling, if it is connected to such advertisement.
  <br /><br />
  If you would like to exercise any of the rights you are entitled to, please contact us as the responsible party via the contact data given above, or use any of the other ways of contact provided to notify us. If you have any questions regarding this, please contact us.`,
  LEGAL_PRIVACY_SUPERVISORY_AUTHORITY_HEADER:
    'Existence of a Right of Appeal to the Supervisory Authority',
  LEGAL_PRIVACY_SUPERVISORY_AUTHORITY: `According to article 77 of the General Data Protection Regulation (GDPR) you are entitled to the right of appeal to the supervisory authority without prejudice to any wider administrative or judicial remedy. This right applies particularly within the member state of your abode, of your working place or of the suspected contravention, if you consider the processing of your personal data to be a violation of the General Data Protection Regulation (GDPR).`,
};
