import { connect } from 'react-redux';

import LovenseInstallation from './LovenseInstallation';

const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  unconfirmed: state.user?.account?.state === 'unconfirmed',
});

export default connect(mapStateToProps)(LovenseInstallation);

