import './Wiki.scss';

import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';

import { getWikiRoutes } from '../../../routes';
import HeaderMenu from '../../HeaderMenu/HeaderMenu';
import Section from '../../Section/Section';
import SpinnerComponent from '../../Spinner/SpinnerComponent';
import WikiContext from './WikiContext';

function Wiki({ hasContest }) {
  const intl = useIntl();
  const location = useLocation();
  const [items, setItems] = useState(null);

  const [routes, setRoutes] = useState(null);
  const [headerTitle, setHeaderTitle] = useState('');
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const contextValue = useMemo(() => {
    return { setIsLoading };
  }, []);

  useEffect(() => {
    if (location.pathname === '/wiki') {
      if (hasContest) {
        history.push('/wiki/contest');
      } else {
        history.push('/wiki/start');
      }
    }
  }, [history, hasContest, location.pathname]);

  useEffect(() => {
    let items = getWikiRoutes(hasContest).filter((item) => !item.notInMenu);

    const headerItems = [...items];

    const routes = items.map((route, i) => {
      if (history.location.pathname.includes(route.path)) {
        setHeaderTitle(intl.formatMessage({ id: route.headerTitle }));
      }
      return (
        <Route
          key={`/wiki${route.path}`}
          path={`/wiki${route.path}`}
          component={route.component}
        />
      );
    });

    if (hasContest) {
      routes.push(<Redirect key="redirect-to/contest" to="/wiki/contest" />);
    }
    setItems(headerItems);
    setRoutes(routes);
  }, [history.location.pathname, hasContest, intl]);

  return (
    <div className="page-wrapper">
      <Section title={headerTitle} className="component-section">
        <>
          {' '}
          {items ? (
            <HeaderMenu
              className="header-menu-wrapper"
              defaultPath={`/wiki`}
              items={items}
            />
          ) : null}
          <div className={'component-wrapper'}>
            {isLoading && <SpinnerComponent />}
            <WikiContext.Provider value={contextValue}>
              <Switch>{routes}</Switch>
            </WikiContext.Provider>
          </div>
        </>
      </Section>
    </div>
  );
}

export default Wiki;
