import { SPINNER } from '../actions/actions';

const initialState = {
  active: false,
  indicator: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SPINNER:
      return {
        active: action.payload?.active || action.payload,
        indicator: action.payload?.indicator,
      };
    default:
      return state;
  }
};

export default reducer;
