import { default as activate } from './activate';
import { default as removeDelayed } from './delayed/remove';
import { default as confirmPlay } from './play/confirm';
import { default as reset } from './reset';
import { default as update } from './update';
import { default as updateVolume } from './volume/update';
export default {
  update,
  activate,
  reset,
  updateVolume,
  confirmPlay,
  removeDelayed,
};
