export default {
  INFOCARD_HEADLINE: `User Infocard`,
  INFOCARD_INTRO: `Im {link} findest Du im rechten Arbeitsbereich alle Informationen zum ausgewählten User. Diese Infos können hilfreich sein, um dem User erste Fragen zu stellen oder auf bestimmte Merkmale einzugehen. Und so ist der User Infobereich aufgebaut:`,
  INFOCARD_INTRO_LINK: `Multi Messenger`,
  INFOCARD_USERINFO: `User Info`,
  INFOCARD_USERINFO_TEXT: `Hier gibt es jede Menge Informationen über den User.`,
  INFOCARD_USERINFO_TEXT_1: `Folgende Informationen kannst Du einsehen:`,
  INFOCARD_NOTES: `Notizen`,
  INFOCARD_NOTES_TEXT: `Sobald Du mit dem User in Kontakt stehst und er etwas von sich preisgibt, dass nicht in den Profilinformationen zu finden ist, kannst Du Dir unter diesem Tab Notizen machen. So hast Du alles Wichtige auch für spätere Nachrichten griffbereit.`,
  INFOCARD_GAMES: `4Score`,
  INFOCARD_GAMES_TEXT: `Hast Du mit dem User bereits 4Score gespielt, so findest Du aktuelle Spiele unter diesem Tab.`,

  INFOCARD_USERINFO_PICTURE: `Profilbild`,
  INFOCARD_USERINFO_PICTURE_TEXT: `Du kannst das Profilbild vergrößern, indem Du auf die Lupe neben dem Bild klickst. Sind Dir die Bilder Deiner Kunden zu explizit? In den {link} kannst Du den Safe Mode aktivieren und siehst dann keine anzüglichen Bilder mehr.`,
  INFOCARD_USERINFO_PICTURE_TEXT_1: `Außerdem findest Du in diesem Bereich den Online Status, den Nickname des Users (er hat genauso wie Du ein Pseudonym als Namen) und von welcher Plattform der Kunde kommt.`,
  INFOCARD_USERINFO_PICTURE_LINK: `Optionen`,

  INFOCARD_USERINFO_FEATURES: `Features`,
  INFOCARD_USERINFO_FEATURES_TEXT: `Schau Dir an welche kostenpflichtigen Features der Kunde nutzen kann.`,

  INFOCARD_USERINFO_DATA: `Wichtige Daten`,
  INFOCARD_USERINFO_DATA_TEXT: `Alter, Geschlecht, Herkunft und Sprache siehst Du auf den ersten Blick. Für persönliche Angaben des Kunden, klicke auf den Button "Mehr".`,
  INFOCARD_USERINFO_PERSONAL: `Persönliche Angaben`,

  INFOCARD_USERINFO_REVENUE: `Umsätze`,
  INFOCARD_USERINFO_REVENUE_TEXT: `Hier findest Du eine Übersicht der Umsätze nach verschiedenen Kategorien aufgeschlüsselt. Dadurch erhältst Du einen Überblick darüber, wie sich die Einnahmen mit dem Kunden zusammensetzen. So kannst Du leicht erkennen, ob der Kunde hauptsächlich in Deine Livecam kommt, Dir Nachrichten schickt, Premium-Dateianhänge kauft oder Deine Foto- und Video Galerien anschaut.`,
};
