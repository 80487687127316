import './CropPreview.scss';

import React, { useEffect, useRef } from 'react';

function CropPreview({ text, imgRef, is169, is43, wideScreen, completedCrop }) {
  const previewCanvasRef = useRef(null);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const crop = completedCrop;
    const pixelRatio = window.devicePixelRatio;

    const canvas = previewCanvasRef.current;
    const ctx = canvas.getContext('2d');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = wideScreen
      ? is43
        ? ((crop.width * 3) / 4) * pixelRatio
        : crop.width * pixelRatio
      : crop.width * pixelRatio;
    canvas.height = wideScreen
      ? crop.height * pixelRatio
      : is169
      ? (crop.height / 16) * 9 * pixelRatio
      : crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    const newWidth = (crop.width * scaleX * 3) / 4;
    const newX = crop.x * scaleX + (crop.width * scaleX - newWidth) / 2;

    const newHeight = ((crop.height * scaleY) / 16) * 9;
    const newY = crop.y * scaleY + (crop.height * scaleY - newHeight) / 2;
    ctx.drawImage(
      image,
      is43 ? newX : crop.x * scaleX,
      wideScreen ? crop.y * scaleY : is169 ? newY : crop.y * scaleY,
      is43 ? newWidth : crop.width * scaleX,
      wideScreen
        ? crop.height * scaleY
        : is169
        ? newHeight
        : crop.height * scaleY,
      0,
      0,
      is43 ? newWidth / scaleX : crop.width,
      wideScreen ? crop.height : is169 ? newHeight / scaleY : crop.height
    );
  }, [completedCrop, imgRef, is169, wideScreen, is43]);

  return (
    <div className="crop-preview">
      <canvas
        ref={previewCanvasRef}
        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
        className="full-size"
      />

      <div className="preview-text">{text}</div>
    </div>
  );
}

export default CropPreview;

