import { connect } from 'react-redux';

import { closeOverlay, socketService } from '../../actions';
import IdleDialog from './IdleDialog';

const mapDispatchToProps = (dispatch) => ({
  closeOverlay: (stamp) => dispatch(closeOverlay(stamp)),
  keepAlive: () => dispatch(socketService.keepAlive()),
  keepIdle: () => dispatch(socketService.keepIdle()),
});

export default connect(null, mapDispatchToProps)(IdleDialog);
