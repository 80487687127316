import { connect } from 'react-redux';

import { camUser } from '../../../../../../actions';
import NavButton from './NavButton';

const mapDispatchToProps = (dispatch) => ({
  selectfirst: () => dispatch(camUser.selectfirst()),
  selectend: () => dispatch(camUser.selectend()),
});

export default connect(null, mapDispatchToProps)(NavButton);
