import React from 'react';

const ReviewHeadline = ({ user, intl, header = false }) => {
  return (
    <div className="review-header intro-text">
      {header && (
        <h1 className="headline">
          {intl.formatMessage({ id: 'ONBOARDING_TITLE' })}
        </h1>
      )}
      <p>
        {user?.account?.review_state?.length > 1
          ? intl.formatMessage({
              id: 'ONBOARDING_REVIEW_HEADLINE_2',
            })
          : intl.formatMessage({
              id: 'ONBOARDING_REVIEW_HEADLINE_1',
            })}
      </p>
    </div>
  );
};

export default ReviewHeadline;

