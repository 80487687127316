import './LandingPageHeadline.scss';

import classNames from 'classnames';
import React from 'react';

function LandingPageHeadline({ children, multipleItems }) {
  const landingPageHeadlineClass = classNames('landing-page-headline-wrapper', {
    'multiple-items': multipleItems,
  });

  return <div className={landingPageHeadlineClass}>{children}</div>;
}

export default LandingPageHeadline;
