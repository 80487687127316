export default {
  SETTINGS_HEADLINE: `Settings`,
  SETTINGS_INTRO: `In this area you can make all the {link} for your account, livecam and prices.`,
  SETTINGS_INTRO_LINK: `settings`,
  SETTINGS_CONTENT_STEP_1: `Options`,
  SETTINGS_CONTENT_STEP_1_CONTENT: `Choose which features you would like to use. You can adjust these at any time. You can find an explanation of the individual features in the info box.`,
  SETTINGS_CONTENT_STEP_1_1_CONTENT: `You can also set your {link} here and you can decide which email notifications you would like to receive from us.`,
  SETTINGS_CONTENT_STEP_1_LINK: `Creator tags`,
  SETTINGS_CONTENT_STEP_2: `Livecam`,
  SETTINGS_CONTENT_STEP_2_CONTENT: `In the stream settings you select your webcam and, if you want, your microphone. The software checks the hardware and software configuration as well as the bandwidth of your Internet connection and uses this to determine the best possible transmission value. We'll show you {link} how you can get started successfully with your webcam.`,
  SETTINGS_CONTENT_STEP_2_LINK: `here in the Wiki`,
  SETTINGS_CONTENT_STEP_3: `Lovense toy`,
  SETTINGS_CONTENT_STEP_3_CONTENT: `In order to be able to offer a Lovense Toy in the live cam, you must first allow the use of the feature under Options. The connection wizard will then guide you through the next steps. You can find {link} in the Wiki.`,
  SETTINGS_CONTENT_STEP_3_LINK: `detailed information`,
  SETTINGS_CONTENT_STEP_4: `Prices`,
  SETTINGS_CONTENT_STEP_4_CONTENT: `Set the price per minute in the livecam and how much a message should cost your customers.`,
};
