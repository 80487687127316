export default {
  KPI_PROFILE_IMAGE: `Profile Image`,
  KPI_PROFILE_IMAGE_TOOLTIP: `This value shows how appealing customers find your current profile picture. The value is determined based on customer voting.`,
  KPI_ICEBREAKER: `Icebreaker`,
  KPI_ICEBREAKER_TOOLTIP: `This value provides information on how well customers respond to a contact via Icebreaker in the chat. 
  This value is influenced, among other things, by your profile picture and chat behavior (quality, response time, response rate).`,
  KPI_ONLINE_TIME: `Online time`,
  KPI_ONLINE_TIME_TOOLTIP: `This value stands for your daily online time in the portal or messenger.`,
  KPI_CHAT_QUALITY: `Chat Quality`,
  KPI_CHAT_QUALITY_TOOLTIP: `This value indicates how long you can bind each customer to you in the chat. A low value is a sign of many short chats.`,
  KPI_RESPONSE_RATE: `Response rate`,
  KPI_RESPONSE_RATE_TOOLTIP: `The response rate represents how reliably you respond to messages from customers.
  If you do not reply to all messages, this has a negative effect.`,
  KPI_RESPONSE_TIME: `Response time`,
  KPI_RESPONSE_TIME_TOOLTIP: `This value indicates how long the customer waits for an answer from you on average.`,
  KPI_PREMIUM_ATTACHMENTS: `Premium attachments`,
  KPI_PREMIUM_ATTACHMENTS_TOOLTIP: `This value indicates how many of your sent paid file attachments are bought by customers.`,
  KPI_QUOTA: `Quota`,
};
