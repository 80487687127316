export default {
  TOYCONTROL_HEADLINE: 'Connection',
  TOYCONTROL_TOY_INTENSITY: 'Toy Intensity',
  TOYCONTROL_TOY_INTENSITY_TEXT:
    'Here you can set the maximum intensity of your Lovense Toy.',
  TOYCONTROL_CURRENT_STATE: 'Current status',
  TOYCONTROL_CONNECTED: 'connected',
  TOYCONTROL_NOT_CONNECTED: 'not connected',
  TOYCONTROL_CONNECTION_TEXT:
    'If you have previously connected your Lovense toy in LiveCreator, turn it on now and connect.',
  TOYCONTROL_CONNECTION_ASSISTENT: 'Connection assistent',
  TOYCONTROL_CONNECTION_DESCRIPTION:
    'If you have never connected your Lovense toy to your LiveCreator account, first download the Lovense Connect app and connect your Lovense toy to the app.',
  TOYCONTROL_NEXT_BTN_LABEL: 'next',
  TOYCONTROL_ASSISTENT_HEADLINE_1: 'Install app & connect toy',
  TOYCONTROL_ASSISTENT_TEXT_1:
    'Tap next when your toy is connected to the app.',
  TOYCONTROL_ASSISTENT_HEADLINE_2: 'Scan QR code with the app',
  TOYCONTROL_ASSISTENT_TEXT_2: 'or enter these numbers in the desktop app',
  TOYCONTROL_ASSISTENT_HEADLINE_3: 'Done!',
  OPTIONS_FEATURES_TOYCONTROL: 'Lovense Toy',

  toycontrol_marketing_headline: 'Use your Lovense toys now',
  toycontrol_marketing_text1:
    '<span class="bold">What are Lovense Toys?</span><br />Lovense\'s erotic love toys are online remote controllable vibrators. They come in many varieties. From the vibrator to the plug, to the vibrating egg or the lay-on vibrator, you can use all the previously known Lovense toys with us.',
  toycontrol_marketing_text2:
    '<span class="bold">How can you use the toy?</span><br />You can make your cam shows even more interactive and hotter with these toys. The customer can control the intensity and duration of the connected toy and thus give you additional desire and joy. In this way you offer your customers the opportunity to actively participate in your pleasure.',
  toycontrol_marketing_text3:
    '<span class="bold">What do you get out of it as a creator?</span><br />With the toy feature, we offer you an additional and very profitable source of income. The customer can choose between 4 different levels. With increasing duration / intensity of the levels, the price increases and thus your earnings. Of course, you have the option beforehand to set the intensity of the individual levels in which you like to feel them.',
  toycontrol_marketing_text4:
    '<span class="bold">What do you need to start?</span><br />You need a toy from the Lovense company. To connect your toy to your computer, you need either the Lovense Connect app for your mobile phone or optionally the USB stick (available separately from Lovense). Then you and your customers can get started immediately.',
  toycontrol_marketing_text5:
    "If you need help setting up LiveCreator, we're here to help.",

  TOYCONTROL_NOT_ACTIVE: `You have not activated the Lovense Toy feature. Go into the Options and set it to "Yes".`,
  TOYCONTROL_NO_TOY: 'none',
  TOYCONTROL_HAS_ACTIVATED_TOY: 'has activated your toy',
  TOYCONTROL_USERS_SG: 'user',
  TOYCONTROL_USERS_PL: 'users',
  TOYCONTROL_USERS_WAITING_SG: 'is waiting',
  TOYCONTROL_USERS_WAITING_PL: 'are waiting',
  TOYCONTROL_SECONDS: 'sec',
  TOYCONTROL_APP_OFFLINE:
    'Your Lovense app is offline. Check your devices internet connection and ensure, the app is running and not in the background. Check also, that there is no energy saving mode or screensaver active. Please stop the stream and reconnect your Lovense Connect App to use your toy again.',

  TOYCONTROL_PRICE_LIST_HEADLINE: `The intensities and prices in detail:`,
  TOYCONTROL_PRICE_LIST: `<table class="noBorder toyPrice noHover">
         <tr>
            <td class="low">Low</td>
            <td class="align-left">10 seconds</td>
            <td>15 Coins</td>
         </tr>
         <tr>
            <td class="medium">Medium</td>
            <td class="align-left">10 seconds</td>
            <td>20 Coins</td>
         </tr>
         <tr>
            <td class="high">High</td>
            <td class="align-left">10 seconds</td>
            <td>25 Coins</td>
         </tr>
         <tr>
            <td class="omg">OMG</td>
            <td class="align-left">15 seconds</td>
            <td>40 Coins</td>
         </tr>
      </table>`,
  TOYCONTROL_TEST_BUTTON: 'Test 15 seconds',
  TOYCONTROL_TEST_NO_ACTIVE_TOY: 'No active Toy connected.',
  TOYCONTROL_VIDEO_HEADLINE_1:
    'Setting up with the smartphone <span class="textcolor">(recommended)</span>',
  TOYCONTROL_VIDEO_HEADLINE_2: 'Setting up with the desktop app',
};
