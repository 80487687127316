import './Preview.scss';

import React from 'react';

import fmotdImg from '../../../../../../assets/img/logo-fmotd.png';

function Preview({ item }) {
  return (
    <div
      className="preview-image"
      style={{
        backgroundImage: `url('${process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES}${item.thumbnail}')`,
      }}
    >
      {item.state === 'current' ? (
        <img src={fmotdImg} className="fmotd-logo" alt="preview" />
      ) : null}
    </div>
  );
}

export default Preview;
