import { connect } from 'react-redux';

import FirstSteps from './FirstSteps';

function getConfirmedProfileImage(user) {
  if (user?.account?.image?.file) {
    return (
      process.env.REACT_APP_IMAGE_URL_2_MEDIAFILES + user?.account?.image?.file
    );
  }
  return null;
}
const mapStateToProps = (state) => ({
  locale: state.intl.locale,
  profileImage: getConfirmedProfileImage(state.user),
});

export default connect(mapStateToProps)(FirstSteps);
