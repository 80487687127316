import close from './close';
import error from './error';
import message from './message';
import open from './open';

export default (ratatoskrService) => ({
  open: open(ratatoskrService),
  message,
  error,
  close,
});
