import { MANAGEMENT_LOGIN_STARTED } from '../../../../constants';
import { sender as senderService } from '../../../../services';
import { logoutSender } from '../../../';
import { MANAGEMENT_EVENTS_CONNECTION_ERROR } from '../../../actions';
export default (management) => (reason) => {
  return (dispatch, getState) => {
    const {
      streaming: { managementStep },
      sender: {
        software: { origin: softwareOrigin },
        server: { serviceURL },
      },
    } = getState();
    const logger = senderService.logger({ serviceURL, softwareOrigin });
    logger.error(`io error: ${reason}`, {}, management.lastSocketId());
    if (managementStep === MANAGEMENT_LOGIN_STARTED) {
      //TODO Cam logout or logout????
      return dispatch(logoutSender(reason, 16, 3));
    }
    dispatch({
      type: MANAGEMENT_EVENTS_CONNECTION_ERROR,
      reason,
    });
  };
};
