import { lStorage } from '../../../services/storage';
import { TOYCONTROLDATA_CHANGED } from '../../actions';
import { setActiveToy } from '../..';

export default (payload) => (dispatch, getState) => {
  if (getState().user?.account?.toy_control !== 'enabled') {
    return;
  }
  // TODO check, what happens, if the active toy is not present anymore,
  // since the call is split in data changed and setActive
  dispatch({
    type: TOYCONTROLDATA_CHANGED,
    payload,
  });
  // The saved toyId can be different to the active toyId
  // e.g if the toy becomes inactive (status 0), the active item goes to none
  // but the saved toyId stays, so when it becomes active again it is selected
  const savedToyId = lStorage.getItem('toyId');

  let activeToyId;
  let save = true;
  if (
    (savedToyId &&
      payload?.payload?.connected?.toys &&
      payload?.payload?.connected?.toys[savedToyId]) ||
    savedToyId === 'none'
  ) {
    //If none is saved or the toyId is part of the connected toys
    activeToyId = savedToyId;
  } else if (
    payload?.payload?.connected?.toys &&
    Object.keys(payload?.payload?.connected?.toys).length > 0 &&
    (typeof getState().sender?.toycontrol?.activeToy === 'undefined' ||
      getState().sender?.toycontrol?.activeToy !== '')
  ) {
    //There are connected toys and activeToy has not been set or is set to a not available toy
    activeToyId = Object.keys(payload?.payload?.connected?.toys).shift();
  } else {
    //There are no connected toys
    //set active toy empty
    activeToyId = '';
    save = false;
  }

  if (activeToyId !== '') {
    // if there are no active toys, reset the activeToy if set before
    const toys = getState().sender?.toycontrol?.connected?.toys || {};
    // Status 0 means that the connected toy is inactive
    if (parseInt(toys[activeToyId]?.status, 10) === 0) {
      activeToyId = '';
      save = false;
    }
  }

  dispatch(setActiveToy(activeToyId, save));
};
