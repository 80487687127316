import { connect } from 'react-redux';

import { user as userUtils } from '../../../../../../utils';
import User from './User';

const mapStateToProps = (state) => {
  const {
    camUser: { camUsers },
    dialog: { dialogs },
    view: { isMobile },
  } = state;
  const user = userUtils.activeUser([...camUsers, ...dialogs]) || {};
  return {
    name: user?.name,
    userId: user?.userId,
    profileCountry: user?.profile?.info?.country?.toLowerCase(),
    typing: user.typing,
    isMobile,
    showStartTime: user?.showStartTime,
    isLivecam: user?.type !== 'dialog.fetched',
  };
};

export default connect(mapStateToProps)(User);
