import '../PersonalData/PersonalData.scss';

import useAxios from 'axios-hooks';
import { Field, useFormikContext } from 'formik';
import _ from 'lodash';
import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import FieldWithErrors from '../../../../Forms/FieldWithErrors/FieldWithErrors';
import Input from '../../../../Forms/Input/Input';
import Select from '../../../../Forms/Select/Select';
import StepperContext from '../../../../Stepper/StepperContext';
import {
  checkIfAllFieldsAreValid,
  checkIfAnyFieldsAreTouched,
  checkIfReviewFieldsAreTouched,
  generateRequestData,
  hasReviewError,
  reviewDisabledField,
} from '../../Onboarding.functions';
import {
  getDayOptions,
  getMonthOptions,
  getYearOptions,
  onDateChange,
} from './PersonalData.functions';

function PersonalData({
  item,
  update = () => {},
  user = {},
  spinner = () => {},
}) {
  const {
    currentItemIndex,
    nextItemIndex,
    setCurrentItemIndex,
    markCurrentItemAsValid,
    markCurrentItemAsInvalid,
    isLoading,
    sendingToReview,
    setSendingToReview,
  } = useContext(StepperContext);

  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    setFieldTouched,
    errors,
  } = useFormikContext();

  const intl = useIntl();

  const [
    { data: accountData, error: accountError, loading: isAccountLoading },
    accountRequest,
  ] = useAxios(
    {
      url: '/user/private',
      method: 'PUT',
      data: generateRequestData(user, values, item),
    },
    {
      manual: true,
    }
  );

  const [
    { error: accountPublicError, loading: isAccountPublicLoading },
    accountPublicRequest,
  ] = useAxios(
    {
      url: '/user/public',
      method: 'PUT',
    },
    {
      manual: true,
    }
  );

  const targetRef = useRef();
  useEffect(() => {
    if (targetRef?.current) {
      targetRef.current.scrollIntoView({
        behavior: 'instant',
        block: 'end',
      });
    }
  }, []);

  useEffect(() => {
    if (
      nextItemIndex === null ||
      nextItemIndex === currentItemIndex ||
      isLoading
    ) {
      return;
    }
    if (checkIfAnyFieldsAreTouched(item.fields, touched)) {
      if (checkIfAllFieldsAreValid(item.fields, errors)) {
        spinner(true);
        accountPublicRequest({ data: { birthday: values.birthday } });
        accountRequest();
      } else {
        markCurrentItemAsInvalid();
        setCurrentItemIndex(nextItemIndex);
      }
    } else {
      setCurrentItemIndex(nextItemIndex);
    }
  }, [
    accountRequest,
    accountPublicRequest,
    currentItemIndex,
    errors,
    isLoading,
    item.fields,
    markCurrentItemAsInvalid,
    nextItemIndex,
    setCurrentItemIndex,
    spinner,
    touched,
    values,
  ]);

  useEffect(() => {
    if (sendingToReview !== 'pending') {
      return;
    }
    accountRequest();
    spinner(true);
  }, [accountRequest, sendingToReview, spinner]);

  useEffect(() => {
    if (!isAccountLoading && !isAccountPublicLoading) {
      return;
    }
    spinner(true);
  }, [spinner, isAccountLoading, isAccountPublicLoading]);

  useEffect(() => {
    if (!accountData || nextItemIndex === currentItemIndex) {
      return;
    }
    spinner(false);

    if (user.account.state !== 'review_needed') {
      setTouched({
        forename: false,
        surname: false,
        birthday: false,
      });
      update({ private: accountData });
      markCurrentItemAsValid();
    } else {
      const propertiesToReview = _.intersection(
        user.account.review_state,
        item.fields
      );
      if (checkIfReviewFieldsAreTouched(propertiesToReview, touched)) {
        const updatedReviewState = _.union(
          user.account.review_state_changed_fields,
          propertiesToReview
        );
        update({
          private: accountData,
          account: {
            ...user.account,
            review_state_changed_fields: updatedReviewState,
          },
        });
        markCurrentItemAsValid();
      }
    }
    setCurrentItemIndex(nextItemIndex);
  }, [
    accountData,
    currentItemIndex,
    item.fields,
    markCurrentItemAsValid,
    nextItemIndex,
    setCurrentItemIndex,
    setTouched,
    spinner,
    touched,
    update,
    user.account,
  ]);

  useEffect(() => {
    if (!accountData || sendingToReview !== 'pending') {
      return;
    }
    const propertiesToReview = _.intersection(
      user.account.review_state,
      item.fields
    );
    const updatedReviewState = _.union(
      user.account.review_state_changed_fields,
      propertiesToReview
    );
    update({
      private: accountData,
      account: {
        ...user.account,
        review_state_changed_fields: updatedReviewState,
      },
    });
    spinner(false);
    if (item.isError) {
      markCurrentItemAsValid();
    }
    setSendingToReview('ready');
  }, [
    accountData,
    item,
    markCurrentItemAsValid,
    sendingToReview,
    spinner,
    setSendingToReview,
    update,
    user,
  ]);

  useEffect(() => {
    if (
      (!accountError && !accountPublicError) ||
      nextItemIndex === currentItemIndex
    ) {
      return;
    }
    spinner(false);
    markCurrentItemAsInvalid();
    setCurrentItemIndex(nextItemIndex);
  }, [
    accountError,
    accountPublicError,
    currentItemIndex,
    markCurrentItemAsInvalid,
    nextItemIndex,
    setCurrentItemIndex,
    spinner,
  ]);

  useEffect(() => {
    if (user.account.state !== 'review_needed' || !item) {
      return;
    }
    const propertiesToReview = _.intersection(
      user.account.review_state,
      item.fields
    );
    if (
      checkIfReviewFieldsAreTouched(propertiesToReview, touched) &&
      item.isError
    ) {
      markCurrentItemAsValid();
    }
  }, [item, markCurrentItemAsValid, touched, user]);

  return (
    <div className="content-inner personal-data-step">
      <span ref={targetRef} />
      <h1 className="step-headline">
        <FormattedMessage id="PERSONAL_DATA" />
      </h1>
      <p>
        <FormattedMessage id="PERSONAL_DATA_SUB">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>

      <FieldWithErrors
        as={Input}
        name="forename"
        label="FIELD_FIRST_NAME"
        tabIndex="1"
        reviewError={
          hasReviewError(user, 'forename', touched.forename)
            ? intl.formatMessage({ id: 'FORENAME_REVIEW_ERROR' })
            : null
        }
        disabled={isLoading || reviewDisabledField(user, 'forename')}
        maxLength="64"
        onChange={(event) => {
          setFieldTouched('forename', true);
          setFieldValue('forename', event.target.value);
        }}
      />
      <FieldWithErrors
        as={Input}
        name="surname"
        label="FIELD_LAST_NAME"
        tabIndex="2"
        reviewError={
          hasReviewError(user, 'surname', touched.surname)
            ? intl.formatMessage({ id: 'SURNAME_REVIEW_ERROR' })
            : null
        }
        disabled={isLoading || reviewDisabledField(user, 'surname')}
        maxLength="64"
        onChange={(event) => {
          setFieldTouched('surname', true);
          setFieldValue('surname', event.target.value);
        }}
      />
      <FieldWithErrors
        name="birthday"
        label="FIELD_BIRTHDAY"
        reviewError={
          hasReviewError(user, 'birthday', touched.birthday)
            ? intl.formatMessage({ id: 'BIRTHDAY_REVIEW_ERROR' })
            : null
        }
      >
        <div className="date-selects">
          <Field
            as={Select}
            name="birthdayDay"
            tabIndex="3"
            intlTranslate={false}
            disabled={isLoading || reviewDisabledField(user, 'birthday')}
            options={getDayOptions(values.birthdayYear, values.birthdayMonth)}
            onChange={(e) =>
              onDateChange(e, values, setFieldValue, setFieldTouched)
            }
          />

          <Field
            as={Select}
            name="birthdayMonth"
            tabIndex="4"
            intlTranslate={false}
            disabled={isLoading || reviewDisabledField(user, 'birthday')}
            options={getMonthOptions(intl)}
            onChange={(e) =>
              onDateChange(e, values, setFieldValue, setFieldTouched)
            }
          />

          <Field
            as={Select}
            name="birthdayYear"
            tabIndex="5"
            intlTranslate={false}
            disabled={isLoading || reviewDisabledField(user, 'birthday')}
            options={getYearOptions()}
            onChange={(e) =>
              onDateChange(e, values, setFieldValue, setFieldTouched)
            }
          />
        </div>
      </FieldWithErrors>

      <div className="bottom">
        <hr />
        <p>
          <FormattedMessage id="PERSONAL_DATA_DESCRIPTION_CONTENT_1">
            {(message) => (
              <span dangerouslySetInnerHTML={{ __html: message[0] }} />
            )}
          </FormattedMessage>
        </p>
        <p className="no-margin">
          <FormattedMessage id="PERSONAL_DATA_DESCRIPTION_CONTENT_2" />
        </p>
      </div>
    </div>
  );
}

export default PersonalData;
