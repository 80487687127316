const defaultInterval = 3000;

let timer;

const start = (management) => (interval) => (dispatch, getState) => {
  if (!timer) {
    timer = setInterval(() => {
      const {
        streaming: { streamInfoCounter, isReconnecting },
        streamingstats: { fps: currentFPS, bandwidth, bytesSent: byteCount },
      } = getState();

      if (isReconnecting) {
        return;
      }

      if (streamInfoCounter === 0 || !management.connected()) {
        return dispatch(stop());
      }

      management.emit('streamInfo', {
        socketId: management.socketId(),
        currentFPS,
        droppedFrames: 0,
        currentBytesPerSecond: bandwidth * 125,
        byteCount,
      });
    }, interval || defaultInterval);
  }
};

const stop = () => () => {
  clearTimeout(timer);
  timer = null;
};

export default (management) => ({
  start: start(management),
  stop,
});
