let wakeLock = null;
const isSupported = !!navigator?.wakeLock;

const request = () => async () => {
  if (!isSupported) return;

  try {
    wakeLock = await navigator.wakeLock.request('screen');
    console.log('wakeLock requested');
  } catch (error) {
    console.log(`error with wakeLock request ${error.name} : ${error.message}`);
  }
};

const release = () => async () => {
  if (!isSupported) return;

  wakeLock &&
    wakeLock.release().then(() => {
      console.log('wakeLock released');
      wakeLock = null;
    });
};

export default {
  request,
  release,
};
