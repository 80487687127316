export default {
  VISIBILITY_HEADLINE: `Visibility and presence – draw attention to yourself!`,
  VISIBILITY_INTRO: `In this article, we will look at the visibility and presence of your profile in the community products. This is significantly influenced by your own activity and is a basic prerequisite for as many users as possible to become aware of you and find their way to you.`,
  VISIBILITY_INTRO_1: `A crucial aspect is the online presence and the overview and processing of incoming messages. As a rule, users automatically receive a message from you as an initial contact within the framework of the {link}. The first thing they notice besides the animation text is either an image combined with the icebreaker, or they take a look at your profile.`,
  VISIBILITY_INTRO_1_LINK: `Icebreaker system`,
  VISIBILITY_INTRO_2: `So, if you're only online for a short time each day, you're less likely to have a user reply to you because they're looking for active communication. However, online presence is not the only way. `,
  VISIBILITY_INTRO_3: `There are many other ways that can help you continue to attract the attention of new and familiar users. In the following, we explain in detail how your profile enjoys stable and steadily increasing profile visits through a good design, your personal diary and regular gallery updates.`,

  VISIBILITY_HEADLINE_1: `You can get attention by:`,
  VISIBILITY_CONTENT_STEP_1: `Designing your profile`,
  VISIBILITY_CONTENT_STEP_1_CONTENT: `{link} is the first impression a customer gets of you. An outdated or blurry profile photo can quickly scare off potential customers. Show your activity by updating your image regularly. Experiment with different outfits or adapt your images to the holidays/seasons. Emphasize your style and personality. These adjustments will keep your profile fresh and show that you are making an effort to continuously maintain and improve it.`,
  VISIBILITY_CONTENT_STEP_1_LINK: `Your profile`,
  VISIBILITY_CONTENT_STEP_1_1_CONTENT: `<span class="bold">Tip:</span> Be sure to use`,
  VISIBILITY_CONTENT_STEP_1_2_CONTENT: ` {link} in your settings and describe yourself with some characteristics. Users who search for these traits will receive your profile as a suggestion if they match.`,
  VISIBILITY_CONTENT_STEP_1_1_LINK: `Creator tags`,
  VISIBILITY_CONTENT_STEP_2: `Diary`,
  VISIBILITY_CONTENT_STEP_2_CONTENT: `Another way to increase your visibility is to keep a {link}. Through regular entries, your regular customers get insights into your everyday life, your thoughts and announcements about upcoming shows or new galleries. If you add an appealing image to your post, it will immediately catch the eye. The posts are displayed on the homepage and thus increase the chances of being discovered. A personal touch can also deepen the bond between you and your customers.`,
  VISIBILITY_CONTENT_STEP_2_LINK: `diary`,
  VISIBILITY_CONTENT_STEP_3: `Photo & Video Galleries`,
  VISIBILITY_CONTENT_STEP_3_CONTENT: `Creativity also pays off in the area of {link}. New galleries on a regular basis provide an additional incentive for customers to revisit your profile. Here you can show off your versatility or ask customers what they want to create new galleries. It's especially effective to set fixed posting days so that customers become aware of them and visit your profile regularly. With the "publish with schedule" feature, galleries can even be scheduled for the coming weeks. This simplifies the organization because you don't have to think about the next release every time. All galleries are automatically displayed on the homepage, which increases your visibility and attracts new users.`,
  VISIBILITY_CONTENT_STEP_3_LINK: `photo and video galleries`,
  VISIBILITY_CONTENT_STEP_3_1_CONTENT: `<span class="bold">Tip:</span> Use the categories. The more categories you choose (up to 5), the easier it will be for your videos to be found. Many users like to use the search function to discover photos or videos that suit their tastes. By specifying the categories, you increase the chance that your photo and video galleries will be seen and purchased.`,

  VISIBILITY_OUTRO: `Remember, every activity on your profile gives you the opportunity to stand out. An interactive profile with regular updates arouses interest and can thus increase your sales.`,
  VISIBILITY_OUTRO_1: `The motto is clear: Be present, be authentic and be creative. These elements are your capital and the key to standing out from the crowd. By following this advice, you can not only increase your visibility, but also build a closer bond with your customers and attract new customers.`,
};
