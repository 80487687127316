import log from '../../utils/logApi';
export default ({
    error,
    message,
    level = 'warn',
    location = '',
    data = {},
    pathname = window.location.pathname,
    count = '',
    time = new Date(),
  }) =>
  (dispatch, getState) => {
    let userId = '';
    let sendername = '';
    let state = '';
    let language = '';
    if (getState().user?.isLoggedIn) {
      const account = getState().user.account;
      userId = account.userId;
      sendername = account.nickname;
      state = account.state;
      language = account.language;
    }

    const errorObject = {
      location,
      state,
      message,
      sendername,
      userId,
      language,
      level,
      pathname,
      error,
      data,
      count,
      time,
    };

    log(errorObject);
  };
