export default (socketService) => (sent) => (dispatch, getState) => {
  const {
    user: { access_token: token },
  } = getState();

  console.debug('... polo');

  socketService.emit('polo', {
    token,
    received: Date.now(),
    sent,
  });
};
