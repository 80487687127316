import {
  ROOM_TYPE_EVENT,
  ROOM_TYPE_EXCLUSIVE,
  ROOM_TYPE_NORMAL,
  ROOM_TYPE_NORMAL_WITH_VOYEUR,
  SHOW_TYPE_EXCLUSIVE,
  SHOW_TYPE_NORMAL,
  SHOW_TYPE_VOYEUR,
} from '../constants';
import { objectUtils } from './';

const hasExclusiveRoomType = (roomTypes) => exclusiveIndex(roomTypes) > -1;

const hasVoyeurRoomType = (roomTypes) => voyeurIndex(roomTypes) > -1;

const hasEvent = (roomTypes) => eventIndex(roomTypes) > -1;

const normalIndex = (roomTypes) =>
  roomTypes.findIndex((roomType) => roomType.name === ROOM_TYPE_NORMAL);

const voyeurIndex = (roomTypes) =>
  roomTypes.findIndex(
    (roomType) => roomType.name === ROOM_TYPE_NORMAL_WITH_VOYEUR
  );

const exclusiveIndex = (roomTypes) =>
  roomTypes.findIndex((roomType) => roomType.name === ROOM_TYPE_EXCLUSIVE);

const eventIndex = (roomTypes) =>
  roomTypes.findIndex((roomType) => roomType.name === ROOM_TYPE_EVENT);

const isExclusive = (roomTypes) => {
  const exclusiveRoomType = roomTypes.find(
    (roomType) => roomType.name === ROOM_TYPE_EXCLUSIVE
  );
  return exclusiveRoomType && exclusiveRoomType.activated ? true : false;
};

const reduceRoomTypes = (roomTypes) => {
  const result = objectUtils.clone(roomTypes);

  const roomTypeNormalIndex = normalIndex(result);
  const roomTypeVoyeurIndex = voyeurIndex(result);

  if (roomTypeNormalIndex >= 0 && roomTypeVoyeurIndex >= 0) {
    result.splice(roomTypeNormalIndex, 1);
  }

  return result;
};

const hasActivatedShowType = (showType, roomTypes) => {
  return (
    roomTypes &&
    getActivated(roomTypes)?.showTypes.some((el) => el.showType === showType)
  );
};

const hasActivatedShowTypeExclusive = (roomTypes) =>
  hasActivatedShowType(SHOW_TYPE_EXCLUSIVE, roomTypes);
const hasActivatedShowTypeNormal = (roomTypes) =>
  hasActivatedShowType(SHOW_TYPE_NORMAL, roomTypes);
const hasActivatedShowTypeVoyeur = (roomTypes) =>
  hasActivatedShowType(SHOW_TYPE_VOYEUR, roomTypes);

const init = (roomTypes) => activateEventOrNormal(reduceRoomTypes(roomTypes));

const activateEventOrNormal = (roomTypes) =>
  hasEvent(roomTypes) ? activateEvent(roomTypes) : activateNormal(roomTypes);

const activateEvent = (roomTypes) => {
  console.log('Activate Event');
  return roomTypes.map((roomType) => {
    if (roomType.name === ROOM_TYPE_EVENT) {
      roomType.activated = true;
    } else {
      roomType.activated = false;
    }
    return roomType;
  });
};
const activateNormal = (roomTypes) =>
  roomTypes.map((roomType) => {
    if (
      roomType.name === ROOM_TYPE_NORMAL ||
      roomType.name === ROOM_TYPE_NORMAL_WITH_VOYEUR
    ) {
      roomType.activated = true;
    } else {
      roomType.activated = false;
    }
    return roomType;
  });

const activateExclusive = (roomTypes) =>
  roomTypes.map((roomType) => {
    if (roomType.name === ROOM_TYPE_EXCLUSIVE) {
      roomType.activated = true;
    } else {
      roomType.activated = false;
    }
    return roomType;
  });

const getActivated = (roomTypes) =>
  roomTypes.find((roomType) => roomType.activated);

const isExclusiveEnabled = ({ roomTypes }) => {
  return hasExclusiveRoomType(roomTypes);
};
const findRoomType = (showType, roomTypes) => {
  return roomTypes
    .filter(
      (rt) =>
        rt.name === showType ||
        (showType === SHOW_TYPE_NORMAL &&
          rt.name === ROOM_TYPE_NORMAL_WITH_VOYEUR)
    )
    .shift();
};

const addVoyeur = (roomTypes) => {
  if (hasVoyeurRoomType(roomTypes)) {
    return roomTypes;
  }
  const index = normalIndex(roomTypes);

  const newRoomTypes = [...roomTypes];
  newRoomTypes[index].name = ROOM_TYPE_NORMAL_WITH_VOYEUR;
  newRoomTypes[index].showTypes = [
    { showType: SHOW_TYPE_NORMAL },
    { showType: SHOW_TYPE_VOYEUR },
  ];
  return newRoomTypes;
};

const removeVoyeur = (roomTypes) => {
  if (!hasVoyeurRoomType(roomTypes)) {
    return roomTypes;
  }
  const index = voyeurIndex(roomTypes);
  const newRoomTypes = [...roomTypes];
  newRoomTypes[index].name = ROOM_TYPE_NORMAL;
  newRoomTypes[index].showTypes = [{ showType: SHOW_TYPE_NORMAL }];
  return newRoomTypes;
};

const isEvent = (roomTypes) =>
  roomTypes.findIndex((rt) => rt.name === ROOM_TYPE_EVENT) >= 0;

export default {
  hasActivatedShowTypeExclusive,
  hasActivatedShowTypeNormal,
  hasActivatedShowTypeVoyeur,
  getActivated,
  activateNormal,
  activateEventOrNormal,
  activateExclusive,
  init,
  hasExclusiveRoomType,
  hasVoyeurRoomType,
  isExclusive,
  isEvent,
  isExclusiveEnabled,
  findRoomType,
  addVoyeur,
  removeVoyeur,
};
