import './DeclinedFmotd.scss';

import React from 'react';
import { useIntl } from 'react-intl';
import { ReactSVG } from 'react-svg';

import errorIcon from '../../../../../../assets/img/svg/error.svg';

function DeclinedFmotd({ item }) {
  const intl = useIntl();

  return (
    <div className="declined">
      <span className="declined-icon">
        <ReactSVG src={errorIcon} className="icon" wrapper="span" />
      </span>
      <div className="declined-content">
        <span className="declined-header">
          {intl.formatMessage({ id: 'FMOTD_DECLINED' })}
        </span>
        <br />
        {item?.comment}
      </div>
    </div>
  );
}

export default DeclinedFmotd;
