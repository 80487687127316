import { connect } from 'react-redux';

import { callToycontrol } from '../../../../actions';
import ToycontrolSettings from './ToycontrolSettings';

const mapStateToProps = (state) => ({
  ...state.sender.toycontrol,
  isMobile: state.view.isMobile,
  language: state.user?.account?.language,
});

const mapDispatchToProps = {
  callToycontrol,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToycontrolSettings);

