import React from 'react';
import { FormattedMessage } from 'react-intl';

import ProvisionDE from '../../../../../assets/img/wiki/wiki_provision_de.png';
import ProvisionEN from '../../../../../assets/img/wiki/wiki_provision_en.png';
import BannerDE from '../../../../../assets/img/wiki/wiki_referral_banner_de.jpg';
import BannerEN from '../../../../../assets/img/wiki/wiki_referral_banner_en.jpg';
import { lStorage } from '../../../../../services/storage';
import BackButton from '../../../../BackButton';
import CopyInput from '../../../../Forms/CopyInput/CopyInput';

function CreatorReferral({ locale, advId, onBackButtonClicked }) {
  const isModelHomepage = !!lStorage.getItem('mhp');
  const url = `${process.env.REACT_APP_PUBLIC_URL}${
    isModelHomepage ? '/model' : ''
  }?AID=${advId}`;
  return (
    <div className="wiki-content creator-referral-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="CREATOR_REFERRAL_TITLE" />
      </h1>
      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_1" />
      </p>

      {locale === 'en' ? (
        <img className="img-responsive" src={BannerEN} alt="Referral program" />
      ) : (
        <img
          className="img-responsive"
          src={BannerDE}
          alt="Empfehlungsprogramm"
        />
      )}

      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_2">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_3" />
      </p>

      <CopyInput label="CREATOR_REFERRAL_LINK" value={url} />

      <div className="image-wrapper">
        {locale === 'en' ? (
          <img
            className="img-responsive"
            src={ProvisionEN}
            alt="Commission rate"
          />
        ) : (
          <img
            className="img-responsive"
            src={ProvisionDE}
            alt="Provisionsstufen"
          />
        )}
      </div>

      <h2 className="headline">
        <FormattedMessage id="CREATOR_REFERRAL_TITLE_1" />
      </h2>
      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_4" />
      </p>
      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_TABLE">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_5">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_6" />
      </p>
      <p className="bold">
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_7" />
      </p>

      <h2 className="headline">
        <FormattedMessage id="CREATOR_REFERRAL_TITLE_2" />
      </h2>
      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_8">
          {(message) => (
            <span dangerouslySetInnerHTML={{ __html: message[0] }} />
          )}
        </FormattedMessage>
      </p>
      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_9" />
      </p>
      <p>
        <FormattedMessage id="CREATOR_REFERRAL_CONTENT_10" />
      </p>
    </div>
  );
}
export default CreatorReferral;

