import { sendSystemChatMessage } from '../../../actions';
import { SOCKET_EMIT_CALLBACK_TIMEOUT } from '../../../constants';
import { SERVER_ERROR } from '../../../errors';
import { notification, sender as senderService } from '../../../services';
import { timeout } from '../../../utils';
import { sender, uuid } from '../../../utils';
import { getCamData } from '../../../utils/cam';
import { MANAGEMENT_CAM_LOGIN, TOYCONTROL_TOGGLE } from '../../actions';
import { stream, wakeLock } from '../..';

let stamp;

const start = (management) => () => {
  return (dispatch, getState) => {
    notification.askNotification();
    const state = getState();
    const micEnabled =
      state.cam.selectedMicrophone.value !== false && !state.streaming.mute
        ? 1
        : 0;
    const data = Object.assign(
      {
        micEnabled,
        motto: state.motto.text,
      },
      sender.getLoginData(state.sender.logins, state.sender.roomTypes),
      getCamData(state.cam)
    );

    if (state.sender?.toycontrol?.active) {
      const activeToy = state.sender.toycontrol.activeToy || '';
      const toys = state.sender.toycontrol.connected?.toys || {};
      const toyName = (toys[activeToy] || {})?.name || '';

      data.toycontrol = {
        active: toyName !== '',
        activeToy,
        toyName,
        checkToy: true,
      };
    }

    stamp = uuid();
    if (!state.streaming.silentReconnect) {
      dispatch({
        type: MANAGEMENT_CAM_LOGIN,
        meta: { start: true, stamp },
      });
    }
    management.emit(
      'camLogin',
      data,
      timeout(SOCKET_EMIT_CALLBACK_TIMEOUT, async (err, data) => {
        if (err) {
          return dispatch(error(new Error(SERVER_ERROR)));
        }

        if (data.errorStatus === 38) {
          try {
            const payload = await senderService.toycontrol({
              toggle: true,
              active: false,
            });
            dispatch({ type: TOYCONTROL_TOGGLE, payload });
          } catch (error) {}
        }

        if (data.errorStatus !== 12) {
          return dispatch(error(new Error(SERVER_ERROR), data.errorStatus));
        }
        dispatch({
          type: MANAGEMENT_CAM_LOGIN,
          meta: { stamp: uuid() },
          payload: data,
        });

        dispatch(wakeLock.request());

        if (data.idleStreamBandwidth !== -1) {
          //initially we have to stream with normal bandwidth
          dispatch(stream.publish(data));
        }
      })
    );
  };
};
const stop = (showMessage = false) => {
  return function (dispatch) {
    dispatch({
      type: MANAGEMENT_CAM_LOGIN,
      meta: { stamp, end: true },
    });
    if (showMessage) {
      dispatch(sendSystemChatMessage({ id: 'systemMessage.loggedIn' }));
    }
  };
};
const error = (err, errorStatus) => {
  return function (dispatch) {
    dispatch({
      type: MANAGEMENT_CAM_LOGIN,
      meta: { stamp, errorStamp: uuid(), errorStatus },
      error: true,
      payload: err,
    });
  };
};
export default (management) => ({
  start: start(management),
  stop,
  error,
});
