import useAxios from 'axios-hooks';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { PAGINATION_PAGE_SIZE } from '../../../../constants';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useTranslateTableTitles from '../../../../hooks/useTranslateTableTitles';
import Pagination from '../../../Pagination/Pagination';
import Scrollbar from '../../../Scrollbar';
import SpinnerComponent from '../../../Spinner/SpinnerComponent';
import DateItem from '../../../Tables/TableItems/DateItem';
import {
  fullVirtualGiftsTableTitles,
  mobileVirtualGiftsTableTitles,
} from './MyVirtualGifts.functions';

function MyVirtualGiftsTable({ openDialog }) {
  const intl = useIntl();
  const isMobileScreen = useMediaQuery('(max-width: 1023px)');
  const [virtualGifts, setVirtualGifts] = useState();
  const [offset, setOffset] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [items, setItems] = useState([]);
  const initialItems = useRef(null);
  const titles = isMobileScreen
    ? mobileVirtualGiftsTableTitles
    : fullVirtualGiftsTableTitles;
  const translatedTitles = useTranslateTableTitles(titles);

  const [{ loading }, virtualGiftsReq] = useAxios(
    {
      url: `user/virtual-gifts`,
    },
    { manual: true }
  );

  useEffect(() => {
    virtualGiftsReq()
      .then((response) => {
        setVirtualGifts(response?.data);
      })
      .catch(() => {});
  }, [virtualGiftsReq, setVirtualGifts]);

  useEffect(() => {
    if (virtualGifts) {
      let items = virtualGifts;
      initialItems.current = items;
      if (initialItems.current.length > PAGINATION_PAGE_SIZE) {
        items = items.slice(0, PAGINATION_PAGE_SIZE);
      }
      setItems([...items]);
    }
  }, [virtualGifts]);
  return (
    <Scrollbar>
      <div className="table-box">
        <h1 className="headline">
          <FormattedMessage id="MY_RECEIVED_VIRTUAL_GIFTS" />
        </h1>
        {loading ? (
          <SpinnerComponent />
        ) : !virtualGifts?.length ? (
          <span>{intl.formatMessage({ id: 'NO_VIRTUAL_GIFTS' })}</span>
        ) : (
          <table className="virtual-gifts noHover">
            <thead>
              <tr>
                {translatedTitles.map((title, index) => {
                  return (
                    <th
                      style={{
                        width: title.width,
                        textAlign: title.position,
                      }}
                      key={`${title}.${index}`}
                    >
                      {title.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {items?.map((item, index) => {
                return (
                  <tr key={`${item.key}.${index}`}>
                    <td>
                      <img
                        src={
                          item.img_medium &&
                          `${process.env.REACT_APP_IMAGE_URL_1}${item.img_medium}`
                        }
                        alt=""
                      />
                    </td>
                    {isMobileScreen ? (
                      <>
                        <td className="user">
                          <Link
                            onClick={() =>
                              openDialog(item.user_id, item.user_nickname)
                            }
                            to="/multi-messenger"
                          >
                            {item.user_nickname}
                          </Link>
                        </td>
                        <td className="text-right">
                          {item.price} {intl.formatMessage({ id: 'COINS' })}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>{item.name}</td>
                        <td className="user">
                          <Link
                            onClick={() =>
                              openDialog(item.user_id, item.user_nickname)
                            }
                            to="/multi-messenger"
                          >
                            {item.user_nickname}
                          </Link>
                        </td>
                        <td>
                          <DateItem data={item.created} />
                        </td>
                        <td className="text-right">
                          {item.price} {intl.formatMessage({ id: 'COINS' })}
                        </td>
                        <td className="text-right">{item.count}</td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      {loading ? null : (
        <Pagination
          offset={offset}
          totalItems={virtualGifts?.length}
          pageNumber={pageNumber}
          setOffset={setOffset}
          setPageNumber={setPageNumber}
          initialItems={initialItems.current}
          setItems={setItems}
        />
      )}
    </Scrollbar>
  );
}

export default MyVirtualGiftsTable;

