import {
  ADD_MESSAGE_EVENT_RATATOSKR,
  CAMUSER_FILTER,
  CAMUSER_MESSAGES_FILTER,
  RATATOSKR_OPEN,
  REMOVE_MESSAGE_EVENT_RATATOSKR,
} from '../actions/actions';

const initialState = {
  filter: '',
  messagesFilter: 'all',
  ratatoskrLoaded: false,
  activities: {
    profile: [],
    online: [],
    friend: [],
    friendRequest: [],
    content: [],
  },
};

const addActivity = (state, type, payload) => {
  return {
    ...state,
    activities: {
      ...state.activities,
      [type]: [
        ...state.activities[type].filter(
          (obj) => obj.userId !== payload.userId
        ),
        payload,
      ],
    },
  };
};
const removeActivity = (state, type, payload) => {
  return {
    ...state,
    activities: {
      ...state.activities,
      [type]: state.activities[type].filter(
        (obj) =>
          obj.userId !== payload.userId || obj.timestamp !== payload.timestamp
      ),
    },
  };
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RATATOSKR_OPEN: {
      return {
        ...state,
        ratatoskrLoaded: true,
      };
    }
    case CAMUSER_FILTER: {
      return { ...state, filter: action.payload };
    }
    case CAMUSER_MESSAGES_FILTER: {
      return { ...state, messagesFilter: action.payload };
    }
    case REMOVE_MESSAGE_EVENT_RATATOSKR: {
      if (!action?.payload?.id) {
        return state;
      }
      switch (action.payload.id) {
        case 'profile':
          return removeActivity(state, 'profile', action.payload);
        case 'online':
          return removeActivity(state, 'online', action.payload);
        case 'friend':
          return removeActivity(state, 'friend', action.payload);
        case 'buymovie':
        case 'buypicture':
          return removeActivity(state, 'content', action.payload);
        case 'buddyrequest':
          return removeActivity(state, 'friendRequest', action.payload);
        default:
          return state;
      }
    }
    case ADD_MESSAGE_EVENT_RATATOSKR: {
      if (!action?.payload?.id) {
        return state;
      }
      switch (action.payload.id) {
        case 'profile':
          return addActivity(state, 'profile', action.payload);
        case 'online':
          return addActivity(state, 'online', action.payload);
        case 'friend':
          return addActivity(state, 'friend', action.payload);
        case 'buymovie':
        case 'buypicture':
          return addActivity(state, 'content', action.payload);
        case 'buddyrequest':
          return addActivity(state, 'friendRequest', action.payload);
        default:
          return state;
      }
    }
    default:
      return state;
  }
};

export default reducer;
