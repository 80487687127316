import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import Button from '../../Button/Button';

class ExclusiveAlert extends React.Component {
  constructor(props) {
    super(props);
    this.el = React.createRef();
  }
  getTranslationIds(isSender) {
    return {
      headlineId: !isSender ? 'alert.exclusive' : 'alert.sender.exclusive',
      askExclusiveId: !isSender
        ? 'alert.askExclusive'
        : 'alert.sender.askExclusive',
      buttonConfirmId: !isSender ? 'alert.exclusiveAccept' : 'alert.sender.ok',
      buttonAbortId: !isSender ? 'alert.exclusiveDeny' : 'alert.sender.cancel',
    };
  }

  render() {
    const {
      onConfirm,
      onAbort,
      type,
      level,
      tick,
      username,
      price,
      expired,
      isSender,
    } = this.props;

    const cssClasses = [
      'alert',
      `alert-${type.toLowerCase().replace('_', '-')}`,
      `alert-${level.toLowerCase()}`,
      'has-headline',
    ];

    const showTick = !isSender && !expired && tick;

    const { headlineId, askExclusiveId, buttonConfirmId, buttonAbortId } =
      this.getTranslationIds(isSender);

    const alertButtonProps = {
      disabled: expired,
      onClick: onConfirm,
      label: buttonConfirmId,
    };

    if (showTick) {
      alertButtonProps.labelValues = { tick: <>({tick})</> };
    } else {
      alertButtonProps.labelValues = { tick: '' };
    }

    return (
      <div ref={this.el} className={cssClasses.join(' ')}>
        <h3>
          <FormattedMessage id={headlineId} />
        </h3>
        <div className="exclusive-warning">
          <FormattedMessage id="alert.kickUserWarning" />
        </div>
        <p className="exclusive-info">
          <FormattedMessage id={askExclusiveId} values={{ username }}>
            {(message) => <span>{message}</span>}
          </FormattedMessage>
        </p>
        <p>
          <FormattedMessage
            id="alert.exclusivePrice"
            values={{ coins: price }}
          />
        </p>
        {expired && (
          <FormattedMessage id="alert.notEnoughCoins" values={{ username }}>
            {(message) => <p className="not-enough-coins">{message}</p>}
          </FormattedMessage>
        )}
        <div className="alert-buttons">
          <Button {...alertButtonProps} />
          <Button onClick={onAbort} label={buttonAbortId} />
        </div>
      </div>
    );
  }
}

export default injectIntl(ExclusiveAlert);
