import { NO_ARRAY } from '../../errors';
import { UPDATE_CAM } from '../actions';

export default (err, cams) => {
  if (err) {
    return {
      type: UPDATE_CAM,
      payload: err,
      error: true,
    };
  }
  if (!Array.isArray(cams)) {
    return {
      type: UPDATE_CAM,
      payload: new Error(NO_ARRAY),
      error: true,
    };
  }
  return {
    type: UPDATE_CAM,
    payload: { cams },
  };
};
