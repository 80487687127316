export function generateSoundOptions(sounds, intl) {
  let options = sounds.map((sound) => {
    return {
      value: sound,
      label: sound,
    };
  });

  options.unshift({
    label: intl.formatMessage({ id: 'NO_SOUND' }),
    value: '',
  });
  return options;
}
