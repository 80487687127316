export default {
  HEADER_TITLE_NEWSLETTER: 'Newsletter Overview',
  HEADER_PAGE_NEWSLETTER: `All newsletters to read again`,
  ERROR_NO_NEWS_LETTER: `No newsletter found`,

  HEADER_TITLE_SUPPORT: `Help & Support`,

  SUPPORT_INFO_HEADLINE: 'Hello!',
  SUPPORT_INFO_TEXT: `<p>I'm Kathrin, your coach on LiveCreator.<br />
  If you have any questions about the onboarding process, most important steps, settings or anything related to your profile, I am available live in <span class="bold">Zoom</span></p>
  <ul class="bold">
    <li>on Tuesdays from 10 am to 11 am</li>
    <li>on Wednesday from 4 pm to 5 pm</li>
    <li>on Thursdays from 2 pm to 3 pm</li>
  </ul>
  <p>Just click on <a class="blue" href="https://us02web.zoom.us/j/89900156510?pwd=am11ME5VVnE4S3BlaWlFZHJCYjFJUT09" target="_blank">this link</a>.</p>
  Please note that the Zoom link only works during the time periods mentioned above.</p>
  <p class="bold">Best regards and good luck<br />
  Kathrin</p>`,

  SUPPORT_INTRO: `What do you need help with?`,
  SUPPORT_BACK_BUTTON: 'Back',

  SUPPORT_CONTACT: `Contact`,
  SUPPORT_EMAIL: `E-Mail: <span class="bold"><a href="mailto:support@livecreator.com">support@livecreator.com</a></span>`,
  SUPPORT_COPYRIGHT: `© {year} by IP Broadcasting B.V.`,
  SUPPORT_ALL_RIGHTS_RESERVED: `All rights reserved.`,
  SUPPORT_NO_REPRODUCTIONS: `No unauthorized reproduction permitted`,
  SUPPORT_VERSION: `<span class="bold">Version:</span> {version}`,

  TUTORIAL_SUBJECT_TEXT: 'Did this solve your problem?',
  TUTORIAL_YES_BUTTON: 'Yes, thank you',
  TUTORIAL_NO_BUTTON: 'No, I need more help by a supporter',

  SUPPORT_FORM_HEADLINE: 'Tell us how can we help you',
  CONTACT_SUPPORT_CATEGORY: `Category:`,
  CONTACT_SUPPORT_SUBJECT: `Subject:`,
  CONTACT_SUPPORT_MESSAGE: `Message:`,
  SUPPORT_ERROR_MESSAGE: `Please enter a message`,
  CONTACT_SUPPORT_BUTTON: `Send message`,

  SUPPORT_SUCCESS: `Your request was successfully submitted.`,
  SUPPORT_ERROR: `There has been an error submitting the request. Please try again.`,
};
