import { user as userUtils, uuid } from '../../../utils';
import { openOverlay } from '../../overlay';
import { socketService } from '../..';

export default () => (dispatch, getState) => {
  const {
    camUser: { camUsers },
    user,
  } = getState();
  if (
    user?.account?.icebreaker !== 'true' ||
    !user?.account?.scopes?.includes('send_message')
  ) {
    return;
  }
  const stamp = uuid();
  if (userUtils.livecamCount(camUsers) > 0) {
    return dispatch(socketService.keepIdle());
  }
  dispatch(
    openOverlay({
      stamp,
      Component: 'IdleDialog',
      props: {
        idledialogstamp: stamp,
      },
    })
  );
};

