import { initUpgrade, sendSystemChatMessage } from '../../../../actions';
import managementActions from '../../../../actions/management';
import { SHOW_TYPE_NORMAL } from '../../../../constants';
import {
  roomType as roomTypeUtils,
  user as userUtils,
} from '../../../../utils';

export default (management) => ({ origin, showType, userId }) => (
  dispatch,
  getState
) => {
  const user = userUtils.get(getState().camUser.camUsers, userId);

  if (!user || !user.visible) {
    return;
  }
  const { roomTypes, upgradeStarted } = getState().sender;

  const roomType = roomTypeUtils.findRoomType(showType, roomTypes);

  let message = '';

  if (showType === SHOW_TYPE_NORMAL) {
    return management.emit(
      'initUpgrade',
      { userId, origin, roomType, message },
      (err) => {
        console.log('initUpgrade normal finished');
        if (err) {
          console.error(err);
        }
      }
    );
  }
  if (roomTypeUtils.isExclusive(roomTypes)) {
    message = 'senderStreamsAlreadyExclusive';
  }

  if (upgradeStarted) {
    message = 'senderIsAlreadyInitUpgrade';
  }

  if (message === '') {
    dispatch(initUpgrade(true));
  }

  management.emit(
    'initUpgrade',
    { userId, origin, roomType, message },
    (err) => {
      console.log('initUpgrade exclusive finished');
      if (err) {
        dispatch(initUpgrade(false));
        if (err === 'disconnect') {
          dispatch(
            sendSystemChatMessage({
              id: 'systemMessage.exclusiveError',
              highlight: true,
            })
          );
          dispatch(managementActions.logoutCam({}, 13));
        }
        return;
      }
    }
  );
};
