import { connect } from 'react-redux';

import Stats from './Stats';

const mapStateToProps = (state) => ({
  user: state.user,
  ...state.browser,
});

export default connect(mapStateToProps)(Stats);
