import { startUpgradetimer } from '../actions';
import { SHOW_TYPE_EXPIRED } from '../actions/actions';
import { SHOW_TYPE_EXCLUSIVE } from '../constants';
import { user as userUtils, uuid } from './';

const exclusiveAllowed = (user, timerList) => {
  if (!user) {
    return false;
  }

  const timer = findExclusiveTimer(user, timerList);

  if (!timer) {
    return false;
  }

  if (timer.requestActive || timer.senderRequest) {
    return false;
  }

  return true;
};

const findExclusiveTimer = (user, timerList) =>
  timerList.find(
    (timer) =>
      timer.userId === user.userId && timer.showType === SHOW_TYPE_EXCLUSIVE
  );

const handleUpgradeTimer = (userInfo, requestActive, getState, dispatch) => {
  if (!userInfo.upgrades || userInfo.upgrades.length === 0) {
    return;
  }

  userInfo.upgrades.forEach((upgrade) => {
    const timerData = {
      userId: userInfo.userId,
      showType: upgrade.showType,
      stamp: uuid(),
      senderRequest: false,
      senderRequestStamp: uuid(),
      requestActive,
    };

    if (upgrade.delay <= 0) {
      return;
    }

    dispatch(startUpgradetimer(timerData));

    setTimeout(() => {
      const user = userUtils.get(getState().camUser.camUsers, userInfo.userId);
      // if no user, the user has maybe logged off, just do nothing
      if (!user) {
        return;
      }
      // if timer was removed, do nothing
      if (!userHasUpgradetimer(getState().upgradetimer, user.userId)) {
        return;
      }

      dispatch({
        type: SHOW_TYPE_EXPIRED,
        payload: {
          userId: userInfo.userId,
          showType: upgrade.showType,
        },
      });
    }, upgrade.delay);
  });
};
const isExclusiveRequestActive = (upgradetimerList, userId) =>
  upgradetimerList.find(
    (timer) =>
      timer.userId === userId &&
      timer.showType === SHOW_TYPE_EXCLUSIVE &&
      timer.requestActive
  )
    ? true
    : false;

const getAllTimerUserIds = (upgradetimerList) => [
  ...new Set(upgradetimerList.map((timer) => timer.userId)),
];

const userHasUpgradetimer = (upgradetimerList, userId) =>
  upgradetimerList.find((timer) => timer.userId === userId) ? true : false;

export default {
  findExclusiveTimer,
  exclusiveAllowed,
  handleUpgradeTimer,
  isExclusiveRequestActive,
  getAllTimerUserIds,
};
