import { version } from '../../../../../package.json';
import { checkVersion } from '../../..';

export default function (data, direct = false) {
  // we get some data from the api, but we do not care about it at the moment
  return (dispatch, getState) => {
    const { user } = getState();

    if (!user?.isLoggedIn) return;

    dispatch(checkVersion(version, direct));
  };
}
