export default {
  GALLERIES_HEADLINE: `Foto und Video Galerien`,
  GALLERIES_INTRO: `Eigene Bild- und Videoinhalte bieten Dir eine zusätzliche, lukrative Verdienstmöglichkeit. Anders als beim Chat im Messenger und in der Livecam, musst Du dafür nicht online sein.Lade Deine Inhalte einmalig hoch und sie stehen fortan in Deinem Profil zum Kauf zur Verfügung. Zudem erhalten User dadurch einen Vorgeschmack und Du kannst ihr Interesse wecken, nicht nur Inhalte von Dir zu kaufen, sondern Dich auch live im Chat und in der Livecam kennen zu lernen.`,

  GALLERIES_CONTENT_STEP_1: `Vorbereiten der Galerien`,
  GALLERIES_CONTENT_STEP_1_CONTENT: `Stelle eine Auswahl an Bildern zusammen, die Du in Deiner Galerie verwenden möchtest. Achte auf eine Auswahl unterschiedlicher Posen oder Outfits. Vermeide doppelte und sehr ähnliche Bilder. Durch Abwechslung in Deinen Galerien weckst Du die Neugier Deiner Kunden und sie werden immer wieder nach neuen Galerien schauen. Wähle ein geeignetes Vorschaubild, denn das ist das Erste, was der Kunde sehen wird. Es sollte zum Inhalt der Galerie passen. Überlege Dir eine passende Überschrift und eine Beschreibung, die den Kunden überzeugen, die Galerie erwerben zu wollen.`,
  GALLERIES_CONTENT_STEP_2: `Neue Galerie anlegen`,
  GALLERIES_CONTENT_STEP_2_CONTENT: `Im Hauptmenü unter „Mein Content“ wählst Du den Punkt „Foto“ oder „Video“ aus. Klicke auf den Button „Neue Galerie erstellen“`,
  GALLERIES_CONTENT_STEP_2_1_CONTENT: `Um eine Galerie veröffentlichen zu können, gelten folgenden Anforderungen:`,
  GALLERIES_CONTENT_STEP_2_2_CONTENT: `
   <ul class='checklist'>
      <li>mindestens 3 Fotos (Mindestauflösung 800 x 600 Pixel) oder ein Video (Format: mp4, avi, mpg, wmv) mit einer Mindestlänge von 1 Minute</li>
      <li>ein Vorschaubild (Mindestauflösung 1024 x 576 Pixel)</li>
      <li>Titel und Beschreibung</li>
      <li>mindestens eine Kategorie</li>
      <li>zugewiesene(s) Darsteller-Dokument(e)</li>
    </ul>`,
  GALLERIES_CONTENT_STEP_2_3_CONTENT: `Hast Du alles hochgeladen, hast Du zwei Optionen zum Veröffentlichen:`,
  GALLERIES_CONTENT_STEP_2_4_CONTENT: `
   <ul class='checklist'>
      <li>Klickst Du auf den Button „Jetzt veröffentlichen“ kommt die Galerie in den Support zur Prüfung. Das bedeutet, dass Deine Galerie nicht sofort für den Kunden verfügbar ist. Dies erfolgt erst nach erfolgter Prüfung durch den Support.</li>
      <li>Der Button „Zeitplan festlegen“ ermöglicht es Dir eine Veröffentlichung in der Zukunft zu planen. Das Datum muss in dem Fall mindestens 2 Tage in der Zukunft liegen. Im Hintergrund prüft der Support die Galerie und gibt sie frei. Zu dem von Dir gewählten Datum wird die Galerie dann automatisch veröffentlicht.</li>
    </ul>`,
  GALLERIES_CONTENT_STEP_3: `Richtlinien für die Preisgestaltung`,
  GALLERIES_CONTENT_STEP_3_CONTENT: `Abhängig von Qualität und FSK<br />
  Video: Circa 10 Coins / Minute<br />Bilder: Circa 2 – 8 Coins / Bild`,
  GALLERIES_CONTENT_STEP_3_1_CONTENT: `Extremere Inhalte wie BDSM, NS, Fetisch usw. können auch deutlich teurer sein!<br />
  Du kannst auch Inhalte gratis als Teaser anbieten (Ausnahme FSK18!)`,
  GALLERIES_CONTENT_STEP_4: `Veröffentlichung, Löschen und Archivieren`,
  GALLERIES_CONTENT_STEP_4_CONTENT: `Einmal veröffentlichte Galerien können nicht gelöscht werden. Wenn Du sie nicht mehr anbieten möchtest, kannst Du sie archivieren. Damit sind sie dann nur noch für Dich und Kunden, welche diese bereits gekauft haben, im Account verfügbar. Galerien kann nur der Support löschen, wenn noch keine Bilder / Filme daraus verkauft wurden. Die Freischaltung / Löschung einer Galerie kann bis zu einem Tag dauern!`,
};
