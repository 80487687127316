import {
  clearSystemMessageId,
  gameUtils,
  user as userUtils,
} from '../../utils';
import { SELECT_USER } from '../actions';
import { camUser, persistDialog, removeChatMessage } from '..';
let timer = null;

export default (userId, prioritizeCamUser, fetchImmediately = false) =>
  async (dispatch, getState) => {
    if (!getState().user.isLoggedIn) {
      return;
    }
    const {
      camUser: { camUsers },
      dialog: { dialogs },
      user: {
        account: { nickname },
      },
    } = getState();
    let id = userId;
    const userList = [...camUsers, ...dialogs];
    let payload = userList.find((user) => user.userId === id);
    if (prioritizeCamUser) {
      const camUserPayload = camUsers.find(
        (user) => `${user.clientProductId}@${user.clientCustomerId}` === id
      );
      if (camUserPayload) {
        id = camUserPayload?.userId;
        payload = { ...camUserPayload };
      }
    }
    const activeUser = userUtils.activeUser(userList);

    if (!payload) {
      console.log(`no user found on user select with id ${id}`);
      return; //should not happen, but avids breaking the app
    }

    if (
      activeUser?.userId === id &&
      !(camUsers.length === 1 && dialogs.length === 0)
    ) {
      return;
    }

    dispatch(camUser.closeAllMenues());

    const isSameUser =
      activeUser?.partner ===
        `${payload?.clientProductId}@${payload?.clientCustomerId}` ||
      payload?.partner ===
        `${activeUser?.clientProductId}@${activeUser?.clientCustomerId}`;
    if (isSameUser && userUtils.isExclusiveShow(activeUser)) {
      dispatch(
        removeChatMessage('systemMessage.startExclusive', activeUser.userId)
      );
    }

    const gameUserId = payload.isDialogUser
      ? id
      : payload?.clientCustomerId && payload?.clientProductId
      ? `${payload?.clientProductId}@${payload?.clientCustomerId}`
      : '';
    let game = {};
    if (gameUserId) {
      game = gameUtils.getGame(getState(), gameUserId);
    }
    const selectUserAction = {
      type: SELECT_USER,
      payload,
      meta: {
        isSameUser,
        game,
        from: nickname,
        to: payload?.name,
      },
    };
    if (isSameUser && typeof activeUser?.text === 'string') {
      selectUserAction.meta.text = activeUser.text;
    }
    dispatch(persistDialog(payload));
    dispatch(selectUserAction);
    clearSystemMessageId();

    // do not fetch instantly,
    // if a creator uses cursor keys to go up or down
    // it would fetch a lot of unnessesary stuff
    clearTimeout(timer);

    if (fetchImmediately) {
      dispatch(camUser.change(id, activeUser, isSameUser));
    } else {
      timer = setTimeout(() => {
        dispatch(camUser.change(id, activeUser, isSameUser));
      }, 100);
    }
  };
