import api from '../../utils/api';
import { addPreferences } from '../';

export default () => async (dispatch, getState) => {
  try {
    if (!getState().user?.isLoggedIn) return;
    const response = await api.get('/user/preferences');

    if (!getState().user?.isLoggedIn) return;
    dispatch(addPreferences(response.data));
  } catch (err) {
    console.log({ preferencesError: err });
    console.log('could not load preferences');
  }
};
