import { connect } from 'react-redux';

import CountriesSelect from './CountriesSelect';

const mapStateToProps = (state) => ({
  countries: state.countries.filter((el) => el.value !== ''),
  user: state.user,
});

export default connect(mapStateToProps)(CountriesSelect);
