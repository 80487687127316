import React from 'react';
import { FormattedMessage } from 'react-intl';

import BackButton from '../../../../BackButton';

function Galleries({ locale, onBackButtonClicked }) {
  return (
    <div className="wiki-content wiki-galleries-wrapper">
      <h1 className="headline">
        {onBackButtonClicked ? (
          <BackButton onClick={onBackButtonClicked} />
        ) : null}
        <FormattedMessage id="GALLERIES_HEADLINE" />
      </h1>
      <p className="has-margin-bottom">
        <FormattedMessage id="GALLERIES_INTRO" />
      </p>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">1</span>
          <span className="bold">
            <FormattedMessage id="GALLERIES_CONTENT_STEP_1" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="GALLERIES_CONTENT_STEP_1_CONTENT" />
          </p>
        </div>
      </div>
      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">2</span>
          <span className="bold">
            <FormattedMessage id="GALLERIES_CONTENT_STEP_2" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="GALLERIES_CONTENT_STEP_2_CONTENT" />
          </p>
          <p className="list-headline">
            <FormattedMessage id="GALLERIES_CONTENT_STEP_2_1_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="GALLERIES_CONTENT_STEP_2_2_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p className="list-headline">
            <FormattedMessage id="GALLERIES_CONTENT_STEP_2_3_CONTENT" />
          </p>
          <p>
            <FormattedMessage id="GALLERIES_CONTENT_STEP_2_4_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">3</span>
          <span className="bold">
            <FormattedMessage id="GALLERIES_CONTENT_STEP_3" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="GALLERIES_CONTENT_STEP_3_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
          <p>
            <FormattedMessage id="GALLERIES_CONTENT_STEP_3_1_CONTENT">
              {(message) => (
                <span dangerouslySetInnerHTML={{ __html: message[0] }} />
              )}
            </FormattedMessage>
          </p>
        </div>
      </div>

      <div className="wiki-list-item">
        <div className="wiki-list-headline">
          <span className="number">4</span>
          <span className="bold">
            <FormattedMessage id="GALLERIES_CONTENT_STEP_4" />
          </span>
        </div>
        <div className="wiki-list-content">
          <p>
            <FormattedMessage id="GALLERIES_CONTENT_STEP_4_CONTENT" />
          </p>
        </div>
      </div>
    </div>
  );
}
export default Galleries;
