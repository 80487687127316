import { connect } from 'react-redux';

import { spinner, userUpdate as update } from '../../../../actions';
import AccountDetails from './AccountDetails';

const mapDispatchToProps = {
  update,
  spinner,
};

export default connect(null, mapDispatchToProps)(AccountDetails);
