export default {
  MY_VIRTUAL_GIFTS_INFO_TEXT: `On this page you can see an overview of all the gifts you have received from your customers in the last 45 days.`,
  MY_VIRTUAL_GIFTS_INFO_TEXT_1: `Gifts are virtual attentions that your customers can give you in the chat.
  They are listed separately in the billing.`,
  MY_VIRTUAL_GIFTS_INFO_HEADER: `What virtual gifts are included in the overview?`,
  ROUTE_VIRTUAL_GIFTS_HEADLINE: `An overview of your received virtual gifts and what virtual gifts there are`,
  MY_RECEIVED_VIRTUAL_GIFTS: `My received virtual gifts`,
  NO_VIRTUAL_GIFTS: `You have not received a gift in the last 45 days.`,
  TABLE_TITLE_NAME: `Name`,
  TABLE_TITLE_LAST_FROM: `from`,
  TABLE_TITLE_DATE: `Date`,
  TABLE_TITLE_PRICE: `Price`,
  TABLE_TITLE_NUMBER: `Count`,
  VIRTUAL_GIFTS_CATEGORY: `Category:`,
  VIRTUAL_GIFTS_CATEGORY_LIST: `The following categories are available`,
};
