import { ALERT_CLOSE, ALERT_OK, DO_NOT_SHOW_IN } from '../actions/actions';

const initialState = {
  askDoNotShowConfirmedAlert: undefined,
  askDoNotShowConfirmed: undefined,
};

const publicDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DO_NOT_SHOW_IN:
      if (action.meta && action.meta.start) {
        return {
          ...state,
          askDoNotShowConfirmedAlert: action.meta.stamp,
        };
      }
      return {
        ...state,
        askDoNotShowConfirmedAlert: undefined,
        askDoNotShowConfirmed: undefined,
      };

    case ALERT_CLOSE: {
      if (action.payload.stamp === state.askDoNotShowConfirmedAlert) {
        return { ...state, askDoNotShowConfirmed: undefined };
      }
      return state;
    }
    case ALERT_OK: {
      if (action.payload.stamp === state.askDoNotShowConfirmedAlert) {
        return { ...state, askDoNotShowConfirmed: true };
      }
      return state;
    }

    default:
      return state;
  }
};

export default publicDetailsReducer;

