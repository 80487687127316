import Spinner from './Spinner';

function SpinnerPage() {
  return (
    <>
      <div className="spinner-wrapper">
        <Spinner />
      </div>
      <div className="background"></div>
    </>
  );
}

export default SpinnerPage;
