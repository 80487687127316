import { NOTIFICATION_LEVELS, NOTIFICATION_WARNING } from '../../constants';
import { ucfirst } from '../../utils';

export const livecamNotAllowedTip = {
  id: 'static_livecam_not_enabled',
  headline: 'DASHBOARD_LIVECAM_NOT_ENABLED_TIP_TITLE',
  text: 'DASHBOARD_LIVECAM_NOT_ENABLED_TIP_CONTENT',
  type: NOTIFICATION_WARNING,
};

export const browserNotAllowedTip = (browser) => ({
  id: 'static_browser_not_allowed',
  headline: 'DASHBOARD_BROWSER_NOT_ALLOWED_TIP_TITLE',
  text: 'DASHBOARD_BROWSER_NOT_ALLOWED_TIP_CONTENT',
  values: {
    browsers: browser.map(
      (b) => `${ucfirst(b.name)} ${b.operator} ${b.minVersion}`
    ).join(`,
       `),
  },
  type: NOTIFICATION_WARNING,
});

export const incompleteBankDetailsTip = {
  id: 'static_incomplete_bank_details',
  headline: 'BANK_ACCOUNT_MISSING_TITLE',
  text: 'BANK_ACCOUNT_MISSING_CONTENT',
  subcontent: 'BANK_ACCOUNT_MISSING_SUBCONTENT',
  link: '/my-account/my-personal-details/bank-details',
  type: NOTIFICATION_WARNING,
};

export const hasMinLevel = (level) => {
  const levels = [];
  let minLevelReached = false;
  for (let i = NOTIFICATION_LEVELS.length - 1; i >= 0; i--) {
    if (level === NOTIFICATION_LEVELS[i]) minLevelReached = true;
    if (minLevelReached) levels.push(NOTIFICATION_LEVELS[i]);
  }
  return (checkLevel) => levels.includes(checkLevel);
};

export const hasMaxLevel = (level) => {
  const levels = [];
  let maxLevelReached = false;
  for (let i = 0; i < NOTIFICATION_LEVELS.length; i++) {
    if (level === NOTIFICATION_LEVELS[i]) maxLevelReached = true;
    if (maxLevelReached) levels.push(NOTIFICATION_LEVELS[i]);
  }
  return (checkLevel) => levels.includes(checkLevel);
};
