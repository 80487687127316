import './Pagination.scss';

import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';

import backButton from '../../assets/img/svg/chevron-single-left.svg';
import forwardButton from '../../assets/img/svg/chevron-single-right.svg';
import { BACK, FORWARD, PAGINATION_PAGE_SIZE } from '../../constants';
import Button from '../Button/Button';

function Pagination({
  offset,
  totalItems,
  pageNumber,
  setOffset,
  setPageNumber,
  initialItems,
  setItems,
  paginationSize,
  onPageChange,
}) {
  const intl = useIntl();
  const paginationPageSize = paginationSize
    ? paginationSize
    : PAGINATION_PAGE_SIZE;

  const paginationClass = classNames('pagination-buttons', {
    hidden: totalItems <= paginationPageSize,
  });

  const changePage = (evt, direction) => {
    let newOffset;
    let newPageNumber;
    if (direction === BACK) {
      if (offset === 0) return;

      newOffset = offset - paginationPageSize;
      newPageNumber = pageNumber - 1;
    } else {
      if (offset + paginationPageSize > totalItems) return;
      newOffset = offset + paginationPageSize;
      newPageNumber = pageNumber + 1;
    }

    setOffset(newOffset);
    setPageNumber(newPageNumber);
    if (setItems) {
      setItems(initialItems.slice(newOffset, newOffset + paginationPageSize));
    }
    if (onPageChange) {
      onPageChange(evt);
    }
  };

  const onBlurHandler = () => {
    let newPageNumber;

    if (pageNumber < 1) {
      newPageNumber = 1;
    } else if (pageNumber > Math.ceil(totalItems / paginationPageSize)) {
      newPageNumber = Math.ceil(totalItems / paginationPageSize);
    } else {
      newPageNumber = pageNumber;
    }

    setOffset((newPageNumber - 1) * paginationPageSize);
    setPageNumber(parseInt(newPageNumber));

    if (setItems) {
      setItems(
        initialItems.slice(
          newPageNumber - 1,
          newPageNumber - 1 + paginationPageSize
        )
      );
    }
  };

  return (
    <div className={paginationClass}>
      <Button
        intlTranslate={false}
        icon={backButton}
        variant="icon-only"
        className={'btn-pagination'}
        renumerateIRIElements={false}
        onClick={(evt) => changePage(evt, BACK)}
        disabled={!offset}
      />
      <div className="pagination-input-wrapper">
        <label htmlFor={'pagination-page-input'}>
          {intl.formatMessage({ id: 'PAGE' })}
        </label>
        <input
          type="number"
          className="pagination-page-input"
          value={pageNumber}
          name="pagination-page-input"
          onChange={(event) => setPageNumber(event.target.value)}
          onBlur={onBlurHandler}
        />
        <div className="additional-label">
          {`${intl.formatMessage({ id: 'PAGE_OF' })} ${Math.ceil(
            totalItems / paginationPageSize
          )}`}
        </div>
      </div>
      <Button
        intlTranslate={false}
        icon={forwardButton}
        variant="icon-only"
        className={'btn-pagination dark'}
        renumerateIRIElements={false}
        onClick={(evt) => changePage(evt, FORWARD)}
        disabled={offset + paginationPageSize >= totalItems}
      />
    </div>
  );
}

export default Pagination;

