import { NO_DEVICE } from '../../errors';
import { getCam, getConstraint } from '../../utils/cam';
import { addCam, changeLabelCam, initCam, stopCam } from '.';
import { changeLabelMicrophone } from '..';

export default () => {
  return async (dispatch, getState) => {
    let { cam } = getState();
    console.log('choose Cam started');

    if (!cam.selectedCam.label) {
      return dispatch(addCam(new Error(NO_DEVICE)));
    }

    dispatch(stopCam());
    try {
      let deviceInfos = await navigator.mediaDevices.enumerateDevices();
      const constraint = getConstraint(cam, deviceInfos);
      const stream = await getCam(constraint);
      deviceInfos = await navigator.mediaDevices.enumerateDevices();
      const foundCam = deviceInfos.find(
        (device) =>
          device.deviceId === cam.selectedCam.value ||
          device.label === cam.selectedCam.label
      );
      if (cam.selectedCam.label !== foundCam.label) {
        dispatch(changeLabelCam(foundCam.label));
      }

      const foundMic = deviceInfos.find(
        (device) =>
          device.deviceId === cam.selectedMicrophone.value ||
          device.label === cam.selectedMicrophone.label
      );
      if (foundMic && cam.selectedMicrophone.label !== foundMic.label) {
        dispatch(changeLabelMicrophone(foundMic.label));
      }

      dispatch(initCam(null, deviceInfos));
      dispatch(addCam(null, stream));
    } catch (e) {
      dispatch(addCam(e));
    }
  };
};
